import React from "react";
import PropTypes from "prop-types";

import { Container, Table, Row, Col, Form, Spinner } from "react-bootstrap";
import CrawlResultsService from "../../services/CrawlResultsService";
import Pagination from "../../components/Pagination";
import "../../../node_modules/currency-flags/dist/currency-flags.min.css";
import PaginationInfo from "../../components/PaginationInfo";
import TableHeaderCell from "../../components/TableHeaderCell";
import TableSpinnerOverlay from "../../components/TableSpinnerOverlay";
import DelayedInput from "../../components/DelayedInput";
import Emitter from "../../services/Emitter";
import Currencies from "../../components/Currencies";
import { Link } from "react-router-dom";

/**
 * Crawl Results component
 * @component
 * @category Scenes
 * @subcategory Websites
 */
class CrawlResults extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.websiteId,
            rows: [],
            info: {},
            page: 1,
            pageSize: 25,
            numberOfItems: 0,
            numberOfPages: 0,
            search: {
                identifier: "",
                sku: "",
                brand: "",
            },
            sort: {
                field: "date",
                order: "desc",
            },
            loading: true,
        };
        this.onSortChange = this.onSortChange.bind(this);
        this.reload = this.reload.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    reload() {
        CrawlResultsService.successful(this.state.id, {
            pageSize: this.state.pageSize,
            page: this.state.page,
            search: this.state.search,
            sort: this.state.sort,
        }).then((data) => {
            let newState = {
                rows: data.rows,
                loading: false,
            },
                ids = [];

            if (typeof data.total == "number") {
                newState.numberOfItems = data.total;
                newState.numberOfPages = Math.ceil(
                    data.total / this.state.pageSize
                );
            }

            if (typeof data.rows == "object") {
                data.rows.forEach((row) => {
                    ids.push(row.id);
                });
            } else {
                newState.rows = [];
            }

            this.setState(newState);
            CrawlResultsService.get(ids).then((data) => {
                let keys = Object.keys(data);
                keys.forEach((key) => {
                    let json,
                        content,
                        node = document.getElementById(key);
                    if (node == null) {
                        return;
                    }
                    try {
                        json = JSON.parse(data[key]);
                        content = JSON.stringify(json, undefined, 2);
                    } catch (exception) {
                        content = data[key];
                    }

                    node.innerHTML = content.replace(/"(https?[^"]+)"/g, '<a href="$1" target="_blank" rel="noopener,noreferrer">$1</a>');
                });
            });
        });
    }

    componentDidMount() {
        Emitter.once("WEBSITE_CRAWLRESULTS", this.reload);
    }

    componentWillUnmount() {
        Emitter.off("WEBSITE_CRAWLRESULTS");
    }

    onRowClick = (e) => {
        let hiddenElement = e.currentTarget.nextSibling;
        // rowIndex = e.currentTarget.getAttribute("data-row");
        hiddenElement.className.indexOf("collapse show") > -1
            ? hiddenElement.classList.remove("show")
            : hiddenElement.classList.add("show");
    };

    onPageChange = (page) => {
        this.setState(
            {
                page,
                loading: true,
            },
            this.reload
        );
    };

    onPageSizeChange = (pageSize) => {
        this.setState(
            {
                pageSize,
                loading: true,
            },
            this.reload
        );
    };

    onSortChange(field, order) {
        this.setState(
            {
                sort: {
                    field,
                    order,
                },
                loading: true,
            },
            this.reload
        );
    }

    onSearch(field, value) {
        let search = this.state.search;
        search[field] = value;
        this.setState(
            {
                search,
                page: 1,
                loading: true,
            },
            this.reload
        );
    }

    render() {
        const rows = this.state.rows.map((row, index) => {
            const currency = Currencies.CodeByID(row.currency_id).toLowerCase();
            return (
                <React.Fragment key={`frag-${row.identifier}-${row.currency_id}`}>
                    <tr
                        key={`${row.identifier}-${row.currency_id}`}
                        onClick={this.onRowClick}
                        data-row={index}
                    >
                        <td>{row.date}</td>
                        <td>{row.identifier}</td>
                        <td>{row.upc}</td>
                        <td>{row.brands.map((brand) => <div><Link className="link" to={`/brand/${brand.id}`}>(#{brand.id}) {brand.name}</Link></div>)}</td>
                        <td>
                            <div className={`currency-flag currency-flag-${currency}`}></div>
                        </td>
                        <td>{row.sku}</td>
                        <td>
                            <Form.Control
                                key={`title-${row.identifier}-${row.currency_id}`}
                                type="text"
                                value={row.title}
                                readOnly={true}
                            />
                        </td>
                    </tr>
                    <tr className="collapse">
                        <td
                            colSpan="7"
                            id={`${this.props.websiteId}_${row.pid}_${row.currency_id}_1`}
                            style={{
                                fontFamily: "Courier New, monospace",
                                whiteSpace: "pre",
                                fontSize: "x-small",
                            }}
                        >
                            <Spinner animation="border" role="status"></Spinner>
                        </td>
                    </tr>
                </React.Fragment>

            )
        });
        return (
            <Container className="pt-4">
                <TableSpinnerOverlay loading={this.state.loading} />
                <Table bordered hover size="sm" className="myTable">
                    <thead>
                        <tr className="title">
                            <TableHeaderCell
                                field="date"
                                label="Date"
                                sortable={true}
                                width="140px"
                                sort={this.state.sort}
                                onChange={this.onSortChange}
                            />
                            <TableHeaderCell
                                field="identifier"
                                label="Identifier"
                                sortable={true}
                                sort={this.state.sort}
                                onChange={this.onSortChange}
                            />
                            <TableHeaderCell
                                field="upc"
                                label="UPC"
                                sortable={true}
                                sort={this.state.sort}
                                onChange={this.onSortChange}
                            />
                            <TableHeaderCell
                                field="brands"
                                label="Brands"
                                sortable={true}
                                sort={this.state.sort}
                                onChange={this.onSortChange}
                            />
                            <th>$</th>
                            <TableHeaderCell
                                field="sku"
                                label="SKU"
                                sortable={true}
                                sort={this.state.sort}
                                onChange={this.onSortChange}
                            />
                            <TableHeaderCell
                                field="title"
                                label="Title"
                                width="350px"
                                sortable={true}
                                sort={this.state.sort}
                                onChange={this.onSortChange}
                            />
                        </tr>
                        <tr>
                            <th></th>
                            <th>
                                <DelayedInput
                                    onChange={this.onSearch}
                                    field="identifier"
                                />
                            </th>
                            <th>
                                <DelayedInput
                                    onChange={this.onSearch}
                                    field="upc"
                                />
                            </th>
                            <th>
                                <DelayedInput
                                    onChange={this.onSearch}
                                    field="brand"
                                />
                            </th>
                            <th></th>
                            <th>
                                <DelayedInput
                                    onChange={this.onSearch}
                                    field="sku"
                                />
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.rows.length === 0 && (
                            <tr>
                                <td colSpan="6">Empty data set</td>
                            </tr>
                        )}
                        {rows}
                    </tbody>
                </Table>
                <Row className="pagination">
                    <Col sm={4} align="left">
                        <PaginationInfo
                            page={this.state.page}
                            numberOfItems={this.state.numberOfItems}
                            pageSize={this.state.pageSize}
                        />
                    </Col>
                    <Col sm={8} align="right">
                        <Pagination
                            key="pagination"
                            page={this.state.page}
                            numberOfPages={this.state.numberOfPages}
                            pageSize={this.state.pageSize}
                            onChangePage={this.onPageChange}
                            onChangePageSize={this.onPageSizeChange}
                        ></Pagination>
                    </Col>
                </Row>
            </Container>
        );
    }
}

CrawlResults.propTypes = {
    /** Website ID */
    websiteId: PropTypes.number.isRequired,
};

export default CrawlResults;
