import React from "react";

import { Carousel } from "react-bootstrap";
import MyCarousel from "../../components/MyCarousel";
import Emitter from "../../services/Emitter";
import Job from "./Job";
import JobsList from "./JobsList";
import NewJob from "./NewJob";

class Jobs extends React.Component {

    constructor(props) {
        super(props);
        this.last = -1;
        this.timer = null;
    }

    showPage(tab) {
        let key = 0,
            jobId = 0;

        if (tab === "new") {
            key = 1;
            this.last = 1;
            this.timer = null;
        } else if (tab === 'job') {
            key = 2;
            if (this.timer !== null) {
                window.clearTimeout(this.timer);
            }

            this.timer = setTimeout(() => {
                jobId = window.location.href.match(/\/(\d+)$/)[1]
                if (this.last !== 2) {
                    Emitter.emit("IMGDISCOVERY_JOB", jobId);
                    this.last = 2;
                    this.timer = null;
                }

            }, 250);
        } else {
            this.last = 0;
            this.timer = null;
        }

        return (
            <MyCarousel activeIndex={key}>
                <Carousel.Item className="standardGrid">
                    <JobsList />
                </Carousel.Item>
                <Carousel.Item className="standardGrid">
                    <NewJob />
                </Carousel.Item>
                <Carousel.Item className="standardGrid">
                    <Job />
                </Carousel.Item>
            </MyCarousel>
        );
    }

    render() {
        let activeKey = 'list';
        if (window.location.href.match(/\/new$/)) {
            activeKey = 'new';
        } else if (window.location.href.match(/\/\d+$/)) {
            activeKey = 'job';
        }

        return this.showPage(activeKey);
    }
}
export default Jobs;
