import React, { createRef } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Link, withRouter } from "react-router-dom";
import { Breadcrumb, Button, Container, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";
import { faCheck, faDownload, faPlus, faTimes, faMinus } from "@fortawesome/free-solid-svg-icons";
import MyTable from "../../components/MyTable";
import WebsiteService from "../../services/WebsiteService";
import Toast from "../../services/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import confirm from "../../components/Confirm";

const KNOWN_PD_ENGINES = {
    'pdd': 'Product Domain Discovery',
    'v1-upc': 'v1 UPC Search',
    'v1-bdo': 'v1 BDO',
    'google-shopping': 'Google Shopping',
    'descobridor': 'Descobridor',
    'npd': 'NPD',
    'direct': 'Direct',
}

/**
 * @component
 * @category Scenes
 * @subcategory Website
 */
class CrawlerDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            currencies: [],
            countries: [],
            pd_engines: [],
            pricing_engines: [],
            url_profiles: [],
        };
        this.handleAddRemoveFromBlackList = this.handleAddRemoveFromBlackList.bind(this);
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
        this.tableRef = createRef();
    }

    downloadSpreadsheet() {
        this.tableRef.current.CSV();
    }

    componentDidMount() {
        WebsiteService.crawlerDetails().then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of websites");
                return;
            }
            const currencies = [];
            const countries = [];
            const pd_engines = [];
            const pricing_engines = [];
            const url_profiles = [];

            data.map((row) => {
                row.automated = row.pd_engines ? 1 : 0;
                if (row.currency) {
                    currencies[row.currency] = true;
                }
                if (row.currencies) {
                    row.currencies.split(', ').map((currency) => {
                        currencies[currency] = true;
                        return null;
                    });
                }
                if (row.country) {
                    countries[row.country] = true;
                }
                if (row.pd_engines) {
                    row.pd_engines.split(', ').map((engine) => {
                        pd_engines[engine] = true;
                        return null;
                    });
                }

                if (row.pricing_engines) {
                    row.pricing_engines.split(', ').map((engine) => {
                        pricing_engines[engine] = true;
                        return null;
                    });
                }

                if (row.url_profile) {
                    url_profiles[row.url_profile] = true;
                }

                return row;
            });

            this.setState(
                {
                    loading: false,
                    currencies: Object.keys(currencies),
                    countries: Object.keys(countries),
                    pd_engines: Object.keys(pd_engines).map((key) => {
                        return {
                            label: KNOWN_PD_ENGINES[key] ?? key,
                            value: key
                        }
                    }),
                    pricing_engines: Object.keys(pricing_engines),
                    url_profiles: Object.keys(url_profiles),
                },
                () => this.tableRef.current.populate(data)
            );
        });
    }

    renderBooleanColumn(field) {
        return {
            className: 'text-center',
            format: row => this.renderBooleanIcon(row[field] === 1),
            csv: row => row[field] === 1 ? 'Yes' : 'No',
        }
    }

    renderBooleanIcon(checked) {
        const icon = checked ? faCheck : faTimes;
        const color = checked ? 'green' : 'red';

        return (<FontAwesomeIcon style={{ color }} icon={icon} />)
    }

    createBlackListButton(icon, inBlackList) {
        return (
            <Button
                size="sm"
                variant={inBlackList ? "danger" : "success"}
                className="tdButton"
                onClick={this.handleAddRemoveFromBlackList}
            >
                <FontAwesomeIcon icon={icon} />
            </Button>
        )
    }

    renderColumnWithButton(field) {
        return {
            className: 'text-center',
            csv: row => row[field] === 1 ? 'Yes' : 'No',
            format: row => {
                const inBlackList = row[field] === 1;
                const icon = inBlackList ? faMinus : faPlus;
                const popoverMessage = inBlackList
                    ? 'Remove from Self Healing Blacklist'
                    : 'Add to Self Healing Blacklist'

                return (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>{this.renderBooleanIcon(inBlackList)}</span>
                        <OverlayTrigger
                            placement="top"
                            trigger={["hover", "focus"]}
                            overlay={
                                <Popover>
                                    <PopoverBody>{popoverMessage}</PopoverBody>
                                </Popover>
                            }>
                            {this.createBlackListButton(icon, inBlackList)}
                        </OverlayTrigger>
                    </div>
                )
            }
        }
    }

    async handleAddRemoveFromBlackList(evt) {
        const obj = this.tableRef.current.getRowFromEvent(evt)
        const website = obj.row.name
        const inBlackList = obj.row.self_healing_blacklist === 1;
        const message = inBlackList
            ? `You are about the remove Website ${website} from Self Healing Blacklist `
            : `You are about to add Website ${website} to Self Healing Blacklist `;

        if (
            await confirm("Are you sure?", "Yes", "No", {
                message: `${message}. Do you want to proceed?`
            })
        ) {
            const action = inBlackList ? 'remove' : 'add';
            const successMessage = inBlackList
                ? 'Website removed successfully from healing blacklist.'
                : 'Website added successfully to healing blacklist.'
            const errorMessage = inBlackList
                ? 'Something went wrong while remove the website from healing blacklist.'
                : 'Something went wrong while add the website to healing blacklist'

            WebsiteService.handleAddOrRemoveFromSelfHealingBlackList(obj.row.id, action)
                .then((result) => {
                    if (!result) {
                        Toast.error(errorMessage)
                        return;
                    }

                    obj.row.self_healing_blacklist = inBlackList ? 0 : 1
                    Toast.ok(successMessage);
                    this.tableRef.current?.updateRow(obj.row);
                })
        }
    }

    get renderColumns() {
        return {
            name: row => <Link className="link" to={`/website/${row.id}`} target="_blank">{row.name}</Link>,
            is_retailer: {
                format: row => row.is_retailer ? 'Retailer' : 'Marketplace',
                csv: row => row.is_retailer ? 'Retailer' : 'Marketplace',
            },
            automated: this.renderBooleanColumn('automated'),
            functional: this.renderBooleanColumn('functional'),
            upc: this.renderBooleanColumn('upc'),
            bdo: this.renderBooleanColumn('bdo'),
            self_healing_blacklist: this.renderColumnWithButton('self_healing_blacklist'),
        }
    }

    get headers() {
        return [
            {
                field: 'name',
                label: 'Crawler Name',
                searchable: true,
                sortable: true,
                width: "250px"
            },
            {
                field: 'is_retailer',
                label: 'Retailer or Marketplace',
                allowEmpty: true,
                sortable: true,
                options: [
                    { label: 'Retailer', value: 1 },
                    { label: 'Marketplace', value: 0 }
                ],
                width: "180px"
            },
            {
                field: 'pricing_engines',
                label: 'Crawler Version',
                allowEmpty: true,
                sortable: true,
                options: this.state.pricing_engines,
                width: "140px"
            },
            {
                field: 'frequency',
                label: 'Crawl Frequency',
                allowEmpty: true,
                sortable: true,
                options: [
                    { label: '1x/day', value: 'daily' },
                    { label: '2x/day', value: 'twice' },
                    { label: '3x/day', value: 'thrice' }
                ],
                width: "140px"
            },
            {
                field: 'functional',
                label: 'Functional',
                hint: 'Crawler is working and crawling data',
                allowEmpty: true,
                sortable: true,
                options: [
                    { label: 'Yes', value: 1 },
                    { label: 'No', value: 0 }
                ],
                width: "100px"
            },
            {
                field: 'automated',
                label: 'Automated',
                allowEmpty: true,
                sortable: true,
                options: [
                    { label: 'Yes', value: 1 },
                    { label: 'No', value: 0 }
                ],
                width: "110px"
            },
            {
                field: 'pd_engines',
                label: 'Automation Status',
                sortable: true,
                allowEmpty: true,
                options: this.state.pd_engines,
                width: "160px"
            },
            {
                field: 'upc',
                label: 'UPC Search',
                sortable: true,
                allowEmpty: true,
                options: [
                    { label: 'Yes', value: 1 },
                    { label: 'No', value: 0 }
                ],
                width: "110px"
            },
            {
                field: 'bdo',
                label: 'BDO',
                sortable: true,
                allowEmpty: true,
                options: [
                    { label: 'Yes', value: 1 },
                    { label: 'No', value: 0 }
                ],
                width: "70px"
            },
            {
                field: 'bucket',
                label: 'Ecommerce Bucket',
                searchable: true,
                sortable: true,
                width: "150px"
            },
            {
                field: 'country',
                label: 'Selling Country',
                allowEmpty: true,
                sortable: true,
                options: this.state.countries,
                width: "140px"
            },
            {
                field: 'currency',
                label: 'Default Currency',
                allowEmpty: true,
                sortable: true,
                options: this.state.currencies,
                width: "160px"
            },
            {
                field: 'currencies',
                label: 'Supported Currencies',
                allowEmpty: true,
                sortable: true,
                options: this.state.currencies,
                width: "180px"
            },
            {
                field: 'tags',
                label: 'Tags',
                searchable: true,
                sortable: true,
                width: "140px"
            },
            {
                field: 'url_profile',
                label: 'Multiple URL Profile',
                allowEmpty: true,
                sortable: true,
                options: this.state.url_profiles,
                width: "220px"
            },
            {
                field: 'self_healing_blacklist',
                label: 'Self Healing Blacklist',
                allowEmpty: true,
                sortable: true,
                options: [
                    { label: 'Yes', value: 1 },
                    { label: 'No', value: 0 }
                ],
                width: "210px"
            },
        ];
    }

    render() {
        return (
            <Container className="pt-4">
                <span style={{
                    float: "right"
                }}
                >
                    <Button
                        disabled={this.state.loading}
                        size="sm"
                        className="tdButton"
                        variant="success"
                        label="Download CSV"
                        onClick={this.downloadSpreadsheet}
                    >
                        <FontAwesomeIcon icon={faDownload} /> Download
                    </Button>
                </span>
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Profiles</Breadcrumb.Item>
                    <Breadcrumb.Item active>Crawler Details</Breadcrumb.Item>
                </Breadcrumb>
                <MyTable
                    name="crawler-details-list"
                    ref={this.tableRef}
                    headers={this.headers}
                    renderColumns={this.renderColumns}
                    sortBy="name"
                    sortOrder="asc"
                />
            </Container>
        )
    }
}

export default withRouter(CrawlerDetails);
