import React from "react";
import PropTypes from "prop-types";

import { Container, Row, Col } from "react-bootstrap";
import Brands from "../../services/BrandService";
import MultiSelect from "react-multi-select-component";
import Emitter from "../../services/Emitter";

import {
    AreaChart,
    Area,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
} from "recharts";

/**
 * Renders daily volume charts
 * @component
 * @category Scenes
 * @subcategory Brands
 */
class Volume extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.brandId,
            loading: true,
            data: [],
            websites: [],
            website: [{ label: "All", value: 0 }],
            websiteSelectionEnabled: false,
        };
        this.onChange = this.onChange.bind(this);
        this.loadChart = this.loadChart.bind(this);
        this.onEnter = this.onEnter.bind(this);
    }

    loadChart(website = 0) {
        Brands.dailyVolume(this.state.id, website).then((data) => {
            let days = Object.keys(data.total),
                unique = [],
                total = [];
            days.forEach((day) => {
                unique.push({
                    name: day,
                    total: data.unique[day],
                });
                total.push({
                    name: day,
                    total: data.total[day],
                });
            });
            this.setState({ dailyTotal: total, dailyUnique: unique });
        });
    }

    onEnter() {
        this.loadChart();

        Brands.getSellingWebsites(this.state.id).then((data) => {
            let rows = [];
            data.forEach((item) => {
                rows.push({
                    value: item.id,
                    label: item.name
                });
            });
            this.setState({
                websites: rows,
                websiteSelectionEnabled: rows.length > 0,
            });
        });
    }

    componentDidMount() {
        Emitter.once("BRAND_VOLUME", this.onEnter);
    }

    componentWillUnmount() {
        Emitter.off("BRAND_VOLUME");
    }

    onChange(list) {
        if (list.length > 1) {
            list.splice(0, 1);
        }
        this.setState({
            website: list,
        });
        if (list.length) {
            this.loadChart(list[0].value);
        } else {
            this.loadChart();
        }
    }

    render() {
        return (
            <Container className="pt-4">
                <Row>
                    <MultiSelect
                        id="brands"
                        disabled={!this.state.websiteSelectionEnabled}
                        options={this.state.websites}
                        value={this.state.website}
                        hasSelectAll={false}
                        onChange={this.onChange}
                        labelledBy="Select brand"
                    />
                </Row>
                <Row>
                    <Col
                        style={{
                            marginTop: "20px",
                        }}
                        className="d-flex align-items-center justify-content-center"
                    >
                        Unique Products
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex align-items-center justify-content-center text-center">
                        <AreaChart
                            width={900}
                            height={350}
                            data={this.state.dailyUnique}
                            margin={{ top: 0, right: 20, bottom: 5, left: 0 }}
                        >
                            <Area
                                type="monotone"
                                dataKey="total"
                                stroke="#8884d8"
                            />
                            <CartesianGrid
                                stroke="#ccc"
                                strokeDasharray="5 5"
                            />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                        </AreaChart>
                    </Col>
                </Row>
                <Row>
                    <Col
                        className="d-flex align-items-center justify-content-center"
                        style={{
                            marginTop: "20px",
                        }}
                    >
                        Offers
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex align-items-center justify-content-center text-center">
                        <AreaChart
                            width={900}
                            height={350}
                            data={this.state.dailyTotal}
                            margin={{ top: 0, right: 0, bottom: 0, left: 30 }}
                        >
                            <Area
                                type="monotone"
                                dataKey="total"
                                stroke="#8884d8"
                            />
                            <CartesianGrid
                                stroke="#ccc"
                                strokeDasharray="5 5"
                            />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                        </AreaChart>
                    </Col>
                </Row>
            </Container>
        );
    }
}

Volume.propTypes = {
    /** Website ID */
    brandId: PropTypes.number.isRequired,
};

export default Volume;
