import React from "react";

import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Button,
    Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Toast from "../../services/Toast";
import Emitter from "../../services/Emitter";
import ImageDiscoveryService from "../../services/ImageDiscoveryService";
import ManufacturerSearch from "../../components/ManufacturerSearch";
import ProcessingButton from "../../components/ProcessingButton";

/**
 * New Job component
 * @component
 * @category Scenes
 * @subcategory Image Discovery
 */
class NewJob extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updating: false,
            manufacturer: null,
            total: 0,
            missing: 0,
            mode: 'partial'
        };
        this.handleManufacturerChange = this.handleManufacturerChange.bind(this);
        this.typeahead = React.createRef();
        this.submit = this.submit.bind(this);
    }

    handleManufacturerChange(text) {
        if (text.length) {
            this.setState({
                manufacturer: text[0],
            });

            ImageDiscoveryService.summary(text[0].id).then((result) => {
                if (result === false) {
                    Toast.error("Failed retrieving manufacturer's summary");
                    return
                }

                if (result.missing === 0) {
                    if (result.total === 0) {
                        result.mode = '';
                    } else {
                        result.mode = 'complete';
                    }
                } else {
                    result.mode = 'partial';
                }
                this.setState(result);
            });

        } else {
            this.setState({
                manufacturer: null,
                total: 0,
                missing: 0
            });
        }
    }

    submit() {
        this.setState({
            updating: true,
        });
        let payload = {
            manufacturer_id: this.state.manufacturer.id,
            mode: this.state.mode,
        };

        ImageDiscoveryService.request(payload).then((result) => {
            if (result) {
                this.setState(
                    {
                        manufacturer: null,
                        updating: false,
                        mode: 'partial',
                        missing: 0,
                        total: 0,
                    },
                    () => {
                        this.props.history.push(`/imgdiscovery`);
                        this.typeahead.current.clear();
                    }
                );
                setTimeout(() => {
                    Emitter.emit("RELOAD_IMG_JOBS");
                }, 500);
            } else {
                Toast.error(
                    "Something went wrong while registering this new job."
                );
                this.setState({
                    updating: false,
                });
            }
        });
    }

    render() {
        const labelPartial = "Only from products with no image" + (this.state.missing ? " (" + this.state.missing + " product" + (this.state.missing > 1 ? "s" : '') + ")" : '')
        const labelComplete = "Complete" + (this.state.total ? " (" + this.state.total + " product" + (this.state.total > 1 ? "s" : '') + ")" : '')
        return (
            <Container className="pt-4 dd">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Image Discovery</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/imgdiscovery/">
                            Jobs
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>New</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <Col
                        sm={2}
                        className="d-flex align-items-center justify-content-end text-center"
                    >
                        Manufacturer
                    </Col>
                    <Col sm={7}>
                        <ManufacturerSearch ref={this.typeahead} onChange={this.handleManufacturerChange} />
                    </Col>
                    <Col sm={3}>
                        <Button
                            disabled={this.state.updating}
                            variant="secondary"
                            as={Link}
                            to="#list"
                        >
                            Cancel
                        </Button>
                        <ProcessingButton
                            variant="primary"
                            processing={this.state.updating}
                            processingLabel="Saving ..."
                            label="Request"
                            onClick={this.submit}
                            disabled={this.state.manufacturer === null || (this.state.total === 0 && this.state.missing === 0)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col
                        sm={2}
                        className="d-flex align-items-center justify-content-end text-center"
                    >
                        Mode
                    </Col>
                    <Col sm={7}>
                        <Form.Check
                            checked={this.state.mode === 'partial'}
                            name="mode"
                            type="radio"
                            onClick={() => { this.setState({ mode: 'partial' }) }}
                            disabled={this.state.missing === 0}
                            label={labelPartial} />
                        <Form.Check
                            checked={this.state.mode === 'complete'}
                            onClick={() => { this.setState({ mode: 'complete' }) }}
                            name="mode"
                            type="radio"
                            disabled={this.state.total === 0}
                            label={labelComplete} />
                    </Col>

                </Row>
                <Row
                    style={{
                        height: "350px",
                    }}
                ></Row>
            </Container>
        );
    }
}

export default withRouter(NewJob);
