import Utils from "./Utils";

/**
 * Auxiliary class for easily generating CSV files
 */
function CSV() {
    var _headers = [],
        _rows = [];

    this.cleanUp = function (text) {
        if (typeof text === "number") {
            return text;
        }
        if (typeof text !== "string") {
            return "";
        }
        return Utils.cleanCSVValue(text);
    }
    /**
     * Adds a column to the headers
     * @param {string} label
     */
    this.addColumn = function () {
        if (arguments.length === 1 && typeof arguments[0] === "object" && arguments[0].length) {
            arguments[0].forEach((r) => {
                _headers.push('"' + this.cleanUp(r) + '"');
            });
            return this;
        }
        for (let i = 0; i < arguments.length; i++) {
            _headers.push('"' + this.cleanUp(arguments[i]) + '"');
        }
        return this;
    };
    /**
     * Adds a rows to the file
     * @param {array} row
     */
    this.addRow = function () {
        let row = [];
        if (arguments.length === 1 && typeof arguments[0] === "object" && arguments[0].length) {
            arguments[0].forEach((r) => {
                row.push('"' + this.cleanUp(r) + '"');
            });
            _rows.push(row.join(","));
            return this;
        }
        for (let i = 0; i < arguments.length; i++) {
            row.push('"' + this.cleanUp(arguments[i]) + '"');
        }
        _rows.push(row.join(","));
        return this;
    };
    /**
     * Generates the CSV file content
     * @returns string
     */
    this.Generate = function () {
        return _headers.join(",") + "\n" + _rows.join("\n");
    };

    this.Download = function (filename) {
        Utils.DownloadContent(filename, this.Generate());
    }
}

export default CSV;
