import React, { createRef } from "react";

import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Button,
    Form,
    OverlayTrigger,
    Popover,
    PopoverBody
} from "react-bootstrap";
import { Link, NavLink, withRouter } from "react-router-dom";
import Emitter from "../../../services/Emitter";
import Toast from "../../../services/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faShare } from "@fortawesome/free-solid-svg-icons";
import 'react-image-lightbox/style.css';
import OnboardingService from "../../../services/OnboardingService";
import MemoryService from "../../../services/MemoryService";
import MySpinner from "../../../components/MySpinner";
import MyTable from "../../../components/MyTable";

/**
 * Onboarding Job view component
 * @component
 * @category Scenes
 * @subcategory Onboarding/Bucketization
 */
class View extends React.Component {
    constructor(props) {
        super(props);
        this.original = [];
        this.state = {
            jobId: 0,
            info: {},
            user: '',
            xpath_requested: null,
            datetime: '',
            brand: null,
            downloading: null
        };
        this.reload = this.reload.bind(this);
        this.handleRequested = this.handleRequested.bind(this);
        this.handleDownloadReport = this.handleDownloadReport.bind(this);
        this.handleDownloadXPath = this.handleDownloadXPath.bind(this);
        this.tableRef = createRef();
    }

    componentDidMount() {
        Emitter.on("ONBOARDING_JOB", this.reload);
    }

    componentWillUnmount() {
        Emitter.off("ONBOARDING_JOB");
    }

    reload(jobId) {
        if (typeof jobId === "undefined") {
            return;
        }

        if (jobId > 0) {
            this.tableRef.current?.populate([]);
            this.tableRef.current?.setLoading(true);

            this.setState({
                jobId: jobId,
                brand: null
            })
        }
        OnboardingService.job(this.state.jobId).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while retriving this job's results");
                return;
            }

            let rows = [];
            result.websites.forEach((item) => {
                item.i = this.original.length;
                let info = OnboardingService.prettifyStatus(item);
                item.status = info.status;
                item.color = info.color;
                item.hint = info.hint;
                item.ticket = typeof item.ticket === "string" ? item.ticket : "";
                rows.push(item);
            });
            this.setState({
                datetime: result.created,
                brand: result.brand,
                xpath_requested: typeof result.xpath_requested === "string" && result.xpath_requested ? "checked" : null,
                user: result.user
            }, () => {
                this.tableRef.current?.populate(rows);
            })
        });
    }


    handleRequested(evt) {
        let checked = evt.target.checked;
        this.setState({ updating: true })
        OnboardingService.setRequested(this.state.jobId, evt.target.checked).then((result) => {
            let update = {
                updating: false
            }
            if (result) {
                Toast.ok("Modification update successfully");
                update.xpath_requested = checked ? "checked" : null;
                MemoryService.Set('REFRESH_ONBOARDING_JOBS', {
                    jobId: this.state.jobId * 1,
                    checked
                });
            } else {
                Toast.error("Something went wrong while updating this entry");
            }
            this.setState(update)
        });
    }

    handleDownloadXPath(evt) {

        this.setState({ downloading: "xpath" });
        OnboardingService.XPathReport(this.state.jobId, false).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while retrieving the list");
            } else if (result === -1) {
                Toast.info("Empty list");
            } else {
                MemoryService.Set('XPATH_LIST', result);
                this.props.history.push('/onboarding/xpath');
            }
            this.setState({ downloading: null });
        });
    }

    handleDownloadReport(evt) {
        this.tableRef.current.CSV({
            filename: "onboarding-" + this.state.jobId + "-report.csv"
        })
    }

    render() {

        return (
            <Container className="pt-4" >
                < Breadcrumb className="dd" >
                    <Breadcrumb.Item>Onboarding</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/onboarding/bucketization/">
                            Bucketization
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active># {this.state.jobId}</Breadcrumb.Item>
                </Breadcrumb>

                {this.state.brand !== null && (
                    <React.Fragment>
                        <Row>
                            <Col sm={3}><b>Date/Time</b>: {this.state.datetime}</Col>
                            <Col sm={3}><b>Client</b>: <NavLink className="link" to={`/brand/${this.state.brand.id}`}>{this.state.brand.store_name}</NavLink></Col>
                            <Col sm={3}
                                style={{
                                    display: "inline-flex"
                                }}><b>XPath Requested</b>:
                                <span style={{
                                    transform: "translate(15px, -4px)",
                                }}>
                                    {(!this.state.updating) && (
                                        <Form.Check className="lg" type="checkbox" checked={this.state.xpath_requested} onChange={this.handleRequested} />
                                    )}
                                    {
                                        this.state.updating && (
                                            <MySpinner />
                                        )}
                                </span>
                            </Col>
                            <Col sm={3}><b>Requester</b>: {this.state.user}</Col>
                        </Row>
                        <Row>
                            <Col className="d-flex align-items-center justify-content-center text-center">
                                {this.state.downloading !== "xpath" && (
                                    <Button variant="outline-primary" onClick={this.handleDownloadXPath}>
                                        <FontAwesomeIcon icon={faShare} style={{
                                            marginRight: "10px"
                                        }} />Send websites to XPath Team
                                    </Button>
                                )}
                                {this.state.downloading === "xpath" && (
                                    <MySpinner />
                                )}
                                {this.state.downloading !== "report" && (
                                    <Button variant="outline-primary" onClick={this.handleDownloadReport}>
                                        <FontAwesomeIcon icon={faBook} style={{
                                            marginRight: "10px"
                                        }} />Download report
                                    </Button>
                                )}
                                {this.state.downloading === "report" && (
                                    <MySpinner />
                                )}

                            </Col>
                        </Row>
                    </React.Fragment>
                )}
                <MyTable
                    name="bucketization-view"
                    ref={this.tableRef}
                    dynamic={false}
                    sortBy="website"
                    sortOrder="asc"
                    headers={[
                        {
                            field: "website",
                            label: "Website",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "requested",
                            label: "Requested on",
                            width: "140px",
                            sortable: true,
                            searchable: true

                        },
                        {
                            field: "delivered",
                            label: "Delivered on",
                            width: "140px",
                            sortable: true,
                            searchable: true
                        },
                        {
                            width: "100px",
                            field: "ticket",
                            label: "Ticket",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "status",
                            label: "Status",
                            width: "400px",
                            sortable: true,
                            searchable: true
                        }
                    ]}
                    renderColumns={{
                        website: (row) => <Link className="link" to={`/website/${row.website_id}`}>(#{row.website_id}) {row.website}</Link>,
                        requested: (row) => /synced/.test(row.status) ? '' : (typeof row.delivered === "string" && row.delivered.length ? this.state.datetime : ''),
                        delivered: (row) => /synced/.test(row.status) ? '' : row.delivered,
                        ticket: (row) => typeof row.ticket === "string" && row.ticket.length > 0 ? (
                            <a className="link" href={`https://juststicky.atlassian.net/browse/${row.ticket}`} target="_blank" rel="noreferrer">{row.ticket}</a>
                        ) : null,
                        status: (row) => typeof row.hint !== "undefined" && row.hint.length ?
                            <OverlayTrigger
                                placement="right"
                                trigger={["hover", "focus"]}
                                overlay={
                                    <Popover>
                                        <PopoverBody>
                                            {row.hint}
                                        </PopoverBody>
                                    </Popover>
                                }
                            >
                                <span style={{ color: row.color }}>
                                    {row.status}
                                </span>
                            </OverlayTrigger> :
                            <span style={{ color: row.color }}>
                                {row.status}
                            </span>
                    }}
                />
            </Container >
        );
    }
}

export default withRouter(View);
