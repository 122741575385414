import React, { createRef } from "react";

import { Container, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEdit, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import prompt from "../../components/Prompt";
import confirm from "../../components/Confirm";
import Toast from "../../services/Toast";
import Emitter from "../../services/Emitter";
import ProductService from "../../services/ProductService";
import URLLookupService from "../../services/URLLookupService";
import MyTable from "../../components/MyTable";
import { Link, withRouter } from "react-router-dom";
import Utils from "../../services/Utils";

/**
 * URL Lookup component
 * @component
 * @category Scenes
 * @subcategory Products
 */
class Lookup extends React.Component {
    constructor(props) {
        super(props);
        this.reload = this.reload.bind(this);

        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
        this.tableRef = createRef();
    }

    componentWillUnmount() {
        Emitter.off("PRODUCT_LOOKUP");
    }

    componentDidMount() {
        Emitter.once("PRODUCT_LOOKUP", this.reload);
    }

    downloadSpreadsheet() {
        this.tableRef.current.CSV({
            filename: "product-" + this.props.id + "-urls.csv"
        });
    }

    reload() {
        ProductService.urls(this.props.id).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while loading this product's lookup table");
                return;
            }
            this.tableRef.current?.populate(result);
        });
    }

    async handleDelete(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);

        if (
            await confirm("Are you sure?", "Yes", "No", {
                message:
                    "You are about to remove this URL. This operation can't be undone. Do you want to proceed?",
            })
        ) {
            URLLookupService.remove(obj.row.id, null).then(
                (result) => {
                    if (!result) {
                        Toast.error("Something went wrong while removing the URL.");
                        return;
                    }
                    Toast.ok("URL removed successfully.");
                    this.tableRef.current?.deleteRow(obj.row);
                }
            );
        }
    }

    handleEdit(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);

        prompt("Edit URL", "Update", "Cancel", "", obj.row.url)
            .then((inputResult) => {
                if (inputResult.value !== obj.row.url) {
                    URLLookupService.set(
                        obj.row.id,
                        inputResult.value
                    ).then((result) => {
                        if (!result) {
                            Toast.error("Something went wrong while updating the URL");
                            return;
                        }
                        obj.row.url = inputResult.value;
                        obj.row.updated = Utils.DateNow();
                        this.tableRef.current?.updateRow(obj.row);
                        Toast.ok("URL updated successfully");
                    });
                }
            })
            .catch(() => { });
    }

    handleNew() {
        prompt("Add new URL", "Add", "Cancel", "", "", {
            submitOnEnter: false
        }).then((inputResult) => {
            let dirtyValues = inputResult.value.replace(/[\n ]/g, ',').split(','),
                cleanValues = []

            dirtyValues.forEach((text) => {
                if (text.trim().length === 0) {
                    return;
                }
                cleanValues.push(text.trim());
            });
            if (cleanValues.length === 0) {
                return;
            }

            let plural = cleanValues.length > 1;


            URLLookupService.addByProduct(this.props.id, cleanValues).then(
                (result) => {

                    if (this.tableRef.current === null) {
                        return;
                    }

                    if (result !== false) {
                        let ok = 0,
                            nok = 0;

                        let rows2add = [],
                            rows2update = [];

                        result.forEach((row) => {
                            if (row === false || row === null) {
                                nok++;
                                return;
                            }
                            ok++;
                            let current = this.tableRef.current.getRowByID(row.id);
                            if (current.index === -1) {
                                rows2add.push(row);
                            } else {
                                current.row.url = row.url;
                                rows2update.push(current.row);
                            }
                        });
                        if (ok === 0) {
                            Toast.error(
                                "Something went wrong while adding the new URL" + (plural ? "s. None got inserted." : "")
                            );
                        } else if (nok) {
                            Toast.info(
                                ok + " URL" + (ok > 1 ? "s were added" : " was added") + " while " + nok + (nok > 1 ? " URLs" : " URL") + " could not be added"
                                , 5000);
                        } else {
                            Toast.ok(
                                ok === 1 ? "URL added successfully" : ok + " URLs were added successfully"
                            );
                        }

                        if (rows2update.length) {
                            this.tableRef.current.updateRows(rows2update);
                        }
                        if (rows2add.length) {
                            this.tableRef.current.addRows(rows2add);
                        }
                    } else {
                        Toast.error(
                            "Something went wrong while adding the new URL" + (plural ? "s" : "")
                        );
                    }
                }
            );
        }).catch(() => { });
    }

    render() {
        return (
            <Container className="pt-4">
                <MyTable
                    name="product-lookup"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="website"
                    sortOrder="asc"
                    headers={[
                        {
                            field: "website",
                            label: "Website",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "url",
                            label: "URL",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "origin",
                            label: "Origin",
                            width: "100px",
                            sortable: true,
                            allowEmpty: true,
                            options: [{ label: "Manual", value: "manual" }, { label: "Product Discovery", value: "dd-" }, { label: "Unknown", value: "unknown" }]
                        },
                        {
                            field: "updated",
                            label: "Updated",
                            width: "140px",
                            sortable: true
                        },
                        {
                            field: "control",
                            width: "87px",
                            className: "text-center",
                            content: <React.Fragment>
                                <Button
                                    size="sm"
                                    variant="success"
                                    className="tdButton"
                                    onClick={this.handleNew}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                                <Button
                                    size="sm"
                                    variant="primary"
                                    className="tdButton"
                                    onClick={this.downloadSpreadsheet}
                                >
                                    <FontAwesomeIcon icon={faDownload} />
                                </Button>
                            </React.Fragment>
                        }]
                    }
                    renderColumns={{
                        website: (row) => <Link className="link" to={`/website/${row.website_id}`}>(#{row.website_id}) {row.website}</Link>,
                        control: (row) => <React.Fragment>
                            <Button
                                size="sm"
                                variant="secondary"
                                className="tdButton"
                                onClick={this.handleEdit}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                            <Button
                                size="sm"
                                variant="danger"
                                className="tdButton"
                                onClick={this.handleDelete}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </React.Fragment>
                    }}
                />

            </Container>
        );
    }
}

export default withRouter(Lookup);
