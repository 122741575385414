import React from "react";

import { Nav, Carousel, Container, Breadcrumb } from "react-bootstrap";
import Emitter from "../../services/Emitter";
import Profile from "./Profile";
import { withRouter } from "react-router-dom";
import MyCarousel from "../../components/MyCarousel";
import Loading from "../Loading";
import UserService from "../../services/UserService";
import Toast from "../../services/Toast";
import Brand from "./Brand";

class Index extends React.Component {
    constructor(props) {
        const userId = props.match.params.id * 1
        super(props);
        this.state = {
            id: userId,
            name: userId === 0 ? "New" : "...",
            loading: userId !== 0
        }
        this.firstLoad = true;

        if (userId) {
            UserService.name(userId).then((info) => {
                if (info === false) {
                    Toast.error("Something went wrong while retrieving the user information");
                    this.props.history.push("/user");
                    return;
                }

                this.setState({
                    name: info,
                    loading: false
                });
            });
        }
    }

    componentWillUnmount() {
        Emitter.off("USER_CREATED");
        Emitter.off("USER_CHANGED");
    }

    componentDidMount() {
        Emitter.on("USER_CREATED", (user) => {
            this.setState({
                id: this.props.match.params.id * 1,
                name: user.name
            });
        });
        Emitter.on("USER_CHANGED", (user) => {
            this.setState({
                name: user.name
            });
        });

    }


    showPage(tab) {
        let key, event;
        switch (tab) {
            default:
                key = 0;
                event = "USER_PROFILE";
                break;
            case "#brands":
                key = 1;
                event = "USER_BRANDS";
                break;
        }

        if (this.firstLoad) {
            this.firstLoad = false;
            setTimeout(() => {
                Emitter.emit(event);
            }, 250);
        } else {
            Emitter.emit(event);
        }

        return (
            <MyCarousel activeIndex={key}>
                <Carousel.Item key="#profile" className="standardGrid">
                    <Profile id={this.state.id} />
                </Carousel.Item>
                <Carousel.Item key="#brands" className="standardGrid">
                    <Brand id={this.state.id} />
                </Carousel.Item>
            </MyCarousel>
        );
    }

    render() {

        if (this.state.loading) {
            return <Loading />
        }

        let match = window.location.href.match(/#\w+/),
            activeKey = match !== null && typeof match[0] !== "undefined" ? match[0] : "#profile";

        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>User</Breadcrumb.Item>
                    <Breadcrumb.Item active>{this.state.name}</Breadcrumb.Item>
                </Breadcrumb>
                <Nav variant="tabs" activeKey={activeKey}>
                    <Nav.Item>
                        <Nav.Link
                            disabled={this.state.id === 0}
                            href="#profile">
                            Profile
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                            disabled={this.state.id === 0}
                            href="#brands">
                            Brands
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                {this.showPage(activeKey)}
            </Container>
        );
    }
}
export default withRouter(Index);
