import React, { createRef } from "react";
import { Container, Breadcrumb, Row, Col, Carousel, Form } from "react-bootstrap";
import Spreadsheet from "../../components/Spreadsheet";
import URLLookupService from "../../services/URLLookupService";
import Toast from "../../services/Toast";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "../../components/ProgressBar";
import MyTable from "../../components/MyTable";
import ProcessingButton from "../../components/ProcessingButton";
import MyCarousel from "../../components/MyCarousel";
import confirm from "../../components/Confirm";


/**
 * @component
 * @category Scenes
 */
class URLRemover extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            progress: 0
        }
        this.upload = this.upload.bind(this);
        this.spreadsheet = createRef();
        this.tableRef = createRef();
        this.editable = true;
        if (window.location.hash.match(/(results)/)) {
            this.props.history.push('/others/urlremover');
        }
        this.data = [[]];

        this.procNext = this.procNext.bind(this);
        this.areYouSure = this.areYouSure.bind(this);
    }

    getSpreadsheet() {
        return this.spreadsheet.current.Get();
    }

    async areYouSure() {
        if (
            !await confirm("Are you sure?", "Yes, wipe it up!", "No, let me check it again", {
                message: "You're about to remove some URLs from the system. This operation can't be undone. Would you like to proceed with this task?",
                enableEscape: false,
            })
        ) {
            return;
        }
        this.upload();
    }

    upload() {

        let items = [], cleanData = [];

        this.getSpreadsheet().options.editable = false;
        this.getSpreadsheet().getData().forEach((item) => {
            if (
                item[0].trim().length === 0 || item[1].trim().length === 0 ||
                item[0].match(/^[^\w-]$/i) || !item[1].match(/^https?:\/\/.+/)
            ) {
                return;
            }
            items.push({
                identifier: item[0].trim(),
                url: item[1].trim()
            });
            cleanData.push([item[0].trim(), item[1].trim()]);
        });

        if (items.length === 0) {
            this.getSpreadsheet().options.editable = true;
            Toast.error("No valid data was informed.");
            return;
        }

        this.getSpreadsheet().setData(cleanData);
        this.setState({
            saving: true,
            progress: 0,
        });

        this.packs = [];
        do {
            let fragment = items.splice(0, 25);
            this.packs.push(fragment);
        } while (items.length > 25);
        if (items.length > 0) {
            this.packs.push(items);
        }

        this.results = {
            items: [],
            steps: this.packs.length,
            pending: this.packs.length
        };
        this.tableRef.current.populate([]);
        this.procNext();
    }

    procNext() {

        if (this.packs.length === 0) {

            this.setState({
                saving: false,
            }, () => {
                this.tableRef.current.populate(this.results.items);
                this.props.history.push('/others/urlremover#results');
                this.getSpreadsheet().options.editable = true;
            });

            return;
        }

        let items = this.packs.shift();
        URLLookupService.delete({ items }).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while removing URLs");
                this.setState({
                    saving: false,
                }, () => {
                    this.getSpreadsheet().options.editable = true;
                });
                return;
            }

            result.items.forEach((item) => {
                this.results.items.push(item);
            });

            this.results.pending--;
            this.setState({
                progress: (this.results.steps - this.results.pending) * 100 / this.results.steps
            })
            this.procNext();
        });

    }

    render() {
        const key = window.location.hash.match(/#results$/) ? 1 : 0;

        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Others</Breadcrumb.Item>
                    <Breadcrumb.Item
                        active={key === 0}
                        onClick={() => {
                            if (this.state.saving) {
                                return;
                            }
                            this.props.history.push("/others/urlremover")
                        }}
                    >
                        URL Removal
                    </Breadcrumb.Item>
                    {key > 0 && (
                        <Breadcrumb.Item active>Results</Breadcrumb.Item>
                    )}
                </Breadcrumb>
                <MyCarousel activeIndex={key}>
                    <Carousel.Item>
                        <React.Fragment>
                            <Spreadsheet ref={this.spreadsheet} data={[[]]} options={{
                                minDimensions: [2, 10000],
                                lazyLoading: true,
                                tableOverflow: true,
                                columnResize: false,
                                minSpareRows: 1,
                                columns: [
                                    {
                                        type: 'text',
                                        title: 'Identifier',
                                        width: 150,
                                        align: "left"
                                    },
                                    {
                                        type: 'text',
                                        title: 'URL',
                                        width: 900,
                                        align: "left"
                                    }
                                ]
                            }} />
                            <Row>
                                <Col sm={6}>
                                    <ProcessingButton
                                        variant="primary"
                                        size="sm"
                                        processing={this.state.saving}
                                        processingLabel="Deleting ..."
                                        label="Delete"
                                        onClick={this.areYouSure}
                                    />
                                </Col>
                                {this.state.saving && (
                                    <Col>
                                        <ProgressBar completed={this.state.progress} bgcolor="#1f4503" />
                                    </Col>
                                )}
                            </Row>
                        </React.Fragment>
                    </Carousel.Item>
                    <Carousel.Item>
                        <MyTable
                            name="url-removal"
                            dynamic={false}
                            ref={this.tableRef}
                            sortBy="identifier"
                            sortOrder="asc"
                            headers={
                                [
                                    {
                                        width: "95px",
                                        field: "identifier",
                                        label: "Identifier",
                                        sortable: true,
                                        searchable: true
                                    },
                                    {
                                        width: "95px",
                                        field: "upc",
                                        label: "UPC",
                                        sortable: true,
                                        searchable: true
                                    },
                                    {
                                        field: "url",
                                        label: "URL",
                                        sortable: true,
                                        searchable: true
                                    },
                                    {
                                        width: "85px",
                                        field: "npd",
                                        label: "Autofeeder",
                                        hint: "An autofeeder constraint rule was automatically created for the URL",
                                        sortable: true,
                                        allowEmpty: true,
                                        options: [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]
                                    },
                                ]}
                            renderColumns={{
                                identifier: (row) => <Link className="link" to={`/product/${row.pid}`}>{row.identifier}</Link>,
                                url: (row) => <Form.Control
                                    type="text"
                                    value={row.url}
                                    readOnly={true}
                                />,
                                npd: {
                                    format: (row) => row.npd ? <FontAwesomeIcon
                                        style={{
                                            color: "#1f4503",
                                        }}
                                        icon={faCheck}
                                    /> : <FontAwesomeIcon
                                        style={{ color: "red" }}
                                        icon={faTimes}
                                    />,
                                    csv: (row) => row.npd === 1 ? 'Yes' : 'No',
                                    text: (row) => row.npd ? 1 : 0,
                                    className: "text-center"
                                },
                            }}
                        />
                    </Carousel.Item>
                </MyCarousel>
            </Container>
        );
    }
}
export default withRouter(URLRemover);
