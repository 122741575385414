import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Modal, Button, Table } from "react-bootstrap";
import { confirmable, createConfirmation } from "react-confirm";

class ConfirmOnboardingWebsite extends React.Component {
    render() {
        const {
            show,
            proceed,
            options,
        } = this.props;
        const selectWebsite = (e) => {
            proceed(parseInt(e.target.getAttribute('wid')));
        },
            yes = <FontAwesomeIcon icon={faCheck} style={{color: "green"}} />,
            no = <FontAwesomeIcon icon={faTimes} style={{color: "red"}} />;

        return (
            <Modal
                show={show}
                onHide={() => proceed(false)}
                backdrop="static"
                keyboard={true}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure we need a new website?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        We already have a website from the same domain, which we should consider using instead of creating a new one. Creating a new website implies costs. Kindly review the table below and confirm if we need a new website or not.
                    </p>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Website</th>
                                <th>Has Crawler</th>
                                <th>Recently Crawled Offers</th>
                                <th>URLs Mapped</th>
                                <th style={{ width: "1px" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {options.map((row, i) =>
                                <tr key={`row-option-${i}`}>
                                    <td>{row.id}</td>
                                    <td>{row.name}</td>
                                    <td className="text-center">{row.parser ? yes : no}</td>
                                    <td>{row.crawls === -1 ? '' : row.crawls}</td>
                                    <td>{row.urls === - 1 ? '' : row.urls}</td>
                                    <td>
                                        <Button
                                            variant="success"
                                            size="sm"
                                            onClick={selectWebsite}
                                            wid={row.id}>
                                            Use this
                                        </Button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => proceed(false)}
                        data-testid="confirm-btn-cancel"
                    >
                        Cancel, I'm not sure
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => proceed(true)}
                        data-testid="confirm-btn-ok"
                    >
                        Yes, I'm sure. Create a new one
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default function confirm(
    options = []
) {
    return createConfirmation(confirmable(ConfirmOnboardingWebsite))({
        options,
    });
}
