import React, { createRef } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Link, withRouter } from "react-router-dom";
import Brand from "../../services/BrandService";
import Toast from "../../services/Toast";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Emitter from "../../services/Emitter";
import MyTable from "../../components/MyTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import PickupWebsite from "../../components/PickupWebsite";
import prompt from "../../components/Prompt";
import Utils from "../../services/Utils";
import MySpinner from "../../components/MySpinner";
import { Menu, MenuItem } from "@szhsin/react-menu";


/**
 * @component
 * @category Scenes
 * @subcategory Brand
 */
class Restrictions extends React.Component {
    constructor(props) {
        super(props);
        this.onEnter = this.onEnter.bind(this);
        this.tableRef = createRef();
        this.state = {
            mode: '',
            blocked: false,
            showPickupWebsite: false,
            loading: true
        };
        this.handleChangeMode = this.handleChangeMode.bind(this);
        this.addRestriction = this.addRestriction.bind(this);
        this.removeRestriction = this.removeRestriction.bind(this);
        this.handleNewWebsites = this.handleNewWebsites.bind(this);
        this.skipWebsites = [];
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
    }

    componentDidMount() {
        Emitter.once("BRAND_RESTRICTIONS", this.onEnter);
    }

    componentWillUnmount() {
        Emitter.off("BRAND_RESTRICTIONS");
    }

    downloadSpreadsheet() {
        this.tableRef.current.CSV({
            filename: "restrictions-brand" + this.props.brandId + "-" + Utils.DateNow().replaceAll(' ', '-') + ".csv"
        })
    }

    onEnter() {
        Brand.restrictions(this.props.brandId).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of restrictions");
                return;
            }

            let blocked = data.websites.length,
                mode = data.mode,
                skipWebsites = data.websites.map((row) => row.id);

            this.setState({ blocked, mode, skipWebsites, loading: false }, () => {
                this.tableRef.current?.populate(data.websites);
            });
        });
    }

    handleChangeMode(evt) {
        let mode = evt.target.getAttribute("value");
        this.setState({ mode });
    }

    handleNewWebsites(evt) {
        const title = this.state.mode === 'whitelist' ? 'Add websites to whitelist' : 'Add websites to blacklist';
        prompt(title, "Add", "Cancel", '', '', {
            submitOnEnter: false
        }).then((list) => {
            let websites = list.value.split('\n').filter((value) => value.trim().length > 3 && value.indexOf('.') !== -1).map((value) => value.trim());
            if (!websites.length) {
                return;
            }

            this.setState({ blocked: true }, () => this.tableRef.current?.setLoading(true));

            Brand.addRestrictions(this.props.brandId, websites, this.state.mode).then((result) => {
                this.tableRef.current?.setLoading(false);
                if (result === false) {
                    Toast.error("Something went wrong while setting restriction");
                    return;
                }

                if (result.websites.length) {
                    this.tableRef.current.addRows(result.websites);
                    let skipWebsites = this.state.skipWebsites;
                    result.websites.forEach((website) => {
                        skipWebsites.push(website.id);
                    })
                    this.setState({ blocked: true, skipWebsites });
                }

                if (result.problems.length) {
                    prompt("Problems", "OK", null, 'The following websites could not be uniquely identified and added:', result.problems.join('\n'), { readOnly: true });
                }
            });

        }).catch(null);
    }

    removeRestriction(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);
        obj.row.deleting = true;
        this.tableRef.current.updateRow(obj.row);
        Brand.removeRestriction(this.props.brandId, obj.row.id).then((result) => {
            delete obj.row.deleting;
            if (!result) {
                Toast.error("Something went wrong while removing this restriction");
                this.tableRef.current.updateRow(obj.row);
                return;
            }
            this.tableRef.current.deleteRow(obj.row);

            let skipWebsites = this.state.skipWebsites;
            skipWebsites.splice(skipWebsites.indexOf(obj.row.id), 1);
            if (this.tableRef.current.data().length === 0) {
                this.setState({ blocked: false, skipWebsites });
            } else {
                this.setState({ skipWebsites });
            }
        });
    }

    addRestriction(website) {
        return new Promise(async (resolve, reject) => {
            Brand.addRestriction(this.props.brandId, website.id, this.state.mode).then((result) => {
                if (result === false) {
                    Toast.error("Something went wrong while setting restriction");
                    reject();
                } else {
                    Toast.ok("Website added successfully");
                    this.tableRef.current.addRow({
                        id: website.id,
                        name: website.display_name
                    });
                    let skipWebsites = this.state.skipWebsites;
                    skipWebsites.push(website.id);
                    this.setState({ blocked: true, skipWebsites });
                    resolve(true);
                }
            }).catch(reject);
        });
    }

    render() {

        return (
            <Container className="pt-4" >
                <PickupWebsite
                    title={this.state.mode === 'whitelist' ? 'Add website to whitelist' : 'Add website to blacklist'}
                    processLabel="Add"
                    processingLabel="Adding ..."
                    show={this.state.showPickupWebsite}
                    onClose={() => { this.setState({ showPickupWebsite: false }) }}
                    callbackPromise={this.addRestriction}
                    skip={this.state.skipWebsites}
                />
                <Row>
                    <Col sm={10}>
                        <b style={{
                            paddingRight: "10px"
                        }}>Mode:</b>
                        <Form.Check
                            inline
                            disabled={this.state.blocked}
                            checked={this.state.mode === 'whitelist'}
                            onChange={this.handleChangeMode}
                            type='radio'
                            value="whitelist"
                            name="restriction-mode"
                            label={`Allow only websites below`}
                        />
                        <Form.Check
                            inline
                            disabled={this.state.blocked}
                            checked={this.state.mode === 'blacklist'}
                            onChange={this.handleChangeMode}
                            type='radio'
                            value="blacklist"
                            name="restriction-mode"
                            label={`Block all websites below`}
                        />
                    </Col>
                    <Col sm={2} style={{
                        textAlign: "right"
                    }}>
                        <Button disabled={this.state.loading} onClick={this.downloadSpreadsheet} className="tdButton" size="sm" variant="success"><FontAwesomeIcon icon={faDownload} style={{ marginRight: "10px" }} />Download</Button>
                    </Col>
                </Row>
                <MyTable
                    name="brand-restrictions"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="name"
                    sortOrder="asc"
                    headers={
                        [
                            {
                                field: 'id',
                                label: "ID",
                                sortable: true,
                                searchable: true,
                                width: "75px"
                            },
                            {
                                field: 'name',
                                label: this.state.mode === 'whitelist' ? 'Allowed websites' : (this.state.mode === 'blacklist' ? 'Blocked websites' : 'Website'),
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: 'control',
                                width: "50px",
                                content: <Menu
                                    direction="left"
                                    style={{
                                        fontSize: "medium"
                                    }}
                                    menuButton={
                                        <Button
                                            disabled={this.state.mode === ''}
                                            size="sm"
                                            className="tdButton"
                                            variant="success"
                                        >
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>}>
                                    <MenuItem
                                        onClick={() => { this.setState({ showPickupWebsite: true }) }}>
                                        Single website
                                    </MenuItem>
                                    <MenuItem onClick={this.handleNewWebsites}>
                                        Multiple websites
                                    </MenuItem>
                                </Menu>
                            },
                        ]
                    }
                    renderColumns={{
                        name: (row) => <Link className="link" to={`/website/${row.id}`}>{row.name}</Link>,
                        control: {
                            format: (row) => row.deleting ? <MySpinner /> : <Button
                                onClick={this.removeRestriction}
                                size="sm"
                                className="tdButton"
                                variant="danger">
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>,
                            className: "text-center"
                        },
                    }
                    }
                    emptyLabel={this.state.mode === 'whitelist' ? 'All websites are allowed' : (this.state.mode === 'blacklist' ? 'No website is blocked' : '...')}
                />
            </Container>
        );
    }
}

export default withRouter(Restrictions);
