import React, { createRef } from "react";

import { Container, Breadcrumb, Button, Modal, Form, Row, Col, Accordion } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import "../../../../node_modules/currency-flags/dist/currency-flags.min.css";
import MyTable from "../../../components/MyTable";
import ProcessingButton from "../../../components/ProcessingButton";
import NPDService from "../../../services/NPDService";
import { faDownload, faEdit, faPlus, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Toast from "../../../services/Toast";
import WebsiteSearch from "../../../components/WebsiteSearch";
import ProductSearch from "../../../components/ProductSearch";
import confirm from "../../../components/Confirm";
import ManufacturerSearch from "../../../components/ManufacturerSearch";

/**
 * Autofeeder Constraints component
 * @component
 * @category Others
 */
class Autofeeder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            accordionKey: 1,
            edit: null,
            website: null,
            manufacturers: {},
            products: {},
            urls: [],
            url: '',
            canSubmit: false,
            submitting: false
        };
        this.tableRef = createRef();
        this.productRef = createRef();
        this.manufacturerRef = createRef();
        this.websiteRef = createRef();

        this.onHide = this.onHide.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSaveChanges = this.onSaveChanges.bind(this);
        this.onAccordionClick = this.onAccordionClick.bind(this);
        this.handleSelectWebsite = this.handleSelectWebsite.bind(this);
        this.handleSelectManufacturer = this.handleSelectManufacturer.bind(this);
        this.removeManufacturer = this.removeManufacturer.bind(this);
        this.handleSelectProduct = this.handleSelectProduct.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
        this.handleURLKeyPress = this.handleURLKeyPress.bind(this);
        this.handleURLChange = this.handleURLChange.bind(this);
        this.removeURL = this.removeURL.bind(this);
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
    }

    componentDidMount() {
        NPDService.autofeeder().then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while retrieving the autofeeder rules");
                return;
            }
            this.tableRef.current.populate(result);
            this.setState({ loading: false })
        })
    }

    onAccordionClick(evt) {
        let key = evt.target.parentNode.parentNode.getAttribute("accordionKey");
        this.setState({ accordion: key });
    }

    downloadSpreadsheet() {
        this.tableRef.current.CSV()
    }

    handleSelectWebsite(website) {
        website = website.length ? {
            'id': website[0]['id'],
            'name': website[0]['display_name'].toLowerCase()
        } : null;
        this.setState({ website, canSubmit: website !== null });
    }

    handleSelectManufacturer(manufacturer) {
        if (!manufacturer.length) {
            return;
        }
        this.manufacturerRef.current.clear();
        let manufacturers = this.state.manufacturers;
        manufacturers[manufacturer[0]['id']] = {
            'id': manufacturer[0]['id'],
            'name': manufacturer[0]['name']
        };
        this.setState({ manufacturers });
    }

    removeManufacturer(evt) {
        let target = evt.target;
        if (/path/i.test(target.nodeName)) {
            target = target.parentNode;
        }
        let id = target.getAttribute('mid'),
            manufacturers = this.state.manufacturers;
        delete manufacturers[id];
        this.setState({ manufacturers });
    }


    handleSelectProduct(product) {
        if (!product.length) {
            return;
        }
        this.productRef.current.clear();
        let products = this.state.products;
        products[product[0]['id']] = {
            'id': product[0]['id'],
            'upc': product[0]['identifier']
        };
        this.setState({ products });
    }

    removeProduct(evt) {
        let target = evt.target;
        if (/path/i.test(target.nodeName)) {
            target = target.parentNode;
        }
        let id = target.getAttribute('pid'),
            products = this.state.products;
        delete products[id];
        this.setState({ products });
    }

    handleURLKeyPress(event) {
        if (event.key === 'Enter') {
            let urls = this.state.urls;
            if (urls.indexOf(this.state.url) === -1) {
                urls.push(this.state.url);
            }
            this.setState({
                urls,
                url: ''
            })
        }
    }

    handleURLChange(evt) {
        this.setState({ url: evt.target.value })
    }

    removeURL(evt) {
        let target = evt.target;
        if (/path/i.test(target.nodeName)) {
            target = target.parentNode;
        }
        let index = target.getAttribute('index'),
            urls = this.state.urls;
        urls.splice(index, 1);
        this.setState({ urls });
    }

    onHide(evt) {
        if (this.state.saving) {
            return;
        }
        this.setState({ edit: null });
    }

    onAdd() {
        delete this.edit;
        this.setState({
            edit: 0,
            website: null,
            manufacturers: {},
            products: {},
            urls: [],
            url: '',
            canSubmit: false,
            submitting: false
        });
    }

    onEdit(evt) {
        this.edit = this.tableRef.current.getRowFromEvent(evt);
        var row = this.edit.row,
            manufacturers = {},
            products = {};

        row.manufacturers.forEach((row) => {
            manufacturers[row['id']] = row;
        });
        row.products.forEach((row) => {
            products[row['id']] = row;
        });

        this.setState({
            edit: row.id,
            website: row.website,
            manufacturers,
            products,
            urls: row.urls,
            url: '',
            canSubmit: true,
            submitting: false
        });
    }

    async onDelete(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);
        if (
            !(await confirm("Are you sure?", "Continue", "Cancel", {
                message:
                    "You're about to remove one set of settings for \"" +
                    obj.row.website.name +
                    '". This operation can\'t be undone. Do you want to continue?',
            }))
        ) {
            return;
        }

        this.tableRef.current.setLoading(true);
        NPDService.autofeeder_set({ id: obj.row.id, website: null }).then((result) => {
            this.tableRef.current.setLoading(false);
            if (!result) {
                Toast.error("Something went wrong while removing this rule.");
            } else {
                this.tableRef.current.deleteRow(obj.row);
                Toast.ok("Rule removed successfully");
            }
        });
    }

    onSaveChanges(evt) {
        let editMode = typeof this.state.edit === "number" && this.state.edit,
            payload = {
                id: this.state.edit,
                website: this.state.website,
                manufacturers: Object.values(this.state.manufacturers),
                products: Object.values(this.state.products),
                urls: this.state.urls
            };

        this.setState({ saving: true });

        if (editMode) {
            NPDService.autofeeder_set(payload).then((result) => {

                if (result === false) {
                    Toast.error("Something went wrong while saving changes.");
                    this.setState({ saving: false });
                    return;
                }
                var row = this.edit.row;
                row.website = payload.website;
                row.manufacturers = payload.manufacturers;
                row.products = payload.products;
                row.urls = payload.urls;
                this.tableRef.current.updateRow(row);
                this.setState({ edit: null, saving: false });
            })
        } else {

            NPDService.autofeeder_set(payload).then((result) => {

                if (result === false) {
                    Toast.error("Something went wrong while adding new rule.");
                    this.setState({ saving: false });
                    return;
                }

                payload['id'] = result;
                this.tableRef.current.addRow(payload);
                this.setState({ edit: null, saving: false });
            })
        }
    }


    render() {
        return (
            <Container className="pt-4">
                <span style={{
                    float: "right"
                }}
                >
                    <Button
                        size="sm"
                        className="tdButton"
                        onClick={this.downloadSpreadsheet}
                        disabled={this.state.loading}
                        variant="success"
                    >
                        <FontAwesomeIcon icon={faDownload} style={{
                            marginRight: "5px"
                        }} />
                        Download
                    </Button>
                </span>

                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Others</Breadcrumb.Item>
                    <Breadcrumb.Item>NPD</Breadcrumb.Item>
                    <Breadcrumb.Item active>Autofeeder Constraints</Breadcrumb.Item>
                </Breadcrumb>
                <Modal
                    size="lg"
                    show={this.state.edit !== null}
                    backdrop={true}
                    keyboard={true}
                    centered
                >
                    <Modal.Header closeButton onHide={this.onHide}>
                        <Modal.Title>
                            {typeof this.state.edit === "number" && this.state.edit ? "Edit" : "Add"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <Accordion defaultActiveKey={`${this.state.accordion}`} activeKey={`${this.state.accordion}`}>
                            <Accordion.Item accordionKey="1" eventKey="1">
                                <Accordion.Header onClick={this.onAccordionClick}>Website</Accordion.Header>
                                <Accordion.Body>
                                    <WebsiteSearch
                                        ref={this.websiteRef}
                                        onChange={this.handleSelectWebsite}
                                        defaultInputValue={this.state.website !== null ? [this.state.website['name']] : []}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item accordionKey="2" eventKey="2">
                                <Accordion.Header onClick={this.onAccordionClick}>Manufacturers</Accordion.Header>
                                <Accordion.Body>
                                    <ManufacturerSearch
                                        ref={this.manufacturerRef}
                                        onChange={this.handleSelectManufacturer}
                                    />
                                    <Container>
                                        <Row>
                                            {Object.values(this.state.manufacturers).map((manufacturer) => (
                                                <Col sm={3} key={`manufacturer-${manufacturer.id}`}><FontAwesomeIcon icon={faTimes} className="pointer" style={{ marginRight: "5px" }} onClick={this.removeManufacturer} mid={manufacturer.id} /> {manufacturer.name}</Col>
                                            ))}
                                        </Row>
                                    </Container>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item accordionKey="3" eventKey="3">
                                <Accordion.Header onClick={this.onAccordionClick}>Products</Accordion.Header>
                                <Accordion.Body>
                                    <ProductSearch
                                        ref={this.productRef}
                                        onChange={this.handleSelectProduct}
                                        includeArchivedProducts={false}
                                        includeDisabledBrands={false}
                                        placeholder="Add product"
                                        manufacturers={Object.values(this.state.manufacturers).map(row => row.id)}
                                        skip={Object.values(this.state.products).map(row => row.id)}
                                    />
                                    <Container>
                                        <Row>
                                            {Object.values(this.state.products).map((product) => (
                                                <Col sm={3} key={`product-${product.id}`}><FontAwesomeIcon icon={faTimes} className="pointer" style={{ marginRight: "5px" }} onClick={this.removeProduct} pid={product.id} /> {product.upc}</Col>
                                            ))}
                                        </Row>
                                    </Container>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item accordionKey="4" eventKey="4">
                                <Accordion.Header onClick={this.onAccordionClick}>URLs</Accordion.Header>
                                <Accordion.Body>
                                    <Form.Control
                                        onKeyPress={this.handleURLKeyPress}
                                        onChange={this.handleURLChange}
                                        disabled={this.state.edit === null}
                                        type="text"
                                        name="url"
                                        value={this.state.url}
                                    />
                                    <Container>
                                        <Row>
                                            {this.state.urls.map((url, index) => (
                                                <Col sm={12} key={`url-${index}`} style={{ display: "inline-flex" }}>
                                                    <FontAwesomeIcon icon={faTimes} style={{ position: "relative", top: "10px", marginRight: "15px" }} className="pointer" onClick={this.removeURL} index={index} />
                                                    <Form.Control
                                                        readOnly={true}
                                                        type="text"
                                                        value={url}
                                                    />
                                                </Col>
                                            ))}
                                        </Row>
                                    </Container>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </Modal.Body>
                    <Modal.Footer style={{
                        justifyContent: "center"
                    }}>
                        <Button disabled={this.state.saving} size="sm" variant="secondary" onClick={this.onHide}>Cancel</Button>
                        <ProcessingButton
                            size="sm"
                            disabled={!this.state.canSubmit}
                            processing={this.state.saving}
                            processingLabel="Saving ..."
                            label="Save"
                            onClick={this.onSaveChanges}
                        />
                    </Modal.Footer>
                </Modal >
                <MyTable
                    name="autofeeder-constraints"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="website"
                    sortOrder="asc"
                    headers={[
                        {
                            field: "website",
                            label: "Website",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "manufacturers",
                            label: "Manufacturers",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "products",
                            label: "Products",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "urls",
                            label: "URLs",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "control",
                            width: "87px",
                            content: <Button
                                onClick={this.onAdd}
                                size="sm"
                                className="tdButton"
                                variant="success">
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        }
                    ]}
                    renderColumns={{
                        website: {
                            format: (row) => <Link className="link" to={`/website/${row.website.id}`} target="_blank">(#{row.website.id}) {row.website.name}</Link>,
                            text: (row) => '(#' + row.website.id + ') ' + row.website.name,
                            csv: (row) => '(#' + row.website.id + ') ' + row.website.name
                        },
                        manufacturers: {
                            format: (row) => row.manufacturers.map((r) => <div><Link className="link" target="_blank" to={`/manufacturer/${r.id}`}>(#{r.id}) {r.name}</Link></div>),
                            text: (row) => row.manufacturers.map((r) => r.id + ' ' + r.name).join(';'),
                            csv: (row) => row.manufacturers.map((r) => '(#' + r.id + ') ' + r.name).join('; ')
                        },
                        products: {
                            format: (row) => row.products.map((r) => <div><Link className="link" target="_blank" to={`/product/${r.id}`}>{r.upc}</Link></div>),
                            text: (row) => row.products.map((r) => r.upc).join(';'),
                            csv: (row) => row.products.map((r) => r.upc).join('; ')
                        },
                        urls: {
                            format: (row) => row.urls.map((url) => <div><a className="link" target="_blank" rel="noreferrer" href={url}>{url}</a></div>),
                            text: (row) => row.urls.join(';'),
                            csv: (row) => row.urls.join('; ')
                        },
                        control: {
                            className: "text-center",
                            format: (row) => <React.Fragment>
                                <Button
                                    onClick={this.onEdit}
                                    size="sm"
                                    className="tdButton"
                                    variant="secondary">
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                                <Button
                                    onClick={this.onDelete}
                                    size="sm"
                                    className="tdButton"
                                    variant="danger">
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </React.Fragment>
                        }
                    }} />
            </Container >
        );
    }
}

export default withRouter(Autofeeder);
