import React, { createRef } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Link, withRouter } from "react-router-dom";
import Toast from "../../services/Toast";
import { Container, Table, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";
import Emitter from "../../services/Emitter";
import Utils from "../../services/Utils";
import WebsiteService from "../../services/WebsiteService";
import MyTable from "../../components/MyTable";


/**
 * @component
 * @category Scenes
 * @subcategory Website
 */
class CrawlingEffort extends React.Component {
    constructor(props) {
        super(props);
        this.onEnter = this.onEnter.bind(this);
        this.tableRef = createRef();
    }

    componentDidMount() {
        Emitter.once("WEBSITE_CRAWLING_EFFORT", this.onEnter);
    }

    componentWillUnmount() {
        Emitter.off("WEBSITE_CRAWLING_EFFORT");
    }

    onEnter() {
        this.timer = setTimeout(() => {
            Toast.info("This data is compiled on demand and it could take a few minutes to complete depending on how big this website is. Please, be patient :)", 15000);
        }, 2500);
        WebsiteService.metrics(this.props.websiteId).then((data) => {
            if (this.timer !== null) {
                window.clearTimeout(this.timer);
                this.timer = null;
            }
            if (data === false) {
                Toast.error("Something went wrong while loading this websites' expenses");
                this.tableRef.current?.populate([]);
                return;
            }

            var list = [],
                keys = Object.keys(data);

            keys.forEach((key) => {
                let row = data[key];
                let totalTime = 0,
                    totalPayload = 0,
                    totalStorage = 0,
                    totalRequests = 0,
                    totalSuccess = 0;

                var clients = Object.keys(row.clients);
                var allProxies = [];
                clients.forEach((client) => {
                    var proxies = Object.keys(row.clients[client]);
                    proxies.forEach((proxy) => {

                        if (allProxies.indexOf(proxy) === -1) {
                            allProxies.push(proxy);
                        }

                        totalTime += row.clients[client][proxy].delay.total;
                        totalPayload += row.clients[client][proxy].payload.total;
                        totalStorage += row.clients[client][proxy].storage.total;
                        totalRequests += row.clients[client][proxy].requests;
                        totalSuccess += row.clients[client][proxy].ok;

                    });
                });

                let entry = {
                    id: key,
                    name: row.name,
                    time: totalTime,
                    payload: totalPayload,
                    storage: totalStorage,
                    requests: totalRequests,
                    success: totalSuccess,
                    proxies: allProxies,
                    clients: row.clients,
                    products: row.products,
                };
                list.push(entry);
            })
            this.tableRef.current?.populate(list);
        });
    }

    render() {
        return (
            <Container className="pt-4">
                <MyTable
                    name="website-crawling-effort"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="requests"
                    sortOrder="desc"
                    headers={
                        [
                            {
                                field: "name",
                                label: "Brand",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "products",
                                label: "Products",
                                width: "100px",
                                hint: "Number of distinct products crawled yesterday",
                                sortable: true
                            },
                            {
                                field: "time",
                                label: "Time",
                                width: "85px",
                                hint: "Execution time",
                                sortable: true
                            },
                            {
                                field: "payload",
                                label: "Payload",
                                width: "85px",
                                hint: "Amount of data(HTML) retrieved in Mb",
                                sortable: true
                            },
                            {
                                field: "storage",
                                label: "Storage",
                                width: "85px",
                                hint: "Amount of data stored in S3 in Mb",
                                sortable: true
                            },
                            {
                                field: "requests",
                                label: "Requests",
                                width: "550px",
                                sortable: true
                            }
                        ]
                    }
                    renderColumns={{
                        name: (row) => <Link className="link" to={`/brand/${row.id}`}>(#{row.id}) {row.name}</Link>,
                        time: (row) => Utils.Seconds2Time(row.time),
                        payload: (row) => Utils.Megabytes(row.payload),
                        storage: (row) => Utils.Megabytes(row.storage),
                        requests: (row) => {
                            const nClients = Object.keys(row.clients).length,
                                empty = [];

                            for (var i = 0; i < 4 - nClients; i++) {
                                empty.push({});
                            }

                            var proxies = Utils.OrderProxies(row.proxies);

                            return (
                                <Table key={`table-w${row.id}`}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            {proxies.map((proxy) => (
                                                <th>{proxy}</th>
                                            ))}
                                            {empty.map(() => (
                                                <th />
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(row.clients).map((client) => (
                                            <tr>
                                                <td className="proxy-client">{client}</td>
                                                {proxies.map((proxy) => {
                                                    const successful = typeof row.clients[client][proxy] !== "undefined" && typeof row.clients[client][proxy].ok === "number" ? (row.clients[client][proxy].ok * 100 / row.clients[client][proxy].requests) : 0;
                                                    const payload = typeof row.clients[client][proxy] !== "undefined" && typeof row.clients[client][proxy].payload === "object" ? row.clients[client][proxy].payload.total : 0;
                                                    const time = typeof row.clients[client][proxy] !== "undefined" && typeof row.clients[client][proxy].delay === "object" ? row.clients[client][proxy].delay.total : 0;
                                                    const color = successful > 70 ? 'green' : 'red';
                                                    return (
                                                        typeof row.clients[client][proxy] === "undefined" ? <td /> : (
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                trigger={["hover", "focus"]}
                                                                overlay={
                                                                    <Popover>
                                                                        <PopoverBody>
                                                                            Success rate: {successful.toFixed(2)}%<br />
                                                                            Payload: {Utils.Megabytes(payload)}Mb<br />
                                                                            Execution: {Utils.Seconds2Time(time)}<br />
                                                                        </PopoverBody>
                                                                    </Popover>
                                                                }
                                                            >
                                                                <td className={color}>
                                                                    {row.clients[client][proxy].requests}
                                                                </td>
                                                            </OverlayTrigger>

                                                        )
                                                    )
                                                }
                                                )}
                                                {empty.map(() => (
                                                    <td />
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            )
                        }
                    }}
                />
            </Container>
        );
    }
}

export default withRouter(CrawlingEffort);