import axios from "axios";

import Config from "./Config";
import APIHelper from "./APIHelper";

let cancels = {};
const ProductService = {
    typeahead(search, opts) {
        if (typeof cancels['typeahead'] !== 'undefined') {
            cancels['typeahead'].cancel("Operation canceled due to new request.");
        }
        cancels['typeahead'] = axios.CancelToken.source();
        return axios
            .post(Config.API_URL + "product/search", {
                name: search,
                options: opts
            }, {
                cancelToken: cancels['typeahead'].token
            })
            .then(APIHelper.returnItemsOrEmptyArray)
            .catch((error) => {
                if (typeof error.message !== "undefined" && /Operation canceled/.test(error.message)) {
                    return null;
                }
                return [];
            });
    },
    getLegacyAttributes(id) {
        return axios
            .get(Config.API_URL + "product/" + id + "/attributes/legacy")
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    getAttributes(id) {
        return axios
            .get(Config.API_URL + "product/" + id + "/attributes")
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    getDictionaryKeys() {
        return axios
            .get(Config.API_URL + "product/attributes/dictionary/keys")
            .then(response => APIHelper.returnAttribute(response, 'dictionary'))
            .catch(APIHelper.returnFalse);
    },
    get(id) {
        return axios
            .get(Config.API_URL + "product/" + id)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    update(id, payload, config) {
        return axios
            .post(Config.API_URL + "product/" + id, payload, config)
            .then(response => APIHelper.returnAttribute(response, 'row'))
            .catch(APIHelper.returnFalse);
    },
    updateAttributes(id, payload) {
        return axios
            .put(Config.API_URL + "product/" + id + "/attributes", payload)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    updateLegacyAttributes(id, payload) {
        return axios
            .put(Config.API_URL + "product/" + id + "/attributes/legacy", payload)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    summary(id) {
        return axios
            .get(Config.API_URL + "product/" + id + "/summary")
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    urls(id) {
        return axios
            .get(Config.API_URL + "product/" + id + "/urls")
            .then(APIHelper.returnItems)
            .catch(APIHelper.returnFalse);
    },
    merchants(id) {
        return axios
            .get(Config.API_URL + "product/" + id + "/merchants")
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    prices(id) {
        return axios
            .get(Config.API_URL + "product/" + id + "/prices")
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    updatePrices(id, prices) {
        return axios
            .put(Config.API_URL + "product/" + id + "/prices", { prices })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    identifier(id) {
        return axios
            .get(Config.API_URL + "product/" + id + "/identifier")
            .then(response => APIHelper.returnAttribute(response, 'identifier', 0))
            .catch(APIHelper.returnFalse);
    }
};

export default ProductService;
