import React, { createRef } from "react";
import PropTypes from "prop-types";

import { Link, withRouter } from "react-router-dom";
import Brand from "../../services/BrandService";
import Toast from "../../services/Toast";
import { Container, Button, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";
import Emitter from "../../services/Emitter";
import Memory from "../../services/MemoryService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faLink, faPlus, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import Utils from "../../services/Utils";
import UserService from "../../services/UserService";
import { Menu, MenuItem } from "@szhsin/react-menu";
import confirm from "../../components/Confirm";
import PickupUser from "../../components/PickupUser";
import MyTable from "../../components/MyTable";
import MySpinner from "../../components/MySpinner";

/**
 * @component
 * @category Scenes
 * @subcategory Brand
 */
class Users extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPickupUser: false
        }
        this.onEnter = this.onEnter.bind(this);
        this.onChangeRoleRequest = this.onChangeRoleRequest.bind(this);
        this.handleDisconnect = this.handleDisconnect.bind(this);
        this.onCreateNewUser = this.onCreateNewUser.bind(this);
        this.onConnectUser = this.onConnectUser.bind(this);
        this.addBrand = this.addBrand.bind(this);
        this.tableRef = createRef();
        this.skipUsers = {};
    }

    componentDidMount() {
        Emitter.once("BRAND_USERS", this.onEnter);
    }

    componentWillUnmount() {
        Emitter.off("BRAND_USERS");
    }

    onEnter() {
        Brand.users(this.props.brandId).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of users");
                this.tableRef.current?.populate([]);
                return;
            }
            this.skipUsers = {};
            data.forEach((row) => {
                this.skipUsers[row.id] = true;
            });
            this.tableRef.current?.populate(data);
        });
    }

    onChangeRoleRequest(evt) {

        let obj = this.tableRef.current.getRowFromEvent(evt);

        evt.preventDefault();
        evt.stopPropagation();

        const newRole = obj.row.role_id === 1 ? 2 : 1;

        obj.row.updating = true;
        this.tableRef.current.updateRow(obj.row);
        UserService.changeRole(obj.row.id, this.props.brandId, newRole).then((result) => {
            delete obj.row.updating;
            if (result) {
                obj.row.role_id = newRole;
                Toast.ok("Role changed successfully");
            } else {
                Toast.error("Something went wrong while changing the role");
            }
            this.tableRef.current.updateRow(obj.row)
        });
    }

    onCreateNewUser() {
        Memory.Set("NEW_USER_BRAND_ID", this.props.brandId);
        this.props.history.push("/user/0");
    }

    onConnectUser() {
        this.setState({ showPickupUser: true });
    }

    addBrand(user) {
        return new Promise(async (resolve, reject) => {
            UserService.addBrand(user.id, [this.props.brandId], false).then((result) => {
                if (result === false) {
                    Toast.error("Something went wrong while connecting user");
                    reject();
                } else {
                    Toast.ok("User connected to the brand successfully");
                    let user = result.user;
                    user.role_id = result.brands[0].role_id;
                    this.tableRef.current.addRow(user);
                    this.skipUsers[user.id] = true;
                    resolve(true);
                }
            }).catch(reject);
        });
    }

    async handleDisconnect(evt) {

        let obj = this.tableRef.current.getRowFromEvent(evt);
        const shouldProcessed = await confirm("Are you sure?", "Yes", "No", { message: "Do you really want to disconnect this user?" });

        if (!shouldProcessed) {
            return;
        }

        obj.row.deleting = true;
        this.tableRef.current.updateRow(obj.row);
        UserService.removeBrand(obj.row.id, this.props.brandId).then((result) => {
            if (result) {
                this.tableRef.current.deleteRow(obj.row);
                delete this.skipUsers[obj.row.id];
            } else {
                Toast.error("Failed disconnecting user from brand");
            }
        });
    }


    render() {
        const skipUsers = Object.keys(this.skipUsers).map((id) => parseInt(id));
        return (
            <Container className="pt-4">
                <PickupUser
                    show={this.state.showPickupUser}
                    onClose={() => { this.setState({ showPickupUser: false }) }}
                    callbackPromise={this.addBrand}
                    skip={skipUsers}
                />
                <MyTable
                    name="brand-users"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="name"
                    sortOrder="asc"
                    headers={
                        [
                            {
                                field: "name",
                                label: "Name",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "email",
                                label: "E-mail",
                                sortable: true,
                                searchable: true,
                            },
                            {
                                field: "role_id",
                                label: "Role",
                                width: "100px",
                                sortable: true,
                                allowEmpty: true,
                                options: [{ label: 'Admin', value: '2' }, { label: 'Viewer', value: '1' }]
                            },
                            {
                                field: "user_active",
                                label: "Active",
                                width: "100px",
                                sortable: true,
                            },
                            {
                                field: "control",
                                width: "50px",
                                content: <Menu
                                    direction="left"
                                    menuButton={
                                        <Button
                                            size="sm"
                                            variant="success"
                                            className="tdButton"
                                        >
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>}>
                                    <MenuItem mode="connect" onClick={this.onConnectUser}>
                                        <FontAwesomeIcon
                                            icon={faLink}
                                            style={{
                                                marginRight: "10px",
                                            }} />
                                        Connect existing user
                                    </MenuItem>
                                    <MenuItem disabled={true} mode="create" onClick={this.onCreateNewUser}>
                                        <FontAwesomeIcon
                                            icon={faFile}
                                            style={{
                                                marginRight: "10px",
                                            }} />
                                        Create new user
                                    </MenuItem>
                                </Menu >
                            }
                        ]
                    }
                    renderColumns={{
                        name: (row) => <Link className="link" to={`/user/${row.id}#brands`}>(#{row.id}) {row.name}</Link>,
                        role_id: {
                            format: (row) => {
                                if (!row.updating) {
                                    return <OverlayTrigger
                                        placement="bottom"
                                        trigger={["hover", "focus"]}
                                        overlay={
                                            <Popover>
                                                <PopoverBody>Switch Role</PopoverBody>
                                            </Popover>
                                        }>
                                        <a href="#users" onClick={this.onChangeRoleRequest} className="link">{Utils.UserRoleName(row.role_id)}</a>
                                    </OverlayTrigger>
                                }
                                return <MySpinner />
                            },
                            className: "text-center"
                        },
                        user_active: {
                            format: (row) => {
                                if (row.user_active) {
                                    return <FontAwesomeIcon
                                        style={{
                                            color: "green",
                                        }}
                                        icon={faCheck}
                                    />
                                }
                                return <FontAwesomeIcon
                                    style={{ color: "red" }}
                                    icon={faTimes}
                                />
                            },
                            className: "text-center"
                        },
                        control: (row) => {
                            if (row.deleting) {
                                return <MySpinner />
                            }
                            return <OverlayTrigger
                                placement="left"
                                trigger={["hover", "focus"]}
                                overlay={
                                    <Popover>
                                        <PopoverBody>Disconnect user from brand</PopoverBody>
                                    </Popover>
                                }>
                                <Button
                                    disabled={row.status === "pending"}
                                    size="sm"
                                    variant="outline-secondary"
                                    className="tdButton"
                                    onClick={this.handleDisconnect}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </OverlayTrigger>
                        }
                    }}
                />
            </Container >
        );
    }
}

Users.propTypes = {
    /** Brand ID */
    brandId: PropTypes.number.isRequired,
};

export default withRouter(Users);
