import React from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

class SelectRows extends React.Component {
    constructor(props) {
        super(props);
        this.checkAll = this.checkAll.bind(this);
        this.checkNone = this.checkNone.bind(this);
        this.checkInvert = this.checkInvert.bind(this);
        this.switch = this.switch.bind(this);
    }

    checkAll() {
        this.props.onSelect('all');
    }

    checkNone() {
        this.props.onSelect('none');
    }

    checkInvert() {
        this.props.onSelect('invert');
    }

    switch(evt) {
        if (evt.target.checked) {
            this.checkAll();
            return;
        }
        this.checkNone();
    }

    render() {
        const checked = this.props.state !== 'none';
        return (
            <div style={{ display: "inline-flex" }}>
            {this.props.state !== 'partial' && (
                <Form.Check
                    disabled={this.props.disabled}
                    type="checkbox"
                    className="lg select-rows"
                    checked={checked}
                    variant="secondary"
                    onChange={this.switch}
                />
            )}
            {this.props.state === 'partial' && (
                <Menu
                    direction="right"
                    menuButton={
                        <Form.Check
                            disabled={this.props.disabled}
                            type="checkbox"
                            className="lg select-rows select-rows-partial"
                            checked={true}
                            variant="secondary"
                        />
                    }>
                    <MenuItem onClick={this.checkAll}>
                        All
                    </MenuItem>
                    <MenuItem onClick={this.checkNone}>
                        None
                    </MenuItem>
                    <MenuItem onClick={this.checkInvert}>
                        Invert
                    </MenuItem>
                </Menu >
            )}
                <Menu
                    direction="right"
                    menuButton={
                        <Button
                            disabled={this.props.disabled}
                            title="Bulk Action"
                            variant="secondary"
                            size="sm"
                            className="select-rows-action-btn"
                        >
                            <FontAwesomeIcon icon={faCaretDown} />
                        </Button>
                    }>
                    {this.props.actions.map((action, index) => (
                        <MenuItem
                            key={`action-${index}-${action.label}`}
                            onClick={action.onClick}
                            disabled={this.props.state === 'none'}
                        >
                            {action.label}
                        </MenuItem>
                    ))}
                </Menu >
            </div>
        )
    }
}

SelectRows.propTypes = {
    state: PropTypes.oneOf(['all', 'partial', 'none']),
    actions: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
};

SelectRows.defaultProps = {
    state: 'none',
    disabled: false,
    actions: [
        {
            label: 'Action',
            onClick: () => {
                console.log('Action')
            }
        }
    ],
    onSelect: (mode) => {
        console.log('onSelect => ' + mode)
    },
};

export default SelectRows;
