import React, { createRef } from "react";
import PropTypes from "prop-types";
import MerchantService from "../services/MerchantService";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

/**
 * Typeahead component for searching for Merchants
 * @component
 */
class MerchantSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        }
        this.ref = createRef();
    }

    static formatOptionLabel = (row) => {
        let label = '';
        if (typeof row.seller_id === "string" && row.seller_id.length) {
            if (typeof row.name === "string" && row.name.length) {
                label = `(${row.seller_id}) ${row.name}`;
            } else {
                label = row.seller_id;
            }
        } else if (typeof row.name === "string" && row.name.length) {
            label = row.name;
        }
        return `#${row.id} ${label}`;
    }

    handleSearch = (query) => {
        this.setState({
            isLoading: true,
        });
        MerchantService.typeahead(query, this.props.previous_names, this.props.marketplaceId).then((options) => {
            if (options === null) {
                // this request was canceled due to a change in the typeahead component
                return;
            }
            let list = [];
            options.forEach((row) => {
                row.seller_id = typeof row.seller_id === "string" ? (row.seller_id === "null" ? "" : row.seller_id) : "";
                if (this.props.skip.indexOf(row.id) === -1) {
                    row.combined = MerchantSearch.formatOptionLabel(row);
                    list.push(row);
                }
            });
            this.setState({
                isLoading: false,
                options: list,
            });
        });
    };

    clear = () => {
        this.ref.current.clear();
    }

    render() {
        const filterBy = () => true;

        return <AsyncTypeahead
            {...this.state}
            ref={this.ref}
            filterBy={filterBy}
            labelKey="combined"
            id="merchant-search"
            maxResults={10}
            minLength={2}
            onSearch={this.handleSearch}
            onChange={this.props.onChange}
            disabled={this.props.disabled}
            onFocus={this.props.onFocus}
            defaultSelected={this.props.defaultSelected}
            selected={this.props.selected}
            placeholder="Search for a merchant..."
            renderMenuItemChildren={(option) => (
                <div
                    key={`mer${option.id}`}
                >
                    <span className="colHead">id:</span>
                    <span className="colValue">
                        {option.id}
                    </span>
                    {option.seller_id.length > 0 && (
                        <React.Fragment>
                            <span className="colHead">seller_id:</span>
                            <span className="colValue">
                                {option.seller_id}
                            </span>
                        </React.Fragment>
                    )}
                    <span className="colHead">name:</span>
                    <span className="colValue">
                        {option.name}
                    </span>
                    {typeof option.previous === "string" && option.previous.length > 0 && (
                        <React.Fragment>
                            <span className="colHead">former:</span>
                            <span className="colValue">
                                {option.previous}
                            </span>

                        </React.Fragment>
                    )}
                    {this.props.marketplaceId === 0 && (
                        <React.Fragment>
                            <span className="colHead">website:</span>
                            <span className="colValue">
                                {option.website}
                            </span>
                            <span className="colHead">type:</span>
                            <span className="colValue">
                                {option.type_id === 1 ? 'Online Retailer' : "Marketplace Seller"}
                            </span>
                        </React.Fragment>
                    )}
                </div>
            )}
            useCache={false}
        />
    }
}

MerchantSearch.propTypes = {
    /**  */
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    previous_names: PropTypes.bool.isRequired,
    skip: PropTypes.arrayOf(PropTypes.number),
    disabled: PropTypes.bool,
    selected: PropTypes.arrayOf(PropTypes.object),
    marketplaceId: PropTypes.number,
};

MerchantSearch.defaultProps = {
    onChange: (values) => {
        console.log("Merchant has changed", values);
    },
    onFocus: () => { },
    previous_names: false,
    skip: [],
    disabled: false,
    selected: null,
    marketplaceId: 0,
};

export default MerchantSearch;
