import axios from "axios";

import Config from "./Config";
import APIHelper from "./APIHelper";

const GenericService = {
    summaryDB() {
        return axios
            .get(Config.API_URL + "summary/db")
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    summaryBrands() {
        return axios
            .get(Config.API_URL + "summary/brands")
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    summaryWebsites(number = null) {
        return axios
            .get(Config.API_URL + "summary/websites" + (number !== null ? "/" + number : ''))
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    version() {
        return axios
            .get(Config.API_URL + "version")
            .then((response) => {
                if (APIHelper.checkStatus(response)) {
                    return {
                        ts: response.data.ts,
                        hash: response.data.hash
                    }
                }
                return false;
            })
            .catch(APIHelper.returnFalse);
    },
    trackedProducts() {
        return axios
            .get(Config.API_URL + "summary/tracked/products")
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    trackedBrands() {
        return axios
            .get(Config.API_URL + "summary/tracked/brands")
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    reviewsPerDay() {
        return axios
            .get(Config.API_URL + "summary/reviews")
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    getManagers() {
        return axios
            .get(Config.API_URL + "users/managers")
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    getAllScheduling() {
        return axios
            .get(Config.API_URL + "scheduler/rule")
            .then(APIHelper.returnResults)
            .catch(APIHelper.returnFalse);
    },
    updateCrawlingScheduler(id, row) {
        return axios
            .post(Config.API_URL + "scheduler/rule/" + id, row)
            .then(APIHelper.returnResults)
            .catch(APIHelper.returnFalse);
    },
    getPDDistribution() {
        return axios
            .get(Config.API_URL + "others/pd")
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    getPDOvertime() {
        return axios
            .get(Config.API_URL + "others/pd/overtime")
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    getProductDiscoveryAnalyzerReport(brands) {
        return axios
            .post(Config.API_URL + "others/pd/analyzer", {brands})
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    cbsHelper(data) {
        return axios
            .post(Config.API_URL + "others/jira-ticket", data)
            .then((response) => {
                return {
                    success: APIHelper.checkStatus(response),
                    ticket: response.data.ticket ?? '',
                };
            })
            .catch(APIHelper.returnFalse);
    }
};

export default GenericService;
