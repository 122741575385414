import React, { createRef } from "react";
import PropTypes from "prop-types";

import { Container, Button } from "react-bootstrap";
import "../../../node_modules/currency-flags/dist/currency-flags.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Emitter from "../../services/Emitter";
import Toast from "../../services/Toast";
import confirm from "../../components/Confirm";
import { Link } from "react-router-dom";
import MerchantService from "../../services/MerchantService";
import LinkMerchant from "../../components/LinkMerchant";
import MyTable from "../../components/MyTable";

/**
 * Linked Channels component
 * @component
 * @category Scenes
 * @subcategory Merchant
 */
class Linked extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState();
        this.onEnter = this.onEnter.bind(this);
        this.handleRemoveLink = this.handleRemoveLink.bind(this);
        this.tableRef = createRef();
    }

    initialState() {
        return {
            id: 0,
            loading: true,
            addNew: false,
            skipMerchants: []
        };
    }

    componentWillUnmount() {
        Emitter.off("MERCHANT_LINKED");
    }

    componentDidMount() {
        Emitter.on("MERCHANT_LINKED", this.onEnter);
        Emitter.on("MERCHANT_RESET", () => {
            this.setState(this.initialState());
        });
    }

    onEnter() {
        if (this.state.id === this.props.merchantId) {
            return;
        }

        this.tableRef.current.setLoading(true);
        MerchantService.linkedChannels(this.props.merchantId).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while retrieving this merchant's linked channels");
                return;
            }
            this.setState({ id: this.props.merchantId, skipMerchants: data.map((row) => row.id) });
            this.tableRef.current?.populate(data);
        });
    }

    async handleRemoveLink(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);

        if (
            !(await confirm("Are you sure?", "Continue", "Cancel", {
                message:
                    "You're about to remove a link between two merchants and this operation can't be undone. Do you want to continue?"
            }))
        ) {
            return;
        }

        this.tableRef.current.setLoading(true);
        MerchantService.removeLinkedChannel(
            this.props.merchantId,
            obj.row.id,
        ).then((result) => {
            if (result) {
                Toast.ok("Link removed successfully");
                this.setState({ id: 0 }, this.onEnter);
            } else {
                Toast.error("Something went wrong while removing the link between the two merchants");
            }
        });
    }

    render() {

        if (this.state.disabled) {
            return (
                <Container className="pt-4">
                    This website is not semi-automatic.
                </Container>
            );
        }
        return (
            <Container className="pt-4" >
                <LinkMerchant
                    merchantId={this.props.merchantId}
                    show={this.state.addNew}
                    onClose={(changedSomething) => {
                        let changes = { addNew: false },
                            callback = null;
                        if (changedSomething) {
                            changes.id = 0;
                            callback = this.onEnter;
                        }
                        this.setState(changes, callback)
                    }}
                    skip={this.state.skipMerchants}
                />
                <MyTable
                    name="merchant-linked"
                    ref={this.tableRef}
                    dynamic={false}
                    sortBy="name"
                    sortOrder="asc"
                    headers={[
                        {
                            field: 'name',
                            label: 'Name',
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: 'website_name',
                            label: "Website",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "control",
                            width: "50px",
                            content: <Button className="tdButton" onClick={() => { this.setState({ addNew: true }) }}>
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        }]
                    }
                    renderColumns={{
                        control: {
                            className: "text-center",
                            format: (row) => <Button className="tdButton" variant="outline-danger" onClick={this.handleRemoveLink}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        },
                        name: (row) => <Link className="link" to={`/merchant/${row.id}`}>{`(#${row.id}) ${row.name}`}</Link>,
                        website_name: (row) => <Link className="link" to={`/website/${row.website_id}`}>{`(#${row.website_id}) ${row.website_name}`}</Link>
                    }}
                />
            </Container>
        );
    }
}

Linked.propTypes = {
    /** Merchant ID */
    merchantId: PropTypes.number.isRequired,
};

export default Linked;
