import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import Emitter from "../services/Emitter";

/**
 * Form.Control element which triggers onChange after a given delay
 * @component
 */
class DelayedInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            last: this.props.value,
            value: this.props.value,
            typingTimeout: 0,
            delay: this.props.delay,
        };
        if (props.enableEmmit) {
            this.onListen = this.onListen.bind(this);
            Emitter.on(`delayed-input-${props.field}`, this.onListen);
        }
    }

    onListen = (newValue) => {
        this.setState({ value: newValue, last: newValue });
    }

    componentWillUnmount() {
        if (this.props.enableEmmit) {
            Emitter.off(`delayed-input-${this.props.field}`)
        }
    }

    handleKeyPress = (event) => {
        if (this.props.onHitEnterOnly && event.key === 'Enter') {
            event.stopPropagation();
            event.preventDefault();
            if (this.state.last !== this.state.value) {
                let target = event.target;
                this.setState({ last: this.state.value }, () => {
                    this.props.onChange(this.props.field, this.state.value, target);
                });
            }
        }
    }

    onChange = (event) => {
        const self = this;
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        let target = event.target;

        if (this.props.onHitEnterOnly) {
            self.setState({
                value: target.value,
            });
            return;
        }

        if (
            target.value.length < self.props.minLength && target.value.length > 0
        ) {
            self.setState({
                value: target.value,
            });
            return;
        }

        self.setState({
            value: target.value,
            typingTimeout: setTimeout(function () {
                if (self.state.last !== self.state.value) {
                    self.props.onChange(self.props.field, self.state.value, target);
                }
                self.setState({ last: self.state.value });
            }, this.state.delay),
        });
    };

    render() {
        return <Form.Control
            data-testid="delayed-input"
            as={this.props.as}
            style={this.props.style}
            value={this.state.value}
            type="text"
            onChange={this.onChange}
            onKeyPress={this.handleKeyPress}
            disabled={this.props.disabled}
        />;
    }
}

DelayedInput.propTypes = {
    /**  */
    delay: PropTypes.number,
    minLength: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    field: PropTypes.string.isRequired,
    value: PropTypes.string,
    as: PropTypes.string,
    style: PropTypes.object,
    onHitEnterOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    // enableEmmit will allow us controlling the value of this component
    // though messaging/Emmit. This should not be enabled unless we need to do
    // such things
    enableEmmit: PropTypes.bool.isRequired
};

DelayedInput.defaultProps = {
    /** Represents the delay between each user input that should trigger onChange callback  */
    delay: 750,
    /** Minimum chars required to triggering onChange */
    minLength: 2,
    /** onChange callback function */
    onChange: (field, newValue) => {
        console.log(field + " has changed to " + newValue);
    },
    /** Field name */
    field: "text",
    value: "", // initial value 
    as: "input",
    style: {},
    onHitEnterOnly: false,
    enableEmmit: false,
    disabled: false
};

export default DelayedInput;
