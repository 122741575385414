import React from "react";
import PropTypes from "prop-types";
import sortable from "../images/sort.png";
import sorting_asc from "../images/sort-asc.png";
import sorting_desc from "../images/sort-desc.png";
import { OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";

/**
 * Show a TH component with a sorting icon
 * @component
 */
class TableHeaderCell extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        let newOrder = "asc";
        if (this.props.field === this.props.sort.field) {
            newOrder = this.props.sort.order === "asc" ? "desc" : "asc";
        }
        this.props.onChange(this.props.field, newOrder);
    }

    render() {
        let style = {
            backgroundPosition: "center right",
            backgroundRepeat: "no-repeat",
            cursor: "pointer",
            ...this.props.style
        };
        if (
            typeof this.props.width === "string" &&
            this.props.width.length > 0
        ) {
            style.width = this.props.width;
        }

        if (this.props.sortable) {
            if (this.props.field === this.props.sort.field) {
                style.color = "#9dff00";
                if (this.props.sort.order === "asc") {
                    style.backgroundImage = "url(" + sorting_asc + ")";
                } else {
                    style.backgroundImage = "url(" + sorting_desc + ")";
                }
            } else {
                style.backgroundImage = "url(" + sortable + ")";
            }
        }

        let Th = (
            <th
                data-testid={`field-${this.props.field}`}
                key={`th${this.props.field}`}
                style={style}
                className={`sorting ${this.props.sort.field === this.props.field
                    ? this.props.sort.order
                    : ""
                    }`}
                onClick={this.handleClick}
                colSpan={this.props.colSpan}
                onContextMenu={this.props.onContextMenu}
                onMouseOver={this.props.onMouseOver}
            >
                {this.props.label}
            </th>
        )

        if (typeof this.props.hint === "string" && this.props.hint.length > 0) {
            return (
                <OverlayTrigger
                    placement="top"
                    trigger={["hover", "focus"]}
                    overlay={
                        <Popover>
                            <PopoverBody>{this.props.hint}</PopoverBody>
                        </Popover>
                    }
                >
                    {Th}
                </OverlayTrigger>)
        }

        return Th;
    }
}

TableHeaderCell.defaultProps = {
    sortable: true,
    colSpan: 1,
    style: {}
};

TableHeaderCell.propTypes = {
    /** Should the "width" of this component be fixed? If so, how? */
    width: PropTypes.string,
    /** Is this a sortable field? */
    sortable: PropTypes.bool.isRequired,
    /** Field's label */
    label: PropTypes.string.isRequired,
    /** Field's name */
    field: PropTypes.string.isRequired,
    /** Current sort direction (asc or desc) */
    sort: PropTypes.object.isRequired,
    /** onChange sorting direction callback function */
    onChange: PropTypes.func.isRequired,
    hint: PropTypes.string,
    colSpan: PropTypes.number,
    style: PropTypes.object,
};

export default TableHeaderCell;
