import React, { createRef } from "react";

import {
    Form,
    Carousel,
    Container,
    Col,
    Row,
    Breadcrumb,
    Dropdown,
    DropdownButton,
    ListGroup,
    Button,
    Modal
} from "react-bootstrap";

import { Link, withRouter } from "react-router-dom";
import MyCarousel from "../../components/MyCarousel";
import {
    faCamera,
    faTimes,
    faExternalLinkSquareAlt,
    faPlus,
    faDownload,
    faUpload,
    faSearch,
    faTools,
    faBug,
    faClipboard
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyTable from "../../components/MyTable";
import MySpinner from "../../components/MySpinner";
import ProductsTrends from "../../services/ProductsTrendsService";
import Toast from "../../services/Toast";
import ProcessingButton from "../../components/ProcessingButton";
import SettingsService from "../../services/SettingsService";
import { Menu, MenuItem } from "@szhsin/react-menu";
import BrandsFilter from "../../components/BrandsFilter";
import {TICKET_TYPES, confirm as ticket} from "../../components/JIRATicket";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = createRef();
        this.state = {
            selectedBrands: [],
            selectedColumn: null,
            selectedOperator: null,
            selectedValue: '',
            conditions: [],
            downloading: false,
            selectedRange: 90,
            dataLoaded: false,
            dataLoading: false,
            showModal: false,
            inaccuracyNotes: '',
            selectedRow: null,
            expectedPrice: '',
            showImageModal: false,
            imageLoaded: false,
        };

        this.baseUrl = 'https://api.trackstreet.com/v1/screenshot-image?ss=';
        this.Conditions = this.Conditions.bind(this);
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
        this.handleReportInaccuracy = this.handleReportInaccuracy.bind(this);
        this.handleOpenImageModal = this.handleOpenImageModal.bind(this);

    }

    DiffRange = () => {
        const handleRangeChange = (e) => {
            this.setState({selectedRange: e.target.value})
        }

        return (
            <React.Fragment>
                <Row>
                    <Col>Difference Percentage: { this.state.selectedRange }%</Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Range disabled={this.state.dataLoading} max="100" min="0"  defaultValue="90" step="1" onSelect={handleRangeChange} onChange={ handleRangeChange }></Form.Range>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    Conditions = () => {
        const handleColumnSelect = (key) => {
            this.setState({ selectedColumn: columns.filter((item) => item.key === key)[0] });
        };
        const handleOperatorSelect = (key) => {
            this.setState({ selectedOperator: operators.filter((item) => item.key === key)[0] });
        };

        const handleAddCondition = () => {
            if (this.state.selectedColumn && this.state.selectedOperator && this.state.selectedValue) {
                this.setState({conditions: [...this.state.conditions, ...[
                        {column: this.state.selectedColumn, operator: this.state.selectedOperator, value: this.state.selectedValue}
                    ]]});
                this.setState({selectedColumn: null});
                this.setState({selectedOperator: null});
                this.setState({selectedValue: ''});
            }

        }

        const handleRemoveCondition = e => {
            e.preventDefault()
            let array = [...this.state.conditions];
            array.splice(e.target.parentNode.getAttribute('value'), 1);
            this.setState({conditions: array});
        }

        const columns = [
            { key: 'product_name', value: 'Product Title' },
            { key: 'merchant_name', value: 'Merchant' },
            { key: 'website_name', value: 'Marketplace' },
            { key: 'upc', value: 'UPC' },
            { key: 'sku', value: 'SKU' },
            { key: 'bdo', value: 'Is BDO' },
            { key: 'upc_lookup', value: 'UPC Search' },
            { key: 'bucket', value: 'Bucket' }
        ];

        const operators = [
            { key: 'equals-to', value: 'Equals To', operator: '=' },
            { key: 'different-from', value: 'Different From', operator: '<>' },
            { key: 'like', value: 'Like', operator: 'like' },
            { key: 'not-like', value: 'Not Like', operator: 'not like' }
        ];


        return (
            <React.Fragment>
                <Row>
                    <Col md={3}>
                        <DropdownButton
                            disabled={this.state.dataLoading}
                            onSelect={handleColumnSelect}
                            title={this.state.selectedColumn?.value || 'Select one'}>
                            {columns.map((item, index) => {
                                return (
                                    <Dropdown.Item key={index} eventKey={item.key}>
                                        {item.value}
                                    </Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </Col>
                    <Col md={3}>
                        <DropdownButton
                            disabled={this.state.dataLoading}
                            onSelect={handleOperatorSelect}
                            title={this.state.selectedOperator?.value || 'Select one'}>
                            {operators.map((item, index) => {
                                return (
                                    <Dropdown.Item key={index} eventKey={item.key}>
                                        {item.value}
                                    </Dropdown.Item>
                                );
                            })}
                        </DropdownButton>
                    </Col>
                    <Col md={5}>
                        <Form.Control
                            disabled={this.state.dataLoading}
                            name="conditionValue"
                            value={this.state.selectedValue}
                            onChange={ e => this.setState({selectedValue: e.target.value})}
                            type="text"
                        />
                    </Col>
                    <Col md={1}>
                        <FontAwesomeIcon className={this.state.dataLoading ? 'd-none' : 'cursor'} role="button" onClick={handleAddCondition} icon={faPlus} style={{color: "green", marginTop: "10px"}} />

                    </Col>
                </Row>
                <Row>
                    <ListGroup as="ol" className={this.state.conditions.length > 0 ? 'pt-4 pb-2 px-4' : ''}>
                        {[...this.state.conditions].map((item, index) => {
                            return (
                                <ListGroup.Item
                                    key={index}
                                    value={index}
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"
                                >
                                    <div className="ms-2 me-auto">
                                        {item.column.value} {item.operator.value} '{item.value}'
                                    </div>

                                    <FontAwesomeIcon
                                        role="button"
                                        value={index}
                                        className={this.state.dataLoading ? 'd-none' : 'cursor'}
                                        onClick={handleRemoveCondition}
                                        style={{ color: "red" }}
                                        icon={faTimes}
                                    />

                                </ListGroup.Item>
                            );
                        })}

                    </ListGroup>
                </Row>
            </React.Fragment>
        );
    }

    Actions = () => {
        return (
                <Row>
                    <Col>
                        <ProcessingButton
                            size="sm"
                            disabled={this.state.selectedBrands.length < 1}
                            processing={this.state.dataLoading}
                            processingLabel="Searching ..."
                            variant="success"
                            label="Search Violations"
                            icon={faSearch}
                            onClick={this.loadData}
                        />
                    </Col>
                    <Col>
                        <ProcessingButton
                            disabled={this.state.dataLoading}
                            size="sm"
                            variant="success"
                            label="Load Conditions"
                            icon={faDownload}
                            onClick={this.loadConditions}
                        />
                    </Col>
                    <Col>
                        <ProcessingButton
                            disabled={this.state.dataLoading}
                            size="sm"
                            variant="success"
                            label="Save Conditions"
                            icon={faUpload}
                            onClick={this.saveConditions}
                        />
                    </Col>
                </Row>
        );
    }

    ImageModal = () => {

        const ss = this.baseUrl + this.state.selectedScreenshot;
        const handleClose = () => {
            this.setState({
                showImageModal: false,
                imageLoaded: false
            });
        };
        const handleImageLoaded = () => {
            this.setState({
                imageLoaded: true
            });
        }
        const handleCopyToClipboard = () => {
            navigator.clipboard.writeText(ss);
            Toast.ok('URL copied to clipboard');
        }
        const handleOpenInNewTab = () => {
            window.open(ss, '_blank');
        }

        return (
            <Modal show={this.state.showImageModal} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon
                            icon={faCamera}
                            className='subMenuIcon'
                        />Screenshot
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="pb-4">
                        <ProcessingButton
                            variant="success"
                            label="Open in new tab"
                            icon={faExternalLinkSquareAlt}
                            onClick={handleOpenInNewTab}
                        />
                        <ProcessingButton
                            variant="secondary"
                            label="Copy screenshot URL"
                            icon={faClipboard}
                            onClick={handleCopyToClipboard}
                        />
                    </div>
                    <div className={this.state.imageLoaded ? 'text-center d-none' : 'text-center'}>
                        <MySpinner ></MySpinner>
                    </div>
                    <img src={ss}
                         alt="Screenshot"
                         width="100%"
                         onLoad={handleImageLoaded} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-success" className={'mx-auto'} onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    handleBrandChange = (rows) => {
        this.setState({
            selectedBrands: rows
        });
    }

    loadData = () => {
        Toast.info("Search may take some time based on the number of records and conditions, please be patient.")
        this.setState({dataLoading: true});
        let conditions = this.state.conditions.map((row) => {
            return {
                'key': row.column.key,
                'operator': row.operator.operator,
                'value': row.value
            }
        });
        ProductsTrends.getPriceViolations(this.state.selectedBrands, conditions, this.state.selectedRange).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while searching for violations.");
                this.setState({dataLoading: false});
                return;
            }
            this.setState({dataLoaded: true, dataLoading:false});
            this.tableRef.current?.populate(data);
        });
    }

    saveConditions = () => {
        if ( this.state.conditions.length > 0 ) {
            SettingsService.Set('pricing-violations-conditions', this.state.conditions);
            Toast.ok("Conditions saved.");
            return;
        }
        Toast.error("There are no conditions to save.");

    }

    loadConditions = () => {
        this.setState({conditionsLoading: true})
        if (SettingsService.Has('pricing-violations-conditions')) {
            this.setState({
                conditions: SettingsService.Get('pricing-violations-conditions')
            });
            Toast.ok("Conditions loaded.");
            return;
        }
        Toast.error("There are no conditions saved.");
    }

    downloadSpreadsheet() {
        this.tableRef.current.CSV();
    }

    async handleReportInaccuracy(evt) {
        const obj = this.tableRef.current.getRowFromEvent(evt);
        obj.row.website = (new URL(obj.row.offer_url)).hostname.replace('www.','');
        obj.row.brand_name = obj.row.brand;
        await ticket(TICKET_TYPES.PRICE_VIOLATION, obj.row);
    }

    handleOpenImageModal(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);
        this.setState({showImageModal: true, selectedScreenshot: obj.row.offer_screenshot});
    }

    render() {
        const key = window.location.hash.match(/results/) ? 2 : (window.location.hash.match(/websites/) ? 1 : 0);
        const Breadcrumbs = () => {
            return (<Breadcrumb className="dd">
                    <Breadcrumb.Item>Diagnostics</Breadcrumb.Item>
                    <Breadcrumb.Item active>Price Violations</Breadcrumb.Item>
                </Breadcrumb>);
        }
        return (
            <Container className="pt-4">
                <this.ImageModal />
                <span style={{
                    float: "right",
                    display: this.state.dataLoaded ? 'block' : 'none'
                }}
                >

                    <ProcessingButton
                        size="sm"
                        processing={this.state.downloading}
                        processingLabel="Downloading ..."
                        variant="success"
                        label="Download CSV"
                        icon={faDownload}
                        disabled={this.state.dataLoading}
                        onClick={this.downloadSpreadsheet}
                    />
                </span>
                <Breadcrumbs />
                <MyCarousel activeIndex={key} onSlide={this.onSlide}>
                    <Carousel.Item>
                        <Row md={12}>
                            <Col md={6}>
                                <span>Select Brand(s):</span>
                                <Row>
                                    <BrandsFilter onChange={this.handleBrandChange} />
                                </Row>
                                <Row>
                                    <this.DiffRange />
                                </Row>
                            </Col>
                            <Col md={6}>
                                <span>Conditions:</span>
                                <Row>
                                    <Col>
                                        <this.Conditions />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Row>
                                    <this.Actions />
                                </Row>
                            </Col>
                        </Row>
                    </Carousel.Item>
                </MyCarousel>

                <div className={!this.state.dataLoading && !this.state.dataLoaded ? 'd-none' :  ''}>
                    <MyTable
                        name="price-violations-table"
                        dynamic={false}
                        ref={this.tableRef}
                        sortBy="created_at"
                        sortOrder="desc"
                        headers={
                            [
                                {
                                    field: "brand",
                                    label: "Brand",
                                    sortable: true,
                                    searchable: true
                                },
                                {
                                    field: "product_name",
                                    label: "Product",
                                    sortable: true,
                                    searchable: true
                                },
                                {
                                    field: "merchant_name",
                                    label: "Merchant",
                                    sortable: true,
                                    searchable: true
                                },
                                {
                                    field: "marketplace",
                                    label: "Marketplace",
                                    sortable: true,
                                    searchable: true
                                },
                                {
                                    field: "identifier",
                                    label: "Identifier",
                                    sortable: true,
                                    searchable: true
                                },
                                {
                                    field: "upc",
                                    label: "UPC",
                                    sortable: true,
                                    searchable: true
                                },
                                {
                                    field: "sku",
                                    label: "SKU",
                                    sortable: true,
                                    searchable: true
                                },
                                {
                                    field: "datetime",
                                    label: "Date/time",
                                    sortable: true,
                                    searchable: false,
                                    width: "85px"
                                },
                                {
                                    field: "map",
                                    label: "MAP",
                                    sortable: true,
                                    searchable: false,
                                    width: "60px"
                                },
                                {
                                    field: "offer_price",
                                    label: "Price",
                                    sortable: true,
                                    searchable: false,
                                    width: "60px"
                                },
                                {
                                    field: "diff_perc",
                                    label: "% Diff",
                                    sortable: true,
                                    searchable: false,
                                    width: "60px"
                                },
                                {
                                    field: "offer_screenshot",
                                    label: "SS",
                                    sortable: false,
                                    searchable: false
                                },
                                {
                                    field: "offer_url",
                                    label: "URL",
                                    sortable: false,
                                    searchable: false
                                },
                                {
                                    field: "actions",
                                    width: "1px",
                                    sortable: false,
                                    searchable: false
                                }
                            ]
                        }
                        renderColumns={{
                            brand: {
                                format: (row) => <Link className="link" to={`/brand/${row.brand_id}`} target="_blank">{row.brand}</Link>,
                                text: (row) => {
                                    return row.brand_id + ' ' + row.brand;
                                }
                            },
                            merchant_name: (row) => <Link className="link" to={`/merchant/${row.merchant_id}`} target="_blank">{row.merchant_name}</Link>,
                            identifier: (row) => <Link className="link" to={`/product/${row.product_id}`} target="_blank">{row.identifier}</Link>,
                            product_name: (row) => <Link className="link" to={`/product/${row.product_id}`} target="_blank">{row.product_name}</Link>,
                            marketplace: (row) => row.marketplace === 'N/A' ? row.marketplace : <Link className="link" to={`/website/${row.website_id}`} target="_blank">{row.marketplace}</Link>,
                            offer_screenshot: {
                                format: (row) => {
                                    return <a href="#image" className="link cursor" onClick={this.handleOpenImageModal} rel="noreferrer">
                                        <FontAwesomeIcon icon={faCamera} style={{color: "silver"}} role={"button"} ></FontAwesomeIcon>
                                    </a>
                                },
                                csv: (row) => {
                                    return this.baseUrl + row.offer_screenshot;
                                }
                            },
                            offer_url: (row) => {
                                return <a className="link" href={row.offer_url} target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faExternalLinkSquareAlt} style={{color: "silver"}} ></FontAwesomeIcon>
                                </a>
                            },
                            map: (row) => {
                                return row.map.toFixed(2)
                            },
                            offer_price: (row) => {
                                return row.offer_price.toFixed(2)
                            },
                            actions: (row) => {
                                return <Menu
                                    align={`start`}
                                    direction={`left`}
                                    key={`control-${row.pid}-${row.mid}`}
                                    menuButton={<Button variant="outline-secondary"
                                                        size="sm"
                                                        style={{
                                                            minWidth: "35px",
                                                            marginLeft: "0px",
                                                            marginRight: "0px"
                                                        }}><FontAwesomeIcon icon={faTools} /></Button>}>
                                    <MenuItem onClick={this.handleReportInaccuracy}>
                                        <FontAwesomeIcon
                                            icon={faBug}
                                            className='subMenuIcon'
                                        /> Report Inaccuracy
                                    </MenuItem>
                                </Menu>
                            },
                        }}
                    />
                </div>
            </Container >
        );
    }
}
export default withRouter(Index);
