import React, { createRef } from "react";

import { Container, Breadcrumb, Button, Table, Modal, Form, Row, Col, Accordion } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import "../../../node_modules/currency-flags/dist/currency-flags.min.css";
import MyTable from "../../components/MyTable";
import ProcessingButton from "../../components/ProcessingButton";
import CrawlingSchedulerService from "../../services/CrawlingSchedulerService";
import { faCheck, faDownload, faEdit, faPlus, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Toast from "../../services/Toast";
import OrganizationSearch from "../../components/OrganizationSearch";
import WebsiteSearch from "../../components/WebsiteSearch";
import ProductSearch from "../../components/ProductSearch";
import confirm from "../../components/Confirm";

/**
 * Crawling Scheduler component
 * @component
 * @category Others
 */
class CrawlingScheduler extends React.Component {
    constructor(props) {
        super(props);
        this.state = { edit: null, loading: true };
        this.tableRef = createRef();
        this.productRef = createRef();
        this.websiteRef = createRef();

        this.onHide = this.onHide.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSaveChanges = this.onSaveChanges.bind(this);
        this.onChangeFrequency = this.onChangeFrequency.bind(this);
        this.handleSwitchStatus = this.handleSwitchStatus.bind(this);
        this.handleChangeSetting = this.handleChangeSetting.bind(this);
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
        this.handleAddElement = this.handleAddElement.bind(this);
        this.removeElement = this.removeElement.bind(this);
        this.handleSelectOrganization = this.handleSelectOrganization.bind(this);
        this.onAccordionClick = this.onAccordionClick.bind(this);
    }

    componentDidMount() {
        CrawlingSchedulerService.rules().then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while retrieving crawling scheduling");
                return;
            }
            let list = result.filter((row) => row.organization !== null);
            this.tableRef.current.populate(list);
            this.setState({ loading: false })
        })
    }

    onAccordionClick(evt) {
        let key = evt.target.parentNode.parentNode.getAttribute("accordionKey");
        this.setState({ accordion: key });
    }

    downloadSpreadsheet() {
        this.tableRef.current.CSV();
    }

    onHide(evt) {
        if (this.state.saving) {
            return;
        }
        this.setState({ edit: null });
    }

    onChangeFrequency(evt) {
        const day = evt.target.getAttribute('day');
        let edit = this.state.edit;
        edit[day] = evt.target.value;
        this.setState({ edit })
    }

    async onDelete(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);
        if (
            !(await confirm("Are you sure?", "Continue", "Cancel", {
                message:
                    "You're about to remove one set of settings for \"" +
                    obj.row.organization.name +
                    '". This operation can\'t be undone. Do you want to continue?',
            }))
        ) {
            return;
        }

        this.tableRef.current.setLoading(true);
        CrawlingSchedulerService.deleteRule(obj.row.id).then((result) => {
            this.tableRef.current.setLoading(false);
            if (!result) {
                Toast.error("Something went wrong while removing this rule.");
            } else {
                this.tableRef.current.deleteRow(obj.row);
                Toast.ok("Rule removed successfully");
            }
        });
    }

    onAdd(evt) {
        this.setState({
            accordion: 0,
            edit: {
                mode: "add",
                id: 0,
                is_active: true,
                sunday: 0,
                monday: 0,
                tuesday: 0,
                wednesday: 0,
                thursday: 0,
                friday: 0,
                saturday: 0,
                brands: [],
                products: [],
                websites: [],
                organization: null,
                dynamic_crawling: '',
            }
        });
    }

    handleSwitchStatus(evt) {
        const checked = evt.target.checked;
        let edit = this.state.edit;
        edit.is_active = checked;
        this.setState({ edit });
    }

    handleChangeSetting(evt) {
        const name = evt.target.name,
            value = evt.target.value;
        let edit = this.state.edit;
        edit[name] = value;
        this.setState({ edit });
    }

    onEdit(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);
        this.setState({
            accordion: 2,
            edit: {
                mode: "edit",
                id: obj.row.id,
                is_active: obj.row.is_active,
                sunday: typeof obj.row['sunday'] === 'number' ? obj.row['sunday'] : 0,
                monday: typeof obj.row['monday'] === 'number' ? obj.row['monday'] : 0,
                tuesday: typeof obj.row['tuesday'] === 'number' ? obj.row['tuesday'] : 0,
                wednesday: typeof obj.row['wednesday'] === 'number' ? obj.row['wednesday'] : 0,
                thursday: typeof obj.row['thursday'] === 'number' ? obj.row['thursday'] : 0,
                friday: typeof obj.row['friday'] === 'number' ? obj.row['friday'] : 0,
                saturday: typeof obj.row['saturday'] === 'number' ? obj.row['saturday'] : 0,
                brands: typeof obj.row['brands'] === 'object' ? obj.row.brands.map(row => row.id) : [],
                products: typeof obj.row['rule_products'] === 'object' ? obj.row.rule_products.map((item) => { return { upc: item.product.upc_code, id: item.product.id } }) : [],
                websites: typeof obj.row['rule_websites'] === 'object' ? obj.row.rule_websites.map((item) => { return { display_name: item.website.display_name, id: item.website.id } }) : [],
                organization: typeof obj.row['organization'] === 'object' ? obj.row.organization : 0,
                dynamic_crawling: typeof obj.row['dynamic_crawling'] === 'string' ? obj.row.dynamic_crawling : ''
            }
        }, () => {
            this.row = obj.row;
            console.log(this.state.accordion);
        })
    }

    handleAddElement(element) {
        const edit = this.state.edit,
            type = typeof element[0].upc === 'undefined' ? 'websites' : 'products',
            componentRef = type === 'websites' ? this.websiteRef : this.productRef;
        edit[type].push(element[0]);
        this.setState({ edit });
        if (componentRef.current !== null) {
            componentRef.current.clear();
        }
    }

    handleSelectOrganization(organization) {

        console.log(organization);
        let edit = this.state.edit;

        edit.organization = organization.length ? organization[0] : null
        edit.brands = edit.organization !== null && typeof edit.organization.brands === "object" ? edit.organization.brands.map(brand => brand.id) : [];
        edit.products = [];
        this.setState({ edit, accordion: 1 });
    }

    removeElement(evt) {
        const target = /path/i.test(evt.target.nodeName) ? evt.target.parentNode : evt.target,
            type = target.getAttribute('wid') !== null ? 'websites' : 'products',
            index = target.getAttribute('pindex'),
            edit = this.state.edit;

        edit[type].splice(index, 1)
        this.setState({ edit });
    }

    onSaveChanges(evt) {
        let editMode = this.state.edit.mode === "edit",
            row = editMode ? this.row : {
                brands: this.state.edit.organization.brands
            },
            payload = editMode ?
                {
                    description: row.description,
                    is_generic: row.is_generic ? 1 : 0,
                    is_active: this.state.edit.is_active,
                    organization_id: row.organization_id,
                    crawlers: row.crawlers,
                    weekdays: {},
                    dynamic_crawling: this.state.edit.dynamic_crawling.length ? this.state.edit.dynamic_crawling : null
                } :
                {
                    description: this.state.edit.organization.name,
                    is_generic: true,
                    is_active: this.state.edit.is_active,
                    organization_id: this.state.edit.organization.id,
                    weekdays: {},
                    dynamic_crawling: this.state.edit.dynamic_crawling.length ? this.state.edit.dynamic_crawling : null
                };
        ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].forEach((day) => {
            if (this.state.edit[day]) {
                row[day] = parseInt(this.state.edit[day]);
            }
            payload.weekdays[day] = parseInt(this.state.edit[day]);
        })
        payload.product_ids = this.state.edit.products.map(row => row.id);
        payload.website_ids = this.state.edit.websites.map(row => row.id);
        row.rule_products = this.state.edit.products.map((row) => { return { product: { id: row.id, upc_code: row.upc } } })
        row.rule_websites = this.state.edit.websites.map((row) => { return { website: { id: row.id, display_name: row.display_name } } })
        row.is_active = this.state.edit.is_active;
        row.dynamic_crawling = this.state.edit.dynamic_crawling;
        payload.website_id = null;
        this.setState({ saving: true });

        if (editMode) {
            CrawlingSchedulerService.updateRule(row.id, payload).then((result) => {

                if (result === false) {
                    Toast.error("Something went wrong while saving changes.");
                    this.setState({ saving: false });
                    return;
                }
                this.tableRef.current.updateRow(row);
                this.setState({ edit: null, saving: false });
            })
        } else {
            CrawlingSchedulerService.addRule(payload).then((result) => {

                if (result === false) {
                    Toast.error("Something went wrong while adding new rule.");
                    this.setState({ saving: false });
                    return;
                }
                result.brands = row.brands;
                this.tableRef.current.addRow(result);
                this.setState({ edit: null, saving: false });
            })
        }
    }


    render() {
        return (
            <Container className="pt-4">
                <span style={{
                    float: "right"
                }}
                >
                    <Button
                        size="sm"
                        className="tdButton"
                        onClick={this.downloadSpreadsheet}
                        disabled={this.state.loading}
                        variant="success"
                    >
                        <FontAwesomeIcon icon={faDownload} style={{
                            marginRight: "5px"
                        }} />
                        Download
                    </Button>
                </span>

                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Others</Breadcrumb.Item>
                    <Breadcrumb.Item active>Crawling Scheduler</Breadcrumb.Item>
                </Breadcrumb>
                <Modal
                    size="lg"
                    show={this.state.edit !== null}
                    backdrop={true}
                    keyboard={true}
                    centered
                >
                    <Modal.Header closeButton onHide={this.onHide}>
                        <Modal.Title>
                            {this.state.edit !== null ? (this.state.edit.mode === "edit" ? "Edit schedule" : "New schedule") : null}
                        </Modal.Title>
                    </Modal.Header>
                    {this.state.edit !== null && (
                        <Modal.Body className="text-center">

                            <Accordion defaultActiveKey={`${this.state.accordion}`} activeKey={`${this.state.accordion}`}>
                                {this.state.edit.mode === "add" && (
                                    <Accordion.Item accordionKey="0" eventKey="0">
                                        <Accordion.Header onClick={this.onAccordionClick}>Organization</Accordion.Header>
                                        <Accordion.Body>
                                            <OrganizationSearch
                                                onChange={this.handleSelectOrganization}
                                                placeholder="Select an organization" />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}
                                <Accordion.Item accordionKey="1" eventKey="1">
                                    <Accordion.Header onClick={this.onAccordionClick}>Settings</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col sm={2} className="d-flex align-items-center justify-content-start">
                                                <Form.Check
                                                    type="switch"
                                                    label={this.state.edit.is_active ? 'Active' : 'Disabled'}
                                                    checked={this.state.edit.is_active}
                                                    onChange={this.handleSwitchStatus}
                                                />

                                            </Col>
                                            <Col sm={3} className="d-flex align-items-center justify-content-end">
                                                Dynamic Crawling
                                            </Col>
                                            <Col sm={2}>
                                                <Form.Select
                                                    aria-label="Dynamic Crawling Mode"
                                                    name="dynamic_crawling"
                                                    onChange={this.handleChangeSetting}
                                                    value={this.state.edit.dynamic_crawling}
                                                >
                                                    <option></option>
                                                    <option value="test">TEST</option>
                                                    <option value="prod">PROD</option>
                                                </Form.Select>

                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {this.state.edit.organization !== null && (
                                    <React.Fragment>
                                        <Accordion.Item accordionKey="2" eventKey="2">
                                            <Accordion.Header onClick={this.onAccordionClick}>Calendar</Accordion.Header>
                                            <Accordion.Body>
                                                <Table style={{
                                                    marginBottom: "0px"
                                                }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Sunday</th>
                                                            <th>Monday</th>
                                                            <th>Tuesday</th>
                                                            <th>Wednesday</th>
                                                            <th>Thursday</th>
                                                            <th>Friday</th>
                                                            <th>Saturday</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td><Form.Control onChange={this.onChangeFrequency} value={this.state.edit["sunday"]} type="number" min={0} day="sunday" /></td>
                                                            <td><Form.Control onChange={this.onChangeFrequency} value={this.state.edit["monday"]} type="number" min={0} day="monday" /></td>
                                                            <td><Form.Control onChange={this.onChangeFrequency} value={this.state.edit["tuesday"]} type="number" min={0} day="tuesday" /></td>
                                                            <td><Form.Control onChange={this.onChangeFrequency} value={this.state.edit["wednesday"]} type="number" min={0} day="wednesday" /></td>
                                                            <td><Form.Control onChange={this.onChangeFrequency} value={this.state.edit["thursday"]} type="number" min={0} day="thursday" /></td>
                                                            <td><Form.Control onChange={this.onChangeFrequency} value={this.state.edit["friday"]} type="number" min={0} day="friday" /></td>
                                                            <td><Form.Control onChange={this.onChangeFrequency} value={this.state.edit["saturday"]} type="number" min={0} day="saturday" /></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item accordionKey="3" eventKey="3">
                                            <Accordion.Header onClick={this.onAccordionClick}>Websites</Accordion.Header>
                                            <Accordion.Body>
                                                <WebsiteSearch
                                                    ref={this.websiteRef}
                                                    onChange={this.handleAddElement}
                                                    placeholder="Select a website"
                                                    skip={this.state.edit.websites.map(row => row.id)}
                                                />
                                                <Container>
                                                    <Row>
                                                        {this.state.edit.websites.map((website, index) => (
                                                            <Col sm={4} key={`website-${website.id}`} className="text-left"><FontAwesomeIcon icon={faTimes} className="pointer" onClick={this.removeElement} wid={website.id} pindex={index} /> {website.display_name}</Col>
                                                        ))}
                                                    </Row>
                                                </Container>

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item accordionKey="4" eventKey="4">
                                            <Accordion.Header onClick={this.onAccordionClick}>Products</Accordion.Header>
                                            <Accordion.Body>
                                                <ProductSearch
                                                    ref={this.productRef}
                                                    onChange={this.handleAddElement}
                                                    includeArchivedProducts={false}
                                                    includeDisabledBrands={false}
                                                    placeholder="Add product"
                                                    brands={this.state.edit.brands}
                                                    skip={this.state.edit.products.map(row => row.id)}
                                                />
                                                <Container>
                                                    <Row>
                                                        {this.state.edit.products.map((product, index) => (
                                                            <Col sm={3} key={`product-${product.id}`}><FontAwesomeIcon icon={faTimes} className="pointer" onClick={this.removeElement} pid={product.id} pindex={index} /> {product.upc}</Col>
                                                        ))}
                                                    </Row>
                                                </Container>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </React.Fragment>
                                )}

                            </Accordion>
                        </Modal.Body>
                    )}
                    <Modal.Footer style={{
                        justifyContent: "center"
                    }}>
                        <Button size="sm" variant="secondary" onClick={this.onHide}>Cancel</Button>
                        <ProcessingButton
                            disabled={this.state.edit !== null && this.state.edit.mode === "add" && this.state.edit.organization === null}
                            size="sm"
                            processing={this.state.saving}
                            processingLabel="Saving ..."
                            label="Save"
                            onClick={this.onSaveChanges}
                        />
                    </Modal.Footer>
                </Modal >
                <MyTable
                    name="crawling-schedule"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="organization"
                    sortOrder="asc"
                    headers={[
                        {
                            field: "organization",
                            label: "Organization",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "brands",
                            label: "Brands",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "websites",
                            label: "Websites",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "products",
                            label: "Products",
                            width: "400px",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "calendar",
                            label: "Schedule",
                            sortable: true
                        },
                        {
                            field: "dynamic_crawling",
                            label: "DC Mode",
                            hint: "Dynamic Crawling Mode",
                            width: "80px",
                            sortable: true,
                            allowEmpty: true,
                            options: [{ label: 'Test', value: 'test' }, { label: 'Prod', value: 'prod' }]
                        },
                        {
                            field: "is_active",
                            label: "Status",
                            width: "70px",
                            sortable: true,
                            allowEmpty: true,
                            options: [{ label: 'Active', value: '1' }, { label: 'Disabled', value: '0' }]
                        },
                        {
                            field: "control",
                            width: "87px",
                            content: <Button
                                onClick={this.onAdd}
                                size="sm"
                                className="tdButton"
                                variant="success">
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        }
                    ]}
                    renderColumns={{
                        organization: {
                            format: (row) => <Link className="link" to={`/organization/${row.organization.id}`}>(#{row.organization.id}) {row.organization.name}</Link>,
                            text: (row) => row.organization.name,
                            csv: (row) => '(#' + row.organization.id + ') ' + row.organization.name
                        },
                        brands: {
                            format: (row) => row.brands.map((r) => <div><Link className="link" to={`/brand/${r.id}#restrictions`}>(#{r.id}) {r.name}</Link></div>),
                            text: (row) => row.brands.map((r) => r.id + ' ' + r.name).join(';'),
                            csv: (row) => row.brands.map((r) => '(#' + r.id + ') ' + r.name).join('; ')
                        },
                        websites: {
                            format: (row) => typeof row.rule_websites === "object"
                                ? row.rule_websites.map(row =>
                                    <Link className="plink" to={`/website/${row.website?.id}`}>
                                        {row.website?.display_name}
                                    </Link>)
                                : null,
                            text: (row) => typeof row.rule_websites === "object"
                                ? row.rule_websites.map(row => row.website?.display_name).join(', ')
                                : '',
                            csv: (row) => typeof row.rule_websites === "object"
                                ? row.rule_websites.map(row => row.website?.display_name).join(', ')
                                : ''
                        },
                        dynamic_crawling: {
                            className: "text-center",
                            format: (row) => typeof row.dynamic_crawling === "string"
                                ? row.dynamic_crawling.toUpperCase()
                                : '',
                        },
                        products: {
                            format: (row) => typeof row.rule_products === "object"
                                ? row.rule_products.map(row =>
                                    <Link className="plink" to={`/product/${row.product.id}`}>
                                        {row.product.upc_code}
                                    </Link>)
                                : null,
                            text: (row) => typeof row.rule_products === "object"
                                ? row.rule_products.map(row => row.product.upc_code).join(', ')
                                : '',
                            csv: (row) => typeof row.rule_products === "object"
                                ? row.rule_products.map(row => row.product.upc_code).join(', ')
                                : ''
                        },
                        is_active: {
                            className: "text-center",
                            format: (row) => row.is_active ? <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} /> : <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />,
                            text: (row) => row.is_active ? '1' : '0',
                            csv: (row) => row.is_active ? 'Active' : 'Disabled',
                        },
                        calendar: {
                            format: (row) => {
                                return <Table style={{
                                    marginBottom: "0px"
                                }} key={`calendar-org${row.id}`}>
                                    <thead>
                                        <tr>
                                            <th className="text-center">Sun</th>
                                            <th className="text-center">Mon</th>
                                            <th className="text-center">Tue</th>
                                            <th className="text-center">Wed</th>
                                            <th className="text-center">Thu</th>
                                            <th className="text-center">Fri</th>
                                            <th className="text-center">Sat</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ borderBottomWidth: "0px" }} className="text-center">{typeof row['sunday'] === 'number' && row['sunday'] ? row['sunday'] : ''}</td>
                                            <td style={{ borderBottomWidth: "0px" }} className="text-center">{typeof row['monday'] === 'number' && row['monday'] ? row['monday'] : ''}</td>
                                            <td style={{ borderBottomWidth: "0px" }} className="text-center">{typeof row['tuesday'] === 'number' && row['tuesday'] ? row['tuesday'] : ''}</td>
                                            <td style={{ borderBottomWidth: "0px" }} className="text-center">{typeof row['wednesday'] === 'number' && row['wednesday'] ? row['wednesday'] : ''}</td>
                                            <td style={{ borderBottomWidth: "0px" }} className="text-center">{typeof row['thursday'] === 'number' && row['thursday'] ? row['thursday'] : ''}</td>
                                            <td style={{ borderBottomWidth: "0px" }} className="text-center">{typeof row['friday'] === 'number' && row['friday'] ? row['friday'] : ''}</td>
                                            <td style={{ borderBottomWidth: "0px" }} className="text-center">{typeof row['saturday'] === 'number' && row['saturday'] ? row['saturday'] : ''}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            },
                            text: (row) => (parseInt(row['sunday']) + parseInt(row['monday']) + parseInt(row['tuesday']) + parseInt(row['wednesday']) + parseInt(row['thursday']) + parseInt(row['friday']) + parseInt(row['saturday'])) + '',
                            csv: (row) => JSON.stringify({
                                'sunday': row['sunday'],
                                'monday': row['monday'],
                                'tuesday': row['tuesday'],
                                'wednesday': row['wednesday'],
                                'thursday': row['thursday'],
                                'friday': row['friday'],
                                'saturday': row['saturday'],
                            })
                        },
                        control: {
                            className: "text-center",
                            format: (row) => <React.Fragment>
                                <Button
                                    onClick={this.onEdit}
                                    size="sm"
                                    className="tdButton"
                                    variant="secondary">
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                                <Button
                                    onClick={this.onDelete}
                                    size="sm"
                                    className="tdButton"
                                    variant="danger">
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </React.Fragment>
                        }
                    }} />
            </Container >
        );
    }
}

export default withRouter(CrawlingScheduler);
