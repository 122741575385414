import React from "react";
import PropTypes from "prop-types";

import { Button, Modal, Spinner } from "react-bootstrap";
import "./EditSIM.css"
import WebsiteService from "../services/WebsiteService";
import Toast from "../services/Toast";
import ProcessingButton from "./ProcessingButton";

/**
 * Shows a Edit SIM range modal
 * @component
 */
class EditSIM extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            upper: this.props.upper,
            lower: this.props.lower,
            saving: false,
            loading: true
        }
        this.handleChange = this.handleChange.bind(this);
        this.onEntering = this.onEntering.bind(this);
    }

    onEntering() {
        this.setState({
            loading: true
        });
        WebsiteService.getSIM(this.props.website.id).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while retrieving this website's SIM range.");
            }
            this.setState({
                upper: result.upper,
                lower: result.lower,
                loading: false
            })
        });
    }

    handleChange(evt) {
        let field = evt.target.getAttribute('id');
        let state = this.state;
        state[field] = evt.target.value;
        this.setState(state);
    }

    render() {
        let cancel = () => { this.props.onClose(false) },
            save = () => {
                this.setState({ saving: true }, () => {
                    WebsiteService.updateSIM(this.props.website.id, this.state.lower, this.state.upper).then((result) => {
                        if (result === true) {
                            this.props.onClose(true)
                            Toast.ok("SIM range updated successfully");
                        } else {
                            Toast.error("Something went wrong while updating SIM");
                        }
                        this.setState({
                            saving: false
                        })
                    });
                });
            }
        return (
            <Modal
                onEntering={this.onEntering}
                size="sm"
                show={this.props.show}
                backdrop={true}
                keyboard={true}
                centered
            >
                <Modal.Header closeButton onHide={cancel}>
                    <Modal.Title>
                        Edit SIM range
                    </Modal.Title>
                </Modal.Header>
                {this.state.loading && (
                    <Modal.Body className="text-center">
                        <Spinner
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    </Modal.Body>
                )}
                {!this.state.loading && (
                    <React.Fragment>
                        <Modal.Body className="text-center">
                            <h5>{this.props.website.name}</h5>
                            {`[ `}
                            <input type="numeric" id="lower" value={this.state.lower} onChange={this.handleChange} className="numberInput" />{` , `}
                            <input type="numeric" id="upper" value={this.state.upper} onChange={this.handleChange} className="numberInput" />{` ]`}
                        </Modal.Body>
                        <Modal.Footer style={{
                            justifyContent: "center"
                        }}>
                            <Button size="sm" variant="secondary" onClick={cancel}>Cancel</Button>
                            <ProcessingButton
                                size="sm"
                                processing={this.state.saving}
                                processingLabel="Saving ..."
                                label="Save"
                                onClick={save}
                            />
                        </Modal.Footer>

                    </React.Fragment>
                )}
            </Modal >
        );
    }
}

EditSIM.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    website: PropTypes.object.isRequired,
    /** upper range */
    upper: PropTypes.number.isRequired,
    /** lower range */
    lower: PropTypes.number.isRequired,
};

EditSIM.defaultProps = {
    show: true,
    onClose: () => { console.log("onClose") },
    upper: 6,
    lower: 0.2
};

export default EditSIM;
