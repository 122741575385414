import React from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { withRouter } from "react-router-dom";
import Memory from "../../services/MemoryService";
import { Breadcrumb, Container, Row } from "react-bootstrap";
import WebsiteSearch from "../../components/WebsiteSearch";

/**
 * Show a typeahead component for finding rows
 * @component
 * @category Scenes
 * @subcategory Websites
 */
class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            query: "",
        };
    }

    handleChange = (row) => {
        Memory.Flush("WEBSITE_PROFILE");
        this.props.history.push(`/website/${row[0].id}`);
    };

    render() {
        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Website</Breadcrumb.Item>
                    <Breadcrumb.Item active>Search</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    {" "}
                    <WebsiteSearch onChange={this.handleChange} />
                </Row>
            </Container>
        );
    }
}

export default withRouter(Search);