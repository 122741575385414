import axios from "axios";

import Config from "./Config";
import Version from "./Version";
import Emitter from "./Emitter";
import Settings from "./SettingsService";
import APIHelper from "./APIHelper";
import { PERMISSION_TYPE, PERMISSIONS } from "./UsersPermission";

axios.interceptors.response.use(
    function (successRes) {
        return successRes;
    },
    function (error) {
        if (typeof error.response !== "undefined") {
            let code = error.response.status,
                data = error.response.data;
            if (code === 403 && data.indexOf("oauth") !== -1) {
                Emitter.emit("REAUTH");
            } else if (code === 503 && data.indexOf("maintenance") === 0) {
                const until = data.match(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/);
                if (until !== null) {
                    window.localStorage.setItem('MAINTENANCE_UNTIL', until[0]);
                }
                Emitter.emit("MAINTENANCE");
            }
        }
        return Promise.reject(error);
    }
);

/**
 * Should assist User Authentaticon
 * @hideconstructor
 */
class AuthService {
    /** Location where the used should be redirected after log-in */
    static #redirect2;
    /**
     * Should register the location through which the user has landed in the system.
     * Someone trying to access https://tools.trackstreet.com/something would be "init"iated with
     * /something
     * @param {string} redirect2
     * @memberof AuthService
     */
    static init(redirect2 = "/") {
        let jwt = localStorage.getItem("jwt");
        if (jwt != null) {
            axios.defaults.headers.common["Authorization"] = jwt;
        }
        AuthService.#redirect2 = redirect2;
        if (Version.Notes !== Settings.Get('LastRelease')) {
            AuthService.#redirect2 = "/releases";
        }
    }
    /**
     * Validates Google OAuth token, finishing up the Authentication process.
     * It would automatically set up Axios to use the Authentication Token on every
     * request going forward.
     * @param {string} code
     * @returns {boolean}
     */
    static sign(code) {
        return axios
            .post(Config.API_URL + "sign", {
                code: code,
            })
            .then((response) => {
                if (APIHelper.checkStatus(response)) {
                    localStorage.setItem("jwt", response.data.jwt);
                    axios.defaults.headers.common["Authorization"] =
                        response.data.jwt;
                    return true;
                }
                return false;
            })
            .catch(APIHelper.returnFalse);
    }
    /**
     * Checks whether the current token is still valid.
     * @returns {boolean}
     */
    static ping() {
        return axios
            .get(Config.API_URL + "ping")
            .then((response) => {
                return (
                    typeof response != "undefined" &&
                    typeof response.data != "undefined" &&
                    response.data === "pong"
                );
            })
            .catch((error) => {
                if (
                    typeof error === "undefined" ||
                    typeof error.response === "undefined" ||
                    typeof error.response.data === "undefined"
                ) {
                    return false;
                }
                return error.response.data.match(/google/)
                    ? error.response.data
                    : false;
            });
    }
    /**
     * Removes all tokens from the browser, "resetting" the session
     */
    static logout() {
        localStorage.removeItem("jwt");
        axios.defaults.headers.common["Authorization"] = null;
    }
    /**
     * Retrieves the user info (name & picture) from the JWT token
     * @returns {(false|object)} false if no user has been authenticated. Object otherwise
     */
    static user() {
        let user = localStorage.getItem("jwt");
        if (user === null) {
            return false;
        }
        user = JSON.parse(atob(user.split(".")[1]));
        return user;
    }

    static hasAccessTo = (area) => {
        const user = this.user();
        if (
            typeof user !== "object"
            || typeof user.username !== "string"
            || user.username.length === 0
        ) {
            return false;
        }
        const userPermission = PERMISSIONS[user.username] ?? [];
        return userPermission.includes(area);
    }

    static isAdmin = () => {
        return AuthService.hasAccessTo(PERMISSION_TYPE.ADMIN);
    }

    static hasAccessToCosts = () => {
        return AuthService.hasAccessTo(PERMISSION_TYPE.COSTS);
    }

    /**
     * Retrieves whether the user should be redirected after login
     * @returns {string} URL through which the user landed in the system before passing through authentication
     * @example /website/1#profile
     */
    static getDesiredLocation() {
        let landingUrl = window.localStorage.getItem("LANDING_URL");
        if (typeof landingUrl === "string" && landingUrl.length) {
            AuthService.#redirect2 = landingUrl;
            window.localStorage.removeItem("LANDING_URL");
        }
        return AuthService.#redirect2 === null ||
            (typeof AuthService.#redirect2 === "string" && AuthService.#redirect2.length === 0)
            ? "/"
            : AuthService.#redirect2;
    }

    static reauth() {
        if (!/\/oauth$/.test(window.location.pathname)) {
            window.localStorage.setItem("LANDING_URL", window.location.pathname + window.location.hash);
        }
        window.location.href = "/oauth";
    }
}

export default AuthService;
