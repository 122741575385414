import React, { createRef } from "react";
import PropTypes from "prop-types";

import { Container, Button } from "react-bootstrap";
import WebsiteService from "../../services/WebsiteService";
import "../../../node_modules/currency-flags/dist/currency-flags.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPlay } from "@fortawesome/free-solid-svg-icons";
import Emitter from "../../services/Emitter";
import Toast from "../../services/Toast";
import Utils from "../../services/Utils";
import confirm from "../../components/Confirm";
import Loading from "../../scenes/Loading";
import { Link } from "react-router-dom";
import MyTable from "../../components/MyTable";
import { Menu, MenuItem } from "@szhsin/react-menu";
import MySpinner from "../../components/MySpinner";

/**
 * Product Discovery component
 * @component
 * @category Scenes
 * @subcategory Websites
 */
class ProductDiscovery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modes: {
                upc: null,
                bdo: null,
                npd: null,
                descobridor: null
            },
            modesFilter: [],
            pending: false,
            downloadable: false,
            downloading: false,
            loading: true
        };
        this.onEnter = this.onEnter.bind(this);
        this.onRequestExecution = this.onRequestExecution.bind(this);
        this.onRequestReport = this.onRequestReport.bind(this);
        this.tableRef = createRef();
    }

    componentWillUnmount() {
        Emitter.off("WEBSITE_DISCOVERY");
    }

    componentDidMount() {
        Emitter.once("WEBSITE_DISCOVERY", this.onEnter);
    }

    onEnter() {
        WebsiteService.productsDiscovery(this.props.websiteId).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while retrieving list of Product Discovery");
                this.setState({ loading: false });
                return;
            }

            let list = [],
                modes = [],
                pending = 0,
                // let's enable the download button regardless as the matcher
                // isn't currently properly writting results into data_discovery_runs table
                downloadable = 1;
            data.rows.forEach((row) => {
                row.date = row.date ?? "";
                row.status = row.status ?? "";
                row.mode = row.mode ?? "";
                row.count = row.count ?? "";
                if (row.status.length && row.status !== 'executed') {
                    pending++;
                }
                if (parseInt(row.count)) {
                    downloadable++;
                }
                modes[row.mode] = true;
                list.push(row);
            });
            this.setState({
                total: data.rows.length,
                modesFilter: Object.keys(modes).filter((mode) => { return mode.length > 0 }),
                modes: data.modes,
                loading: false,
                pending: pending > 0,
                downloadable: downloadable > 0
            });
            this.tableRef.current?.populate(list);
        });
    }

    onRequestReport() {
        this.setState({ downloading: true });
        WebsiteService.npd(this.props.websiteId).then((result) => {
            if (!result) {
                Toast.error("Something went wrong while retrieving the report");
            }
            this.setState({ downloading: false });
        });
    }

    async onRequestExecution(evt) {
        let target = evt.target || evt.syntheticEvent.target;

        if (/svg/i.test(target.nodeName)) {
            target = target.parentNode
        } else if (/path/i.test(target.nodeName)) {
            target = target.parentNode.parentNode
        }

        let obj = this.tableRef.current.getRowFromEvent(evt),
            mode = null,
            message = null;

        if (obj.index === -1) {
            mode = 'npd';
            message = "You're about to request a new Product Discovery execution for this website. Do you want to continue?";
        } else {
            mode = target.getAttribute("mode");
            message = `You're about to request a new Product Discovery execution for brand "${obj.row.brand}". Do you want to continue?`;
        }

        if (!(await confirm("Are you sure?", "Continue", "Cancel", { message }))) {
            return;
        }

        this.tableRef.current.setLoading();

        WebsiteService.requestProductDiscovery(
            this.props.websiteId,
            mode !== 'npd' ? obj.row.id : 0,
            mode
        ).then((result) => {
            if (result) {
                Toast.ok("Execution requested successfully");
                this.onEnter();
            } else {
                Toast.error("Something went wrong while requesting execution");
                this.tableRef.current.setLoading(false);
            }
        });
    }

    render() {
        if (this.state.loading) {
            return <Loading />
        }
        if (!this.state.modes.upc && !this.state.modes.bdo && !this.state.modes.npd && !this.state.modes.descobridor) {
            return (
                <Container className="pt-4">
                    This website is not semi-automatic.
                </Container>
            );
        }
        const { modes, modesFilter } = this.state,
            modeHeader = {
                field: "mode",
                label: "Mode",
                width: "150px",
                sortable: true,
                allowEmpty: true,
            };
        if (modesFilter.length > 1) {
            modeHeader.options = modesFilter.map((mode) => { return { value: mode, label: mode.toUpperCase() } });
        }

        return (
            <Container className="pt-4" >
                <MyTable
                    name="website-product-discovery"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="date"
                    sortOrder="desc"
                    headers={
                        [
                            {
                                field: "brand",
                                label: "Brand",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "date",
                                label: "Date",
                                width: "150px",
                                sortable: true,
                                searchable: true,
                            },
                            {
                                field: "status",
                                label: "Status",
                                width: "150px",
                                sortable: true,
                                allowEmpty: true,
                                options: [
                                    { label: 'Requested', value: 'requested' },
                                    { label: 'Scheduled', value: 'scheduled' },
                                    { label: 'Crawled', value: 'crawled' },
                                    { label: 'Executed', value: 'executed' }
                                ]
                            },
                            modeHeader,
                            {
                                field: "count",
                                label: "Count",
                                width: "150px",
                                sortable: true,
                            },
                            {
                                field: "control",
                                width: "87px",
                                content: modes.npd ?
                                    <React.Fragment>
                                        <Button
                                            size="sm"
                                            className="tdButton"
                                            variant="outline-secondary"
                                            mode="npd"
                                            disabled={this.state.pending}
                                            onClick={this.onRequestExecution}
                                        >
                                            <FontAwesomeIcon icon={faPlay} />
                                        </Button>
                                        {this.state.downloading ?
                                            <MySpinner />
                                            :
                                            <Button
                                                size="sm"
                                                className="tdButton"
                                                variant="outline-success"
                                                disabled={!this.state.downloadable}
                                                onClick={this.onRequestReport}
                                            >
                                                <FontAwesomeIcon icon={faDownload} />
                                            </Button>
                                        }
                                    </React.Fragment> : null
                            }
                        ]
                    }
                    renderColumns={{
                        brand: (row) => <Link className="link" to={`/brand/${row.id}`}>{`(#${row.id}) ${row.brand}`}</Link>,
                        status: (row) => Utils.Capitalize(row.status),
                        mode: (row) => row.mode.toUpperCase(),
                        control: () => {
                            if (modes.upc && modes.bdo) {
                                return (
                                    <Menu
                                        direction="left"
                                        style={{
                                            fontSize: "medium"
                                        }}
                                        menuButton={
                                            <Button
                                                size="sm"
                                                className="tdButton"
                                                variant="outline-secondary"
                                            >
                                                <FontAwesomeIcon icon={faPlay} />
                                            </Button>}>
                                        <MenuItem
                                            mode="upc"
                                            onClick={this.onRequestExecution}>
                                            UPC-Search
                                        </MenuItem>
                                        <MenuItem
                                            mode="bdo"
                                            onClick={this.onRequestExecution}>
                                            BDO
                                        </MenuItem>
                                    </Menu>
                                );
                            }

                            if (modes.npd) {
                                return null;
                            }

                            return (
                                <Button
                                    size="sm"
                                    className="tdButton"
                                    variant="outline-secondary"
                                    mode={modes.descobridor ? 'descobridor' : (modes.upc ? 'upc' : 'bdo')}
                                    onClick={this.onRequestExecution}>
                                    <FontAwesomeIcon icon={faPlay} />
                                </Button>
                            );
                        }
                    }}

                />
            </Container >
        );
    }
}

ProductDiscovery.propTypes = {
    /** Website ID */
    websiteId: PropTypes.number.isRequired,
};

export default ProductDiscovery;
