import axios from "axios";

import Config from "./Config";
import APIHelper from "./APIHelper";

const LogsService = {
    recent(mode) {
        return axios
            .post(Config.API_URL + "logs/10000", {
                source: mode
            })
            .then(APIHelper.returnEntries)
            .catch(APIHelper.returnFalse);
    },
    search(text, source = '') {
        return axios
            .post(Config.API_URL + "logs/search", {
                text,
                source
            })
            .then(APIHelper.returnEntries)
            .catch(APIHelper.returnFalse);
    }
};

export default LogsService;
