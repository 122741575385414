import React from "react";

import { Carousel } from "react-bootstrap";
import List from "./List"
import Emitter from "../../services/Emitter";
import Profile from "./Profile"
import MyCarousel from "../../components/MyCarousel";

class Partner extends React.Component {

    componentDidMount() {
        this.updateActiveKey();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.updateActiveKey();
        }
    }

    updateActiveKey() {
        if (window.location.href.match(/\/new$/)) {
            Emitter.emit("NEW_PARTNER");
            this.setState({ activeKey: 'new' });
        } else if (window.location.href.match(/edit\/\d+$/)) {
            Emitter.emit("EDIT_PARTNER");
            this.setState({ activeKey: 'edit' });
        } else {
            this.setState({ activeKey: 'list' });
        }
    }
    
    render() {
        const activeKey = this.state?.activeKey ?? 'list';
        let key = 0;
        if (activeKey === "new" || activeKey === "edit") {
            key = 1;
        }

        return (
            <MyCarousel activeIndex={key}>
                <Carousel.Item key="partner.list" className="standardGrid">
                    <List />
                </Carousel.Item>
                <Carousel.Item key="partner.profile" className="standardGrid">
                    <Profile action={activeKey} />
                </Carousel.Item>
            </MyCarousel>
        );
    }
}
export default Partner;
