import React from "react";
import PropTypes from "prop-types";

import { Button, Container, Form, Modal, Spinner, Table, Toast } from "react-bootstrap";
import Categories from "../components/Categories";
import BrandService from "../services/BrandService";
import WebsiteService from "../services/WebsiteService";
import OnboardingService from "../services/OnboardingService";
import ProcessingButton from "./ProcessingButton";

/**
 * Shows a Edit Products Attributes modal
 * @component
 */
class FixMissingWebsites extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            issues: [],
            allowedCategories: [],
            loading: true,
            processingIndex: 0,
            saving: false,
        }
        this.onEntering = this.onEntering.bind(this);
        this.procNext = this.procNext.bind(this);
    }

    onEntering() {
        if (this.props.brandId === 0) {
            return;
        }

        BrandService.categories(this.props.brandId).then((result) => {
            if (result === false) {
                Toast.error("Something when wrong while retrieving this brand's categories");
                return;
            }
            this.setState({
                issues: this.props.issues,
                saving: false,
                loading: false,
                allowedCategories: result
            });
        });
    }

    procNext() {
        if (this.state.processingIndex >= this.state.issues.length) {
            // finished
            if (this.createList.length === 0) {
                this.setState({ saving: false })
                this.props.onClose(true);
                return;
            }
            OnboardingService.requestWebsites(this.props.brandId, this.createList).then((result) => {
                this.setState({ saving: false })
                this.props.onClose(true);
            });
            return;
        }
        let issue = this.state.issues[this.state.processingIndex];

        if (!issue.checked) {
            this.setState({ processingIndex: this.state.processingIndex + 1 }, this.procNext);
            return;
        }

        if (issue.type === "create") {
            let ids = [];
            issue.categories.forEach((item) => {
                ids.push(item.value);
            });
            this.createList.push({
                code: 'new',
                hostname: issue.name,
                categories: ids
            });
            this.setState({ processingIndex: this.state.processingIndex + 1 }, this.procNext);
            return;
        } else {
            let categories = [];
            issue.categories.forEach((item) => {
                categories.push(item.value);
            });
            WebsiteService.addCategories(issue.id, categories).then((result) => {
                this.setState({ processingIndex: this.state.processingIndex + 1 }, this.procNext);
            });
        }
    }

    render() {
        if (this.props.brandId === 0) {
            return null;
        }
        const cancel = () => {
            if (this.state.saving) {
                return;
            }
            this.props.onClose(false);
        }

        const save = () => {
            this.createList = [];
            this.setState({ saving: true, processingIndex: 0 }, this.procNext);
            // this.props.onClose(true);
        }

        const Rows = () => {
            return this.state.issues.map((item, index) => {
                const action = item.type === "create" ? "Create, bucketize & add categories" : "Add categories";
                return (
                    <tr key={`r-${index}`} index={index}>
                        <td style={{ verticalAlign: "middle" }}><Form.Check type="checkbox" checked={item.checked} onChange={(evt) => { let issues = this.state.issues; issues[index].checked = evt.target.checked; this.setState({ issues }) }} /></td>
                        <td style={{ verticalAlign: "middle" }}>{item.name}</td>
                        <td style={{ verticalAlign: "middle" }}>{action}</td>
                        <td><Categories key={`cat-${index}`} allowed={this.state.allowedCategories} value={item.categories} onChange={(list) => { let issues = this.state.issues; issues[index].categories = list; this.setState({ issues }); }} /> </td>
                    </tr>
                );
            });
        }

        const ValidSettings = () => {
            let checkeds = 0;
            for (let i = 0; i < this.state.issues.length; i++) {
                let issue = this.state.issues[i];
                if (!issue.checked) {
                    continue;
                }
                checkeds++;
                if (typeof issue.categories === "undefined" || issue.categories.length === 0) {
                    return false;
                }
            }
            return checkeds > 0;
        }

        return (
            <Modal
                onEntering={this.onEntering}
                size="lg"
                show={this.props.show}
                backdrop={false}
                keyboard={false}
                centered
            >
                <Modal.Header closeButton onHide={cancel}>
                    <Modal.Title>
                        Fix issues
                    </Modal.Title>
                </Modal.Header>
                {this.state.loading && (
                    <Modal.Body className="text-center">
                        <Spinner
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    </Modal.Body>
                )}
                {!this.state.loading && (
                    <React.Fragment>
                        <Modal.Body className="text-center">
                            <Container>
                                <Table bordered hover size="sm" className="myTable">
                                    <thead>
                                        <tr className="title">
                                            <th>Apply</th>
                                            <th>Website</th>
                                            <th>Action</th>
                                            <th>Settings</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Rows()}
                                    </tbody>
                                </Table>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer style={{
                            justifyContent: "center"
                        }}>
                            <Button disabled={this.state.saving} size="sm" variant="secondary" onClick={cancel}>Cancel</Button>
                            <ProcessingButton
                                size="sm"
                                processing={this.state.saving}
                                processingLabel="Saving ..."
                                label="Save"
                                onClick={save}
                                disabled={!ValidSettings()}
                            />
                        </Modal.Footer>

                    </React.Fragment>
                )
                }
            </Modal >
        );
    }
}

FixMissingWebsites.propTypes = {
    brandId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    onUpdated: PropTypes.func,
    issues: PropTypes.array.isRequired
};

FixMissingWebsites.defaultProps = {
    show: true,
    onClose: () => { console.log("onClose") },
    issues: []
};

export default FixMissingWebsites;
