import React from "react";

import { Nav, Carousel, Container, Breadcrumb } from "react-bootstrap";
import Summary from "./Summary";
import Emitter from "../../services/Emitter";
import Profile from "./Profile";
import Lookup from "./Lookup";
import Merchants from "./Merchants";
import Prices from "./Prices";
import Attributes from "./Attributes";
import ProductService from "../../services/ProductService";
import Loading from "../Loading";
import { withRouter } from "react-router-dom";
import Toast from "../../services/Toast";
import MyCarousel from "../../components/MyCarousel";

class Index extends React.Component {
    constructor(props) {
        super(props);
        let id = this.props.match.params.id;
        this.state = {
            id,
            loading: true
        };
        this.firstLoad = true;
        ProductService.identifier(id).then((identifier) => {
            if (identifier === 0) {
                Toast.error("There's no product with ID " + id, 3000);
                this.props.history.push("/product");
            } else if (identifier === false) {
                Toast.error("Something went wrong while retrieving data for product with ID " + id, 3000);
                this.props.history.push("/product");
            } else {
                this.setState({ loading: false, identifier });
            }
        });
    }

    showPage(tab) {
        let key, event;
        switch (tab) {
            case "#profile":
                key = 1;
                event = "PRODUCT_PROFILE";
                break;
            case "#attributes":
                key = 2;
                event = "PRODUCT_ATTRIBUTES";
                break;
            case "#lookup":
                key = 3;
                event = "PRODUCT_LOOKUP";
                break;
            case "#merchants":
                key = 4;
                event = "PRODUCT_MERCHANTS";
                break;
            case "#pricing":
                key = 5;
                event = "PRODUCT_PRICES";
                break;
            default:
                key = 0;
                event = "PRODUCT_SUMMARY";
                break;
        }

        if (this.firstLoad) {
            this.firstLoad = false;
            setTimeout(() => {
                Emitter.emit(event);
            }, 250);
        } else {
            Emitter.emit(event);
        }

        return (
            <MyCarousel activeIndex={key}>
                <Carousel.Item key="#summary" className="standardGrid">
                    <Summary id={this.state.id} />
                </Carousel.Item>
                <Carousel.Item key="#profile" className="standardGrid">
                    <Profile id={this.state.id} />
                </Carousel.Item>
                <Carousel.Item key="#attributes" className="standardGrid">
                    <Attributes id={this.state.id} />
                </Carousel.Item>
                <Carousel.Item key="#lookup" className="standardGrid">
                    <Lookup id={this.state.id} />
                </Carousel.Item>
                <Carousel.Item key="#merchants" className="standardGrid">
                    <Merchants id={this.state.id} />
                </Carousel.Item>
                <Carousel.Item key="#pricing" className="standardGrid">
                    <Prices id={this.state.id} />
                </Carousel.Item>
            </MyCarousel>
        );
    }

    render() {
        if (this.state.loading) {
            return <Loading />
        }
        let match = window.location.href.match(/#\w+/),
            activeKey,
            page = match !== null && typeof match[0] !== "undefined" ? match[0] : null;
        switch (page) {
            default:
                activeKey = "#summary";
                break;
            case "#profile":
                activeKey = "#profile";
                break;
            case "#attributes":
                activeKey = "#attributes";
                break;
            case "#merchants":
                activeKey = "#merchants";
                break;
            case "#lookup":
                activeKey = "#lookup";
                break;
            case "#pricing":
                activeKey = "#pricing";
                break;
        }

        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Product</Breadcrumb.Item>
                    <Breadcrumb.Item active>{this.state.identifier}</Breadcrumb.Item>
                </Breadcrumb>
                <Nav variant="tabs" activeKey={activeKey}>
                    <Nav.Item>
                        <Nav.Link href="#summary">Summary</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#profile">Profile</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#attributes">Attributes</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#lookup">URLs</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#merchants">Merchants</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#pricing">Pricing</Nav.Link>
                    </Nav.Item>
                </Nav>
                {this.showPage(activeKey)}
            </Container>
        );
    }
}
export default withRouter(Index);
