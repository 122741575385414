import { faBars, faTrash, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { createRef } from "react";

import { Container, Breadcrumb, Button } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import MyTable from "../../components/MyTable";
import DomainDiscoveryService from "../../services/DomainDiscoveryService";
import Emitter from "../../services/Emitter";
import Toast from "../../services/Toast";
import confirm from "../../components/Confirm";

/**
 * Displays a Job Summary component
 * @component
 * @category Scenes
 * @subcategory Domain Discovery
 */
class Job extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            engines: []
        };
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
        this.tableRef = createRef();

        this.reload = this.reload.bind(this);
        this.viewFragment = this.viewFragment.bind(this);
        this.deleteFragment = this.deleteFragment.bind(this);
    }

    componentDidMount() {
        Emitter.on("DD_JOB", this.reload);
    }

    componentWillUnmount() {
        Emitter.off("DD_JOB", this.reload);
    }

    reload(jobId) {
        if (typeof jobId === "undefined") {
            return;
        }
        this.tableRef.current.populate([])
        this.tableRef.current.setLoading(true);

        this.setState({ id: jobId }, () => {

            DomainDiscoveryService.job(this.state.id).then((data) => {
                if (data === false) {
                    Toast.error("Something went wrong while retrieving this job's data");
                    return;
                }
                let engines = {};
                data.forEach((row) => {
                    engines[row.engine] = true;
                })
                this.setState({ engines: Object.keys(engines) }, () => {
                    this.tableRef.current.populate(data);
                })
            });

        })
    }


    downloadSpreadsheet(evt) {
        this.tableRef.current.CSV({
            filename: "dd-job-" + this.state.id + ".csv"
        });
    }

    viewFragment(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);
        this.props.history.push('/dd/job/' + this.state.id + '/' + obj.row.id);
    }

    async deleteFragment(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);
        if (
            await confirm("Are you really sure?", "Yes", "No", {
                message:
                    "You are about the remove a fragment and all its collected result, if there's any. This operation can't be undone. Do you want to proceed?",
            })
        ) {
            DomainDiscoveryService.removeFragment(obj.row.id).then((result) => {
                if (result) {
                    Toast.ok("Fragment removed successfully.");
                    this.tableRef.current.deleteRow(obj.row);
                } else {
                    Toast.error("Something went wrong while removing this fragment.");
                }
            });
        }

    }
    render() {

        return (
            <Container className="pt-3 dd">
                <span style={{
                    float: "right"
                }}
                >
                    <Button
                        size="sm"
                        className="tdButton"
                        variant="success"
                        label="Download CSV"
                        onClick={this.downloadSpreadsheet}
                    >
                        <FontAwesomeIcon icon={faDownload} /> Download
                    </Button>
                </span>
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Domain Discovery</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/dd">
                            Jobs
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        #{this.state.id}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <MyTable
                    name="dd-job"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="page"
                    sortOrder="asc"
                    headers={
                        [
                            {
                                field: "id",
                                label: "ID",
                                width: "60px",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "keyword",
                                label: "Keyword",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "results",
                                label: "# Results",
                                hint: "Number of results found for the fragment",
                                width: "100px",
                                sortable: true,
                            },

                            {
                                field: "domains",
                                label: "# Domains",
                                hint: "Number of unique domain names found for the fragment",
                                width: "100px",
                                sortable: true,
                            },
                            {
                                field: "engine",
                                label: "Engine",
                                width: "150px",
                                sortable: true,
                                allowEmpty: true,
                                options: this.state.engines
                            },
                            {
                                field: "status",
                                label: "Status",
                                width: "150px",
                                sortable: true,
                                allowEmpty: true,
                                options: [{ label: 'Executed', value: 'executed' }, { label: 'Pending', value: 'pending' }]
                            },
                            {
                                field: "control",
                                width: "87px"
                            }
                        ]
                    }
                    renderColumns={{
                        control: (row) => <React.Fragment><Button
                            size="sm"
                            variant="outline-danger"
                            className="tdButton"
                            onClick={this.deleteFragment}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                            {row.status === 'executed' && (
                                <Button
                                    size="sm"
                                    variant="outline-secondary"
                                    className="tdButton"
                                    onClick={this.viewFragment}
                                >
                                    <FontAwesomeIcon icon={faBars} />
                                </Button>

                            )}
                        </React.Fragment>
                    }}

                />

            </Container>
        );
    }
}

export default withRouter(Job);
