import axios from "axios";

import Config from "./Config";
import Memory from "./MemoryService";
import APIHelper from "./APIHelper";

let cancels = {};
const MerchantService = {
    typeahead(search, includePreviousNames = false, websiteId = 0) {
        if (typeof cancels['typeahead'] !== 'undefined') {
            cancels['typeahead'].cancel("Operation canceled due to new request.");
        }
        cancels['typeahead'] = axios.CancelToken.source();
        return axios
            .post(Config.API_URL + "merchant/search", {
                name: search,
                previous_names: includePreviousNames,
                website_id: websiteId
            }, {
                cancelToken: cancels['typeahead'].token
            })
            .then(APIHelper.returnItemsOrEmptyArray)
            .catch((error) => {
                if (typeof error.message !== "undefined" && /Operation canceled/.test(error.message)) {
                    return null;
                }
                return [];
            });
    },
    get(id) {
        return axios
            .get(Config.API_URL + "merchant/" + id)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    notes(id) {
        return axios
            .get(Config.API_URL + "merchant/" + id + '/notes')
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    update(row, updateAllLinkedMerchants = false) {
        let payload = Object.assign({}, row);
        if (typeof payload.country !== "undefined" && payload.country.length) {
            payload.country_id = payload.country[0].value;
            delete payload.country;
        }
        let allowedFields = ['address_1', 'address_2', 'contact_email', 'phone', 'fax', 'city', 'state', 'zip', 'country_id', 'logo_img_url', 'merchant_url', 'bad_contact_emails', 'dba_name', 'related_group_id'];
        Object.keys(payload).forEach((k) => {
            if (allowedFields.indexOf(k) === - 1) {
                delete payload[k];
            }
        });
        payload.updateAllLinkedMerchants = updateAllLinkedMerchants;
        return axios
            .put(Config.API_URL + "merchant/" + row.id, payload)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    addNote(merchant_id, text, add2all) {
        return axios
            .post(Config.API_URL + "merchant/" + merchant_id + '/note', { entry: text, addToAllChannels: add2all })
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    updateNote(note_id, text) {
        return axios
            .put(Config.API_URL + "merchant/0/note/" + note_id, { entry: text })
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    deleteNote(note_id) {
        return axios
            .delete(Config.API_URL + "merchant/0/note/" + note_id)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    linkedChannels(merchant_id) {
        return axios
            .get(Config.API_URL + "merchant/" + merchant_id + '/linkedchannels')
            .then(response => APIHelper.returnAttribute(response, 'channels'))
            .catch(APIHelper.returnFalse);
    },
    removeLinkedChannel(merchant_id, merchant_id2) {
        return axios
            .delete(Config.API_URL + "merchant/" + merchant_id + '/linkedchannel/' + merchant_id2)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    linkMerchants(merchant_id, merchant_id2) {
        return axios
            .put(Config.API_URL + "merchant/" + merchant_id + '/linkedchannel/' + merchant_id2)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    summary(id) {
        return axios
            .get(Config.API_URL + "merchant/" + id + "/summary")
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    brands(id) {
        return axios
            .get(Config.API_URL + "merchant/" + id + "/brands")
            .then(APIHelper.returnItems)
            .catch(APIHelper.returnFalse);
    },
    dailyVolume(id, brand) {
        return axios
            .get(Config.API_URL + "merchant/" + id + "/daily/" + brand)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    trends(id) {
        return axios
            .get(Config.API_URL + "merchant/" + id + "/trends")
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    /**
     * Retrieves and stores the Merchant name in memory for rendering the Breadcrumbs of /merchant
     * @param {int} id 
     * @returns 
     */
    breadcrumb(id) {
        return new Promise((resolve, reject) => {
            if (Memory.Has('merchant/' + id)) {
                resolve(Memory.Get('merchant/' + id));
            } else {
                axios
                    .get(Config.API_URL + "merchant/" + id + "/name")
                    .then((response) => {
                        if (APIHelper.checkStatus(response)) {
                            Memory.Set('merchant/' + id, response.data.name);
                            resolve(response.data.name);
                        }
                        reject();
                    })
                    .catch((error) => {
                        reject();
                    });
            }
        });
    }
};

export default MerchantService;
