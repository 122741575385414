import React from "react";

import {
    Container,
    Breadcrumb,
    Button,
    Form
} from "react-bootstrap";
import AsinDiscoveryService from "../../services/AsinDiscoveryService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faRedo, faPlus } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import Emitter from "../../services/Emitter";
import confirm from "../../components/Confirm";
import Toast from "../../services/Toast";
import Utils from "../../services/Utils";
import ProgressBar from "../../components/ProgressBar";
import MyTable from "../../components/MyTable";
import { createRef } from "react";
import MySpinner from "../../components/MySpinner";
import SelectRows from "../../components/SelectRows";

/**
 * Jobs component
 * @component
 * @category Scenes
 * @subcategory Asin Discovery
 */
class JobsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selection: 'none',
            selected: 0,
        };
        this.tableRef = createRef();

        this.handleDownload = this.handleDownload.bind(this);
        this.handleUploaded = this.handleUploaded.bind(this);
        this.changeSelection = this.changeSelection.bind(this);
        this.withSelected = this.withSelected.bind(this);
        this.reload = this.reload.bind(this);

        Emitter.on("RELOAD_AD_JOBS", this.reload);
    }

    componentDidMount() {
        this.reload();
    }

    componentWillUnmount() {
        Emitter.off("RELOAD_AD_JOBS", this.reload);
    }

    reload() {
        return this.tableRef.current?.reload();
    }


    handleDownload(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt),
            jobId = obj.row.id;

        obj.row.downloading = true;
        this.tableRef.current.setRow(obj.index, obj.row);
        AsinDiscoveryService.getResults(jobId).then((result) => {
            if (!result) {
                Toast.error(
                    "Something went wrong while generating the report."
                );
            }
            obj.row.downloading = false;
            this.tableRef.current.setRow(obj.index, obj.row);
        });
    }

    handleUploaded(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt),
            jobId = obj.row.id,
            checked = evt.target.checked;

        obj.row.updating = true;

        this.tableRef.current.setRow(obj.index, obj.row);
        AsinDiscoveryService.setUploaded(jobId, checked).then((result) => {
            obj.row.updating = false;
            if (result) {
                obj.row.uploaded = checked ? "checked" : null;
                Toast.ok("Row updated successfully");
            } else {
                Toast.error("Something went wrong while updating this row");
            }
            this.tableRef.current.setRow(obj.index, obj.row);
        });
    }

    changeSelection(evt) {
        const data = this.tableRef.current.data(false);
        if (evt === 'all') {
            data.forEach((row) => {
                row.selected = true;
                this.tableRef.current.setRow(row.index, row);
            });
            this.setState({ selection: 'all', selected: data.length });
            return;
        }
        if (evt === 'none') {
            data.forEach((row) => {
                delete row.selected;
                this.tableRef.current.setRow(row.index, row);
            });
            this.setState({ selection: 'none', selected: 0 });
            return;
        }
        if (evt === 'invert') {
            let selected = 0;
            data.forEach((row) => {
                if (typeof row.selected === "undefined" || row.selected === false) {
                    row.selected = true;
                    selected++;
                } else {
                    delete row.selected;
                }
                this.tableRef.current.setRow(row.index, row);
            });
            this.setState({ selected });
            return;
        }

        const obj = this.tableRef.current.getRowFromEvent(evt);
        const checked = evt.target.checked;
        const checkedRows = data.filter((row) => row.selected).length + (checked ? 1 : -1);
        const partial = checkedRows && checkedRows < data.length;
        obj.row.selected = checked;
        this.tableRef.current.setRow(obj.index, obj.row);
        this.setState({
            selection: partial ? 'partial' : (checked ? 'all' : 'none'),
            selected: checkedRows,
        });
    }

    async withSelected(action) {
        if (action !== 'resume') {
            const extra = action === 'delete'
                ? " This operation can't be undone. Do you want to proceed?"
                : '';
            if (!await confirm("Are you sure?", "Yes", "No", {
                message: `Are you sure you want to ${action} the selected jobs?${extra}`,
            })) {
                return;
            }
        }

        this.tableRef.current.setLoading(true);
        const ids = this.tableRef.current.data(false).filter((row) => row.selected).map((row) => row.id);
        AsinDiscoveryService.handleJobs(action, ids).then((result) => {
            if (result) {
                Toast.ok("Action performed successfully");
                this.reload();
                return;
            }

            Toast.error("Something went wrong while performing the action");
            this.tableRef.current.setLoading(false);
        });
    }

    render() {
        const label = this.state.selected > 1 ? 'Jobs' : 'Job';
        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>ASIN Discovery</Breadcrumb.Item>
                    <Breadcrumb.Item active>Jobs</Breadcrumb.Item>
                </Breadcrumb>
                <MyTable
                    name="asin-jobs"
                    ref={this.tableRef}
                    dynamic={true}
                    sortBy="created"
                    sortOrder="desc"
                    headers={
                        [
                            {
                                field: 'actions',
                                label: 'Bulk',
                                content: <SelectRows
                                    state={this.state.selection}
                                    onSelect={this.changeSelection}
                                    actions={[
                                        {
                                            label: `Pause ${label}`,
                                            onClick: () => this.withSelected('pause')
                                        },
                                        {
                                            label: `Resume ${label}`,
                                            onClick: () => this.withSelected('resume')
                                        },
                                        {
                                            label: `Delete ${label}`,
                                            onClick: () => this.withSelected('delete')
                                        }
                                    ]}
                                />
                            },
                            {
                                field: 'id',
                                label: 'ID',
                                width: "45px",
                                sortable: true,
                                searchable: false,
                            },
                            {
                                field: 'manufacturer',
                                label: "Manufacturer",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: 'created',
                                width: "140px",
                                label: "Requested",
                                sortable: true,
                                searchable: false
                            },
                            {
                                field: 'requester',
                                label: "Requester",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: 'duration',
                                width: "100px",
                                label: "Duration",
                                sortable: true,
                                searchable: false
                            },
                            {
                                field: 'products',
                                width: "100px",
                                label: "Products",
                                sortable: true,
                                searchable: false
                            },
                            {
                                field: 'progress',
                                width: "70px",
                                label: "Progress",
                                sortable: false,
                                searchable: false
                            },
                            {
                                field: 'status',
                                width: "90px",
                                label: "Status",
                                sortable: true,
                                searchable: false
                            },
                            {
                                field: 'uploaded',
                                label: 'Uploaded',
                                width: "85px",
                                sortable: true,
                                searchable: false
                            },
                            {
                                field: 'control',
                                width: "80px",
                                content: (
                                    <React.Fragment>
                                        <Button
                                            size="sm"
                                            variant="primary"
                                            className="tdButton"
                                            onClick={() => {
                                                this.reload().then((result) => {
                                                    if (result) {
                                                        Toast.ok("Data reloaded successfully");
                                                    }
                                                })
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faRedo} />
                                        </Button>
                                        <Button
                                            as={NavLink}
                                            to="/ad/jobs#new"
                                            size="sm"
                                            variant="success"
                                            className="tdButton">
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                    </React.Fragment>
                                )
                            }
                        ]
                    }
                    reload={(setup) => {
                        return new Promise((resolve, reject) => {
                            AsinDiscoveryService.jobs({
                                pageSize: setup.pageSize,
                                page: setup.page,
                                search: setup.search,
                                sort: setup.sort,
                            }).then((data) => {
                                if (data === false) {
                                    Toast.error("Something went wrong while retrieving the list of jobs");
                                    reject();
                                    return;
                                }
                                for (let i = 0; i < data.rows.length; i++) {
                                    data.rows[i].downloading = false;
                                }
                                this.setState(
                                    { selection: 'none', selected: 0 },
                                    () => resolve(data)
                                );
                            }).catch(reject);
                        })
                    }}
                    renderColumns={{
                        actions: (row) => <Form.Check
                            className="lg"
                            checked={row.selected ?? false}
                            onClick={this.changeSelection}
                        />,
                        duration: (row) => Utils.Seconds2PrettyString(row.duration),
                        progress: (row) => <ProgressBar
                            key={`progress-bar-${row.id}`}
                            bgcolor="#1f4503"
                            completed={
                                ((row.products - row.pending) * 100) / row.products
                            }
                        />,
                        status: (row) => row.status.toUpperCase(),
                        uploaded: {
                            className: "text-center",
                            format: (row) =>
                                row.status === 'finished' && (typeof row.updating === "undefined" || row.updating === false) ?
                                    <Form.Check className="lg" type="checkbox" checked={row.uploaded !== null && row.uploaded.length > 0} onChange={this.handleUploaded} />
                                    : (
                                        typeof row.updating !== "undefined" && row.updating ?
                                            <MySpinner />
                                            : null

                                    )
                        },
                        control: {
                            className: "text-center",
                            format: (row) => row.downloading ?
                                <MySpinner />
                                :
                                <Button
                                    disabled={row.status !== "finished"}
                                    size="sm"
                                    variant="outline-secondary"
                                    className="tdButton"
                                    onClick={this.handleDownload}
                                >
                                    <FontAwesomeIcon icon={faDownload} />
                                </Button>
                        }
                    }}
                />
            </Container>
        )
    }
}

export default JobsList;
