import React from "react";
import PropTypes from "prop-types";

import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Countries from "../../components/Countries";
import Toast from "../../services/Toast";
import Loading from "../Loading";
import Emitter from "../../services/Emitter";
import MerchantService from "../../services/MerchantService";
import confirm from "../../components/Confirm";
import ProcessingButton from "../../components/ProcessingButton";

/**
 * Show a CRUD for the Website row
 * @component
 * @category Scenes
 * @subcategory Websites
 */
class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState();

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCountry = this.handleChangeCountry.bind(this);
        this.dropEdition = this.dropEdition.bind(this);
        this.startEdition = this.startEdition.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.reload = this.reload.bind(this);
    }

    initialState() {
        return {
            id: 0,
            row: {},
            loading: true,
            editing: false,
            updating: false,
        };
    }

    handleChangeCountry(selected) {
        let row = this.state.row;
        row.country_id = selected.length > 0 ? selected[0].value : null;
        row.country = selected;
        this.setState({ row });
    }

    handleChange(evt) {
        let key = evt.target.name,
            row = this.state.row,
            isCheckbox = evt.target.className.match(/check/);
        switch (key) {
            default:
                row[key] = isCheckbox
                    ? this.state.row[key]
                        ? 0
                        : 1
                    : evt.target.value;
        }
        this.setState({ row });
    }


    reload() {
        if (this.state.id === this.props.merchantId) {
            return;
        }
        MerchantService.get(this.props.merchantId).then((data) => {
            if (data === false) {
                return;
            }
            Object.keys(data).forEach((key) => {
                if (data[key] === null) {
                    data[key] = "";
                }
            });
            data.country = Countries.Selected([data.country_id]);
            this.setState({
                loading: false,
                row: data,
                editing: false,
                id: this.props.merchantId
            });
        });
    }

    componentWillUnmount() {
        Emitter.off("MERCHANT_PROFILE");
        Emitter.off("MERCHANT_RESET");
    }

    componentDidMount() {
        Emitter.on("MERCHANT_PROFILE", this.reload);
        Emitter.on("MERCHANT_RESET", () => {
            this.setState(this.initialState());
        });
    }

    saveChanges() {

        confirm("Apply changes to Linked Merchants?", "Yes", "No", {
            message: "Would you like to have this changes been set to all Linked Merchants as well?",
        }).then((updateLinkedMerchants) => {

            this.setState({ updating: true });
            MerchantService.update(this.state.row, updateLinkedMerchants).then((result) => {
                if (result === false) {
                    this.setState({ updating: false, editing: false });
                    Toast.error(
                        "Something wrong happened and changes could not be saved."
                    );
                } else {
                    this.setState({
                        updating: false,
                        editing: false
                    });
                    Toast.ok("Changes properly saved.");
                    Emitter.emit('MERCHANT_SUMMARY');
                }
            });

        })
    }

    startEdition() {
        this.original = Object.assign({}, this.state.row);
        this.setState({ editing: true });
    }

    dropEdition() {
        this.setState({ editing: false, row: this.original });
        delete this.original;
    }

    render() {
        if (this.state.loading) {
            return (
                <Container className="pt-4">
                    <Row
                        style={{
                            height: "100px",
                        }}
                    >
                        <Col>
                            <Loading />
                        </Col>
                    </Row>
                </Container>
            );
        }
        return (
            <Container className="pt-4">
                <Row>
                    <Col sm={3}>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="merchant_name"
                            value={this.state.row.merchant_name}
                            readOnly={true}
                        />
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Business Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="dba_name"
                            value={this.state.row.dba_name}
                            readOnly={
                                !this.state.editing || this.state.updating
                            }
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Seller ID</Form.Label>
                        <Form.Control
                            type="text"
                            name="seller_id"
                            value={this.state.row.seller_id}
                            readOnly={true}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Created</Form.Label>
                        <Form.Control
                            type="text"
                            name="created_at"
                            value={this.state.row.created_at}
                            readOnly={true}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Modified</Form.Label>
                        <Form.Control
                            type="text"
                            name="modified_at"
                            value={this.state.row.modified_at}
                            readOnly={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <Form.Label>URL</Form.Label>
                        <Form.Control
                            type="text"
                            name="merchant_url"
                            value={this.state.row.merchant_url}
                            readOnly={true}
                        />
                    </Col>
                    <Col sm={4}>
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control
                            type="text"
                            name="contact_email"
                            value={this.state.row.contact_email}
                            onChange={this.handleChange}
                            readOnly={
                                !this.state.editing || this.state.updating
                            }
                        />
                    </Col>
                    <Col sm={4}>
                        <Form.Label>Bad e-mails</Form.Label>
                        <Form.Control
                            type="text"
                            name="bad_contact_emails"
                            value={this.state.row.bad_contact_emails}
                            onChange={this.handleChange}
                            readOnly={
                                !this.state.editing || this.state.updating
                            }
                        />
                    </Col>
                </Row>
                <Row
                    style={{
                        marginTop: "30px",
                    }}
                >
                    <Col sm={4}>
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                            type="text"
                            name="phone"
                            value={this.state.row.phone}
                            onChange={this.handleChange}
                            readOnly={
                                !this.state.editing || this.state.updating
                            }
                        />
                    </Col>
                    <Col sm={4}>
                        <Form.Label>Fax</Form.Label>
                        <Form.Control
                            type="text"
                            name="fax"
                            value={this.state.row.fax}
                            onChange={this.handleChange}
                            readOnly={
                                !this.state.editing || this.state.updating
                            }
                        />
                    </Col>
                    <Col sm={4}>
                        <Form.Label>Logo</Form.Label>
                        <Form.Control
                            type="text"
                            name="logo_img_url"
                            value={this.state.row.logo_img_url}
                            readOnly={true}
                        />
                    </Col>
                </Row>
                <Row
                    style={{
                        marginTop: "30px",
                    }}
                >
                    <Col sm={6}>
                        <Form.Label>Address (Line 1)</Form.Label>
                        <Form.Control
                            type="text"
                            name="address_1"
                            value={this.state.row.address_1}
                            onChange={this.handleChange}
                            readOnly={
                                !this.state.editing || this.state.updating
                            }
                        />
                    </Col>
                    <Col sm={6}>
                        <Form.Label>Address (Line 2)</Form.Label>
                        <Form.Control
                            type="text"
                            name="address_2"
                            value={this.state.row.address_2}
                            onChange={this.handleChange}
                            readOnly={
                                !this.state.editing || this.state.updating
                            }
                        />
                    </Col>
                </Row>
                <Row
                    style={{
                        marginTop: "30px",
                    }}
                >
                    <Col sm={3}>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                            type="text"
                            name="city"
                            value={this.state.row.city}
                            onChange={this.handleChange}
                            readOnly={
                                !this.state.editing || this.state.updating
                            }
                        />
                    </Col>
                    <Col sm={3}>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                            type="text"
                            name="state"
                            value={this.state.row.state}
                            onChange={this.handleChange}
                            readOnly={
                                !this.state.editing || this.state.updating
                            }
                        />
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                            type="text"
                            name="zip"
                            value={this.state.row.zip}
                            onChange={this.handleChange}
                            readOnly={
                                !this.state.editing || this.state.updating
                            }
                        />
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Country</Form.Label>
                        <Countries
                            singleValue={true}
                            name="country"
                            disabled={
                                !this.state.editing || this.state.updating
                            }
                            value={this.state.row.country}
                            readOnly={
                                !this.state.editing || this.state.updating
                            }
                            onChange={this.handleChangeCountry}
                        />
                    </Col>
                </Row>
                <Row
                    style={{
                        marginTop: "30px",
                        height: "350px",
                    }}
                >
                    <Col sm={6}>
                        {!this.state.editing && (
                            <Button
                                variant="secondary"
                                onClick={this.startEdition}
                            >
                                Edit
                            </Button>
                        )}
                        {this.state.editing && (
                            <span>
                                <Button
                                    disabled={this.state.updating}
                                    variant="secondary"
                                    onClick={this.dropEdition}
                                >
                                    Cancel
                                </Button>
                                <ProcessingButton
                                    variant="primary"
                                    processing={this.state.updating}
                                    processingLabel="Saving ..."
                                    label="Save"
                                    onClick={this.saveChanges}
                                />
                            </span>
                        )}
                    </Col>
                </Row>
            </Container>
        );
    }
}

Profile.propTypes = {
    /** Merchant ID */
    merchantId: PropTypes.number.isRequired,
};

export default Profile;
