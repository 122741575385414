import axios from "axios";

import Config from "./Config";
import APIHelper from "./APIHelper";

class AmazonService {
    static AMAZON_ID = 1;
    static #amazonWebsites = null;

    static Inject(list) {
        AmazonService.#amazonWebsites = list;        
    }

    static Load(list = null) {
        return new Promise((resolve, reject) => {
            if (list !== null) {
                AmazonService.#amazonWebsites = list;
                resolve(AmazonService.#amazonWebsites);
                return;
            }
            if (AmazonService.#amazonWebsites === null) {
                axios.get(Config.API_URL + "website/amazon")
                    .then((response) => {
                        if (APIHelper.checkStatus(response)) {
                            AmazonService.#amazonWebsites = response.data.items;
                            resolve(AmazonService.#amazonWebsites);
                            return;
                        }
                        reject();
                    })
                    .catch(() => {
                        reject();
                    });
            } else {
                resolve(AmazonService.#amazonWebsites);
            }
        });
    }

    /**
     * Should return a list of Amazon websites filtered by country ID. If the country ID is not found,
     * it should include Amazon.com website among results.
     */
    static async FilterByCountryID(countryID) {
        return await AmazonService.Load().then((items) => {
            const filter = typeof countryID === "number" ? [countryID] : countryID;
            let amazonComIsAmongResult = false,
                amazonProfile,
                filteredList = items.filter((website) => {
                    const shouldInclude = filter.indexOf(website.country_id) !== -1;
                    if (website.id === AmazonService.AMAZON_ID) {
                        amazonProfile = website;
                        if (shouldInclude) {
                            amazonComIsAmongResult = true;
                        }
                    }
                    return shouldInclude;
                }),
                missingCountries = filter.length - filteredList.length > 0;

            return missingCountries && !amazonComIsAmongResult ? [amazonProfile, ...filteredList] : filteredList;
        }).catch(() => false);
    }
};

export default AmazonService;