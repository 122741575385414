import React, { createRef } from "react";
import PropTypes from "prop-types";

import { Link, withRouter } from "react-router-dom";
import Toast from "../../services/Toast";
import { Container, Button, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";
import Emitter from "../../services/Emitter";
import prompt from "../../components/Prompt";
import OrganizationService from "../../services/OrganizationService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import MyTable from "../../components/MyTable";


/**
 * @component
 * @category Scenes
 * @subcategory Website
 */
class Brands extends React.Component {
    constructor(props) {
        super(props);
        this.onEnter = this.onEnter.bind(this);
        this.tableRef = createRef();
    }

    componentDidMount() {
        Emitter.once("ORG_BRANDS", this.onEnter);
    }

    componentWillUnmount() {
        Emitter.off("ORG_BRANDS");
    }

    onEnter() {
        OrganizationService.brands(this.props.orgId).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of merchants");
                return;
            }
            this.tableRef.current?.populate(data);
        });
    }

    render() {
        return (
            <Container className="pt-4">
                <MyTable
                    name="organization-brands"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="name"
                    sortOrder="asc"
                    headers={[
                        {
                            field: "name",
                            label: "Brand",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "active",
                            width: "120px",
                            label: "Active",
                            sortable: true,
                            allowEmpty: true,
                            options: [{ label: "Active", value: "1" }, { label: "Disabled", value: "0" }]
                        },
                        {
                            field: "control",
                            width: "10px",
                            content: <OverlayTrigger
                                placement="left"
                                trigger={["hover", "focus"]}
                                overlay={
                                    <Popover>
                                        <PopoverBody>
                                            Create new brand
                                        </PopoverBody>
                                    </Popover>
                                }
                            >
                                <Button onClick={() => {
                                    prompt("Create new Brand", "Create", "Cancel", "Brand name:", '').then((evt) => {
                                        const name = evt.value.trim();
                                        if (name.length) {
                                            OrganizationService.newBrand(this.props.orgId, name).then((id) => {
                                                if (id === false) {
                                                    Toast.error("Something went wrong while creating the new organization");
                                                    return;
                                                }
                                                this.tableRef.current.addRow({
                                                    id,
                                                    name,
                                                    active: 0
                                                });
                                                Toast.ok("Brand created successfully");
                                            });
                                        }
                                    }).catch(() => { });
                                }} variant="success" className="tdButton" size="sm">
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </OverlayTrigger>
                        }]}
                    renderColumns={{
                        name: (row) => <Link className="link" to={`/brand/${row.id}#profile`}>(#{row.id}) {row.name}</Link>,
                        active: {
                            className: "text-center",
                            format: (row) => row.active ? <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} /> : <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
                        }
                    }}
                />

            </Container>
        );
    }
}

Brands.propTypes = {
    orgId: PropTypes.number.isRequired,
};

export default withRouter(Brands);
