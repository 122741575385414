import axios from "axios";

import Config from "./Config";
import APIHelper from "./APIHelper";

const AsinDiscoveryService = {
    /**
     * Retrieves jobs
     * @param {object} settings Related to pagination settings: page, pageSize, sort, search
     * @returns {array} of rows
     */
    jobs(settings) {
        return axios
            .post(Config.API_URL + "ad/jobs", settings)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    /**
     * Dispatch a new job
     * @param {object} settings Brands & Keywords
     * @returns {bool} Has the new job been properly scheduled?
     */
    request(settings) {
        return axios
            .post(Config.API_URL + "ad/job", settings)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    /**
     * Checks how many new products and recently added products there are for a given manufacturer
     * @param {int} manufacturer_id
     * @returns {mixed} false if something goes wrong or an object otherwise 
     */
    summary(id, country_ids) {
        return axios
            .post(Config.API_URL + "manufacturer/" + id + "/products/ad", { countries: country_ids })
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    getResults(id) {
        return axios
            .get(Config.API_URL + "ad/job/" + id + "/csv")
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data.report])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    "asin-discovery_manufacturer" + response.data.job.manufacturer.id + ".csv"
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
                return true;
            })
            .catch(APIHelper.returnFalse);
    },
    setUploaded(id, mode) {
        return axios
            .put(Config.API_URL + "ad/job/" + id, {
                uploaded: mode
            })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    handleJobs(action, ids) {
        return axios.post(Config.API_URL + "ad/jobs/" + action, {
                ids: ids
            })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
};

export default AsinDiscoveryService;
