import { Switch, Route, useHistory } from "react-router-dom";
import Welcome from "./scenes/Welcome";
import NoMatch from "./scenes/NoMatch";
import Logout from "./scenes/Auth/Logout";
import Forbidden from "./scenes/Auth/Forbidden";
import OAuth from "./scenes/Auth/OAuth";
import WebsiteSearch from "./scenes/Website/Search";
import BrandList from "./scenes/Brand/List";
import ManufacturerList from "./scenes/Manufacturer/List"
import ProductSearch from "./scenes/Product/Search";
import WebsiteIndex from "./scenes/Website/Index";
import BrandIndex from "./scenes/Brand/Index";
import ManufacturerIndex from "./scenes/Manufacturer/Index";
import OrganizationIndex from "./scenes/Organization/Index";
import UserList from "./scenes/User/List";
import UserIndex from "./scenes/User/Index";
import ProductIndex from "./scenes/Product/Index";
import MerchantIndex from "./scenes/Merchant/Index";
import MerchantSearch from "./scenes/Merchant/Search";
import BlackListTLD from "./scenes/DomainDiscovery/BlackListTLD";
import BlackListDomain from "./scenes/DomainDiscovery/BlackListDomain";
import BlackListSubdomain from "./scenes/DomainDiscovery/BlackListSubdomain";
import DomainOverride from "./scenes/DomainDiscovery/Override";
import DDLogs from "./scenes/DomainDiscovery/Log";
import RejectedProduct from "./scenes/Diagnostics/RejectedProducts/Index";
import MissingWebsites from "./scenes/Diagnostics/MissingWebsites/Index";
import DDJobs from "./scenes/DomainDiscovery/Jobs";
import ADJobs from "./scenes/AsinDiscovery/Jobs";
import PDDJobs from "./scenes/PDD/Jobs";
import PDDDisabledDashboards from "./scenes/PDD/Disabled";
import PDDManualReview from "./scenes/PDD/ManualReview";
import IMGJobs from "./scenes/ImageDiscovery/Jobs";
import URLUploader from "./scenes/Others/URLUploader";
import Logs from "./scenes/Logs/Index";
import Settings from "./scenes/Others/Settings";
import PDGauge from "./scenes/Others/PD";
import CrawlingScheduler from "./scenes/Others/CrawlingScheduler";
import Bucketization from "./scenes/Onboarding/Bucketization/Index"
import XPath from "./scenes/Onboarding/XPath/List"
import Releases from "./scenes/Releases";
import OrganizationList from "./scenes/Organization/List";
import DoNotMap from "./scenes/Others/DoNotMap";
import PriceViolations from "./scenes/PriceViolations/Index";
import Gauges from "./scenes/Others/NPD/Gauges";
import SmartSelection from "./scenes/Others/NPD/SmartSelection";
import Crawler from "./scenes/Others/NPD/Crawler";
import Matcher from "./scenes/Others/NPD/Matcher";
import Autofeeder from "./scenes/Others/NPD/Autofeeder"
import Download from "./scenes/Others/NPD/Download"
import URLChecker from "./scenes/Others/URLChecker";
import URLRemover from "./scenes/Others/URLRemover";
import Websites from "./scenes/Others/NPD/Websites";
import ProductDiscoveryAnalyzer from "./scenes/Others/ProductDiscoveryAnalyzer";
import CrawlerDetails from "./scenes/Website/CrawlerDetails";
import Maintenance from "./scenes/Maintenance";
import GoogleShoppingPD from "./scenes/Onboarding/Bucketization/GoogleShoppingPD";
import Formula from "./scenes/CostExplorer/Formula";
import React, { useEffect } from "react";
import Emitter from "./services/Emitter";
import AssetCostPerEntity from "./scenes/CostExplorer/AssetCostPerEntity";
import CostPerEntity from "./scenes/CostExplorer/CostPerEntity";
import Bills from "./scenes/CostExplorer/Bills";
import URLMappings from "./scenes/Others/URLMappings";
import Auth from "./services/AuthService";
import Partner from "./scenes/Partner/Index"
import { PERMISSION_TYPE } from "./services/UsersPermission";

const ProtectedRoute = (props) => {
    if (!props.permission || !Auth.hasAccessTo(props.permission)) return null;
    return <Route key={props.key} path={props.path} component={props.component} />;
}

const PublicRoutes = () => {
    return (
        <Switch>
            <Route key="oauth.code" path="/oauth/" component={OAuth} />
            <Route key="logout" path="/user/logout" component={Logout} />
            <Route key="forbidden" path="/forbidden" component={Forbidden} />
            <Route key="maintenance" path="/maintenance" component={Maintenance} />
            <Route key="oauth" path="/" component={OAuth} />
        </Switch>
    )
}

const PrivateRoutes = () => {
    return (
        <Switch>
            <Route key="oauth.code" path="/oauth/" component={OAuth} />
            <Route key="home" exact path="/" component={Welcome} />
            <Route key="logout" path="/user/logout" component={Logout} />
            <Route key="website.search" exact path="/website" component={WebsiteSearch} />
            <Route key="manufacturer.list" exact path="/manufacturer" component={ManufacturerList} />
            <Route key="organization.list" exact path="/organization" component={OrganizationList} />
            <Route key="user.search" exact path="/user" component={UserList} />
            <Route key="brand.list" exact path="/brand" component={BrandList} />
            <Route key="crawler-details" exact path="/crawler-details" component={CrawlerDetails} />
            <Route key="product.search" exact path="/product" component={ProductSearch} />
            <Route key="merchant.search" exact path="/merchant" component={MerchantSearch} />
            <Route key="diags.rp" exact path="/diags/rp" component={RejectedProduct} />
            <Route key="diags.mw" exact path="/diags/mw" component={MissingWebsites} />
            <Route key="diags.price-violations" path="/diags/price-violations" component={PriceViolations} />
            <Route key="user.preferences" path="/user/preferences" component={Settings} />
            <Route key="website.profile" exact path="/website/:id" component={WebsiteIndex} />
            <Route key="brand.profile" exact path="/brand/:id" component={BrandIndex} />
            <Route key="manufacturer.profile" exact path="/manufacturer/:id" component={ManufacturerIndex} />
            <Route key="organization.profile" exact path="/organization/:id" component={OrganizationIndex} />
            <Route key="product.profile" exact path="/product/:id" component={ProductIndex} />
            <Route key="merchant.profile" exact path="/merchant/:id" component={MerchantIndex} />
            <Route key="user.profile" exact path="/user/:id" component={UserIndex} />
            <Route key="partner.list" path="/partner" component={Partner} />
            <Route key="partner.new" path="/partner/new" component={Partner} />
            <Route key="partner.edit" path="/partner/edit/:id" component={Partner} />
            <ProtectedRoute permission={PERMISSION_TYPE.COSTS} key="costexplorer.bills-last-week" path="/costexplorer/bills-last-week" component={Bills} />
            <ProtectedRoute permission={PERMISSION_TYPE.COSTS} key="costexplorer.bills-per-month" path="/costexplorer/bills-last-semester" component={Bills} />
            <ProtectedRoute permission={PERMISSION_TYPE.COSTS} key="costexplorer.formula" path="/costexplorer/formula" component={Formula} />
            <ProtectedRoute permission={PERMISSION_TYPE.COSTS} key="costexplorer.assetcostperentity" path="/costexplorer/asset-entity" component={AssetCostPerEntity} />
            <ProtectedRoute permission={PERMISSION_TYPE.COSTS} key="costexplorer.costperentity" path="/costexplorer/entity" component={CostPerEntity} />
            <Route key="dd.blacklist.tld" path="/dd/blacklist/tld" component={BlackListTLD} />
            <Route key="dd.blacklist.domain" path="/dd/blacklist/domain" component={BlackListDomain} />
            <Route key="dd.blacklist.subdomain" path="/dd/blacklist/subdomain" component={BlackListSubdomain} />
            <Route key="dd.override" path="/dd/override" component={DomainOverride} />
            <Route key="dd.logs" path="/dd/logs" component={DDLogs} />
            <Route key="dd.jobs" path="/dd" component={DDJobs} />
            <Route key="dd.jobs.new" path="/dd/job/new" component={DDJobs} />
            <Route key="dd.job" path="/dd/job/:id" component={DDJobs} />
            <ProtectedRoute permission={PERMISSION_TYPE.PDD} key="pdd.jobs" path="/pdd/jobs" component={PDDJobs} />
            <ProtectedRoute permission={PERMISSION_TYPE.PDD} key="pdd.review" path="/pdd/review" component={PDDManualReview} />
            <ProtectedRoute permission={PERMISSION_TYPE.PDD} key="pdd.disabled.hasboards" path="/pdd/disabled" component={PDDDisabledDashboards} />
            <Route key="ad.jobs" path="/ad/jobs" component={ADJobs} />
            <Route key="img.disc" path="/imgdiscovery" component={IMGJobs} />
            <Route key="img.disc.new" path="/imgdiscovery/new" component={IMGJobs} />
            <Route key="img.disc.job" path="/imgdiscovery/job/:id" component={IMGJobs} />
            <Route key="onboarding.bucketization.gs" path="/onboarding/bucketization/gs-pd" component={GoogleShoppingPD} />
            <Route key="onboarding.bucketization" path="/onboarding/bucketization" component={Bucketization} />
            <Route key="onboarding.bucketization.job" path="/onboarding/bucketization/job/:id" component={Bucketization} />
            <Route key="onboarding.xpath" path="/onboarding/xpath" component={XPath} />
            <Route key="other.urlmappings" path="/others/mappings" component={URLMappings} />
            <Route key="other.url" path="/others/url" component={URLUploader} />
            <Route key="other.urlchecker" path="/others/urlchecker" component={URLChecker} />
            <Route key="other.urlremover" path="/others/urlremover" component={URLRemover} />
            <Route key="other.product-discovery-analyzer" path="/others/product-discovery-analyzer" component={ProductDiscoveryAnalyzer} />
            <Route key="other.pd" path="/others/pd" component={PDGauge} />
            <Route key="other.scheduler" path="/others/scheduler" component={CrawlingScheduler} />
            <Route key="other.donotmap" path="/others/donotmap" component={DoNotMap} />
            <Route key="npd.smartselection" path="/npd/smartselection" component={SmartSelection} />
            <ProtectedRoute permission={PERMISSION_TYPE.NPD} key="npd.gauges" path="/npd/gauges" component={Gauges} />
            <ProtectedRoute permission={PERMISSION_TYPE.NPD} key="npd.crawler" path="/npd/crawler" component={Crawler} />
            <ProtectedRoute permission={PERMISSION_TYPE.NPD} key="npd.matcher" path="/npd/matcher" component={Matcher} />
            <ProtectedRoute permission={PERMISSION_TYPE.NPD} key="npd.autofeeder" path="/npd/autofeeder" component={Autofeeder} />
            <ProtectedRoute permission={PERMISSION_TYPE.NPD} key="npd.download" path="/npd/download" component={Download} />
            <ProtectedRoute permission={PERMISSION_TYPE.NPD} key="npd.websites" path="/npd/websites" component={Websites} />
            <ProtectedRoute permission={PERMISSION_TYPE.LOGS} key="logs.all" path="/logs" component={Logs} />
            <ProtectedRoute permission={PERMISSION_TYPE.LOGS} key="logs.system" path="/logs/system" component={Logs} />
            <ProtectedRoute permission={PERMISSION_TYPE.LOGS} key="logs.users" path="/logs/users" component={Logs} />
            <Route key="logs.crawlers" path="/logs/crawlers" component={Logs} />
            <Route key="releases" path="/releases" component={Releases} />
            <Route key="404" path="*" component={NoMatch} />
        </Switch>
    )
}

function Routes(props) {
    const history = useHistory();

    useEffect(() => {
        trackPageView();
        history.listen(trackPageView); // Track all subsequent pageviews
    }, [history]);

    function trackPageView(location, action) {
        Emitter.emit("NAVIGATE", { location, action });
    }

    return props.authenticated ? <PrivateRoutes /> : <PublicRoutes />;
}

export default Routes;
