import React from "react";
import PropTypes from "prop-types";

import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import prompt from "./Prompt";
import "./Pagination.css";

/**
 * Component in charge of drawing pagination elements control (such as Next & Previous page buttons)
 * @component
 */
class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.middleButtons = this.middleButtons.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handlePageSizeClick = this.handlePageSizeClick.bind(this);
    }

    async handleClick(evt) {
        let page = evt.target.getAttribute("page");
        if (page === "?") {
            let p;
            try {
                p = await prompt("Which page you'd like to go to?", "Go", "Cancel", "", "", {
                    submitOnEnter: true,
                    numericField: true
                });
                page = p.value * 1;
            } catch (e) {
                page = 0;
            }
            if (page <= 0) {
                return;
            }
            if (page > this.props.numberOfPages) {
                page = this.props.numberOfPages;
            };
        }
        this.props.onChangePage(page * 1);
    }

    handlePageSizeClick(evt) {
        if (typeof this.props.onChangePageSize != "function") {
            return;
        }
        let newSize = evt.target.getAttribute("psize");
        this.props.onChangePageSize(newSize * 1);
    }

    middleButtons() {
        let buttons = [];
        if (this.props.numberOfPages <= 7) {
            for (let i = 0; i < this.props.numberOfPages; i++) {
                buttons.push(
                    <Button
                        key={`page${i + 1}`}
                        page={i + 1}
                        onClick={this.handleClick}
                        variant={
                            this.props.page === i + 1
                                ? "secondary"
                                : "outline-primary"
                        }
                        size="sm"
                        className={`pagButton ${this.props.allDisabled || i + 1 === this.props.page
                            ? "disabled"
                            : ""
                            }`}
                    >
                        {i + 1}
                    </Button>
                );
            }
        } else if (this.props.page <= 4) {
            for (let i = 0; i < 4; i++) {
                buttons.push(
                    <Button
                        key={`page${i + 1}`}
                        page={i + 1}
                        onClick={this.handleClick}
                        variant={
                            this.props.page === i + 1
                                ? "secondary"
                                : "outline-primary"
                        }
                        size="sm"
                        className={`pagButton ${this.props.allDisabled || i + 1 === this.props.page
                            ? "disabled"
                            : ""
                            }`}
                    >
                        {i + 1}
                    </Button>
                );
            }
            buttons.push(
                <Button
                    key="page5"
                    page="5"
                    onClick={this.handleClick}
                    variant="outline-primary"
                    size="sm"
                    className={`pagButton ${this.props.allDisabled ? "disabled" : ""
                        }`}
                >
                    5
                </Button>
            );
            buttons.push(
                <Button
                    key="pageEmpty"
                    variant="outline-primary"
                    size="sm"
                    className="pagButton"
                    page="?"
                    onClick={this.handleClick}
                >
                    ...
                </Button>
            );
            buttons.push(
                <Button
                    key={`page${this.props.numberOfPages}`}
                    page={this.props.numberOfPages}
                    onClick={this.handleClick}
                    variant="outline-primary"
                    size="sm"
                    className={`pagButton ${this.props.allDisabled ? "disabled" : ""
                        }`}
                >
                    {this.props.numberOfPages}
                </Button>
            );
        } else if (this.props.page >= this.props.numberOfPages - 3) {
            buttons.push(
                <Button
                    key="page1"
                    page="1"
                    onClick={this.handleClick}
                    variant="outline-primary"
                    size="sm"
                    className={`pagButton ${this.props.allDisabled ? "disabled" : ""
                        }`}
                >
                    1
                </Button>
            );
            buttons.push(
                <Button
                    key="pageEmpty"
                    variant="outline-primary"
                    size="sm"
                    className="pagButton"
                    page="?"
                    onClick={this.handleClick}
                >
                    ...
                </Button>
            );
            for (
                let i = this.props.numberOfPages - 5;
                i < this.props.numberOfPages;
                i++
            ) {
                buttons.push(
                    <Button
                        key={`page${i + 1}`}
                        page={i + 1}
                        onClick={this.handleClick}
                        variant={
                            this.props.page === i + 1
                                ? "secondary"
                                : "outline-primary"
                        }
                        size="sm"
                        className={`pagButton ${this.props.allDisabled || i + 1 === this.props.page
                            ? "disabled"
                            : ""
                            }`}
                    >
                        {i + 1}
                    </Button>
                );
            }
        } else {
            buttons.push(
                <Button
                    key="page1"
                    page="1"
                    onClick={this.handleClick}
                    variant="outline-primary"
                    size="sm"
                    className={`pagButton ${this.props.allDisabled ? "disabled" : ""
                        }`}
                >
                    1
                </Button>
            );
            buttons.push(
                <Button
                    key="empty1"
                    variant="outline-primary"
                    size="sm"
                    className="pagButton"
                    page="?"
                    onClick={this.handleClick}
                >
                    ...
                </Button>
            );
            buttons.push(
                <Button
                    key={`page${this.props.page - 1}`}
                    page={this.props.page - 1}
                    onClick={this.handleClick}
                    variant="outline-primary"
                    size="sm"
                    className={`pagButton ${this.props.allDisabled ? "disabled" : ""
                        }`}
                >
                    {this.props.page - 1}
                </Button>
            );
            buttons.push(
                <Button
                    key={`page${this.props.page}`}
                    page={this.props.page}
                    onClick={this.handleClick}
                    variant="secondary"
                    size="sm"
                    className="pagButton disabled"
                >
                    {this.props.page}
                </Button>
            );
            buttons.push(
                <Button
                    key={`page${this.props.page + 1}`}
                    page={this.props.page + 1}
                    onClick={this.handleClick}
                    variant="outline-primary"
                    size="sm"
                    className={`pagButton ${this.props.allDisabled ? "disabled" : ""
                        }`}
                >
                    {this.props.page + 1}
                </Button>
            );
            buttons.push(
                <Button
                    key="empty2"
                    variant="outline-primary"
                    size="sm"
                    className="pagButton"
                    page="?"
                    onClick={this.handleClick}
                >
                    ...
                </Button>
            );
            buttons.push(
                <Button
                    key={`page${this.props.numberOfPages}`}
                    page={this.props.numberOfPages}
                    onClick={this.handleClick}
                    variant="outline-primary"
                    size="sm"
                    className={`pagButton ${this.props.allDisabled ? "disabled" : ""
                        }`}
                >
                    {this.props.numberOfPages}
                </Button>
            );
        }
        return buttons;
    }

    render() {
        const buttons = {
            previousEnabled: this.props.page > 1,
            nextEnabled: this.props.page < this.props.numberOfPages,
            allDisabled: this.props.disabled,
        };

        let dropdown = null;
        if (
            typeof this.props.pageSizeOptions === "object" &&
            this.props.pageSizeOptions.length > 1
        ) {
            dropdown = (
                <DropdownButton
                    data-testid="pagination-dropdown"
                    className="pagination-dropdown"
                    disabled={this.props.disabled}
                    key={"pageSize-dropdown"}
                    size="sm"
                    variant="outline-primary"
                    title={this.props.pageSize}
                >
                    {this.props.pageSizeOptions.map((number) => (
                        <Dropdown.Item
                            disabled={
                                number === this.props.pageSize
                                    ? "disabled"
                                    : false
                            }
                            eventKey={number}
                            key={`pageSize-${number}`}
                            psize={number}
                            onClick={this.handlePageSizeClick}
                        >
                            {number} items/page
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
            );
        }

        return (
            <React.Fragment>
                {dropdown}
                <Button
                    data-testid="pagination-previous"
                    key="previous"
                    page={this.props.page - 1}
                    onClick={this.handleClick}
                    variant="outline-primary"
                    size="sm"
                    className={`pagButton ${buttons.previousEnabled && !buttons.allDisabled
                        ? ""
                        : "disabled"
                        }`}
                >
                    Previous
                </Button>
                {this.middleButtons()}
                <Button
                    data-testid="pagination-next"
                    key="next"
                    page={this.props.page + 1}
                    variant="outline-primary"
                    onClick={this.handleClick}
                    size="sm"
                    className={`pagButton ${buttons.nextEnabled && !buttons.allDisabled
                        ? ""
                        : "disabled"
                        }`}
                >
                    Next
                </Button>
            </React.Fragment>
        );
    }
}

Pagination.propTypes = {
    /** What is the current page? */
    page: PropTypes.number.isRequired,
    /** How elements should be shown per page? */
    pageSize: PropTypes.number.isRequired,
    /** How many elements we have in total? */
    numberOfPages: PropTypes.number.isRequired,
    /** onChange callback function */
    onChangePage: PropTypes.func.isRequired,
    /** Should this element by disabled? */
    disabled: PropTypes.bool,
    /** Which page sizes can the user pick up from? If empty or a single array is provided, the dropdown will be omitted  */
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
    /** onChangePageSize callback function */
    onChangePageSize: PropTypes.func,
};

Pagination.defaultProps = {
    page: 1,
    pageSize: 25,
    pageSizeOptions: [10, 25, 50, 100, 250, 500],
    numberOfPages: PropTypes.number.isRequired,
    onChangePage: (page) => {
        console.log("Should navigate to page " + page);
    },
    onChangePageSize: (size) => {
        console.log("Page size now is " + size);
    },
    disabled: false,
};

export default Pagination;