import React, { createRef } from "react";
import { Breadcrumb, Container } from "react-bootstrap";
import { faDownload, faPlus, faRedo } from "@fortawesome/free-solid-svg-icons";
import Emitter from "../../services/Emitter";
import IconButton from "../../components/IconButton";
import PDDService from "../../services/PDDService";
import MySpinner from "../../components/MySpinner";
import Toast from "../../services/Toast";
import MyTable from "../../components/MyTable";
import ProgressBar from "../../components/ProgressBar";

/**
 * @component
 * @category Scenes
 * @subcategory PDD
 */
class JobsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showPickup: false,
        }
        this.tableRef = createRef();
        this.reload = this.reload.bind(this);
        this.handleDownloadResults = this.handleDownloadResults.bind(this);
    }

    componentWillUnmount() {
        Emitter.off("RELOAD_PDD_JOBS", this.reload);
    }

    componentDidMount() {
        Emitter.on("RELOAD_PDD_JOBS", this.reload);
        this.reload();
    }

    handleDownloadResults(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);

        obj.row.downloading = true;
        this.tableRef.current.setRow(obj.index, obj.row);

        PDDService.download(obj.row.id).then((result) => {
            if (!result) {
                Toast.error(
                    "Something went wrong while generating the report."
                );
            }
            obj.row.downloading = false;
            this.tableRef.current?.setRow(obj.index, obj.row);
        });
    }

    reload(evt) {
        const refreshButton = evt?.currentTarget !== undefined;
        this.setState({ loading: true });
        this.tableRef.current?.setLoading(true);
        PDDService.jobs().then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of jobs");
                this.setState({ loading: false });
                this.tableRef.current?.setLoading(false);
                return;
            }
            this.setState({ loading: false }, () => {
                this.tableRef.current?.populate(data);
            });
            if (refreshButton) {
                Toast.ok("Data reloaded successfully");
            }
        });
    }

    render() {
        return <Container className="pt-4">
            <Breadcrumb className="dd">
                <Breadcrumb.Item>PDD</Breadcrumb.Item>
                <Breadcrumb.Item active>Jobs</Breadcrumb.Item>
            </Breadcrumb>
            <MyTable
                name="pdd-jobs"
                dynamic={false}
                ref={this.tableRef}
                sortBy="created_at"
                sortOrder="desc"
                headers={
                    [
                        {
                            field: "id",
                            label: "ID",
                            sortable: true,
                            searchable: true,
                            width: "80px",
                        },
                        {
                            field: "manufacturer_name",
                            label: "Manufacturer",
                            sortable: true,
                            searchable: true,
                        },
                        {
                            field: "brand_name",
                            label: "Brand",
                            sortable: true,
                            searchable: true,
                        },
                        {
                            field: "created_at",
                            label: "Requested Date",
                            width: "125px",
                            sortable: true,
                            searchable: true,
                        },
                        {
                            field: "user_name",
                            label: "Requestor",
                            sortable: true,
                            searchable: true,
                        },
                        {
                            field: "total_products",
                            label: "# Products",
                            width: "90px",
                            sortable: true,
                            searchable: true,
                        },
                        {
                            field: "results",
                            label: "# Results",
                            width: "90px",
                            sortable: true,
                            searchable: true,
                        },
                        {
                            field: "progress",
                            label: "Progress",
                            width: "100px",
                        },
                        {
                            field: "status",
                            label: "Status",
                            sortable: true,
                            allowEmpty: true,
                            options: [{ label: 'Pending', value: 'pending' }, { label: 'Finished', value: 'finished' }]
                        },
                        {
                            field: "control",
                            width: "80px",
                            className: "text-center",
                            content:
                                <React.Fragment>
                                    <IconButton
                                        size="sm"
                                        className="tdButton"
                                        variant="primary"
                                        icon={faRedo}
                                        onClick={this.reload}
                                    />
                                    <IconButton
                                        size="sm"
                                        className="tdButton"
                                        variant="success"
                                        icon={faPlus}
                                        to="/pdd/jobs#new"
                                    />
                                </React.Fragment>
                        }
                    ]
                }
                renderColumns={{
                    created_at: (row) => row.created_at ? new Date(row.created_at).toISOString().slice(0, 10) : null,
                    user_name: {
                        text: (row) => row.user_id ? row.user_name : 'Automatic',
                        csv: (row) => row.user_id ? row.user_name : 'Automatic',
                        format: (row) => row.user_id ? row.user_name : <i>Automatic</i>
                    },
                    progress: (row) => {
                        return <ProgressBar
                            key={`progress-bar-${row.id}`}
                            completed={row.progress.replace("%", "")}
                        />
                    },
                    control: {
                        className: "text-center",
                        format: (row) => {
                            if (row.downloading) {
                                return <MySpinner />
                            }
                            return <IconButton
                                size="sm"
                                variant="secondary"
                                className="tdButton"
                                onClick={this.handleDownloadResults}
                                icon={faDownload}
                            />
                        }
                    }
                }}
            />
        </Container>
    }
}

export default JobsList;
