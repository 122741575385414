import React from "react";
import PropTypes from "prop-types";

import { Container, Row, Col, Button, Form, Accordion } from "react-bootstrap";
import confirm from "../../components/Confirm";
import Categories from "../../components/Categories";
import Toast from "../../services/Toast";
import Loading from "../Loading";
import Emitter from "../../services/Emitter";
import ProcessingButton from "../../components/ProcessingButton";
import BrandService from "../../services/BrandService";
import Regions from "../../components/Regions";
import OrganizationSearch from "../../components/OrganizationSearch";
import MerchantSearch from "../../components/MerchantSearch";
import AmazonService from "../../services/AmazonService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import "./Profile.css";
import PartnerService from "../../services/PartnerService";

/**
 * Show a CRUD for Brands
 * @component
 * @category Scenes
 * @subcategory Brands
 */
class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.brandId,
            row: {
                categories: [],
                crawlingRegions: [],
            },
            settings: {},
            loading: true,
            editing: false,
            updating: false,
            problems: false,
            pillars: BrandService.getPillars(),
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSetting = this.handleChangeSetting.bind(this);
        this.handleChangeRegions = this.handleChangeRegions.bind(this);
        this.handleOrgChange = this.handleOrgChange.bind(this);
        this.handleMerchantChange = this.handleMerchantChange.bind(this);
        this.dropEdition = this.dropEdition.bind(this);
        this.startEdition = this.startEdition.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.reload = this.reload.bind(this);
        this.PPDStatus = this.PPDStatus.bind(this);
    }

    handleOrgChange = (org) => {

        let row = this.state.row;
        row.org_id = org.length ? org[0].id : 0;

        if (row.org_id !== this.original.org_id) {
            this.changes.fields.org_id = row.org_id;
        } else {
            delete this.changes.fields.org_id;
        }

        this.setState({ row, problems: this.validateRow(row) });

    }

    async adjustAmazonVisibilitySuite(row = null, returnRow = false) {
        const data = row || this.state.row;
        const selectedCountryIDs = data.crawlingRegions.map((obj) => Regions.GetByID(obj.value).country);
        let amazonWebsites = await AmazonService.FilterByCountryID(selectedCountryIDs),
            pillars = this.state.pillars,
            changes = 0;

        pillars.forEach((pillar) => {
            if (pillar.code === "visibility_pillar") {
                pillar.modules.forEach((module) => {
                    if (module.code === "amazon_visibility_suite") {
                        changes += this.adjustAmazonMerchantSettings(
                            module.settings,
                            amazonWebsites
                        );
                    }
                });
            }
        });

        if (returnRow) {
            return pillars;
        }

        if (changes) {
            this.setState({ pillars })
        }
    }

    /**
     * Re-compose the amazon_merchant settings inside pillars based on the selected countries
     * @returns {number} number of changes made on the current pillars
     */
    adjustAmazonMerchantSettings(settings, amazonWebsites) {
        const websiteIds = amazonWebsites.map((obj) => obj.id);
        let changes = 0;
        for (let i = 0; i < settings.length; i++) {
            const setting = settings[i];
            if (setting.code === "amazon_merchant") {
                if (websiteIds.indexOf(setting.id) === -1) {
                    settings.splice(i--, 1);
                    changes++;
                } else {
                    websiteIds.splice(websiteIds.indexOf(setting.id), 1);
                }
            }
        }
        if (websiteIds.length) {
            changes++;
            amazonWebsites.forEach((website) => {
                if (websiteIds.indexOf(website.id) !== -1) {
                    settings.push({
                        code: "amazon_merchant",
                        country_id: website.country_id,
                        website_id: website.id,
                        label: website.name + "'s Seller",
                        type: "merchant",
                        book: "country"
                    });
                }
            });
        }
        settings.sort((a, b) => a.website_id < b.website_id ? -1 : 1);
        return changes;
    }

    validateRow(row) {
        let problems = {};
        if (row.org_id === 0) {
            problems.org_id = true;
        }

        if (row.store_name.trim().length === 0) {
            problems.store_name = true;
        }
        return Object.keys(problems).length === 0 ? false : problems;
    }

    updateRow(name, value, book, isCheckbox = false) {
        const bookSettings = book + "Settings"; // brandSettings or storeSettings
        let row = this.state.row;

        row[bookSettings][name] = value;

        if (name === "whack_price_protection") {
            name = "whack_price_disabled";
            value = value === "on" ? "off" : "on";
            row[bookSettings][name] = value;
        }

        let mapBook =
            "map" +
            bookSettings.charAt(0).toUpperCase() +
            bookSettings.slice(1); // mapBrandSettings or mapStoreSettings

        this.setState({ row });

        let rowId = typeof this[mapBook][name] === "number" ? this[mapBook][name] : -1,
            setting = null,
            data = {
                name,
                id: 0,
                value,
            };

        if (rowId !== -1) {
            setting = this[bookSettings][rowId];
            let oldValue = isCheckbox
                ? /(1|on)/i.test(setting.value)
                    ? "on"
                    : "off"
                : setting.value;
            if (value !== oldValue) {
                data.id = setting.id;
                this.changes[book][name] = data;
            } else {
                delete this.changes[book][name];
            }
        } else {
            if (value === "off" || value === "") {
                delete this.changes[book][name];
            } else {
                this.changes[book][name] = data;
            }
        }
    }

    handleChange(evt) {
        let key = evt.target.name,
            row = this.state.row,
            isCheckbox = evt.target.className.match(/check/),
            value = isCheckbox
                ? (evt.target.checked ? 1 : 0)
                : evt.target.value,
            oldValue = isCheckbox ? (/(1|on)/i.test(this.original[key]) ? 1 : 0) : this.original[key];

        if (oldValue !== value) {
            this.changes.fields[key] = value;
        } else {
            delete this.changes.fields[key];
        }
        row[key] = value;
        this.setState({ row, problems: this.validateRow(row) });
    }

    handleMerchantChange(selected) {
        this.updateRow(this.merchantOnFocus, selected.length ? selected[0] : 0, 'country');
    }

    getModuleObject(pillar, module) {
        if (pillar === null || module === null) {
            return {};
        }
        return this.state.pillars.find(p => p.code === pillar).modules.find(m => m.code === module);
    }

    async checkConfirmation(pillar, module, value) {
        const config = this.getModuleObject(pillar, module),
            confirmationMessage = typeof config.confirm !== "undefined" && typeof config.confirm[value] !== "undefined"
                ? config.confirm[value]
                : null;

        if (confirmationMessage === null) {
            return true;
        }

        let title = "Are you sure?",
            message = confirmationMessage,
            confirmButton = "Yes",
            cancelButton = "No",
            size = null;

        if (typeof confirmationMessage === "object") {
            title = confirmationMessage.title || title;
            message = confirmationMessage.message || "";
            confirmButton = confirmationMessage.yes || confirmButton;
            cancelButton = confirmationMessage.no || cancelButton;
            size = confirmationMessage.size || size;
        }

        return await confirm(title, confirmButton, cancelButton, {
            message: message,
            size,
            enableEscape: false
        });
    }

    displayFeedbackMessage(pillar, module, value) {
        const config = this.getModuleObject(pillar, module),
            feedbackMessage = typeof config.feedback !== "undefined" && typeof config.feedback[value] !== "undefined"
                ? config.feedback[value]
                : null;

        if (feedbackMessage === null) {
            return;
        }
        Toast.info(feedbackMessage, 7500);
    }

    async handleChangeSetting(evt, settings = []) {
        const isCheckbox = evt.target.className.match(/check/) !== null,
            book = evt.target.getAttribute("book"), // brand or store
            name = evt.target.getAttribute("name"),
            pillar = evt.target.getAttribute("pillar"),
            value = isCheckbox ? (evt.target.checked ? 'on' : 'off') : evt.target.value;

        if (!await this.checkConfirmation(pillar, name, value)) {
            return;
        }

        this.displayFeedbackMessage(pillar, name, value);
        this.updateRow(name, value, book, isCheckbox);

        if (settings.length && value === "off") {
            const settingValue = isCheckbox ? "off" : "";
            settings.forEach((setting) => {
                this.updateRow(setting.code, settingValue, setting.book);
            });
        }
    }

    async handleChangeRegions(selected) {
        let row = this.state.row;
        row["crawlingRegions"] = selected;
        const pillars = await this.adjustAmazonVisibilitySuite(row, true);

        this.setState({ row, pillars }, () => {
            const newRegions = selected.map((obj) => obj.value).sort(),
                oldRegions = this.original.crawlingRegions.map((obj) => obj.value).sort();
            if (JSON.stringify(newRegions) === JSON.stringify(oldRegions)) {
                this.changes.regions = null;
            } else {
                this.changes.regions = newRegions;
            }
        });
    }

    polishData(row) {
        row.categories = Categories.Selected(row.categories);
        row.crawlingRegions = Regions.Selected(row.crawlingRegions);
        this.storeSettings = [];
        this.mapStoreSettings = {};
        let storeSettings = {};
        if (typeof row.store === "object" && row.store.length) {
            row.store.forEach((setting, i) => {
                this.storeSettings.push(setting);
                this.mapStoreSettings[setting.name] = i;
                storeSettings[setting.name] = setting.value;
            });
        }
        storeSettings.whack_price_protection = typeof storeSettings.whack_price_disabled === "undefined" || /(off|0)/i.test(storeSettings.whack_price_disabled) ? 'on' : 'off';
        row.storeSettings = storeSettings;
        this.brandSettings = [];
        this.mapBrandSettings = {};
        let brandSettings = {};
        if (typeof row.brand === "object" && row.brand.length) {
            row.brand.forEach((setting, i) => {
                this.brandSettings.push(setting);
                this.mapBrandSettings[setting.name] = i;
                brandSettings[setting.name] = setting.value;
            });
        }
        row.brandSettings = brandSettings;
        this.countrySettings = [];
        this.mapCountrySettings = {};
        let countrySettings = {};
        if (typeof row.country === "object" && row.country.length) {
            row.country.forEach((setting, i) => {
                const key = `${setting.code}-${setting.country_id}`;
                this.countrySettings.push(setting);
                this.mapCountrySettings[key] = i;
                countrySettings[key] = setting;
            });
        }
        row.countrySettings = countrySettings;
        delete row.store;
        delete row.brand;
        delete row.country;
        return row;
    }

    /**
     *
     * @param {string} book store or brand
     * @param {string} key setting code
     * @param {*} defaultValue
     * @returns {*}
     */
    getSetting(book, key, defaultValue) {
        book += 'Settings';
        if (typeof this.state.row === "object" && typeof this.state.row[book] === "object" && typeof this.state.row[book][key] !== "undefined") {
            return this.state.row[book][key];
        }
        return defaultValue;
    }

    getSettingBool(book, key) {
        return /(on|1|true)/i.test(this.getSetting(book, key, ''));
    }

    getPillarStatus(settings) {
        if (settings !== "undefined") {
            for (const setting of settings) {
                if (this.getSettingBool(setting.book, setting.code))
                    return true;
            }
        }

        return false;
    }

    reload() {
        BrandService.get(this.state.id).then(async (data) => {
            if (data === false) {
                Toast.error("Something went wrong while retrieving data from this brand");
                return;
            }

            let prettyData = this.polishData(data),
                pillars = await this.adjustAmazonVisibilitySuite(prettyData, true);

            this.setState({
                loading: false,
                row: prettyData,
                pillars,
                defaultSelected: data.organization !== null && typeof data.organization.id === "number" ? [data.organization] : [],
                editing: false,
            });
        });
    }

    componentWillUnmount() {
        Emitter.off("BRAND_PROFILE");
    }

    componentDidMount() {
        Emitter.once("BRAND_PROFILE", this.reload);
    }

    saveChanges() {
        this.setState({ updating: true });
        let payload = Object.assign({}, this.changes);
        let switchedWhereToBuy = null;

        if (Object.keys(payload.brand).length === 0 &&
            Object.keys(payload.store).length === 0 &&
            Object.keys(payload.fields).length === 0 &&
            Object.keys(payload.country).length === 0 &&
            payload.regions === null &&
            payload.categories === null) {
            this.setState({ updating: false, editing: false });
            Toast.info("No modifications were made.");
            return;
        }

        // store settings
        let store = [];
        Object.keys(payload.store).forEach((key) => {
            let obj = payload.store[key];
            if (obj.id) { // there's an old value
                obj.oldValue = this.storeSettings[this.mapStoreSettings[obj.name]].value;
            } else {
                obj.oldValue = '';
            }
            store.push(obj);
            if (obj.name === PartnerService.WHERE_TO_BUY_MODULE_NAME) {
                switchedWhereToBuy = obj.value === 'on';
            }
        });
        payload.store = store;
        store = null;

        // brand settings
        let brand = [];
        Object.keys(payload.brand).forEach((key) => {
            let obj = payload.brand[key];
            if (obj.id) { // there's an old value
                obj.oldValue = this.brandSettings[this.mapBrandSettings[obj.name]].value;
            } else {
                obj.oldValue = '';
            }
            brand.push(obj);
        });
        payload.brand = brand;
        brand = null;

        // brand_country settings
        let country = [];
        Object.keys(payload.country).forEach((key) => {
            const obj = payload.country[key],
                parts = key.split('-');
            let data = {
                name: parts[0],
                country_id: parseInt(parts[1]),
                oldValue: obj.id ? obj.id : '',
                value: typeof obj.value === "object" && obj.value.id ? obj.value.id : ''
            };
            country.push(data);
        });
        payload.country = country;
        country = null;

        // fields settings
        let fields = [];
        Object.keys(payload.fields).forEach((key) => {
            let newValue = payload.fields[key],
                oldValue = this.original[key];
            fields.push({
                name: key,
                value: newValue,
                oldValue
            });
        });
        payload.fields = fields;
        fields = null;

        // categories
        if (payload.categories !== null) {
            let originalCategories = this.original.categories.map((row) => row.value),
                add = [],
                remove = [];

            payload.categories.forEach((id) => {
                if (originalCategories.indexOf(id) === -1) {
                    add.push(id);
                }
            });
            originalCategories.forEach((id) => {
                if (payload.categories.indexOf(id) === -1) {
                    remove.push(id);
                }
            })
            payload.categories = {};
            if (add.length) {
                payload.categories.add = add;
            }
            if (remove.length) {
                payload.categories.remove = remove;
            }
        } else {
            payload.categories = {}
        }

        // crawling regions
        if (payload.regions !== null) {
            let originalRegions = this.original.crawlingRegions.map((row) => row.value),
                add = [],
                remove = [];

            payload.regions.forEach((id) => {
                if (originalRegions.indexOf(id) === -1) {
                    add.push(id);
                }
            });
            originalRegions.forEach((id) => {
                if (payload.regions.indexOf(id) === -1) {
                    remove.push(id);
                }
            })
            payload.regions = {};
            if (add.length) {
                payload.regions.add = add;
            }
            if (remove.length) {
                payload.regions.remove = remove;
            }
        } else {
            payload.regions = {};
        }

        BrandService.update(this.state.id, payload).then(async (result) => {
            if (result === false) {
                this.setState({ updating: false, editing: true });
                Toast.error(
                    "Something wrong happened and changes could not be saved."
                );
            } else {
                const row = this.polishData(result),
                    pillars = await this.adjustAmazonVisibilitySuite(row, true);
                this.setState({
                    updating: false,
                    editing: false,
                    row,
                    pillars
                });
                delete this.original;
                if (switchedWhereToBuy !== null) {
                    Emitter.emit('BRAND_WHERE_TO_BUY_SWITCH', switchedWhereToBuy);
                }
                Toast.ok("Changes properly saved.");
            }
        });
    }

    startEdition() {
        this.original = JSON.parse(JSON.stringify(this.state.row));
        this.changes = {
            fields: {},
            store: {},
            brand: {},
            country: {},
            regions: null,
            categories: null
        };
        this.setState({
            editing: true,
            problems: this.validateRow(this.state.row)
        });
    }

    dropEdition() {
        this.setState({ editing: false, row: this.original });
        delete this.original;
    }

    PPDStatus() {
        const scheduled_pdd = this.state.row.scheduled_pdd ?? null;
        if (scheduled_pdd === null) {
            return (
                <div
                    className="disabled"
                    title="PPD Schedule API is not available"
                >Unknown <FontAwesomeIcon icon={faQuestionCircle} /></div>
            );
        }
        return (
            <Form.Check
                type="switch"
                label={scheduled_pdd ? "On" : "Off"}
                name="scheduled_pdd"
                checked={scheduled_pdd}
                disabled={true}
            />
        );
    }

    render() {
        if (this.state.loading) {
            return (
                <Container className="pt-4">
                    <Row
                        style={{
                            height: "100px",
                        }}
                    >
                        <Col>
                            <Loading />
                        </Col>
                    </Row>
                </Container>
            );
        }

        const setPillarDisplay = (pillar) => {
            const pillarSettings = pillar.modules;
            if (typeof pillarSettings === "undefined") {
                return;
            }

            let appsGroup = [];
            let settingsGroup = [];

            pillarSettings.forEach((app) => {
                const book =
                    typeof app.book === "undefined"
                        ? "store"
                        : app.book;
                const appSettings = app.settings || [];

                appsGroup.push(
                    <Form.Check
                        key={`app-${app.code}`}
                        type="switch"
                        label={app.label}
                        name={app.code}
                        book={book}
                        pillar={pillar.code}
                        checked={this.getSettingBool(book, app.code)}
                        disabled={
                            !this.state.editing || this.state.updating
                        }
                        onChange={(e) =>
                            this.handleChangeSetting(e, appSettings)
                        }
                    />
                );

                if (appSettings.length) {
                    let inner = [];

                    appSettings.forEach((setting) => {
                        const book =
                            typeof setting.book === "undefined"
                                ? "store"
                                : setting.book;
                        const shouldEnable =
                            this.state.editing
                            && !this.state.updating
                            && this.getSettingBool(app.book, app.code);

                        if (setting.type === "boolean") {
                            inner.push(
                                <Form.Check
                                    key={`setting-${setting.code}`}
                                    type="switch"
                                    label={setting.label}
                                    name={setting.code}
                                    book={book}
                                    checked={this.getSettingBool(book, setting.code)}
                                    disabled={!shouldEnable}
                                    onChange={this.handleChangeSetting}
                                />
                            );
                        } else if (setting.type === "text") {
                            inner.push(
                                <Row key={`setting-${setting.code}`}>
                                    <Col className="d-flex align-items-center left-content-center">
                                        {setting.label}
                                    </Col>
                                    <Col sm={7}>
                                        <Form.Control
                                            type="text"
                                            name={setting.code}
                                            book={book}
                                            value={this.getSetting(book, setting.code)}
                                            disabled={!shouldEnable}
                                            placeholder={setting.placeholder}
                                            onChange={this.handleChangeSetting}
                                        />
                                    </Col>
                                </Row>
                            );
                        } else if (setting.type === "option") {
                            inner.push(
                                <Form.Group className="mt-3">
                                    <Form.Label>{setting.label}</Form.Label>
                                    <Form.Select
                                        aria-label={setting.label}
                                        name={setting.code}
                                        book={book}
                                        disabled={!shouldEnable}
                                        onChange={this.handleChangeSetting}
                                        value={this.getSetting(book, setting.code)}
                                    >
                                        {setting.options.map((opt) => (
                                            <option
                                                key={`opt-${setting.code}-${opt.value}`}
                                                value={opt.value}
                                            >
                                                {opt.label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            );
                        } else if (setting.type === "merchant") {
                            const key = `${setting.code}-${setting.country_id}`,
                                value = this.getSetting(book, key, 0),
                                selected = value !== null && value !== 0
                                    ? [Object.assign(value, { combined: MerchantSearch.formatOptionLabel(value) })]
                                    : [];

                            inner.push(
                                <Col key={`setting-${setting.id}`} sm={12} className="left-content-center">
                                    {setting.label}
                                    <MerchantSearch
                                        onFocus={() => { this.merchantOnFocus = key }}
                                        marketplaceId={setting.website_id}
                                        onChange={this.handleMerchantChange}
                                        selected={selected}
                                        disabled={!shouldEnable}
                                    />
                                </Col>
                            );
                        }
                    });

                    if (inner.length) {
                        settingsGroup.push(
                            <div key={`app-setting-${app.code}`} className="settings__content">
                                <div className="settings__content__title"><span>{app.label}</span></div>
                                <div className="settings__content__content">
                                    {inner}
                                </div>
                            </div>
                        )
                    }
                }
            });

            return (
                <div className="pillar">
                    <div className="pillar__apps">{appsGroup}</div>
                    {!!settingsGroup.length && (
                        <div className="pillar__settings">
                            <div className="settings__title"><span>Settings</span></div>
                            {settingsGroup}
                        </div>
                    )}
                </div>
            );
        };

        return (
            <Container className="pt-4">
                <Row>
                    <Col sm={8}>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="store_name"
                            value={this.state.row.store_name}
                            readOnly={!this.state.editing || this.state.updating}
                            className={this.state.problems !== false && this.state.problems.store_name ? "is-invalid" : ''}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Created</Form.Label>
                        <Form.Control
                            type="text"
                            name="created_at"
                            value={this.state.row.created_at}
                            disabled={true}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Modified</Form.Label>
                        <Form.Control
                            type="text"
                            name="modified"
                            value={this.state.row.modified}
                            disabled={true}
                        />
                    </Col>


                </Row>
                <Row>
                    <Col sm={7}>
                        <Form.Label>Crawling countries</Form.Label>
                        <Regions
                            name="crawlingRegions"
                            value={this.state.row.crawlingRegions}
                            disabled={!this.state.editing || this.state.updating}
                            onChange={this.handleChangeRegions}
                        />
                    </Col>
                    <Col sm={5}>
                        <Form.Label>Customer Service Level</Form.Label>
                        <Form.Select
                            aria-label="Customer Service Level"
                            name="customer_service_level"
                            book="store"
                            value={this.getSetting("store", "customer_service_level", "")}
                            disabled={!this.state.editing || this.state.updating}
                            onChange={this.handleChangeSetting}
                        >
                            <option value="">Unknown</option>
                            <option value="Support Squad">Support Squad</option>
                            <option value="Account Management">Account Management</option>
                            <option value="Managed Services">Managed Services</option>
                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <Form.Label>Organization</Form.Label>
                        <OrganizationSearch
                            defaultSelected={this.state.defaultSelected}
                            onChange={this.handleOrgChange}
                            disabled={!this.state.editing || this.state.updating}
                            invalid={this.state.problems !== false && this.state.problems.org_id}
                        />
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Sales Status</Form.Label>
                        <Form.Select
                            aria-label="Sales Status"
                            name="sales_status"
                            book="brand"
                            disabled={!this.state.editing || this.state.updating}
                            onChange={this.handleChangeSetting}
                            value={this.getSetting('brand', 'sales_status')}
                        >
                            <option value="Unknown">Unknown</option>
                            <option value="Demo - Build In Progress">Demo - Build In Progress</option>
                            <option value="Demo - Ready">Demo - Ready</option>
                            <option value="Demo - Brand Still Evaluating">Demo - Brand Still Evaluating</option>
                            <option value="Demo - Status Unknown">Demo - Status Unknown</option>
                            <option value="Demo - Did Not Convert">Demo - Did Not Convert</option>
                            <option value="Trial - Free">Trial - Free</option>
                            <option value="Trial - Free (Special Offer)">Trial - Free (Special Offer)</option>
                            <option value="Trial - Paid">Trial - Paid</option>
                            <option value="Trial - Did Not Convert">Trial - Did Not Convert</option>
                            <option value="Paying/Active">Paying/Active</option>
                            <option value="Delinquent">Delinquent</option>
                            <option value="Canceled - Churned/Lost">Canceled - Churned/Lost</option>
                            <option value="Products Moved">Products Moved</option>
                            <option value="Mistake / Duplicate">Mistake / Duplicate</option>
                            <option value="Paying/Active - Untracked Account">Paying/Active - Untracked Account</option>
                            <option value="Used for Testing">Used for Testing</option>
                            <option value="Demo - PDLT">Demo - PDLT</option>
                            <option value="Disabled - PDLT">Disabled - PDLT</option>
                        </Form.Select>
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Onboarding Status</Form.Label>
                        <Form.Select
                            aria-label="Onboarding Status"
                            name="onboarding_status"
                            book="brand"
                            value={this.getSetting("brand", "onboarding_status")}
                            disabled={!this.state.editing || this.state.updating}
                            onChange={this.handleChangeSetting}
                        >
                            <option value="Unknown">Unknown</option>
                            <option value="Onboarding - Welcome/Training">Onboarding - Welcome/Training</option>
                            <option value="Onboarding - Complete">Onboarding - Complete</option>
                        </Form.Select>
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Capture Screenshots</Form.Label>
                        <Form.Select
                            name="ss_every_product"
                            book="store"
                            value={this.getSetting("store", "ss_every_product")}
                            disabled={!this.state.editing || this.state.updating}
                            onChange={this.handleChangeSetting}
                        >
                            <option value="0">of Violations</option>
                            <option value="1">of Everything</option>
                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                    <Col sm={2}>
                        <Form.Label>Status</Form.Label>
                        <Form.Check
                            type="switch"
                            label={/(1|on)/i.test(this.state.row.store_enable) ? "Active" : "Disabled"}
                            name="store_enable"
                            checked={/(1|on)/i.test(this.state.row.store_enable)}
                            disabled={!this.state.editing || this.state.updating}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Scheduled PDD</Form.Label>
                        <this.PPDStatus />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Review Crawling</Form.Label>
                        <Form.Check
                            type="switch"
                            label={/(1|on)/i.test(this.state.row.reviews) ? "On" : "Off"}
                            name="reviews"
                            checked={/(1|on)/i.test(this.state.row.reviews)}
                            // disabled={!this.state.editing || this.state.updating}
                            // disabled until we figure out where this is used
                            disabled={true}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Force Fresh Screenshot</Form.Label>
                        <Form.Check
                            type="switch"
                            label={/(1|on)/i.test(this.state.row.force_screenshot) ? "Yes" : "No"}
                            name="force_screenshot"
                            checked={/(1|on)/i.test(this.state.row.force_screenshot)}
                            // disabled={!this.state.editing || this.state.updating}
                            // perhaps should not be enabled for non-devs
                            disabled={true}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Note Enabled</Form.Label>
                        <Form.Check
                            type="switch"
                            label={this.state.row.note_enable ? "Yes" : "No"}
                            name="note_enable"
                            checked={/(1|on)/i.test(this.state.row.note_enable)}
                            // disabled={!this.state.editing || this.state.updating}
                            // disabled until we figure out where this is being used
                            disabled={true}
                            onChange={this.handleChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form.Label>Internal Notes</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Leave an internal note here"
                            name="notes"
                            book="store"
                            value={this.getSetting('store', 'notes', '')}
                            disabled={!this.state.editing || this.state.updating}
                            onChange={this.handleChangeSetting}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className="pillars">
                        <Accordion defaultActiveKey="0" flush>
                            {this.state.pillars.map((obj, index) => (
                                <Accordion.Item
                                    eventKey={`${index}`}
                                    key={`pillar-${obj.code}`}
                                >
                                    <Accordion.Header>
                                        {this.getPillarStatus(obj.modules) && (
                                            <FontAwesomeIcon
                                                icon={faCheckCircle}
                                                style={{
                                                    color: "#0c63e4",
                                                }}
                                            />
                                        )}
                                        {obj.label}
                                    </Accordion.Header>

                                    <Accordion.Body>
                                        {setPillarDisplay(obj)}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        {!this.state.editing && (
                            <Button
                                variant="secondary"
                                onClick={this.startEdition}
                            >
                                Edit
                            </Button>
                        )}
                        {this.state.editing && (
                            <span>
                                <Button
                                    disabled={this.state.updating}
                                    variant="secondary"
                                    onClick={this.dropEdition}
                                >
                                    Cancel
                                </Button>
                                <ProcessingButton
                                    variant="primary"
                                    processing={this.state.updating}
                                    processingLabel="Saving ..."
                                    label="Save"
                                    disabled={this.state.problems !== false}
                                    onClick={this.saveChanges}
                                />
                            </span>
                        )}
                    </Col>
                </Row>
            </Container >
        );
    }
}

Profile.propTypes = {
    /** Brand ID */
    brandId: PropTypes.number.isRequired,
};

export default Profile;
