import React, { createRef } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Toast from "../../services/Toast";
import { Breadcrumb, Container, Button, Row, Col, Form } from "react-bootstrap";
import MyTable from "../../components/MyTable";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import CostExplorerService from "../../services/CostExplorerService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MySpinner from "../../components/MySpinner";

/**
 * @component
 * @category Scenes
 * @subcategory CostExplorer
 */
class AssetCostPerEntity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entity: "brand",
            assets: [],
            asset_id: 0,
            showTable: false,
            showOptions: false,
            loading: false,
            dates: [],
            topRow: null,
            lastDay: '',
            division: null,
        }
        this.tableRef = createRef();
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
        this.handleEntityChange = this.handleEntityChange.bind(this);
        this.handleAssetChange = this.handleAssetChange.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    handleAssetChange(e) {
        this.setState({
            asset_id: e.target.value,
            division: e.target.options[e.target.selectedIndex].getAttribute('division').toUpperCase()
        }, this.loadData);
    }

    handleEntityChange(e) {
        this.setState({
            entity: e.target.value
        }, () => {
            if (this.state.asset_id) {
                this.loadData()
            }
        });
    }

    loadData() {
        if (this.tableRef.current) {
            this.tableRef.current.setLoading(true);
        }
        this.setState({
            loading: true,
        });
        CostExplorerService.asset(this.state.entity, this.state.asset_id).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the data.");
                this.setState({
                    loading: false,
                });
                return;
            }

            let topRow = {
                id: '',
                name: 'Total Bill'
            },
                dates = data.dates.map(date => {
                    topRow[date] = '$' + data.bills[date]['cost'].toFixed(2);
                    return {
                        field: date,
                        label: date,
                        sortable: true,
                        width: "100px",
                    }
                });
            const items = data.items.map(item => {
                const row = {
                    id: item.id,
                    name: item.name,
                    empty: 0
                };
                data.dates.forEach(date => {
                    const percentage = data.bills[date]['total'] ? item[date] / data.bills[date]['total'] : 0,
                        value = data.bills[date]['cost'] * percentage,
                        text = value.toFixed(3) + ' (' + (percentage * 100).toFixed(3) + '%)',
                        empty = text.match(/^0\.000 /);

                    row[date] = text;
                    if (empty) {
                        row[date] = '';
                        row.empty++;
                    }
                });

                row.empty = row.empty === data.dates.length;
                return row;
            }).filter(item => !item.empty);

            this.setState({
                dates,
                topRow,
                showTable: true,
                loading: false,
                lastDay: dates[dates.length - 1].field
            }, () => {
                this.tableRef.current.populate(items);
            });
        });
    }

    componentDidMount() {
        CostExplorerService.formula().then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of assets.");
                return;
            }
            let rows = [
                { id: 0, value: '' }
            ];
            data.filter(asset => asset.free === false).forEach(asset => {
                rows.push({ id: asset.id, value: asset.label, division: asset.divided_by});
            });

            this.setState({
                assets: rows,
                showOptions: true,
            });
        });
    }

    downloadSpreadsheet() {
        this.tableRef.current.CSV({
            filename: "asset" + this.state.asset_id + "_cost-per-" + this.state.entity + ".csv"
        });
    }

    render() {
        const { assets } = this.state,
            assetOptions = assets.map(asset => {
                return <option key={asset.id} value={asset.id} division={asset.division}>{asset.value}</option>
            }),
            renderColumns = {};
        this.state.dates.forEach(date => {
            renderColumns[date.field] = {
                text: (row, field) => {
                    return row[field] ? parseFloat(row[field].split(' ')[0]) : 0;
                }
            }
        });
        return (

            <Container className="pt-4">
                {this.state.showTable && <span style={{
                    float: "right"
                }}
                >
                    <Button
                        disabled={this.state.loading}
                        size="sm"
                        className="tdButton"
                        variant="success"
                        label="Download CSV"
                        onClick={this.downloadSpreadsheet}
                    >
                        <FontAwesomeIcon icon={faDownload} /> Download
                    </Button>
                </span>}
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>CostExplorer</Breadcrumb.Item>
                    <Breadcrumb.Item active>Asset Cost per Entity</Breadcrumb.Item>
                </Breadcrumb>
                {this.state.showOptions && <Row>
                    <Col sm={1} className="d-flex align-items-center justify-content-end">Asset</Col>
                    <Col sm={3}>
                        <Form.Select
                            disabled={this.state.loading}
                            aria-label="Asset"
                            name="asset"
                            value={this.state.asset_id}
                            onChange={this.handleAssetChange}
                        >
                            {assetOptions}
                        </Form.Select>
                    </Col>
                    <Col sm={1} />
                    <Col sm={1} className="d-flex align-items-center justify-content-end">Entity</Col>
                    <Col sm={1} className="d-flex align-items-center justify-content-center">
                        <Form.Check
                            disabled={this.state.loading}
                            type="radio"
                            name="entity"
                            value="brand"
                            label="Brand"
                            onChange={this.handleEntityChange}
                            checked={this.state.entity === "brand"}
                        />
                    </Col>
                    <Col sm={1} className="d-flex align-items-center justify-content-center">
                        <Form.Check
                            disabled={this.state.loading}
                            type="radio"
                            name="entity"
                            value="website"
                            label="Website"
                            onChange={this.handleEntityChange}
                            checked={this.state.entity === "website"}
                        />
                    </Col>
                    {this.state.division && <React.Fragment>
                        <Col sm={1} />
                        <Col sm={1} className="d-flex align-items-center justify-content-center">Division</Col>
                        <Col sm={1} className="d-flex align-items-center justify-content-center bold">{this.state.division}</Col>
                    </React.Fragment>}
                </Row>}
                {!this.state.showOptions && <div className="text-center"><MySpinner /></div>}
                {!this.state.showTable && this.state.loading && <div className="text-center"><MySpinner /></div>}
                {this.state.showTable && <MyTable
                    name="costexplorer-cost-per-entity"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy={this.state.lastDay}
                    sortOrder="desc"
                    topRow={this.state.topRow}
                    headers={
                        [
                            {
                                field: "id",
                                label: "ID",
                                sortable: true,
                                searchable: true,
                                width: "60px",
                            },
                            {
                                field: "name",
                                label: "Name",
                                sortable: true,
                                searchable: true,
                                width: "250px",
                            },
                            ...this.state.dates
                        ]
                    }
                    renderColumns={renderColumns}
                />}
            </Container>
        )
    }
}

export default AssetCostPerEntity;