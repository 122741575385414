/**
 * Class for handling API responses
 */
class APIHelper {
    /**
     * Validates the response's attribute property
     * @param AxiosResponse response
     * @param string attributeName
     * @returns boolean
     */
    static checkStatus(response, attributeName = 'status') {
        return typeof response.data === "object" && typeof response.data[attributeName] === "boolean" && response.data[attributeName];
    }

    /**
     * Validates the response's status and returns the 'data' property from the response object
     * @param AxiosResponse response
     * @returns 
     */
    static returnPayload(response) {
        return APIHelper.checkStatus(response) ? response.data : false;
    }

    static returnAttribute(response, attributeName, defaultValue = false) {
        const payload = APIHelper.returnPayload(response);
        delete payload.status;
        return payload[attributeName] ?? defaultValue;
    }

    /**
     * Validates the response's status and returns the 'data' property from the payload object
     * @param AxiosResponse response
     * @returns 
     */
    static returnData(response) {
        return APIHelper.returnAttribute(response, "data", false);
    }

    /**
     * Validates the response's status and returns the 'results' property from the payload object
     * @param AxiosResponse response
     * @returns 
     */
    static returnResults(response) {
        return APIHelper.returnAttribute(response, "results", false);
    }

    /**
     * Validates the response's status and returns the 'rows' property from the payload object
     * @param AxiosResponse response
     * @returns 
     */
    static returnRows(response) {
        return APIHelper.returnAttribute(response, "rows", false);
    }

    /**
     * Validates the response's status and returns the 'items' property from the payload object
     * @param AxiosResponse response
     * @returns 
     */
    static returnItems(response) {
        return APIHelper.returnAttribute(response, "items", []);
    }

    /**
     * Validates the response's status and returns the 'entries' property from the payload object
     * @param AxiosResponse response
     * @returns 
     */
        static returnEntries(response) {
            return APIHelper.returnAttribute(response, "entries", []);
        }
    
    /**
     * Validates the response's status and returns the 'data' property from the payload object
     * @param AxiosResponse response
     * @returns 
     */
    static returnDataOrEmptyArray(response) {
        return APIHelper.returnAttribute(response, "data", []);
    }

    /**
     * Validates the response's status and returns the 'items' property from the payload object
     * @param AxiosResponse response
     * @returns 
     */
    static returnItemsOrEmptyArray(response) {
        return APIHelper.returnAttribute(response, "items", []);
    }

    static returnFalse(error) {
        return false;
    }

    static returnEmptyArray(error) {
        return [];
    }
}

export default APIHelper;
