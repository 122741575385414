import EventEmitter from "eventemitter3";

const eventEmitter = new EventEmitter();

/**
 * Emitter class allows communication between components by establishing a pub/sub channel in the application
 * @class
 * @hideconstructor
 */
const Emitter = {
    /**
     * Registers a callback listener for a given event
     * @param {string} event
     * @param {function} callback
     */
    on: (event, fn) => eventEmitter.on(event, fn),
    /**
     * Registers a ONE-TIME callback listener for a given event
     * @param {string} event
     * @param {function} callback
     */
    once: (event, fn) => eventEmitter.once(event, fn),
    /**
     * Removers a callback listener for a given event
     * @param {string} event
     * @param {function} callback
     */
    off: (event, fn) => eventEmitter.off(event, fn),
    /**
     * Dispatches any given
     * @param {string} event
     * @param {string|int|bool|object} payload passed along with the event
     */
    emit: (event, payload) => eventEmitter.emit(event, payload),
};

Object.freeze(Emitter);

export default Emitter;
