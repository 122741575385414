import React, { createRef } from "react";
import { Container, Breadcrumb, Row, Col, Button, Carousel, Form } from "react-bootstrap";
import Spreadsheet from "../../components/Spreadsheet";
import URLLookupService from "../../services/URLLookupService";
import Toast from "../../services/Toast";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faDownload, faTimes } from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "../../components/ProgressBar";
import MyTable from "../../components/MyTable";
import Loading from "../Loading";
import ProcessingButton from "../../components/ProcessingButton";
import MyCarousel from "../../components/MyCarousel";


/**
 * @component
 * @category Scenes
 */
class URLChecker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            loading: false,
            problems: 0,
            showModal: false,
            progress: 0
        }
        this.upload = this.upload.bind(this);
        this.spreadsheet = createRef();
        this.tableRef = createRef();
        this.editable = true;
        if (window.location.hash.match(/(results)/)) {
            this.props.history.push('/others/urlchecker');
        }
        this.data = [[]];

        this.ShowResults = this.ShowResults.bind(this);
        this.procNext = this.procNext.bind(this);
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
    }

    getSpreadsheet() {
        return this.spreadsheet.current.Get();
    }

    downloadSpreadsheet() {
        this.tableRef.current.CSV();
    }

    upload() {
        let items = [], cleanData = [];

        this.getSpreadsheet().options.editable = false;
        this.getSpreadsheet().getData().forEach((item) => {
            if (
                item[0].trim().length === 0 || item[1].trim().length === 0 ||
                item[0].match(/^[^\w-]$/i) || !item[1].match(/^https?:\/\/.+/)
            ) {
                return;
            }
            items.push({
                identifier: item[0].trim(),
                url: item[1].trim()
            });
            cleanData.push([item[0].trim(), item[1].trim()]);
        });

        if (items.length === 0) {
            this.getSpreadsheet().options.editable = true;
            Toast.error("No valid data was informed.");
            return;
        }

        this.getSpreadsheet().setData(cleanData);
        this.tableRef.current?.search("status", "");
        this.setState({
            saving: true,
            progress: 0,
        });

        this.packs = [];
        do {
            let fragment = items.splice(0, 25);
            this.packs.push(fragment);
        } while (items.length > 25);
        if (items.length > 0) {
            this.packs.push(items);
        }

        this.results = {
            items: [],
            websites: {},
            steps: this.packs.length,
            pending: this.packs.length
        };
        this.tableRef.current.populate([]);
        this.procNext();
    }

    procNext() {

        if (this.packs.length === 0) {

            this.setState({
                saving: false,
            }, () => {
                this.tableRef.current.populate(this.results.items);
                this.props.history.push('/others/urlchecker#results');
                this.getSpreadsheet().options.editable = true;
                this.ShowResults();
            });

            return;
        }

        let items = this.packs.shift();
        URLLookupService.check({ items }).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while checking URLs");
                this.setState({
                    saving: false,
                }, () => {
                    this.getSpreadsheet().options.editable = true;
                });
                return;
            }

            result.items.forEach((item) => {
                this.results.items.push(item);
            });

            Object.keys(result.websites).forEach((key) => {
                this.results.websites[key] = result.websites[key];
            });

            this.results.pending--;
            this.setState({
                progress: (this.results.steps - this.results.pending) * 100 / this.results.steps
            })
            this.procNext();
        });

    }

    async ShowResults() {
        let plural;
        plural = this.results.items.length > 1;
        Toast.ok(plural ? "All rows have been processed successfully" : "Row processed successfully");
    }

    render() {
        const key = window.location.hash.match(/#results$/) ? 1 : (window.location.hash.match(/websites/) ? 1 : 0);

        return (
            <Container className="pt-4">
                {this.state.saving === false && key === 1 && (
                    <span style={{
                        float: "right"
                    }}
                    >
                        <Button
                            size="sm"
                            className="tdButton"
                            variant="success"
                            onClick={this.downloadSpreadsheet}
                        >
                            <FontAwesomeIcon icon={faDownload} style={{
                                marginRight: "5px"
                            }} />
                            Download
                        </Button>

                    </span>
                )}

                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Others</Breadcrumb.Item>
                    <Breadcrumb.Item
                        active={key === 0}
                        onClick={() => {
                            if (this.state.saving) {
                                return;
                            }
                            this.props.history.push("/others/urlchecker")
                        }}
                    >
                        URL Checker
                    </Breadcrumb.Item>
                    {key > 0 && (
                        <Breadcrumb.Item active>Results</Breadcrumb.Item>
                    )}
                </Breadcrumb>
                <MyCarousel activeIndex={key}>
                    <Carousel.Item>
                        {this.state.loading && (
                            <Loading />
                        )}
                        {!this.state.loading && (
                            <React.Fragment>
                                <Spreadsheet ref={this.spreadsheet} data={[[]]} options={{
                                    minDimensions: [2, 10000],
                                    lazyLoading: true,
                                    tableOverflow: true,
                                    columnResize: false,
                                    minSpareRows: 1,
                                    columns: [
                                        {
                                            type: 'text',
                                            title: 'Identifier',
                                            width: 150,
                                            align: "left"
                                        },
                                        {
                                            type: 'text',
                                            title: 'URL',
                                            width: 900,
                                            align: "left"
                                        }
                                    ]
                                }} />
                                <Row>
                                    <Col sm={6}>
                                        <ProcessingButton
                                            variant="primary"
                                            size="sm"
                                            processing={this.state.saving}
                                            processingLabel="Checking ..."
                                            label="Check"
                                            onClick={this.upload}
                                        />
                                    </Col>
                                    {this.state.saving && (
                                        <Col>
                                            <ProgressBar completed={this.state.progress} bgcolor="#1f4503" />
                                        </Col>
                                    )}
                                </Row>
                            </React.Fragment>
                        )}
                    </Carousel.Item>
                    <Carousel.Item>
                        <MyTable
                            name="url-checker"
                            dynamic={false}
                            ref={this.tableRef}
                            sortBy="identifier"
                            sortOrder="asc"
                            headers={
                                [
                                    {
                                        width: "85px",
                                        field: "identifier",
                                        label: "Identifier",
                                        sortable: true,
                                        searchable: true
                                    },
                                    {
                                        width: "85px",
                                        field: "upc",
                                        label: "UPC",
                                        sortable: true,
                                        searchable: true
                                    },
                                    {
                                        width: "200px",
                                        field: "url",
                                        label: "URL",
                                        sortable: true,
                                        searchable: true
                                    },
                                    {
                                        width: "200px",
                                        field: "original",
                                        label: "In DB",
                                        sortable: true,
                                        searchable: true
                                    },
                                    {
                                        width: "50px",
                                        field: "equals",
                                        label: "Equals",
                                        sortable: true,
                                        allowEmpty: true,
                                        options: [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]
                                    },
                                ]}
                            renderColumns={{
                                identifier: (row) => <Link className="link" to={`/product/${row.pid}`}>{row.identifier}</Link>,
                                url: (row) => <Form.Control
                                    type="text"
                                    value={row.url}
                                    readOnly={true}
                                />,
                                original: (row) => <Form.Control
                                    type="text"
                                    value={row.original}
                                    readOnly={true}
                                />,
                                equals: {
                                    format: (row) => parseInt(row.equals) ? <FontAwesomeIcon
                                        style={{
                                            color: "#1f4503",
                                        }}
                                        icon={faCheck}
                                    /> : <FontAwesomeIcon
                                        style={{ color: "red" }}
                                        icon={faTimes}
                                    />,
                                    csv: (row) => row.equals === 1 ? 'Yes' : 'No',
                                    className: "text-center"
                                },
                            }}
                        />
                    </Carousel.Item>
                </MyCarousel>
            </Container>
        );
    }
}
export default withRouter(URLChecker);
