import React, { createRef } from "react";

import { Container, Breadcrumb, Button, Modal, Form } from "react-bootstrap";
import Toast from "../../../services/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faDownload, faFlag, faPen, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import MyTable from "../../../components/MyTable";
import NPDService from "../../../services/NPDService";
import { Link } from "react-router-dom";
import MySpinner from "../../../components/MySpinner";
import ProcessingButton from "../../../components/ProcessingButton";
import WebsiteSearch from "../../../components/WebsiteSearch";
import confirm from "../../../components/Confirm";
import "./SmartSelection.css";

/**
 * Smart Selection component
 * @component
 * @category Others
 */
class Sniffer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add: false,
            adding: false,
            edit: false,
            editing: false,
            canSubmit: false,
            skip: []
        }
        this.tableRef = createRef();
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
        this.handleSwitch = this.handleSwitch.bind(this);
        this.handleSwitchAutopopulate = this.handleSwitchAutopopulate.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleNotes = this.handleNotes.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.onChangeWebsite = this.onChangeWebsite.bind(this);
        this.markAsBad = this.markAsBad.bind(this);

    }

    handleNew() {
        this.setState({ add: true });
    }

    showNotes(row) {
        this.edit = row;
        this.setState({
            edit: true,
            notes: row.notes
        });
    }

    handleSave() {
        var row = this.edit,
            notes = this.state.notes.trim();
        this.setState({ editing: true });
        NPDService.setNotes(row.id, notes).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while saving notes");
                this.setState({ editing: false });
            } else {
                row.notes = notes;
                this.tableRef.current.updateRow(row);
                delete this.edit;
                this.setState({ edit: false, editing: false });
            }
        });
    }

    handleNotes(evt) {
        var obj = this.tableRef.current.getRowFromEvent(evt),
            row = obj.row;

        if (typeof row.notes === 'undefined') {
            this.tableRef.current.setLoading(true);
            NPDService.getNotes(row.id).then((result) => {
                if (result === false) {
                    Toast.error("Something went wrong while retrieving this website's notes");
                } else {
                    row.notes = result;
                    this.tableRef.current.updateRow(row);
                    this.showNotes(row);
                }
                this.tableRef.current.setLoading(false);
            });
        } else {
            this.showNotes(row);
        }
    }

    handleAdd() {

        this.setState({ adding: true }, () => {
            NPDService.add(this.state.website_id).then((row) => {
                if (row === false) {
                    Toast.error("Something went wrong while saving changes");
                    this.setState({ adding: false })
                    return;
                }
                var skip = this.state.skip;
                row.coverage = row.target_products ? (row.current_urls * 100 / row.target_products).toFixed(2) : 0.00;
                row.issues = false;
                skip.push(row.id);
                this.setState({
                    add: false,
                    adding: false,
                    skip
                }, () => {
                    this.tableRef.current.addRow(row);
                });
            });
        });
    }

    markAsBad(evt) {
        var obj = this.tableRef.current.getRowFromEvent(evt),
            row = obj.row,
            notes;
        if (typeof row.notes === "string" && row.notes.includes('No UPC')) {
            Toast.info("Website already flagged as false positive.");
            return;
        }

        row.flagging = true;
        notes = typeof row.notes !== "string" ? "No UPC" : (row.notes + "\nNo UPC").trimStart()
        this.tableRef.current.updateRow(row);
        NPDService.setNotes(row.id, notes).then((result) => {
            delete row.flagging;
            if (result === false) {
                Toast.error("Something went wrong while saving notes");
            } else {
                row.notes = notes;
            }
            this.tableRef.current.updateRow(row);
        });
    }

    async handleSwitch(evt) {
        var obj = this.tableRef.current.getRowFromEvent(evt),
            row = obj.row;

        if (!(await confirm("Are you sure?", "Continue", "Cancel", { message: "You're about to " + (row.enabled ? "disable" : "enable") + " " + row.website + (row.enabled ? " from NPD" : " into NPD") + ". Would you like to proceed?" }))) {
            return;
        }

        row.switching = true;
        this.tableRef.current.updateRow(row);
        NPDService.switch(row.id, !row.enabled).then((result) => {
            if (result) {
                Toast.ok("Settings changed successfully");
                row.enabled = !row.enabled;
            } else {
                Toast.error("Something went wrong while changing settings");
            }
            delete row.switching;
            this.tableRef.current.updateRow(row);
        });
    }

    async handleSwitchAutopopulate(evt) {
        var obj = this.tableRef.current.getRowFromEvent(evt),
            row = obj.row;

        if (!await confirm(
            "Are you sure?",
            "Continue",
            "Cancel",
            {
                message: "You're about to " + (row.auto_populate ? "disable" : "enable") +
                    " autopopulation for " + row.website + ". Would you like to proceed?"
            }
        )) {
            return;
        }

        row.autopopulating = true;
        this.tableRef.current.updateRow(row);
        NPDService.autopopulate(row.id, !row.auto_populate).then((result) => {
            if (result) {
                Toast.ok("Settings changed successfully");
                row.auto_populate = !row.auto_populate;
            } else {
                Toast.error("Something went wrong while changing settings");
            }
            delete row.autopopulating;
            this.tableRef.current.updateRow(row);
        });
    }

    downloadSpreadsheet(evt) {
        this.tableRef.current.CSV()
    }

    componentDidMount() {
        NPDService.smartselection().then((data) => {
            if (data === false) {
                Toast.error(
                    "Something went wrong while retrieving the list of rows"
                );
                return;
            }
            data.forEach((row) => {
                row.coverage = row.target_products ? (row.current_urls * 100 / row.target_products).toFixed(2) : 0.00;
                row.issues = row.matcher_check === 0 || row.crawler_check === 0;
            });
            this.tableRef.current?.populate(data);
            var skip = [];
            data.forEach((row) => {
                skip.push(row.id);
            });
            this.setState({ skip });
        });
    }

    onChangeWebsite(row) {
        if (row === null || row.length === 0) {
            this.setState({ canSubmit: false });
            return;
        }

        this.setState({ canSubmit: true, website_id: row[0].id });
    }

    render() {
        return (
            <Container className="pt-4">
                <span style={{
                    float: "right"
                }}
                >
                    <Button
                        size="sm"
                        className="tdButton"
                        variant="success"
                        label="Download CSV"
                        onClick={this.downloadSpreadsheet}
                    >
                        <FontAwesomeIcon icon={faDownload} /> Download
                    </Button>
                </span>
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Others</Breadcrumb.Item>
                    <Breadcrumb.Item>NPD</Breadcrumb.Item>
                    <Breadcrumb.Item active>Smart Selection</Breadcrumb.Item>
                </Breadcrumb>
                <Modal
                    size="lg"
                    show={this.state.add || this.state.adding}
                    backdrop={true}
                    keyboard={true}
                    centered
                >
                    <Modal.Header closeButton onHide={() => this.setState({ add: false })}>
                        <Modal.Title>
                            Add Website
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <WebsiteSearch disabled={this.state.adding} skip={this.state.skip} onChange={this.onChangeWebsite} />
                    </Modal.Body>
                    <Modal.Footer style={{
                        justifyContent: "center"
                    }}>
                        <Button size="sm" variant="secondary" onClick={() => this.setState({ add: false })}>Cancel</Button>
                        <ProcessingButton
                            size="sm"
                            disabled={!this.state.canSubmit}
                            processing={this.state.adding}
                            processingLabel="Adding ..."
                            label="Add"
                            onClick={this.handleAdd}
                        />
                    </Modal.Footer>
                </Modal >
                <Modal
                    size="lg"
                    show={this.state.edit || this.state.editing}
                    backdrop={true}
                    keyboard={true}
                    centered
                >
                    <Modal.Header closeButton onHide={() => this.setState({ edit: false })}>
                        <Modal.Title>
                            Notes
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control
                            as="textarea"
                            placeholder="Notes"
                            style={{
                                height: "350px"
                            }}
                            value={this.state.notes}
                            onChange={(evt) => this.setState({ notes: evt.target.value })}
                            readOnly={this.state.editing}
                        />
                    </Modal.Body>
                    <Modal.Footer style={{
                        justifyContent: "center"
                    }}>
                        <Button size="sm" variant="secondary" onClick={() => this.setState({ edit: false })}>Cancel</Button>
                        <ProcessingButton
                            size="sm"
                            processing={this.state.editing}
                            processingLabel="Saving ..."
                            label="Save"
                            onClick={this.handleSave}
                        />
                    </Modal.Footer>
                </Modal >
                <MyTable
                    name="smart-selection"
                    ref={this.tableRef}
                    dynamic={false}
                    sortBy="current_urls"
                    sortOrder="asc"
                    headers={[
                        {
                            field: "id",
                            label: "ID",
                            sortable: true,
                            searchable: true,
                            width: "50px"
                        },
                        {
                            field: "website",
                            label: "Website",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "bucket",
                            label: "Bucket",
                            sortable: true,
                            searchable: true,
                            width: "125px"
                        },
                        {
                            field: "origin",
                            label: "Origin",
                            sortable: true,
                            width: "100px",
                            hint: "Which component detected this website's NPD compatibility?",
                            allowEmpty: true,
                            options: [{ label: 'Crawl Results', value: 'crawl_results' }, { label: 'HTML Cache', value: 'html' }]
                        },
                        {
                            field: "target_clients",
                            label: "# Clients",
                            hint: "How many clients can be possibly affected by this website?",
                            sortable: true,
                            width: "100px",
                            allowEmpty: true,
                            options: [{ label: '0 - 333', value: '333' }, { label: '333 - 666', value: '666' }, { label: '> 666', value: '999' }]
                        },
                        {
                            field: "target_products",
                            hint: "How many products can be possibly found in this website?",
                            label: "# Products",
                            sortable: true,
                            width: "100px",
                        },
                        {
                            field: "current_urls",
                            hint: "How many URLs do website currenty have?",
                            label: "URLs",
                            width: "100px",
                            sortable: true,
                            allowEmpty: true,
                            options: [{ label: '0 - 25', value: '<25' }, { label: '25 - 50', value: '<50' }, { label: '50 - 150', value: '<150' }, { label: '> 150', value: '>150' }]
                        },
                        {
                            field: "coverage",
                            label: "Coverage",
                            hint: "Relationship between number of candidate products / current URL mappings",
                            sortable: true,
                            width: "100px",
                        },
                        {
                            field: "enabled",
                            label: "NPD",
                            sortable: true,
                            width: "75px",
                            allowEmpty: true,
                            options: [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]
                        },
                        {
                            field: "auto_populate",
                            label: "AutoPop",
                            hint: "Automatically populate products' lookup table with results?",
                            sortable: true,
                            width: "75px",
                            allowEmpty: true,
                            options: [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]
                        },
                        {
                            field: "issues",
                            label: "Issues",
                            sortable: true,
                            width: "75px",
                            allowEmpty: true,
                            options: [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]
                        },
                        {
                            field: "notes",
                            label: "Notes",
                            sortable: true,
                            width: "75px",
                            allowEmpty: true,
                            options: [{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]
                        },
                        {
                            field: "control",
                            width: "87px",
                            className: "text-center",
                            content: <Button
                                onClick={this.handleNew}
                                size="sm"
                                className="tdButton"
                                variant="success">
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        }
                    ]}
                    renderColumns={{
                        website: {
                            format: (row) => <Link className="link" to={`/website/${row.id}`} target="_blank">{row.website.toLowerCase()}</Link>,
                            text: (row) => row.website,
                            csv: (row) => row.website.toLowerCase()
                        },
                        enabled: {
                            className: "text-center",
                            format: (row) => {
                                if (typeof row.switching === 'undefined') {
                                    const style = row.enabled ? { color: "green" } : { color: "red" },
                                        icon = row.enabled ? faCheck : faTimes;
                                    return <FontAwesomeIcon
                                        icon={icon}
                                        style={style}
                                        className="flying-btn" />

                                }
                                return <MySpinner />
                            },
                            text: (row) => row.enabled ? '1' : '0',
                            csv: (row) => row.enabled ? 'Yes' : 'No',
                        },
                        auto_populate: {
                            className: "text-center",
                            format: (row) => {
                                if (!row.enabled) {
                                    return null;
                                }
                                if (typeof row.autopopulating === 'undefined') {
                                    const style = row.auto_populate ? { color: "green" } : { color: "red" },
                                        icon = row.auto_populate ? faCheck : faTimes;

                                    return (
                                        <FontAwesomeIcon
                                            onClick={this.handleSwitchAutopopulate}
                                            icon={icon}
                                            style={style}
                                            className="flying-switch" />
                                    );
                                }
                                return <MySpinner />
                            },
                            text: (row) => row.enabled ? (row.auto_populate ? '1' : '0') : '',
                            csv: (row) => row.enabled ? (row.auto_populate ? 'Yes' : 'No') : '',
                        },
                        notes: {
                            className: "text-center",
                            format: (row) => {
                                return typeof row.notes === 'string' && row.notes.length
                                    ? <FontAwesomeIcon className="flying-btn" icon={faCheck} />
                                    : null
                            },
                            text: (row) => typeof row.notes === 'string' && row.notes.length ? '1' : '0',
                            csv: (row) => typeof row.notes === 'string' && row.notes.length ? 'Yes' : 'No',
                        },
                        issues: {
                            className: "text-center",
                            format: (row) => {
                                const cause = row.crawler_check === 0 ? "Crawling problems" : "Matching problems";
                                return !row.issues ? null :
                                    <FontAwesomeIcon
                                        className="flying-btn"
                                        title={cause}
                                        icon={faCheck}
                                        style={{ color: "red" }} />
                            },
                            text: (row) => row.issues ? '1' : '0',
                            csv: (row) => row.issues ? 'Yes' : 'No',
                        },
                        current_urls: {
                            text: (row) => {
                                if (row.current_urls <= 25) {
                                    return '<25';
                                }
                                if (row.current_urls <= 50) {
                                    return '<50';
                                }
                                if (row.current_urls <= 150) {
                                    return '<150';
                                }
                                return '>150';
                            }
                        },
                        target_clients: {
                            text: (row) => {
                                if (row.target_clients <= 333) {
                                    return '333';
                                }
                                if (row.target_clients <= 666) {
                                    return '666';
                                }
                                return '999';
                            }
                        },
                        control: {
                            format: (row) => typeof row.flagging !== "undefined" ? <MySpinner /> : <React.Fragment>
                                <Button
                                    size="sm"
                                    variant="outline-secondary"
                                    className="tdButton"
                                    onClick={this.handleNotes}
                                >
                                    <FontAwesomeIcon icon={faPen} />
                                </Button>
                                <Button
                                    size="sm"
                                    variant="outline-danger"
                                    className="tdButton"
                                    onClick={this.markAsBad}
                                    title="Mark as NO UPC"
                                >
                                    <FontAwesomeIcon icon={faFlag} />
                                </Button>
                            </React.Fragment>,
                            className: "text-center"
                        }
                    }}
                />
            </Container >
        );
    }
}

export default Sniffer;
