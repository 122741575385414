import React, { createRef } from "react";
import { Breadcrumb, Container, Form } from "react-bootstrap";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../components/IconButton";
import PDDService from "../../services/PDDService";
import MySpinner from "../../components/MySpinner";
import Toast from "../../services/Toast";
import MyTable from "../../components/MyTable";
import AuthService from "../../services/AuthService";
import Utils from "../../services/Utils";

/**
 * @component
 * @category Scenes
 * @subcategory PDD
 */
class ManualReview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showPickup: false,
        }
        this.tableRef = createRef();
        this.handleDownloadResults = this.handleDownloadResults.bind(this);
        this.handleChangeReviewStatus = this.handleChangeReviewStatus.bind(this);
    }

    handleDownloadResults(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);

        obj.row.downloading = true;
        this.tableRef.current.setRow(obj.index, obj.row);

        PDDService.download(obj.row.id, 'review', 'pdd-review').then((result) => {
            if (!result) {
                Toast.error(
                    "Something went wrong while generating the report."
                );
            }
            obj.row.downloading = false;
            this.tableRef.current?.setRow(obj.index, obj.row);
        });
    }

    handleChangeReviewStatus(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);

        obj.row.updating = true;
        this.tableRef.current.setRow(obj.index, obj.row);
        const status = evt.target.value;
        PDDService.changeReviewStatus(obj.row.id, status).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while updating the status");
                obj.row.updating = false;
                this.tableRef.current?.setRow(obj.index, obj.row);
                return;
            }
            console.log(AuthService.user());
            obj.row.review_status = status;
            obj.row.updated_by = AuthService.user().name;
            obj.row.updated_at = Utils.DateNow();
            obj.row.updating = false;
            this.tableRef.current?.setRow(obj.index, obj.row);
        });
    }

    componentDidMount() {
        PDDService.jobsInReview().then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of jobs");
                this.setState({ loading: false });
                this.tableRef.current?.setLoading(false);
                return;
            }
            this.setState({ loading: false }, () => {
                this.tableRef.current?.populate(data);
            });
        });
    }

    render() {
        return <Container className="pt-4">
            <Breadcrumb className="dd">
                <Breadcrumb.Item>PDD</Breadcrumb.Item>
                <Breadcrumb.Item active>Manual Review</Breadcrumb.Item>
            </Breadcrumb>
            <MyTable
                name="pdd-review"
                dynamic={false}
                ref={this.tableRef}
                sortBy="created_at"
                sortOrder="desc"
                headers={
                    [
                        {
                            field: "created_at",
                            label: "Date",
                            width: "90px",
                            sortable: true,
                            searchable: true,
                        },
                        {
                            field: "id",
                            label: "Batch ID",
                            sortable: true,
                            searchable: true,
                            width: "100px",
                        },
                        {
                            field: "manufacturer_name",
                            label: "Manufacturer",
                            sortable: true,
                            searchable: true,
                        },
                        {
                            field: "brand_name",
                            label: "Brand",
                            sortable: true,
                            searchable: true,
                        },
                        {
                            field: "total_products",
                            label: "# Products",
                            hint: 'Count of Unique Products',
                            width: "100px",
                            sortable: true,
                        },
                        {
                            field: "total_urls",
                            label: "# URLs",
                            hint: 'Count of URLs in Review',
                            width: "70px",
                            sortable: true,
                        },
                        {
                            field: "review_status",
                            label: "Status",
                            sortable: true,
                            allowEmpty: true,
                            width: "150px",
                            options: [
                                { label: 'Not started', value: 'pending' },
                                { label: 'In Review', value: 'in-review' },
                                { label: 'Completed', value: 'reviewed' },
                            ]
                        },
                        {
                            field: "updated_at",
                            label: "Updated at",
                            width: "100px",
                            sortable: true,
                        },
                        {
                            field: "updated_by",
                            label: "Updated By",
                            sortable: true,
                            searchable: true,
                        },
                        {
                            field: "control",
                            width: "80px",
                        }
                    ]
                }
                renderRows={{
                    className: (row) => {
                        switch(row.review_status) {
                            case 'in-review': return "ongoing";
                            case 'reviewed': return "success";
                            default: return "blocked";
                        }
                    }
                }}
                renderColumns={{
                    review_status: {
                        format: (row) => {
                            if (row.updating) {
                                return <MySpinner />
                            }
                            return <Form.Select
                                value={row.review_status}
                                onChange={this.handleChangeReviewStatus}
                            >
                                <option value='pending'>Not Started</option>
                                <option value='in-review'>In Review</option>
                                <option value='reviewed'>Completed</option>
                            </Form.Select>
                        }
                    },
                    control: {
                        className: "text-center",
                        format: (row) => {
                            if (row.downloading) {
                                return <MySpinner />
                            }
                            return <IconButton
                                size="sm"
                                variant="secondary"
                                className="tdButton"
                                onClick={this.handleDownloadResults}
                                icon={faDownload}
                            />
                        }
                    }
                }}
            />
        </Container>
    }
}

export default ManualReview;
