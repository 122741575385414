import axios from "axios";

import Config from "./Config";
import APIHelper from "./APIHelper";

const ImageDiscoveryService = {
    summary(manufacturer_id) {
        return axios
            .get(Config.API_URL + "imgdiscovery/products/" + manufacturer_id + "/summary")
            .then((response) => {
                if (APIHelper.checkStatus(response)) {
                    return {
                        total: response.data.total,
                        missing: response.data.missing
                    };
                }
                return false;
            })
            .catch(APIHelper.returnFalse);
    },
    /**
     * Retrieves jobs
     * @param {object} settings Related to pagination settings: page, pageSize, sort, search
     * @returns {array} of rows
     */
    jobs(settings) {
        return axios
            .post(Config.API_URL + "imgdiscovery/jobs", settings)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    request(payload) {
        return axios
            .post(Config.API_URL + "imgdiscovery/job", payload)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    job(id) {
        return axios
            .get(Config.API_URL + "imgdiscovery/job/" + id)
            .then(response => APIHelper.returnAttribute(response, 'row'))
            .catch(APIHelper.returnFalse);
    },
    remove(pid) {
        return axios
            .delete(Config.API_URL + "imgdiscovery/product/" + pid + "/image")
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
};

export default ImageDiscoveryService;
