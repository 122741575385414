import React, { createRef } from "react";

import { Container, Breadcrumb, Button } from "react-bootstrap";
import ImageDiscoveryService from "../../services/ImageDiscoveryService";
import { Link, withRouter } from "react-router-dom";
import Emitter from "../../services/Emitter";
import Toast from "../../services/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faDownload, faHourglass, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { MenuItem } from "@szhsin/react-menu";
import ContextMenuObject from "../../components/ContextMenuObject";
import confirm from "../../components/Confirm";
import MySpinner from "../../components/MySpinner";
import MyTable from "../../components/MyTable";

/**
 * Image Discovery Job view component
 * @component
 * @category Scenes
 * @subcategory Image Discovery
 */
class Job extends React.Component {
    constructor(props) {
        super(props);
        this.original = [];
        this.state = {
            image: null,
            loading: true
        };
        this.reload = this.reload.bind(this);
        this.showImage = this.showImage.bind(this);
        this.handleRemoveImage = this.handleRemoveImage.bind(this);
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
        this.tableRef = createRef();
    }

    componentDidMount() {
        Emitter.on("IMGDISCOVERY_JOB", this.reload);
    }

    componentWillUnmount() {
        Emitter.off("IMGDISCOVERY_JOB");
    }

    reload(jobId) {
        if (typeof jobId === "undefined") {
            return;
        }

        this.tableRef.current.populate([]);
        this.tableRef.current.setLoading(true);
        this.setState({ jobId, loading: true });
        ImageDiscoveryService.job(jobId).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while retriving this job's results");
                return;
            }

            this.tableRef.current?.populate(result.items);
            this.setState({ loading: false });
        });
    }

    showImage(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);
        this.setState({ image: `https://vi.trackstreet.com/products/${obj.row.pid}.jpg` })
    }

    downloadSpreadsheet() {
        this.tableRef.current.CSV({
            filename: "imgdiscovery-job-" + this.state.jobId + ".csv"
        });
    }

    async handleRemoveImage(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);

        if (
            !(await confirm("Are you sure?", "Continue", "Cancel", {
                message:
                    "You're about to remove image of product " + obj.row.identifier + ". This operation can't be undone. Do you want to continue?",
            }))
        ) {
            return;
        }

        obj.row.removing = true;
        this.tableRef.current.updateRow(obj.row);

        ImageDiscoveryService.remove(obj.row.pid).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while removing image");
            } else {
                obj.row.found = 0;
                Toast.ok("Image removed successfully");
            }

            delete obj.row.removing;
            this.tableRef.current.updateRow(obj.row);
        });
    }

    render() {
        return (
            <Container className="pt-4" >
                {
                    this.state.image !== null && (
                        <Lightbox
                            mainSrc={this.state.image}
                            onCloseRequest={() => this.setState({ image: null })}
                        />
                    )
                }
                <span style={{
                    float: "right"
                }}
                >
                    <Button
                        size="sm"
                        className="tdButton"
                        onClick={this.downloadSpreadsheet}
                        variant="success"
                        disabled={this.state.loading}
                    >
                        <FontAwesomeIcon icon={faDownload} style={{
                            marginRight: "5px"
                        }} />
                        Download
                    </Button>
                </span>
                <Breadcrumb className="dd" >
                    <Breadcrumb.Item>Image Discovery</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/imgdiscovery/">
                            Jobs
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active># {this.state.jobId}</Breadcrumb.Item>
                </Breadcrumb>
                <MyTable
                    name="imgdiscovery-job"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="identifier"
                    sortOrder="asc"
                    headers={
                        [
                            {
                                width: "125px",
                                field: "identifier",
                                label: "Identifier",
                                sortable: true,
                                searchable: true
                            },
                            {
                                width: "125px",
                                field: "upc",
                                label: "UPC",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "title",
                                label: "Title",
                                sortable: true,
                                searchable: true

                            },
                            {
                                width: "75px",
                                field: "status",
                                label: "Status",
                                sortable: true,
                                allowEmpty: true,
                                options: [{ label: 'Executed', value: 'executed' }, { label: 'Pending', value: 'pending' }]
                            },
                            {
                                width: "75px",
                                field: "found",
                                label: "Found",
                                sortable: true,
                                allowEmpty: true,
                                options: [{ label: 'Yes', value: '1' }, { label: 'No', value: '0' }]
                            },
                            {
                                field: "image",
                                label: "Image",
                                width: "125px",
                            }
                        ]}
                    renderColumns={{
                        identifier: (row) => <Link to={`/product/${row.pid}`} className="link">{row.identifier}</Link>,
                        upc: (row) => <Link to={`/product/${row.pid}`} className="link">{row.upc}</Link>,
                        image: {
                            csv: (row) => row.status === 'executed' && row.found ? `https://vi.trackstreet.com/products/${row.pid}.jpg` : '',
                            className: 'text-center',
                            format: (row) => typeof row.removing !== "undefined" && row.removing ? (
                                <MySpinner />
                            ) : row.status === 'executed' && row.found ? <ContextMenuObject target={(<img className="pointer" src={`https://vi.trackstreet.com/products/${row.pid}-tb.jpg`} alt={row.identifier} onClick={this.showImage} />)} menu={(
                                <MenuItem onClick={this.handleRemoveImage}><FontAwesomeIcon icon={faTrash} style={{
                                    marginRight: "10px",
                                }} />{` Discard & remove image`}</MenuItem>
                            )} /> : null
                        },
                        found: {
                            className: "text-center",
                            csv: (row) => row.status === 'executed' ? (row.found ? 'Yes' : 'No') : '',
                            format: (row) => row.status === 'executed' ? (row.found ? <FontAwesomeIcon
                                style={{
                                    color: "#1f4503",
                                }}
                                icon={faCheck}
                            /> :
                                <FontAwesomeIcon
                                    style={{ color: "red" }}
                                    icon={faTimes}
                                />) : null
                        },
                        status: {
                            className: "text-center",
                            format: (row) => row.status === 'executed' ?
                                <FontAwesomeIcon
                                    style={{
                                        color: "#1f4503",
                                    }}
                                    icon={faCheck}
                                />
                                :
                                <FontAwesomeIcon
                                    style={{
                                        color: "silver",
                                    }}
                                    icon={faHourglass}
                                />
                        }
                    }}
                />

            </Container >
        );
    }
}

export default withRouter(Job);
