import React, { createRef } from "react";

import { Container, Breadcrumb, Button, Form, } from "react-bootstrap";
import OnboardingService from "../../../services/OnboardingService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFileWord, faListOl, faBars, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink, withRouter } from "react-router-dom";
import Emitter from "../../../services/Emitter";
import Toast from "../../../services/Toast";
import ProgressBar from "../../../components/ProgressBar";
import { Menu, MenuItem } from "@szhsin/react-menu";
import Utils from "../../../services/Utils";
import MySpinner from "../../../components/MySpinner";
import MyTable from "../../../components/MyTable";

/**
 * Jobs component
 * @component
 * @category Scenes
 * @subcategory Onboarding/Bucketization
 */
class List extends React.Component {
    constructor(props) {
        super(props);

        this.reload = this.reload.bind(this);

        this.switchXPathRequested = this.switchXPathRequested.bind(this);
        this.handleDownloadXPath = this.handleDownloadXPath.bind(this);
        this.handleDownloadReport = this.handleDownloadReport.bind(this);
        this.updateJob = this.updateJob.bind(this);
        this.viewJob = this.viewJob.bind(this);

        this.tableRef = createRef();
        Emitter.on("RELOAD_ONBOARDING_JOBS", this.reload);
        Emitter.on("UPDATE_ONBOARDING_JOB", this.updateJob);
    }

    componentWillUnmount() {
        Emitter.off("RELOAD_ONBOARDING_JOBS", this.reload);
        Emitter.off("UPDATE_ONBOARDING_JOB", this.updateJob);
    }

    componentDidMount() {
        this.tableRef.current.init();
    }

    updateJob(info) {
        if (typeof info === "string" && info === "refresh") {
            this.reload();
            return;
        }
        if (typeof this.last !== "undefined" && this.last.jobId === info.jobId && this.last.checked === info.checked) {
            return;
        }

        this.last = info;
        let rows = this.tableRef.current?.data() ?? [];
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].id === info.jobId) {
                let row = rows[i];
                row.xpath_requested = info.checked ? "checked" : null;
                this.tableRef.current?.setRow(i, row);
                break;
            }
        }
    }

    reload() {
        this.tableRef.current?.reload();
    }

    switchXPathRequested(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt),
            requested = evt.target.checked;


        obj.row.updating = true;
        this.tableRef.current.setRow(obj.index, obj.row);
        OnboardingService.setRequested(obj.row.id, requested).then((result) => {
            obj.row.updating = false;
            if (result) {
                obj.row.xpath_requested = requested ? "checked" : null;
                Toast.ok("Row updated successfully");
            } else {
                Toast.error("Something went wrong while updating this row");
            }
            this.tableRef.current.setRow(obj.index, obj.row);
        });
    }

    handleDownloadXPath(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);
        obj.row.downloading = true;
        this.tableRef.current.setRow(obj.index, obj.row);

        OnboardingService.XPathReport(obj.row.id).then((result) => {
            delete obj.row.downloading;
            if (result === false) {
                Toast.error("Something went wrong while retrieving the list");
            } else if (result === -1) {
                Toast.info("Empty list");
            }
            this.tableRef.current?.setRow(obj.index, obj.row);
        });
    }

    handleDownloadReport(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);
        obj.row.downloading = true;
        this.tableRef.current.setRow(obj.index, obj.row);

        OnboardingService.job(obj.row.id).then((result) => {
            delete obj.row.downloading;
            if (result === false) {
                Toast.error("Something went wrong while generating the report");
            } else {
                let content = '"ID","Website","Requested","Delivered","Ticket","Status"\n';
                result.websites.forEach((row) => {
                    const synced = row.status === "categorized",
                        requested = synced ? '' : (typeof row.delivered === "string" && row.delivered.length ? result.created : ''),
                        delivered = synced ? '' : (typeof row.delivered === "string" ? row.delivered : '');

                    content += '"' + row.website_id + '","' + row.website + '","' + requested + '","' + delivered + '","' + (row.ticket !== null ? row.ticket : '') + '","' + OnboardingService.prettifyStatus(row).status + '"\n';
                })
                Utils.DownloadContent("onboarding-" + obj.row.id + "-report.csv", content);
            }
            this.tableRef.current?.setRow(obj.index, obj.row);
        });
    }

    viewJob(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);
        this.props.history.push('/onboarding/bucketization/job/' + obj.row.id);
    }

    render() {

        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Onboarding</Breadcrumb.Item>
                    <Breadcrumb.Item active>Bucketization</Breadcrumb.Item>
                </Breadcrumb>
                <MyTable
                    name="bucketization-list"
                    ref={this.tableRef}
                    dynamic={true}
                    sortBy="created"
                    sortOrder="desc"
                    reload={(setup) => {
                        return new Promise((resolve, reject) => {
                            OnboardingService.jobs({
                                pageSize: setup.pageSize,
                                page: setup.page,
                                search: setup.search,
                                sort: setup.sort,
                            }).then((data) => {
                                if (data === false) {
                                    Toast.error(
                                        "Something went wrong while retrieving the list of jobs"
                                    );
                                    reject();
                                    return
                                }
                                resolve(data);
                            }).catch(reject);
                        })
                    }}
                    headers={[
                        {
                            field: "id",
                            label: "ID",
                            width: "60px",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "created",
                            label: "Date",
                            width: "140px",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "requester",
                            label: "Requester",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "brand",
                            label: "Brand",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "websites",
                            label: "# Websites",
                            width: "100px",
                            sortable: true

                        },
                        {
                            field: "xpath_requested",
                            label: "XPath Requested",
                            width: "140px",
                            sortable: true

                        },
                        {
                            field: "progress",
                            label: "Progress",
                            width: "200px"
                        },
                        {

                            field: "status",
                            label: "Status",
                            width: "120px",
                            sortable: true
                        },
                        {
                            field: "control",
                            width: "80px",
                            content: <Button
                                as={NavLink}
                                to="/onboarding/bucketization/new"
                                size="sm"
                                variant="success"
                                className="tdButton"
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>

                        }]}
                    renderColumns={{
                        brand: (row) => <Link className="link" to={`/brand/${row.brand_id}`}>(#{row.brand_id}) {row.brand}</Link>,
                        xpath_requested: {
                            className: "text-center",
                            format: (row) => typeof row.updating === "undefined" || row.updating === false ?
                                <Form.Check className="lg" type="checkbox" checked={typeof row.xpath_requested === "string" && row.xpath_requested.length > 0} onChange={this.switchXPathRequested} />
                                : (
                                    typeof row.updating !== "undefined" && row.updating ? <MySpinner /> : null
                                )
                        },
                        progress: (row) => <ProgressBar
                            key={`progress-bar-${row.id}`}
                            bgcolor={"#1f4503"}
                            completed={row.progress}
                        />,
                        status: (row) => row.status.toUpperCase(),
                        control: {
                            style: {
                                display: "inline-flex"
                            },
                            format: (row) => {
                                let DropDownButton = () => {
                                    if (typeof row.downloading === "boolean" && row.downloading) {
                                        return (
                                            <MySpinner />
                                        )
                                    }
                                    return (
                                        <Menu
                                            direction="right"
                                            style={{
                                                fontSize: "medium"
                                            }}
                                            menuButton={
                                                <Button
                                                    size="sm"
                                                    variant="outline-secondary"
                                                    className="tdButton"
                                                >
                                                    <FontAwesomeIcon icon={faDownload} />
                                                </Button>}>
                                            <MenuItem mode="report" onClick={this.handleDownloadReport}>
                                                <FontAwesomeIcon
                                                    icon={faListOl}
                                                    style={{
                                                        marginRight: "10px",
                                                    }}
                                                />
                                                Full report
                                            </MenuItem>
                                            <MenuItem mode="keywords" onClick={this.handleDownloadXPath}>
                                                <FontAwesomeIcon
                                                    icon={faFileWord}
                                                    style={{
                                                        marginRight: "10px",
                                                    }}
                                                />
                                                Websites to XPath team</MenuItem>
                                        </Menu >)
                                }
                                return <React.Fragment>
                                    {DropDownButton()}
                                    <Button
                                        size="sm"
                                        variant="outline-secondary"
                                        className="tdButton"
                                        onClick={this.viewJob}
                                    >
                                        <FontAwesomeIcon icon={faBars} />
                                    </Button>
                                </React.Fragment>
                            }
                        }
                    }}
                />
            </Container>
        );
    }
}

export default withRouter(List);
