import React from "react";
import ReactMarkdown from 'react-markdown'
import { Container } from "react-bootstrap";
import SettingsService from "../services/SettingsService";
import Version from "../services/Version";

/**
 * Show release notes
 * @component
 * @category User
 */
class Releases extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: ""
        }

        fetch('NOTES.txt', {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 0
            }
        })
            .then((r) => r.text())
            .then(text => {
                this.setState({ notes: text })
                SettingsService.Set('LastRelease', Version.Notes);
            })
    }

    render() {
        const LinkRenderer = (props) => {
            return (
                <a href={props.href} target="_blank" rel="noreferrer">
                    {props.children}
                </a>
            );
        },
            ParagraphRender = (props) => {
                return <h3 style={{
                    marginTop: "25px",
                    marginBottom: "10px"
                }}>{props.children}</h3>
            }
        return (
            <Container className="pt-4">
                <ReactMarkdown components={{ a: LinkRenderer, h3: ParagraphRender }}>{this.state.notes}</ReactMarkdown>
            </Container >
        );
    }
}
export default Releases;
