import axios from "axios";

import Config from "./Config";
import APIHelper from "./APIHelper";

/**
 * Wrapper for crawl_results' related services
 * @hideconstructor
 */
class CrawlResultsService {
    /**
     * Retrieves all INFO field from all the rows identified through ids
     * @param {array} ids
     * @returns {array}
     */
    static get(ids) {
        return axios
            .post(Config.API_URL + "crawl_results", { ids })
            .then(APIHelper.returnDataOrEmptyArray)
            .catch(APIHelper.returnEmptyArray);
    }
    /**
     * Provides data to the Crawled Results tab
     * @param {int} website_id
     * @param {object} settings Related to pagination settings: page, pageSize, sort, search
     * @returns {array} of rows
     */
    static successful(website_id, settings) {
        return axios
            .post(
                Config.API_URL + "website/" + website_id + "/crawl_results",
                settings
            )
            .then(APIHelper.returnDataOrEmptyArray)
            .catch(APIHelper.returnEmptyArray);
    }
    /**
     * Provides data to the Rejected tab
     * @param {int} website_id
     * @param {object} settings Related to pagination settings: page, pageSize, sort, search
     * @returns {array} of rows
     */
    static unsuccessful(website_id, settings) {
        return axios
            .post(
                Config.API_URL + "website/" + website_id + "/rejected",
                settings
            )
            .then(APIHelper.returnDataOrEmptyArray)
            .catch(APIHelper.returnEmptyArray);
    }

    static getNonCrawledItems(website_id, brand_id) {
        return axios
            .post(
                Config.API_URL + "diags/non_crawled_items", {
                website_id,
                brand_id
            }
            )
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    }

    static switchCheck(id, checked) {
        return axios
            .post(
                Config.API_URL + "crawl_results/switch", {
                id,
                checked
            }
            )
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    }

    static checkForModifications(pack) {
        return axios
            .post(Config.API_URL + "crawl_results/review", pack)
            .then(APIHelper.returnItems)
            .catch(APIHelper.returnFalse);
    }
}

export default CrawlResultsService;
