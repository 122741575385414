import axios from "axios";

import Config from "./Config";
import APIHelper from "./APIHelper";

const DoNotMapService = {
    /**
         * Retrieves rows
         * @param {object} settings Related to pagination settings: page, pageSize, sort, search
         * @returns {array} of rows
         */
    all() {
        return axios
            .get(Config.API_URL + "donotmap")
            .then(APIHelper.returnRows)
            .catch(APIHelper.returnFalse);
    },
    note(id) {
        return axios
            .get(Config.API_URL + "donotmap/" + id + "/note")
            .then(response => APIHelper.returnAttribute(response, 'note'))
            .catch(APIHelper.returnFalse);
    },
    new(payload) {
        return axios
            .post(Config.API_URL + "donotmap/new", payload)
            .then(response => APIHelper.returnAttribute(response, 'row'))
            .catch(APIHelper.returnFalse);
    },
    save(id, payload) {
        return axios
            .put(Config.API_URL + "donotmap/" + id, payload)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    delete(id) {
        return axios
            .delete(Config.API_URL + "donotmap/" + id)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    }
};

export default DoNotMapService;
