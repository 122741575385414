import React from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { withRouter } from "react-router-dom";
import Memory from "../../services/MemoryService";
import { Breadcrumb, Container, Form, Row } from "react-bootstrap";
import MerchantSearch from "../../components/MerchantSearch";

/**
 * Show a typeahead component for finding rows
 * @component
 * @category Scenes
 * @subcategory Merchant
 */
class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            previous_names: false,
            options: [],
            query: "",
        };
    }

    handleChange = (row) => {
        Memory.Flush("MERCHANT_PROFILE");
        this.props.history.push(`/merchant/${row[0].id}`);
    };

    render() {
        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Merchant</Breadcrumb.Item>
                    <Breadcrumb.Item active>Search</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    {" "}
                    <MerchantSearch onChange={this.handleChange} previous_names={this.state.previous_names} />
                    <div>
                        <Form.Check type="checkbox" label="Search within sellers' previous names" checked={this.state.previous_names} onChange={(evt) => {
                            this.setState({ previous_names: evt.target.checked });
                        }} />
                    </div>
                </Row>
            </Container>
        );
    }
}

export default withRouter(Search);
