import React, { createRef } from "react";

import { Button, Container } from "react-bootstrap";
import Toast from "../../services/Toast";
import "../../../node_modules/currency-flags/dist/currency-flags.min.css";
import LogsService from "../../services/LogsService";
import { faDownload, faExclamationTriangle, faInfoCircle, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DomainDiscovery from "../../services/DomainDiscoveryService";
import "./Log.css";
import confirm from "../../components/Confirm";
import MyTable from "../../components/MyTable";
import prompt from "../../components/Prompt";

/**
 * Log component
 * @component
 * @category Scenes
 * @subcategory Domain Discovery
 */
class Log extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showReset: false
        }
        this.returnDelivery = this.returnDelivery.bind(this);
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
        this.reset = this.reset.bind(this);
        this.tableRef = createRef();
    }

    reset() {
        this.tableRef.current.setLoading(true);
        this.setState({ loading: true })
        LogsService.recent('dd').then((data) => {
            this.setState({ loading: false })
            if (data === false) {
                Toast.error("Something went wrong while retrieving logs");
                return;
            }
            this.tableRef.current?.populate(data);
        });
    }

    componentDidMount() {
        this.reset();
    }


    downloadSpreadsheet() {
        this.tableRef.current.CSV()
    }

    returnDelivery(evt) {
        const msg = evt.target.closest("span.zeroResults").getElementsByClassName("msg")[0].innerText,
            fragId = msg.match(/Fragment #(\d+)/)[1];
        confirm("Fragment #" + fragId, "Yes", "No", {
            message: "Would you like to send this fragment back to processing?"
        }).then((result) => {
            if (!result) {
                return;
            }

            DomainDiscovery.resetFragment(fragId).then((result) => {
                if (result === false) {
                    Toast.error("Failed resetting fragment.");
                } else if (typeof result === "string") {
                    Toast.info(result);
                } else {
                    Toast.ok("Fragment sent back to process successfully!");
                }
            });
        });
    }

    render() {

        return (
            <Container className="pt-4">
                <div style={{
                    textAlign: "right"
                }}
                >
                    {this.state.showReset && (
                        <Button
                            disabled={this.state.loading}
                            onClick={() => {
                                this.setState({ showReset: false }, this.reset)
                            }}
                            variant="secondary"
                            size="sm"
                            className="tdButton"
                        >
                            <FontAwesomeIcon icon={faRedo} style={{
                                marginRight: "5px"
                            }} />
                            Reset
                        </Button>
                    )}
                    <Button
                        className="tdButton"
                        disabled={this.state.loading}
                        onClick={async () => {
                            let search = await prompt('Enter search', 'Search', 'Cancel');
                            if (search.value.length) {
                                this.setState({ loading: true });
                                this.tableRef.current.setLoading(true);
                                LogsService.search(search.value, 'dd').then((data) => {
                                    this.setState({ loading: false, showReset: true })
                                    if (data === false) {
                                        Toast.error("Something went wrong while retrieving logs");
                                        return;
                                    }
                                    this.tableRef.current?.populate(data);
                                });
                            }
                        }}
                        variant="primary"
                        size="sm"
                    >Extended search</Button>
                    <Button
                        size="sm"
                        className="tdButton"
                        variant="success"
                        onClick={this.downloadSpreadsheet}
                        disabled={this.state.loading}
                    >
                        <FontAwesomeIcon icon={faDownload} style={{
                            marginRight: "5px"
                        }} />
                        Download
                    </Button>
                </div>
                <MyTable
                    name="dd-log"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="datetime"
                    sortOrder="desc"
                    headers={
                        [
                            {
                                field: "datetime",
                                label: "Date/time",
                                width: "140px",
                                sortable: true,
                                searchable: true,
                            },
                            {
                                field: "level",
                                label: "Severity",
                                sortable: true,
                                width: "80px"
                            },
                            {
                                field: "message",
                                label: "Message",
                                sortable: true,
                                searchable: true
                            }
                        ]}
                    renderColumns={{
                        level: {
                            className: "text-center",
                            format: (row) => {
                                let icon, color;
                                switch (row.level) {
                                    case 'error':
                                        icon = faExclamationTriangle;
                                        color = 'red';
                                        break;
                                    case 'warning':
                                        icon = faExclamationTriangle;
                                        color = 'orange';
                                        break;
                                    default:
                                        icon = faInfoCircle;
                                        color = 'navy';
                                        break;
                                }
                                return <FontAwesomeIcon icon={icon} style={{
                                    color,
                                }} />
                            }
                        },
                        message: (row) => {
                            let message = row.message.indexOf('with 0 results') !== -1 ? (
                                <span className="zeroResults" onClick={this.returnDelivery}><span className="msg">{row.message}</span><span className="reset">(Click to reset)</span></span>
                            ) : null,
                                parts = row.message.match(/Fragment #(\d+) \(Job #(\d+)/i);
                            if (message === null && parts !== null) {
                                message = <a className="link" target="_blank" rel="noreferrer" href={"/dd/job/" + parts[2] + "/" + parts[1]}>{row.message}</a>
                            }
                            if (message === null) {
                                message = row.message;
                            }
                            return message;
                        }
                    }}
                />
            </Container>
        );
    }
}

export default Log;
