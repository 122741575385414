import React from "react";

import { Nav, Carousel, Container, Breadcrumb } from "react-bootstrap";
import ManufacturerService from "../../services/ManufacturerService";
import Profile from "./Profile";
import MyCarousel from "../../components/MyCarousel";

class Index extends React.Component {
    constructor(props) {
        super(props);
        let id = this.props.match.params.id * 1;
        this.state = {
            name: "...",
            id
        };
        this.firstLoad = true;
        ManufacturerService.breadcrumb(id).then((name) => {
            this.setState({ name: name });
        }).catch(() => {
            this.setState({ name: '?' });
        })
    }

    showPage(tab) {
        return (
            <MyCarousel activeIndex={0}>
                <Carousel.Item key="#profile" className="standardGrid">
                    <Profile manufacturerId={this.state.id} />
                </Carousel.Item>
            </MyCarousel>
        );
    }

    render() {
        let activeKey = "#profile";
        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Manufacturer</Breadcrumb.Item>
                    <Breadcrumb.Item active>{this.state.name}</Breadcrumb.Item>
                </Breadcrumb>
                <Nav variant="tabs" activeKey={activeKey}>
                    <Nav.Item>
                        <Nav.Link href="#profile">Profile</Nav.Link>
                    </Nav.Item>
                </Nav>
                {this.showPage(activeKey)}
            </Container>
        );
    }
}
export default Index;
