import React from "react";

import { Container, Breadcrumb, Row, Col } from "react-bootstrap";
import {
    Tooltip,
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell,
    CartesianGrid,
    XAxis,
    YAxis,
    Line,
    LineChart
} from "recharts";
import Toast from "../../services/Toast";
import GenericService from "../../services/GenericService";
import Utils from "../../services/Utils";
import MySpinner from "../../components/MySpinner";

const FIELDS = ['manual', 'v1', 'npd', 'ng', 'direct', 'pdd', 'google-shopping', 'unknown', 'others'];
const COLORS = ["#700000", "#388E3C", "#1a237e", "#8E398A", "#f76d9e", "#99CCFF", "#99CC00", "#808080", "#ff9a46"];
/**
 * Smart Selection Gauge component
 * @component
 * @category Others
 */
class PD extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingStates: true,
            loadingOvertime: true,
            overtime: []
        }
    }

    componentDidMount() {
        GenericService.getPDDistribution().then((data) => {
            if (data === false) {
                Toast.error(
                    "Something went wrong while retrieving the list of rows"
                );
                return;
            }

            this.setState({
                loadingStates: false,
                websites: data.websites,
                urls: data.urls
            });
        });
        GenericService.getPDOvertime().then((data) => {
            if (data === false) {
                Toast.error(
                    "Something went wrong while retrieving the data over time"
                );
                return;
            }

            let pretty = [];
            Object.keys(data).forEach((k) => {
                let row = data[k];
                row.name = k;
                pretty.push(row);
            });

            this.setState({
                loadingOvertime: false,
                overtime: pretty
            });
        });
    }

    CustomTooltip({ active, payload, label }) {
        if (active && payload && payload.length) {
            return (
                <div className="entryTooltip">
                    <div className="title"><b>{payload[0].name}</b></div>
                    <div className="percentage">{payload[0].payload.percent}% ({Utils.FormatNumber(payload[0].value, false)})</div>
                </div>
            );
        }
        return null;
    }

    CustomTooltipOvertime({ active, payload, label }) {
        if (active && payload && payload.length) {
            let total = 0;
            FIELDS.forEach((field) => {
                if (typeof payload[0].payload[field] === "undefined") {
                    payload[0].payload[field] = 0;
                } else if (typeof payload[0].payload[field] === "number") {
                    total += parseInt(payload[0].payload[field]);
                } else {
                    payload[0].payload[field] = 0;
                }
            });
            return (
                <div className="entryTooltip">
                    <div className="title"><b>{payload[0].payload.name}</b></div>
                    {FIELDS.map((field, index) => {
                        return <div className="listEntry" key={`tooltip-${index}`}>
                            <span className="square" style={{ backgroundColor: COLORS[index] }}></span>
                            <span><b>{field}</b>: {Utils.FormatNumber(payload[0].payload[field], false)} ({Utils.FormatNumber(payload[0].payload[field] * 100 / total, true)}%)</span>
                        </div>
                    })}
                    <div className="listEntry">
                        <span className="square" style={{ visibility: "hidden" }}></span>
                        <span><b>total</b>: {Utils.FormatNumber(total, false)}</span>
                    </div>
                </div>
            );
        }
        return null;
    }

    render() {
        const stats = this.state.loadingStates
            ? <p align="center"><MySpinner /></p>
            : <React.Fragment>
                <Row>
                    <Col sm={6}>
                        <p align="center"><b>Websites</b></p>
                        <ResponsiveContainer
                            ResponsiveContainer
                            width={"99%"}
                            height={525}
                        >
                            <PieChart>
                                <Pie
                                    dataKey="value"
                                    data={this.state.websites}
                                    outerRadius={250}
                                    fill="#82ca9d"
                                >
                                    {this.state.websites.map(
                                        (entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    )}
                                </Pie>
                                <Tooltip content={<this.CustomTooltip />} />
                            </PieChart>
                        </ResponsiveContainer>
                    </Col>
                    <Col sm={6}>
                        <p align="center"><b>URLs</b></p>
                        <ResponsiveContainer
                            ResponsiveContainer
                            width={"99%"}
                            height={525}
                        >
                            <PieChart>
                                <Pie
                                    dataKey="value"
                                    data={this.state.urls}
                                    outerRadius={250}
                                    fill="#82ca9d"
                                >
                                    {this.state.urls.map(
                                        (entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    )}
                                </Pie>
                                <Tooltip content={<this.CustomTooltip />} />
                            </PieChart>
                        </ResponsiveContainer>
                    </Col>
                </Row>
                <Row>
                    <Col sm={2}/>
                    <Col sm={4}>
                        {this.state.websites.map((entry, index) => (
                            <div className="listEntry" key={`websites-${entry.name}`}>
                                <span
                                    className="square"
                                    style={{ backgroundColor: COLORS[index % COLORS.length] }}
                                ></span>
                                <span><b>{entry.name}</b>: {Utils.FormatNumber(entry.value, false)} ({entry.percent}%)</span>
                            </div>
                        ))}
                        <div className="listEntry" key={`urls-total`}>
                            <span
                                className="square"
                                style={{
                                    visibility: "hidden"
                                }}
                            ></span>
                            <span><b>total</b>: {Utils.FormatNumber(this.state.websites.map((entry) => entry.value).reduce((a, b) => a + b), false)}</span>
                        </div>
                    </Col>
                    <Col sm={2}/>
                    <Col sm={4}>
                        {this.state.urls.map((entry, index) => (
                            <div className="listEntry" key={`urls-${entry.name}`}>
                                <span
                                    className="square"
                                    style={{
                                        backgroundColor: COLORS[index % COLORS.length]
                                    }}
                                ></span>
                                <span><b>{entry.name}</b>: {Utils.FormatNumber(entry.value, false)} ({entry.percent}%)</span>
                            </div>
                        ))}
                        <div className="listEntry" key={`urls-total`}>
                            <span
                                className="square"
                                style={{
                                    visibility: "hidden"
                                }}
                            ></span>
                            <span><b>total</b>: {Utils.FormatNumber(this.state.urls.map((entry) => entry.value).reduce((a, b) => a + b), false)}</span>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>,
            overtime = this.state.loadingOvertime
                ? <p align="center"><MySpinner /></p>
                : <React.Fragment>
                    <Row>
                        <Col sm={12} style={{
                            marginTop: "50px"
                        }}>
                            <p align="center"><b>URLs delivery over time since January 2023</b></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <ResponsiveContainer
                                ResponsiveContainer
                                width={"99%"}
                                height={450}
                            >
                                <LineChart data={this.state.overtime} margin={{ top: 25, right: 0, left: 10, bottom: 50 }} >
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <CartesianGrid strokeDasharray="10 10" />
                                    {FIELDS.map((field, index) => <Line type="monotone" dataKey={field} stroke={COLORS[index]} key={`line-${index}`} dot={false}/>)}
                                    <Tooltip shared={false} content={<this.CustomTooltipOvertime />} />
                                </LineChart>
                            </ResponsiveContainer>
                        </Col>
                    </Row>
                </React.Fragment>
        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Others</Breadcrumb.Item>
                    <Breadcrumb.Item active>Product Discovery</Breadcrumb.Item>
                </Breadcrumb>
                {stats}
                {overtime}
            </Container >
        );
    }
}

export default PD;
