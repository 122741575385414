import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Table } from "react-bootstrap";
import TableSpinnerOverlay from "./TableSpinnerOverlay";
import TableHeaderCell from "./TableHeaderCell";
import DelayedInput from "./DelayedInput";
import PaginationInfo from "./PaginationInfo";
import Pagination from "./Pagination";
import DropDown from "./DropDown";
import Utils from "../services/Utils";
import Toast from "../services/Toast";
import { ControlledMenu, MenuItem } from "@szhsin/react-menu";
import SettingsService from "../services/SettingsService";
import CSV from "../services/CSV";
import Emitter from "../services/Emitter";
import MySpinner from "./MySpinner";

/**
 * Table component
 * @component
 */
class MyTable extends React.Component {

    constructor(props) {
        super(props);
        this.myId = Utils.getRandomInt(0, 999999);

        this.state = {
            rows: [],
            page: 1,
            pageSize: !props.showPagination ? 99999999 : 25,
            numberOfItems: 0,
            numberOfPages: 0,
            search: typeof props.initialFilter === "object" && props.initialFilter !== null ? props.initialFilter : {},
            sort: {
                field: props.sortBy,
                order: props.sortOrder,
            },
            loading: true,
            menuPosition: {
                x: 0,
                y: 0
            },
            showMenu: 'closed',
            ...this._loadVisibleColumns()
        }

        this._onSearch = this._onSearch.bind(this);
        this._onSortChange = this._onSortChange.bind(this);
        this._redraw = this._redraw.bind(this);
        this._loadVisibleColumns = this._loadVisibleColumns.bind(this);
        this._onPageChange = this._onPageChange.bind(this);
        this._onPageSizeChange = this._onPageSizeChange.bind(this);
        this._switchVisible = this._switchVisible.bind(this);
        this._onRowClick = this._onRowClick.bind(this);
        this._getVisibleHeaders = this._getVisibleHeaders.bind(this);
        this._getTextFunction = this._getTextFunction.bind(this);
        this._getFormatFunction = this._getFormatFunction.bind(this);
        this._getStyle = this._getStyle.bind(this);
        this._getClassName = this._getClassName.bind(this);
        this._renderHeaders = this._renderHeaders.bind(this);
        this._renderMenuOptions = this._renderMenuOptions.bind(this);
        this._renderPagination = this._renderPagination.bind(this);
        this._renderTopRow = this._renderTopRow.bind(this);
        this._renderRows = this._renderRows.bind(this);
        this.resetSettings = this.resetSettings.bind(this);
        this.populate = this.populate.bind(this);
        this.reload = this.reload.bind(this);
        this.init = this.init.bind(this);
    }

    /**
     * Initiates Dynamic (only!) tables
     */
    init() {
        if (!this.props.dynamic) {
            alert("init should only be used by dynamic tables");
            return;
        }
        return this.reload();
    }

    /**
     * Retrieves the table's data
     * @param {boolean} applyFilter Should we use the same filters set on state.search for retrieving the data?
     * @returns
     */
    data(applyFilter = true) {
        if (applyFilter) {
            if (this.props.dynamic) {
                return this.state.rows;
            }
            return this._rows();
        }
        return this.props.dynamic ? this.state.rows : (this.original !== null && typeof this.original === "object" && this.original.length ? this.original.filter((row) => row !== null) : []);
    }

    snapshot(load = null) {
        if (load === null) {
            return {
                page: this.state.page,
                pageSize: this.state.pageSize,
                numberOfItems: this.state.numberOfItems,
                search: this.state.search,
                visibleColumns: this.state.visibleColumns,
                data: this.data(false)
            }
        } else {
            this.setState({
                page: load.page,
                pageSize: load.pageSize,
                search: load.search,
                visibleColumns: load.visibleColumns
            }, () => {
                this.populate([])
                this.props.headers.forEach((header) => {
                    let newValue = typeof load.search[header.field] === "string" ? load.search[header.field] : '';
                    Emitter.emit(`delayed-input-${header.field}`, newValue);
                })
                this.populate(load.data)
            });
        }
    }

    /**
     * Downloads the data() into a CSV file
     */
    CSV(settings) {
        const filename = settings !== null && typeof settings === "object" && typeof settings.filename === "string" ? settings.filename : this.props.name + '-' + Utils.DateNow().replaceAll(' ', '-') + ".csv";

        let labels = {},
            visibleColumns = this.getVisibleFields();
        this.props.headers.forEach((header) => {
            if (typeof header.field === "undefined") {
                return;
            }
            if (visibleColumns.indexOf(header.field) !== -1) {
                if (typeof header.csv === "string") {
                    labels[header.field] = header.csv;
                } else if (typeof header.label === "string") {
                    labels[header.field] = header.label;
                }
            }
        });
        visibleColumns = null;

        let csv = new CSV(),
            data = this.data(),
            fields = Object.keys(labels);

        fields.forEach((field) => {
            csv.addColumn(labels[field]);
        });

        data.forEach((row) => {
            let columns = [];
            fields.forEach((field) => {
                if (typeof this.props.renderColumns[field] === "object" && typeof this.props.renderColumns[field].csv === "function") {
                    columns.push(this.props.renderColumns[field].csv(row, field));
                } else {
                    columns.push(row[field]);
                }
            });
            csv.addRow(columns);
        });
        csv.Download(filename);
    }


    /**
     * Gets a row related to a given event
     */
    getRowFromEvent(evt) {
        const target = typeof evt.syntheticEvent === "undefined" ? (typeof evt.target === "undefined" ? evt : evt.target) : evt.syntheticEvent.target,
            closest = target.closest("tr");
        if (closest === null) {
            return {
                index: -1,
                row: null,
                target
            };
        }
        let index = parseInt(closest.getAttribute("row-index"));
        if (this.props.dynamic) {
            return {
                index,
                row: this.state.rows[index],
                target
            };
        }
        let id = closest.getAttribute("row-id"),
            row = {
                _i: this.map[id]
            };

        if (id === null) {
            return {
                index: -1,
                row: null,
                target
            };
        }

        Object.assign(row, this.original[this.map[id]]);
        return {
            index,
            row,
            target
        }
    }

    /**
     * Retrieves a row by ID
     */
    getRowByID(id) {
        if (this.props.dynamic) {

            for (let i = 0; i < this.state.rows.length; i++) {
                if (typeof this.state.rows[i].id === "number" && this.state.rows[i].id === id) {
                    return {
                        i,
                        row: this.state.rows[i]
                    };
                }
            }

            return {
                index: -1,
                row: null
            };
        }
        if (typeof this.map[id] === "number") {
            let row = {
                _i: this.map[id]
            };
            Object.assign(row, this.original[this.map[id]]);

            return {
                index: this.map[id],
                row
            }
        }

        return {
            index: -1,
            row: null
        };
    }

    /**
     * Enables/disables the Loading icon and prevents the table to be "touched"
     */
    setLoading(loading = true) {
        this.setState({ loading })
    }

    /**
     * Returns the list of visible columns
     */
    getVisibleFields() {
        return Object.keys(this.state.visibleColumns).filter((key) => this.state.visibleColumns[key]);
    }

    resetSettings() {
        this.setState({
            page: 1,
            pageSize: !this.props.showPagination ? 99999999 : 25,
            sort: {
                field: this.props.sortBy,
                order: this.props.sortOrder,
            },
            ...this._loadVisibleColumns()
        });
    }

    /**
     * Initiates a non-dynamic table
     */
    populate(data) {

        this.original = [];
        this.map = {};

        data.forEach((row, index) => {
            if (typeof row.id === "undefined") {
                row.id = index;
            }
            this.original.push(row);
            this.map[row.id] = index;
        })

        this.setState({
            loading: false
        }, this._redraw)
    }

    /**
     * Adds a row into the table
     * Exclusive for non-dynamic tables
     */
    addRow(row) {
        return new Promise((resolve, reject) => {
            if (this.props.dynamic) {
                reject();
                alert("addRow should not be used by dynamic tables");
                return false;
            }

            if (typeof row.id === "undefined") {
                row.id = this.original.length;
            }
            this.map[row.id] = this.original.length;
            this.original.push(row);
            this._redraw().then(resolve);
        });
    }

    /**
     * Adds multiple rows into the table
     * Exclusive for non-dynamic tables
     */
    addRows(rows) {
        return new Promise((resolve, reject) => {
            if (this.props.dynamic) {
                reject();
                alert("addRows should not be used by dynamic tables");
                return false;
            }

            rows.forEach((row) => {
                if (typeof row.id === "undefined") {
                    row.id = this.original.length;
                }
                this.map[row.id] = this.original.length;
                this.original.push(row);
            });
            this._redraw().then(resolve);
        });
    }

    /**
     * Deletes a row by index (or by a row collected with getRowFromEvent/getRowByID)
     * Exclusive for non-dynamic tables
     */
    deleteRow(index) {
        return new Promise((resolve, reject) => {
            if (this.props.dynamic) {
                reject();
                alert("deleteRow should not be used by dynamic tables");
                return false;
            }

            const i = typeof index === "number" ? index : (typeof index._i === "number" ? index._i : (typeof index.row === "object" && typeof index.row._i === "number" ? index.row._i : -1)),
                row = this.original[i];

            if (i === -1) { // something off just happened
                reject();
                return;
            }

            if (typeof row.id === "number") {
                delete this.map[row.id];
            }
            this.original[i] = null;
            this._redraw().then(resolve);
        });
    }

    /**
     * Deletes multiple rows by index (or collected with getRowFromEvent/getRowByID)
     * Exclusive for non-dynamic tables
     */
    deleteRows(list) {
        return new Promise((resolve, reject) => {
            if (this.props.dynamic) {
                reject();
                alert("deleteRows should not be used by dynamic tables");
                return false;
            }

            list.forEach((index) => {
                const i = typeof index === "number" ? index : (typeof index._i === "number" ? index._i : (typeof index.row === "object" && typeof index.row._i === "number" ? index.row._i : -1)),
                    row = this.original[i];

                if (i === -1) { // something off just happened
                    return;
                }

                if (typeof row.id === "number") {
                    delete this.map[row.id];
                }
                this.original[i] = null;
            })
            this._redraw().then(resolve);
        });
    }

    /**
     * Updates a row collected with getRowFromEvent/getRowByID
     * Exclusive for non-dynamic tables
     */
    updateRow(row) {
        return new Promise((resolve, reject) => {
            if (this.props.dynamic) {
                reject();
                alert('updateRow should not be used by dynamic tables');
                return false;
            }
            if (typeof row._i !== "number") {
                reject();
                return false;
            }
            this._updateRow(row).then(resolve);
        });
    }

    /**
     * Updates multiple rows collected with getRowFromEvent/getRowByID
     * Exclusive for non-dynamic tables
     */
    updateRows(rows) {
        return new Promise((resolve, reject) => {
            if (this.props.dynamic) {
                reject();
                alert('updateRows should not be used by dynamic tables');
                return false;
            }
            for (let i = 0; i < rows.length; i++) {
                let row = rows[i];
                if (typeof row._i !== "number") {
                    reject();
                    return false;
                }

                const oldId = this.original[row._i].id,
                    indexOnOriginal = this.map[oldId];
                if (row.id !== oldId) {
                    delete this.map[oldId];
                    this.map[row.id] = indexOnOriginal;
                }

                this.original[indexOnOriginal] = row;
            }
            this._redraw().then(resolve);
        });
    }

    /**
     * Updates a row by index
     * Intended to be used by Dynamic tables
     */
    setRow(index, row) {
        return new Promise((resolve, reject) => {
            if (index >= this.state.pageSize) {
                reject();
                return false;
            }

            if (!this.props.dynamic) {
                const oldId = typeof row._i === "number" ? this.original[row._i].id : this.state.rows[index].id,
                    indexOnOriginal = this.map[oldId];
                if (row.id !== oldId) {
                    delete this.map[oldId];
                    this.map[row.id] = indexOnOriginal;
                }
                this.original[indexOnOriginal] = row;
                this._redraw().then(resolve);
            } else {
                let rows = this.state.rows;
                rows[index] = row;
                this.setState({ rows }, resolve);
            }
        });
    }

    /**
     * Retrieves data from the table for debugging/testing purposes
     */
    debug() {
        if (this.props.dynamic) {
            return this.state;
        }
        let data = this.state;
        data.original = this.original;
        data.map = this.map;
        return data;
    }

    /**
     * Requests a Dynamic table to load up its contents
     */
    reload() {
        this._closeCollapsed();
        return new Promise((resolve) => {
            this.setState({
                loading: true
            });

            this.props.reload({
                pageSize: this.state.pageSize,
                page: this.state.page,
                search: this.state.search,
                sort: this.state.sort
            }).then((data) => {

                let newState = this.state;

                if (typeof data.total === "number") {
                    newState.numberOfItems = data.total;
                    newState.numberOfPages = Math.ceil(
                        data.total / newState.pageSize
                    );
                } else if (newState.numberOfItems === 0 && data.rows.length) {
                    newState.page = 1;
                    newState.numberOfItems = data.rows.length;
                    newState.numberOfPages = Math.ceil(
                        data.rows.length / newState.pageSize
                    );
                }
                newState.loading = false;
                newState.rows = data.rows;

                this.setState(newState);
                resolve(true);

            }).catch(() => {
                this.setState({
                    loading: false
                });
                resolve(false);
            });
        })
    }

    search(field, value) {
        this._onSearch(field, value);
    }


    numberOfItems() {
        return this.state.numberOfItems;
    }

    /**
     * Updates a row collected with getRowFromEvent/getRowByID
     * Exclusive for non-dynamic tables
     */
    _loadVisibleColumns() {
        const { name, headers } = this.props;
        let visibleColumns = {},
            numberOfVisibleColumns = 0;

        const initialSettings = SettingsService.Get(name, {});
        headers.forEach((header) => {
            if (header === null || typeof header !== 'object') {
                return;
            }
            if (typeof header.field === "string") {
                visibleColumns[header.field] = initialSettings[header.field] ?? true;
                if (typeof header.label === "string" && visibleColumns[header.field]) {
                    numberOfVisibleColumns++;
                }
            }
        });

        return {
            visibleColumns,
            numberOfVisibleColumns
        }
    }

    _updateRow(row) {
        return new Promise((resolve) => {
            const oldId = this.original[row._i].id,
                indexOnOriginal = this.map[oldId];
            if (row.id !== oldId) {
                delete this.map[oldId];
                this.map[row.id] = indexOnOriginal;
            }
            this.original[indexOnOriginal] = row;
            this._redraw().then(resolve);
        });
    }

    _getTextFunction(field) {
        return typeof this.props.renderColumns[field] === "object" && typeof this.props.renderColumns[field].text === "function"
            ? this.props.renderColumns[field].text
            : (row, field) => typeof row === 'undefined' || row === null || typeof row[field] === 'undefined' || row[field] === null ? '' : row[field];
    }

    _getFormatFunction(field) {
        if (typeof this.props.renderColumns === "object" && typeof this.props.renderColumns[field] !== "undefined" && typeof this.props.renderColumns[field].format === "function") {
            return this.props.renderColumns[field].format;
        }
        if (typeof this.props.renderColumns[field] === "function") {
            return this.props.renderColumns[field];
        }
        return (row, field) => typeof row === 'undefined' || row === null || typeof row[field] === 'undefined' || row[field] === null ? '' : row[field];
    }

    _getStyle(field) {
        return typeof this.props.renderColumns === "object" && typeof this.props.renderColumns[field] === "object" && typeof this.props.renderColumns[field].style === "object"
            ? this.props.renderColumns[field].style
            : null;
    }

    _getClassName(field) {
        return typeof this.props.renderColumns === "object" && typeof this.props.renderColumns[field] === "object" && typeof this.props.renderColumns[field].className === "string"
            ? this.props.renderColumns[field].className
            : null;
    }


    _rows() {
        const getValue = (source, key) => {
            const textFunction = this._getTextFunction(key);
            let r;
            try {
                r = textFunction(source, key);
            } catch (e) {
                r = null;
            }
            if (typeof r === 'string') {
                return r;
            } else if (r === null) {
                return '';
            } else if (typeof r === 'object') {
                return JSON.stringify(r);
            }
            return '' + r;
        },
            getSortValue = (_v1, _v2, numerical) => {
                const v1 = numerical ? parseFloat(_v1) : _v1,
                    v2 = numerical ? parseFloat(_v2) : _v2;

                let ascending = this.state.sort.order === "asc";
                if (!numerical) {
                    return ascending ? v1.localeCompare(v2) : v2.localeCompare(v1);
                }
                let order = ascending ? v1 < v2 : v1 > v2;
                return order ? -1 : 1;
            };
        let newRows = this.original?.filter((r) => r !== null) ?? [];
        Object.keys(this.state.search)
            .filter((key) => this.state.visibleColumns[key] && this.state.search[key].length)
            .forEach((key) => {
                // stuff like > 100 or < 50
                let parts = this.state.search[key].match(/^([><])\s*(\d+)$/);
                if (parts !== null) {
                    let operator = parts[1],
                        value = parseFloat(parts[2]);
                    newRows = newRows.filter((row) => {
                        let rowValue = getValue(row, key);
                        if (isNaN(rowValue)) {
                            return false;
                        }
                        if (operator === ">") {
                            return rowValue > value;
                        }
                        return rowValue < value;
                    });
                    return;
                }
                // stuff like 100 - 200 (in between two values)
                parts = this.state.search[key].match(/^(\d+)\s*-\s*(\d+)$/);
                if (parts !== null) {
                    let min = parseFloat(parts[1]),
                        max = parseFloat(parts[2]);
                    newRows = newRows.filter((row) => {
                        let rowValue = getValue(row, key);
                        if (isNaN(rowValue)) {
                            return false;
                        }
                        return rowValue >= min && rowValue < max;
                    });
                    return;
                }
                let regex = new RegExp(Utils.SafeRegex(this.state.search[key]), 'i');
                newRows = newRows.filter((row) => getValue(row, key).match(regex));
            });
        if (typeof this.props.callbackFiltering === 'function') {
            this.props.callbackFiltering(newRows);
        }
        newRows.sort((first, second) => {
            const firstValue = getValue(first, this.state.sort.field),
                secondValue = getValue(second, this.state.sort.field),
                numericValues = !isNaN(firstValue) && !isNaN(secondValue);
            return getSortValue(firstValue, secondValue, numericValues);
        });
        return newRows;
    }

    _redraw() {
        return new Promise((resolve) => {

            let newRows = this._rows();

            let total = newRows.length,
                numberOfPages = Math.ceil(total / this.state.pageSize),
                pageNumber = this.state.page > numberOfPages ? 1 : this.state.page;

            newRows = newRows.splice(
                (pageNumber - 1) * this.state.pageSize,
                this.state.pageSize
            );

            this.setState({
                rows: newRows,
                numberOfItems: total,
                page: pageNumber,
                numberOfPages: numberOfPages,
                loading: false
            }, resolve);
        });
    }

    _onPageChange = (page) => {
        this.setState(
            {
                page,
                loading: true,
            },
            this.props.dynamic ? this.reload : this._redraw
        );
    };

    _onPageSizeChange = (pageSize) => {
        let numberOfPages = Math.ceil(this.state.numberOfItems / pageSize),
            page = this.state.page > numberOfPages ? 1 : this.state.page;
        if (this.state.page)
            this.setState(
                {
                    page,
                    pageSize,
                    loading: true,
                },
                this.props.dynamic ? this.reload : this._redraw
            );
    };

    _onSortChange(field, order) {
        this.setState(
            {
                sort: {
                    field,
                    order,
                },
                loading: true,
            },
            this.props.dynamic ? this.reload : this._redraw
        );
    }

    _onSearch(field, value) {
        let search = this.state.search;
        search[field] = value;
        this.setState(
            {
                search,
                page: 1,
                loading: true,
            },
            this.props.dynamic ? this.reload : this._redraw
        );
    }


    _switchVisible(evt) {
        const target = evt.syntheticEvent.target;
        let field = /span/i.test(target.nodeName) ? target.parentNode.getAttribute("field") : target.getAttribute("field");
        let columns = this.state.visibleColumns,
            numberOfVisibleColumns = this.state.numberOfVisibleColumns,
            checked = !columns[field];
        if (checked) {
            numberOfVisibleColumns++;
        } else {
            numberOfVisibleColumns--;
        }

        if (numberOfVisibleColumns === 0) {
            Toast.error("There should be at least one visible column apart from the control column.");
            return;
        }
        columns[field] = checked;

        this.setState({ visibleColumns: columns, numberOfVisibleColumns }, () => {
            SettingsService.Set(this.props.name, columns);
            if (typeof this.state.search[field] !== "undefined" && this.state.search[field].length > 0) {
                let search = this.state.search;
                search[field] = '';
                this.setState({ search }, () => {
                    if (this.props.dynamic) {
                        this.reload();
                    } else {
                        this._redraw()
                    }
                });
            }
        });
    }

    _onRowClick = (e) => {
        let obj = this.getRowFromEvent(e),
            hiddenElement = e.currentTarget.nextSibling;

        if (typeof obj.row.collapse === "undefined") {
            let f = this.props.collapse(obj.row);
            if (Utils.isPromise(f)) {
                this.props.collapse(obj.row).then((result) => {
                    obj.row.collapse = result;
                    if (this.props.dynamic) {
                        this.setRow(obj.index, obj.row);
                    } else {
                        this._updateRow(obj.row);
                    }
                });
            } else {
                if (f === false) {
                    return;
                }
                obj.row.collapse = f;
                if (this.props.dynamic) {
                    this.setRow(obj.index, obj.row);
                } else {
                    this._updateRow(obj.row);
                }
            }
        }

        hiddenElement.className.indexOf("collapse show") > -1 ? hiddenElement.classList.remove("show") : hiddenElement.classList.add("show");
    };

    _closeCollapsed() {
        let rows = document.getElementById("myTable-" + this.myId).getElementsByClassName("collapse");
        for (let i = 0; i < rows.length; i++) {
            rows.item(i).classList.remove("show");
        }
    }

    _getVisibleHeaders() {
        return this.props.headers
            .filter((header) => {
                return typeof header === 'object' && typeof header.field === 'string' && this.state.visibleColumns[header.field]
            });
    }

    _renderHeaders() {
        const onContextMenu = (evt) => {
            evt.preventDefault();
            this.setState({
                menuPosition: { x: evt.clientX - 5, y: evt.clientY - 5 },
                showMenu: 'open'
            })
        };
        return <thead>
            <tr key="header-row-top" className="title">
                {this.props.headers.map((header) => {
                    if (typeof header.field === "string") {
                        if (!this.state.visibleColumns[header.field]) {
                            return null;
                        }
                    }
                    if (typeof header.sortable === "boolean" && header.sortable && typeof header.field === "string" && typeof header.label === "string") {
                        return <TableHeaderCell
                            key={`sort-by-${header.field}`}
                            data-testid={`field-${header.field}`}
                            field={header.field}
                            label={header.label}
                            onContextMenu={onContextMenu}
                            width={typeof header.width !== "undefined" ? header.width : null}
                            sortable={true}
                            style={typeof header.style !== "undefined" ? header.style : null}
                            hint={typeof header.hint === "string" ? header.hint : null}
                            sort={this.state.sort}
                            onChange={this._onSortChange}
                        />
                    }

                    return <th
                        data-testid={`field-${header.field}`}
                        onContextMenu={onContextMenu}
                        key={`row-${header.field}-top`}
                        width={typeof header.width !== "undefined" ? header.width : null}
                        style={typeof header.style !== "undefined" ? header.style : null}
                    >
                        {typeof header.label === "string" ? header.label : null}
                    </th>

                })}
            </tr>
            <tr key="header-row-bottom">
                {this.props.headers.map((header) => {
                    if (typeof header.field === "string" && !this.state.visibleColumns[header.field]) {
                        return null;
                    }
                    const className = typeof header.className === "string" ? header.className : null;
                    if (typeof header.searchable === "boolean" && header.searchable && typeof header.field === "string") {
                        return (
                            <th key={`search-by-${header.field}`} className={className}>
                                <DelayedInput
                                    data-testid={`search-by-${header.field}`}
                                    onChange={this._onSearch}
                                    field={header.field}
                                    enableEmmit={this.props.enableSnapshoting}
                                />
                            </th>
                        )
                    }
                    if (typeof header.options === "object") {
                        return (
                            <th key={`search-by-${header.field}`} className={className}>
                                <DropDown
                                    allowEmpty={typeof header.allowEmpty === "boolean" && header.allowEmpty}
                                    options={header.options}
                                    selected={typeof this.state.search[header.field] === "number" || typeof this.state.search[header.field] === "string" ? this.state.search[header.field] : null}
                                    onChange={(evt) => { this._onSearch(header.field, evt.target.value) }}
                                />
                            </th>
                        )
                    }
                    return (
                        <th key={`row-${header.field}-bottom`} className={className}>
                            {typeof header.content !== "undefined" ? header.content : null}
                        </th>
                    )
                })}
            </tr>
        </thead>
    }

    _renderMenuOptions() {
        let menuOptions = [];
        this.props.headers
            .filter((header) => typeof header === 'object' && typeof header.field === 'string' && typeof header.label === 'string')
            .forEach((header) => {
                menuOptions.push((
                    <MenuItem key={`menu-${this.props.name}-${header.field}`} onClick={this._switchVisible} field={header.field}><span style={this.state.visibleColumns[header.field] ? null : { color: "silver" }}>{header.label}</span></MenuItem>
                ))
            });

        return <ControlledMenu
            anchorPoint={this.state.menuPosition}
            state={this.state.showMenu}
            onClose={() => this.setState({ showMenu: 'closed' })}
            onMouseLeave={() => this.setState({ showMenu: 'closed' })}
        >
            {menuOptions}
        </ControlledMenu>
    }

    _renderPagination() {
        if (!this.props.showPagination) {
            return null;
        }
        return <Row className="pagination">
            <Col sm={4} align="left">
                <PaginationInfo
                    page={this.state.page}
                    numberOfItems={this.state.numberOfItems}
                    pageSize={this.state.pageSize}
                />
            </Col>
            <Col sm={8} align="right">
                <Pagination
                    key="pagination"
                    page={this.state.page}
                    numberOfPages={this.state.numberOfPages}
                    pageSize={this.state.pageSize}
                    onChangePage={this._onPageChange}
                    onChangePageSize={this._onPageSizeChange}
                ></Pagination>
            </Col>
        </Row>
    }

    _renderTopRow() {
        if (this.props.topRow === null) {
            return null;
        }

        const row = this.props.topRow;
        let columns = this._getVisibleHeaders().map((header) => {
            const field = header.field,
                formatFunction = this._getFormatFunction(field),
                content = formatFunction(row, field),
                className = this._getClassName(field);
            return <td
                key={`row-top-column-${field}`}
                className={className}
                style={{ color: "white" }}
            >{content}</td>
        })
        return <tr key={`row-top`} style={{ backgroundColor: "gray" }}>
            {columns}
        </tr>
    }

    _renderRows() {
        if (!this.state.rows.length) {
            const numberOfColumns = this._getVisibleHeaders().length;
            return <tr>
                <td colSpan={numberOfColumns}>{this.props.emptyLabel}</td>
            </tr>;
        }
        const fields = this._getVisibleHeaders().map((header) => header.field),
            rows = this.state.rows.map((row, index) => {
                const rowClassname = typeof this.props.renderRows.className === "function" ? this.props.renderRows.className(row) : '',
                    columns = fields.map((field) => {
                        const formatFunction = this._getFormatFunction(field),
                            content = formatFunction(row, field),
                            className = this._getClassName(field),
                            style = this._getStyle(field);
                        return <td
                            key={`row-${index}-column-${field}`}
                            className={className}
                            style={style}>
                            {content}
                        </td>
                    }),
                    identifier = row.id ?? index,
                    rowId = row.id ?? null;

                if (typeof this.props.collapse === "function") {
                    const hasContent = typeof row.collapse !== "undefined",
                        content = typeof row.collapse === "string" && row.collapse.length ? row.collapse : <i>Empty</i>;
                    return (
                        <React.Fragment key={`row-${identifier}`}>
                            <tr
                                row-id={rowId}
                                row-index={index}
                                className={rowClassname}
                                onClick={this._onRowClick} >
                                {columns}
                            </tr>
                            <tr className="collapse">
                                <td colSpan={columns.length}>{hasContent ? content : <MySpinner />}</td>
                            </tr>
                        </React.Fragment>
                    )
                }
                return <tr
                    key={`row-${index}`}
                    row-id={rowId}
                    row-index={index}
                    className={rowClassname} >
                    {columns}
                </tr>
            })
        return rows;
    }

    render() {
        const minWidth = this._getVisibleHeaders().map(header => parseInt(header.width) ?? 0).reduce((a, b) => a + b, 0),
            style = minWidth > 1400 ? { minWidth: minWidth } : null;
        return (
            <div className="myTable" >
                <TableSpinnerOverlay loading={this.state.loading} />
                <Table bordered hover size="sm" className="myTable" responsive={true} id={`myTable-${this.myId}`} style={style}>
                    <this._renderHeaders />
                    <tbody>
                        <this._renderTopRow />
                        <this._renderRows />
                    </tbody>
                </Table>
                <this._renderPagination />
                <this._renderMenuOptions />
            </div >
        )
    }
}

MyTable.propTypes = {
    name: PropTypes.string.isRequired,
    dynamic: PropTypes.bool.isRequired,
    sortBy: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired,
    headers: PropTypes.array.isRequired,
    renderColumns: PropTypes.object.isRequired,
    renderRows: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired,
    initialFilter: PropTypes.object,
    emptyLabel: PropTypes.string.isRequired,
    showPagination: PropTypes.bool.isRequired,
    enableSnapshoting: PropTypes.bool.isRequired,
    collapseStyle: PropTypes.object,
    topRow: PropTypes.object,
    callbackFiltering: PropTypes.func,
};

MyTable.defaultProps = {
    dynamic: false,
    showPagination: true,
    sortBy: 'date',
    sortOrder: 'desc',
    headers: [],
    renderColumns: {},
    renderRows: {},
    reload: () => {
        alert('Implement MyTable.reload');
    },
    initialFilter: null,
    emptyLabel: "Empty data set",
    enableSnapshoting: false,
    topRow: null,
    callbackFiltering: null,
    name: ''
};

export default MyTable;
