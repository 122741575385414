import React from "react";
import PropTypes from "prop-types";

import { Container, Table, Row, Col, Spinner, Dropdown } from "react-bootstrap";
import CrawlResultsService from "../../services/CrawlResultsService";
import Pagination from "../../components/Pagination";
import "../../../node_modules/currency-flags/dist/currency-flags.min.css";
import PaginationInfo from "../../components/PaginationInfo";
import TableHeaderCell from "../../components/TableHeaderCell";
import TableSpinnerOverlay from "../../components/TableSpinnerOverlay";
import DelayedInput from "../../components/DelayedInput";
import Toast from "../../services/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTools,
    faLink,
    faUnlink,
    faBug,
    faUnlock,
    faCheck,
} from "@fortawesome/free-solid-svg-icons";
import confirm from "../../components/Confirm";
import prompt from "../../components/Prompt";
import WebsiteService from "../../services/WebsiteService";
import { TICKET_TYPES, confirm as ticket } from "../../components/JIRATicket";
import Emitter from "../../services/Emitter";
import Currencies from "../../components/Currencies";
import { Link } from "react-router-dom";
import DropDown from "../../components/DropDown";
import MySpinner from "../../components/MySpinner";

/**
 * Rejected Items component
 * @component
 * @category Scenes
 * @subcategory Websites
 */
class Rejected extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.websiteId,
            name: null,
            rows: [],
            info: {},
            page: 1,
            pageSize: 25,
            numberOfItems: 0,
            numberOfPages: 0,
            search: {
                identifier: "",
                sku: "",
                brand: "",
            },
            sort: {
                field: "date",
                order: "desc",
            },
            loading: true,
        };
        this.onSortChange = this.onSortChange.bind(this);
        this.reload = this.reload.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.handleEditURLClick = this.handleEditURLClick.bind(this);
        this.handleRemoveURLClick = this.handleRemoveURLClick.bind(this);
        this.handleCreateJIRAClick = this.handleCreateJIRAClick.bind(this);
        this.handleReleaseAllBlockClick =
            this.handleReleaseAllBlockClick.bind(this);
        this.handleReleaseBlockClick = this.handleReleaseBlockClick.bind(this);
        this.handleSwitchVerified = this.handleSwitchVerified.bind(this);
    }

    reload() {
        this.setState({
            loading: true,
        });

        if (this.state.name === null) {
            WebsiteService.simpleRow(this.state.id).then((result) => {
                if (result !== false) {
                    this.setState({ name: result.display_name });
                } else {
                    Toast.error("Something went wrong while retriving this website's details");
                }
            });
        }

        CrawlResultsService.unsuccessful(this.state.id, {
            pageSize: this.state.pageSize,
            page: this.state.page,
            search: this.state.search,
            sort: this.state.sort,
        }).then((data) => {
            let newState = {
                rows: data.rows,
                loading: false,
            },
                ids = [];

            if (typeof data.total === "number") {
                newState.numberOfItems = data.total;
                newState.numberOfPages = Math.ceil(
                    data.total / this.state.pageSize
                );
            }

            if (typeof data.rows === "object") {
                data.rows.forEach((row) => {
                    ids.push(row.id);
                });
            } else {
                newState.rows = [];
            }

            this.setState(newState);
            CrawlResultsService.get(ids).then((data) => {
                let keys = Object.keys(data);
                keys.forEach((key) => {
                    let json,
                        content,
                        node = document.getElementById(key);
                    if (node === null) {
                        return;
                    }
                    try {
                        json = JSON.parse(data[key]);
                        if (
                            typeof json[0] !== "undefined" &&
                            typeof json[1] === "undefined"
                        ) {
                            content = json[0];
                        } else if (json[0] !== "undefined") {
                            content = "";
                            json.forEach((item) => {
                                content += item + "\n";
                            });
                        } else {
                            content = JSON.stringify(json, undefined, 2);
                        }
                    } catch (exception) {
                        content = json;
                    }

                    node.innerHTML = typeof content === 'string' ? content.replace(/"(https?[^"]+)"/g, '<a href="$1" target="_blank" rel="noopener,noreferrer">$1</a>') : '';
                });
            });
        });
    }

    componentWillUnmount() {
        Emitter.off("WEBSITE_REJECTED");
    }

    componentDidMount() {
        Emitter.once("WEBSITE_REJECTED", this.reload);
    }

    onRowClick = (e) => {
        let hiddenElement = e.currentTarget.nextSibling;
        hiddenElement.className.indexOf("collapse show") > -1
            ? hiddenElement.classList.remove("show")
            : hiddenElement.classList.add("show");
    };

    onPageChange = (page) => {
        this.setState(
            {
                page,
                loading: true,
            },
            this.reload
        );
    };

    onPageSizeChange = (pageSize) => {
        let numberOfPages = Math.ceil(this.state.numberOfItems / pageSize),
            page = this.state.page > numberOfPages ? 1 : this.state.page;

        this.setState(
            {
                page,
                pageSize,
                loading: true,
            },
            this.reload
        );
    };

    onSortChange(field, order) {
        this.setState(
            {
                sort: {
                    field,
                    order,
                },
                loading: true,
            },
            this.reload
        );
    }

    onSearch(field, value) {
        let search = this.state.search;
        search[field] = value;
        this.setState(
            {
                search,
                page: 1,
                loading: true,
            },
            this.reload
        );
    }

    handleEditURLClick(evt) {
        let rowIndex = evt.target.closest("tr").getAttribute("data-row"),
            row = this.state.rows[rowIndex];

        prompt("Edit URL", "Update", "Cancel", row.identifier, row.url)
            .then((result) => {
                if (result.value === row.url) {
                    return;
                }

                if (result.value.trim().length === 0) {
                    WebsiteService.removeURL(
                        this.props.websiteId,
                        row.pid
                    ).then((updateResult) => {
                        if (updateResult) {
                            let rows = this.state.rows;
                            rows[rowIndex].url = "";
                            this.setState({ rows });

                            Toast.ok("URL removed successfully");
                        } else {
                            Toast.error(
                                "Something went wrong while updating this URL. Changes could not be properly saved."
                            );
                        }
                    });
                } else {
                    WebsiteService.setURL(
                        this.props.websiteId,
                        row.pid,
                        result.value
                    ).then((updateResult) => {
                        if (updateResult) {
                            let rows = this.state.rows;
                            rows[rowIndex].url = result.value;
                            this.setState({ rows });

                            Toast.ok("URL updated successfully");
                        } else {
                            Toast.error(
                                "Something went wrong while updating this URL. Changes could not be properly saved."
                            );
                        }
                    });
                }
            })
            .catch(() => { });
    }

    async handleRemoveURLClick(evt) {
        let rowIndex = evt.target.closest("tr").getAttribute("data-row"),
            row = this.state.rows[rowIndex];
        if (
            await confirm("Are you sure?", "Yes", "No", {
                message:
                    "You are about the remove the URL for product " +
                    row.identifier +
                    ". This operation can't be undone. Do you want to proceed?",
            })
        ) {
            WebsiteService.removeURL(this.state.id, row.pid).then((result) => {
                if (result) {
                    Toast.ok("URL removed successfully.");
                    // this.reload();
                    let rows = this.state.rows;
                    rows[rowIndex].url = "";
                    this.setState({ rows });
                } else {
                    Toast.error("Something went wrong while removing the URL.");
                }
            });
        }
    }

    async handleCreateJIRAClick(evt) {
        const rowIndex = evt.target.closest("tr").getAttribute("data-row"),
            row = this.state.rows[rowIndex];

        await ticket(TICKET_TYPES.REJECTED_PRODUCT, {
            brands: row.brands,
            website_id: this.state.id,
            website_name: this.state.name,
            datetime: row.date,
            product_id: row.pid,
            product_name: row.title,
            offer_url: row.url,
            notes: document.getElementById(row.id).innerText,
        });
    }

    handleSwitchVerified(evt) {
        let rowIndex = evt.target.closest("tr").getAttribute("data-row"),
            rows = this.state.rows;

        rows[rowIndex].switchingVerified = true;
        this.setState({ rows })
        let checked = rows[rowIndex].action === null || rows[rowIndex].action.length === 0;
        CrawlResultsService.switchCheck(rows[rowIndex].id, checked).then((result) => {
            delete rows[rowIndex].switchingVerified;
            if (result === false) {
                Toast.error("Something went wrong while switching statuses")
            } else {
                rows[rowIndex].action = checked ? 'verifiedByAuditor' : null
            }
            this.setState({ rows })
        });
    }

    handleReleaseBlockClick(evt) {
        let rowIndex = evt.target.closest("tr").getAttribute("data-row"),
            row = this.state.rows[rowIndex];

        WebsiteService.unblock(this.state.id, row.pid).then((result) => {
            if (result) {
                Toast.ok("Released product successfully");
                let rows = this.state.rows;
                rows[rowIndex].blockage = 0;
                this.setState({ rows });
            } else {
                Toast.error(
                    "Something went wrong while releasing blocked row."
                );
            }
        });
    }

    async handleReleaseAllBlockClick(evt) {
        if (
            !(await await confirm("Are you sure?", "Yes", "No", {
                message:
                    "You are about release all crawlings that got automatically blocked due their persistent failures. If nothing got changed in the crawling side, this will probably not make any difference in the results. Do you want to proceed?",
            }))
        ) {
            return;
        }

        WebsiteService.unblock(this.state.id).then((result) => {
            if (result) {
                Toast.ok("Released blocked entries successfully");
                let rows = this.state.rows;
                for (let i = 0; i < rows.length; i++) {
                    rows[i].blockage = 0;
                }
                this.setState({ rows });
            } else {
                Toast.error(
                    "Something went wrong while releasing blocked rows."
                );
            }
        });
    }

    render() {
        const rows = this.state.rows.map((row, index) => {
            const currency = Currencies.CodeByID(row.currency_id).toLowerCase();
            return (
                <React.Fragment key={`frag-${row.identifier}-${row.currency_id}`}>
                    <tr
                        key={`${row.identifier}-${row.currency_id}`}
                        className={row.blockage ? `blocked` : ``}
                        onClick={this.onRowClick}
                        data-row={index}
                    >
                        <td>{row.date}</td>
                        <td>{row.identifier}</td>
                        <td>{row.upc}</td>
                        <td>{row.brands.map((brand) => <div><Link className="link" to={`/brand/${brand.id}`}>(#{brand.id}) {brand.name}</Link></div>)}</td>
                        <td>
                            <div className={`currency-flag currency-flag-${currency}`}></div>
                        </td>
                        <td>{row.sku}</td>
                        <td className="cut-overflow">
                            <a
                                href={row.url}
                                rel="nofollow noopener noreferrer"
                                target="_blank"
                            >
                                {row.url}
                            </a>
                        </td>
                        <td>{typeof row.switchingVerified === "boolean" ? <MySpinner /> : (row.action === null || row.action.length === 0 ? null : <FontAwesomeIcon
                            style={{
                                color: "#1f4503",
                            }}
                            icon={faCheck}
                        />)}</td>
                        <td>
                            <Dropdown
                                key={`control-${row.id}`}
                                drop="start"
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                    evt.preventDefault();
                                }}
                            >
                                <Dropdown.Toggle
                                    id="dropdown-basic"
                                    variant="outline-secondary"
                                    size="sm"
                                    style={{
                                        minWidth: "50px",
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTools} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {row.blockage === 1 && (
                                        <Dropdown.Item
                                            onClick={this.handleReleaseBlockClick}
                                        >
                                            <FontAwesomeIcon
                                                icon={faUnlock}
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                            />
                                            Release product for crawling
                                        </Dropdown.Item>
                                    )}
                                    <Dropdown.Item
                                        onClick={this.handleEditURLClick}
                                    >
                                        <FontAwesomeIcon
                                            icon={faLink}
                                            style={{
                                                marginRight: "10px",
                                            }}
                                        />
                                        Edit URL
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={this.handleRemoveURLClick}
                                    >
                                        <FontAwesomeIcon
                                            icon={faUnlink}
                                            style={{
                                                marginRight: "10px",
                                            }}
                                        />
                                        Remove URL
                                    </Dropdown.Item>
                                    {this.state.name !== null && (
                                        <Dropdown.Item
                                            onClick={this.handleCreateJIRAClick}
                                        >
                                            <FontAwesomeIcon
                                                icon={faBug}
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                            />
                                            Create JIRA
                                        </Dropdown.Item>
                                    )}
                                    <Dropdown.Item
                                        onClick={this.handleSwitchVerified}
                                    >
                                        <FontAwesomeIcon
                                            icon={faUnlink}
                                            style={{
                                                marginRight: "10px",
                                            }}
                                        />
                                        {row.action === null || row.action.length === 0 ? 'Mark as verified' : 'Mark as unverified'}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                    </tr>
                    <tr className="collapse show">
                        <td
                            colSpan="9"
                            id={`${this.props.websiteId}_${row.pid}_${row.currency_id}_0`}
                            style={{
                                fontFamily: "Courier New, monospace",
                                whiteSpace: "pre",
                                fontSize: "x-small",
                                padding: "10px",
                            }}
                        >
                            <Spinner animation="border" role="status"></Spinner>
                        </td>
                    </tr>
                </React.Fragment>
            )
        });
        return (
            <Container className="pt-4">
                <TableSpinnerOverlay loading={this.state.loading} />
                <Table bordered hover size="sm" className="myTable">
                    <thead>
                        <tr className="title">
                            <TableHeaderCell
                                field="date"
                                label="Date"
                                sortable={true}
                                width="140px"
                                sort={this.state.sort}
                                onChange={this.onSortChange}
                            />
                            <TableHeaderCell
                                field="identifier"
                                label="Identifier"
                                sortable={true}
                                sort={this.state.sort}
                                onChange={this.onSortChange}
                            />
                            <TableHeaderCell
                                field="upc"
                                label="UPC"
                                sortable={true}
                                sort={this.state.sort}
                                onChange={this.onSortChange}
                            />
                            <TableHeaderCell
                                field="brands"
                                label="Brands"
                                sortable={true}
                                sort={this.state.sort}
                                onChange={this.onSortChange}
                            />
                            <th>$</th>
                            <TableHeaderCell
                                field="sku"
                                label="SKU"
                                sortable={true}
                                sort={this.state.sort}
                                onChange={this.onSortChange}
                            />
                            <th
                                style={{
                                    minWidth: "350px",
                                }}
                            >
                                URL
                            </th>
                            <TableHeaderCell
                                field="action"
                                label="Verified"
                                width="80px"
                                sortable={true}
                                sort={this.state.sort}
                                onChange={this.onSortChange}
                            />
                            <th
                                style={{
                                    width: "10px",
                                }}
                            ></th>
                        </tr>
                        <tr>
                            <th></th>
                            <th>
                                <DelayedInput
                                    onChange={this.onSearch}
                                    field="identifier"
                                />
                            </th>
                            <th>
                                <DelayedInput
                                    onChange={this.onSearch}
                                    field="upc"
                                />
                            </th>
                            <th>
                                <DelayedInput
                                    onChange={this.onSearch}
                                    field="brand"
                                />
                            </th>
                            <th></th>
                            <th>
                                <DelayedInput
                                    onChange={this.onSearch}
                                    field="sku"
                                />
                            </th>
                            <th></th>
                            <th>
                                <DropDown
                                    allowEmpty={true}
                                    options={['Verified', 'Unverified']}
                                    selected={this.state.search.action}
                                    onChange={(evt) => { this.onSearch('action', evt.target.value) }}
                                />
                            </th>
                            <th>
                                <Dropdown key="overall-control" drop="start">
                                    <Dropdown.Toggle
                                        id="dropdown-basic"
                                        variant="secondary"
                                        size="sm"
                                        style={{
                                            minWidth: "50px",
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTools} />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={
                                                this.handleReleaseAllBlockClick
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faLink}
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                            />
                                            Release all blocked rows
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.rows.length === 0 && (
                            <tr>
                                <td colSpan="7">Empty data set</td>
                            </tr>
                        )}
                        {rows}
                    </tbody>
                </Table>
                <Row className="pagination">
                    <Col sm={4} align="left">
                        <PaginationInfo
                            page={this.state.page}
                            numberOfItems={this.state.numberOfItems}
                            pageSize={this.state.pageSize}
                        />
                    </Col>
                    <Col sm={8} align="right">
                        <Pagination
                            key="pagination"
                            page={this.state.page}
                            numberOfPages={this.state.numberOfPages}
                            pageSize={this.state.pageSize}
                            onChangePage={this.onPageChange}
                            onChangePageSize={this.onPageSizeChange}
                        ></Pagination>
                    </Col>
                </Row>
            </Container>
        );
    }
}

Rejected.propTypes = {
    /** Website ID */
    websiteId: PropTypes.number.isRequired,
};

export default Rejected;
