import React from "react";
import PropTypes from "prop-types";

import MultiSelect from "react-multi-select-component";
import "./Categories.css";

/**
 * Shows a categories MultiSelect field
 * @component
 */
class Categories extends React.Component {
    /**
     * All supported categories
     */
    static OPTIONS = [
        { label: "Adult Toys", value: 43 },
        { label: "Appliances", value: 1 },
        { label: "Apps & Games", value: 2 },
        { label: "Arts, Crafts & Sewing", value: 3 },
        { label: "Automotive", value: 4 },
        { label: "Baby", value: 15 },
        { label: "Beauty", value: 6 },
        { label: "Books", value: 7 },
        { label: "CDs & Vinyl", value: 8 },
        { label: "Cell Phones & Accessories", value: 9 },
        { label: "Clothing, Shoes & Accessories", value: 10 },
        { label: "Collectibles & Fine Art", value: 16 },
        { label: "Computers", value: 17 },
        { label: "Credit and Payment Cards", value: 18 },
        { label: "Digital Music", value: 19 },
        { label: "Electronics", value: 20 },
        { label: "Firearms", value: 42 },
        { label: "Gift Cards", value: 21 },
        { label: "Grocery & Gourmet Food", value: 22 },
        { label: "Health & Personal Care", value: 23 },
        { label: "Home & Kitchen", value: 24 },
        { label: "Industrial & Scientific", value: 25 },
        { label: "Kindle Store", value: 26 },
        { label: "Luggage & Travel Gear", value: 27 },
        { label: "Magazine Subscriptions", value: 28 },
        { label: "Movies & TV", value: 29 },
        { label: "Musical Instruments", value: 30 },
        { label: "Office Products", value: 31 },
        { label: "Patio, Lawn & Garden", value: 32 },
        { label: "Pet Supplies", value: 33 },
        { label: "Prime Pantry", value: 34 },
        { label: "Software", value: 35 },
        { label: "Sports & Outdoors", value: 36 },
        { label: "Tools & Home Improvement", value: 37 },
        { label: "Toys & Games", value: 38 },
        { label: "Video Games", value: 39 },
        { label: "Wine", value: 40 },
    ];

    /**
     * Map to provide fast translation of id -> label
     */
    static #map = {
        43: "Adult Toys",
        1: "Appliances",
        2: "Apps & Games",
        3: "Arts, Crafts & Sewing",
        4: "Automotive",
        15: "Baby",
        6: "Beauty",
        7: "Books",
        8: "CDs & Vinyl",
        9: "Cell Phones & Accessories",
        10: "Clothing, Shoes & Accessories",
        16: "Collectibles & Fine Art",
        17: "Computers",
        18: "Credit and Payment Cards",
        19: "Digital Music",
        20: "Electronics",
        42: "Firearms",
        21: "Gift Cards",
        22: "Grocery & Gourmet Food",
        23: "Health & Personal Care",
        24: "Home & Kitchen",
        25: "Industrial & Scientific",
        26: "Kindle Store",
        27: "Luggage & Travel Gear",
        28: "Magazine Subscriptions",
        29: "Movies & TV",
        30: "Musical Instruments",
        31: "Office Products",
        32: "Patio, Lawn & Garden",
        33: "Pet Supplies",
        34: "Prime Pantry",
        35: "Software",
        36: "Sports & Outdoors",
        37: "Tools & Home Improvement",
        38: "Toys & Games",
        39: "Video Games",
        40: "Wine",
    };
    /**
     * Defines the set of categories that should be marked as selected
     * @param {array} list
     * @example [1, 3, 5]
     * @returns {array} A list of {id, label} entries
     */
    static Selected(list) {
        let selected = [];
        if (typeof list === "object" && list.length) {
            for (let i = 0; i < list.length; i++) {
                selected.push({
                    label: Categories.#map[list[i]],
                    value: list[i],
                });
            }
        }
        return selected;
    }
    /**
     * Returns only the numeric "value" of the items in the list
     * @param {array} list
     * @example [{id: 1, label: "A"}, {id: 2, label: "B"}]
     * @returns {array} Array of integers representing the "value" field of each entry in the list
     */
    static Values(list) {
        let selected = [];
        for (let i = 0; i < list.length; i++) {
            selected.push(list[i].value);
        }
        return selected;
    }

    render() {
        let className = this.props.disabled ? "disabled" : "";
        let options;
        if (this.props.allowed.length === 0) {
            options = Categories.OPTIONS;
        } else {
            options = [];
            Categories.OPTIONS.forEach((opt) => {
                if (this.props.allowed.indexOf(opt.value) !== -1) {
                    options.push(opt);
                }
            });
        }
        return (
            <MultiSelect
                className={`cat ${className}`}
                options={options}
                value={this.props.value}
                onChange={this.props.onChange}
                disabled={this.props.disabled}
                labelledBy="Select"
            />
        );
    }
}

Categories.propTypes = {
    /** Categories under selection */
    value: PropTypes.array.isRequired,
    /** onChange callback function */
    onChange: PropTypes.func.isRequired,
    /** Should this component be disabled? */
    disabled: PropTypes.bool,
    /** allow categories ids only */
    allowed: PropTypes.array
};

Categories.defaultProps = {
    allowed: [],
    value: [],
    onChange: (newSelection) => {
        console.log(newSelection);
    },
    disabled: false,
};

export default Categories;
