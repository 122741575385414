import React from "react";
import PropTypes from "prop-types";

import { Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import Toast from "../services/Toast";
import WebsiteService from "../services/WebsiteService";
import MultiSelect from "react-multi-select-component";
import WebsiteSearch from "./WebsiteSearch";
import ProcessingButton from "./ProcessingButton";

/**
 * Shows a Import URLs component
 * @component
 */
class ImportURLs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            importing: false,
            isBrandLoading: false,
            website: null,
            brandsAvailable: [],
            brandsSelected: [],
        }
        this.onEntering = this.onEntering.bind(this);
        this.selectBrand = this.selectBrand.bind(this);
    }

    selectBrand(list) {
        this.setState({
            brandsSelected: list,
        });
    }

    handleWebsiteChange = (row) => {
        let website = row.length > 0 ? row[0] : null;
        this.setState({
            website,
            isBrandLoading: website !== null,
            brandsSelected: [],
            brandsAvailable: []
        });
        if (website !== null) {
            WebsiteService.brandsMapped(website.id).then((result) => {
                if (result === false) {
                    Toast.error("Something went wrong while retrieving brands");
                    return;
                }

                this.setState({
                    brandsAvailable: result,
                    isBrandLoading: false
                })
            });
        }
    };

    onEntering() {
        this.setState({
            website: null,
            brandsAvailable: [],
            brandsSelected: [],
            importing: false,
            isBrandLoading: false
        });
    }

    render() {
        const cancel = () => {
            if (this.state.importing) {
                return;
            }
            this.props.onClose();
        }

        const save = () => {
            if (this.state.importing) {
                return;
            }
            this.setState({ importing: true }, () => {
                let ids = [];
                this.state.brandsSelected.forEach((item) => {
                    ids.push(item.value);
                });
                WebsiteService.urls(this.state.website.id, ids).then((result) => {
                    if (result === false) {
                        Toast.error("Something went wrong while retrieving URLs");
                        this.setState({ importing: false });
                        return;
                    }
                    this.props.onClose(result);
                });
            });
        }

        return (
            <Modal
                onEntering={this.onEntering}
                size="lg"
                show={this.props.show}
                onHide={cancel}
                backdrop={!this.state.importing}
                keyboard={!this.state.importing}
                centered
            >
                <Modal.Header closeButton onHide={cancel}>
                    <Modal.Title>
                        Import URLs
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Container>
                        <Row>
                            <WebsiteSearch onChange={this.handleWebsiteChange} />
                        </Row>
                        <Row>
                            {this.state.isBrandLoading && (
                                <Col><Spinner
                                    animation="border"
                                    size="sm"
                                /></Col>
                            )}
                            {!this.state.isBrandLoading && this.state.brandsAvailable.length > 0 && (

                                <MultiSelect
                                    id="brands"
                                    options={this.state.brandsAvailable}
                                    value={this.state.brandsSelected}
                                    onChange={this.selectBrand}
                                    labelledBy="Select brand"
                                />
                            )}
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer style={{
                    justifyContent: "center"
                }}>
                    <Button disabled={this.state.importing} size="sm" variant="secondary" onClick={cancel}>Cancel</Button>
                    <ProcessingButton
                        size="sm"
                        processing={this.state.importing}
                        processingLabel="Importing ..."
                        label="Import"
                        onClick={save}
                        disabled={this.state.brandsSelected.length === 0 || this.state.website === null}
                    />
                </Modal.Footer>
            </Modal >
        );
    }
}

ImportURLs.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    onUpdated: PropTypes.func,
    issues: PropTypes.array.isRequired
};

ImportURLs.defaultProps = {
    show: true,
    onClose: () => { console.log("onClose") },
    issues: []
};

export default ImportURLs;
