import React from "react";
import PropTypes from "prop-types";

import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import Toast from "../services/Toast";
import MerchantService from "../services/MerchantService";
import MerchantSearch from "./MerchantSearch";
import ProcessingButton from "./ProcessingButton";

/**
 * Shows a Link Merchant modal
 * @component
 */
class LinkMerchant extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            merchant: [],
            saving: false,
            options: [],
            query: "",
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(data) {
        this.setState({ merchant: data });
    }

    render() {
        let cancel = () => { this.props.onClose(false) },
            save = () => {
                this.setState({ saving: true }, () => {
                    MerchantService.linkMerchants(this.props.merchantId, this.state.merchant[0].id).then((result) => {
                        if (result === true) {
                            this.props.onClose(true)
                            Toast.ok("Merchants linked successfully");
                        } else {
                            Toast.error("Something went wrong while linking merchants");
                        }
                        this.setState({
                            saving: false
                        })
                    });
                });
            },
            skip = [...this.props.skip, this.props.merchantId];

        return (
            <Modal
                onEntering={this.onEntering}
                show={this.props.show}
                size="lg"
                backdrop={true}
                keyboard={true}
                centered
            >
                <Modal.Header closeButton onHide={cancel}>
                    <Modal.Title>
                        Link channels
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <MerchantSearch onChange={this.handleChange} skip={skip} />
                    {this.state.merchant.length > 0 && (
                        <Container className="pt-3 dd">
                            <Row><Col>Selected merchant:</Col></Row>
                            <Row>
                                <Col sm={2} className="text-right">Name</Col>
                                <Col sm={4} className="text-left bold">{this.state.merchant[0].name}</Col>
                                <Col sm={2} className="text-right">ID</Col>
                                <Col sm={4} className="text-left bold">{this.state.merchant[0].id}</Col>
                            </Row>
                            {this.state.merchant[0].type_id === 1 && (
                                <Row>
                                    <Col sm={2} className="text-right">Website</Col>
                                    <Col sm={10} className="text-left bold">{this.state.merchant[0].website}</Col>
                                </Row>
                            )}
                            {this.state.merchant[0].type_id === 2 && (
                                <Row>
                                    <Col sm={2} className="text-right">Marketplace</Col>
                                    <Col sm={4} className="text-left bold">{this.state.merchant[0].website}</Col>
                                    <Col sm={2} className="text-right">Seller ID</Col>
                                    <Col sm={4} className="text-left bold">{this.state.merchant[0].seller_id}</Col>
                                </Row>
                            )}
                            <Row>
                                <Col sm={2} className="text-right">URL</Col>
                                <Col sm={10} className="text-left bold"><a href={this.state.merchant[0].url} className="link" target="_blank" rel="noreferrer">{this.state.merchant[0].url}</a></Col>
                            </Row>

                        </Container>
                    )}
                </Modal.Body>
                <Modal.Footer style={{
                    justifyContent: "center"
                }}>
                    <Button size="sm" variant="secondary" onClick={cancel}>Cancel</Button>
                    <ProcessingButton
                        size="sm"
                        processing={this.state.saving}
                        processingLabel="Linking ..."
                        label="Link"
                        onClick={save}
                        disabled={this.state.merchant.length === 0}
                    />
                </Modal.Footer>
            </Modal >
        );
    }
}

LinkMerchant.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    merchantId: PropTypes.number.isRequired,
    skip: PropTypes.arrayOf(PropTypes.number),
};

LinkMerchant.defaultProps = {
    show: true,
    skip: [],
    onClose: () => { console.log("onClose") },
    merchantId: 0
};

export default LinkMerchant;
