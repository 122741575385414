import React from "react";
import { Col, Container, Row } from "react-bootstrap";

class Maintenance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            until: "."
        };
    }

    componentDidMount() {
        const until = window.localStorage.getItem('MAINTENANCE_UNTIL');
        if (until) {
            this.setState({
                until: " until " + until
            });
            window.localStorage.removeItem('MAINTENANCE_UNTIL');
        }
    }

    render() {
        return (
            <Container className="pt-4">
                <Row>
                    <Col className="text-center">
                        <h1>Maintenance Mode</h1>
                        <p>Sorry, we are currently down for maintenance{this.state.until}</p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Maintenance;
