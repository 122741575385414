import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Button component for with processing state
 * @component
 */
class ProcessingButton extends React.Component {

    render() {
        let style = typeof this.props.style === "undefined" || this.props.style === null ? {} : this.props.style;
        if (this.props.processing) {
            return (
                <Button style={style} disabled={true} variant={this.props.variant} size={this.props.size}>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span
                        style={{
                            paddingLeft: "10px",
                        }}
                    >
                        {this.props.processingLabel}
                    </span>
                </Button>
            )
        }
        const icon = typeof this.props.icon === "object" ?
            <FontAwesomeIcon
                icon={this.props.icon}
                style={{
                    marginRight: "7px",
                }} />
            : null;
        if (typeof this.props.minWidth === "string") {
            style.minWidth = this.props.minWidth
        }

        return (
            <Button
                variant={this.props.variant}
                size={this.props.size}
                disabled={this.props.disabled}
                onClick={this.props.onClick}
                style={style}
            >
                {icon}{this.props.label}
            </Button>
        )
    }
}

ProcessingButton.propTypes = {
    disabled: PropTypes.bool.isRequired,
    processing: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    variant: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    processingLabel: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    minWidth: PropTypes.string,
    icon: PropTypes.object,
    style: PropTypes.object
};

ProcessingButton.defaultProps = {
    size: "md",
    label: 'Submit',
    processingLabel: 'Processing ...',
    variant: "primary",
    processing: false,
    disabled: false,
    onClick: () => {
        console.log('Clicked')
    },
    style: null
};

export default ProcessingButton;
