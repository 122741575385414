import axios from "axios";

import Config from "./Config";
import APIHelper from "./APIHelper";

let cancels = {};
const UserService = {
    typeahead(search, includePreviousNames) {
        if (typeof cancels['typeahead'] !== 'undefined') {
            cancels['typeahead'].cancel("Operation canceled due to new request.");
        }
        cancels['typeahead'] = axios.CancelToken.source();
        return axios
            .post(Config.API_URL + "user/search", {
                name: search,
                previous_names: includePreviousNames
            }, {
                cancelToken: cancels['typeahead'].token
            })
            .then(APIHelper.returnItemsOrEmptyArray)
            .catch((error) => {
                if (typeof error.message !== "undefined" && /Operation canceled/.test(error.message)) {
                    return null;
                }
                return [];
            });
    },
    all() {
        return axios
            .get(Config.API_URL + "users")
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    get(id) {
        return axios
            .get(Config.API_URL + "user/" + id)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    update(id, changes) {
        return axios
            .put(Config.API_URL + "user/" + id, changes)
            .then(response => APIHelper.returnAttribute(response, 'user'))
            .catch(APIHelper.returnFalse);
    },
    name(id) {
        return axios
            .get(Config.API_URL + "user/" + id + "/name")
            .then(response => APIHelper.returnAttribute(response, 'name'))
            .catch(APIHelper.returnFalse);
    },
    brands(id) {
        return axios
            .get(Config.API_URL + "user/" + id + "/brands")
            .then(response => APIHelper.returnAttribute(response, 'brands'))
            .catch(APIHelper.returnFalse);
    },
    create(payload) {
        return axios
            .post(Config.API_URL + "users", payload)
            .then((response) => {
                if (typeof response.data === "object" && typeof response.data.status === "boolean" && response.data.status && typeof response.data.user !== "undefined" && typeof response.data.user.id === "number") {
                    return response.data.user;
                }
                if (typeof response.data === "object" && typeof response.data.error === "string") {
                    return response.data.error;
                }
                return false;
            })
            .catch(APIHelper.returnFalse);
    },
    /**
     * Links an user to a set of brands 
     * @param {number} id User ID
     * @param {array[number]} ids Brand IDs
     * @param {boolean} adminRole 
     */
    addBrand(id, ids, adminRole) {
        return axios
            .post(Config.API_URL + "user/" + id + "/brands", {
                brands: ids,
                role_id: adminRole ? 2 : 1
            })
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    removeBrand(id, brand_id) {
        return axios
            .delete(Config.API_URL + "user/" + id + "/brand/" + brand_id)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    changeRole(id, brand_id, role_id) {
        return axios
            .put(Config.API_URL + "user/" + id + "/brand/" + brand_id, {
                role_id
            })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    }

};

export default UserService;
