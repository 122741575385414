import React from "react";

import { Carousel } from "react-bootstrap";
import Emitter from "../../../services/Emitter";
import MemoryService from "../../../services/MemoryService";
import Job from "./View";
import JobsList from "./List";
import NewJob from "./New";
import MyCarousel from "../../../components/MyCarousel";

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.last = -1;
        this.timer = null;
        this.handleOnSlide = this.handleOnSlide.bind(this);
    }

    componentWillUnmount() {
        if (MemoryService.Has('REFRESH_ONBOARDING_JOBS')) {
            MemoryService.Flush('REFRESH_ONBOARDING_JOBS');
        }
    }

    handleOnSlide(eventKey) {
        if (this.last !== "undefined" && this.last === eventKey) {
            return;
        }
        this.last = eventKey;
        if (eventKey === 0 && MemoryService.Has('REFRESH_ONBOARDING_JOBS')) {
            setTimeout(() => {
                Emitter.emit("UPDATE_ONBOARDING_JOB", MemoryService.Get('REFRESH_ONBOARDING_JOBS'));
                MemoryService.Flush('REFRESH_ONBOARDING_JOBS');
            }, 250);
        } else if (eventKey === 1) {
            Emitter.emit("ONBOARDING_JOB_NEW");
        } else if (eventKey === 2) {
            setTimeout(() => {
                let jobId = window.location.href.match(/job\/(\d+)$/)[1]
                Emitter.emit("ONBOARDING_JOB", jobId);
            }, 250);
        }
    }

    showPage(tab) {
        let key = 0;
        if (tab === "new") {
            key = 1;
        } else if (tab === 'job') {
            key = 2;
        }
        if (typeof this.first === "undefined") {
            this.handleOnSlide(key);
        }
        return (
            <MyCarousel activeIndex={key} onSlide={this.handleOnSlide}>
                <Carousel.Item key="onboarding.list" className="standardGrid">
                    <JobsList />
                </Carousel.Item>
                <Carousel.Item key="onboarding.new" className="standardGrid">
                    <NewJob />
                </Carousel.Item>
                <Carousel.Item key="onboarding.job" className="standardGrid">
                    <Job />
                </Carousel.Item>
            </MyCarousel>
        );
    }

    render() {
        let activeKey = 'list';
        if (window.location.href.match(/\/new$/)) {
            activeKey = 'new';
        } else if (window.location.href.match(/job\/\d+$/)) {
            activeKey = 'job';
        }

        return this.showPage(activeKey);
    }
}
export default Index;
