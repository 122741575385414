import React from "react";

import { Container, Row, Col, Form, Breadcrumb } from "react-bootstrap";
import SettingsService from "../../services/SettingsService";

/**
 * Settings component
 * @component
 * @category Scenes
 * @subcategory Others
 */
class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pdlt: SettingsService.Get('pdlt', false)
        }
        this.handlePDLTChange = this.handlePDLTChange.bind(this);
    }

    handlePDLTChange(evt) {
        SettingsService.Set('pdlt', evt.target.checked);
        this.setState({ pdlt: evt.target.checked });
    }

    render() {
        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item active>Preferences</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <Col>
                        <Form.Check data-testid="include-pdlt" type="checkbox" label="Include PDLT Brands on searches" checked={this.state.pdlt} onChange={this.handlePDLTChange} />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Settings;
