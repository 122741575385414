import axios from "axios";

import Config from "./Config";
import APIHelper from "./APIHelper";

const CostExplorerService = {
    bills(type) {
        return axios
            .get(Config.API_URL + "costexplorer/bills/" + type)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    formula() {
        return axios
            .get(Config.API_URL + "costexplorer/formula")
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    asset(type, id) {
        return axios
            .get(Config.API_URL + "costexplorer/bill-per-entity/" + type + "/" + id)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    report(type, month) {
        return axios
            .get(Config.API_URL + "costexplorer/report/" + type + "/" + month)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
};

export default CostExplorerService;
