import axios from "axios";

import Config from "./Config";
import APIHelper from "./APIHelper";

const ProductsTrendsService = {

    /**
     *
     * @param {array} brands
     * @param {array} conditions
     * @param {integer} difference
     * @returns {Promise<AxiosResponse<any> | boolean>}
     */
    getPriceViolations(brands, conditions, difference) {
        return axios
            .post(Config.API_URL + "pricing-violations", {brands: brands, conditions: conditions, difference: difference})
            .then(APIHelper.returnItems)
            .catch(APIHelper.returnFalse);
    },
};

export default ProductsTrendsService;
