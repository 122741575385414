import React, { createRef } from "react";
import PropTypes from "prop-types";

import { Container, Button, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";
import "../../../node_modules/currency-flags/dist/currency-flags.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Emitter from "../../services/Emitter";
import { Link, withRouter } from "react-router-dom";
import Utils from "../../services/Utils";
import Toast from "../../services/Toast";
import UserService from "../../services/UserService";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import OrganizationAndBrands from "../../components/OrganizationAndBrands";
import confirm from "../../components/Confirm";
import MySpinner from "../../components/MySpinner";
import MyTable from "../../components/MyTable";

/**
 * Crawl Results component
 * @component
 * @category Scenes
 * @subcategory Brand
 */
class Brand extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            brands2skip: []
        }
        this.onEnter = this.onEnter.bind(this);
        this.onDisconnectRequest = this.onDisconnectRequest.bind(this);
        this.onChangeRoleRequest = this.onChangeRoleRequest.bind(this);
        this.tableRef = createRef();
    }

    componentWillUnmount() {
        Emitter.off("USER_BRANDS");
    }

    componentDidMount() {
        Emitter.once("USER_BRANDS", this.onEnter);
    }

    onEnter() {
        UserService.brands(this.props.id).then((brands) => {
            if (brands === false) {
                Toast.error("Something went wrong while retrieving brands associated to this user");
                this.props.history.push("/user/" + this.props.id);
                return;
            }
            this.setState({
                brands2skip: brands.map((r) => r.id)
            })

            this.tableRef.current?.populate(brands);
        });
    }


    async onDisconnectRequest(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);
        const shouldProcessed = await confirm("Are you sure?", "Yes", "No", { message: "Do you really want to disconnect this user from " + obj.row.brand + "?" });
        if (!shouldProcessed) {
            return;
        }

        obj.row.deleting = true;
        this.tableRef.current.updateRow(obj.row);
        UserService.removeBrand(this.props.id, obj.row.id).then((result) => {
            if (result === false) {
                Toast.error("Failed disconnecting user from brand");
                delete obj.row.deleting;
                this.tableRef.current.updateRow(obj.row);
                return;
            }

            this.tableRef.current.deleteRow(obj.row);
        });
    }


    onChangeRoleRequest(evt) {
        evt.preventDefault();
        evt.stopPropagation();

        let obj = this.tableRef.current.getRowFromEvent(evt),
            newRole = obj.row.role_id === 1 ? 2 : 1;

        obj.row.updating = true;
        this.tableRef.current.updateRow(obj.row);

        UserService.changeRole(this.props.id, obj.row.id, newRole).then((result) => {
            delete obj.row.updating;
            if (result) {
                obj.row.role_id = newRole;
                Toast.ok("Role changed successfully");
            } else {
                Toast.error("Something went wrong while changing the role");
            }
            this.tableRef.current.updateRow(obj.row);
        });
    }

    render() {

        return (
            <Container className="pt-4">
                <OrganizationAndBrands title="Connect Brands"
                    onClose={(evt) => {
                        this.setState({ showModal: false })
                    }}
                    show={this.state.showModal}
                    callbackPromise={(brands) => {
                        return new Promise(async (resolve, reject) => {
                            let isAdmin = await confirm("Should this user have Admin role on " + (brands.length > 1 ? "these brands?" : "this brand?"), "Yes", "No", { enableEscape: false });
                            UserService.addBrand(this.props.id, brands, isAdmin).then((result) => {
                                if (result === false) {
                                    Toast.error("Something went wrong while connecting brands");
                                    reject();
                                    return;
                                }
                                let brands2skip = this.state.brands2skip;
                                result.brands.forEach((brand) => {
                                    brands2skip.push(brand.id);
                                });
                                this.setState({ brands2skip });
                                this.tableRef.current?.addRows(result.brands);
                                resolve(true);
                            }).catch(reject);
                        })
                    }}
                    skip={this.state.brands2skip}
                />
                <MyTable
                    name="user-brands"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="brand"
                    sortOrder="asc"
                    headers={
                        [
                            {
                                field: "brand",
                                label: "Brand",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "organization",
                                label: "Organization",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "role",
                                label: "Role",
                                width: "100px",
                            },
                            {
                                field: "control",
                                width: "45px",
                                content: <OverlayTrigger
                                    placement="bottom"
                                    trigger={["hover", "focus"]}
                                    overlay={
                                        <Popover>
                                            <PopoverBody>
                                                Connect to other brands
                                            </PopoverBody>
                                        </Popover>
                                    }
                                >
                                    <Button
                                        size="sm"
                                        className="tdButton"
                                        variant="success"
                                        onClick={() => {
                                            this.setState({ showModal: true })
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                </OverlayTrigger>
                            }
                        ]}

                    renderColumns={{
                        brand: (row) => <Link className="link" to={`/brand/${row.id}#users`}>(#{row.id}) {row.brand}</Link>,
                        organization: (row) => <Link className="link" to={`/organization/${row.org_id}`}>(#{row.org_id}) {row.organization}</Link>,
                        role: {
                            className: "text-center",
                            format: (row) => !row.updating ?
                                <OverlayTrigger
                                    placement="bottom"
                                    trigger={["hover", "focus"]}
                                    overlay={
                                        <Popover>
                                            <PopoverBody>Switch Role</PopoverBody>
                                        </Popover>
                                    }>
                                    <a href="#users" onClick={this.onChangeRoleRequest} className="link">{Utils.UserRoleName(row.role_id)}</a>
                                </OverlayTrigger>
                                :
                                <MySpinner />
                        },
                        control: {
                            className: "text-center",
                            format: (row) => !row.deleting ?
                                <OverlayTrigger
                                    placement="bottom"
                                    trigger={["hover", "focus"]}
                                    overlay={
                                        <Popover>
                                            <PopoverBody>
                                                Disconnect from Brand
                                            </PopoverBody>
                                        </Popover>
                                    }
                                >
                                    <Button
                                        size="sm"
                                        className="tdButton"
                                        variant="outline-secondary"
                                        onClick={this.onDisconnectRequest}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </OverlayTrigger>
                                :
                                <MySpinner />
                        }
                    }}
                />
            </Container >
        );
    }
}

Brand.propTypes = {
    /** Brand ID */
    id: PropTypes.number.isRequired,
};

export default withRouter(Brand);
