import axios from "axios";

import Config from "./Config";
import APIHelper from "./APIHelper";

const PDDService = {
    jobs() {
        return axios
            .get(Config.API_URL + "pdd/jobs")
            .then(APIHelper.returnRows)
            .catch(APIHelper.returnFalse);
    },
    jobsInReview() {
        return axios
            .get(Config.API_URL + "pdd/jobs/review")
            .then(APIHelper.returnRows)
            .catch(APIHelper.returnFalse);
    },
    changeReviewStatus(batch_id, status) {
        return axios
            .put(Config.API_URL + "pdd/job/" + batch_id, { review_status: status})
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    request(payload) {
        return axios
            .post(Config.API_URL + "pdd/jobs", payload)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    summary(manufacturer_ids, country_ids) {
        return axios
        .post(Config.API_URL + "pdd/summary", { manufacturer_ids, country_ids })
        .then(APIHelper.returnRows)
        .catch(APIHelper.returnFalse);
    },
    download(batch_id, type="all", filename="pdd-result") {
        return axios
            .get(Config.API_URL + "pdd/job/" + batch_id + "/csv/" + type)
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data.report])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    filename + "-" + batch_id + ".csv"
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                return true;
            })
            .catch(APIHelper.returnFalse);
    },
    listDisabled() {
        return axios
            .get(Config.API_URL + "pdd/brand/disabled")
            .then(APIHelper.returnRows)
            .catch(APIHelper.returnFalse);
    },
    toggle(brand_id, enabled) {
        const verb = enabled ? axios.put : axios.delete;
        return verb(Config.API_URL + "pdd/brand/" + brand_id)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    }
};

export default PDDService;
