import React from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import EntityCosts from "../../components/EntityCosts";

/**
 * @component
 * @category Scenes
 * @subcategory Website
 */
class Costs extends React.Component {

    render() {
        return <EntityCosts type="website" id={this.props.websiteId} />
    }
}

Costs.propTypes = {
    websiteId: PropTypes.number.isRequired,
};

export default withRouter(Costs);
