import React, { createRef } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Toast from "../../services/Toast";
import { Breadcrumb, Container, Button, Row, Col, Form } from "react-bootstrap";
import MyTable from "../../components/MyTable";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import CostExplorerService from "../../services/CostExplorerService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MySpinner from "../../components/MySpinner";
import Utils from "../../services/Utils";
import CSV from "../../services/CSV";
import { Link, withRouter } from "react-router-dom";

/**
 * @component
 * @category Scenes
 * @subcategory CostExplorer
 */
class CostPerEntity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dates: [],
            headers: [],
            entity: "brand",
            showTable: false,
            loading: false,
            month: this.getInitialMonth(),
            months: this.getMonthList(),
            topRow: {},
        }
        this.tableRef = createRef();
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
        this.handleEntityChange = this.handleEntityChange.bind(this);
        this.handleMonthChange = this.handleMonthChange.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    getInitialMonth() {
        const date = new Date();
        if (date.getDate() === 1) {
            date.setMonth(date.getMonth() - 1);
        }
        return date.toJSON().substring(0, 7);
    }

    getMonthList() {
        const stopAt = '2023-08', // when we started collecting this data
            date = new Date(),
            months = [];
        if (date.getDate() === 1) {
            date.setMonth(date.getMonth() - 1);
        }
        let month, monthLabel;
        do {
            month = date.toJSON().substring(0, 7);
            monthLabel = Utils.MonthAndYear(month);
            months.push({
                value: month,
                label: monthLabel
            });
            date.setMonth(date.getMonth() - 1);
        } while (month !== stopAt);
        return months;
    }

    handleMonthChange(e) {
        this.setState({
            month: e.target.value,
        }, this.loadData);
    }

    handleEntityChange(e) {
        this.setState({
            entity: e.target.value
        }, () => {
            this.loadData()
        });
    }

    loadData() {
        const tableRef = this.tableRef.current ?? { setLoading: () => { } };
        tableRef.setLoading(true);

        this.setState({
            loading: true,
        });
        CostExplorerService.report(this.state.entity, this.state.month).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the data.");
                this.setState({
                    loading: false,
                    showTable: false
                });
                return;
            }

            const datesTotal = {},
                renderDates = {},
                dates = data.headers.filter(date => date.match(/^\d{4}-\d{2}-\d{2}/)).map(date => {
                    renderDates[date] = this.formatValue;
                    datesTotal[date] = 0;
                    return {
                        field: date,
                        label: date,
                        sortable: true,
                        width: "100px",
                    };
                }),
                lines = Utils.parseCSV(data.items),
                items = [];
            let dataPoints = 0;

            lines.forEach((content) => {
                let obj = {
                    total: 0
                };
                for (let i = 0; i < data.headers.length; i++) {
                    obj[data.headers[i]] = content[i];
                }
                dates.forEach(item => {
                    const field = item.field,
                        value = obj[field] * 1;
                    obj.total += value;
                    datesTotal[field] += value;
                });
                obj.crawls = parseInt(obj.crawls);
                obj.ppc = obj.crawls ? obj.total / obj.crawls : 0;
                dataPoints += obj.crawls;
                items.push(obj);
            });

            const nonEmptyDateColumns = dates.filter(date => datesTotal[date.field] > 0),
                renderColumns = this.state.entity === "brand"
                    ? {
                        name: this.formatBrand,
                        organization_name: this.formatOrganization,
                        total: this.formatValue,
                        ppc: this.formatValue,
                        ...renderDates
                    }
                    : {
                        name: this.formatWebsite,
                        total: this.formatValue,
                        ppc: this.formatValue,
                        ...renderDates
                    },
                commonHeaders = [
                    {
                        field: "name",
                        label: "Name",
                        sortable: true,
                        searchable: true,
                        width: "200px",
                    },
                    {
                        field: "total",
                        label: "Total",
                        sortable: true,
                        width: "100px",
                    },
                    {
                        field: "crawls",
                        label: "Data Points",
                        hint: "Number of crawled data points in the month",
                        sortable: true,
                        width: "100px",
                    },
                    {
                        field: "ppc",
                        label: "Cost PDP",
                        hint: "Cost Per Data Point",
                        sortable: true,
                        width: "100px",
                    },
                    ...nonEmptyDateColumns
                ],
                headers = this.state.entity === "brand" ?
                    [
                        {
                            field: "airtable_id",
                            label: "Airtable ID",
                            sortable: true,
                            searchable: true,
                            width: "100px",
                        },
                        {
                            field: "organization_name",
                            label: "Organization",
                            sortable: true,
                            searchable: true,
                            width: "200px",
                        },
                        ...commonHeaders
                    ] : commonHeaders;

            this.renderColumns = renderColumns;

            let total = Object.values(datesTotal).reduce((a, b) => a + b, 0);
            this.setState({
                dates: nonEmptyDateColumns,
                headers,
                showTable: true,
                loading: false,
                topRow: {
                    name: 'Total',
                    total,
                    crawls: dataPoints,
                    ppc: dataPoints ? total / dataPoints : 0,
                    ...datesTotal
                }
            }, () => {
                const tableRef = this.tableRef.current ?? { resetSettings: () => { }, populate: console.log };
                tableRef.resetSettings();
                tableRef.populate(items.filter(item => item.total > 0));
            });
        });
    }

    componentDidMount() {
        this.loadData();
    }

    downloadSpreadsheet() {
        const csv = new CSV(),
            dates = this.state.dates.map(date => date.field),
            rows = this.tableRef.current.data();

        if (this.state.entity === "brand") {
            csv.addColumn("Airtable ID", "Organization ID", "Organization Name", "Brand ID", "Brand Name", "Total", ...dates);
            rows.forEach((item) => {
                let row = [item.airtable_id, item.organization_id, item.organization_name, item.id, item.name, item.total];
                dates.forEach(date => row.push(item[date]));
                csv.addRow(row);
            });
            csv.Download("cost-per-brand-" + this.state.month + ".csv");
            return;
        }
        csv.addColumn("Website ID", "Website Name", "Total", ...dates);
        rows.forEach((item) => {
            let row = [item.id, item.name, item.total];
            dates.forEach(date => row.push(item[date]));
            csv.addRow(row);
        });
        csv.Download("cost-per-website-" + this.state.month + ".csv");
    }

    formatValue(row, field) {
        const value = row[field] ?? null;
        switch (value) {
            case '0':
            case 0:
            case '':
            case null:
                return '';
            default:
                return Utils.AsManyDecimalsAsNeeded(value);
        }
    }

    formatBrand(row) {
        return typeof row.id === 'undefined' ? row.name : <Link target="_blank" to={`/brand/${row.id}/#costs`} className="link">{row.name}</Link>
    }

    formatWebsite(row) {
        return typeof row.id === 'undefined' ? row.name : <Link target="_blank" to={`/website/${row.id}/#costs`} className="link">{row.name}</Link>
    }

    formatOrganization(row) {
        return <Link target="_blank" to={`/organization/${row.organization_id}`} className="link">{row.organization_name}</Link>
    }

    render() {
        const monthOptions = this.state.months.map(month => {
            return <option key={month.value} value={month.value}>{month.label}</option>
        });

        return <Container className="pt-4">
            {this.state.showTable && <span style={{
                float: "right"
            }}
            >
                <Button
                    disabled={this.state.loading}
                    size="sm"
                    className="tdButton"
                    variant="success"
                    label="Download CSV"
                    onClick={this.downloadSpreadsheet}
                >
                    <FontAwesomeIcon icon={faDownload} /> Download
                </Button>
            </span>}
            <Breadcrumb className="dd">
                <Breadcrumb.Item>CostExplorer</Breadcrumb.Item>
                <Breadcrumb.Item active>Cost per Entity</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                <Col sm={1} className="d-flex align-items-center justify-content-end">Month</Col>
                <Col sm={2}>
                    <Form.Select
                        disabled={this.state.loading}
                        aria-label="Month"
                        name="month"
                        value={this.state.month}
                        onChange={this.handleMonthChange}
                    >
                        {monthOptions}
                    </Form.Select>
                </Col>
                <Col sm={1} />
                <Col sm={1} className="d-flex align-items-center justify-content-end">Entity</Col>
                <Col sm={1} className="d-flex align-items-center justify-content-center">
                    <Form.Check
                        disabled={this.state.loading}
                        type="radio"
                        name="entity"
                        value="brand"
                        label="Brand"
                        onChange={this.handleEntityChange}
                        checked={this.state.entity === "brand"}
                    />
                </Col>
                <Col sm={1} className="d-flex align-items-center justify-content-center">
                    <Form.Check
                        disabled={this.state.loading}
                        type="radio"
                        name="entity"
                        value="website"
                        label="Website"
                        onChange={this.handleEntityChange}
                        checked={this.state.entity === "website"}
                    />
                </Col>
            </Row>
            {!this.state.showTable && this.state.loading && <div className="text-center"><MySpinner /></div>}
            {this.state.showTable && <MyTable
                name={`costexplorer-cost-per-entity-${this.state.entity}`}
                dynamic={false}
                ref={this.tableRef}
                sortBy="total"
                sortOrder="desc"
                headers={this.state.headers}
                renderColumns={this.renderColumns}
                topRow={this.state.topRow}
            />}
        </Container>
    }
}

export default withRouter(CostPerEntity);