import React from "react";

import { Container, Breadcrumb, Row, Col } from "react-bootstrap";
import GaugeChart from 'react-gauge-chart';
import SmartSelectionService from "../../../services/NPDService";
import Toast from "../../../services/Toast";
import Loading from "../../Loading";

/**
 * Smart Selection Gauge component
 * @component
 * @category Others
 */
class Gauges extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            urls_percent: 0,
            urls_npd: 0,
            urls_total: 0,
            crawls_percent: 0,
            crawls_ok: 0,
            crawls_total: 0
        }
    }

    componentDidMount() {
        SmartSelectionService.gauge().then((data) => {
            var urls_percent = 0,
                urls_npd = 0,
                urls_total = 0,
                crawls_total = 0,
                crawls_percent = 0,
                crawls_ok = 0;
            if (data === false) {
                Toast.error("Something went wrong while retrieving data");
            } else {
                urls_percent = data.urls.percent / 100;
                urls_npd = data.urls.npd;
                urls_total = data.urls.total;
                crawls_percent = data.crawls.percent / 100;
                crawls_ok = data.crawls.ok;
                crawls_total = data.crawls.total;
            }
            this.setState({
                urls_percent,
                urls_npd,
                urls_total,
                crawls_percent,
                crawls_ok,
                crawls_total,
                loading: false,
            });
        });

    }

    render() {
        const urls_percent = this.state.urls_percent,
            page = this.state.loading ? <Loading /> :
                <React.Fragment>
                    <Row>
                        <Col sm={6}>
                            <p align="center"><b>URLs coming from NPD since September 2022</b></p>
                            <GaugeChart id="gauge-smart-selection"
                                colors={['#ff0018', '#ffc107', '#09b11a']}
                                arcsLength={[0.15, 0.24, 1]}
                                percent={urls_percent}
                                arcPadding={0}
                                needleColor="#345243"
                                cornerRadius={0}
                                textColor="black"
                                animate={true}
                            />
                            <p className="text-center">{this.state.urls_npd} out of {this.state.urls_total}</p>
                        </Col>
                        <Col sm={6}>
                            <p align="center"><b>Effectiveness of NPD crawlers</b></p>
                            <GaugeChart id="gauge-smart-selection-crawlers"
                                percent={this.state.crawls_percent}
                                nrOfLevels={100}
                                colors={['#ff0018', '#09b11a']}
                                arcPadding={0}
                                needleColor="#345243"
                                cornerRadius={0}
                                textColor="black"
                                animate={true}
                            />
                            <p className="text-center">{this.state.crawls_ok} out of {this.state.crawls_total}</p>
                        </Col>
                    </Row>
                </React.Fragment>;
        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Others</Breadcrumb.Item>
                    <Breadcrumb.Item>NPD</Breadcrumb.Item>
                    <Breadcrumb.Item active>Gauges</Breadcrumb.Item>
                </Breadcrumb>
                {page}
            </Container >
        );
    }
}

export default Gauges;
