import React from "react";

import { Nav, Carousel, Container, Breadcrumb } from "react-bootstrap";
import Profile from "./Profile";
import Emitter from "../../services/Emitter";
import OrganizationService from "../../services/OrganizationService";
import Toast from "../../services/Toast";
import { withRouter } from "react-router-dom";
import Brands from "./Brands";
import MyCarousel from "../../components/MyCarousel";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.orgId = parseInt(this.props.match.params.id);
        this.state = {
            organization: "..."
        };
        this.firstLoad = true;
        OrganizationService.name(this.orgId).then((name) => {
            if (name === false) {
                Toast.error("Something went wrong while retrieving this organization's data");
                this.props.history.push("/organization");
                return;
            }
            this.setState({ organization: name });
        })
    }

    showPage(tab) {
        let key, event;
        key = 0;
        event = "ORG_PROFILE";

        if (tab === "#brands") {
            event = "ORG_BRANDS";
            key = 1;
        }

        if (this.firstLoad) {
            this.firstLoad = false;
            setTimeout(() => {
                Emitter.emit(event);
            }, 250);
        } else {
            Emitter.emit(event);
        }

        return (
            <MyCarousel activeIndex={key}>
                <Carousel.Item key="#profile" className="standardGrid">
                    <Profile orgId={this.orgId} />
                </Carousel.Item>
                <Carousel.Item key="#brands" className="standardGrid">
                    <Brands orgId={this.orgId} />
                </Carousel.Item>
            </MyCarousel>
        );
    }

    render() {
        let match = window.location.href.match(/#\w+/),
            activeKey = match !== null ? match[0] : "#profile";

        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Organization</Breadcrumb.Item>
                    <Breadcrumb.Item active>{this.state.organization}</Breadcrumb.Item>
                </Breadcrumb>
                <Nav variant="tabs" activeKey={activeKey}>
                    <Nav.Item>
                        <Nav.Link href="#profile">Profile</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#brands">Brands</Nav.Link>
                    </Nav.Item>
                </Nav>
                {this.showPage(activeKey)}
            </Container>
        );
    }
}
export default withRouter(Index);
