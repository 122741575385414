import React, { createRef } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Link, withRouter } from "react-router-dom";
import Brand from "../../services/BrandService";
import Toast from "../../services/Toast";
import { Button, Container } from "react-bootstrap";
import MyTable from "../../components/MyTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faDownload, faTimes } from "@fortawesome/free-solid-svg-icons";
import Utils from "../../services/Utils";
import Emitter from "../../services/Emitter";
import Lightbox from "react-image-lightbox";


/**
 * @component
 * @category Scenes
 * @subcategory Brand
 */
class Products extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = createRef();
        this.state = {
            downloading: false,
            image: null
        }
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
        this.onEnter = this.onEnter.bind(this);
    }

    componentDidMount() {
        Emitter.once("BRAND_PRODUCTS", this.onEnter);
    }

    componentWillUnmount() {
        Emitter.off("BRAND_PRODUCTS");
    }

    onEnter() {
        Brand.products(this.props.brandId, true).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of products");
                return;
            }

            this.tableRef.current?.populate(data);
        });
    }

    downloadSpreadsheet() {
        this.tableRef.current.CSV({
            filename: "brand" + this.props.brandId + "-products-" + Utils.DateNow().replaceAll(' ', '-') + ".csv"
        })
    }

    render() {
        return (

            <Container className="pt-4">
                {
                    this.state.image !== null && (
                        <Lightbox
                            mainSrc={this.state.image}
                            onCloseRequest={() => this.setState({ image: null })}
                        />
                    )
                }
                <MyTable
                    name="brand-products"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="identifier"
                    sortOrder="asc"
                    initialFilter={{
                        is_archived: '0'
                    }}
                    headers={
                        [
                            {
                                field: "id",
                                label: "ID",
                                sortable: true,
                                searchable: true,
                                width: "60px",
                            },
                            {
                                field: "image",
                                label: "Image",
                            },
                            {
                                field: "identifier",
                                label: "Identifier",
                                width: "120px",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "upc",
                                label: "UPC",
                                width: "120px",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "title",
                                label: "Title",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "sku",
                                label: "SKU",
                                width: "120px",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "is_tracked",
                                label: "Tracked",
                                width: "100px",
                                sortable: true,
                                allowEmpty: true,
                                options: [{ label: 'Yes', value: '1' }, { label: 'No', value: '0' }]
                            },
                            {
                                field: "is_archived",
                                label: "Archived",
                                width: "100px",
                                sortable: true,
                                allowEmpty: true,
                                options: [{ label: 'Yes', value: '1' }, { label: 'No', value: '0' }]
                            },
                            {
                                field: "control",
                                label: '',
                                width: "1px",
                                content: <Button size="sm" className="tdButton" variant="outline-primary" onClick={this.downloadSpreadsheet}>
                                    <FontAwesomeIcon icon={faDownload} />
                                </Button>
                            }
                        ]
                    }
                    renderRows={{
                        className: (row) => row.is_archived === 1 ? "blocked" : ""
                    }}
                    renderColumns={{
                        id: (row) => <Link className="link" to={`/product/${row.id}`}>{row.id}</Link>,
                        title: (row) => <Link className="link" to={`/product/${row.id}`}>{row.title}</Link>,
                        image: {
                            format: (row) => <img key={`img-${row.id}`} src={row.image === null ? '#' : row.image} alt={row.identifier} onClick={() => {
                                this.setState({ image: row.image.replace(/-tb.jpg/, '.jpg') })
                            }} onError={(e) => {
                                e.target.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
                            }} style={{
                                maxWidth: "100px",
                                maxHeight: "100px",
                                cursor: "pointer"
                            }} />,
                            className: "text-center"
                        },
                        is_archived: {
                            csv: (row) => row.is_archived ? 'Yes' : 'No',
                            format: (row) => row.is_archived ? (
                                <FontAwesomeIcon
                                    style={{
                                        color: "red",
                                    }}
                                    icon={faCheck}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    style={{ color: "green" }}
                                    icon={faTimes}
                                />
                            ),
                            className: "text-center"
                        },
                        is_tracked: {
                            csv: (row) => row.is_tracked ? 'Yes' : 'No',
                            format: (row) => row.is_tracked ? (
                                <FontAwesomeIcon
                                    style={{
                                        color: "green",
                                    }}
                                    icon={faCheck}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    style={{ color: "red" }}
                                    icon={faTimes}
                                />
                            ),
                            className: "text-center"
                        },

                    }}
                />
            </Container>
        )
    }
}

export default withRouter(Products);