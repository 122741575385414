import React, { createRef } from "react";

import { Container, Breadcrumb, Button, Spinner, Form } from "react-bootstrap";
import Toast from "../../../services/Toast";
import MyTable from "../../../components/MyTable";
import NPDService from "../../../services/NPDService";
import WebsiteService from "../../../services/WebsiteService";
import { Link } from "react-router-dom";
import Utils from "../../../services/Utils";
import MySpinner from "../../../components/MySpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Menu, MenuItem } from "@szhsin/react-menu";

/**
 * NPD component
 * @component
 * @category Others
 */
class Matcher extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = createRef();
        this.downloadPackage = this.downloadPackage.bind(this);
        this.switchStatus = this.switchStatus.bind(this);
    }

    downloadPackage(evt) {
        var obj = this.tableRef.current.getRowFromEvent(evt),
            row = obj.row,
            mode = obj.target.getAttribute("mode");

        row.downloading = true;
        this.tableRef.current.updateRow(row);
        WebsiteService.npd(row.website_id, mode).then((result) => {
            if (!result) {
                Toast.error("Something went wrong while retrieving the report");
            }
            delete row.downloading;
            this.tableRef.current.updateRow(row);
            Toast.info("You might encounter less than " + row.count + " rows in the spreadsheet as URLs from PDLT accounts were automatically removed from it.", 7500);
        });

    }

    switchStatus(evt) {
        var obj = this.tableRef.current.getRowFromEvent(evt),
            row = obj.row,
            field = evt.target.getAttribute("field"),
            checked = evt.target.checked;

        row[field + 'ing'] = true;
        this.tableRef.current.updateRow(row);

        NPDService.checked(row.website_id, field, checked).then((value) => {
            if (value === false) {
                Toast.error("Something went wrong while switching settings");
            } else {
                row[field] = value;
            }
            delete row[field + 'ing'];
            this.tableRef.current.updateRow(row);
        });
    }

    componentDidMount() {
        NPDService.matcher().then((data) => {
            if (data === false) {
                Toast.error(
                    "Something went wrong while retrieving the list of rows"
                );
                return;
            }
            this.tableRef.current?.populate(data);
        });
    }

    render() {
        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Others</Breadcrumb.Item>
                    <Breadcrumb.Item>NPD</Breadcrumb.Item>
                    <Breadcrumb.Item active>Matcher</Breadcrumb.Item>
                </Breadcrumb>
                <MyTable
                    name="npd-matcher"
                    ref={this.tableRef}
                    dynamic={false}
                    sortBy="executed"
                    sortOrder="desc"
                    headers={[
                        {
                            field: "website_id",
                            label: "ID",
                            sortable: true,
                            searchable: true,
                            width: "75px"
                        },
                        {
                            field: "website",
                            label: "Website",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "status",
                            label: "Status",
                            sortable: true,
                            width: "125px",
                            allowEmpty: true,
                            options: [{ label: 'Waiting', value: 'waiting' }, { label: 'Matching', value: 'matching' }, { label: 'Matched', value: 'matched' }]
                        },
                        {
                            field: "crawled",
                            label: "Crawled",
                            sortable: true,
                            searchable: true,
                            width: "175px"
                        },
                        {
                            field: "executed",
                            label: "Matched",
                            sortable: true,
                            searchable: true,
                            width: "175px"
                        },
                        {
                            field: "count",
                            label: "Count",
                            sortable: true,
                            width: "100px"
                        },
                        {
                            field: "review",
                            label: "In Review",
                            width: "200px",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "uploaded",
                            label: "Verified Results Uploaded",
                            width: "200px",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "control",
                            width: "50px"
                        }
                    ]}
                    renderColumns={{
                        website: (row) => <Link className="link" to={`/website/${row.website_id}#discovery`} target="_blank">{row.website.toLowerCase()}</Link>,
                        status: (row) => {
                            const style = row.status === 'waiting' ? {
                                color: "red"
                            } : (
                                row.status === 'matching' ? {
                                    color: 'orange'
                                } : {
                                    color: 'black'
                                }
                            );
                            return <span style={style}>{Utils.Capitalize(row.status)}</span>
                        },
                        count: {
                            format: (row) => row.status === 'matched' ? row.count : null,
                            className: "text-right"
                        },
                        review: {
                            text: (row) => typeof row.review === "string" ? row.review : '',
                            format: (row) => row.status !== 'matched' ? null : (row.reviewing ? <Spinner
                                animation="border"
                                role="status"
                            /> : <React.Fragment><Form.Check className="lg" type="checkbox" checked={Utils.isMoreRecentThan(row.review, row.executed)} onChange={this.switchStatus} field="review" /> {typeof row.review === "string" ? row.review : null}</React.Fragment>
                            ),
                            className: "text-center"
                        },
                        uploaded: {
                            text: (row) => typeof row.uploaded === "string" ? row.uploaded : '',
                            format: (row) => row.status !== 'matched' ? null : (row.uploadeding ? <Spinner
                                animation="border"
                                role="status"
                            /> : <React.Fragment><Form.Check className="lg" type="checkbox" checked={Utils.isMoreRecentThan(row.uploaded, row.executed)} onChange={this.switchStatus} field="uploaded" /> {typeof row.uploaded === "string" ? row.uploaded : null}</React.Fragment>
                            ),
                            className: "text-center"
                        },
                        control: (row) => typeof row.downloading !== "undefined" ? <MySpinner /> :
                            (row.status === "matched" ?
                                <Menu
                                    direction="left"
                                    style={{
                                        fontSize: "medium"
                                    }}
                                    menuButton={
                                        <Button
                                            size="sm"
                                            className="tdButton"
                                            variant="outline-secondary"
                                        >
                                            <FontAwesomeIcon icon={faDownload} />
                                        </Button>}>
                                    <MenuItem mode="simple"
                                        onClick={this.downloadPackage}>
                                        Simple
                                    </MenuItem>
                                    <MenuItem mode="original"
                                        onClick={this.downloadPackage}>
                                        Original
                                    </MenuItem>
                                </Menu>
                                : null)

                    }}
                />
            </Container >
        );
    }
}

export default Matcher;
