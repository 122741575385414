import React, { createRef } from "react";
import { Link, withRouter } from "react-router-dom";
import Brand from "../../services/BrandService";
import Toast from "../../services/Toast";
import { Button, Container } from "react-bootstrap";
import Emitter from "../../services/Emitter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faDownload, faTimes } from "@fortawesome/free-solid-svg-icons";
import MyTable from "../../components/MyTable";


/**
 * @component
 * @category Scenes
 * @subcategory Brand
 */
class Merchants extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            enforcement: {
                map: false,
                unauthorized: false
            }
        }
        this.onEnter = this.onEnter.bind(this);
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
        this.tableRef = createRef();
    }

    componentDidMount() {
        Emitter.once("BRAND_MERCHANTS", this.onEnter);
    }

    componentWillUnmount() {
        Emitter.off("BRAND_MERCHANTS");
    }

    downloadSpreadsheet() {
        this.tableRef.current.CSV({
            filename: "brand-" + this.props.brandId + "-merchants.csv"
        })
    }

    onEnter() {
        Brand.merchants(this.props.brandId).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of merchants");
                return;
            }
            let list = [];
            data.merchants.forEach((row) => {
                row.type = row.type_id === 1 ? 'Online Retailer' : 'Marketplace Seller';
                list.push(row);
            })
            data.enforcement.map = data.enforcement.map ? true : false;
            data.enforcement.unauthorized = data.enforcement.unauthorized ? true : false;
            this.setState({ yesterday: data.yesterday, enforcement: data.enforcement }, () => {
                this.tableRef.current?.resetSettings();
                this.tableRef.current?.populate(list);
            });
        });
    }


    render() {
        const headers = [
            {
                field: "name",
                label: "Merchant",
                sortable: true,
                searchable: true
            },
            {
                field: "website_name",
                label: "Website",
                sortable: true,
                searchable: true
            },
            {
                field: "automated",
                label: "Automated",
                sortable: true,
                allowEmpty: true,
                options: ['Yes', 'No']
            },
            {
                field: "type",
                label: "Type",
                sortable: true,
                allowEmpty: true,
                options: ["Marketplace Seller", "Online Retailer"]
            },
            {
                field: "products",
                label: "Products",
                width: "80px",
                sortable: true,
                className: "text-center",
                allowEmpty: true,
                options: ['< 50', '50 - 100', '100 - 250', '> 250']
            }
        ],
            boolValue = (row, field) => row[field] ? 'yes' : 'no',
            renderBool = (row, field) => {
                return row[field]
                    ? <FontAwesomeIcon
                        style={{
                            color: "green",
                        }}
                        icon={faCheck}
                    />
                    :
                    <FontAwesomeIcon
                        style={{ color: "red" }}
                        icon={faTimes}
                    />;
            };


        if (this.state.enforcement.unauthorized) {
            headers.push({
                field: "authorized",
                label: "Authorized Seller",
                width: "130px",
                sortable: true,
                allowEmpty: true,
                options: ['Yes', 'No']
            })
        }
        if (this.state.enforcement.map) {
            headers.push({
                field: "violations",
                label: "MAP Violations",
                width: "130px",
                sortable: true,

            })
        }

        return (
            <Container className="pt-4">
                <span style={{
                    position: "absolute",
                    top: "-40px",
                    right: "15px"
                }}><Button disabled={this.state.loading} onClick={this.downloadSpreadsheet} className="tdButton" size="sm" variant="success"><FontAwesomeIcon icon={faDownload} /></Button></span>
                <MyTable
                    name="brand-merchants"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="products"
                    sortOrder="desc"
                    headers={headers}
                    renderColumns={{
                        name: (row) => <Link className="link" to={`/merchant/${row.id}`}>(#{row.id}) {row.name}</Link>,
                        website_name: (row) => <Link className="link" to={`/website/${row.website_id}`}>(#{row.website_id}) {row.website_name}</Link>,
                        type_id: (row) => row.type,
                        authorized: {
                            format: renderBool,
                            className: "text-center",
                            text: boolValue,
                            csv: boolValue,
                        },
                        automated: {
                            format: renderBool,
                            className: "text-center",
                            text: boolValue,
                            csv: boolValue
                        }
                    }}
                />
            </Container>
        );
    }
}

export default withRouter(Merchants);
