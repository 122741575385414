import React, { createRef } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Toast from "../../services/Toast";
import { Breadcrumb, Container, Button, Popover, PopoverBody, OverlayTrigger } from "react-bootstrap";
import MyTable from "../../components/MyTable";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import CostExplorerService from "../../services/CostExplorerService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * @component
 * @category Scenes
 * @subcategory CostExplorer
 */
class Formula extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            sources: [],
            division: [],
        }
        this.tableRef = createRef();
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
    }

    componentDidMount() {
        CostExplorerService.formula().then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the formula.");
                return;
            }
            let rows = [],
                groups = {},
                sources = {},
                division = {};

            data.forEach(asset => {
                const row = {
                    asset_id: asset.id,
                    name: asset.label,
                    group: asset.group_name,
                    settings: JSON.stringify(asset.billing_settings),
                    division: asset.divided_by,
                    source: asset.billing,
                    description: asset.description ?? '',
                };
                rows.push(row);
                groups[row.group] = true;
                sources[row.source] = true;
                division[row.division] = true;
            });

            this.setState({
                groups: Object.keys(groups),
                sources: Object.keys(sources),
                division: Object.keys(division),
            }, () => {
                this.tableRef.current?.populate(rows);
            })
        });
    }

    downloadSpreadsheet() {
        this.tableRef.current.CSV();
    }

    render() {
        return (

            <Container className="pt-4">
                <span style={{
                    float: "right"
                }}
                >
                    <Button
                        size="sm"
                        className="tdButton"
                        variant="success"
                        label="Download CSV"
                        onClick={this.downloadSpreadsheet}
                    >
                        <FontAwesomeIcon icon={faDownload} /> Download
                    </Button>
                </span>
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>CostExplorer</Breadcrumb.Item>
                    <Breadcrumb.Item active>Formula</Breadcrumb.Item>
                </Breadcrumb>
                <MyTable
                    name="costexplorer-formula"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="group"
                    sortOrder="desc"
                    headers={
                        [
                            {
                                field: "asset_id",
                                label: "ID",
                                sortable: true,
                                searchable: true,
                                width: "60px",
                            },
                            {
                                field: "name",
                                label: "Name",
                                sortable: true,
                                searchable: true,
                                width: "250px",
                            },
                            {
                                field: "group",
                                label: "Group",
                                sortable: true,
                                allowEmpty: true,
                                options: this.state.groups,
                                width: "200px",
                            },
                            {
                                field: "division",
                                label: "Division",
                                sortable: true,
                                allowEmpty: true,
                                options: this.state.division,
                                width: "200px",
                            },
                            {
                                field: "source",
                                label: "Billing",
                                sortable: true,
                                allowEmpty: true,
                                options: this.state.sources,
                                width: "200px",
                            },
                            {
                                field: "settings",
                                label: "Settings",
                                searchable: true
                            },
                        ]
                    }
                    renderColumns={{
                        name: {
                            format: (row) => row.description.length
                                ? <OverlayTrigger
                                    placement="top"
                                    trigger={["hover", "focus"]}
                                    overlay={
                                        <Popover style={{ backgroundColor: "beige" }}>
                                            <PopoverBody>{row.description}</PopoverBody>
                                        </Popover>
                                    }
                                >
                                    <span style={{
                                        textDecoration: "underline",
                                        cursor: "pointer"
                                    }}>{row.name}</span>
                                </OverlayTrigger>
                                : row.name
                        },
                        settings: (row) => <span style={{
                            fontFamily: "monospace"
                        }}>{row.settings}</span>
                    }}
                />
            </Container>
        )
    }
}

export default Formula;