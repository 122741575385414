import React from "react";
import PropTypes from "prop-types";

import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Toast from "../../services/Toast";
import Utils from "../../services/Utils";
import Loading from "../Loading";
import ProcessingButton from "../../components/ProcessingButton";
import ManufacturerService from "../../services/ManufacturerService";

/**
 * Show a CRUD for Manufacturers
 * @component
 * @category Scenes
 * @subcategory Manufacturers
 */
class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.manufacturerId,
            loading: true,
            editing: false,
            updating: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.dropEdition = this.dropEdition.bind(this);
        this.startEdition = this.startEdition.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    handleChange(evt) {
        let key = evt.target.name,
            row = this.state.row;

        row[key] = evt.target.value;
        this.setState({ row });
    }

    componentDidMount() {
        ManufacturerService.get(this.state.id).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while retrieving data from this manufacturer");
                return;
            }

            data.name_variants_ext = data.name_variants.join('|');
            data.sku_prefixes_ext = data.sku_prefixes.join('|');
            data.sku_suffixes_ext = data.sku_suffixes.join('|');
            this.setState({
                loading: false,
                row: data,
                editing: false,
            });
        });
    }

    saveChanges() {
        this.setState({ updating: true });
        let payload = {
            name_variants: Utils.UniqueNonEmpty(this.state.row.name_variants_ext).join("|"),
            sku_prefixes: Utils.UniqueNonEmpty(this.state.row.sku_prefixes_ext).join("|"),
            sku_suffixes: Utils.UniqueNonEmpty(this.state.row.sku_suffixes_ext).join("|"),
        },
            originalPayload = {
                name_variants: this.original.name_variants.join("|"),
                sku_prefixes: this.original.sku_prefixes.join("|"),
                sku_suffixes: this.original.sku_suffixes.join("|")
            };

        if (JSON.stringify(payload) === JSON.stringify(originalPayload)) {
            this.dropEdition();
            return
        }
        ManufacturerService.update(this.state.id, payload).then((result) => {
            if (result === false) {
                this.setState({ updating: false, editing: true });
                Toast.error(
                    "Something wrong happened and changes could not be saved."
                );
                return;
            }

            let row = this.original;
            Object.keys(payload).forEach((key) => {
                row[key] = payload[key].split("|").filter(n => n);
                row[key + "_ext"] = payload[key];
            });

            this.setState({
                updating: false,
                editing: false,
                row
            });
            delete this.original;

            let message = 'Changes properly saved.'

            if (result.product_discovery_connected && !result.product_discovery_success) {
                Toast.info(message + ' ' + result.product_discovery_message, 10000)
                return;
            }

            if (result.product_discovery_connected) {
                message = message + ' ' + result.product_discovery_message;
            }

            Toast.ok(message, 10000);
        });
    }

    startEdition() {
        this.original = JSON.parse(JSON.stringify(this.state.row));
        this.setState({
            editing: true
        });
    }

    dropEdition() {
        this.setState({ editing: false, row: this.original, updating: false });
        delete this.original;
    }

    render() {
        if (this.state.loading) {
            return (
                <Container className="pt-4">
                    <Row
                        style={{
                            height: "100px",
                        }}
                    >
                        <Col>
                            <Loading />
                        </Col>
                    </Row>
                </Container>
            );
        }

        const NameVariants = () => {
            if (!this.state.editing) {
                if (this.state.row.name_variants.length === 0) {
                    return <div><i>None</i></div>
                }
                return <div style={{
                    lineHeight: 2,
                    lineBreak: "anywhere"
                }}>
                    {this.state.row.name_variants.map((value, index) => {
                        return <span key={`name-variant-${index}`} className="attr-legacy-value">{value}</span>
                    })}
                </div>
            }
            return (
                <Form.Control
                    type="text"
                    name="name_variants_ext"
                    value={this.state.row.name_variants_ext}
                    onChange={this.handleChange}
                />
            )
        },
            SKUPrefixes = () => {
                if (!this.state.editing) {
                    if (this.state.row.sku_prefixes.length === 0) {
                        return <div><i>None</i></div>
                    }
                    return <div style={{
                        lineHeight: 2,
                        lineBreak: "anywhere"
                    }}>
                        {this.state.row.sku_prefixes.map((value, index) => {
                            return <span key={`sku-prefix-${index}`} className="attr-legacy-value">{value}</span>
                        })}
                    </div>
                }
                return (
                    <Form.Control
                        type="text"
                        name="sku_prefixes_ext"
                        value={this.state.row.sku_prefixes_ext}
                        onChange={this.handleChange}
                    />
                )
            },
            SKUSuffixes = () => {
                if (!this.state.editing) {
                    if (this.state.row.sku_suffixes.length === 0) {
                        return <div><i>None</i></div>
                    }
                    return <div style={{
                        lineHeight: 2,
                        lineBreak: "anywhere"
                    }}>
                        {this.state.row.sku_suffixes.map((value, index) => {
                            return <span key={`sku-suffix-${index}`} className="attr-legacy-value">{value}</span>
                        })}
                    </div>
                }
                return (
                    <Form.Control
                        type="text"
                        name="sku_suffixes_ext"
                        value={this.state.row.sku_suffixes_ext}
                        onChange={this.handleChange}
                    />
                )
            };

        return (
            <Container className="pt-4">
                <Row>
                    <Col sm={8}>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={this.state.row.name}
                            readOnly={true}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Created</Form.Label>
                        <Form.Control
                            type="text"
                            name="created_at"
                            value={this.state.row.created_at}
                            disabled={true}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Modified</Form.Label>
                        <Form.Control
                            type="text"
                            name="updated_at"
                            value={this.state.row.updated_at}
                            disabled={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <Form.Label>Name Variants</Form.Label>
                        {NameVariants()}
                    </Col>
                    <Col sm={4}>
                        <Form.Label>SKU Prefixes</Form.Label>
                        {SKUPrefixes()}
                    </Col>
                    <Col sm={4}>
                        <Form.Label>SKU Suffixes</Form.Label>
                        {SKUSuffixes()}
                    </Col>
                </Row>

                <Row>
                    <Col sm={6}>
                        {!this.state.editing && (
                            <Button
                                variant="secondary"
                                onClick={this.startEdition}
                            >
                                Edit
                            </Button>
                        )}
                        {this.state.editing && (
                            <span>
                                <Button
                                    disabled={this.state.updating}
                                    variant="secondary"
                                    onClick={this.dropEdition}
                                >
                                    Cancel
                                </Button>
                                <ProcessingButton
                                    variant="primary"
                                    processing={this.state.updating}
                                    processingLabel="Saving ..."
                                    label="Save"
                                    onClick={this.saveChanges}
                                />
                            </span>
                        )}
                    </Col>
                </Row>
            </Container >
        );
    }
}

Profile.propTypes = {
    /** Brand ID */
    manufacturerId: PropTypes.number.isRequired,
};

export default Profile;
