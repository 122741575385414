import axios from "axios";

import Config from "./Config";
import Utils from "./Utils";
import Memory from "./MemoryService";
import APIHelper from "./APIHelper";

let cancels = {};
const WebsiteService = {
    typeahead(search) {
        if (typeof cancels['typeahead'] !== 'undefined') {
            cancels['typeahead'].cancel("Operation canceled due to new request.");
        }
        cancels['typeahead'] = axios.CancelToken.source();
        return axios
            .post(Config.API_URL + "website/search", {
                name: search,
            }, {
                cancelToken: cancels['typeahead'].token
            })
            .then(APIHelper.returnItemsOrEmptyArray)
            .catch((error) => {
                if (typeof error.message !== "undefined" && /Operation canceled/.test(error.message)) {
                    return null;
                }
                return [];
            });
    },

    get(id) {
        return axios
            .get(Config.API_URL + "website/" + id)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    simpleRow(id) {
        return axios
            .get(Config.API_URL + "website/" + id + '/basic')
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    dailyVolume(id, brand) {
        return axios
            .get(Config.API_URL + "website/" + id + "/daily/" + brand)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    merchant(id) {
        return axios
            .get(Config.API_URL + "website/" + id + "/merchant")
            .then(response => APIHelper.returnAttribute(response, 'merchant'))
            .catch(APIHelper.returnFalse);
    },
    brands(id) {
        return axios
            .get(Config.API_URL + "website/" + id + "/brands")
            .then(APIHelper.returnItems)
            .catch(APIHelper.returnFalse);
    },
    brandsMapped(id) {
        return axios
            .get(Config.API_URL + "website/" + id + "/brands/mapped")
            .then(APIHelper.returnItems)
            .catch(APIHelper.returnFalse);
    },
    update(row) {
        let payload = Object.assign({}, row);
        if (typeof payload.defaultCurrency !== "undefined" && payload.defaultCurrency.length) {
            payload.currency_id = payload.defaultCurrency[0].value;
            payload.currency = payload.defaultCurrency[0].label;
            delete payload.defaultCurrency;
        }

        if (typeof payload.supportedCurrencies !== "undefined" && payload.supportedCurrencies.length) {
            payload.currencies = [];
            payload.supportedCurrencies.forEach((obj) => {
                payload.currencies.push(obj.value);
            });
            delete payload.supportedCurrencies;
        }

        if (typeof payload.categories !== "undefined" && payload.categories.length) {
            let categories = [];
            payload.categories.forEach((obj) => {
                categories.push(obj.value);
            });
            payload.categories = categories;
        }

        if (typeof payload.sellingRegion !== "undefined" && payload.sellingRegion.length) {
            payload.country_id = payload.sellingRegion[0].value;
            delete payload.sellingRegion;
        }

        return axios
            .put(Config.API_URL + "website/" + row.id, payload)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    addCategories(id, categories) {
        return axios
            .post(Config.API_URL + "website/" + id + "/categories/add", { categories })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    updateSIM(id, lower, upper) {
        return axios
            .put(Config.API_URL + "website/" + id + "/sim", {
                upper: upper * 1, lower: lower * 1
            })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    getSIM(id) {
        return axios
            .get(Config.API_URL + "website/" + id + "/sim")
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    setURL(id, pid, url) {
        return axios
            .put(Config.API_URL + "website/" + id + "/url/" + pid, {
                url
            })
            .then((response) => {
                if (APIHelper.checkStatus(response)) {
                    return true;
                }
                return typeof response.data.error !== "undefined" ? response.data.error : false;
            })
            .catch(APIHelper.returnFalse);
    },
    removeURL(id, pid) {
        return axios
            .delete(Config.API_URL + "website/" + id + "/url/" + pid)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    removeURLs(id, pids) {
        return axios
            .post(Config.API_URL + "website/" + id + "/urls/remove", {
                pids
            })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    unblockByID(ids) {
        return axios
            .post(Config.API_URL + "website/release", {
                ids
            })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    unblock(id, pids = null) {
        const product_id = typeof pids === "number" ? [pids] : (pids === null ? [] : pids);
        return axios
            .post(Config.API_URL + "website/" + id + "/release", {
                product_id
            })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    summary(id) {
        return axios
            .get(Config.API_URL + "website/" + id + "/summary")
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    productsMapped(id) {
        return axios
            .get(Config.API_URL + "website/" + id + "/products")
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    productsDiscovery(id) {
        return axios
            .get(Config.API_URL + "website/" + id + "/discovery")
            .then(response => APIHelper.checkStatus(response) ? response.data : [])
            .catch(APIHelper.returnEmptyArray);
    },
    requestProductDiscovery(id, brand_id, mode) {
        return axios
            .post(Config.API_URL + "website/" + id + "/discovery", {
                brand_id,
                mode,
            })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    urls(id, brand_id) {
        let endpoint = "website/" + id + "/urls/" + brand_id,
            func = 'get',
            payload = {};
        if (typeof brand_id !== "number") {
            endpoint = "urls/search";
            func = 'post';
            payload = {
                website_id: id,
                brand_ids: brand_id
            };
        }
        return axios[func](Config.API_URL + endpoint, payload)
            .then((response) => {
                if (!APIHelper.checkStatus(response)) {
                    return false;
                }
                return Object.keys(payload).length ? response.data.items : response.data;
            })
            .catch(APIHelper.returnFalse);
    },
    costs(id) {
        return axios
            .get(Config.API_URL + "website/" + id + "/costs")
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    npd(id, mode = "simple") {
        return axios
            .get(Config.API_URL + "website/" + id + "/npd" + (mode === "simple" ? "" : "/" + mode))
            .then((response) => {
                if (APIHelper.checkStatus(response)) {
                    Utils.DownloadContent('npd-' + response.data.name + (mode === "simple" ? '' : ("-" + mode)) + '.csv', response.data.csv);
                    return true;
                }
                return false;
            })
            .catch(APIHelper.returnFalse);
    },
    metrics(id) {
        return axios
            .get(Config.API_URL + "website/" + id + "/metrics")
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    merchants(id) {
        return axios
            .get(Config.API_URL + "website/" + id + "/merchants")
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    /**
     * Retrieves and stores the Website name in memory for rendering the Breadcrumbs of /website
     * @param {int} id
     * @returns
     */
    breadcrumb(id) {
        return new Promise((resolve, reject) => {
            if (Memory.Has('website/' + id)) {
                resolve(Memory.Get('website/' + id));
            } else {
                axios
                    .get(Config.API_URL + "website/" + id + "/basic")
                    .then((response) => {
                        if (APIHelper.checkStatus(response)) {
                            Memory.Set('website/' + id, response.data.data);
                            resolve(response.data.data);
                        }
                        reject();
                    })
                    .catch((error) => {
                        reject();
                    });
            }
        });
    },

    crawlerDetails() {
        return axios
            .get(`${Config.API_URL}/crawler-details`)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },

    handleAddOrRemoveFromSelfHealingBlackList(websiteId, action) {
        return axios
            .post(`${Config.API_URL}self-healing/blacklist/${websiteId}/${action}`)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
};

export default WebsiteService;
