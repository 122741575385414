import axios from "axios";

import Config from "./Config";
import APIHelper from "./APIHelper";

const URLLookupService = {
    upload(items) {
        return axios
            .post(Config.API_URL + "/urls/upload", items)
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    check(items) {
        return axios
            .post(Config.API_URL + "/urls/check", items)
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    delete(items) {
        return axios
            .post(Config.API_URL + "/urls/delete", items)
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    purge(website_id, brand_id = 0) {
        return axios
            .delete(Config.API_URL + "/urls/" + website_id + '/' + brand_id)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    remove(id) {
        return axios
            .delete(Config.API_URL + "/url/" + id)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    set(id, url) {
        return axios
            .put(Config.API_URL + "/url/" + id, {
                url
            })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    addByProduct(id, urls) {
        return axios
            .post(Config.API_URL + "/product/" + id + "/urls", { urls })
            .then(APIHelper.returnItems)
            .catch(APIHelper.returnFalse);
    }
};

export default URLLookupService;
