import React from "react";

import { Carousel } from "react-bootstrap";
import Emitter from "../../services/Emitter";
import JobsList from "./JobsList";
import NewJob from "./NewJob";
import Job from "./Job";
import Fragment from "./Fragment";
import MyCarousel from "../../components/MyCarousel";

class Jobs extends React.Component {

    constructor(props) {
        super(props);
        this.last = -1;
        this.timer = null;
    }

    showPage(tab) {
        let key = 0,
            jobId = 0,
            fragId = 0;

        if (tab === "list") {
            this.last = 0;
            this.timer = null;
        } else if (tab === "new") {
            key = 3;
            this.last = 3;
            this.timer = null;
        } else {
            if (this.timer !== null) {
                window.clearTimeout(this.timer);
            }

            if (tab === 'job') {
                key = 1;
                this.timer = setTimeout(() => {
                    jobId = window.location.href.match(/job\/(\d+)$/)[1]
                    if (this.last !== 1) {
                        Emitter.emit("DD_JOB", jobId);
                        this.last = 1;
                        this.timer = null;
                    }
                }, 250);
            } else if (tab === 'fragment') {
                key = 2;
                this.timer = setTimeout(() => {
                    jobId = window.location.href.match(/job\/(\d+)\//)[1];
                    fragId = window.location.href.match(/job\/\d+\/(\d+)$/)[1];
                    if (this.last !== 2) {
                        Emitter.emit("DD_FRAGMENT", {
                            jobId,
                            fragId
                        });
                        this.last = 2;
                        this.timer = null;
                    }
                }, 250);
            }
        }

        return (
            <MyCarousel activeIndex={key}>
                <Carousel.Item key="dd.list" className="standardGrid">
                    <JobsList />
                </Carousel.Item>
                <Carousel.Item key="dd.job" className="standardGrid">
                    <Job />
                </Carousel.Item>
                <Carousel.Item key="dd.fragment" className="standardGrid">
                    <Fragment />
                </Carousel.Item>
                <Carousel.Item key="dd.new" className="standardGrid">
                    <NewJob />
                </Carousel.Item>
            </MyCarousel>
        );
    }

    render() {
        let activeKey = 'list';
        if (window.location.href.match(/\/new$/)) {
            activeKey = 'new';
        } else if (window.location.href.match(/job\/\d+$/)) {
            activeKey = 'job';
        } else if (window.location.href.match(/job\/\d+\/\d+$/)) {
            activeKey = 'fragment';
        }

        return this.showPage(activeKey);
    }
}
export default Jobs;
