import Emitter from "./Emitter";

/**
 * Factory class for handling Toast messages (that get sent to index.js)
 * @hideconstructor
 */
class Toast {
    /**
     * Show a neutral Toast
     * @param {string} title
     * @param {string} message
     * @param {integer} delay
     */
    static message(title, message, delay = 3000) {
        Emitter.emit("TOAST", {
            title,
            message,
            theme: "success",
            delay: delay,
        });
    }
    /**
     * Show an OK Toast
     * @param {string} title
     * @param {string} message
     * @param {integer} delay
     */
    static ok(message, delay = 3000) {
        Emitter.emit("TOAST", {
            title: "OK",
            message,
            theme: "success",
            delay: delay,
        });
    }
    /**
     * Show an Info Toast
     * @param {string} title
     * @param {string} message
     * @param {integer} delay
     */
    static info(message, delay = 3000) {
        Emitter.emit("TOAST", {
            title: "Info",
            message,
            theme: "warning",
            delay: delay,
        });
    }
    /**
     * Show an Error Toast
     * @param {string} title
     * @param {string} message
     * @param {integer} delay
     */
    static error(message, delay = 3000) {
        Emitter.emit("TOAST", {
            title: "ERROR",
            message,
            theme: "danger",
            delay: delay,
        });
    }
}

export default Toast;
