import React from "react";

import { Container, Card, Row, Col, InputGroup, FormControl, Button } from "react-bootstrap";
import Toast from "../../services/Toast";
import Emitter from "../../services/Emitter";
import Currencies from "../../components/Currencies";
import ProductService from "../../services/ProductService";
import "./Prices.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Loading from "../Loading";
import ProcessingButton from "../../components/ProcessingButton";


/**
 * Show a price editing page
 * @component
 * @category Scenes
 * @subcategory Products
 */
class Prices extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState();

        this.dropEdition = this.dropEdition.bind(this);
        this.startEdition = this.startEdition.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.reload = this.reload.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
        this.handleAddCurrency = this.handleAddCurrency.bind(this);
    }

    initialState() {
        return {
            rows: {},
            loading: true,
            editing: false,
            updating: false,
            canSave: true
        };
    }

    reload() {
        ProductService.prices(this.props.id).then((data) => {
            if (data === false) {
                return;
            }
            let rows = Object.values(data).map((row) => {
                let newObject = [];
                Object.keys(row.prices).forEach((currency_id) => {
                    currency_id = parseInt(currency_id);
                    let obj = row.prices[currency_id];
                    obj.currency_id = currency_id;
                    newObject.push(obj);
                });
                row.prices = newObject;
                return row;
            });
            this.setState({
                loading: false,
                editing: false,
                rows
            });
        });
    }

    componentWillUnmount() {
        Emitter.off("PRODUCT_PRICES");
    }

    componentDidMount() {
        Emitter.once("PRODUCT_PRICES", this.reload);
    }

    saveChanges() {

        this.setState({ updating: true });
        ProductService.updatePrices(this.props.id, this.state.rows).then((result) => {
            if (result === false) {
                this.setState({ updating: false, editing: true });
                Toast.error(
                    "Something wrong happened and changes could not be saved."
                );
            } else {
                this.setState({
                    updating: false,
                    editing: false,
                    canSave: true
                });
                delete this.original;
                Toast.ok("Changes properly saved.");
                Emitter.emit("PRODUCT_SUMMARY_RELOAD");
            }
        });
    }

    startEdition() {
        this.original = JSON.parse(JSON.stringify(this.state.rows));
        this.setState({ editing: true });
    }

    dropEdition() {
        this.setState({ editing: false, rows: JSON.parse(JSON.stringify(this.original)) });
        delete this.original;
    }

    handleDelete(evt) {
        const target = /svg/i.test(evt.target.nodeName) ? evt.target.parentNode : (/path/i.test(evt.target.nodeName) ? evt.target.parentNode.parentNode : evt.target),
            currency = parseInt(target.closest(".pricing-row").getAttribute("currency")),
            brand = parseInt(target.closest(".pricing-row").getAttribute("brand"));

        let rows = this.state.rows;
        rows[brand].prices.splice(currency, 1);
        this.setState({ rows, canSave: this.checkValid(rows) });
    }

    handleAddCurrency(evt) {
        const target = /svg/i.test(evt.target.nodeName) ? evt.target.parentNode : (/path/i.test(evt.target.nodeName) ? evt.target.parentNode.parentNode : evt.target),
            brand = parseInt(target.getAttribute("index"));
        let rows = this.state.rows;
        rows[brand].prices.push({
            currency_id: 0,
            1: 0,
            2: 0,
            3: 0
        })
        this.setState({ rows, canSave: this.checkValid(rows) });
    }

    handleCurrencyChange(newCurrency, target) {
        let rows = this.state.rows,
            indexes = target.split('-'),
            brand = parseInt(indexes[0]),
            currency = parseInt(indexes[1]);
        if (newCurrency.length === 1) {
            rows[brand].prices[currency].currency_id = parseInt(newCurrency[0].value);
        } else {
            rows[brand].prices[currency].currency_id = 0;
        }
        this.setState({ rows, canSave: this.checkValid(rows) })
    }

    handlePriceChange(evt) {
        const brand = parseInt(evt.target.closest(".pricing-row").getAttribute("brand")),
            currency = parseInt(evt.target.closest(".pricing-row").getAttribute("currency")),
            type_id = parseInt(evt.target.getAttribute('type_id'));

        let rows = this.state.rows;
        rows[brand].prices[currency][type_id] = evt.target.value;
        this.setState({ rows });
    }

    checkValid(rows) {
        for (let i = 0; i < rows.length; i++) {
            let row = rows[i],
                unique = [];
            for (let ii = 0; ii < row.prices.length; ii++) {
                let currency_id = row.prices[ii].currency_id;
                if (currency_id === 0) {
                    return false;
                }
                if (unique.indexOf(currency_id) === -1) {
                    unique.push(currency_id);
                } else {
                    return false;
                }
            }
        }
        return true;
    }

    render() {
        if (this.state.loading) {
            return <Loading />
        }
        return (
            <Container className="pt-4" style={{
                paddingBottom: "350px"
            }}>


                {this.state.rows.length === 0 && (
                    <Row><Col><i>Empty</i></Col></Row>
                )}
                {this.state.rows.map((row, i) => {
                    return <React.Fragment>
                        <Row>
                            <Col sm={10}><b>(#{row.brand.id}) {row.brand.name}</b></Col>
                            <Col sm={2}>
                                {this.state.editing &&
                                    <Button
                                        size="sm"
                                        variant="success"
                                        className="float-right"
                                        index={i}
                                        onClick={this.handleAddCurrency}
                                    >
                                        <FontAwesomeIcon icon={faPlus} /> Add currency
                                    </Button>
                                }
                            </Col>
                        </Row>
                        {row.prices.length === 0 && (
                            <Row><Col><i>Empty</i></Col></Row>
                        )}
                        {row.prices.map((row, ii) => {
                            return (
                                <Card>
                                    <Card.Body className="pricing-row" key={`row-${i}-${ii}`} brand={i} currency={ii}>
                                        <div>
                                            <InputGroup className="pricing-column">
                                                <InputGroup.Text>Currency</InputGroup.Text>
                                                <Currencies
                                                    name={`${i}-${ii}`}
                                                    singleValue={true}
                                                    disabled={!this.state.editing || this.state.updating}
                                                    readOnly={false}
                                                    value={row.currency_id ? Currencies.Selected([row.currency_id]) : []}
                                                    onChange={this.handleCurrencyChange}
                                                    className="form-group-pricing"
                                                />
                                            </InputGroup>
                                        </div>
                                        <div>
                                            <InputGroup className="pricing-column">
                                                <InputGroup.Text>Retail</InputGroup.Text>
                                                <FormControl
                                                    type="number"
                                                    value={row[3]}
                                                    readOnly={!this.state.editing || this.state.updating}
                                                    type_id={3}
                                                    onChange={this.handlePriceChange} />
                                            </InputGroup>
                                        </div>
                                        <div>
                                            <InputGroup className="pricing-column">
                                                <InputGroup.Text>MAP</InputGroup.Text>
                                                <FormControl
                                                    type="number"
                                                    value={row[1]}
                                                    readOnly={!this.state.editing || this.state.updating}
                                                    type_id={1}
                                                    onChange={this.handlePriceChange} />
                                            </InputGroup>
                                        </div>
                                        <div>
                                            <InputGroup className="pricing-column">
                                                <InputGroup.Text>Wholesale</InputGroup.Text>
                                                <FormControl
                                                    type="number"
                                                    value={row[2]}
                                                    readOnly={!this.state.editing || this.state.updating}
                                                    type_id={2}
                                                    onChange={this.handlePriceChange} />
                                            </InputGroup>
                                        </div>
                                        <div>
                                            {this.state.editing && (
                                                <Button
                                                    variant="danger"
                                                    className="btn tdButton"
                                                    onClick={this.handleDelete}
                                                    disabled={!this.state.editing || this.state.updating}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </Button>
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            )
                        })}
                    </React.Fragment>
                })}


                {this.state.editing && (
                    <Row>
                        <span>
                            <Button
                                disabled={this.state.updating}
                                variant="secondary"
                                onClick={this.dropEdition}
                            >
                                Cancel
                            </Button>
                            <ProcessingButton
                                variant="primary"
                                processing={this.state.updating}
                                processingLabel="Saving ..."
                                label="Save"
                                disabled={!this.state.canSave}
                                onClick={this.saveChanges}
                            />
                        </span>
                    </Row>
                )}
                {!this.state.editing && (
                    <Row>
                        <Col>
                            <Button
                                variant="secondary"
                                onClick={this.startEdition}
                            >
                                Edit
                            </Button>
                        </Col>
                    </Row>
                )}
            </Container >
        );
    }
}

export default Prices;
