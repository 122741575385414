import React from "react";
import { Modal, Button } from "react-bootstrap";
import { confirmable, createConfirmation } from "react-confirm";

class Confirm extends React.Component {
    render() {
        const {
            proceedLabel,
            cancelLabel,
            confirmation,
            show,
            proceed,
            options,
        } = this.props;
        const enableEscape = typeof options.enableEscape === "boolean" ? options.enableEscape : true,
            onDropReturnValue = typeof options.onDropReturn !== "undefined" ? options.onDropReturn : false,
            size = typeof options.size === "string" ? options.size : null;

        return (
            <Modal
                show={show}
                onHide={() => proceed(onDropReturnValue)}
                backdrop={enableEscape ? true : "static"}
                keyboard={enableEscape}
                size={size}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{confirmation}</Modal.Title>
                </Modal.Header>
                {typeof options != "undefined" &&
                    typeof options.message == "string" && (
                        <Modal.Body>{options.message}</Modal.Body>
                    )}
                <Modal.Footer>
                    {cancelLabel !== null && (
                        <Button
                            variant="secondary"
                            onClick={() => proceed(false)}
                            data-testid="confirm-btn-cancel"
                        >
                            {cancelLabel}
                        </Button>
                    )}
                    <Button
                        variant="primary"
                        onClick={() => proceed(true)}
                        data-testid="confirm-btn-ok"
                    >
                        {proceedLabel}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default function confirm(
    confirmation,
    proceedLabel = "OK",
    cancelLabel = "cancel",
    options = {}
) {
    return createConfirmation(confirmable(Confirm))({
        confirmation,
        proceedLabel,
        cancelLabel,
        options,
    });
}
