import React from "react";

import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Button,
    Form,
    OverlayTrigger,
    Popover,
    PopoverBody,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import AsinDiscoveryService from "../../services/AsinDiscoveryService";
import { withRouter } from "react-router-dom";
import Toast from "../../services/Toast";
import Emitter from "../../services/Emitter";
import ManufacturerSearch from "../../components/ManufacturerSearch";
import ProcessingButton from "../../components/ProcessingButton";
import Countries from "../../components/Countries";
import MySpinner from "../../components/MySpinner";
import ManufacturerService from "../../services/ManufacturerService";

/**
 * New Job component
 * @component
 * @category Scenes
 * @subcategory Asin Discovery
 */
class NewJob extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState();
        this.handleChangeManufacturer = this.handleChangeManufacturer.bind(this);
        this.handleChangeCountry = this.handleChangeCountry.bind(this);
        this.checkSummaryForCountries = this.checkSummaryForCountries.bind(this);
        this.typeahead = React.createRef();
        this.submit = this.submit.bind(this);
    }

    initialState() {
        return {
            updating: false,
            manufacturer: null,
            mode: "complete",
            newProducts: -1,
            allProducts: -1,
            noasinProducts: -1,
            canSubmit: false,
            countries: [],
            sellingCountries: [],
            loadingCountries: false,
            loadingSummary: false
        }
    }

    handleChangeManufacturer(text) {

        if (text.length) {
            this.setState({
                manufacturer: text[0],
                loadingCountries: true,
                canSubmit: false,

            });

            ManufacturerService.getTrackedCountries(text[0].id).then((result) => {
                if (result === false) {
                    Toast.error("Something went wrong while loading this manufacturer's settings");
                    return;
                }
                let countries = [];
                result.forEach((country_id) => {
                    countries.push({
                        label: Countries.GetByID(country_id).label,
                        value: country_id
                    });
                });
                this.setState({
                    sellingCountries: countries,
                    countries: countries,
                    loadingCountries: false
                }, this.checkSummaryForCountries);

            })

        } else {
            this.setState({
                manufacturer: null,
                allProducts: 0,
                newProducts: 0,
                noasinProducts: 0,
                canSubmit: false,
                loadingCountries: false
            });
        }
    }

    handleChangeCountry(selected) {
        this.setState({ countries: selected }, this.checkSummaryForCountries);
    }

    checkSummaryForCountries() {

        this.setState({
            allProducts: -1,
            newProducts: -1,
            noasinProducts: -1,
            canSubmit: false,
            loadingSummary: this.state.countries.length > 0
        });

        let countriesIds = [];
        this.state.countries.forEach((country) => {
            countriesIds.push(country.value);
        })

        if (!countriesIds.length) {
            return;
        }

        AsinDiscoveryService.summary(this.state.manufacturer.id, countriesIds).then((result) => {

            if (result === false) {
                Toast.error("Failed retrieving manufacturer's summary");
                return
            }

            let mode = this.state.mode;
            if (mode.length === 0) {
                mode = "complete";
            }
            if (mode === "recent" && !result.new) {
                if (result.total > 0) {
                    mode = "complete";
                } else {
                    mode = "";
                }
            } else if (mode === 'noasins' && !result.nonasins) {
                if (result.total > 0) {
                    mode = "complete";
                } else {
                    mode = "";
                }
            } else if (mode === "complete" && !result.total) {
                mode = "";
            }

            this.setState({
                allProducts: result.total,
                newProducts: result.new,
                noasinProducts: result.noasins,
                canSubmit: result.total + result.new + result.noasins > 0,
                mode,
                loadingSummary: false
            });
        });

    }

    submit() {
        this.setState({
            updating: true,
        });
        let payload = {
            manufacturer_id: this.state.manufacturer.id,
            mode: this.state.mode,
            countries: this.state.countries.map((country) => country.value)
        };

        AsinDiscoveryService.request(payload).then((result) => {
            if (result) {
                this.setState(this.initialState(),
                    () => {
                        this.props.history.push(`/ad/jobs/#list`);
                        this.typeahead.current.clear();
                    }
                );
                setTimeout(() => {
                    Emitter.emit("RELOAD_AD_JOBS");
                }, 500);
            } else {
                Toast.error(
                    "Something went wrong while registering this new job."
                );
                this.setState({
                    updating: false,
                });
            }
        });
    }

    render() {
        const labelAllProducts = "All products" + (this.state.allProducts !== -1 ? " (" + this.state.allProducts + " product" + (this.state.allProducts !== 1 ? "s" : '') + ")" : '')
        let labelRecentlyAdded = "Recently added products only" + (this.state.newProducts !== -1 ? " (" + this.state.newProducts + " product" + (this.state.newProducts !== 1 ? "s" : '') + ")" : '')
        const labelNoASINs = 'Products with no ASIN' + (this.state.noasinProducts !== -1 ? " (" + this.state.noasinProducts + " product" + (this.state.noasinProducts !== 1 ? "s" : '') + ")" : '')
        labelRecentlyAdded = (
            <OverlayTrigger
                placement="bottom"
                trigger={["hover", "focus"]}
                overlay={
                    <Popover>
                        <PopoverBody>Only products added in the last week</PopoverBody>
                    </Popover>
                }>
                <span>{labelRecentlyAdded}</span>
            </OverlayTrigger>
        )
        return (
            <Container className="pt-3 dd">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>ASIN Discovery</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="#list">
                            Jobs
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>New</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <Col
                        sm={2}
                        className="d-flex align-items-center justify-content-end text-center"
                    >
                        Manufacturer
                    </Col>
                    <Col sm={7}>
                        <ManufacturerSearch ref={this.typeahead} onChange={this.handleChangeManufacturer} />
                    </Col>
                    <Col sm={3}>
                        <Button
                            disabled={this.state.updating}
                            variant="secondary"
                            as={Link}
                            to="#list"
                        >
                            Cancel
                        </Button>
                        <ProcessingButton
                            processing={this.state.updating}
                            processingLabel="Saving ..."
                            label="Request"
                            onClick={this.submit}
                            disabled={!this.state.canSubmit}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col
                        sm={2}
                        className="d-flex align-items-center justify-content-end text-center"
                    >
                        Countries
                    </Col>
                    <Col sm={7}>
                        <Countries
                            disabled={this.state.loadingCountries}
                            singleValue={false}
                            name="countries"
                            value={this.state.countries}
                            options={this.state.sellingCountries}
                            onChange={this.handleChangeCountry} />
                    </Col>
                </Row>
                <Row>
                    <Col
                        sm={2}
                        className="d-flex align-items-center justify-content-end text-center"
                    >
                        Mode
                    </Col>
                    <Col sm={6}>
                        <Form.Check
                            disabled={this.state.allProducts === 0 || this.state.countries.length === 0 || !this.state.canSubmit}
                            checked={this.state.mode === 'complete'}
                            name="mode"
                            type="radio"
                            onClick={() => { this.setState({ mode: 'complete' }) }}
                            label={labelAllProducts} />
                        <Form.Check
                            disabled={this.state.newProducts === 0 || this.state.countries.length === 0 || !this.state.canSubmit}
                            checked={this.state.mode === 'recent'}
                            onClick={() => { this.setState({ mode: 'recent' }) }}
                            name="mode"
                            type="radio"
                            label={labelRecentlyAdded} />
                        <Form.Check
                            disabled={this.state.noasinProducts === 0 || this.state.countries.length === 0 || !this.state.canSubmit}
                            checked={this.state.mode === 'noasins'}
                            onClick={() => { this.setState({ mode: 'noasins' }) }}
                            name="mode"
                            type="radio"
                            label={labelNoASINs} />

                    </Col>
                    {(this.state.loadingSummary || this.state.loadingCountries) && (
                        <Col
                            sm={1}
                            className="d-flex align-items-center justify-content-center text-center"
                        >
                            <MySpinner />
                        </Col>
                    )}
                </Row>
            </Container>
        );
    }
}

export default withRouter(NewJob);
