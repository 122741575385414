import React from "react";

import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Button,
    Form,
    Dropdown,
    Card,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import DomainDiscoveryService from "../../services/DomainDiscoveryService";
import { withRouter } from "react-router-dom";
import Toast from "../../services/Toast";
import Emitter from "../../services/Emitter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faStar, faTrash } from "@fortawesome/free-solid-svg-icons";
import ManufacturerSearch from "../../components/ManufacturerSearch";
import ProcessingButton from "../../components/ProcessingButton";
import DropDown from "../../components/DropDown";


/**
 * New Job component
 * @component
 * @category Scenes
 * @subcategory Domain Discovery
 */
class NewJob extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState();
        this.handleManufacturerChange = this.handleManufacturerChange.bind(this);
        this.submit = this.submit.bind(this);
        this.typeahead = React.createRef();
        this.canSubmit = this.canSubmit.bind(this);
        this.autogenerate = this.autogenerate.bind(this);
    }

    initialState() {
        return {
            updating: false,
            canSubmit: false,
            manufacturer: null,
            pages: 20,
            engines: { google: "" },
            availableEngines: { google: "Google Search", bing: "Microsoft Bing", "google-shopping": "Google Shopping" },
            generating: false
        };
    }

    handleManufacturerChange(text) {
        this.setState({
            manufacturer: text.length === 0 ? null : text[0],
            canSubmit: this.canSubmit(this.state.engines)
        });
    }

    canSubmit(engines) {
        if (this.state.manufacturer === null) {
            return false;
        }
        let content = 0,
            k = Object.keys(engines);

        for (let i = 0; i < k.length; i++) {
            if (engines[k[i]].trim().length > 0) {
                content++;
                break;
            }
        }

        return content > 0;
    }

    autogenerate() {
        this.setState({
            generating: true,
        });
        DomainDiscoveryService.autogenerate(this.state.manufacturer.id).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while generating keywords automatically");
                this.setState({ generating: false });
                return;
            }
            Toast.ok("Keywords generated successfully");
            let engines = {};
            Object.keys(result).forEach((engine) => {
                engines[engine] = Object.values(result[engine]).join('\n');
            })
            this.setState({ generating: false, engines, canSubmit: this.canSubmit(engines) });
        });
    }

    submit() {
        this.setState({
            updating: true,
        });
        DomainDiscoveryService.request(this.state.manufacturer.id, {
            engines: this.state.engines,
            pages: this.state.pages
        }).then((result) => {
            if (result) {
                this.setState(this.initialState(),
                    () => {
                        this.props.history.push(`/dd`);
                        this.typeahead.current.clear();
                    }
                );
                setTimeout(() => {
                    Emitter.emit("RELOAD_DD_JOBS");
                }, 500);
            } else {
                Toast.error(
                    "Something went wrong while registering this new job."
                );
                this.setState({
                    updating: false,
                });
            }
        });
    }

    render() {
        return (
            <Container className="pt-3 dd">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Domain Discovery</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/dd">
                            Jobs
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>New</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <Col
                        sm={2}
                        className="d-flex align-items-center justify-content-end text-center"
                    >
                        Manufacturer
                    </Col>
                    <Col sm={10}>
                        <ManufacturerSearch ref={this.typeahead} onChange={this.handleManufacturerChange} />
                    </Col>
                </Row>
                <Row>
                    <Col sm={2} className="d-flex align-items-center justify-content-end text-center">Number of pages</Col>
                    <Col sm={1}>
                        <DropDown
                            allowEmpty={false}
                            selected={this.state.pages}
                            options={[5, 15, 20, 30, 50]}
                            onChange={(evt) => { this.setState({ pages: evt.target.value }) }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={2} className="d-flex align-items-center justify-content-end text-center">Engines</Col>
                    <Col sm={10}>
                        <Row>
                            {Object.keys(this.state.engines).length === 0 && (
                                <i>None</i>
                            )}

                            {Object.keys(this.state.engines).map((engine) => {
                                const entry = this.state.engines[engine],
                                    label = engine === 'google' ? 'Google Search' : (engine === 'google-shopping' ? 'Google Shopping' : 'Microsoft Bing'),
                                    remove = () => {
                                        let engines = this.state.engines;
                                        delete engines[engine];
                                        this.setState({ engines, canSubmit: this.canSubmit(engines) });
                                    }
                                return (
                                    <Col sm={6} style={{
                                        marginBottom: "25px"
                                    }}>
                                        <Card key={`card-${engine}`}>
                                            <Card.Header>
                                                <div style={{
                                                    display: "inline-flex",
                                                    width: "100%"
                                                }}>
                                                    <span style={{
                                                        marginRight: "auto",
                                                        marginTop: "auto",
                                                        marginBottom: "auto"
                                                    }}><FontAwesomeIcon icon={faCog} /> {label}</span>
                                                    <span>
                                                        <Button size="sm" variant="outline-danger" className="tdButton" onClick={remove}>
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </Button>
                                                    </span>
                                                </div>
                                            </Card.Header>
                                            <Card.Body>
                                                Keywords
                                                <Form.Control
                                                    key={`keywords-${engine}`}
                                                    as="textarea"
                                                    value={entry}
                                                    onChange={(evt) => {
                                                        let engines = this.state.engines;
                                                        engines[engine] = evt.target.value;
                                                        this.setState({ engines, canSubmit: this.canSubmit(engines) });
                                                    }}
                                                    style={{
                                                        height: "200px",
                                                    }}
                                                />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex align-items-center justify-content-center text-center">

                        <Button
                            disabled={this.state.updating}
                            variant="secondary"
                            onClick={() => {
                                this.props.history.push("/dd/jobs");
                            }}
                        >
                            Cancel
                        </Button>
                        {Object.keys(this.state.availableEngines).length !== Object.keys(this.state.engines).length && (
                            <Dropdown>
                                <Dropdown.Toggle
                                    id="dropdown-basic"
                                    variant="success"
                                >
                                    Add Engine
                                </Dropdown.Toggle>
                                <Dropdown.Menu>

                                    {Object.keys(this.state.availableEngines).map((engine) => (
                                        typeof this.state.engines[engine] === "undefined" && (
                                            <Dropdown.Item onClick={() => {
                                                let engines = this.state.engines;
                                                engines[engine] = "";
                                                this.setState({ engines });
                                            }}>
                                                {this.state.availableEngines[engine]}
                                            </Dropdown.Item>
                                        )
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                        <ProcessingButton
                            variant="dark"
                            processing={this.state.generating}
                            processingLabel="Generating keywords ..."
                            icon={faStar}
                            label="Fulfill Keywords Automatically"
                            onClick={this.autogenerate}
                            disabled={this.state.manufacturer === null}
                        />
                        <ProcessingButton
                            variant="primary"
                            processing={this.state.updating}
                            processingLabel="Saving ..."
                            label="Request"
                            onClick={this.submit}
                            disabled={!this.state.canSubmit}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withRouter(NewJob);
