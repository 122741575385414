import React, { createRef } from "react";

import { Container, Button, Row, Col } from "react-bootstrap";
import Toast from "../../services/Toast";
import "../../../node_modules/currency-flags/dist/currency-flags.min.css";
import LogsService from "../../services/LogsService";
import { faDownload, faExclamationTriangle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.css";
import { withRouter } from "react-router-dom";
import MyTable from "../../components/MyTable";
import prompt from "../../components/Prompt";
import Utils from "../../services/Utils";

/**
 * Logs component
 * @component
 * @category Scenes
 * @subcategory Logs
 */
class Logs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: null,
            loading: true,
            source: ''
        }
        this.setMode = this.setMode.bind(this);
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
        this.tableRef = createRef();
    }

    componentDidMount() {
        this.setMode();
    }

    downloadSpreadsheet() {
        this.tableRef.current.CSV({
            filename: 'logs-' + this.state.source + '-' + Utils.DateNow().replaceAll(' ', '-') + ".csv"
        })
    }

    setMode() {
        let mode = null,
            source = '';
        if (/logs\/users$/.test(window.location.href)) {
            mode = 'users';
            source = 'user';
        } else if (/logs\/system$/.test(window.location.href)) {
            mode = 'system';
            source = 'system';
        } else if (/logs\/crawlers$/.test(window.location.href)) {
            mode = 'crawlers';
            source = 'crawler';
        } else if (/logs\/crawlers\/summary$/.test(window.location.href)) {
            mode = 'crawlers/summary';
            source = 'crawler';
        } else if (/logs\/search$/.test(window.location.href)) {
            source = 'search';
            mode = this.state.mode;
        }

        this.setState({ mode, source, loading: true }, () => {
            this.tableRef.current.setLoading(true);
            const func = source === 'search' ? LogsService.search : LogsService.recent;
            func(mode).then((data) => {
                this.setState({ loading: false })
                if (data === false) {
                    Toast.error("Something went wrong while retrieving logs");
                    return;
                }
                this.tableRef.current?.populate(data);
            });
        })
    }

    render() {
        const action = (evt) => {
            const mode = evt.target.getAttribute('mode');
            this.props.history.push('/logs/' + mode)
            this.setMode();
        }
        return (
            <Container className="pt-4">
                <Row>
                <Col>
                    Display:
                    <span className="filters">
                        <Button mode="all" onClick={action} disabled={this.state.loading || this.state.mode === null} size="sm" variant={this.state.mode === null ? 'primary' : 'outline-primary'}>All</Button>
                        <Button mode="system" onClick={action} disabled={this.state.loading || this.state.mode === 'system'} variant={this.state.mode === 'system' ? 'primary' : 'outline-primary'} size="sm">System</Button>
                        <Button mode="users" onClick={action} disabled={this.state.loading || this.state.mode === 'users'} size="sm" variant={this.state.mode === 'users' ? 'primary' : 'outline-primary'}>Users</Button>
                        <Button mode="crawlers" onClick={action} disabled={this.state.loading || this.state.mode === 'crawlers'} size="sm" variant={this.state.mode === 'crawlers' ? 'primary' : 'outline-primary'}>Crawlers</Button>
                        <Button mode="crawlers/summary" onClick={action} disabled={this.state.loading || this.state.mode === 'crawlers/summary'} variant={this.state.mode === 'crawlers/summary' ? 'primary' : 'outline-primary'} size="sm">Crawlers - Summary</Button>
                    </span>
                </Col>
                <Col sm={4} className="text-right">
                    <Button
                        disabled={this.state.loading}
                        onClick={async () => {
                            let search = await prompt('Enter search', 'Search', 'Cancel');
                            if (search.value.trim().length) {
                                this.setState({
                                    mode: search.value
                                }, () => {
                                    this.props.history.push('/logs/search')
                                    this.setMode();
                                })
                            }
                        }}
                        variant="primary"
                        size="sm"
                    >Extended search</Button>
                    <Button
                        size="sm"
                        className="tdButton"
                        variant="success"
                        onClick={this.downloadSpreadsheet}
                        disabled={this.state.loading}
                    >
                        <FontAwesomeIcon icon={faDownload} style={{
                            marginRight: "5px"
                        }} />
                        Download
                    </Button>
                </Col>
                </Row>
                <MyTable
                    name="logs"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="datetime"
                    sortOrder="desc"
                    headers={
                        [
                            {
                                field: "datetime",
                                label: "Datetime",
                                width: "140px",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "source",
                                label: "Source",
                                width: "160px",
                                sortable: true,
                                allowEmpty: false,
                                options: [{ label: 'All', value: '' }, { label: 'Users', value: 'user' }, { label: 'Crawlers', value: 'crawl' }, { label: 'System', value: 'system' }]
                            },
                            {
                                field: "level",
                                label: "Severity",
                                width: "80px",
                                sortable: true,
                                allowEmpty: true,
                                options: [{ label: 'Info', value: 'i' }, { label: 'Warning', value: 'w' }, { label: 'Error', value: 'e' }]
                            },
                            {
                                field: "message",
                                label: "Message",
                                sortable: true,
                                searchable: true
                            }
                        ]}

                    renderColumns={{
                        source: {
                            csv: (row) => row.source !== "system" ? row.source + '(' + row.user + ')' : row.source,
                            format: (row) => row.source !== "system" ? row.source + '(' + row.user + ')' : row.source,
                        },
                        level: {
                            format: (row) => {
                                let icon, color;
                                switch (row.level) {
                                    case 'error':
                                        icon = faExclamationTriangle;
                                        color = 'red';
                                        break;
                                    case 'warning':
                                        icon = faExclamationTriangle;
                                        color = 'orange';
                                        break;
                                    default:
                                        icon = faInfoCircle;
                                        color = 'navy';
                                        break;
                                }
                                return <FontAwesomeIcon icon={icon} style={{
                                    color,
                                }} />
                            },
                            className: "text-center"
                        }
                    }}
                />
            </Container >
        );
    }
}

export default withRouter(Logs);
