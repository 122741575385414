import React, { createRef } from "react";
import PropTypes from "prop-types";
import WebsiteService from "../services/WebsiteService";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

/**
 * Typeahead component for searching for Websites
 * @component
 */
class WebsiteSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        }
        this.ref = createRef();
    }

    handleSearch = (query) => {
        this.setState({
            isLoading: true,
        });
        WebsiteService.typeahead(query).then((options) => {
            if (options === null) {
                // this request was canceled due to a change in the typeahead component
                return;
            }
            const list = this.props.skip.length > 0
                ? options.filter((row) => this.props.skip.indexOf(row.id) === -1)
                : options;

            this.setState({
                options: list,
                isLoading: false,
            });
        });
    };

    clear = () => {
        this.ref.current.clear();
    }

    render() {
        const filterBy = () => true;

        return <AsyncTypeahead
            {...this.state}
            filterBy={filterBy}
            labelKey="display_name"
            ref={this.ref}
            id="website-search"
            maxResults={10}
            minLength={2}
            disabled={this.props.disabled}
            defaultSelected={this.props.defaultSelected}
            defaultInputValue={this.props.defaultInputValue}
            onSearch={this.handleSearch}
            onChange={this.props.onChange}
            placeholder={this.props.placeholder}
            renderMenuItemChildren={(option, props) => (
                <div
                    key={`m${option.id}`}
                    className={`websiteActive${option.is_active}`}
                >
                    <span className="colHead">id:</span>
                    <span className="colValue">{option.id}</span>
                    <span className="colHead">name:</span>
                    <span className="colValue">{option.name}</span>
                    <span className="colHead">hostname:</span>
                    <span className="colValue">
                        {option.display_name}
                    </span>
                </div>
            )}
            useCache={false}
        />
    }
}

WebsiteSearch.propTypes = {
    /**  */
    onChange: PropTypes.func.isRequired,
    skip: PropTypes.arrayOf(PropTypes.number),
    placeholder: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired
};

WebsiteSearch.defaultProps = {
    disabled: false,
    onChange: (values) => {
        console.log("Website has changed", values);
    },
    skip: [],
    placeholder: "Search for a website..."
};

export default WebsiteSearch;
