import axios from "axios";

import Config from "./Config";
import APIHelper from "./APIHelper";

let cancels = {};
const OrganizationService = {
    create(name) {
        return axios
            .post(Config.API_URL + "organization", { name })
            .then(response => APIHelper.returnAttribute(response, 'id'))
            .catch(APIHelper.returnFalse);
    },
    all() {
        return axios
            .get(Config.API_URL + "organization")
            .then(APIHelper.returnItems)
            .catch(APIHelper.returnFalse);
    },
    get(id) {
        return axios
            .get(Config.API_URL + "organization/" + id)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    name(id) {
        return axios
            .get(Config.API_URL + "organization/" + id + "/name")
            .then(response => APIHelper.returnAttribute(response, 'name'))
            .catch(APIHelper.returnFalse);
    },
    typeahead(search) {
        if (typeof cancels['typeahead'] !== 'undefined') {
            cancels['typeahead'].cancel("Operation canceled due to new request.");
        }
        cancels['typeahead'] = axios.CancelToken.source();
        return axios
            .post(Config.API_URL + "organization/search", {
                name: search,
            }, {
                cancelToken: cancels['typeahead'].token
            })
            .then(APIHelper.returnItemsOrEmptyArray)
            .catch((error) => {
                if (typeof error.message !== "undefined" && /Operation canceled/.test(error.message)) {
                    return null;
                }
                return [];
            });
    },
    update(id, row) {
        return axios
            .post(Config.API_URL + "organization/" + id, row)
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    brands(id) {
        return axios
            .get(Config.API_URL + "organization/" + id + "/brands")
            .then(response => APIHelper.returnAttribute(response, 'brands'))
            .catch(APIHelper.returnFalse);
    },
    newBrand(id, name) {
        return axios
            .post(Config.API_URL + "organization/" + id + "/brands", {
                name
            })
            .then(response => APIHelper.returnAttribute(response, 'id'))
            .catch(APIHelper.returnFalse);
    },
};

export default OrganizationService;
