import React, { createRef } from "react";
import PropTypes from "prop-types";

import { Container, Button, Spinner } from "react-bootstrap";
import WebsiteService from "../../services/WebsiteService";
import BrandService from "../../services/BrandService";
import "../../../node_modules/currency-flags/dist/currency-flags.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import Emitter from "../../services/Emitter";
import Toast from "../../services/Toast";
import MyTable from "../../components/MyTable";
import confirm from "../../components/Confirm";
import { Link } from "react-router-dom";
import { Menu, MenuItem } from "@szhsin/react-menu";
import Utils from "../../services/Utils";

/**
 * Product Discovery component
 * @component
 * @category Scenes
 * @subcategory Brands
 */
class ProductDiscovery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.brandId,
            total: 0,
            requesting: false,
            modes: []
        };
        this.onEnter = this.onEnter.bind(this);
        this.onRequestExecution = this.onRequestExecution.bind(this);
        this.onRequestFullExecution = this.onRequestFullExecution.bind(this);
        this.tableRef = createRef();
    }

    componentWillUnmount() {
        Emitter.off("BRAND_DISCOVERY");
    }

    componentDidMount() {
        Emitter.once("BRAND_DISCOVERY", this.onEnter);
    }

    onEnter() {
        BrandService.productsDiscovery(this.state.id).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while retrieving list");
                return;
            }
            let list = [],
                modes = {};
            data.rows.forEach((row) => {
                row.date = row.date ?? "";
                row.status = row.status ?? "";
                row.mode = row.mode ?? "";
                row.modes = row.modes ?? "";
                row.count = row.count ?? "";
                row.support = [];
                row.modes.split('+').forEach((mode) => {
                    row.support.push(mode);
                });
                modes[row.mode] = true;
                delete(row.modes);
                list.push(row);
            });

            this.setState({
                total: data.rows.length,
                modes: Object.keys(modes)
            });
            this.tableRef.current?.populate(list);
        });
    }

    async onRequestExecution(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt),
            mode = obj.target.getAttribute("mode")
                ?? obj.target.closest("button").getAttribute("mode")
                ?? obj.row.support[0];

        if (
            !(await confirm("Are you sure?", "Continue", "Cancel", {
                message:
                    "You're about to request a new Product Discovery execution on website \"" +
                    obj.row.name +
                    '". Do you want to continue?',
            }))
        ) {
            return;
        }

        this.tableRef.current.setLoading();
        WebsiteService.requestProductDiscovery(
            obj.row.id,
            this.state.id,
            mode
        ).then((result) => {
            if (result) {
                Toast.ok("Execution requested successfully");
                this.onEnter();
            } else {
                Toast.error("Something went wrong while requesting execution");
                this.tableRef.current.setLoading(false);
            }
        });
    }

    async onRequestFullExecution(evt) {
        if (
            !(await confirm("Are you really, really sure?", "Continue", "Cancel", {
                message:
                    "You're about to request Product Discovery executions on " +
                    this.state.total +
                    ' website' + (this.state.total > 1 ? 's' : '') + '. NPD websites will be ignored as they can\'t be triggered per brand. Do you want to continue?',
            }))
        ) {
            return;
        }

        this.tableRef.current.setLoading();
        this.setState({
            requesting: true
        });
        WebsiteService.requestProductDiscovery(
            0,
            this.state.id,
            'any'
        ).then((result) => {
            if (result) {
                Toast.ok("Executions requested successfully");
                this.onEnter();
            } else {
                Toast.error("Something went wrong while requesting executions");
                this.tableRef.current.setLoading(false);
            }
            this.setState({ requesting: false });
        });
    }

    render() {
        if (this.state.disabled) {
            return (
                <Container className="pt-4">
                    This website is not semi-automatic.
                </Container>
            );
        }
        return (
            <Container className="pt-4" >
                <MyTable
                    name="brand-product-discovery"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="date"
                    sortOrder="desc"
                    headers={
                        [
                            {
                                field: "name",
                                label: "Website",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "date",
                                label: "Date",
                                width: "150px",
                                sortable: true,
                                searchable: true,
                            },
                            {
                                field: "status",
                                label: "Status",
                                width: "150px",
                                sortable: true,
                                allowEmpty: true,
                                options: [{ label: 'Requested', value: 'requested' }, { label: 'Scheduled', value: 'scheduled' }, { label: 'Crawled', value: 'crawled' }, { label: 'Executed', value: 'executed' }]
                            },
                            {
                                field: "mode",
                                label: "Mode",
                                width: "150px",
                                sortable: true,
                                allowEmpty: true,
                                options: this.state.modes
                                    .filter((mode) => mode.length > 0)
                                    .map((mode) => { return { label: mode.toUpperCase(), value: mode } })
                            },
                            {
                                field: "count",
                                label: "Count",
                                width: "150px",
                                sortable: true,
                            },
                            {
                                field: "control",
                                width: "50px",
                                content:
                                    !this.state.requesting ? (
                                        <Button
                                            size="sm"
                                            className="tdButton"
                                            variant="danger"
                                            onClick={this.onRequestFullExecution}
                                        >
                                            <FontAwesomeIcon icon={faPlay} />
                                        </Button>
                                    ) : (
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                        </Spinner >

                                    )
                            }
                        ]
                    }
                    renderColumns={{
                        name: (row) => <Link className="link" to={`/website/${row.id}`}>{`(#${row.id}) ${row.name}`}</Link>,
                        status: (row) => Utils.Capitalize(row.status),
                        mode: (row) => row.mode.toUpperCase(),
                        control: (row) => {

                            if (row.support.length > 1) {
                                return (
                                    <Menu
                                        direction="left"
                                        style={{
                                            fontSize: "medium"
                                        }}
                                        menuButton={
                                            <Button
                                                size="sm"
                                                className="tdButton"
                                                variant="outline-secondary"
                                            >
                                                <FontAwesomeIcon icon={faPlay} />
                                            </Button>}>
                                        {
                                            row.support.map((mode) => {
                                                return (
                                                    <MenuItem key={`${row.id}-${mode}`} mode={mode}
                                                        onClick={this.onRequestExecution}>
                                                        {mode.toUpperCase()}
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                    </Menu>
                                );
                            }

                            return (
                                <Button
                                    size="sm"
                                    className="tdButton"
                                    variant="outline-secondary"
                                    onClick={this.onRequestExecution}
                                >
                                    <FontAwesomeIcon icon={faPlay} />
                                </Button>
                            );
                        }
                    }}

                />
            </Container >
        );
    }
}

ProductDiscovery.propTypes = {
    /** Brand ID */
    brandId: PropTypes.number.isRequired,
};

export default ProductDiscovery;
