import React, { createRef } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Breadcrumb, Container, Button, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CostExplorerService from "../../services/CostExplorerService";
import Toast from "../../services/Toast";
import MyTable from "../../components/MyTable";

/**
 * @component
 * @category Scenes
 * @subcategory CostExplorer
 */
class Bills extends React.Component {
    constructor(props) {
        super(props);
        const type = window.location.href.match(/week$/) ? 'week' : 'month';
        this.tableRef = createRef();
        this.state = {
            type,
            groups: [],
            sources: [],
            periods: [],
            dates: [],
            sortBy: '',
            topRow: null,
            breadCrumb: type === 'week' ? 'Bills of Last Week' : 'Bills of Last Semester'
        };
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
        this.updateTopRow = this.updateTopRow.bind(this);
    }

    componentDidMount() {
        CostExplorerService.bills(this.state.type).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of bills");
                return;
            }
            const periods = data.dates.map(date => ({
                field: date,
                label: date,
                sortable: true,
                width: "100px"
            })),
                rows = [],
                groups = {},
                sources = {};

            data.items.forEach(asset => {
                const row = {
                    asset_id: asset.id,
                    name: asset.name,
                    group: asset.group,
                    settings: asset.billing_settings,
                    source: asset.source,
                    description: asset.description,
                };
                Object.keys(asset.bills).forEach(period => {
                    row[period] = asset.bills[period];
                });
                rows.push(row);
                groups[asset.group] = true;
                sources[asset.source] = true;
            });

            this.setState({
                dates: data.dates,
                groups: Object.keys(groups),
                sources: Object.keys(sources),
                periods,
                sortBy: data.dates[data.dates.length - 1],
                topRow: this.polishTopRow(rows, data.dates),
            }, () => {
                this.tableRef.current?.resetSettings();
                this.tableRef.current?.populate(rows);
            })
        });
    }

    downloadSpreadsheet() {
        this.tableRef.current.CSV();
    }

    polishTopRow(rows, dates = null) {
        const _dates = dates ?? this.state.periods.map(period => period.field),
            topRow = {
                name: '',
                description: '',
            };

        rows.forEach(row => {
            _dates.forEach(date => {
                if (topRow[date] === undefined) {
                    topRow[date] = 0;
                }
                topRow[date] += row[date];
            });
        });

        _dates.forEach(date => {
            if (topRow[date] === undefined) {
                topRow[date] = 0;
            }
            topRow[date] = topRow[date].toFixed(3);
        });
        return topRow;
    }

    updateTopRow(rows) {
        this.setState({
            topRow: this.polishTopRow(rows)
        });
    }

    render() {
        return (

            <Container className="pt-4">
                <span style={{
                    float: "right"
                }}
                >
                    <Button
                        size="sm"
                        className="tdButton"
                        variant="success"
                        label="Download CSV"
                        onClick={this.downloadSpreadsheet}
                    >
                        <FontAwesomeIcon icon={faDownload} /> Download
                    </Button>
                </span>
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>CostExplorer</Breadcrumb.Item>
                    <Breadcrumb.Item active>{this.state.breadCrumb}</Breadcrumb.Item>
                </Breadcrumb>
                <MyTable
                    name="bills"
                    dynamic={false}
                    ref={this.tableRef}
                    topRow={this.state.topRow}
                    sortBy={this.state.sortBy}
                    callbackFiltering={this.updateTopRow}
                    sortOrder="desc"
                    headers={
                        [
                            {
                                field: "asset_id",
                                label: "ID",
                                sortable: true,
                                searchable: true,
                                width: "60px",
                            },
                            {
                                field: "name",
                                label: "Name",
                                sortable: true,
                                searchable: true,
                                width: "200px",
                            },
                            {
                                field: "group",
                                label: "Group",
                                sortable: true,
                                allowEmpty: true,
                                options: this.state.groups,
                            },
                            {
                                field: "source",
                                label: "Billing",
                                sortable: true,
                                allowEmpty: true,
                                options: this.state.sources,
                            },
                            ...this.state.periods,
                        ]
                    }
                    renderColumns={{
                        source: {
                            format: (row) => (<OverlayTrigger
                                placement="top"
                                trigger={["hover", "focus"]}
                                overlay={
                                    <Popover>
                                        <PopoverBody style={{
                                            fontFamily: "monospace",
                                            fontSize: "smaller",
                                            backgroundColor: "beige"
                                        }}>{JSON.stringify(row.settings)}</PopoverBody>
                                    </Popover>
                                }
                            >
                                <span>{row.source}</span>
                            </OverlayTrigger>)
                        },
                        name: {
                            format: (row) => row.description.length
                                ? <OverlayTrigger
                                    placement="top"
                                    trigger={["hover", "focus"]}
                                    overlay={
                                        <Popover style={{ backgroundColor: "beige" }}>
                                            <PopoverBody>{row.description}</PopoverBody>
                                        </Popover>
                                    }
                                >
                                    <span style={{
                                        textDecoration: "underline",
                                        cursor: "pointer"
                                    }}>{row.name}</span>
                                </OverlayTrigger>
                                : row.name
                        }
                    }} />
            </Container>
        )
    }
}

export default Bills;