import React, { Component } from 'react';
import PropTypes from "prop-types";

import JSONEditorOriginal from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';

class JSONEditor extends Component {
    constructor(props) {
        super(props);
        this.onEditable = this.onEditable.bind(this);
    }

    onEditable() {
        return typeof this.props.readOnly !== "boolean" || !this.props.readOnly;
    }

    componentDidMount() {
        const options = {
            onChangeJSON: this.props.onChangeJSON,
            onChangeText: this.props.onChangeText,
            onChange: this.props.onChange,
            sortObjectKeys: this.props.sortObjectKeys,
            navigationBar: this.props.navigationBar,
            history: this.props.history,
            modes: this.props.allowedModes,
            mode: this.props.mode,
            statusBar: this.props.statusBar,
            search: this.props.search,
            onEditable: this.onEditable,
            onValidationError: this.props.onValidationError
        };

        this.jsoneditor = new JSONEditorOriginal(this.container, options);
        this.jsoneditor.set(this.props.json);
    }

    componentWillUnmount() {
        if (this.jsoneditor) {
            this.jsoneditor.destroy();
        }
    }

    render() {
        return (
            <div className="jsoneditor-react-container" style={{
                maxHeight: "500px"
            }} ref={elem => this.container = elem} />
        );
    }
}

JSONEditor.defaultProps = {
    sortObjectKeys: false,
    navigationBar: false,
    history: false,
    modes: ['tree', 'code', 'text', 'view'],
    mode: 'tree',
    statusBar: false,
    search: false
};

JSONEditor.propTypes = {
    onChangeJSON: PropTypes.func,
    onChangeText: PropTypes.func,
    onChange: PropTypes.func,
};

export default JSONEditor;