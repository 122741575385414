import { faCheck, faDownload, faLink, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { createRef } from "react";

import { Container, Breadcrumb, Row, Col, Button } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import MyTable from "../../components/MyTable";
import DomainDiscoveryService from "../../services/DomainDiscoveryService";
import Emitter from "../../services/Emitter";
import Toast from "../../services/Toast";

/**
 * Displays a Job's Fragment component
 * @component
 * @category Scenes
 * @subcategory Domain Discovery
 */
class Fragment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            keyword: null,
            pages: null,
            engine: null,
            fragId: 0,
            jobId: 0,
        };
        this.reload = this.reload.bind(this);
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
        this.tableRef = createRef();
    }

    componentDidMount() {
        Emitter.on("DD_FRAGMENT", this.reload);
    }

    componentWillUnmount() {
        Emitter.off("DD_FRAGMENT", this.reload);
    }

    reload(ids) {
        if (typeof ids === "undefined") {
            return;
        }
        this.tableRef.current.setLoading(true);

        this.setState({ fragId: ids.fragId, jobId: ids.jobId, pages: null, keyword: null, engine: null }, () => {

            DomainDiscoveryService.fragment(this.state.fragId).then((result) => {
                if (result === false) {
                    Toast.error("Something went wrong while retrieving this fragment's data");
                    return;
                }
                let list = [];
                result.items.forEach((row) => {
                    if (row.page === 0) {
                        row.page = 9999;
                    }
                    list.push(row);
                });
                this.setState({
                    engine: result.data.engine,
                    pages: result.data.pages,
                    keyword: result.data.keyword
                }, () => {
                    this.tableRef.current.populate(list);
                });
            });

        })
    }

    downloadSpreadsheet(evt) {
        this.tableRef.current.CSV({
            filename: "dd-job" + this.state.jobId + "-fragment-" + this.state.fragId + ".csv"
        })
    }

    render() {


        return (
            <Container className="pt-3 dd" >
                <span style={{
                    float: "right"
                }}
                >
                    <Button
                        size="sm"
                        className="tdButton"
                        variant="success"
                        label="Download CSV"
                        onClick={this.downloadSpreadsheet}
                    >
                        <FontAwesomeIcon icon={faDownload} /> Download
                    </Button>
                </span>
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Domain Discovery</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/dd/job">
                            Jobs
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/dd/job/${this.state.jobId}`}>
                            {this.state.jobId}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        #{this.state.fragId}
                    </Breadcrumb.Item>
                </Breadcrumb>
                {this.state.keyword !== null && (
                    <Row>
                        <Col className="text-right" sm="1">Keyword</Col>
                        <Col sm="5"><b>{this.state.keyword}</b></Col>
                        <Col className="text-right" sm="1">Engine</Col>
                        <Col sm="3"><b>{this.state.engine}</b></Col>
                        <Col className="text-right" sm="1">Pages</Col>
                        <Col sm="1"><b>{this.state.pages}</b></Col>
                    </Row>
                )}
                <MyTable
                    name="dd-fragment"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="page"
                    sortOrder="asc"
                    headers={
                        [
                            {
                                field: "page",
                                label: "Page",
                                width: "110px",
                                sortable: true,
                            },
                            {

                                field: "title",
                                label: "Title",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "domain",
                                label: "Domain",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "is_ecommerce",
                                label: "E-commerce",
                                width: "100px",
                                sortable: true,
                                allowEmpty: true,
                                options: [{ label: 'Yes', value: '1' }, { label: 'No', value: '0' }]
                            },
                            {
                                field: "url",
                                label: "URL",
                                width: "100px",
                                sortable: true,
                                searchable: true
                            }
                        ]
                    }
                    renderColumns={{
                        url: {
                            format: (row) => <a href={row.url} rel="noreferrer" target="_blank">
                                <FontAwesomeIcon icon={faLink} />
                            </a>,
                            className: "text-center"
                        },
                        page: {
                            format: (row) => row.page === 9999 ? 'Compare price' : row.page,
                            csv: (row) => row.page === 9999 ? 'Compare price' : row.page,
                        },
                        is_ecommerce: {
                            csv: (row) => row.is_ecommerce ? 'Yes' : 'No',
                            format: (row) => row.is_ecommerce ?
                                <FontAwesomeIcon
                                    style={{
                                        color: "#1f4503",
                                    }}
                                    icon={faCheck}
                                />
                                : <FontAwesomeIcon
                                    style={{ color: "red" }}
                                    icon={faTimes}
                                />,
                            className: "text-center"
                        }
                    }}

                />

            </Container >
        );
    }
}

export default withRouter(Fragment);
