import React from "react";

import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Toast from "../../services/Toast";
import Loading from "../../scenes/Loading";
import Emitter from "../../services/Emitter";
import ProcessingButton from "../../components/ProcessingButton";
import DropDown from "../../components/DropDown";
import UserService from "../../services/UserService";
import Utils from "../../services/Utils";
import Memory from "../../services/MemoryService";
import { withRouter } from "react-router-dom";


/**
 * Show a CRUD for the User row
 * @component
 * @category Scenes
 * @subcategory User
 */
class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState();

        this.handleChange = this.handleChange.bind(this);
        this.dropEdition = this.dropEdition.bind(this);
        this.startEdition = this.startEdition.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.reload = this.reload.bind(this);
        this.hasProblems = this.hasProblems.bind(this);
    }

    initialState() {
        return {
            row: {
                terms_accepted: 0,
                user_active: 1,
                brand_id: Memory.Get("NEW_USER_BRAND_ID", 0),
                first_name: '',
                last_name: '',
                email: ''
            },
            loading: this.props.id !== 0,
            editing: this.props.id === 0,
            updating: false,
            problems: {
                first_name: true,
                email: true
            }
        };
    }

    hasProblems() {
        let problems = {},
            numberOfProblems = 0;
        if (!Utils.IsValidEmail(this.state.row.email)) {
            problems.email = true;
            numberOfProblems++;
        }
        if (typeof this.state.row.first_name !== "string" || this.state.row.first_name.trim().length < 2) {
            problems.first_name = true;
            numberOfProblems++;
        }
        return numberOfProblems ? problems : false;
    }

    handleChange(evt) {
        let row = this.state.row,
            key = evt.target.name;

        row[key] = evt.target.value;
        this.setState({ row, problems: this.hasProblems() });
    }


    reload() {
        if (this.props.id === 0) {
            return;
        }
        UserService.get(this.props.id).then((data) => {
            if (data === false) {
                Toast.error("Failed loading this user's data");
                this.props.history.push("/user");
                return;
            }

            let payload = {
                loading: false,
                row: data,
                editing: false,
                problems: false
            };
            this.setState(payload);
        });
    }

    componentWillUnmount() {
        Emitter.off("USER_PROFILE");
        Memory.Flush("NEW_USER_BRAND_ID");
    }

    componentDidMount() {
        Emitter.once("USER_PROFILE", this.reload);
    }

    saveChanges() {

        this.setState({ updating: true });

        if (this.props.id === 0) { // new user
            UserService.create(this.state.row).then((user) => {
                if (typeof id === "string") {
                    this.setState({ updating: false });
                    Toast.error(user);
                } else {
                    Toast.ok("User account created successfully");
                    this.props.history.push("/user/" + user.id);
                    Emitter.emit("USER_CREATED", user);
                    this.setState({
                        updating: false,
                        editing: false,
                        row: user
                    });

                }
            });
            return;
        }

        // modifying existing user
        UserService.update(this.props.id, this.state.row).then((user) => {
            if (user === false) {
                this.setState({ updating: false });
                Toast.error(
                    "Something wrong happened and changes could not be saved."
                );
            } else {
                Emitter.emit("USER_CHANGED", {
                    name: user.name
                });
                this.setState({
                    updating: false,
                    editing: false,
                    row: user
                });
                Toast.ok("Changes properly saved.");
            }
        });
    }

    startEdition() {
        this.original = JSON.stringify(this.state.row);
        this.setState({ editing: true });
    }

    dropEdition() {
        this.setState({ editing: false, row: JSON.parse(this.original) });
        delete this.original;
    }

    render() {
        if (this.state.loading) {
            return (
                <Container className="pt-4">
                    <Row
                        style={{
                            height: "100px",
                        }}
                    >
                        <Col>
                            <Loading />
                        </Col>
                    </Row>
                </Container>
            );
        }

        return (
            <Container className="pt-4">
                <Row>
                    <Col sm={2}>
                        <Form.Label>ID</Form.Label>
                        <Form.Control
                            type="text"
                            name="id"
                            value={this.state.row.id}
                            readOnly={true}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="first_name"
                            className={this.state.problems !== false && typeof this.state.problems.first_name !== "undefined" ? "is-invalid" : ''}
                            value={this.state.row.first_name}
                            onChange={this.handleChange}
                            readOnly={!this.state.editing || this.state.updating}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="last_name"
                            value={this.state.row.last_name}
                            onChange={this.handleChange}
                            readOnly={!this.state.editing || this.state.updating}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Hubspot</Form.Label>
                        <Form.Control
                            type="text"
                            name="hubspot_id"
                            value={this.state.row.hubspot_id}
                            onChange={this.handleChange}
                            readOnly={!this.state.editing || this.state.updating}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Created</Form.Label>
                        <Form.Control
                            type="text"
                            name="created"
                            value={this.state.row.created}
                            readOnly={true}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Modified</Form.Label>
                        <Form.Control
                            type="text"
                            name="modified"
                            value={this.state.row.modified}
                            readOnly={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control
                            type="text"
                            name="email"
                            className={this.state.problems !== false && typeof this.state.problems.email !== "undefined" ? "is-invalid" : ''}
                            value={this.state.row.email !== null ? this.state.row.email : ''}
                            readOnly={!this.state.editing || this.state.updating}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                            type="text"
                            name="phone_number"
                            value={this.state.row.phone_number !== null ? this.state.row.phone_number : ''}
                            readOnly={!this.state.editing || this.state.updating}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Terms Accepted</Form.Label>
                        <DropDown
                            name="terms_accepted"
                            allowEmpty={false}
                            options={[{ label: 'Yes', value: '1' }, { label: 'No', value: '0' }]}
                            selected={this.state.row.terms_accepted}
                            disabled={!this.state.editing || this.state.updating}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Status</Form.Label>
                        <DropDown
                            name="user_active"
                            allowEmpty={false}
                            options={[{ label: 'Active', value: '1' }, { label: 'Disabled', value: '0' }]}
                            selected={this.state.row.user_active}
                            disabled={!this.state.editing || this.state.updating}
                            onChange={this.handleChange}
                        />
                    </Col>
                </Row>
                <Row
                    style={{
                        marginTop: "30px",
                        height: "350px",
                    }}
                >
                    <Col sm={6}>
                        {!this.state.editing && (
                            <Button
                                variant="secondary"
                                onClick={this.startEdition}
                            >
                                Edit
                            </Button>
                        )}
                        {this.state.editing && (
                            <span>
                                {this.props.id !== 0 && (
                                    <Button
                                        disabled={this.state.updating}
                                        variant="secondary"
                                        onClick={this.dropEdition}
                                    >
                                        Cancel
                                    </Button>
                                )}
                                <ProcessingButton
                                    disabled={this.state.problems !== false || this.props.id === 0} // preventing new accounts to be created
                                    variant="primary"
                                    processing={this.state.updating}
                                    processingLabel={this.props.id === 0 ? "Creating account ..." : "Saving ..."}
                                    label={this.props.id === 0 ? "Create account" : "Save"}
                                    onClick={this.saveChanges}
                                />
                            </span>
                        )}
                    </Col>
                </Row>
            </Container >
        );
    }
}

export default withRouter(Profile);
