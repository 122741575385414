import React from "react";
import PropTypes from "prop-types";

import { Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import Toast from "../services/Toast";
import MultiSelect from "react-multi-select-component";
import ProcessingButton from "./ProcessingButton";
import OrganizationSearch from "./OrganizationSearch"
import OrganizationService from "../services/OrganizationService";

/**
 * Shows a Import URLs component
 * @component
 */
class OrganizationAndBrands extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            importing: false,
            isBrandLoading: false,
            website: null,
            brandsAvailable: [],
            brandsSelected: [],
        }
        this.onEntering = this.onEntering.bind(this);
        this.selectBrand = this.selectBrand.bind(this);
    }

    selectBrand(list) {
        this.setState({
            brandsSelected: list,
        });
    }

    handleOrganizationChange = (row) => {
        let organization = row.length > 0 ? row[0] : null;
        this.setState({
            organization,
            isBrandLoading: organization !== null,
            brandsSelected: [],
            brandsAvailable: []
        });
        if (organization !== null) {
            OrganizationService.brands(organization.id).then((result) => {
                if (result === false) {
                    Toast.error("Something went wrong while retrieving brands");
                    return;
                }
                let brands = [];
                result.forEach((row) => {
                    if (row.active && this.props.skip.indexOf(row.id) === -1) {
                        brands.push({
                            value: row.id,
                            label: row.name
                        });
                    }
                });

                this.setState({
                    brandsAvailable: brands,
                    isBrandLoading: false
                })
            });
        }
    };

    onEntering() {
        this.setState({
            website: null,
            brandsAvailable: [],
            brandsSelected: [],
            importing: false,
            isBrandLoading: false
        });
    }

    render() {
        const cancel = () => {
            if (this.state.importing) {
                return;
            }
            this.props.onClose();
        }

        const save = () => {
            if (this.state.importing) {
                return;
            }
            this.setState({ importing: true }, () => {
                let ids = [];
                this.state.brandsSelected.forEach((item) => {
                    ids.push(item.value);
                });
                this.props.callbackPromise(ids).then((result) => {
                    if (result) {
                        this.setState({ importing: false });
                        this.props.onClose(result);
                    }
                }).catch(() => {
                    this.setState({ importing: false });
                });
            });
        }

        return (
            <Modal
                onEntering={this.onEntering}
                size="lg"
                show={this.props.show}
                onHide={cancel}
                backdrop={!this.state.importing}
                keyboard={!this.state.importing}
                centered
            >
                <Modal.Header closeButton onHide={cancel}>
                    <Modal.Title>
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Container>
                        <Row>
                            <OrganizationSearch onChange={this.handleOrganizationChange} />
                        </Row>
                        <Row>
                            {this.state.isBrandLoading && (
                                <Col><Spinner
                                    animation="border"
                                    size="sm"
                                /></Col>
                            )}
                            {!this.state.isBrandLoading && this.state.brandsAvailable.length > 0 && (

                                <MultiSelect
                                    id="brands"
                                    options={this.state.brandsAvailable}
                                    value={this.state.brandsSelected}
                                    onChange={this.selectBrand}
                                    labelledBy="Select brand"
                                />
                            )}
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer style={{
                    justifyContent: "center"
                }}>
                    <Button disabled={this.state.importing} size="sm" variant="secondary" onClick={cancel}>Cancel</Button>
                    <ProcessingButton
                        size="sm"
                        processing={this.state.importing}
                        processingLabel={this.props.processingLabel}
                        label={this.props.processLabel}
                        onClick={save}
                        disabled={this.state.brandsSelected.length === 0 || this.state.organization === null}
                    />
                </Modal.Footer>
            </Modal >
        );
    }
}

OrganizationAndBrands.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    onUpdated: PropTypes.func,
    issues: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    processLabel: PropTypes.string.isRequired,
    processingLabel: PropTypes.string.isRequired,
    callbackPromise: PropTypes.func,
    skip: PropTypes.arrayOf(PropTypes.number),
};

OrganizationAndBrands.defaultProps = {
    show: false,
    onClose: () => { console.log("onClose") },
    issues: [],
    title: 'Connect Brands',
    processLabel: 'Connect',
    processingLabel: 'Connecting ...',
    skip: [],
    callbackPromise: (brands) => { return new Promise((resolve) => { console.log(brands); setTimeout(() => { resolve(true) }, 2000); }); }
};

export default OrganizationAndBrands;
