import React from "react";
import PropTypes from "prop-types";
import ProcessingButton from "./ProcessingButton";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import confirm from "./Confirm";
import Utils from "../services/Utils";
import MySpinner from "./MySpinner";

/**
 * File Upload component
 * @component
 */
class FileUploadButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            processing: false,
            ownID: Utils.getRandomInt(1, 9999)
        }
        this.processFile = this.processFile.bind(this);
    }

    processFile(evt) {
        const file = evt.currentTarget.files[0], that = this;
        this.setState({
            processing: true
        }, () => {
            var reader = new FileReader();
            reader.onload = function () {
                document.getElementById(`fileUpload-${that.state.ownID}`).value = null;
                if (Utils.isPromise(that.props.onFileUpload)) {
                    that.props.onFileUpload({
                        metadata: file,
                        content: reader.result
                    }).then(() => {
                        that.setState({
                            processing: false
                        })
                    })
                } else {
                    that.props.onFileUpload({
                        metadata: file,
                        content: reader.result
                    });
                    // when property "processing" is passed, it overrides
                    // this.state.processing value, so there's no really need
                    // to handle that
                    if (typeof that.props.processing != "boolean") {
                        that.setState({ processing: false })
                    }
                }
            };
            reader.readAsBinaryString(file);
        })
    }

    render() {

        const processing = typeof this.props.processing === "boolean" ? this.props.processing : this.state.processing,
            onClick = async () => {
                if (typeof this.props.instructions === "string" && this.props.instructions.length) {
                    await confirm("Instructions", "OK", null, {
                        message: this.props.instructions
                    });
                }
                document.getElementById(`fileUpload-${this.state.ownID}`).click();
                return false;
            },
            obj = this.props.type === "button" ?
                <ProcessingButton
                    variant={this.props.variant}
                    size={this.props.size}
                    processing={processing}
                    processingLabel={this.props.processingLabel}
                    label={this.props.label}
                    icon={faUpload}
                    onClick={onClick}
                /> :
                <span>
                    {processing && (<React.Fragment>
                        <MySpinner style={{
                            position: "relative",
                            top: "5px",
                            marginRight: "8px"
                        }} />
                        {this.props.processingLabel}
                    </React.Fragment>)}
                    {!processing && (<React.Fragment>
                        <a className="link" href="#upload" onClick={onClick} style={{
                            lineHeight: "40px"
                        }}>{this.props.label}</a>
                    </React.Fragment>)}
                </span>

        return (
            <React.Fragment>
                <input type="file"
                    id={`fileUpload-${this.state.ownID}`}
                    style={{ display: "none" }}
                    onChange={this.processFile}
                    accept={this.props.acceptedFileTypes} />
                {obj}
            </React.Fragment>
        )
    }
}

FileUploadButton.propTypes = {
    variant: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    processingLabel: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    acceptedFileTypes: PropTypes.string.isRequired,
    instructions: PropTypes.string,
    onFileUpload: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired
};

FileUploadButton.defaultProps = {
    variant: "primary",
    size: "sm",
    processingLabel: "Uploading file...",
    label: "Upload file",
    acceptedFileTypes: ".csv,.CSV",
    instructions: null,
    onFileUpload: (content) => {
        console.log(content)
    },
    type: "button"
};

export default FileUploadButton;