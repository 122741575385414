import React from "react";
import PropTypes from "prop-types";

import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import Toast from "../services/Toast";
import UIVersion from "../services/Version";
import GenericService from "../services/GenericService";

/**
 * Displays the version of the front-end/back-end systems
 * @component
 */
class Version extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            API: null
        }
        this.onEntering = this.onEntering.bind(this);
    }

    onEntering() {
        if (this.state.API !== null) {
            return;
        }
        this.setState({
            loading: true
        });
        GenericService.version().then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while retriving the API version");
                this.props.onClose();
                return;
            }
            this.setState({ API: result, loading: false })
        });
    }


    render() {
        const Loading = (
            <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
        )
        return (
            <Modal
                onEntering={this.onEntering}
                onHide={this.props.onClose}
                show={this.props.show}
                backdrop={true}
                keyboard={true}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Versions
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center" style={{
                    fontFamily: "monospace"
                }}>
                    <Row>
                        <Col sm={2} className="text-left"><b>UI</b></Col>
                        <Col sm={4}>{UIVersion.Hash}</Col>
                        <Col sm={6} className="text-right">{new Date(UIVersion.TS * 1000).toISOString().slice(0, 19).replace('T', ' ')}</Col>
                    </Row>
                    <Row>
                        <Col sm={2} className="text-left"><b>API</b></Col>
                        <Col sm={4}>{this.state.API === null ? Loading : this.state.API.hash}</Col>
                        <Col sm={6} className="text-right">{this.state.API === null ? null : new Date(this.state.API.ts * 1000).toISOString().slice(0, 19).replace('T', ' ')}</Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer style={{
                    justifyContent: "center"
                }}>

                    <Button size="sm" variant="primary" onClick={this.props.onClose}>OK</Button>
                </Modal.Footer>
            </Modal >
        );
    }
}

Version.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
};

export default Version;
