/**
 * Should serve as a Global var environment that persists data shared by multiple
 * components.
 * @hideconstructor
 */
class MemoryService {
    /** Does this really need a comment? */
    static #memory = {};
    /**
     * Puts something into memory
     * @param {string} key
     * @param {*} data
     */
    static Set(key, data) {
        MemoryService.#memory[key] = data;
    }
    /**
     * Alias to Set
     * @param {string} key
     * @param {*} data
     */
    static Put(key, data) {
        MemoryService.Set(key, data);
    }
    /**
     * Get something from memory. If the given key hasn't been set, defaultValue will be returned
     * @param {string} key
     * @param {*} defaultValue
     * @returns {*}
     */
    static Get(key, defaultValue = null) {
        return typeof MemoryService.#memory[key] == "undefined"
            ? defaultValue
            : MemoryService.#memory[key];
    }
    /**
     * Verifies if a given key has been already set
     * @param {string} key
     * @returns {boolean}
     */
    static Has(key) {
        return typeof MemoryService.#memory[key] != "undefined";
    }
    /**
     * Removes something from memory
     * @param {string} key
     * @returns {*}
     */
    static Flush(key) {
        const value = MemoryService.#memory[key];
        delete MemoryService.#memory[key];
        return value;
    }
}
export default MemoryService;
