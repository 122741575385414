import axios from "axios";
import Config from "./Config";
import Utils from "./Utils";
import APIHelper from "./APIHelper";

const NPDService = {
    smartselection() {
        return axios
            .get(Config.API_URL + "npd/smartselection")
            .then(APIHelper.returnRows)
            .catch(APIHelper.returnFalse);
    },
    autofeeder() {
        return axios
            .get(Config.API_URL + "npd/autofeeder")
            .then(APIHelper.returnRows)
            .catch(APIHelper.returnFalse);
    },
    autofeeder_set(payload) {
        return axios
            .post(Config.API_URL + "npd/autofeeder/" + payload['id'], payload)
            .then((response) => {
                if(APIHelper.checkStatus(response)) {
                    return payload['id'] === 0 ? response.data.id : response.data.status;
                }
                return false;
            })
            .catch(APIHelper.returnFalse);
    },
    add(website_id) {
        return axios
            .post(Config.API_URL + "npd/smartselection", { website_id })
            .then(response => APIHelper.returnAttribute(response, 'row'))
            .catch(APIHelper.returnFalse);
    },
    switch(website_id, mode) {
        return axios
            .post(Config.API_URL + "npd/" + website_id + "/switch", { mode })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    autopopulate(website_id, mode) {
        return axios
            .post(Config.API_URL + "npd/" + website_id + "/autopopulate", { mode })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    getNotes(website_id) {
        return axios
            .get(Config.API_URL + "npd/smartselection/" + website_id + "/notes")
            .then(response => APIHelper.returnAttribute(response, 'notes'))
            .catch(APIHelper.returnFalse);
    },
    setNotes(website_id, notes) {
        return axios
            .post(Config.API_URL + "npd/smartselection/" + website_id + "/notes", { notes })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    gauge() {
        return axios
            .get(Config.API_URL + "npd/gauge")
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    crawler() {
        return axios
            .get(Config.API_URL + "npd/crawler")
            .then(APIHelper.returnRows)
            .catch(APIHelper.returnFalse);
    },
    matcher() {
        return axios
            .get(Config.API_URL + "npd/matcher")
            .then(APIHelper.returnRows)
            .catch(APIHelper.returnFalse);
    },
    package(website_id) {
        return axios
            .get(Config.API_URL + "npd/" + website_id + "/package")
            .then((response) => {
                if(APIHelper.checkStatus(response)) {
                    Utils.DownloadContent(website_id + "-package.json", response.data.content);
                    return true;
                }
                return false;
            })
            .catch(APIHelper.returnFalse);
    },
    checked(website_id, field, checked) {
        return axios
            .post(Config.API_URL + "npd/" + website_id + "/checked", { field, checked })
            .then(response => APIHelper.returnAttribute(response, 'value', null))
            .catch(APIHelper.returnFalse);
    },
    settings(website_id, settings) {
        return axios
            .post(Config.API_URL + "npd/" + website_id, settings)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    websites() {
        return axios
            .get(Config.API_URL + "npd/websites")
            .then(APIHelper.returnRows)
            .catch(APIHelper.returnFalse);
    }
};

export default NPDService;
