import React, { createRef } from "react";
import PropTypes from "prop-types";

import { Container, Button, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";
import WebsiteService from "../../services/WebsiteService";
import "../../../node_modules/currency-flags/dist/currency-flags.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import Emitter from "../../services/Emitter";
import { Link } from "react-router-dom";
import confirm from "../../components/Confirm";
import Toast from "../../services/Toast";
import URLLookupService from "../../services/URLLookupService";
import { Menu, MenuItem } from "@szhsin/react-menu";
import MyTable from "../../components/MyTable";
import MySpinner from "../../components/MySpinner";

/**
 * Crawl Results component
 * @component
 * @category Scenes
 * @subcategory Websites
 */
class ProductsMapped extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleting: false
        };
        this.onEnter = this.onEnter.bind(this);
        this.onRequestRemoveURLs = this.onRequestRemoveURLs.bind(this);
        this.handleOpenURLs = this.handleOpenURLs.bind(this);
        this.tableRef = createRef();
        this.supported = [];
    }

    componentWillUnmount() {
        Emitter.off("WEBSITE_PRODUCTS");
    }

    componentDidMount() {
        Emitter.once("WEBSITE_PRODUCTS", this.onEnter);
    }

    onEnter() {
        WebsiteService.productsMapped(this.props.websiteId).then((data) => {
            if (data !== false) {
                let list = [];
                this.supported = typeof data.supportedCurrencies !== "object" ? [] : data.supportedCurrencies;
                data.rows.forEach((row) => {
                    list.push(row);
                });
                this.currencies = [];
                data.currencies.forEach((item) => {
                    this.currencies[item.id] = item;
                });
                this.tableRef.current?.populate(list);
            }
        });
    }


    handleOpenURLs(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt),
            target = obj.target.getAttribute("target");

        if (target === 'uploader') {
            window.localStorage.setItem('uploader', JSON.stringify({
                website_id: this.props.websiteId,
                brand_id: obj.row.id
            }));
            window.open("/others/url", "_blank", "noopener,noreferrer");
        } else {
            window.open("/brand/" + obj.row.id + "#lookup," + this.props.websiteId, "_blank", "noopener,noreferrer");
        }
    }

    async onRequestRemoveURLs(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt),
            brand_id = obj.row !== null ? obj.row.id : 0;
        if (
            await confirm("Are you sure?", "Yes, continue", "No", {
                message:
                    "You might be about to remove URLs for a bunch of items. This operation can't be undone. Do you want to proceed?",
            }) === false
        ) {
            return;
        }

        const callBack = () => {
            URLLookupService.purge(this.props.websiteId, brand_id).then((result) => {
                if (!result) {
                    Toast.error("Something went wrong while removing mappings");
                    this.setState({ deleting: false });
                    if (brand_id) {
                        delete obj.row.deleting;
                        this.tableRef.current?.updateRow(obj.row);
                    }
                    return;
                }
                Toast.ok("Mappings removed successfully");
                if (!brand_id) {
                    this.setState({ deleting: false }, () => {
                        this.tableRef.current?.populate([]);
                    });
                    return;
                }
                this.tableRef.current?.deleteRow(obj.row);
            });
        }

        if (obj.row === null) {
            this.setState({ deleting: true }, callBack);
        } else {
            obj.row.deleting = true;
            this.tableRef.current.updateRow(obj.row).then(callBack);
        }
    }

    render() {

        return (
            <Container className="pt-4" >
                <MyTable
                    name="website-products-mapped"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="products"
                    sortOrder="desc"
                    headers={
                        [
                            {
                                field: "brand",
                                label: "Brand",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "active",
                                label: "Active",
                                width: "75px",
                                sortable: true

                            },
                            {
                                field: "currencies",
                                label: "Brand currencies",
                                width: "120px"
                            },
                            {
                                field: "products",
                                label: "Products",
                                width: "100px",
                                sortable: true
                            },
                            {
                                field: "control",
                                width: "45px",
                                content: this.state.deleting ?
                                    <MySpinner />
                                    :
                                    <OverlayTrigger
                                        placement="bottom"
                                        trigger={["hover", "focus"]}
                                        overlay={
                                            <Popover>
                                                <PopoverBody>
                                                    Remove ALL product mappings for this website
                                                </PopoverBody>
                                            </Popover>
                                        }
                                    >
                                        <Button
                                            size="sm"
                                            className="tdButton"
                                            variant="danger"
                                            onClick={this.onRequestRemoveURLs}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    </OverlayTrigger>
                            }
                        ]}
                    renderColumns={{
                        brand: (row) => {
                            const supported = this.supported,
                                currenciesInCommon = row.currencies.filter(function (n) {
                                    return supported.indexOf(n) !== -1;
                                }),
                                unsupported = currenciesInCommon.length === 0;
                            return <span>
                                <Link className="link" to={`/brand/${row.id}`}>(#{row.id}) {row.brand}</Link>{unsupported && (
                                    <span className="not-supported-currency">Unsupported currency</span>
                                )}
                            </span>
                        },
                        active: {
                            format: (row) => row.active === 1 ?
                                <FontAwesomeIcon
                                    style={{
                                        color: "#1f4503",
                                    }}
                                    icon={faCheck}
                                />
                                :
                                <FontAwesomeIcon
                                    style={{ color: "red" }}
                                    icon={faTimes}
                                />,
                            className: "text-center"
                        },
                        currencies: {
                            format: (row) => row.currencies.map((item) => {
                                if (typeof this.currencies[item] === "undefined") {
                                    return null;
                                }
                                return (
                                    <div
                                        data-testid="currency-flag"
                                        key={`currency-${row.id}-${item}`}
                                        className={`currency-flag currency-flag-${this.currencies[item].code}`}
                                    ></div>
                                );
                            }),
                            className: "text-center"
                        },
                        products: {
                            format: (row) => <Menu
                                direction="left"
                                style={{
                                    fontSize: "medium"
                                }}
                                menuButton={
                                    <span className="link">{row.products}</span>
                                }
                            >
                                <MenuItem target="uploader" onClick={this.handleOpenURLs}>URL Uploader</MenuItem>
                                <MenuItem target="brand" onClick={this.handleOpenURLs}>Brand's Lookup</MenuItem>
                            </Menu>,
                            className: "text-right"
                        },
                        control: (row) => !row.deleting ?
                            <OverlayTrigger
                                placement="bottom"
                                trigger={["hover", "focus"]}
                                overlay={
                                    <Popover>
                                        <PopoverBody>
                                            Remove all mappings for this brand only
                                        </PopoverBody>
                                    </Popover>
                                }
                            >
                                <Button
                                    size="sm"
                                    className="tdButton"
                                    variant="outline-secondary"
                                    onClick={this.onRequestRemoveURLs}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </OverlayTrigger>
                            :
                            <MySpinner />
                    }}
                />
            </Container >
        );
    }
}

ProductsMapped.propTypes = {
    /** Website ID */
    websiteId: PropTypes.number.isRequired,
};

export default ProductsMapped;
