import React, { createRef } from "react";

import { Container, Breadcrumb, Button } from "react-bootstrap";
import Toast from "../../../services/Toast";
import MyTable from "../../../components/MyTable";
import NPDService from "../../../services/NPDService";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

/**
 * NPD component
 * @component
 * @category Others
 */
class Websites extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = createRef();
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
    }

    downloadSpreadsheet(evt) {
        this.tableRef.current.CSV()
    }

    componentDidMount() {
        Toast.info("The table's content is updated every 12hrs", 5000);
        NPDService.websites().then((data) => {
            if (data === false) {
                Toast.error(
                    "Something went wrong while retrieving the list of rows"
                );
                return;
            }
            this.tableRef.current?.populate(data);
        });
    }

    render() {
        return (
            <Container className="pt-4">
                <span style={{
                    float: "right"
                }}
                >
                    <Button
                        size="sm"
                        className="tdButton"
                        variant="success"
                        onClick={this.downloadSpreadsheet}
                    >
                        <FontAwesomeIcon icon={faDownload} /> Download
                    </Button>
                </span>
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Others</Breadcrumb.Item>
                    <Breadcrumb.Item>NPD</Breadcrumb.Item>
                    <Breadcrumb.Item active>Deliveries per Websites</Breadcrumb.Item>
                </Breadcrumb>
                <MyTable
                    name="npd-websites"
                    ref={this.tableRef}
                    dynamic={false}
                    sortBy="count"
                    sortOrder="desc"
                    headers={[
                        {
                            field: "website_id",
                            label: "ID",
                            sortable: true,
                            searchable: true,
                            width: "75px"
                        },
                        {
                            field: "website",
                            label: "Website",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "count",
                            label: "URLs",
                            width: "200px",
                            sortable: true,
                            searchable: true
                        }
                    ]}
                    renderColumns={{
                        website: (row) => <Link className="link" to={`/website/${row.website_id}#discovery`} target="_blank">{row.website.toLowerCase()}</Link>,
                        count: {
                            className: "text-right"
                        }
                    }}
                />
            </Container >
        );
    }
}

export default withRouter(Websites);
