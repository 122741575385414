import React, { createRef } from "react";

import { Container, Breadcrumb, Button } from "react-bootstrap";
import DomainDiscoveryService from "../../services/DomainDiscoveryService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEdit, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import prompt from "../../components/Prompt";
import confirm from "../../components/Confirm";
import Toast from "../../services/Toast";
import Utils from "../../services/Utils";
import MyTable from "../../components/MyTable";

/**
 * Blacklist Domain component
 * @component
 * @category Scenes
 * @subcategory Domain Discovery
 */
class BlackListSubdomain extends React.Component {
    constructor(props) {
        super(props);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.tableRef = createRef();
    }

    componentDidMount() {
        DomainDiscoveryService.getAllBlacklistedSubdomain().then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of subdomains");
                return;
            }
            this.tableRef.current?.populate(data);
        });
    }

    handleDownload() {
        this.tableRef.current.CSV();
    }

    async handleDelete(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);

        if (
            await confirm("Are you sure?", "Yes", "No", {
                message:
                    "You are about the remove subdomain *" +
                    obj.row.subdomain +
                    "* from the blacklist. This operation can't be undone. Do you want to proceed?",
            })
        ) {
            DomainDiscoveryService.removeBlackListSubdomain(obj.row.id).then(
                (result) => {
                    if (result) {
                        Toast.ok("Subdomain removed successfully.");
                        this.tableRef.current.deleteRow(obj.row);
                    } else {
                        Toast.error(
                            "Something went wrong while removing the subdomain."
                        );
                    }
                }
            );
        }
    }

    handleEdit(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);

        prompt("Edit subdomain", "Update", "Cancel", "", obj.row.subdomain)
            .then((inputResult) => {
                let newValue = Utils.CleanUpSubdomain(inputResult.value);
                if (newValue !== obj.row.subdomain) {
                    DomainDiscoveryService.updateBlackListSubdomain(
                        obj.row.id,
                        newValue
                    ).then((result) => {
                        if (result) {
                            obj.row.subdomain = newValue;
                            this.tableRef.current.updateRow(obj.row);
                            Toast.ok("Subdomain updated successfully");
                        } else {
                            Toast.error(
                                "Something went wrong while updating the subdomain."
                            );
                        }
                    });
                }
            })
            .catch(() => { });
    }

    handleNew() {
        prompt("Add new Subdomain", "Add", "Cancel", "", "", {
            submitOnEnter: false
        }).then((inputResult) => {
            let dirtyValues = inputResult.value.replace(/[\n ]/g, ',').split(','),
                cleanValues = [],
                plural;

            dirtyValues.forEach((text) => {
                let cleanValue = Utils.CleanUpSubdomain(text);
                if (cleanValue.length === 0) {
                    return;
                }
                cleanValues.push(cleanValue);
            });
            if (cleanValues.length === 0) {
                return;
            }
            plural = cleanValues.length > 1;

            DomainDiscoveryService.insertBlackListSubdomain(cleanValues).then(
                (result) => {
                    if (result !== false) {
                        result.forEach((row) => {
                            this.tableRef.current.addRow(row);
                        });
                        Toast.ok("Subdomain" + (plural ? "s" : "") + " added successfully");
                    } else {
                        Toast.error(
                            "Something went wrong while adding the new subdomain" + (plural ? "s" : "")
                        );
                    }
                }
            );
        }).catch(() => { });
    }

    render() {

        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Domain Discovery</Breadcrumb.Item>
                    <Breadcrumb.Item>Blacklist</Breadcrumb.Item>
                    <Breadcrumb.Item active>Subdomain</Breadcrumb.Item>
                </Breadcrumb>
                <MyTable
                    name="dd-blacklist-subdomain"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="subdomain"
                    sortOrder="asc"
                    headers={
                        [
                            {
                                field: "subdomain",
                                label: "Subdomain",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "control",
                                width: "87px",
                                className: "text-center",
                                content: <React.Fragment>
                                    <Button
                                        size="sm"
                                        variant="success"
                                        className="tdButton"
                                        onClick={this.handleNew}
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                    <Button
                                        size="sm"
                                        variant="primary"
                                        className="tdButton"
                                        onClick={this.handleDownload}
                                    >
                                        <FontAwesomeIcon icon={faDownload} />
                                    </Button>
                                </React.Fragment>
                            }
                        ]
                    }
                    renderColumns={{
                        control: (row) => <React.Fragment>
                            <Button
                                size="sm"
                                variant="secondary"
                                className="tdButton"
                                onClick={this.handleEdit}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                            <Button
                                size="sm"
                                variant="danger"
                                className="tdButton"
                                onClick={this.handleDelete}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </React.Fragment>
                    }}
                />
            </Container>
        );
    }
}

export default BlackListSubdomain;
