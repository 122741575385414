import React, { createRef } from "react";
import PropTypes from "prop-types";

import { Link, withRouter } from "react-router-dom";
import Toast from "../../services/Toast";
import { Container } from "react-bootstrap";
import Emitter from "../../services/Emitter";
import ProductService from "../../services/ProductService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import MyTable from "../../components/MyTable";


/**
 * @component
 * @category Scenes
 * @subcategory Products
 */
class Merchants extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            yesterday: 'Yesterday'
        }
        this.onEnter = this.onEnter.bind(this);
        this.tableRef = createRef();
    }

    componentDidMount() {
        Emitter.once("PRODUCT_MERCHANTS", this.onEnter);
    }

    componentWillUnmount() {
        Emitter.off("PRODUCT_MERCHANTS");
    }

    onEnter() {
        ProductService.merchants(this.props.id).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of merchants");
                return;
            }
            data.forEach((row) => {
                row.type = row.type_id === 1 ? 'Online Retailer' : 'Marketplace Seller';
                delete row.type_id;
                row.violated = row.violated ? "1" : "0"
            })
            this.tableRef.current?.populate(data);
        });
    }

    render() {
        return (
            <Container className="pt-4">
                <MyTable
                    name="product-merchants"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="name"
                    sortOrder="asc"
                    headers={[
                        {
                            field: "name",
                            label: "Merchant",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "type",
                            label: "Type",
                            sortable: true,
                            allowEmpty: true,
                            options: ["Marketplace Seller", "Online Retailer"]
                        },
                        {
                            field: "website",
                            label: "Website",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "violated",
                            label: "Violated",
                            sortable: true,
                            allowEmpty: true,
                            options: [{ label: "Violated", value: "1" }, { label: "Not violated", value: "0" }]
                        }]}
                    renderColumns={{
                        name: (row) => <Link target="_blank" className="link" to={`/merchant/${row.id}`}>(#{row.id}) {row.name}</Link>,
                        website: (row) => <Link target="_blank" className="link" to={`/website/${row.website_id}`}>(#{row.website_id}) {row.website}</Link>,
                        violated: {
                            format: (row) => row.violated === "1" ? <FontAwesomeIcon
                                style={{
                                    color: "red",
                                }}
                                icon={faCheck}
                            /> : null,
                            className: "text-center",
                            csv: (row) => row.violated === "1" ? "Yes" : "No"
                        }
                    }}
                />

            </Container>
        );
    }
}

Merchants.propTypes = {
    id: PropTypes.string.isRequired,
};

export default withRouter(Merchants);
