import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import forbidden from "../../images/forbidden-bw.png";

/**
 * Renders a "Forbidden" access page
 * @component
 * @category Scenes
 */
class Forbidden extends React.Component {
    render() {
        return (
            <Container className="pt-4">
                <Row className="d-flex align-items-center justify-content-center text-center">
                    <Col style={{ fontSize: "large" }}>
                        <img
                            src={forbidden}
                            alt="Logo"
                            style={{ margin: "20px" }}
                        />
                        It looks like you don't have access to this area.
                    </Col>
                </Row>
            </Container>
        );
    }
}
export default Forbidden;
