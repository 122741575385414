import React from "react";
import PropTypes from "prop-types";
import MultiSelect from "react-multi-select-component";
import BrandsService from "../services/BrandService";
import Toast from "../services/Toast";

/**
 * Brands simple filter
 * @component
 */
class BrandsFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            options: [],
            selected: [],
        }
        this.getOptions();
    }

    getOptions = () => {
        BrandsService.all()
            .then(data => {
                let brands = data.filter(b => b.status === '1').map(this.mapOptions)
                this.setState({options: brands});
            })
            .catch((error) => {
                Toast.error('Something went wrong while loading the list of brands');
            })
            .finally(() => {
                this.setState({loading: false});
            });
    }
    mapOptions = (option) => {
        let organization = '';

        if (option.organization && option.name !== option.organization ) {
            organization = `(${option.organization})`
        }

        return {
            label: `${option.id} - ${option.name} ${organization}`,
            value: option.id
        }
    }
    onChange = (rows) => {
        this.setState({selected: rows})
        this.props.onChange(rows.map(({value}) => value));
    }

    render() {
        return <MultiSelect
            id="brands-filter"
            disabled={this.state.loading}
            options={this.state.options}
            isLoading={this.state.loading}
            value={this.state.selected}
            onChange={this.onChange}
            labelledBy="Select Brands"
        />
    }
}

BrandsFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default BrandsFilter;
