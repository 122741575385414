import React from "react";
import PropTypes from "prop-types";

import MultiSelect from "react-multi-select-component";
import ReactCountryFlag from "react-country-flag";
import "./Regions.css";

/**
 * Shows a Region MultiSelect field
 * Content generated with: SELECT CONCAT('{"id": ',r.id,', "country_id": ', r.country_id, ', "code": ',c.country_code, ', "name": "', r.name, '", "currency": "', c.currency_code, '"}') line FROM regions r JOIN countries c ON c.id = r.country_id;
 * @component
 */
class Regions extends React.Component {
    /**
     * All supported regions
     */
    static OPTIONS = [
        { label: "United States", value: 1 },
        { label: "Canada", value: 2 },
        { label: "Afghanistan", value: 3 },
        { label: "Åland Islands", value: 4 },
        { label: "Albania", value: 5 },
        { label: "Algeria", value: 6 },
        { label: "American Samoa", value: 7 },
        { label: "Andorra", value: 8 },
        { label: "Angola", value: 9 },
        { label: "Anguilla", value: 10 },
        { label: "Antarctica", value: 11 },
        { label: "Antigua and Barbuda", value: 12 },
        { label: "Argentina", value: 13 },
        { label: "Armenia", value: 14 },
        { label: "Aruba", value: 15 },
        { label: "Australia", value: 16 },
        { label: "Austria", value: 17 },
        { label: "Azerbaijan", value: 18 },
        { label: "Bahamas", value: 19 },
        { label: "Bahrain", value: 20 },
        { label: "Bangladesh", value: 21 },
        { label: "Barbados", value: 22 },
        { label: "Belarus", value: 23 },
        { label: "Belgium", value: 24 },
        { label: "Belize", value: 25 },
        { label: "Benin", value: 26 },
        { label: "Bermuda", value: 27 },
        { label: "Bhutan", value: 28 },
        { label: "Bolivia", value: 29 },
        { label: "Bonaire, Sint Eustatius and Saba", value: 30 },
        { label: "Bosnia and Herzegovina", value: 31 },
        { label: "Botswana", value: 32 },
        { label: "Bouvet Island", value: 33 },
        { label: "Brazil", value: 34 },
        { label: "British Indian Ocean Territory", value: 35 },
        { label: "British Virgin Islands", value: 36 },
        { label: "Brunei Darussalam", value: 37 },
        { label: "Bulgaria", value: 38 },
        { label: "Burkina Faso", value: 39 },
        { label: "Burundi", value: 40 },
        { label: "Cambodia", value: 41 },
        { label: "Cameroon", value: 42 },
        { label: "Cape Verde", value: 43 },
        { label: "Cayman Islands", value: 44 },
        { label: "Central African Republic", value: 45 },
        { label: "Chad", value: 46 },
        { label: "Chile", value: 47 },
        { label: "China", value: 48 },
        { label: "Christmas Island", value: 49 },
        { label: "Cocos [Keeling] Islands", value: 50 },
        { label: "Colombia", value: 51 },
        { label: "Comoros", value: 52 },
        { label: "Cook Islands", value: 53 },
        { label: "Costa Rica", value: 54 },
        { label: "Croatia", value: 55 },
        { label: "Cuba", value: 56 },
        { label: "Curaçao", value: 57 },
        { label: "Cyprus", value: 58 },
        { label: "Czech Republic", value: 59 },
        { label: "Democratic Republic of the Congo", value: 60 },
        { label: "Denmark", value: 61 },
        { label: "Djibouti", value: 62 },
        { label: "Dominica", value: 63 },
        { label: "Dominican Republic", value: 64 },
        { label: "East Timor", value: 65 },
        { label: "Ecuador", value: 66 },
        { label: "Egypt", value: 67 },
        { label: "El Salvador", value: 68 },
        { label: "Equatorial Guinea", value: 69 },
        { label: "Eritrea", value: 70 },
        { label: "Estonia", value: 71 },
        { label: "Ethiopia", value: 72 },
        { label: "Falkland Islands", value: 73 },
        { label: "Faroe Islands", value: 74 },
        { label: "Fiji", value: 75 },
        { label: "Finland", value: 76 },
        { label: "France", value: 77 },
        { label: "French Guiana", value: 78 },
        { label: "French Polynesia", value: 79 },
        { label: "French Southern Territories", value: 80 },
        { label: "Gabon", value: 81 },
        { label: "Gambia", value: 82 },
        { label: "Georgia", value: 83 },
        { label: "Germany", value: 84 },
        { label: "Ghana", value: 85 },
        { label: "Gibraltar", value: 86 },
        { label: "Greece", value: 87 },
        { label: "Greenland", value: 88 },
        { label: "Grenada", value: 89 },
        { label: "Guadeloupe", value: 90 },
        { label: "Guam", value: 91 },
        { label: "Guatemala", value: 92 },
        { label: "Guernsey", value: 93 },
        { label: "Guinea", value: 94 },
        { label: "Guinea-Bissau", value: 95 },
        { label: "Guyana", value: 96 },
        { label: "Haiti", value: 97 },
        { label: "Heard Island and McDonald Islands", value: 98 },
        { label: "Honduras", value: 99 },
        { label: "Hong Kong", value: 100 },
        { label: "Hungary", value: 101 },
        { label: "Iceland", value: 102 },
        { label: "India", value: 103 },
        { label: "Indonesia", value: 104 },
        { label: "Iran", value: 105 },
        { label: "Iraq", value: 106 },
        { label: "Ireland", value: 107 },
        { label: "Isle of Man", value: 108 },
        { label: "Israel", value: 109 },
        { label: "Italy", value: 110 },
        { label: "Ivory Coast", value: 111 },
        { label: "Jamaica", value: 112 },
        { label: "Japan", value: 113 },
        { label: "Jersey", value: 114 },
        { label: "Jordan", value: 115 },
        { label: "Kazakhstan", value: 116 },
        { label: "Kenya", value: 117 },
        { label: "Kiribati", value: 118 },
        { label: "Kosovo", value: 119 },
        { label: "Kuwait", value: 120 },
        { label: "Kyrgyzstan", value: 121 },
        { label: "Laos", value: 122 },
        { label: "Latvia", value: 123 },
        { label: "Lebanon", value: 124 },
        { label: "Lesotho", value: 125 },
        { label: "Liberia", value: 126 },
        { label: "Libya", value: 127 },
        { label: "Liechtenstein", value: 128 },
        { label: "Lithuania", value: 129 },
        { label: "Luxembourg", value: 130 },
        { label: "Macao", value: 131 },
        { label: "Madagascar", value: 132 },
        { label: "Malawi", value: 133 },
        { label: "Malaysia", value: 134 },
        { label: "Maldives", value: 135 },
        { label: "Mali", value: 136 },
        { label: "Malta", value: 137 },
        { label: "Marshall Islands", value: 138 },
        { label: "Martinique", value: 139 },
        { label: "Mauritania", value: 140 },
        { label: "Mauritius", value: 141 },
        { label: "Mayotte", value: 142 },
        { label: "Mexico", value: 143 },
        { label: "Micronesia", value: 144 },
        { label: "Moldova", value: 145 },
        { label: "Monaco", value: 146 },
        { label: "Mongolia", value: 147 },
        { label: "Montenegro", value: 148 },
        { label: "Montserrat", value: 149 },
        { label: "Morocco", value: 150 },
        { label: "Mozambique", value: 151 },
        { label: "Myanmar [Burma]", value: 152 },
        { label: "Namibia", value: 153 },
        { label: "Nauru", value: 154 },
        { label: "Nepal", value: 155 },
        { label: "Netherlands", value: 156 },
        { label: "New Caledonia", value: 157 },
        { label: "New Zealand", value: 158 },
        { label: "Nicaragua", value: 159 },
        { label: "Niger", value: 160 },
        { label: "Nigeria", value: 161 },
        { label: "Niue", value: 162 },
        { label: "Norfolk Island", value: 163 },
        { label: "North Korea", value: 164 },
        { label: "North Macedonia", value: 165 },
        { label: "Northern Mariana Islands", value: 166 },
        { label: "Norway", value: 167 },
        { label: "Oman", value: 168 },
        { label: "Pakistan", value: 169 },
        { label: "Palau", value: 170 },
        { label: "Palestine", value: 171 },
        { label: "Panama", value: 172 },
        { label: "Papua New Guinea", value: 173 },
        { label: "Paraguay", value: 174 },
        { label: "Peru", value: 175 },
        { label: "Philippines", value: 176 },
        { label: "Pitcairn Islands", value: 177 },
        { label: "Poland", value: 178 },
        { label: "Portugal", value: 179 },
        { label: "Puerto Rico", value: 180 },
        { label: "Qatar", value: 181 },
        { label: "Republic of the Congo", value: 182 },
        { label: "Réunion", value: 183 },
        { label: "Romania", value: 184 },
        { label: "Russia", value: 185 },
        { label: "Rwanda", value: 186 },
        { label: "Saint Barthélemy", value: 187 },
        { label: "Saint Helena", value: 188 },
        { label: "Saint Kitts and Nevis", value: 189 },
        { label: "Saint Lucia", value: 190 },
        { label: "Saint Martin", value: 191 },
        { label: "Saint Pierre and Miquelon", value: 192 },
        { label: "Saint Vincent and the Grenadines", value: 193 },
        { label: "Samoa", value: 194 },
        { label: "San Marino", value: 195 },
        { label: "São Tomé and Príncipe", value: 196 },
        { label: "Saudi Arabia", value: 197 },
        { label: "Senegal", value: 198 },
        { label: "Serbia", value: 199 },
        { label: "Seychelles", value: 200 },
        { label: "Sierra Leone", value: 201 },
        { label: "Singapore", value: 202 },
        { label: "Sint Maarten", value: 203 },
        { label: "Slovakia", value: 204 },
        { label: "Slovenia", value: 205 },
        { label: "Solomon Islands", value: 206 },
        { label: "Somalia", value: 207 },
        { label: "South Africa", value: 208 },
        { label: "South Georgia and the South Sandwich Islands", value: 209 },
        { label: "South Korea", value: 210 },
        { label: "South Sudan", value: 211 },
        { label: "Spain", value: 212 },
        { label: "Sri Lanka", value: 213 },
        { label: "Sudan", value: 214 },
        { label: "Suriname", value: 215 },
        { label: "Svalbard and Jan Mayen", value: 216 },
        { label: "Swaziland", value: 217 },
        { label: "Sweden", value: 218 },
        { label: "Switzerland", value: 219 },
        { label: "Syria", value: 220 },
        { label: "Taiwan", value: 221 },
        { label: "Tajikistan", value: 222 },
        { label: "Tanzania", value: 223 },
        { label: "Thailand", value: 224 },
        { label: "Togo", value: 225 },
        { label: "Tokelau", value: 226 },
        { label: "Tonga", value: 227 },
        { label: "Trinidad and Tobago", value: 228 },
        { label: "Tunisia", value: 229 },
        { label: "Turkey", value: 230 },
        { label: "Turkmenistan", value: 231 },
        { label: "Turks and Caicos Islands", value: 232 },
        { label: "Tuvalu", value: 233 },
        { label: "U.S. Minor Outlying Islands", value: 234 },
        { label: "U.S. Virgin Islands", value: 235 },
        { label: "Uganda", value: 236 },
        { label: "Ukraine", value: 237 },
        { label: "United Arab Emirates", value: 238 },
        { label: "United Kingdom of Great Britain and Northern Ireland", value: 239 },
        { label: "Uruguay", value: 240 },
        { label: "Uzbekistan", value: 241 },
        { label: "Vanuatu", value: 242 },
        { label: "Vatican City", value: 243 },
        { label: "Venezuela", value: 244 },
        { label: "Vietnam", value: 245 },
        { label: "Wallis and Futuna", value: 246 },
        { label: "Western Sahara", value: 247 },
        { label: "Yemen", value: 248 },
        { label: "Zambia", value: 249 },
        { label: "Zimbabwe", value: 250 }
    ];

    static #map = {
        1: { country: 233, code: "US", label: "United States", currency: "USD" },
        2: { country: 38, code: "CA", label: "Canada", currency: "CAD" },
        3: { country: 3, code: "AF", label: "Afghanistan", currency: "AFN" },
        4: { country: 15, code: "AX", label: "Åland Islands", currency: "EUR" },
        5: { country: 6, code: "AL", label: "Albania", currency: "ALL" },
        6: { country: 62, code: "DZ", label: "Algeria", currency: "DZD" },
        7: { country: 11, code: "AS", label: "American Samoa", currency: "USD" },
        8: { country: 1, code: "AD", label: "Andorra", currency: "EUR" },
        9: { country: 8, code: "AO", label: "Angola", currency: "AOA" },
        10: { country: 5, code: "AI", label: "Anguilla", currency: "XCD" },
        11: { country: 9, code: "AQ", label: "Antarctica", currency: "" },
        12: { country: 4, code: "AG", label: "Antigua and Barbuda", currency: "XCD" },
        13: { country: 10, code: "AR", label: "Argentina", currency: "ARS" },
        14: { country: 7, code: "AM", label: "Armenia", currency: "AMD" },
        15: { country: 14, code: "AW", label: "Aruba", currency: "AWG" },
        16: { country: 13, code: "AU", label: "Australia", currency: "AUD" },
        17: { country: 12, code: "AT", label: "Austria", currency: "EUR" },
        18: { country: 16, code: "AZ", label: "Azerbaijan", currency: "AZN" },
        19: { country: 32, code: "BS", label: "Bahamas", currency: "BSD" },
        20: { country: 23, code: "BH", label: "Bahrain", currency: "BHD" },
        21: { country: 19, code: "BD", label: "Bangladesh", currency: "BDT" },
        22: { country: 18, code: "BB", label: "Barbados", currency: "BBD" },
        23: { country: 36, code: "BY", label: "Belarus", currency: "BYR" },
        24: { country: 20, code: "BE", label: "Belgium", currency: "EUR" },
        25: { country: 37, code: "BZ", label: "Belize", currency: "BZD" },
        26: { country: 25, code: "BJ", label: "Benin", currency: "XOF" },
        27: { country: 27, code: "BM", label: "Bermuda", currency: "BMD" },
        28: { country: 33, code: "BT", label: "Bhutan", currency: "BTN" },
        29: { country: 29, code: "BO", label: "Bolivia", currency: "BOB" },
        30: { country: 30, code: "BQ", label: "Bonaire, Sint Eustatius and Saba", currency: "USD" },
        31: { country: 17, code: "BA", label: "Bosnia and Herzegovina", currency: "BAM" },
        32: { country: 35, code: "BW", label: "Botswana", currency: "BWP" },
        33: { country: 34, code: "BV", label: "Bouvet Island", currency: "NOK" },
        34: { country: 31, code: "BR", label: "Brazil", currency: "BRL" },
        35: { country: 106, code: "IO", label: "British Indian Ocean Territory", currency: "USD" },
        36: { country: 239, code: "VG", label: "British Virgin Islands", currency: "USD" },
        37: { country: 28, code: "BN", label: "Brunei Darussalam", currency: "BND" },
        38: { country: 22, code: "BG", label: "Bulgaria", currency: "BGN" },
        39: { country: 21, code: "BF", label: "Burkina Faso", currency: "XOF" },
        40: { country: 24, code: "BI", label: "Burundi", currency: "BIF" },
        41: { country: 117, code: "KH", label: "Cambodia", currency: "KHR" },
        42: { country: 47, code: "CM", label: "Cameroon", currency: "XAF" },
        43: { country: 52, code: "CV", label: "Cape Verde", currency: "CVE" },
        44: { country: 124, code: "KY", label: "Cayman Islands", currency: "KYD" },
        45: { country: 41, code: "CF", label: "Central African Republic", currency: "XAF" },
        46: { country: 215, code: "TD", label: "Chad", currency: "XAF" },
        47: { country: 46, code: "CL", label: "Chile", currency: "CLP" },
        48: { country: 48, code: "CN", label: "China", currency: "CNY" },
        49: { country: 54, code: "CX", label: "Christmas Island", currency: "AUD" },
        50: { country: 39, code: "CC", label: "Cocos [Keeling] Islands", currency: "AUD" },
        51: { country: 49, code: "CO", label: "Colombia", currency: "COP" },
        52: { country: 119, code: "KM", label: "Comoros", currency: "KMF" },
        53: { country: 45, code: "CK", label: "Cook Islands", currency: "NZD" },
        54: { country: 50, code: "CR", label: "Costa Rica", currency: "CRC" },
        55: { country: 98, code: "HR", label: "Croatia", currency: "HRK" },
        56: { country: 51, code: "CU", label: "Cuba", currency: "CUP" },
        57: { country: 53, code: "CW", label: "Curaçao", currency: "ANG" },
        58: { country: 55, code: "CY", label: "Cyprus", currency: "EUR" },
        59: { country: 56, code: "CZ", label: "Czech Republic", currency: "CZK" },
        60: { country: 40, code: "CD", label: "Democratic Republic of the Congo", currency: "COD" },
        61: { country: 59, code: "DK", label: "Denmark", currency: "DKK" },
        62: { country: 58, code: "DJ", label: "Djibouti", currency: "DJF" },
        63: { country: 60, code: "DM", label: "Dominica", currency: "XCD" },
        64: { country: 61, code: "DO", label: "Dominican Republic", currency: "DOP" },
        65: { country: 221, code: "TL", label: "East Timor", currency: "USD" },
        66: { country: 63, code: "EC", label: "Ecuador", currency: "USD" },
        67: { country: 65, code: "EG", label: "Egypt", currency: "EGP" },
        68: { country: 210, code: "SV", label: "El Salvador", currency: "USD" },
        69: { country: 88, code: "GQ", label: "Equatorial Guinea", currency: "XAF" },
        70: { country: 67, code: "ER", label: "Eritrea", currency: "ERN" },
        71: { country: 64, code: "EE", label: "Estonia", currency: "EUR" },
        72: { country: 69, code: "ET", label: "Ethiopia", currency: "ETB" },
        73: { country: 72, code: "FK", label: "Falkland Islands", currency: "FKP" },
        74: { country: 74, code: "FO", label: "Faroe Islands", currency: "DKK" },
        75: { country: 71, code: "FJ", label: "Fiji", currency: "FJD" },
        76: { country: 70, code: "FI", label: "Finland", currency: "EUR" },
        77: { country: 75, code: "FR", label: "France", currency: "EUR" },
        78: { country: 80, code: "GF", label: "French Guiana", currency: "EUR" },
        79: { country: 175, code: "PF", label: "French Polynesia", currency: "XPF" },
        80: { country: 216, code: "TF", label: "French Southern Territories", currency: "EUR" },
        81: { country: 76, code: "GA", label: "Gabon", currency: "XAF" },
        82: { country: 85, code: "GM", label: "Gambia", currency: "GMD" },
        83: { country: 79, code: "GE", label: "Georgia", currency: "GEL" },
        84: { country: 57, code: "DE", label: "Germany", currency: "EUR" },
        85: { country: 82, code: "GH", label: "Ghana", currency: "GHS" },
        86: { country: 83, code: "GI", label: "Gibraltar", currency: "GIP" },
        87: { country: 89, code: "GR", label: "Greece", currency: "EUR" },
        88: { country: 84, code: "GL", label: "Greenland", currency: "DKK" },
        89: { country: 78, code: "GD", label: "Grenada", currency: "XCD" },
        90: { country: 87, code: "GP", label: "Guadeloupe", currency: "EUR" },
        91: { country: 92, code: "GU", label: "Guam", currency: "USD" },
        92: { country: 91, code: "GT", label: "Guatemala", currency: "GTQ" },
        93: { country: 81, code: "GG", label: "Guernsey", currency: "GBP" },
        94: { country: 86, code: "GN", label: "Guinea", currency: "GNF" },
        95: { country: 93, code: "GW", label: "Guinea-Bissau", currency: "XOF" },
        96: { country: 94, code: "GY", label: "Guyana", currency: "GYD" },
        97: { country: 99, code: "HT", label: "Haiti", currency: "HTG" },
        98: { country: 96, code: "HM", label: "Heard Island and McDonald Islands", currency: "AUD" },
        99: { country: 97, code: "HN", label: "Honduras", currency: "HNL" },
        100: { country: 95, code: "HK", label: "Hong Kong", currency: "HKD" },
        101: { country: 100, code: "HU", label: "Hungary", currency: "HUF" },
        102: { country: 109, code: "IS", label: "Iceland", currency: "ISK" },
        103: { country: 105, code: "IN", label: "India", currency: "INR" },
        104: { country: 101, code: "ID", label: "Indonesia", currency: "IDR" },
        105: { country: 108, code: "IR", label: "Iran", currency: "IRR" },
        106: { country: 107, code: "IQ", label: "Iraq", currency: "IQD" },
        107: { country: 102, code: "IE", label: "Ireland", currency: "EUR" },
        108: { country: 104, code: "IM", label: "Isle of Man", currency: "GBP" },
        109: { country: 103, code: "IL", label: "Israel", currency: "ILS" },
        110: { country: 110, code: "IT", label: "Italy", currency: "EUR" },
        111: { country: 44, code: "CI", label: "Ivory Coast", currency: "XOF" },
        112: { country: 112, code: "JM", label: "Jamaica", currency: "JMD" },
        113: { country: 114, code: "JP", label: "Japan", currency: "JPY" },
        114: { country: 111, code: "JE", label: "Jersey", currency: "GBP" },
        115: { country: 113, code: "JO", label: "Jordan", currency: "JOD" },
        116: { country: 125, code: "KZ", label: "Kazakhstan", currency: "KZT" },
        117: { country: 115, code: "KE", label: "Kenya", currency: "KES" },
        118: { country: 118, code: "KI", label: "Kiribati", currency: "AUD" },
        119: { country: 245, code: "XK", label: "Kosovo", currency: "EUR" },
        120: { country: 123, code: "KW", label: "Kuwait", currency: "KWD" },
        121: { country: 116, code: "KG", label: "Kyrgyzstan", currency: "KGS" },
        122: { country: 126, code: "LA", label: "Laos", currency: "LAK" },
        123: { country: 135, code: "LV", label: "Latvia", currency: "EUR" },
        124: { country: 127, code: "LB", label: "Lebanon", currency: "LBP" },
        125: { country: 132, code: "LS", label: "Lesotho", currency: "LSL" },
        126: { country: 131, code: "LR", label: "Liberia", currency: "LRD" },
        127: { country: 136, code: "LY", label: "Libya", currency: "LYD" },
        128: { country: 129, code: "LI", label: "Liechtenstein", currency: "CHF" },
        129: { country: 133, code: "LT", label: "Lithuania", currency: "EUR" },
        130: { country: 134, code: "LU", label: "Luxembourg", currency: "EUR" },
        131: { country: 148, code: "MO", label: "Macao", currency: "MOP" },
        132: { country: 142, code: "MG", label: "Madagascar", currency: "MGA" },
        133: { country: 156, code: "MW", label: "Malawi", currency: "MWK" },
        134: { country: 158, code: "MY", label: "Malaysia", currency: "MYR" },
        135: { country: 155, code: "MV", label: "Maldives", currency: "MVR" },
        136: { country: 145, code: "ML", label: "Mali", currency: "XOF" },
        137: { country: 153, code: "MT", label: "Malta", currency: "EUR" },
        138: { country: 143, code: "MH", label: "Marshall Islands", currency: "USD" },
        139: { country: 150, code: "MQ", label: "Martinique", currency: "EUR" },
        140: { country: 151, code: "MR", label: "Mauritania", currency: "MRO" },
        141: { country: 154, code: "MU", label: "Mauritius", currency: "MUR" },
        142: { country: 247, code: "YT", label: "Mayotte", currency: "EUR" },
        143: { country: 157, code: "MX", label: "Mexico", currency: "MXN" },
        144: { country: 73, code: "FM", label: "Micronesia", currency: "USD" },
        145: { country: 139, code: "MD", label: "Moldova", currency: "MDL" },
        146: { country: 138, code: "MC", label: "Monaco", currency: "EUR" },
        147: { country: 147, code: "MN", label: "Mongolia", currency: "MNT" },
        148: { country: 140, code: "ME", label: "Montenegro", currency: "EUR" },
        149: { country: 152, code: "MS", label: "Montserrat", currency: "XCD" },
        150: { country: 137, code: "MA", label: "Morocco", currency: "MAD" },
        151: { country: 159, code: "MZ", label: "Mozambique", currency: "MZN" },
        152: { country: 146, code: "MM", label: "Myanmar [Burma]", currency: "MMK" },
        153: { country: 160, code: "NA", label: "Namibia", currency: "NAD" },
        154: { country: 169, code: "NR", label: "Nauru", currency: "AUD" },
        155: { country: 168, code: "NP", label: "Nepal", currency: "NPR" },
        156: { country: 166, code: "NL", label: "Netherlands", currency: "EUR" },
        157: { country: 161, code: "NC", label: "New Caledonia", currency: "XPF" },
        158: { country: 171, code: "NZ", label: "New Zealand", currency: "NZD" },
        159: { country: 165, code: "NI", label: "Nicaragua", currency: "NIO" },
        160: { country: 162, code: "NE", label: "Niger", currency: "XOF" },
        161: { country: 164, code: "NG", label: "Nigeria", currency: "NGN" },
        162: { country: 170, code: "NU", label: "Niue", currency: "NZD" },
        163: { country: 163, code: "NF", label: "Norfolk Island", currency: "AUD" },
        164: { country: 121, code: "KP", label: "North Korea", currency: "KPW" },
        165: { country: 144, code: "MK", label: "North Macedonia", currency: "MKD" },
        166: { country: 149, code: "MP", label: "Northern Mariana Islands", currency: "USD" },
        167: { country: 167, code: "NO", label: "Norway", currency: "NOK" },
        168: { country: 172, code: "OM", label: "Oman", currency: "OMR" },
        169: { country: 178, code: "PK", label: "Pakistan", currency: "PKR" },
        170: { country: 185, code: "PW", label: "Palau", currency: "USD" },
        171: { country: 183, code: "PS", label: "Palestine", currency: "ILS" },
        172: { country: 173, code: "PA", label: "Panama", currency: "PAB" },
        173: { country: 176, code: "PG", label: "Papua New Guinea", currency: "PGK" },
        174: { country: 186, code: "PY", label: "Paraguay", currency: "PYG" },
        175: { country: 174, code: "PE", label: "Peru", currency: "PEN" },
        176: { country: 177, code: "PH", label: "Philippines", currency: "PHP" },
        177: { country: 181, code: "PN", label: "Pitcairn Islands", currency: "NZD" },
        178: { country: 179, code: "PL", label: "Poland", currency: "PLN" },
        179: { country: 184, code: "PT", label: "Portugal", currency: "EUR" },
        180: { country: 182, code: "PR", label: "Puerto Rico", currency: "USD" },
        181: { country: 187, code: "QA", label: "Qatar", currency: "QAR" },
        182: { country: 42, code: "CG", label: "Republic of the Congo", currency: "XAF" },
        183: { country: 188, code: "RE", label: "Réunion", currency: "EUR" },
        184: { country: 189, code: "RO", label: "Romania", currency: "RON" },
        185: { country: 191, code: "RU", label: "Russia", currency: "RUB" },
        186: { country: 192, code: "RW", label: "Rwanda", currency: "RWF" },
        187: { country: 26, code: "BL", label: "Saint Barthélemy", currency: "EUR" },
        188: { country: 199, code: "SH", label: "Saint Helena", currency: "SHP" },
        189: { country: 120, code: "KN", label: "Saint Kitts and Nevis", currency: "XCD" },
        190: { country: 128, code: "LC", label: "Saint Lucia", currency: "XCD" },
        191: { country: 141, code: "MF", label: "Saint Martin", currency: "EUR" },
        192: { country: 180, code: "PM", label: "Saint Pierre and Miquelon", currency: "EUR" },
        193: { country: 237, code: "VC", label: "Saint Vincent and the Grenadines", currency: "XCD" },
        194: { country: 244, code: "WS", label: "Samoa", currency: "WST" },
        195: { country: 204, code: "SM", label: "San Marino", currency: "EUR" },
        196: { country: 209, code: "ST", label: "São Tomé and Príncipe", currency: "STD" },
        197: { country: 193, code: "SA", label: "Saudi Arabia", currency: "SAR" },
        198: { country: 205, code: "SN", label: "Senegal", currency: "XOF" },
        199: { country: 190, code: "RS", label: "Serbia", currency: "RSD" },
        200: { country: 195, code: "SC", label: "Seychelles", currency: "SCR" },
        201: { country: 203, code: "SL", label: "Sierra Leone", currency: "SLL" },
        202: { country: 198, code: "SG", label: "Singapore", currency: "SGD" },
        203: { country: 211, code: "SX", label: "Sint Maarten", currency: "ANG" },
        204: { country: 202, code: "SK", label: "Slovakia", currency: "EUR" },
        205: { country: 200, code: "SI", label: "Slovenia", currency: "EUR" },
        206: { country: 194, code: "SB", label: "Solomon Islands", currency: "SBD" },
        207: { country: 206, code: "SO", label: "Somalia", currency: "SOS" },
        208: { country: 248, code: "ZA", label: "South Africa", currency: "ZAR" },
        209: { country: 90, code: "GS", label: "South Georgia and the South Sandwich Islands", currency: "GBP" },
        210: { country: 122, code: "KR", label: "South Korea", currency: "KRW" },
        211: { country: 208, code: "SS", label: "South Sudan", currency: "SSP" },
        212: { country: 68, code: "ES", label: "Spain", currency: "EUR" },
        213: { country: 130, code: "LK", label: "Sri Lanka", currency: "LKR" },
        214: { country: 196, code: "SD", label: "Sudan", currency: "SDG" },
        215: { country: 207, code: "SR", label: "Suriname", currency: "SRD" },
        216: { country: 201, code: "SJ", label: "Svalbard and Jan Mayen", currency: "NOK" },
        217: { country: 213, code: "SZ", label: "Swaziland", currency: "SZL" },
        218: { country: 197, code: "SE", label: "Sweden", currency: "SEK" },
        219: { country: 43, code: "CH", label: "Switzerland", currency: "CHF" },
        220: { country: 212, code: "SY", label: "Syria", currency: "SYP" },
        221: { country: 228, code: "TW", label: "Taiwan", currency: "TWD" },
        222: { country: 219, code: "TJ", label: "Tajikistan", currency: "TJS" },
        223: { country: 229, code: "TZ", label: "Tanzania", currency: "TZS" },
        224: { country: 218, code: "TH", label: "Thailand", currency: "THB" },
        225: { country: 217, code: "TG", label: "Togo", currency: "XOF" },
        226: { country: 220, code: "TK", label: "Tokelau", currency: "NZD" },
        227: { country: 224, code: "TO", label: "Tonga", currency: "TOP" },
        228: { country: 226, code: "TT", label: "Trinidad and Tobago", currency: "TTD" },
        229: { country: 223, code: "TN", label: "Tunisia", currency: "TND" },
        230: { country: 225, code: "TR", label: "Turkey", currency: "TRY" },
        231: { country: 222, code: "TM", label: "Turkmenistan", currency: "TMT" },
        232: { country: 214, code: "TC", label: "Turks and Caicos Islands", currency: "USD" },
        233: { country: 227, code: "TV", label: "Tuvalu", currency: "AUD" },
        234: { country: 232, code: "UM", label: "U.S. Minor Outlying Islands", currency: "USD" },
        235: { country: 240, code: "VI", label: "U.S. Virgin Islands", currency: "USD" },
        236: { country: 231, code: "UG", label: "Uganda", currency: "UGX" },
        237: { country: 230, code: "UA", label: "Ukraine", currency: "UAH" },
        238: { country: 2, code: "AE", label: "United Arab Emirates", currency: "AED" },
        239: { country: 77, code: "GB", label: "United Kingdom of Great Britain and Northern Ireland", currency: "GBP" },
        240: { country: 234, code: "UY", label: "Uruguay", currency: "UYU" },
        241: { country: 235, code: "UZ", label: "Uzbekistan", currency: "UZS" },
        242: { country: 242, code: "VU", label: "Vanuatu", currency: "VUV" },
        243: { country: 236, code: "VA", label: "Vatican City", currency: "EUR" },
        244: { country: 238, code: "VE", label: "Venezuela", currency: "VEF" },
        245: { country: 241, code: "VN", label: "Vietnam", currency: "VND" },
        246: { country: 243, code: "WF", label: "Wallis and Futuna", currency: "XPF" },
        247: { country: 66, code: "EH", label: "Western Sahara", currency: "MAD" },
        248: { country: 246, code: "YE", label: "Yemen", currency: "YER" },
        249: { country: 249, code: "ZM", label: "Zambia", currency: "ZMW" },
        250: { country: 250, code: "ZW", label: "Zimbabwe", currency: "ZWL" }
    };
    static GetByID(id) {
        return typeof Regions.#map[id] !== "undefined" ? Regions.#map[id] : null;
    }

    /**
     * Defines the set of currencies that should be marked as selected
     * @function
     * @param {array} list
     * @example [1, 2]
     * @returns {array} A list of {id, label} entries
     */
    static Selected(list) {
        let selected = [];
        for (let i = 0; i < list.length; i++) {
            if (typeof Regions.#map[list[i]] != "undefined") {
                selected.push({
                    label: Regions.#map[list[i]].label,
                    value: list[i],
                });
            }
        }
        return selected;
    }

    /**
     * Returns only the numeric "value" of the items in the list
     * @function
     * @param {array} list
     * @example [{id: 1, label: "A"}, {id: 2, label: "B"}]
     * @returns {array} Array of integers representing the "value" field of each entry in the list
     */
    static Values(list) {
        let selected = [];
        for (let i = 0; i < list.length; i++) {
            selected.push(list[i].value);
        }
        return selected;
    }

    setSelected = (elements) => {
        if (this.props.singleValue === true) {
            if (elements.length > 1) {
                elements.splice(0, 1);
            }
        }
        this.setState({ value: elements });
        this.props.onChange(elements, this.props.name);
    };

    customValueRenderer = (selected) => {
        return selected.length
            ? selected.map(
                ({ value, label }) =>
                    typeof value != "undefined" && (
                        <span key={`uniq${value}`}>
                            <ReactCountryFlag
                                countryCode={Regions.#map[value].code}
                                className="flag"
                            />
                            {label}
                        </span>
                    )
            )
            : "Select...";
    };

    render() {
        let className = this.props.disabled ? "disabled" : "";
        return (
            <MultiSelect
                className={`selRegion ${className}`}
                options={Regions.OPTIONS}
                value={this.props.value}
                onChange={this.setSelected}
                labelledBy="Select"
                hasSelectAll={false}
                disabled={this.props.disabled}
                valueRenderer={this.customValueRenderer}
            />
        );
    }
}

Regions.propTypes = {
    /** Selling Region under selection */
    value: PropTypes.array.isRequired,
    /** OnChange callback function */
    onChange: PropTypes.func.isRequired,
    /** Should this component be disabled? */
    disabled: PropTypes.bool,
};

export default Regions;
