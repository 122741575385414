import React from "react";

import { Nav, Carousel, Container, Breadcrumb } from "react-bootstrap";
import Summary from "./Summary";
import Emitter from "../../services/Emitter";
import Profile from "./Profile";
import Notes from "./Notes";
import Volume from "./Volume";
import Linked from "./Linked";
import Trends from "./Trends";
import MerchantService from "../../services/MerchantService";
import MyCarousel from "../../components/MyCarousel";

class Index extends React.Component {
    constructor(props) {
        super(props);
        let merchant_id = parseInt(window.location.href.match(/merchant\/(\d+)/)[1]),
            page = window.location.href.match(/merchant\/\d+(#\w+)/);

        this.state = {
            id: merchant_id,
            name: '...',
            page: page === null ? "#summary" : page[1]
        };

        this.reloadBreadcrumb = this.reloadBreadcrumb.bind(this);
        this.notifyTabs = this.notifyTabs.bind(this);

        // triggered on URL changes
        this.unlisten = this.props.history.listen((location, action) => {
            let id = location.pathname.match(/merchant\/(\d+)/);
            if (id === null) {
                return;
            }
            let merchant_id = parseInt(id[1]);
            if (merchant_id !== this.state.id) {
                this.setState({
                    id: merchant_id,
                    page: location.hash.length ? location.hash : '#summary'
                }, () => {
                    Emitter.emit("MERCHANT_RESET");
                    this.reloadBreadcrumb();
                    this.notifyTabs();
                });
            } else if (location.hash !== this.state.page) {
                this.setState({
                    page: location.hash
                }, this.notifyTabs);
            }
        });
    }


    notifyTabs() {
        const page = this.getMap(this.state.page);
        Emitter.emit(page.event);
    }

    reloadBreadcrumb() {
        MerchantService.breadcrumb(this.state.id).then((name) => {
            this.setState({ name: name });
        }).catch(() => {
            this.setState({ name: '?' });
        })
    }

    componentDidMount() {
        this.reloadBreadcrumb();
        this.notifyTabs();
    }

    componentWillUnmount() {
        this.unlisten();
    }

    getMap(tab) {
        let key, event;
        if (tab === "#profile") {
            key = 1;
            event = "MERCHANT_PROFILE";
        } else if (tab === "#linked") {
            key = 2;
            event = "MERCHANT_LINKED";
        } else if (tab === "#volume") {
            key = 3;
            event = "MERCHANT_VOLUME";
        } else if (tab === "#trends") {
            key = 4;
            event = "MERCHANT_TRENDS";
        } else if (tab === "#notes") {
            key = 5;
            event = "MERCHANT_NOTES";
        } else {
            key = 0;
            event = "MERCHANT_SUMMARY";
        }
        return {
            key,
            event
        };
    }


    render() {
        const page = this.getMap(this.state.page),
            pages = (
                <MyCarousel activeIndex={page.key}>
                    <Carousel.Item key="#summary" className="standardGrid">
                        <Summary merchantId={this.state.id} />
                    </Carousel.Item>
                    <Carousel.Item key="#profile" className="standardGrid">
                        <Profile merchantId={this.state.id} />
                    </Carousel.Item>
                    <Carousel.Item key="#linked" className="standardGrid">
                        <Linked merchantId={this.state.id} />
                    </Carousel.Item>
                    <Carousel.Item key="#volume" className="standardGrid">
                        <Volume merchantId={this.state.id} />
                    </Carousel.Item>
                    <Carousel.Item key="#trends" className="standardGrid">
                        <Trends merchantId={this.state.id} />
                    </Carousel.Item>
                    <Carousel.Item key="#notes" className="standardGrid">
                        <Notes merchantId={this.state.id} />
                    </Carousel.Item>
                </MyCarousel>
            );

        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Merchant</Breadcrumb.Item>
                    <Breadcrumb.Item active>{this.state.name}</Breadcrumb.Item>
                </Breadcrumb>
                <Nav variant="tabs" activeKey={this.state.page}>
                    <Nav.Item>
                        <Nav.Link href="#summary">Summary</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#profile">Profile</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#linked">Linked Channels</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#volume">Volume</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#trends">Trends</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#notes">Notes</Nav.Link>
                    </Nav.Item>
                </Nav>
                {pages}
            </Container>
        );
    }
}
export default Index;
