import React, { createRef } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import BrandService from "../services/BrandService";
import WebsiteService from "../services/WebsiteService";
import Toast from "../services/Toast";
import { Button, Col, Container, OverlayTrigger, Popover, PopoverBody, Row } from "react-bootstrap";
import MyTable from "../components/MyTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import Emitter from "../services/Emitter";
import Utils from "../services/Utils";


class EntityCosts extends React.Component {
    constructor(props) {
        super(props);
        this.onEnter = this.onEnter.bind(this);
        this.updateTotal = this.updateTotal.bind(this);
        this.tableRef = createRef();
        this.state = {
            total: null,
            subTotal: null,
            day: null,
            filterCategory: [],
            filterUse: [],
            filterBillSource: [],
            loading: true
        }
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
    }

    componentDidMount() {
        Emitter.once(this.props.type.toUpperCase() + "_COSTS", this.onEnter);
    }

    componentWillUnmount() {
        Emitter.off(this.props.type.toUpperCase() + "_COSTS");
    }

    updateTotal(items) {
        let total = 0;
        items.forEach((row) => {
            total += row.divided_cost;
        })
        this.setState({ subTotal: total });
    }

    downloadSpreadsheet() {
        this.tableRef.current.CSV({
            filename: this.props.type + "-" + this.props.id + "-bill-" + Utils.DateNow().replaceAll(' ', '-') + ".csv"
        })
    }

    pricePerCrawl(cost, crawls) {
        return crawls ? cost / crawls : null
    }

    onEnter() {
        const c = this.props.type === "brand" ? BrandService : WebsiteService;
        c.costs(this.props.id).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the " + this.props.type + "'s costs");
                return;
            }

            let items = [],
                categories = Object.keys(data.items),
                total = 0,
                filterUse = {},
                filterCategory = {},
                filterBillSource = {};

            categories.forEach((category) => {

                filterCategory[category] = {
                    label: category,
                    value: category
                };

                data.items[category].forEach((row) => {
                    let share = row.use.share / row.use.total;

                    filterUse[row.use.division] = {
                        label: row.use.division,
                        value: row.use.division
                    };

                    filterBillSource[row.bill.source] = {
                        label: row.bill.source,
                        value: row.bill.source
                    };

                    items.push({
                        category,
                        resource: row.label,
                        unit: row.unit,
                        division: row.use.division,
                        total_use: row.use.total,
                        share: row.use.share,
                        divided_use: share * 100,
                        total_cost: row.bill.total,
                        divided_cost: row.bill.total * share,
                        bill_source: row.bill.source
                    });
                    total += row.bill.total * share;
                });
            });
            this.setState({
                day: data.date,
                total,
                crawls: data.crawls,
                ppc: this.pricePerCrawl(total, data.crawls),
                filterCategory: Object.values(filterCategory),
                filterUse: Object.values(filterUse),
                filterBillSource: Object.values(filterBillSource),
                loading: false

            }, () => {
                const tableRef = this.tableRef.current ?? {populate: ()=>{}};
                tableRef.populate(items);
            });

            if (items.filter((item) => item.total_cost === 0).length) {
                Toast.info("Mind that some bills are missing their totals due to technical reasons", 5000);
            }
        });
    }

    render() {
        return (
            <Container className="pt-4">
                <MyTable
                    name={`${this.props.type}-costs`}
                    dynamic={false}
                    ref={this.tableRef}
                    showPagination={false}
                    sortBy="divided_cost"
                    sortOrder="desc"
                    callbackFiltering={this.updateTotal}
                    headers={
                        [
                            {
                                field: "category",
                                label: "Category",
                                sortable: true,
                                allowEmpty: true,
                                options: this.state.filterCategory
                            },
                            {
                                field: "resource",
                                label: "Resource",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "division",
                                label: "Pay per",
                                width: "100px",
                                sortable: true,
                                allowEmpty: true,
                                options: this.state.filterUse
                            },
                            {
                                field: "total_use",
                                label: "TS Total",
                                width: "100px",
                                sortable: true
                            },
                            {
                                field: "share",
                                label: Utils.Capitalize(this.props.type) + "'s total",
                                width: "110px",
                                sortable: true
                            },
                            {
                                field: "divided_use",
                                label: "%",
                                width: "50px",
                                sortable: true
                            },
                            {
                                field: "total_cost",
                                width: "90px",
                                label: "Total bill",
                                hint: `From ${this.state.day}`,
                                sortable: true
                            },
                            {
                                field: "bill_source",
                                width: "90px",
                                label: "Bill Source",
                                sortable: true,
                                allowEmpty: true,
                                options: this.state.filterBillSource
                            },
                            {
                                field: "divided_cost",
                                label: "$",
                                width: "60px",
                                sortable: true,
                                showSum: true
                            }
                        ]
                    }
                    renderColumns={{
                        divided_use: (row) => row.divided_use.toFixed(2),
                        divided_cost: (row) => row.divided_cost.toFixed(3),
                        total_cost: (row) => row.total_cost === 0 ? <OverlayTrigger
                            placement="top"
                            trigger={["hover", "focus"]}
                            overlay={
                                <Popover>
                                    <PopoverBody>Missing bill</PopoverBody>
                                </Popover>
                            }
                        >
                            <b style={{ color: "red" }}>0.00 <FontAwesomeIcon icon={faExclamationTriangle} /></b>
                        </OverlayTrigger> : row.total_cost.toFixed(2)
                    }}
                />
                {this.state.subTotal !== null && (
                    <div style={{

                        fontSize: "small",
                        position: "absolute",
                        right: "0px",
                        marginRight: "20px"
                    }}>
                        <span>Sub-total: </span>
                        <span><b>{this.state.subTotal.toFixed(2)}</b></span>
                    </div>

                )}

                {this.state.total !== null && (
                    <React.Fragment>
                        <Row>
                            <Col className="text-center">
                                <Button disabled={this.state.loading} onClick={this.downloadSpreadsheet} className="tdButton" size="sm"><FontAwesomeIcon icon={faDownload} style={{ marginRight: "10px" }} />Download</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                A total of <b>${this.state.total.toFixed(2)}</b> was spent on <b>{this.state.day}</b> for extracting <b>{this.state.crawls}</b> pricing data points
                                {this.state.ppc !== null && <span>, meaning each data point cost <b>${Utils.AsManyDecimalsAsNeeded(this.state.ppc)}</b></span>}.
                                <p>The forecast for the next month is <b>${(this.state.total * 30).toFixed(2)}</b></p>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </Container>
        );
    }
}

EntityCosts.propTypes = {
    type: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
};

export default withRouter(EntityCosts);
