import React from "react";
import PropTypes from "prop-types";

import MultiSelect from "react-multi-select-component";
import ReactCountryFlag from "react-country-flag";
import "./Currencies.css";

/**
 * Shows a currency MultiSelect field
 * @component
 */
class Currencies extends React.Component {
    constructor(props) {
        super(props);
        if (props.singleValue && props.value.length > 0) {
            props.value.splice(1);
        }
    }

    /**
     * All supported currencies
     */
    static OPTIONS = [
        { label: "AED", value: 3 },
        { label: "AFN", value: 4 },
        { label: "ALL", value: 5 },
        { label: "AMD", value: 6 },
        { label: "ANG", value: 7 },
        { label: "AOA", value: 8 },
        { label: "ARS", value: 9 },
        { label: "AUD", value: 10 },
        { label: "AWG", value: 11 },
        { label: "AZN", value: 12 },
        { label: "BAM", value: 13 },
        { label: "BBD", value: 14 },
        { label: "BDT", value: 15 },
        { label: "BGN", value: 16 },
        { label: "BHD", value: 17 },
        { label: "BIF", value: 18 },
        { label: "BMD", value: 19 },
        { label: "BND", value: 20 },
        { label: "BOB", value: 21 },
        { label: "BRL", value: 22 },
        { label: "BSD", value: 23 },
        { label: "BTN", value: 24 },
        { label: "BWP", value: 25 },
        { label: "BYR", value: 26 },
        { label: "BZD", value: 27 },
        { label: "CAD", value: 2 },
        { label: "CHF", value: 28 },
        { label: "CLP", value: 29 },
        { label: "CNY", value: 30 },
        { label: "COD", value: 31 },
        { label: "COP", value: 32 },
        { label: "CRC", value: 33 },
        { label: "CUP", value: 34 },
        { label: "CVE", value: 35 },
        { label: "CZK", value: 36 },
        { label: "DJF", value: 37 },
        { label: "DKK", value: 38 },
        { label: "DOP", value: 39 },
        { label: "DZD", value: 40 },
        { label: "EGP", value: 41 },
        { label: "ERN", value: 42 },
        { label: "ETB", value: 43 },
        { label: "EUR", value: 44 },
        { label: "FJD", value: 45 },
        { label: "FKP", value: 46 },
        { label: "GBP", value: 47 },
        { label: "GEL", value: 48 },
        { label: "GHS", value: 49 },
        { label: "GIP", value: 50 },
        { label: "GMD", value: 51 },
        { label: "GNF", value: 52 },
        { label: "GTQ", value: 53 },
        { label: "GYD", value: 54 },
        { label: "HKD", value: 55 },
        { label: "HNL", value: 56 },
        { label: "HRK", value: 57 },
        { label: "HTG", value: 58 },
        { label: "HUF", value: 59 },
        { label: "IDR", value: 60 },
        { label: "ILS", value: 61 },
        { label: "INR", value: 62 },
        { label: "IQD", value: 63 },
        { label: "IRR", value: 64 },
        { label: "ISK", value: 65 },
        { label: "JMD", value: 66 },
        { label: "JOD", value: 67 },
        { label: "JPY", value: 68 },
        { label: "KES", value: 69 },
        { label: "KGS", value: 70 },
        { label: "KHR", value: 71 },
        { label: "KMF", value: 72 },
        { label: "KPW", value: 73 },
        { label: "KRW", value: 74 },
        { label: "KWD", value: 75 },
        { label: "KYD", value: 76 },
        { label: "KZT", value: 77 },
        { label: "LAK", value: 78 },
        { label: "LBP", value: 79 },
        { label: "LKR", value: 80 },
        { label: "LRD", value: 81 },
        { label: "LSL", value: 82 },
        { label: "LYD", value: 83 },
        { label: "MAD", value: 84 },
        { label: "MDL", value: 85 },
        { label: "MGA", value: 86 },
        { label: "MKD", value: 87 },
        { label: "MMK", value: 88 },
        { label: "MNT", value: 89 },
        { label: "MOP", value: 90 },
        { label: "MRO", value: 91 },
        { label: "MUR", value: 92 },
        { label: "MVR", value: 93 },
        { label: "MWK", value: 94 },
        { label: "MXN", value: 95 },
        { label: "MYR", value: 96 },
        { label: "MZN", value: 97 },
        { label: "NAD", value: 98 },
        { label: "NGN", value: 99 },
        { label: "NIO", value: 100 },
        { label: "NOK", value: 101 },
        { label: "NPR", value: 102 },
        { label: "NZD", value: 103 },
        { label: "OMR", value: 104 },
        { label: "PAB", value: 105 },
        { label: "PEN", value: 106 },
        { label: "PGK", value: 107 },
        { label: "PHP", value: 108 },
        { label: "PKR", value: 109 },
        { label: "PLN", value: 110 },
        { label: "PYG", value: 111 },
        { label: "QAR", value: 112 },
        { label: "RON", value: 113 },
        { label: "RSD", value: 114 },
        { label: "RUB", value: 115 },
        { label: "RWF", value: 116 },
        { label: "SAR", value: 117 },
        { label: "SBD", value: 118 },
        { label: "SCR", value: 119 },
        { label: "SDG", value: 120 },
        { label: "SEK", value: 121 },
        { label: "SGD", value: 122 },
        { label: "SHP", value: 123 },
        { label: "SLL", value: 124 },
        { label: "SOS", value: 125 },
        { label: "SRD", value: 126 },
        { label: "SSP", value: 127 },
        { label: "STD", value: 128 },
        { label: "SYP", value: 129 },
        { label: "SZL", value: 130 },
        { label: "THB", value: 131 },
        { label: "TJS", value: 132 },
        { label: "TMT", value: 133 },
        { label: "TND", value: 134 },
        { label: "TOP", value: 135 },
        { label: "TRY", value: 136 },
        { label: "TTD", value: 137 },
        { label: "TWD", value: 138 },
        { label: "TZS", value: 139 },
        { label: "UAH", value: 140 },
        { label: "UGX", value: 141 },
        { label: "USD", value: 1 },
        { label: "UYU", value: 142 },
        { label: "UZS", value: 143 },
        { label: "VEF", value: 144 },
        { label: "VND", value: 145 },
        { label: "VUV", value: 146 },
        { label: "WST", value: 147 },
        { label: "XAF", value: 148 },
        { label: "XCD", value: 149 },
        { label: "XOF", value: 150 },
        { label: "XPF", value: 151 },
        { label: "YER", value: 152 },
        { label: "ZAR", value: 153 },
        { label: "ZMW", value: 154 },
        { label: "ZWL", value: 155 },
    ];

    static #map = {
        3: "AED",
        4: "AFN",
        5: "ALL",
        6: "AMD",
        7: "ANG",
        8: "AOA",
        9: "ARS",
        10: "AUD",
        11: "AWG",
        12: "AZN",
        13: "BAM",
        14: "BBD",
        15: "BDT",
        16: "BGN",
        17: "BHD",
        18: "BIF",
        19: "BMD",
        20: "BND",
        21: "BOB",
        22: "BRL",
        23: "BSD",
        24: "BTN",
        25: "BWP",
        26: "BYR",
        27: "BZD",
        2: "CAD",
        28: "CHF",
        29: "CLP",
        30: "CNY",
        31: "COD",
        32: "COP",
        33: "CRC",
        34: "CUP",
        35: "CVE",
        36: "CZK",
        37: "DJF",
        38: "DKK",
        39: "DOP",
        40: "DZD",
        41: "EGP",
        42: "ERN",
        43: "ETB",
        44: "EUR",
        45: "FJD",
        46: "FKP",
        47: "GBP",
        48: "GEL",
        49: "GHS",
        50: "GIP",
        51: "GMD",
        52: "GNF",
        53: "GTQ",
        54: "GYD",
        55: "HKD",
        56: "HNL",
        57: "HRK",
        58: "HTG",
        59: "HUF",
        60: "IDR",
        61: "ILS",
        62: "INR",
        63: "IQD",
        64: "IRR",
        65: "ISK",
        66: "JMD",
        67: "JOD",
        68: "JPY",
        69: "KES",
        70: "KGS",
        71: "KHR",
        72: "KMF",
        73: "KPW",
        74: "KRW",
        75: "KWD",
        76: "KYD",
        77: "KZT",
        78: "LAK",
        79: "LBP",
        80: "LKR",
        81: "LRD",
        82: "LSL",
        83: "LYD",
        84: "MAD",
        85: "MDL",
        86: "MGA",
        87: "MKD",
        88: "MMK",
        89: "MNT",
        90: "MOP",
        91: "MRO",
        92: "MUR",
        93: "MVR",
        94: "MWK",
        95: "MXN",
        96: "MYR",
        97: "MZN",
        98: "NAD",
        99: "NGN",
        100: "NIO",
        101: "NOK",
        102: "NPR",
        103: "NZD",
        104: "OMR",
        105: "PAB",
        106: "PEN",
        107: "PGK",
        108: "PHP",
        109: "PKR",
        110: "PLN",
        111: "PYG",
        112: "QAR",
        113: "RON",
        114: "RSD",
        115: "RUB",
        116: "RWF",
        117: "SAR",
        118: "SBD",
        119: "SCR",
        120: "SDG",
        121: "SEK",
        122: "SGD",
        123: "SHP",
        124: "SLL",
        125: "SOS",
        126: "SRD",
        127: "SSP",
        128: "STD",
        129: "SYP",
        130: "SZL",
        131: "THB",
        132: "TJS",
        133: "TMT",
        134: "TND",
        135: "TOP",
        136: "TRY",
        137: "TTD",
        138: "TWD",
        139: "TZS",
        140: "UAH",
        141: "UGX",
        1: "USD",
        142: "UYU",
        143: "UZS",
        144: "VEF",
        145: "VND",
        146: "VUV",
        147: "WST",
        148: "XAF",
        149: "XCD",
        150: "XOF",
        151: "XPF",
        152: "YER",
        153: "ZAR",
        154: "ZMW",
        155: "ZWL",
        "AED": 3,
        "AFN": 4,
        "ALL": 5,
        "AMD": 6,
        "ANG": 7,
        "AOA": 8,
        "ARS": 9,
        "AUD": 10,
        "AWG": 11,
        "AZN": 12,
        "BAM": 13,
        "BBD": 14,
        "BDT": 15,
        "BGN": 16,
        "BHD": 17,
        "BIF": 18,
        "BMD": 19,
        "BND": 20,
        "BOB": 21,
        "BRL": 22,
        "BSD": 23,
        "BTN": 24,
        "BWP": 25,
        "BYR": 26,
        "BZD": 27,
        "CAD": 2,
        "CHF": 28,
        "CLP": 29,
        "CNY": 30,
        "COD": 31,
        "COP": 32,
        "CRC": 33,
        "CUP": 34,
        "CVE": 35,
        "CZK": 36,
        "DJF": 37,
        "DKK": 38,
        "DOP": 39,
        "DZD": 40,
        "EGP": 41,
        "ERN": 42,
        "ETB": 43,
        "EUR": 44,
        "FJD": 45,
        "FKP": 46,
        "GBP": 47,
        "GEL": 48,
        "GHS": 49,
        "GIP": 50,
        "GMD": 51,
        "GNF": 52,
        "GTQ": 53,
        "GYD": 54,
        "HKD": 55,
        "HNL": 56,
        "HRK": 57,
        "HTG": 58,
        "HUF": 59,
        "IDR": 60,
        "ILS": 61,
        "INR": 62,
        "IQD": 63,
        "IRR": 64,
        "ISK": 65,
        "JMD": 66,
        "JOD": 67,
        "JPY": 68,
        "KES": 69,
        "KGS": 70,
        "KHR": 71,
        "KMF": 72,
        "KPW": 73,
        "KRW": 74,
        "KWD": 75,
        "KYD": 76,
        "KZT": 77,
        "LAK": 78,
        "LBP": 79,
        "LKR": 80,
        "LRD": 81,
        "LSL": 82,
        "LYD": 83,
        "MAD": 84,
        "MDL": 85,
        "MGA": 86,
        "MKD": 87,
        "MMK": 88,
        "MNT": 89,
        "MOP": 90,
        "MRO": 91,
        "MUR": 92,
        "MVR": 93,
        "MWK": 94,
        "MXN": 95,
        "MYR": 96,
        "MZN": 97,
        "NAD": 98,
        "NGN": 99,
        "NIO": 100,
        "NOK": 101,
        "NPR": 102,
        "NZD": 103,
        "OMR": 104,
        "PAB": 105,
        "PEN": 106,
        "PGK": 107,
        "PHP": 108,
        "PKR": 109,
        "PLN": 110,
        "PYG": 111,
        "QAR": 112,
        "RON": 113,
        "RSD": 114,
        "RUB": 115,
        "RWF": 116,
        "SAR": 117,
        "SBD": 118,
        "SCR": 119,
        "SDG": 120,
        "SEK": 121,
        "SGD": 122,
        "SHP": 123,
        "SLL": 124,
        "SOS": 125,
        "SRD": 126,
        "SSP": 127,
        "STD": 128,
        "SYP": 129,
        "SZL": 130,
        "THB": 131,
        "TJS": 132,
        "TMT": 133,
        "TND": 134,
        "TOP": 135,
        "TRY": 136,
        "TTD": 137,
        "TWD": 138,
        "TZS": 139,
        "UAH": 140,
        "UGX": 141,
        "USD": 1,
        "UYU": 142,
        "UZS": 143,
        "VEF": 144,
        "VND": 145,
        "VUV": 146,
        "WST": 147,
        "XAF": 148,
        "XCD": 149,
        "XOF": 150,
        "XPF": 151,
        "YER": 152,
        "ZAR": 153,
        "ZMW": 154,
        "ZWL": 155,
    };
    static CodeByID(id) {
        return typeof Currencies.#map[id] !== "undefined" ? Currencies.#map[id] : '';
    }
    static IDByCode(code) {
        return typeof Currencies.#map[code] !== "undefined" ? Currencies.#map[code] : '';
    }
    /**
     * Defines the set of currencies that should be marked as selected
     * @function
     * @param {array} list
     * @example [1, 2]
     * @returns {array} A list of {id, label} entries
     */
    static Selected(list) {
        let selected = [];
        for (let i = 0; i < list.length; i++) {
            selected.push({
                label: Currencies.#map[list[i]],
                value: parseInt(list[i]),
            });
        }
        return selected;
    }
    /**
     * Returns only the numeric "value" of the items in the list
     * @function
     * @param {array} list
     * @example [{id: 1, label: "A"}, {id: 2, label: "B"}]
     * @returns {array} Array of integers representing the "value" field of each entry in the list
     */
    static Values(list) {
        let selected = [];
        for (let i = 0; i < list.length; i++) {
            selected.push(list[i].value);
        }
        return selected;
    }

    setSelected = (elements) => {
        if (this.props.singleValue === true) {
            if (elements.length > 1) {
                elements.splice(0, 1);
            }
        }
        this.setState({ value: elements });
        this.props.onChange(elements, this.props.name);
    };

    customValueRenderer = (selected) => {
        return selected.length
            ? selected.map(
                ({ value, label }) =>
                    typeof value != "undefined" && (
                        <span key={`uniq${value}`}>
                            {typeof Currencies.#map[value] === "string" &&
                                <ReactCountryFlag
                                    data-testid="currency-flag"
                                    countryCode={Currencies.#map[value].substring(0, 2)}
                                    className="flag"
                                    style={{
                                        position: "relative",
                                        top: "-2px"
                                    }}
                                />
                            }
                            <span
                                data-testid="currency-label"
                                style={{
                                    paddingLeft: "5px"
                                }}
                            >{label}</span>
                        </span >
                    )
            )
            : "Select...";
    };

    render() {
        let className = this.props.disabled ? "disabled" : "";
        if (typeof this.props.className === "string" && this.props.className.length) {
            className += (className.length ? " " : "") + this.props.className;
        }
        return (
            <MultiSelect
                className={`cur ${className}`}
                options={Currencies.OPTIONS}
                value={this.props.value}
                onChange={this.setSelected}
                labelledBy="Select"
                hasSelectAll={false}
                disabled={this.props.disabled}
                valueRenderer={this.customValueRenderer}
            />
        );
    }
}

Currencies.defaultProps = {
    value: [{ value: 1, label: "USD" }],
    onChange: (e) => { console.log(e) },
    disabled: false,
    singleValue: true,
    className: ""
}

Currencies.propTypes = {
    /** Currencies under selection */
    value: PropTypes.array.isRequired,
    /** OnChange callback function */
    onChange: PropTypes.func.isRequired,
    /** Should this component be disabled? */
    disabled: PropTypes.bool,
    /** Should multiple entries be selected at once? */
    singleValue: PropTypes.bool,
    className: PropTypes.string
};

export default Currencies;
