import React from "react";
import { Container, Breadcrumb, Row, Col, Form, Collapse } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import MultiSelect from "react-multi-select-component";
import Toast from "../../services/Toast";
import BrandSearch from "../../components/BrandSearch";
import ManufacturerSearch from "../../components/ManufacturerSearch";
import Countries from "../../components/Countries";
import ProductSearch from "../../components/ProductSearch";
import BrandProductsMappingService from "../../services/BrandProductsMappingService";
import "./URLMappings.css";
import ProcessingButton from "../../components/ProcessingButton";


/**
 * @component
 * @category Scenes
 */
class URLMappings extends React.Component {
    static CRAWLER_OPTIONS = [
        { label: "v1 PD (UPC/SKU Search)", value: 'v1-upc' },
        { label: "v1 PD (BDO)", value: 'v1-bdo' },
        { label: "NG PD", value: 'descobridor' },
        { label: "NPD", value: 'npd' },
        { label: "PDD", value: 'pdd' },
        { label: "Google Shopping", value: 'google-shopping' },
        { label: "Direct", value: 'direct' },
    ];

    static INTENT_OPTIONS = [
        { label: "Allow", value: 'allow' },
        { label: "Crawl", value: 'crawl' },
        { label: "Block", value: 'block' },
    ];

    constructor(props) {
        super(props);
        this.state = this.initialState();
        this.handleChangeBrands = this.handleChangeBrands.bind(this);
        this.handleChangeManufacturers = this.handleChangeManufacturers.bind(this);
        this.handleChangeCountries = this.handleChangeCountries.bind(this);
        this.handleChangeProducts = this.handleChangeProducts.bind(this);
        this.handleChangeProductsExclude = this.handleChangeProductsExclude.bind(this);
        this.handleChangeCrawlers = this.handleChangeCrawlers.bind(this);
        this.handleChangeCrawlersExclude = this.handleChangeCrawlersExclude.bind(this);
        this.handleChangePDExclude = this.handleChangePDExclude.bind(this);
        this.handleChangeIntent = this.handleChangeIntent.bind(this);
        this.handleChangeRestrictions = this.handleChangeRestrictions.bind(this);
        this.handleWebsiteSelection = this.handleWebsiteSelection.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
        this.loadCountries = this.loadCountries.bind(this);
        this.brandsRef = React.createRef();
        this.manufacturersRef = React.createRef();
        this.productsRef = React.createRef();
    }

    initialState() {
        return {
            loading: false,
            brands: [],
            manufacturers: [],
            countries: [],
            products: [],
            excludeProducts: false,
            crawlerTypes: [],
            excludeCrawlers: false,
            excludeProductDiscovery: true,
            intent: URLMappings.INTENT_OPTIONS.slice(0, 2),
            restrictions: true,
            countriesList: [],
            selectedCountries: [],
            websitesList: [],
            websitesSelected: 0,
            showWebsites: false,
            loadingCountries: false,
            loadingWebsites: false,
            generatingReport: false,
        }
    }

    handleChangeBrands(brands) {
        this.setState({
            brands: brands.map(b => b.id),
            manufacturers: [],
            products: [],
            loadingCountries: true
        }, () => {
            this.manufacturersRef.current.clear();
            this.productsRef.current.clear();
            this.loadCountries(this.state.brands, this.state.manufacturers);
        });
    }

    handleChangeManufacturers(manufacturers) {
        this.setState({
            manufacturers: manufacturers.map(m => m.id),
            products: [],
            loadingCountries: true
        }, () => {
            this.productsRef.current.clear();
            this.loadCountries(this.state.brands, this.state.manufacturers);
        });
    }

    handleChangeCountries(countries) {
        this.setState({
            countries: countries.map(c => c.value),
            products: [],
            selectedCountries: countries
        }, () => {
            this.productsRef.current.clear();
        });
    }

    handleChangeProducts(products) {
        this.setState({
            products: products.map(p => p.id)
        });
    }

    handleChangeProductsExclude(evt) {
        this.setState({ excludeProducts: evt.target.checked });
    }

    handleChangeCrawlers(crawlers) {
        this.setState({
            crawlerTypes: crawlers
        });
    }

    handleChangeCrawlersExclude(evt) {
        this.setState({ excludeCrawlers: evt.target.checked });
    }

    handleChangePDExclude(evt) {
        const checked = evt.target.checked;

        this.setState({
            crawlerTypes: checked ? [] : this.state.crawlerTypes,
            excludeCrawlers: checked ? false : this.state.excludeCrawlers,
            excludeProductDiscovery: checked
        });
    }

    handleChangeIntent(intent) {
        this.setState({ intent });
    }

    handleChangeRestrictions(evt) {
        this.setState({ restrictions: evt.target.checked });
    }

    handleWebsiteSelection(idx, checked) {
        const websitesList = [...this.state.websitesList];
        const checkedWebsite = { ...websitesList[idx], selected: checked };
        websitesList[idx] = checkedWebsite;
        const websitesSelected = this.state.websitesSelected + (checked ? 1 : -1);

        this.setState({ websitesList, websitesSelected });
    }

    handleSelection(evt) {
        evt.preventDefault();
        const mode = evt.target.getAttribute("mode");
        let websitesSelected = 0;
        this.setState({
            websitesList: this.state.websitesList.map(website => {
                website.selected = mode === "all" || (mode === "invert" && !website.selected);
                if (website.selected) websitesSelected++;
                return website;
            }),
            websitesSelected
        });
    }

    loadCountries = (brands, manufacturers) => {
        if (brands.length === 0 && manufacturers.length === 0) {
            this.setState({
                countries: [],
                selectedCountries: [],
                countriesList: [],
                loadingCountries: false
            });
            return;
        }

        BrandProductsMappingService.getCountries(brands, manufacturers).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while loading the countries list.");
                return;
            }

            const countries = result.map(country => ({ label: country.name, value: country.id }));
            this.setState({
                countries: [],
                selectedCountries: [],
                countriesList: countries
            });
        }).finally(() => {
            this.setState({ loadingCountries: false });
        });
    }

    loadWebsites = () => {
        if (this.state.brands.length === 0 && this.state.manufacturers.length === 0) {
            Toast.error("You must select at least one brand or manufacturer.");
            return;
        }

        this.setState({
            websitesList: [],
            websitesSelected: 0,
            showWebsites: false,
            loadingWebsites: true
        });

        const body = {
            brands: this.state.brands,
            manufacturers: this.state.manufacturers,
            countries: this.state.countries,
            crawler_types: this.state.crawlerTypes.map(c => c.value),
            exclude_crawler_type: this.state.excludeCrawlers,
            exclude_product_discovery: this.state.excludeProductDiscovery,
            restriction: this.state.restrictions
        };

        BrandProductsMappingService.getWebsites(body).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while loading the websites list.");
                return;
            }

            const websites = result
                .sort((a, b) => a.display_name.localeCompare(b.display_name))
                .map(website => ({ ...website, selected: true }));
            this.setState({
                websitesList: websites,
                websitesSelected: websites.length,
                showWebsites: true
            });
        }).finally(() => {
            this.setState({ loadingWebsites: false });
        });
    }

    generateReport = () => {
        if (this.state.brands.length === 0 && this.state.manufacturers.length === 0) {
            Toast.error("You must select at least one brand or manufacturer.");
            return;
        }

        this.setState({ generatingReport: true });

        const body = {
            brand_ids: this.state.brands,
            manufacturer_ids: this.state.manufacturers,
            product_ids: this.state.products,
            exclude_products: this.state.excludeProducts,
            country_ids: this.state.countries,
            intent: this.state.intent.map(i => i.value).join(','),
            website_ids: this.state.websitesList.filter(w => w.selected).map(w => w.id),
        };

        BrandProductsMappingService.generateReport(body).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while generating the report.");
                return;
            }

            Toast.ok("Report has been generated and downloaded successfully.");
        }).finally(() => {
            this.setState({ generatingReport: false });
        });
    }

    render() {
        const noWebsites = this.state.showWebsites && this.state.websitesList.length === 0;
        const noWebsitesSelected = this.state.websitesSelected === 0;
        const disabled = this.state.brands.length === 0 && this.state.manufacturers.length === 0;
        const generatingReport = this.state.generatingReport;

        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Others</Breadcrumb.Item>
                    <Breadcrumb.Item active>Mappings</Breadcrumb.Item>
                </Breadcrumb>

                <Row>
                    <Col sm={6}>
                        <Form.Label>Dashboards</Form.Label>
                        <BrandSearch
                            disabled={generatingReport}
                            ref={this.brandsRef}
                            onChange={this.handleChangeBrands}
                            multiple={true}
                        />
                    </Col>

                    <Col sm={6}>
                        <Form.Label>Manufacturers</Form.Label>
                        <ManufacturerSearch
                            disabled={generatingReport}
                            ref={this.manufacturersRef}
                            onChange={this.handleChangeManufacturers}
                            multiple={true}
                            brands={this.state.brands}
                        />
                    </Col>
                </Row>

                <Row className="pt-3">
                    <Col sm={3}>
                        <Form.Label>Countries</Form.Label>
                        <Countries
                            disabled={disabled || this.state.loadingCountries || generatingReport}
                            value={this.state.selectedCountries}
                            options={this.state.countriesList}
                            onChange={this.handleChangeCountries}
                        />
                    </Col>

                    <Col sm={7}>
                        <Form.Label>Products</Form.Label>
                        <ProductSearch
                            ref={this.productsRef}
                            onChange={this.handleChangeProducts}
                            includeArchivedProducts={false}
                            includeDisabledBrands={false}
                            multiple={true}
                            disabled={disabled || generatingReport}
                            placeholder="Search for products..."
                            brands={this.state.brands}
                            manufacturers={this.state.manufacturers}
                            regions={this.state.countries}
                        />
                    </Col>

                    <Col sm={2} style={{ paddingTop: '1.75rem' }}>
                        <Form.Check
                            type="checkbox"
                            checked={this.state.excludeProducts}
                            onChange={this.handleChangeProductsExclude}
                            label="Exclude products"
                            disabled={disabled || generatingReport}
                        />
                    </Col>
                </Row>

                <Row className="pt-3">
                    <Col sm={3} style={{ paddingTop: '2.5rem' }}>
                        <Form.Check type="checkbox"
                            checked={this.state.excludeProductDiscovery}
                            onChange={this.handleChangePDExclude}
                            label="Exclude Product Discovery"
                            disabled={generatingReport}
                        />
                    </Col>

                    <Col sm={3}>
                        <Form.Label>Crawler Types</Form.Label>
                        <MultiSelect
                            className="selector"
                            hasSelectAll={false}
                            disabled={this.state.excludeProductDiscovery || generatingReport}
                            options={URLMappings.CRAWLER_OPTIONS}
                            value={this.state.crawlerTypes}
                            onChange={this.handleChangeCrawlers}
                            labelledBy="Select crawler types"
                        />
                        <Form.Check
                            className="mt-1"
                            type="checkbox"
                            checked={this.state.excludeCrawlers}
                            disabled={this.state.excludeProductDiscovery || generatingReport}
                            onChange={this.handleChangeCrawlersExclude}
                            label="Exclude crawlers"
                        />
                    </Col>

                    <Col sm={2}>
                        <Form.Label>Intent</Form.Label>
                        <MultiSelect
                            className="selector"
                            hasSelectAll={false}
                            options={URLMappings.INTENT_OPTIONS}
                            value={this.state.intent}
                            onChange={this.handleChangeIntent}
                            labelledBy="Select intent"
                            disabled={generatingReport}
                        />
                    </Col>

                    <Col sm={4} style={{ paddingTop: '2.5rem' }}>
                        <Form.Check
                            type="checkbox"
                            checked={this.state.restrictions}
                            onChange={this.handleChangeRestrictions}
                            label="Respect Whitelist/Blacklist restrictions"
                            disabled={generatingReport}
                        />
                    </Col>
                </Row>

                <Collapse in={this.state.showWebsites}>
                    <Row className="pt-4">
                        {!generatingReport && !noWebsites && <div className="selection-toolbox">
                            <span>
                                <Link href="#" passHref className="link" mode="all" onClick={this.handleSelection}>Select all</Link>
                                <Link href="#" passHref className="link" mode="none" onClick={this.handleSelection}>Select none</Link>
                                <Link href="#" passHref className="link" mode="invert" onClick={this.handleSelection}>Invert selection</Link>
                            </span>
                        </div>}
                        <Col>
                            <fieldset className="websites">
                                <legend>Websites {noWebsites ? '' : '(' + this.state.websitesSelected + ')'}</legend>
                                <div>
                                    {noWebsites ? <p>No websites found.</p> : this.state.websitesList.map((website, index) => (
                                        <Form.Check
                                            key={`website_${website.id}`}
                                            type="checkbox"
                                            checked={this.state.websitesList[index].selected}
                                            onChange={(evt) => this.handleWebsiteSelection(index, evt.target.checked)}
                                            label={website.display_name}
                                            disabled={generatingReport}
                                        />
                                    ))}
                                </div>
                            </fieldset>
                        </Col>
                    </Row>
                </Collapse>

                <Row className="pt-3">
                    <Col>
                        <ProcessingButton
                            variant="primary"
                            onClick={this.loadWebsites}
                            disabled={disabled || generatingReport}
                            processing={this.state.loadingWebsites}
                            label="Load Websites"
                            processingLabel="Loading Websites ..."
                        />
                        <ProcessingButton
                            variant="dark"
                            disabled={disabled || noWebsitesSelected}
                            onClick={this.generateReport}
                            processing={generatingReport}
                            label="Generate Report"
                            processingLabel="Generating Report ..."
                        />
                    </Col>
                </Row>
            </Container>
        );
    }
}
export default withRouter(URLMappings);
