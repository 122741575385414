import React from "react";
import PropTypes from "prop-types";

import { Container, Row, Col } from "react-bootstrap";
import Emitter from "../../services/Emitter";
import Loading from "../Loading";
import BrandService from "../../services/BrandService";
import {
    PieChart,
    Pie,
    Cell,
    Tooltip,
    Bar,
    BarChart,
    XAxis,
    YAxis,
    ResponsiveContainer,
} from "recharts";
import Toast from "../../services/Toast";
import { Link, withRouter } from "react-router-dom";
import Utils from "../../services/Utils";

const COLORS = Utils.GetColors();

/**
 * Renders summary data regarding a brand
 * @component
 * @category Scenes
 * @subcategory Brands
 */
class Summary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            loading: true,
            urls: [],
            volume: []
        };
        this.onEnter = this.onEnter.bind(this);
    }

    onEnter() {
        BrandService.summary(this.props.brandId).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading this brand's summary info.");
                return;
            }
            let urlObj = [];
            if (typeof data.urls === "object") {
                data.urls.forEach((obj) => {
                    urlObj.push({
                        name: obj.name,
                        value: obj.products,
                    });
                });
            }

            let volumeObj = [];
            if (typeof data.lastDays === "object") {
                let days = Object.keys(data.lastDays);
                days.forEach((day) => {
                    volumeObj.push({
                        name: day,
                        value: data.lastDays[day],
                    });
                });
            }

            this.setState({
                name: data.name,
                organization: data.organization,
                loading: false,
                urls: urlObj,
                volume: volumeObj,
            });
        });
    }

    componentWillUnmount() {
        Emitter.off("BRAND_SUMMARY");
    }

    componentDidMount() {
        Emitter.once("BRAND_SUMMARY", this.onEnter);
    }

    render() {
        const showVolumeChart = typeof this.state.volume == "object" && this.state.volume.length > 0,
            showPieChart = typeof this.state.urls == "object" && this.state.urls.length > 0;

        return (
            <Container className="pt-4">
                {this.state.loading && <Loading />}
                {!this.state.loading && (
                    <React.Fragment>
                        <Row>
                            <Col>
                                <h1>{this.state.name}</h1>
                                <h5><Link className="link" to={`/organization/${this.state.organization.id}`}>{this.state.organization.name}</Link></h5>
                            </Col>
                        </Row>
                        {showVolumeChart && (
                            <Row data-testid="last-week">
                                <p align="center">Last month of crawling (unique hits)</p>
                                <ResponsiveContainer width={"99%"} height={300}>
                                    <BarChart data={this.state.volume}>
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Bar
                                            dataKey="value"
                                            fill="#8884d8"
                                        />
                                        <Tooltip cursor={false} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </Row>
                        )}

                        {showPieChart && (
                            <Row data-testid="url-distribution">
                                &nbsp;
                                <p align="center">URLs distribution among websites (Top 25)</p>
                                <ResponsiveContainer width={"99%"} height={300}>
                                    <PieChart>
                                        <Pie
                                            data={this.state.urls}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={true}
                                            outerRadius={150}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {this.state.urls.map(
                                                (entry, index) => (
                                                    <Cell
                                                        key={`cell-${index}`}
                                                        fill={
                                                            COLORS[
                                                            index %
                                                            COLORS.length
                                                            ]
                                                        }
                                                    />
                                                )
                                            )}
                                        </Pie>
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                            </Row>
                        )}
                    </React.Fragment>
                )}
            </Container>
        );
    }
}

Summary.propTypes = {
    /** Brand ID */
    brandId: PropTypes.number.isRequired,
};

export default withRouter(Summary);
