import React from "react";
import PropTypes from "prop-types";
import UserService from "../services/UserService";
import Settings from "../services/SettingsService";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

/**
 * Typeahead component for searching for Users
 * @component
 */
class UserSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        }
        this.changeSettings = this.changeSettings.bind(this);
    }

    changeSettings = (evt) => {
        const mode = evt.target.getAttribute("setting"),
            checked = evt.target.checked;
        if (mode === "archived-products") {
            Settings.Set("products-search-include-archived-products", checked);
            this.setState({ includeArchivedProducts: checked });
        } else if (mode === "disabled-brands") {
            Settings.Set("products-search-include-disabled-brands", checked);
            this.setState({ includeDisabledBrands: checked });
        }
    }

    handleSearch = (query) => {
        this.setState({
            isLoading: true,
        });
        UserService.typeahead(query).then((options) => {
            if (options === null) {
                // this request was canceled due to a change in the typeahead component
                return;
            }
            let list = [];
            options.forEach((row) => {
                if (typeof this.props.skip === "undefined" || this.props.skip.indexOf(row.id) === -1) {
                    list.push(row);
                }
            });
            this.setState({
                options: list,
                isLoading: false,
            });
        });
    };

    render() {
        const filterBy = () => true;

        return <React.Fragment>
            <AsyncTypeahead
                {...this.state}
                filterBy={filterBy}
                labelKey="name"
                id="user-search"
                maxResults={10}
                minLength={2}
                onSearch={this.handleSearch}
                onChange={this.props.onChange}
                defaultSelected={this.props.defaultSelected}
                placeholder="Search for an user..."
                renderMenuItemChildren={(option) => {
                    return (
                        <div key={`m${option.id}`} className="user">
                            <div>
                                <span className="colHead">id:</span>
                                <span className="colValue">
                                    {option.id}
                                </span>
                                <span className="colHead">name:</span>
                                <span className="colValue">
                                    {option.name}
                                </span>
                                <span className="colHead">role:</span>
                                <span className="colValue">
                                    {option.role}
                                </span>
                                {typeof option.orgs === "string" && option.orgs.length && (
                                    <React.Fragment>
                                        <span className="colHead">org(s):</span>
                                        <span className="colValue">
                                            {option.orgs}
                                        </span>
                                    </React.Fragment>
                                )}
                                {typeof option.brands === "string" && option.brands.length && (
                                    <React.Fragment>
                                        <span className="colHead">brand(s):</span>
                                        <span className="colValue">
                                            {option.brands}
                                        </span>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    )
                }
                }
                useCache={false}
            />
        </React.Fragment>
    }
}

UserSearch.propTypes = {
    /**  */
    onChange: PropTypes.func.isRequired,
    skip: PropTypes.arrayOf(PropTypes.number)
};

UserSearch.defaultProps = {
    onChange: (values) => {
        console.log("User has changed", values);
    },
    skip: []
};

export default UserSearch;
