import React, { createRef } from "react";
import PropTypes from "prop-types";

import { Link, withRouter } from "react-router-dom";
import Website from "../../services/WebsiteService";
import Toast from "../../services/Toast";
import { Container } from "react-bootstrap";
import Emitter from "../../services/Emitter";
import MyTable from "../../components/MyTable";


/**
 * @component
 * @category Scenes
 * @subcategory Website
 */
class Merchants extends React.Component {
    constructor(props) {
        super(props);
        this.state = { yesterday: '...' }
        this.onEnter = this.onEnter.bind(this);
        this.tableRef = createRef();
    }

    componentDidMount() {
        Emitter.once("WEBSITE_MERCHANTS", this.onEnter);
    }

    componentWillUnmount() {
        Emitter.off("WEBSITE_MERCHANTS");
    }

    onEnter() {
        Website.merchants(this.props.websiteId).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of merchants");
                return;
            }
            data.merchants.forEach((row) => {
                row.type = row.type_id === 1 ? 'Online Retailer' : 'Marketplace Seller';
            })
            this.setState({ yesterday: data.yesterday }, () => {
                this.tableRef.current.populate(data.merchants);
            });
        });
    }

    render() {

        return (
            <Container className="pt-4">
                <MyTable
                    name="website-merchants"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="name"
                    sortOrder="asc"
                    headers={[
                        {
                            field: "name",
                            label: "Merchant",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "products",
                            width: "120px",
                            label: this.state.yesterday
                        }
                    ]}
                    renderColumns={{
                        name: (row) => <Link className="link" to={`/merchant/${row.id}`}>(#{row.id}) {row.name}</Link>
                    }}
                />
            </Container>
        );
    }
}

Merchants.propTypes = {
    websiteId: PropTypes.number.isRequired,
};

export default withRouter(Merchants);
