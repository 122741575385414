import React from "react";
import jexcel from "jexcel";
import PropTypes from "prop-types";
import "../../node_modules/jexcel/dist/jexcel.css";
import "./Spreadsheet.css";

class Spreadsheet extends React.Component {
    constructor(props) {
        super(props);
        this.options = props.options;
        this.wrapper = React.createRef();
    }

    Get() {
        return this.el;
    }

    componentDidMount = function () {
        this.el = jexcel(this.wrapper.current, this.options);
    };

    render() {
        return (
            <div ref={this.wrapper} />
        )
    }
}

Spreadsheet.propTypes = {
    data: PropTypes.array.isRequired,
    options: PropTypes.object.isRequired
};

export default Spreadsheet;
