import React, { createRef } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Link, withRouter } from "react-router-dom";
import User from "../../services/UserService";
import Toast from "../../services/Toast";
import { Breadcrumb, Container } from "react-bootstrap";
import MyTable from "../../components/MyTable";
import ProcessingButton from "../../components/ProcessingButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faDownload, faTimes } from "@fortawesome/free-solid-svg-icons";

/**
 * @component
 * @category Scenes
 * @subcategory Brand
 */
class List extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = createRef();
        this.state = {
            downloading: false,
        }
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
    }

    componentDidMount() {
        User.all().then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of brands");
                return;
            }
            this.tableRef.current?.populate(data);
        });
    }

    downloadSpreadsheet() {
        this.tableRef.current.CSV();
    }

    render() {
        return (

            <Container className="pt-4">
                <span style={{
                    float: "right"
                }}
                >
                    <ProcessingButton
                        size="sm"
                        processing={this.state.downloading}
                        processingLabel="Downloading ..."
                        variant="success"
                        label="Download CSV"
                        icon={faDownload}
                        onClick={this.downloadSpreadsheet}
                    />
                </span>
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Users</Breadcrumb.Item>
                    <Breadcrumb.Item active>List</Breadcrumb.Item>
                </Breadcrumb>
                <MyTable
                    name="users-list"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="last_login"
                    sortOrder="desc"
                    headers={
                        [
                            {
                                field: "id",
                                label: "ID",
                                sortable: true,
                                searchable: true,
                                width: "70px",
                            },
                            {
                                field: "organizations",
                                label: "Organizations",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "role",
                                label: "Role",
                                sortable: true,
                                allowEmpty: true,
                                options: ['Viewer', 'Admin'],
                                width: "70px"
                            },
                            {
                                field: "super_admin",
                                label: "SU",
                                hint: "Super Admin User",
                                sortable: true,
                                allowEmpty: true,
                                options: [{ label: "Yes", value: 1 }, { label: "No", value: 0 }],
                                width: "70px"
                            },
                            {
                                field: "name",
                                label: "Name",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "email",
                                label: "E-mail",
                                sortable: true,
                                searchable: true,
                            },
                            {
                                field: "brands",
                                label: "Brands",
                                sortable: true,
                                searchable: true,
                            },
                            {
                                field: "last_login",
                                label: "Last Login",
                                sortable: true,
                                searchable: true,
                                width: "85px"
                            },
                            {
                                field: "status",
                                label: "Status",
                                sortable: true,
                                allowEmpty: true,
                                options: [{ label: "Active", value: 1 }, { label: "Disabled", value: 0 }],
                                width: "60px"
                            },
                        ]
                    }
                    renderRows={{
                        className: (row) => row.status !== 1 ? "blocked" : ""
                    }}
                    renderColumns={{
                        id: (row) => <Link className="link" to={`/user/${row.id}`}>{row.id}</Link>,
                        organizations: {
                            format: (row) => row.organizations.map((organization) => {
                                const name = organization.active ? organization.name : <span className={organization.active ? '' : 'orgDisabled'}>{organization.name}</span>;
                                return <span style={{ marginRight: "5px" }}><Link className="link" to={`/organization/${organization.id}`}>{name}</Link></span>
                            }),
                            text: (row) => row.organizations.map(organization => `${organization.id} ${organization.name}`).join(' '),
                            csv: (row) => row.organizations.map(organization => `(${organization.id}) ${organization.name}`).join('; ')
                        },
                        super_admin: {
                            csv: (row) => row.super_admin === 1 ? 'Yes' : 'No',
                            format: (row) => row.super_admin === 1 ? (
                                <FontAwesomeIcon
                                    style={{
                                        color: "green",
                                    }}
                                    icon={faCheck}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    style={{ color: "red" }}
                                    icon={faTimes}
                                />
                            ),
                            className: "text-center"
                        },
                        status: {
                            csv: (row) => row.status === 1 ? 'Active' : 'Disabled',
                            format: (row) => row.status === 1 ? (
                                <FontAwesomeIcon
                                    style={{
                                        color: "green",
                                    }}
                                    icon={faCheck}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    style={{ color: "red" }}
                                    icon={faTimes}
                                />
                            ),
                            className: "text-center"
                        },
                        last_login: {
                            className: "text-center"
                        },
                        brands: {
                            format: (row) => row.brands.map((brand) => {
                                const name = brand.active ? brand.name : <span className={brand.active ? '' : 'orgDisabled'}>{brand.name}</span>;
                                return <span style={{ marginRight: "5px" }}><Link className="link" to={`/brand/${brand.id}`}>{name}</Link></span>
                            }),
                            text: (row) => row.brands.map(brand => `${brand.id} ${brand.name}`).join(' '),
                            csv: (row) => row.brands.map(brand => `(${brand.id}) ${brand.name}`).join('; ')
                        }
                    }}
                />
            </Container >
        )
    }
}

export default withRouter(List);