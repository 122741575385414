import React, { createRef } from "react";
import { Breadcrumb, Container } from "react-bootstrap";
import { faDownload, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../components/IconButton";
import PDDService from "../../services/PDDService";
import MySpinner from "../../components/MySpinner";
import PickupBrand from "../../components/PickupBrand";
import Toast from "../../services/Toast";
import MyTable from "../../components/MyTable";
import confirm from "../../components/Confirm";

/**
 * @component
 * @category Scenes
 * @subcategory PDD
 */
class Disabled extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showPickup: false,
        }
        this.skipBrands = {};
        this.tableRef = createRef();
        this.handleRemove = this.handleRemove.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
    }

    downloadSpreadsheet() {
        this.tableRef.current.CSV();
    }

    componentDidMount() {
        PDDService.listDisabled().then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of dashboards");
                this.setState({ loading: false });
                this.tableRef.current.setLoading(false);
                return;
            }
            this.skipBrands = {};
            data.forEach((row) => this.skipBrands[row.id] = true);
            this.setState({ loading: false }, () => {
                this.tableRef.current?.populate(data);
            })
        });
    }

    async handleRemove(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);

        if (
            !(await confirm("Are you sure?", "Continue", "Cancel", {
                message:
                    "You're about to remove dashboard " + obj.row.name + " from the list. This operation can't be undone. Do you want to continue?",
            }))
        ) {
            return;
        }

        obj.row.removing = true;
        this.tableRef.current.updateRow(obj.row);

        PDDService.toggle(obj.row.id, true).then((result) => {
            if (result === false) {
                delete obj.row.removing;
                Toast.error("Something went wrong while removing dashboard");
                return;
            }

            Toast.ok("Dashboard removed successfully");
            this.tableRef.current.deleteRow(obj.row);
            delete this.skipBrands[obj.row.id];
        });
    }

    handleAdd(brand) {
        return new Promise(async (resolve, reject) => {
            PDDService.toggle(brand.id, false).then((result) => {
                if (result === false) {
                    Toast.error("Something went wrong while adding dashboard");
                    reject();
                    return;
                }

                Toast.ok("Dashboard added successfully");
                this.tableRef.current.addRow(brand);
                this.skipBrands[brand.id] = true;
                resolve(true);
            });
        });
    }

    render() {
        const skipBrands = Object.keys(this.skipBrands).map((id) => parseInt(id));
        return <Container className="pt-4">
            <PickupBrand
                title={'Select a dashboard'}
                processLabel="Add"
                processingLabel="Adding ..."
                show={this.state.showPickup}
                onClose={() => { this.setState({ showPickup: false }) }}
                callbackPromise={this.handleAdd}
                skip={skipBrands}
            />
            <span style={{
                float: "right"
            }}
            >
                <IconButton
                    disabled={this.state.loading}
                    size="sm"
                    variant="success"
                    label="Download CSV"
                    icon={faDownload}
                    onClick={this.downloadSpreadsheet}
                />
            </span>
            <Breadcrumb className="dd">
                <Breadcrumb.Item>PDD</Breadcrumb.Item>
                <Breadcrumb.Item active>Disabled Dashboards</Breadcrumb.Item>
            </Breadcrumb>
            <MyTable
                name="pdd-disabled-dashboards"
                dynamic={false}
                ref={this.tableRef}
                headers={
                    [
                        {
                            field: "id",
                            label: "ID",
                            sortable: true,
                            searchable: true,
                            width: "80px",
                        },
                        {
                            field: "name",
                            label: "Dashboard",
                            sortable: true,
                            searchable: true,
                        },
                        {
                            field: "control",
                            width: "80px",
                            className: "text-center",
                            content:
                                <IconButton
                                    size="sm"
                                    className="tdButton"
                                    variant="success"
                                    icon={faPlus}
                                    onClick={() => this.setState({ showPickup: true })}
                                />
                        }
                    ]
                }
                renderColumns={{
                    control: {
                        className: "text-center",
                        format: (row) => {
                            if (row.removing) {
                                return <MySpinner />
                            }
                            return <IconButton
                                size="sm"
                                variant="danger"
                                className="tdButton"
                                onClick={this.handleRemove}
                                icon={faTrash}
                            />
                        }
                    }
                }}
            />
        </Container>
    }
}

export default Disabled;
