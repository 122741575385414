import React from "react";
import { Container, Row } from "react-bootstrap";
import Emitter from "../../services/Emitter";
import AuthService from "../../services/AuthService";

class Logout extends React.Component {
    constructor(props) {
        super(props);
        AuthService.logout();
        Emitter.emit("LOGGED_OUT");
    }

    render() {
        return (
            <Container className="pt-4">
                <Row>Bye bye</Row>
            </Container>
        );
    }
}

export default Logout;
