import React, { createRef } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Link, withRouter } from "react-router-dom";
import Organization from "../../services/OrganizationService";
import Toast from "../../services/Toast";
import { Breadcrumb, Button, Container } from "react-bootstrap";
import MyTable from "../../components/MyTable";
import prompt from "../../components/Prompt";
import ProcessingButton from "../../components/ProcessingButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faDownload, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";


/**
 * @component
 * @category Scenes
 * @subcategory Organization
 */
class List extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = createRef();
        this.state = {
            downloading: false
        }
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
        this.createNewOrganization = this.createNewOrganization.bind(this);
    }

    componentDidMount() {
        Organization.all().then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of organizations");
                return;
            }
            for (let i = 0; i < data.length; i++) {
                let brands = '';
                for (let j = 0; j < data[i].brands.length; j++) {
                    brands += '(#' + data[i].brands[j].id + ') ' + data[i].brands[j].name + ';';
                }
                data[i]._brands = data[i].brands;
                data[i].brands = brands;
            }
            this.tableRef.current?.populate(data);
        });
    }

    createNewOrganization() {
        prompt("Create new Organization", "Create", "Cancel", "Organization name:", '').then((evt) => {
            const name = evt.value.trim();
            if (name.length) {
                Organization.create(name).then((id) => {
                    if (id === false) {
                        Toast.error("Something went wrong while creating the new organization");
                        return;
                    }
                    this.props.history.push("/organization/" + id + "#profile");
                    Toast.ok("Organization created successfully.");
                });
            }
        }).catch(() => { });
    }

    downloadSpreadsheet() {
        this.tableRef.current.CSV();
    }

    render() {
        return (

            <Container className="pt-4">
                <span style={{
                    float: "right"
                }}
                >
                    <Button
                        size="sm"
                        className="tdButton"
                        onClick={this.createNewOrganization}
                    >
                        <FontAwesomeIcon icon={faPlus} style={{
                            marginRight: "5px"
                        }} />
                        New Organization
                    </Button>
                    <ProcessingButton
                        size="sm"
                        processing={this.state.downloading}
                        processingLabel="Downloading ..."
                        variant="success"
                        label="Download CSV"
                        icon={faDownload}
                        onClick={this.downloadSpreadsheet}
                    />
                </span>
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Organization</Breadcrumb.Item>
                    <Breadcrumb.Item active>List</Breadcrumb.Item>
                </Breadcrumb>
                <MyTable
                    name="organization-list"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="name"
                    sortOrder="asc"
                    headers={
                        [
                            {
                                field: "id",
                                label: "ID",
                                sortable: true,
                                searchable: true,
                                width: "60px",
                            },
                            {
                                field: "name",
                                label: "Name",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "status",
                                label: "Status",
                                width: "100px",
                                sortable: true,
                                allowEmpty: true,
                                options: [{ label: 'Active', value: 'active' }, { label: 'Disabled', value: 'disabled' }]
                            },
                            {
                                field: "brands",
                                label: "Brands",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "hubspot",
                                label: "Hubspot",
                                width: "120px",
                                sortable: true,
                                searchable: true
                            }
                        ]
                    }
                    renderRows={{
                        className: (row) => row.status !== 'active' ? "blocked" : ""
                    }}
                    renderColumns={{
                        id: (row) => <Link className="link" to={`/organization/${row.id}`}>{row.id}</Link>,
                        name: (row) => <Link className="link" to={`/organization/${row.id}`}>{row.name}</Link>,
                        brands: (row) => {
                            return row._brands.length ? row._brands.map((brand, index) => index ? <React.Fragment>;&nbsp;<Link className={`link ${brand.status ? '' : 'orgDisabled'}`} to={`/brand/${brand.id}`}>{`(#${brand.id}) ${brand.name}`}</Link></React.Fragment> : <Link className={`link ${brand.status ? '' : 'orgDisabled'}`} to={`/brand/${brand.id}`}>{`(#${brand.id}) ${brand.name}`}</Link>) : null
                        },
                        status: {
                            format: (row) => row.status === 'active' ? (
                                <FontAwesomeIcon
                                    style={{
                                        color: "green",
                                    }}
                                    icon={faCheck}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    style={{ color: "red" }}
                                    icon={faTimes}
                                />
                            ),
                            className: "text-center"
                        }
                    }}
                />
            </Container>
        )
    }
}

export default withRouter(List);