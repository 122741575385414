import React, { createRef } from "react";

import {
    Container,
    Button,
    Breadcrumb,
    OverlayTrigger,
    Popover,
    PopoverBody
} from "react-bootstrap";
import OnboardingService from "../../../services/OnboardingService";
import MemoryService from "../../../services/MemoryService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faHourglass, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Link, withRouter } from "react-router-dom";
import Toast from "../../../services/Toast";
import Utils from "../../../services/Utils";
import { faFrown } from "@fortawesome/free-regular-svg-icons";
import XPathRequest from "../../../components/XPathRequest";
import MyTable from "../../../components/MyTable";


/**
 * Jobs list component
 * @component
 * @category Scenes
 * @subcategory Onboarding/XPath
 */
class List extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newRequest: false
        }
        this.tableRef = createRef();
    }

    componentDidMount() {
        this.tableRef.current.init();
    }

    render() {
        return (
            <Container className="pt-4" >
                <XPathRequest show={this.state.newRequest} onClose={(result) => {
                    if (result) {
                        this.tableRef.current.reload();
                    }
                    this.setState({ newRequest: false });
                }} />
                <span style={{
                    display: "inline-block",
                    width: "100%"
                }}>
                    <Breadcrumb className="dd" style={{
                        display: "inline-block"
                    }}>
                        <Breadcrumb.Item>Onboarding</Breadcrumb.Item>
                        <Breadcrumb.Item active>XPath Requests</Breadcrumb.Item>
                    </Breadcrumb>
                    <Button size="sm" variant="primary" style={{
                        float: "right"
                    }} onClick={() => {
                        this.setState({ newRequest: true })
                    }}>
                        New request
                    </Button>
                </span>

                <MyTable
                    name="onboarding-xpath"
                    dynamic={true}
                    ref={this.tableRef}
                    sortBy="requested"
                    sortOrder="desc"
                    headers={
                        [
                            {
                                field: "website",
                                label: "Website",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "brands",
                                label: "Brands"
                            },
                            {
                                field: "requested",
                                label: "Requested on",
                                sortable: true,
                                searchable: true,
                                width: "140px"
                            },
                            {
                                field: "contractor",
                                label: "Contractor",
                                width: "100px",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "delivered_on",
                                label: "Delivered on",
                                width: "140px",
                                sortable: true
                            },
                            {
                                field: "delay",
                                label: "Delay",
                                sortable: true,
                                width: "90px"
                            },
                            {
                                field: "status",
                                label: "Status",
                                width: "120px",
                                sortable: true,
                                allowEmpty: true,
                                options: [{ label: 'Pending', value: 'pending' }, { label: 'Delivered', value: 'delivered' }, { label: 'Not possible', value: 'notpossible' }]
                            }
                        ]
                    }
                    renderColumns={{
                        website: (row) => <Link className="link" to={`/website/${row.website_id}`}>(#{row.website_id}) {row.website}</Link>,
                        delivered_on: (row) => row.delivered_on !== '0000-00-00 00:00:00' ? row.delivered_on : null,
                        delay: (row) => Utils.Seconds2PrettyString(row.delay),
                        status: (row) => {
                            let reason, status;
                            switch (row.problem) {
                                case 'multiple_variations':
                                    reason = 'Product page presents multiple variations';
                                    break;
                                case 'multiple_products':
                                    reason = 'Product page presents multiple products';
                                    break;
                                case 'login':
                                    reason = 'Website requires login to present prices';
                                    break;
                                case 'zipcode':
                                    reason = 'Website requires ZIP code to present prices';
                                    break;
                                case 'offline':
                                    reason = 'Website is offline';
                                    break;
                                case 'redirection':
                                    reason = 'Website redirects to a different one';
                                    break;
                                default:
                                    reason = typeof row.reason === "string" && row.reason.trim().length > 0 ? row.reason : (<span><FontAwesomeIcon icon={faFrown} /> Root cause not informed</span>);
                            }
                            switch (row.status) {
                                default:
                                    status = (
                                        <span style={{ color: "#fc7303" }}>
                                            <FontAwesomeIcon icon={faHourglass} /> Pending
                                        </span>
                                    )
                                    break;
                                case 'delivered':
                                    status = (
                                        <span style={{ color: "#1f4503" }}>
                                            <FontAwesomeIcon icon={faCheck} /> Delivered
                                        </span>
                                    )
                                    break;
                                case 'notpossible':
                                    status = (
                                        <OverlayTrigger
                                            placement="right"
                                            trigger={["hover", "focus"]}
                                            overlay={
                                                <Popover>
                                                    <PopoverBody>
                                                        {reason}
                                                    </PopoverBody>
                                                </Popover>
                                            }
                                        >
                                            <span style={{ color: "red" }}>
                                                <FontAwesomeIcon icon={faExclamationTriangle} /> Not possible
                                            </span>
                                        </OverlayTrigger>
                                    )
                                    break;
                            }
                            return status;
                        }
                    }}
                    reload={(setup) => {
                        return new Promise((resolve, reject) => {

                            OnboardingService.xpaths({
                                pageSize: setup.pageSize,
                                page: setup.page,
                                search: setup.search,
                                sort: setup.sort,
                            }).then((data) => {
                                if (data === false) {
                                    Toast.error("Something went wrong while retrieving the list of jobs");
                                    reject();
                                    return;
                                }
                                if (MemoryService.Has('XPATH_LIST')) {
                                    this.setState({ newRequest: true });
                                }
                                resolve(data);
                            }).catch(reject);
                        })
                    }}
                />

            </Container >
        );
    }
}

export default withRouter(List);
