import axios from "axios";

import Config from "./Config";
import APIHelper from "./APIHelper";

const CrawlingSchedulerService = {

    rules() {
        return axios
            .get(Config.API_URL + "scheduler/rule")
            .then(APIHelper.returnResults)
            .catch(APIHelper.returnFalse);
    },
    updateRule(id, row) {
        return axios
            .post(Config.API_URL + "scheduler/rule/" + id, row)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    addRule(row) {
        return axios
            .post(Config.API_URL + "scheduler/rule", row)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    deleteRule(id) {
        return axios
            .delete(Config.API_URL + "scheduler/rule/" + id)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    }
};

export default CrawlingSchedulerService;
