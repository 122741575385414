import React from "react";
import PropTypes from "prop-types";

import { Link, withRouter } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Toast from "../../services/Toast";
import Loading from "../Loading";
import Emitter from "../../services/Emitter";
import MerchantService from "../../services/MerchantService";
import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import confirm from "../../components/Confirm";
import prompt from "../../components/Prompt";
import Utils from "../../services/Utils";

/**
 * Show a CRUD for the Website row
 * @component
 * @category Scenes
 * @subcategory Websites
 */
class Notes extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState();
        this.reload = this.reload.bind(this);
        this.handleNewNote = this.handleNewNote.bind(this);
        this.handleDeleteNote = this.handleDeleteNote.bind(this);
        this.handleEditNote = this.handleEditNote.bind(this);
        this.addNew = this.addNew.bind(this);

    }

    initialState() {
        return {
            id: 0,
            row: {},
            loading: true,
            updating: false,
        };
    }

    addNew(text, add2all) {
        MerchantService.addNote(this.props.merchantId, text, add2all).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while adding new note");
                return;
            }
            Toast.ok("Note added successfully");
            let rows = this.state.global;
            delete result.status;
            rows.unshift(result);
            this.setState({ global: rows });
        });
    }

    handleNewNote() {
        prompt("New global note", "Add", "Cancel", "", "", {
            submitOnEnter: false
        })
            .then((r) => {

                if (!this.state.hasLinks) {
                    this.addNew(r.value, false);
                    return;
                }

                confirm("Add Note to Linked Merchants?", "Yes", "No", {
                    message: "Would you like to have this Note added to all Linked Merchants as well?",
                }).then((r2) => {
                    this.addNew(r.value, r2);
                })
            }).catch(() => { });
    }

    handleEditNote(evt) {
        let i = evt.target.closest("div.card").getAttribute("i"),
            row = this.state.global[i];
        prompt("Edit note", "Save changes", "Cancel", "", row.entry, {
            submitOnEnter: false
        })
            .then((r) => {
                MerchantService.updateNote(row.id, r.value).then((result) => {
                    if (result === false) {
                        Toast.error("Something went wrong while saving changes");
                        return;
                    }
                    Toast.ok("Changes registered successfully");
                    let rows = this.state.global;
                    rows[i].entry = r.value;
                    rows[i].date = Utils.DateNow();
                    this.setState({ global: rows });
                });
            });
    }

    async handleDeleteNote(evt) {
        let i = evt.target.closest("div.card").getAttribute("i"),
            row = this.state.global[i];
        if (
            !(await confirm("Are you really, really sure?", "Continue", "Cancel", {
                message:
                    "You're about to a global note from this merchant. This operation can't be undone. Do you want to continue?",
            }))
        ) {
            return;
        }

        MerchantService.deleteNote(row.id).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while removing global note");
                return;
            }
            Toast.ok("Global note removed successfully");
            let rows = this.state.global;
            rows.splice(i, 1);
            this.setState({ global: rows });
        });

    }

    reload() {
        if (this.state.id === this.props.merchantId) {
            return;
        }

        MerchantService.notes(this.props.merchantId).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while retrieving notes");
                return;
            }
            this.setState({ brands: result.notes.brands, global: result.notes.global, hasLinks: result.hasLinks, loading: false, id: this.props.merchantId })
        });
    }

    componentWillUnmount() {
        Emitter.off("MERCHANT_NOTES");
        Emitter.off("MERCHANT_RESET");
    }

    componentDidMount() {
        Emitter.on("MERCHANT_NOTES", this.reload);
        Emitter.on("MERCHANT_RESET", () => {
            this.setState(this.initialState());
        });
    }


    render() {
        if (this.state.loading) {
            return (
                <Container className="pt-4">
                    <Row
                        style={{
                            height: "100px",
                        }}
                    >
                        <Col>
                            <Loading />
                        </Col>
                    </Row>
                </Container>
            );
        }
        const brands = this.state.brands.length ?
            this.state.brands.map((note) => (
                <Card key={`brands-${note.id}`} style={{
                    marginBottom: "25px"
                }}>
                    <Card.Header>{note.date}</Card.Header>
                    <Card.Body>
                        <blockquote className="blockquote mb-0">
                            <p className="asTextarea">
                                {note.entry}
                            </p>
                            <footer className="blockquote-footer">
                                <i><b>{note.user_name}</b></i> from <Link className="link" to={`/brand/${note.brand_id}`}><i><b>{note.brand_name}</b></i></Link>
                            </footer>
                        </blockquote>
                    </Card.Body>
                </Card>
            )) : (<Card style={{
                marginBottom: "25px"
            }}>
                <Card.Body>
                    Empty
                </Card.Body>
            </Card>
            );
        const globals = this.state.global.length ?
            this.state.global.map((note, index) => (
                <Card key={`global-${note.id}`} style={{
                    marginBottom: "25px"
                }} i={index}>
                    <Card.Header>{note.date}
                        <div style={{
                            float: "right"
                        }}>
                            <Button size="sm" variant="outline-primary" onClick={this.handleEditNote}>
                                <FontAwesomeIcon icon={faPen} /> Edit note
                            </Button>
                            <Button size="sm" variant="outline-danger" onClick={this.handleDeleteNote}>
                                <FontAwesomeIcon icon={faTrash} /> Delete note
                            </Button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <blockquote className="blockquote mb-0">
                            <p className="asTextarea">
                                {note.entry}
                            </p>
                            <footer className="blockquote-footer">
                                <i><b>{note.user_name}</b></i>
                            </footer>
                        </blockquote>
                    </Card.Body>
                </Card>
            )) : (<Card style={{
                marginBottom: "25px"
            }}>
                <Card.Body>
                    Empty
                </Card.Body>
            </Card>
            );

        return (
            <Container className="pt-4">
                <h3>Global notes
                    <Button style={{
                        float: "right"
                    }}
                        onClick={this.handleNewNote}
                    >
                        <FontAwesomeIcon icon={faPlus} /> New global note
                    </Button>
                </h3>
                {globals}

                <h3>Brand notes</h3>
                {brands}
            </Container>
        );
    }
}

Notes.propTypes = {
    /** Merchant ID */
    merchantId: PropTypes.number.isRequired,
};

export default withRouter(Notes);
