import React from "react";
import PropTypes from "prop-types";
import { Carousel } from "react-bootstrap";

/**
 * Typeahead component for searching for Organizations
 * @component
 */
class MyCarousel extends React.Component {

    constructor(props) {
        super(props);
        this.onSlid = this.onSlid.bind(this);
        this.onSlide = this.onSlide.bind(this);
    }

    onSlid(evt) {
        document.getElementsByClassName("carousel-inner")[0].style.overflow = "visible";
        if (typeof this.props.onSlid === "function") {
            this.props.onSlid(evt);
        }
    }

    onSlide(evt) {
        document.getElementsByClassName("carousel-inner")[0].style.overflow = "hidden";
        if (typeof this.props.onSlide === "function") {
            this.props.onSlide(evt);
        }
    }

    render() {
        return (
            <Carousel activeIndex={this.props.activeIndex} onSlid={this.onSlid} onSlide={this.onSlide} controls={this.props.controls}>
                {this.props.children}
            </Carousel>
        );
    }
}

MyCarousel.propTypes = {
    /**  */
    activeIndex: PropTypes.number.isRequired,
    controls: PropTypes.bool,
    onSlide: PropTypes.func,
    onSlid: PropTypes.func,
};

MyCarousel.defaultProps = {
    activeIndex: 0,
    controls: false
};

export default MyCarousel;
