import React from "react";
import PropTypes from "prop-types";

/**
 * Component in charge of simply displaying the number of elements being shown
 * @component
 */
class PaginationInfo extends React.Component {
    render() {
        let to = this.props.page * this.props.pageSize;
        if (to > this.props.numberOfItems) {
            to = this.props.numberOfItems;
        }
        return (
            this.props.numberOfItems > 0 && (
                <React.Fragment>
                    Showing {(this.props.page - 1) * this.props.pageSize + 1} to{" "}
                    {to} of {this.props.numberOfItems}{" "}
                    {this.props.numberOfItems > 1 ? `entries` : `entry`}
                </React.Fragment>
            )
        );
    }
}

PaginationInfo.propTypes = {
    /** Which page are we at? */
    page: PropTypes.number.isRequired,
    /** How many elements should be shown per page? */
    pageSize: PropTypes.number.isRequired,
    /** How many items we have in total? */
    numberOfItems: PropTypes.number.isRequired,
};

PaginationInfo.defaultValues = {
    page: 1,
    pageSize: 25,
};

export default PaginationInfo;
