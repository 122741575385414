import React from "react";
import PropTypes from "prop-types";

import { Container, Row, Col } from "react-bootstrap";
import Emitter from "../../services/Emitter";
import Loading from "../Loading";
import ProductService from "../../services/ProductService";
import {
    Tooltip,
    Bar,
    BarChart,
    XAxis,
    YAxis,
    ResponsiveContainer,
} from "recharts";
import Toast from "../../services/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import Lightbox from "react-image-lightbox";
import { Link, withRouter } from "react-router-dom";
import Currencies from "../../components/Currencies";

const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    return (
        <text x={x + width / 2} y={y} fill="black" textAnchor="middle" dy={-6}>
            {value}
        </text>
    );
};

/**
 * Renders summary data regarding a product
 * @component
 * @category Scenes
 * @subcategory Products
 */
class Summary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            loading: true,
            image: null
        };
        this.onEnter = this.onEnter.bind(this);
    }

    onEnter() {
        ProductService.summary(this.props.id).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading this product's summary info.");
                return;
            }
            this.setState({
                basic: data.basic,
                crawling: data.crawling,
                websites: data.websites,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        Emitter.off("PRODUCT_SUMMARY");
        Emitter.off("PRODUCT_SUMMARY_RELOAD");
    }

    componentDidMount() {
        Emitter.once("PRODUCT_SUMMARY", this.onEnter);
        Emitter.on("PRODUCT_SUMMARY_RELOAD", this.onEnter);
    }

    render() {
        const showChart = typeof this.state.crawling == "object" && this.state.websites > 0,
            chartTitle = this.state.websites > 0 ? (this.state.websites > 1 ? (<span>This product has been crawled in <b>{this.state.websites}</b> different websites in the last week</span>) : (<span>This product has been crawled in a single website in the last week</span>)) : (<span>This product hasn't been crawled in the last week</span>),
            ok = (<FontAwesomeIcon
                style={{
                    color: "#1f4503",
                }}
                icon={faCheck}
            />),
            nok = (
                <FontAwesomeIcon
                    style={{ color: "red" }}
                    icon={faTimes}
                />
            ),
            upc = typeof this.state.basic == "object" && typeof this.state.basic.upc === "string" && this.state.basic.upc !== this.state.basic.identifier ? this.state.basic.upc : null;
        let brands = [],
            rows = typeof this.state.basic == "object" && typeof this.state.basic.brands == "object" ? Object.keys(this.state.basic.brands) : [];

        rows.forEach((brand_id) => {
            let brand = this.state.basic.brands[brand_id],
                prices = typeof this.state.basic.prices === "object" && typeof this.state.basic.prices[brand_id] === "object" ? this.state.basic.prices[brand_id] : {},
                currencies = Object.keys(prices);
            brand.maps = [];
            currencies.forEach((currency_id) => {
                if (typeof prices[currency_id] === "object" && typeof prices[currency_id][1] === "number") {
                    brand.maps.push({
                        currency_id: currency_id,
                        price: prices[currency_id][1]
                    });
                }
            });
            brand.is_archived = typeof this.state.basic.is_archived === "object" && typeof this.state.basic.is_archived[brand_id] === "number" ? this.state.basic.is_archived[brand_id] : 0;
            brand.is_tracked = typeof this.state.basic.is_tracked === "object" && typeof this.state.basic.is_tracked[brand_id] === "number" ? this.state.basic.is_tracked[brand_id] : 0;
            brands.push(brand);
        });
        return (
            <Container className="pt-4">
                {
                    this.state.image !== null && (
                        <Lightbox
                            mainSrc={this.state.image}
                            onCloseRequest={() => this.setState({ image: null })}
                        />
                    )
                }
                {this.state.loading && <Loading />}
                {!this.state.loading && (
                    <React.Fragment>
                        <div className="thumb" style={{
                            float: "right"
                        }} >
                            <img src={this.state.basic.image === null ? '#' : this.state.basic.image} alt={this.state.basic.identifier} onClick={() => {
                                this.setState({ image: this.state.basic.image.replace(/-tb.jpg/, '.jpg') })
                            }} onError={(e) => {
                                e.target.parentNode.className += " failed";
                                e.target.remove()
                            }} style={{
                                maxWidth: "100px",
                                maxHeight: "100px",
                                cursor: "pointer"
                            }} />
                        </div>
                        <div>
                            <Row>
                                <Col sm={2} className="text-right">Identifier</Col>
                                <Col sm={2}>
                                    <b>{this.state.basic.identifier}</b>
                                </Col>
                                {upc !== null && (
                                    <React.Fragment>
                                        <Col sm={2} className="text-right">UPC</Col>
                                        <Col sm={2}>
                                            <b>{upc}</b>
                                        </Col>
                                    </React.Fragment>
                                )}
                                <Col sm={2} className="text-right">SKU</Col>
                                <Col sm={2}>
                                    <b>{this.state.basic.sku}</b>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={2} className="text-right">Title</Col>
                                <Col sm={9}><b>{this.state.basic.title}</b>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={2} className="text-right">Manufacturer</Col>
                                <Col sm={9}><b>{this.state.basic.manufacturer.name}</b>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={2} className="text-right">Brand / Org</Col>
                                <Col sm={9}>
                                    {brands.map((brand) =>
                                        <div style={{ display: "flex" }}>
                                            <span style={{
                                                minWidth: "300px",
                                                display: "inline-block",
                                                fontWeight: "bold"
                                            }}><Link className="link" to={`/brand/${brand.id}`}>{brand.name}</Link> / <Link className="link" to={`/organization/${brand.organization.id}`}>{brand.organization.name}</Link></span>
                                            <span style={{ marginLeft: "40px" }}>Tracked</span>
                                            <span style={{ marginLeft: "10px" }}>{brand.is_tracked ? ok : nok}</span>
                                            <span style={{ marginLeft: "40px" }}>Archived</span>
                                            <span style={{ marginLeft: "10px" }}>{brand.is_archived ? ok : nok}</span>
                                            <span style={{ marginLeft: "40px" }}>MAP</span>
                                            <span style={{
                                                maxWidth: "275px",
                                                width: "275px",
                                                display: "block",
                                                float: "right",
                                                marginLeft: "10px"
                                            }}>{brand.maps.map((map) =>
                                                <span style={{ paddingRight: "15px" }}>
                                                    <span
                                                        key={`currency-${Currencies.CodeByID(map.currency_id).toLowerCase()}`}
                                                        className={`currency-flag currency-flag-${Currencies.CodeByID(map.currency_id).toLowerCase()}`}
                                                    ></span>
                                                    {map.price}
                                                </span>)}</span>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                {showChart && (
                                    <React.Fragment>
                                        <div className="text-center">Last week of crawling</div>
                                        <ResponsiveContainer width={"99%"} height={300}>
                                            <BarChart
                                                margin={{
                                                    top: 20,
                                                    left: 10,
                                                    right: 0,
                                                    bottom: 10,
                                                }}
                                                style={{
                                                    paddingTop: "20px",
                                                }}
                                                data={this.state.crawling}
                                            >
                                                <XAxis dataKey="date" />
                                                <YAxis />
                                                <Bar dataKey="non-violated" stackId="a" fill="rgb(31, 69, 3)" />
                                                <Bar dataKey="violated" stackId="a" fill="#8c0000" label={renderCustomBarLabel} />
                                                <Tooltip cursor={false} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </React.Fragment>
                                )}
                            </Row>
                            <Row>
                                <div className="text-center">{chartTitle}</div>
                            </Row>
                        </div>
                    </React.Fragment>
                )}
            </Container>
        );
    }
}

Summary.propTypes = {
    /** Product ID */
    id: PropTypes.string.isRequired,
};

export default withRouter(Summary);
