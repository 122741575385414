import React from "react";
import PropTypes from "prop-types";

import { Container, Row, Col } from "react-bootstrap";
import Emitter from "../../services/Emitter";
import Loading from "../Loading";
import MerchantService from "../../services/MerchantService";
import { Link, withRouter } from "react-router-dom";
import {
    Tooltip,
    Bar,
    BarChart,
    XAxis,
    YAxis,
    ResponsiveContainer,
} from "recharts";
import Toast from "../../services/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faFax, faLink, faPhone } from "@fortawesome/free-solid-svg-icons";

/**
 * Renders summary data regarding a merchant
 * @component
 * @category Scenes
 * @subcategory Merchants
 */
class Summary extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState();
        this.onEnter = this.onEnter.bind(this);
    }

    initialState() {
        return {
            id: 0,
            name: "",
            created: "",
            loading: true,
            urls: [],
            volume: [],
            working: false,
            contacts: {}
        };
    }

    componentWillUnmount() {
        Emitter.off("MERCHANT_SUMMARY");
        Emitter.off("MERCHANT_RESET");
    }

    componentDidMount() {
        Emitter.on("MERCHANT_SUMMARY", this.onEnter);
        Emitter.on("MERCHANT_RESET", () => {
            this.setState(this.initialState());
        });
    }

    onEnter() {
        if (this.state.id === this.props.merchantId) {
            return;
        }

        MerchantService.summary(this.props.merchantId).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading this website's summary info.");
                return;
            }

            let volumeObj = [];
            if (typeof data.lastDays === "object") {
                let days = Object.keys(data["lastDays"]);
                days.forEach((day) => {
                    volumeObj.push({
                        name: day,
                        value: data["lastDays"][day],
                    });
                });
                delete data.lastDays;
            }

            data.id = this.props.merchantId;
            data.loading = false;
            data.type = data.type_id === 1 ? 'Online Retailer of' : 'Marketplace Seller from';
            data.created = typeof data.created_at === "string" && data.created_at !== "0000-00-00 00:00:00" ? data.created_at : "";
            data.logo = typeof data.logo_img_url === "string" && data.logo_img_url.match(/^http/) ? data.logo_img_url : null;
            Object.keys(data).forEach((k) => {
                if (typeof data[k] === "undefined" || data[k] === null) {
                    data[k] = "";
                }
            });
            data.volume = volumeObj;
            ["merchant_url", "alexa_global_rank", "dba_name", "merchant_name", "website_name", "phone", "fax", "contact_email", "address_1", "address_2", "city", "state", "country"].forEach((attr) => {
                if (typeof data[attr] === "undefined") {
                    data[attr] = "";
                }
            });
            this.setState(data);
        });
    }

    formatListOfContact(list) {
        return list.map((row) => {
            const phone = typeof row.phone === "string" && row.phone.length > 0 ? (<React.Fragment><br /><FontAwesomeIcon icon={faPhone} /> <a href={`tel:${row.phone}`} className="link">{row.phone}</a></React.Fragment>) : null,
                email = typeof row.email === "string" && row.email.length > 0 ? (<React.Fragment><br /><FontAwesomeIcon icon={faEnvelope} /> <a href={`mailto:${row.email}`} className="link">{row.email}</a></React.Fragment>) : null;
            return (
                <div style={{
                    marginBottom: "20px"
                }}>
                    <span style={{
                        fontSize: "large"
                    }}>{row.name}</span><br /><i>by {` ${row.brand_name}`}</i>
                    {phone}
                    {email}
                </div>
            )
        })
    }

    render() {
        if (this.state.loading) return (
            <Container className="pt-4">
                <Loading />
            </Container>
        );
        const showVolumeChart = typeof this.state.volume == "object" && this.state.volume.length > 0,
            phone = this.state.phone.length > 0 ? <p><FontAwesomeIcon icon={faPhone} /> <a href={`tel:${this.state.phone}`} className="link">{this.state.phone}</a></p> : null,
            fax = this.state.fax.length > 0 ? <p><FontAwesomeIcon icon={faFax} /> {this.state.fax}</p> : null,
            email = this.state.contact_email.length > 0 ? <p><FontAwesomeIcon icon={faEnvelope} /> <a href={`mailto:${this.state.contact_email}`} className="link">{this.state.contact_email}</a></p> : null,
            address = this.state.address_1.length > 0 || this.state.city.length > 0 || this.state.state.length > 0 || this.state.country.length > 0 ?
                <p data-testid="address">
                    {this.state.address_1.length > 0 && (
                        <React.Fragment>
                            {this.state.address_1}
                            < br />
                        </React.Fragment>
                    )}
                    {this.state.address_2.length > 0 && (
                        <React.Fragment>
                            {this.state.address_2}
                            < br />
                        </React.Fragment>
                    )}
                    {this.state.city.length > 0 && (
                        <React.Fragment>
                            {this.state.city}
                            < br />
                        </React.Fragment>
                    )}
                    {this.state.state.length > 0 && (
                        <React.Fragment>
                            {this.state.state}
                            < br />
                        </React.Fragment>
                    )}
                    {this.state.country.length > 0 && (
                        this.state.country
                    )}
                </p> : null,
            alexa = this.state.alexa_global_rank > 0 ? (<p>Alexa Rank: {this.state.alexa_global_rank}</p>) : null,
            url = this.state.merchant_url.length === 0 ? "https://" + this.state.website_name.toLowerCase() : this.state.merchant_url,
            merchant_url = (<p><FontAwesomeIcon icon={faLink} /> <a href={url} rel="noreferrer" target="_blank">{url}</a></p>),
            previous = typeof this.state.previous_names !== "undefined" && this.state.previous_names.length ? (
                <p>&nbsp;<h4>Seller Name History</h4>
                    <ul>
                        {this.state.previous_names.map((row) => (
                            <li>{row.name} (<i>{row.date}</i>)</li>
                        ))}
                    </ul>
                </p>

            ) : null;
        let primary = null,
            account_rep = null,
            others = null;
        if (typeof this.state.contacts === "object") {
            if (typeof this.state.contacts[1] !== "undefined" && this.state.contacts[1].length > 0) {
                primary = (
                    <Col sm={4} data-testid="primary">
                        <h4>Primary Contact{this.state.contacts[1].length > 1 ? 's' : ''}</h4>
                        {this.formatListOfContact(this.state.contacts[1])}
                    </Col >
                )
            }
            if (typeof this.state.contacts[2] !== "undefined" && this.state.contacts[2].length > 0) {
                account_rep = (
                    <Col sm={4} data-testid="account-rep">
                        <h4>Account Rep{this.state.contacts[2].length > 1 ? 's' : ''}</h4>
                        {this.formatListOfContact(this.state.contacts[2])}
                    </Col>
                )
            }
            if (typeof this.state.contacts[3] !== "undefined" && this.state.contacts[3].length > 0) {
                others = (
                    <Col sm={4} data-testid="other-contacts">
                        <h4>Other Contact{this.state.contacts[3].length > 1 ? 's' : ''}</h4>
                        {this.formatListOfContact(this.state.contacts[3])}
                    </Col>
                )
            }
        }
        const contacts = (
            <Row>
                {primary}
                {account_rep}
                {others}
            </Row>
        )

        return (
            <Container className="pt-4" >
                <Row>
                    <Col sm={6}>
                        <h1>{this.state.merchant_name}</h1>
                        {this.state.dba_name.length > 0 && (<h4>{this.state.dba_name}</h4>)}
                        <h5>{this.state.type}{` `}<Link to={`/website/${this.state.website_id}`}>{this.state.website_name}</Link></h5>
                        {this.state.logo.length > 0 && (
                            <img src={this.state.logo} referrerpolicy="no-referrer" alt="Merchant logo" />
                        )}
                        <p>Parent Organization: {this.state.org_name}</p>
                        {merchant_url}
                        {alexa}
                        {phone}
                        {fax}
                        {email}
                        {address}
                    </Col>
                    <Col sm={6}>
                        {previous}
                    </Col>
                </Row>
                {showVolumeChart && (
                    <Row data-testid="last-week">
                        <p align="center">Last month of crawling (unique hits)</p>

                        <ResponsiveContainer width={"99%"} height={300}>
                            <BarChart data={this.state.volume}>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Bar
                                    dataKey="value"
                                    fill="#8884d8"
                                />
                                <Tooltip cursor={false} />

                            </BarChart>
                        </ResponsiveContainer>
                    </Row>
                )}
                {contacts}
            </Container>
        );
    }
}

Summary.propTypes = {
    /** Merchant ID */
    merchantId: PropTypes.number.isRequired,
};

export default withRouter(Summary);
