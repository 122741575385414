import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

/**
 * Shows a loading spinner that should cover the following table
 * @component
 */
class TableSpinnerOverlay extends React.Component {
    render() {
        if (!this.props.loading) {
            return null;
        }
        return (
            <React.Fragment>
                <div
                    style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "white",
                        opacity: "0.25",
                        zIndex: "2"
                    }}
                />
                <div
                    style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        zIndex: "9999",
                        transform: "translate(-50%, -50%)"
                    }}
                >
                    <Spinner
                        animation="border"
                        role="status"
                        style={{
                            position: "relative",
                            top: "50%",
                            left: "50%",
                        }}
                    >
                    </Spinner >
                </div>
            </React.Fragment >
        );
    }
}

TableSpinnerOverlay.propTypes = {
    /** Should it be visible? */
    loading: PropTypes.bool.isRequired,
};

export default TableSpinnerOverlay;
