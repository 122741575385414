import React, { useMemo } from "react";

import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Toast from "../../services/Toast";
import Loading from "../Loading";
import Emitter from "../../services/Emitter";
import ProcessingButton from "../../components/ProcessingButton";
import ProductService from "../../services/ProductService";
import { useDropzone } from "react-dropzone";


/**
 * Show a CRUD for the Products row
 * @component
 * @category Scenes
 * @subcategory Products
 */
class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState();

        this.handleChange = this.handleChange.bind(this);
        this.dropEdition = this.dropEdition.bind(this);
        this.startEdition = this.startEdition.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.reload = this.reload.bind(this);
        this.handleRemoveImage = this.handleRemoveImage.bind(this);
    }

    initialState() {
        return {
            id: 0,
            row: {},
            loading: true,
            editing: false,
            updating: false,
            file: null,
            preview: null,
            removeImage: false,
            functionalImageURL: true
        };
    }

    handleChange(evt) {
        let key = evt.target.name,
            row = this.state.row,
            isCheckbox = evt.target.className.match(/check/);
        switch (key) {
            default:
                row[key] = isCheckbox
                    ? this.state.row[key]
                        ? 0
                        : 1
                    : evt.target.value;
        }
        this.setState({ row });
    }


    reload() {
        ProductService.get(this.props.id).then((data) => {
            if (data === false) {
                return;
            }
            this.setState({
                loading: false,
                row: data,
                editing: false,
                image: typeof data.image === "string" && data.image.length > 0 ? data.image : "#",
                preview: null,
                removeImage: false
            });
        });
    }

    componentWillUnmount() {
        Emitter.off("PRODUCT_PROFILE");
    }

    componentDidMount() {
        Emitter.on("PRODUCT_PROFILE", this.reload);
    }

    saveChanges() {

        this.setState({ updating: true });
        let payload = {
            row: this.state.row,
        },
            config = {
                headers: {
                    'content-type': 'application/json'
                }
            }
        if (this.state.removeImage) {
            payload.row.image = 'remove';
        } else if (this.state.preview !== null) {
            let row = this.state.row;
            row.image = 'new';
            payload = new FormData();
            payload.append('row', JSON.stringify(row));
            payload.append('image', this.image);
            config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
        }

        ProductService.update(this.props.id, payload, config).then((result) => {
            if (result === false) {
                this.setState({ updating: false, editing: false });
                Toast.error(
                    "Something wrong happened and changes could not be saved."
                );
            } else {
                this.setState({
                    updating: false,
                    editing: false,
                    row: result,
                    functionalImageURL: true,
                    removeImage: false,
                    image: result.image === null ? "#" : result.image
                });
                Toast.ok("Changes properly saved.");
            }
        });
    }

    startEdition() {
        this.original = JSON.stringify(this.state.row);
        this.setState({ editing: true });
    }

    dropEdition() {
        this.file = null
        this.setState({
            editing: false,
            row: JSON.parse(this.original),
            image: null,
            preview: null,
            removeImage: true
        });
        delete this.original;
    }

    handleRemoveImage() {
        this.file = null;
        this.setState({
            image: null,
            preview: null,
            removeImage: true
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <Container className="pt-4">
                    <Row
                        style={{
                            height: "100px",
                        }}
                    >
                        <Col>
                            <Loading />
                        </Col>
                    </Row>
                </Container>
            );
        }

        const baseStyle = {
            cursor: "pointer",
            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#eeeeee',
            borderStyle: 'dashed',
            outline: 'none',
            position: "absolute"
        };

        const activeStyle = {
            borderColor: '#2196f3'
        };

        const acceptStyle = {
            borderColor: '#00e676'
        };

        const rejectStyle = {
            borderColor: '#ff1744'
        };

        function StyledDropzone(props) {
            const {
                getRootProps,
                getInputProps,
                isDragActive,
                isDragAccept,
                isDragReject
            } = useDropzone({
                accept: 'image/*', onDrop: acceptedFiles => {
                    if (acceptedFiles.length === 0) {
                        Toast.error("File type not valid");
                        return;
                    }
                    props.onFileSelected(acceptedFiles[0]);
                }
            });

            const style = useMemo(() => ({
                ...baseStyle,
                ...(isDragActive ? activeStyle : {}),
                ...(isDragAccept ? acceptStyle : {}),
                ...(isDragReject ? rejectStyle : {})
            }), [
                isDragActive,
                isDragReject,
                isDragAccept
            ]);

            return (
                <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <img src={props.image} alt={''}
                        className="thumb"
                        style={{
                            maxWidth: "100px",
                            maxHeight: "100px",
                        }}
                        onError={(e) => {
                            e.target.className += " failed";
                            e.target.src = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                        }} />
                </div>
            );
        }

        const Image = () => {
            if (this.state.editing || this.state.updating) {
                return (
                    <React.Fragment>
                        <StyledDropzone image={this.state.preview !== null ? this.state.preview : (this.state.image !== null ? this.state.image : "#")} onFileSelected={(file) => {
                            this.image = file;
                            this.setState({ preview: URL.createObjectURL(file), removeImage: false })
                        }} />
                        {(this.state.image !== null || this.state.preview !== null) && !this.state.removeImage && this.state.functionalImageURL && (
                            <Button size="sm" variant="outline-danger" style={{
                                position: "relative",
                                top: "70px",
                                left: "70px"
                            }} onClick={this.handleRemoveImage}>Remove image</Button>
                        )}
                    </React.Fragment>
                )
            }
            return (
                <div style={{
                    width: "100px",
                    height: "100px",
                    position: "absolute"
                }}>
                    <img src={this.state.image} alt="" className="thumb"
                        onError={(e) => {
                            e.target.className += " failed";
                            e.target.src = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                            this.setState({ functionalImageURL: false });
                        }} />
                </div>
            )
        }

        return (
            <Container className="pt-4" >
                <Row>
                    <Col sm={1}>
                        <Form.Label>ID</Form.Label>
                        <Form.Control
                            type="text"
                            name="id"
                            value={this.state.row.id}
                            readOnly={true}
                        />
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Identifier</Form.Label>
                        <Form.Control
                            type="text"
                            name="identifier"
                            value={this.state.row.identifier}
                            readOnly={true}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>UPC</Form.Label>
                        <Form.Control
                            type="text"
                            name="identifier"
                            value={this.state.row.upc}
                            readOnly={true}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>SKU</Form.Label>
                        <Form.Control
                            type="text"
                            name="sku"
                            value={this.state.row.sku}
                            readOnly={
                                !this.state.editing || this.state.updating
                            }
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col style={{
                        paddingRight: "unset",
                        paddingLeft: "unset",
                    }} sm={4}>
                        <Form.Label>Image</Form.Label>
                        <Image />
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            name="title"
                            value={this.state.row.title}
                            onChange={this.handleChange}
                            readOnly={
                                !this.state.editing || this.state.updating
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <Form.Label>Created</Form.Label>
                        <Form.Control
                            type="text"
                            name="created_at"
                            value={this.state.row.created_at}
                            readOnly={true}
                        />
                    </Col>
                    <Col sm={4}>
                        <Form.Label>Modified</Form.Label>
                        <Form.Control
                            type="text"
                            name="modified_at"
                            value={this.state.row.modified}
                            readOnly={true}
                        />
                    </Col>

                </Row>
                <Row
                    style={{
                        marginTop: "30px",
                        height: "350px",
                    }}
                >
                    <Col sm={6}>
                        {!this.state.editing && (
                            <Button
                                variant="secondary"
                                onClick={this.startEdition}
                            >
                                Edit
                            </Button>
                        )}
                        {this.state.editing && (
                            <span>
                                <Button
                                    disabled={this.state.updating}
                                    variant="secondary"
                                    onClick={this.dropEdition}
                                >
                                    Cancel
                                </Button>
                                <ProcessingButton
                                    variant="primary"
                                    processing={this.state.updating}
                                    processingLabel="Saving ..."
                                    label="Save"
                                    onClick={this.saveChanges}
                                />
                            </span>
                        )}
                    </Col>
                </Row>
            </Container >
        );
    }
}

export default Profile;
