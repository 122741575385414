import React from "react";
import PropTypes from "prop-types";

import {
    Breadcrumb, Button, Col,
    Container, Form, Row,
} from "react-bootstrap";
import Emitter from "../../services/Emitter";
import { Link, withRouter } from "react-router-dom";
import ProcessingButton from "../../components/ProcessingButton";
import PartnerService from "../../services/PartnerService";
import Toast from "../../services/Toast";
import Utils from "../../services/Utils";
import Loading from "../Loading";
import MultiSelect from "react-multi-select-component";

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState();
        this.initialState = this.initialState.bind(this);
        this.fetchPartner = this.fetchPartner.bind(this);
        this.savePartner = this.savePartner.bind(this);
        this.fetchModules = this.fetchModules.bind(this);
        this.Form = this.Form.bind(this)
    }

    async componentDidMount() {
        this.fetchModules();
        Emitter.on("EDIT_PARTNER", this.fetchPartner);
        Emitter.on("NEW_PARTNER", () => {
            this.setState(this.initialState());
        });
    }

    componentWillUnmount() {
        Emitter.off("NEW_PARTNER");
        Emitter.off("EDIT_PARTNER");
    }

    fetchModules() {
        PartnerService.modules().then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of modules");
                return;
            }
            this.setState({ moduleList: data, modules: data });
        });
    }

    fetchPartner() {
        this.setState({
            loading: true,
            partnerId: parseInt(window.location.href.match(/edit\/(\d+)/)[1])
        }, () => {
            PartnerService.get(this.state.partnerId).then((data) => {

                if (data === false) {
                    Toast.error("Something went wrong while loading the list of modules");
                    return;
                }

                this.setState({
                    name: data.name,
                    email: data.email,
                    modules: data.modules,
                    enabled: data.enabled,
                    loading: false
                })
            })
        });
    }

    initialState() {
        return {
            name: '',
            email: '',
            modules: this.state?.modules ?? [],
            enabled: true,
            moduleList: this.state?.modules ?? [],
            processing: false,
            loading: false,
        };
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    savePartner() {

        if (!this.validateForm()) return;

        this.setState({ processing: true });

        const params = {
            name: this.state.name,
            email: this.state.email,
            enabled: this.state.enabled ? 1 : 0,
            modules: this.state.modules.map((module) => module.id),
        }

        this.props.action === 'new'
            ? this.create(params)
            : this.update(params);
    }

    create(params) {
        PartnerService.create(params)
            .then((response) => {
                this.setState({ processing: false })
                if (response.status === false) {
                    Toast.error(response?.message || 'Something went wrong while creating new Partner');
                    return;
                }

                Toast.ok("Partner created successfully");
                Emitter.emit("RELOAD_PARTNER_LIST");
                this.props.history.push(`/partner`);
            })
    }

    update(params) {
        PartnerService.update(this.state.partnerId, params)
            .then((response) => {
                this.setState({ processing: false })
                if (response.status === false) {
                    Toast.error(response?.message || 'Something went wrong while updating the Partner');
                    return;
                }

                Toast.ok("Partner updated successfully");
                Emitter.emit("RELOAD_PARTNER_LIST");
                this.props.history.push(`/partner`);
            })
    }

    validateForm() {
        const { name, email, modules } = this.state;
        const inputLimit = 150;

        const showError = (message) => {
            Toast.error(message);
            return false;
        };

        if (!name.trim()) {
            return showError("Name is required.");
        }

        if (name.length > inputLimit) {
            return showError("Name is too long.");
        }

        if (!email.trim()) {
            return showError("Email is required.");
        }

        if (email.length > inputLimit) {
            return showError("Email is too long.");
        }

        if (!Utils.IsValidEmail(email)) {
            return showError("Email is invalid.");
        }

        if (modules.length === 0) {
            return showError("Module is required.");
        }

        return true;
    }

    canSubmit() {
        return !!(this.state.name?.length && this.state.email?.length);
    }

    Form(isEditing) {
        if (this.state.loading) {
            return <Row
                style={{
                    height: "100px",
                }}
            >
                <Col>
                    <Loading />
                </Col>
            </Row>
        }
        return <React.Fragment>
            <Row>
                <Col
                    sm={1}
                    className="d-flex align-items-center justify-content-end text-center"
                >
                    Name
                </Col>
                <Col sm={5}>
                    <Form.Control
                        type="text"
                        name="name"
                        value={this.state.name}
                        disabled={this.state.processing}
                        onChange={this.handleInputChange}
                    />
                </Col>
                <Col
                    sm={1}
                    className="d-flex align-items-center justify-content-end text-center"
                >
                    Email
                </Col>
                <Col sm={5}>
                    <Form.Control
                        type="email"
                        name="email"
                        value={this.state.email}
                        disabled={this.state.processing}
                        onChange={this.handleInputChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col
                    sm={1}
                    className="d-flex align-items-center justify-content-end text-center"
                >
                    Modules
                </Col>
                <Col sm={5}>
                    <MultiSelect
                        options={this.state.moduleList}
                        value={this.state.modules}
                        onChange={(selected) => this.setState({ modules: selected })}
                        labelledBy="Select"
                        disabled={this.state.processing}
                    />
                </Col>
                {isEditing && (
                    <React.Fragment>
                        <Col
                            sm={1}
                            className="d-flex align-items-center justify-content-end text-center"
                        >
                            Status
                        </Col>
                        <Col sm={5}>
                            <Form.Check
                                style={{
                                    marginTop: "0.5rem"
                                }}
                                type="switch"
                                label={this.state.enabled ? "Active" : "Disabled"}
                                checked={this.state.enabled}
                                onChange={(evt) => { this.setState({ enabled: evt.target.checked }) }} />
                        </Col>
                    </React.Fragment>
                )}
            </Row>
            <Row>
                <Col className="d-flex align-items-center justify-content-center text-center"
                >
                    <Button
                        size="sm"
                        variant="secondary"
                        onClick={() => {
                            this.props.history.push("/partner");
                        }}
                    >
                        Cancel
                    </Button>
                    <ProcessingButton
                        size="sm"
                        processingLabel="Saving ..."
                        label="Save"
                        processing={this.state.processing}
                        onClick={this.savePartner}
                        disabled={!this.canSubmit()}
                    />
                </Col>
            </Row>
        </React.Fragment>
    }

    render() {
        const isEditing = this.props.action === 'edit';
        return (
            <Container className="pt-3">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>
                        <Link to="/partner">
                            Integration Partners
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{isEditing ? 'Edit' : 'New'}</Breadcrumb.Item>
                </Breadcrumb>
                {this.Form(isEditing)}
            </Container >
        );
    }
}

Profile.propTypes = {
    action: PropTypes.string,
};

Profile.defaultProps = {
    action: 'new'
};

export default withRouter(Profile);
