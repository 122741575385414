import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Toast, ToastContainer } from "react-bootstrap";

import TopMenu from "./components/TopMenu";
import AuthService from "./services/AuthService";
import Emitter from "./services/Emitter";
import GoogleAnalyticsTracking from "./components/GoogleAnalyticsTracking";
import Routes from "./Routes";
import "./App.css";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            authenticated: false,
            user: false,
            toast: {
                show: false,
                title: "",
                delay: 0,
                message: "",
                theme: "dark",
            }
        };
        const { location } = window;
        AuthService.init(location.pathname + location.hash);
    }

    componentWillUnmount() {
        Emitter.off("LOGGED_IN");
        Emitter.off("REAUTH");
        Emitter.off("MAINTENANCE");
        Emitter.off("LOGGED_OUT");
        Emitter.off("TOAST");
    }

    componentDidMount() {
        Emitter.on("LOGGED_IN", () => {
            this.setState({
                authenticated: true,
                user: AuthService.user(),
            });
        });
        Emitter.on("REAUTH", () => {
            AuthService.reauth();
        });
        Emitter.on("MAINTENANCE", () => {
            if (window.location.href.indexOf("/maintenance") === -1) {
                window.location.href = "/maintenance";
            }
        });
        Emitter.on("LOGGED_OUT", () => {
            this.setState({
                authenticated: false,
                user: false,
            });
        });
        Emitter.on("TOAST", (config) => {
            let settings = {
                delay: 1500,
                title: "",
                message: "",
                theme: "info",
                show: true,
            };
            Object.keys(settings).forEach((key) => {
                if (typeof config[key] != "undefined") {
                    settings[key] = config[key];
                }
            });
            this.setState({
                toast: settings,
            });
        });
    }

    render() {
        return (
            <BrowserRouter>
                <TopMenu user={this.state.user} />
                <Routes authenticated={this.state.authenticated} />
                <GoogleAnalyticsTracking />
                <ToastContainer
                    className="position-fixed p-3"
                    position="bottom-end"
                >
                    <Toast
                        bg={this.state.toast.theme.toLowerCase()}
                        animation={true}
                        delay={this.state.toast.delay}
                        autohide
                        show={this.state.toast.show}
                        onClose={() => {
                            let toast = this.state.toast;
                            toast.show = false;
                            this.setState({ toast });
                        }}
                    >
                        <Toast.Header>
                            <span
                                style={{
                                    width: "100%",
                                    fontWeight: "bold",
                                }}
                            >
                                {this.state.toast.title}
                            </span>
                        </Toast.Header>
                        <Toast.Body
                            style={{
                                backgroundColor: "white",
                            }}
                        >
                            {this.state.toast.message}
                        </Toast.Body>
                    </Toast>
                </ToastContainer>
            </BrowserRouter>
        );
    }
}

export default App;
