import React from "react";

import {
    Container,
    Breadcrumb,
    Button,
    Popover,
    OverlayTrigger,
    PopoverBody,
} from "react-bootstrap";
import DomainDiscoveryService from "../../services/DomainDiscoveryService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faDownload, faRedo, faPause, faPlus, faFileWord, faListOl, faBars, faWrench, faTrash } from "@fortawesome/free-solid-svg-icons";
import { NavLink, withRouter } from "react-router-dom";
import Emitter from "../../services/Emitter";
import Toast from "../../services/Toast";
import Utils from "../../services/Utils";
import ProgressBar from "../../components/ProgressBar";
import { Menu, MenuItem, SubMenu } from "@szhsin/react-menu";
import MyTable from "../../components/MyTable";
import { createRef } from "react";
import MySpinner from "../../components/MySpinner";
import confirm from "../../components/Confirm";


/**
 * Jobs component
 * @component
 * @category Scenes
 * @subcategory Domain Discovery
 */
class JobsList extends React.Component {
    constructor(props) {
        super(props);

        this.handleDownload = this.handleDownload.bind(this);
        this.handleStart = this.handleStart.bind(this);
        this.handlePause = this.handlePause.bind(this);
        this.viewJob = this.viewJob.bind(this);
        this.deleteJob = this.deleteJob.bind(this);

        this.reload = this.reload.bind(this);
        this.tableRef = createRef();
    }

    componentWillUnmount() {
        Emitter.off("RELOAD_DD_JOBS", this.reload);
    }

    componentDidMount() {
        Emitter.on("RELOAD_DD_JOBS", this.reload);
        this.reload();
    }

    reload() {
        this.tableRef.current?.setLoading(true);
        return this.tableRef.current?.reload();
    }

    handleStart(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);

        obj.row.status = '...';
        obj.row.updating = true;
        this.tableRef.current.setRow(obj.index, obj.row);

        DomainDiscoveryService.playpause(obj.row.id, true).then((result) => {
            if (result === false) {
                obj.row.status = 'paused';
                obj.row.updating = false;
                Toast.error("Something went wrong while resuming this job");
            } else {
                obj.row.status = 'pending';
                obj.row.updating = false;
                Toast.ok("Job resumed successfully");
            }
            this.tableRef.current?.setRow(obj.index, obj.row);
        });

    }

    handlePause(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);

        obj.row.status = '...';
        obj.row.updating = true;
        this.tableRef.current.setRow(obj.index, obj.row);

        DomainDiscoveryService.playpause(obj.row.id, false).then((result) => {
            if (result === false) {
                obj.row.status = 'pending';
                obj.row.updating = false;
                Toast.error("Something went wrong while pausing this job");
            } else {
                obj.row.status = 'paused';
                obj.row.updating = false;
                Toast.ok("Job paused successfully");
            }
            this.tableRef.current?.setRow(obj.index, obj.row);
        });
    }

    handleDownload(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt),
            mode = obj.target.getAttribute("mode");

        obj.row.downloading = true;
        this.tableRef.current.setRow(obj.index, obj.row);

        let func;
        switch (mode) {
            case "keywords":
                func = DomainDiscoveryService.getKeywords;
                break;
            case "domains":
                func = DomainDiscoveryService.getDomains;
                break;
            default:
                func = DomainDiscoveryService.getResults;
        }

        func(obj.row.id).then((result) => {
            if (!result) {
                Toast.error(
                    "Something went wrong while generating the report."
                );
            }
            obj.row.downloading = false;
            this.tableRef.current?.setRow(obj.index, obj.row);
        });
    }

    viewJob(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);
        this.props.history.push('/dd/job/' + obj.row.id);
    }

    async deleteJob(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);

        if (
            !(await confirm("Are you sure?", "Continue", "Cancel", {
                message:
                    "You're about delete a DD request (with all its results) from the database. This operation can't be undone. Do you really want to continue?",
            }))
        ) {
            return;
        }

        obj.row.deleting = true;
        this.tableRef.current.setRow(obj.index, obj.row);
        this.tableRef.current.setLoading(true);
        DomainDiscoveryService.deleteJob(obj.row.id).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while deleting this job.");
                delete obj.row.deleting;
                this.tableRef.current?.setRow(obj.index, obj.row);
                this.tableRef.current?.setLoading(false);
            } else {
                Toast.info("Job #" + obj.row.id + " deleted successfully.");
                this.tableRef.current?.reload();
            }
        });
    }

    render() {
        return (
            <Container className="pt-4" >
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Domain Discovery</Breadcrumb.Item>
                    <Breadcrumb.Item active>Jobs</Breadcrumb.Item>
                </Breadcrumb>
                <MyTable
                    name="dd-job"
                    dynamic={true}
                    ref={this.tableRef}
                    sortBy="id"
                    sortOrder="desc"
                    headers={
                        [
                            {
                                field: "id",
                                label: "ID",
                                width: "60px",
                                sortable: true,
                                searchable: true,
                            },
                            {
                                field: "manufacturer",
                                label: "Manufacturer",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "created",
                                label: "Requested",
                                width: "140px",
                                sortable: true,
                            },
                            {
                                field: "requester",
                                label: "Requester",
                                sortable: true,
                                searchable: true

                            },
                            {
                                field: "results",
                                label: "# Results",
                                hint: "Number of results found for the fragment",
                                width: "100px",
                            },
                            {
                                field: "domains",
                                label: "# Domains",
                                hint: "Number of unique domain names found for the fragment",
                                width: "100px",
                            },
                            {
                                field: "duration",
                                label: "Duration",
                                width: "100px",
                                sortable: true,
                            }, {
                                field: "fragments",
                                label: "Fragments",
                                width: "100px",
                                sortable: true,

                            },
                            {
                                field: "progress",
                                label: "Progress"
                            },
                            {
                                field: "status",
                                label: "Status",
                                width: "100px",
                                sortable: true
                            },
                            {
                                field: "control",
                                width: "80px",
                                content: <React.Fragment>
                                    <Button
                                        size="sm"
                                        variant="primary"
                                        className="tdButton"
                                        onClick={() => {
                                            this.reload().then((result) => {
                                                if (result) {
                                                    Toast.ok("Data reloaded successfully");
                                                }
                                            })
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faRedo} />
                                    </Button>
                                    <Button
                                        as={NavLink}
                                        to="/dd/job/new"
                                        size="sm"
                                        variant="success"
                                        className="tdButton"
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                </React.Fragment>,
                                className: "text-center"
                            }
                        ]
                    }
                    renderColumns={{
                        manufacturer: {
                            text: (row) => row.manufacturer.id + " " + row.manufacturer.name,
                            format: (row) => row.manufacturer.name
                        },
                        duration: (row) => Utils.Seconds2PrettyString(row.duration),
                        progress: (row) => {
                            const bgcolor = row.status === 'paused' ? "#525252" : "#1f4503",
                                complete = row.status === 'wrapping' ? 99.9 : ((row.fragments - row.pending) * 100) / row.fragments;
                            return <ProgressBar
                                key={`progress-bar-${row.id}`}
                                bgcolor={bgcolor}
                                completed={complete}
                            />
                        },
                        status: (row) => row.status === 'wrapping' ? 'WRAPPING UP' : row.status.toUpperCase(),
                        control: {
                            format: (row) => {
                                let State = null;
                                if (typeof row.deleting !== "undefined" && row.deleting) {
                                    return <MySpinner />;
                                }

                                if (row.status === 'paused') {
                                    State = <OverlayTrigger
                                        placement="right"
                                        trigger={["hover", "focus"]}
                                        overlay={
                                            <Popover>
                                                <PopoverBody>Resume this job</PopoverBody>
                                            </Popover>
                                        }
                                    >
                                        <Button
                                            size="sm"
                                            variant="secondary"
                                            className="tdButton"
                                            onClick={this.handleStart}
                                        >
                                            <FontAwesomeIcon icon={faPlay} />
                                        </Button>
                                    </OverlayTrigger>

                                } else if (row.status === 'pending') {
                                    State = <OverlayTrigger
                                        placement="right"
                                        trigger={["hover", "focus"]}
                                        overlay={
                                            <Popover>
                                                <PopoverBody>Pause this job</PopoverBody>
                                            </Popover>
                                        }
                                    >
                                        <Button
                                            size="sm"
                                            variant="danger"
                                            className="tdButton"
                                            onClick={this.handlePause}
                                        >
                                            <FontAwesomeIcon icon={faPause} />
                                        </Button>
                                    </OverlayTrigger>
                                } else if (typeof row.updating !== "undefined" && row.updating) {
                                    State = <MySpinner />
                                }
                                return <React.Fragment>
                                    {row.downloading && (
                                        <MySpinner />
                                    )}
                                    {(typeof row.downloading === "undefined" || !row.downloading) && (
                                        <Menu
                                            direction="left"
                                            menuButton={<Button
                                                size="sm"
                                                variant="outline-secondary"
                                                className="tdButton">
                                                <FontAwesomeIcon icon={faWrench} />
                                            </Button>}>
                                            <SubMenu label={
                                                <React.Fragment>
                                                    <FontAwesomeIcon
                                                        icon={faDownload}
                                                        style={{
                                                            marginRight: "10px",
                                                        }}
                                                    />
                                                    Download
                                                </React.Fragment>
                                            }>
                                                {row.status === 'finished' && (
                                                    <MenuItem mode="report" onClick={this.handleDownload}>
                                                        <FontAwesomeIcon
                                                            icon={faListOl}
                                                            style={{
                                                                marginRight: "10px",
                                                            }}
                                                        />
                                                        Full report
                                                    </MenuItem>)}
                                                {row.status === 'finished' && (
                                                    <MenuItem mode="domains" onClick={this.handleDownload}>
                                                        <FontAwesomeIcon
                                                            icon={faBars}
                                                            style={{
                                                                marginRight: "10px",
                                                            }}
                                                        />
                                                        Domains
                                                    </MenuItem>
                                                )}
                                                <MenuItem mode="keywords" onClick={this.handleDownload}>
                                                    <FontAwesomeIcon
                                                        icon={faFileWord}
                                                        style={{
                                                            marginRight: "10px",
                                                        }}
                                                    />
                                                    Keywords</MenuItem>
                                            </SubMenu>
                                            <MenuItem onClick={this.viewJob}>
                                                <FontAwesomeIcon
                                                    icon={faBars}
                                                    style={{
                                                        marginRight: "10px",
                                                    }}
                                                />
                                                View job
                                            </MenuItem>
                                            <MenuItem onClick={this.deleteJob}>
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    style={{
                                                        marginRight: "10px",
                                                    }}
                                                />
                                                Delete job
                                            </MenuItem>
                                        </Menu >
                                    )}
                                    {State}
                                </React.Fragment>
                            },
                            style: {
                                width: "87px",
                                display: "inline-flex"
                            }
                        }
                    }}
                    reload={(setup) => {
                        return new Promise((resolve, reject) => {
                            DomainDiscoveryService.jobs({
                                pageSize: setup.pageSize,
                                page: setup.page,
                                search: setup.search,
                                sort: setup.sort,
                            }).then((data) => {
                                if (data === false) {
                                    Toast.error(
                                        "Something went wrong while retrieving the list of jobs"
                                    );
                                    reject();
                                    return
                                }
                                resolve(data);
                            }).catch(reject)

                        });
                    }}
                />

            </Container >
        );
    }
}

export default withRouter(JobsList);
