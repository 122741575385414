import React, { createRef } from "react";
import PropTypes from "prop-types";
import OrganizationService from "../services/OrganizationService";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

/**
 * Typeahead component for searching for Organizations
 * @component
 */
class OrganizationSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        }
        this.ref = createRef();
    }

    handleSearch = (query) => {
        this.setState({
            isLoading: true,
        });
        OrganizationService.typeahead(query).then((options) => {
            if (options === null) {
                // this request was canceled due to a change in the typeahead component
                return;
            }
            this.setState({
                options,
                isLoading: false,
            });
        });
    };

    clear = () => {
        this.ref.current.clear();
    }

    render() {
        const filterBy = () => true;

        return <AsyncTypeahead
            {...this.state}
            disabled={this.props.disabled}
            ref={this.ref}
            filterBy={filterBy}
            labelKey="name"
            id="organization-search"
            maxResults={10}
            minLength={2}
            isInvalid={this.props.invalid}
            onSearch={this.handleSearch}
            onChange={this.props.onChange}
            defaultSelected={this.props.defaultSelected}
            placeholder={this.props.placeholder}
            renderMenuItemChildren={(option) => (
                <div
                    key={`m${option.id}`}
                    className={option.status === 'active' ? '' : 'orgDisabled'}
                >
                    <span className="colHead">id:</span>
                    <span className="colValue">
                        {option.id}
                    </span>
                    <span className="colHead">name:</span>
                    <span className="colValue">
                        {option.name}
                    </span>
                    <span className="colHead">type:</span>
                    <span className="colValue">
                        {option.type}
                    </span>
                    {typeof option.brands === "object" && option.brands !== null && option.brands.length && (
                        <React.Fragment>
                            <span className="colHead">brand{option.brands.length > 1 ? 's' : ''}:</span>
                            <span className="colValue">
                                {option.brands.map(brand => brand.name).join(', ')}
                            </span>
                        </React.Fragment>
                    )}
                    {typeof option.hubspot === "string" && option.hubspot.length && (
                        <React.Fragment>
                            <span className="colHead">hubspot:</span>
                            <span className="colValue">
                                {option.hubspot}
                            </span>
                        </React.Fragment>
                    )}
                </div>
            )}
            useCache={false}
        />
    }
}

OrganizationSearch.propTypes = {
    /**  */
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    placeholder: PropTypes.string.isRequired,
};

OrganizationSearch.defaultProps = {
    onChange: (values) => {
        console.log("Organization has changed", values);
    },
    disabled: false,
    invalid: false,
    placeholder: "Search for an organization..."
};

export default OrganizationSearch;
