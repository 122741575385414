import React, { createRef } from "react";

import { Container, Breadcrumb, Button } from "react-bootstrap";
import ImageDiscoveryService from "../../services/ImageDiscoveryService";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faListAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import Emitter from "../../services/Emitter";
import Toast from "../../services/Toast";
import Utils from "../../services/Utils";
import ProgressBar from "../../components/ProgressBar";
import MyTable from "../../components/MyTable";

/**
 * Jobs component
 * @component
 * @category Scenes
 * @subcategory Image Discovery
 */
class JobsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loading: true }
        this.tableRef = createRef();
        this.reload = this.reload.bind(this);
    }

    componentWillUnmount() {
        Emitter.off("RELOAD_IMG_JOBS", this.reload);
    }

    componentDidMount() {
        Emitter.on("RELOAD_IMG_JOBS", this.reload);
        this.tableRef.current.init();
    }

    reload() {
        return this.tableRef.current.reload();
    }

    render() {
        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Image Discovery</Breadcrumb.Item>
                    <Breadcrumb.Item active>Jobs</Breadcrumb.Item>
                </Breadcrumb>
                <MyTable
                    name="imgdiscovery-jobs"
                    dynamic={true}
                    ref={this.tableRef}
                    sortBy="id"
                    sortOrder="desc"
                    headers={[
                        {

                            field: "id",
                            label: "ID",
                            width: "50px",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "manufacturer",
                            label: "Manufacturer",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "created",
                            label: "Requested",
                            width: "140px",
                            sortable: true
                        },
                        {
                            field: "requester",
                            label: "Requester",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "duration",
                            label: "Duration",
                            width: "100px",
                            sortable: true
                        }, {
                            field: "products",
                            label: "Products",
                            width: "100px",
                            sortable: true
                        },
                        {
                            field: "progress"
                        },
                        {
                            field: "status",
                            label: "Status",
                            width: "100px",
                            sortable: true,
                            allowEmpty: true,
                            options: [{ label: 'Finished', value: 'finished' }, { label: 'Pending', value: 'pending' }]
                        },
                        {
                            field: "control",
                            width: "80px",
                            content: <React.Fragment>
                                <Button
                                    size="sm"
                                    variant="primary"
                                    className="tdButton"
                                    onClick={() => {
                                        this.reload().then((result) => {
                                            if (result) {
                                                Toast.ok("Data reloaded successfully");
                                            }
                                        })
                                    }}
                                >
                                    <FontAwesomeIcon icon={faRedo} />
                                </Button>
                                <Button
                                    as={NavLink}
                                    to="/imgdiscovery/new"
                                    size="sm"
                                    variant="success"
                                    className="tdButton"
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </React.Fragment>
                        }]}

                    renderColumns={{
                        manufacturer: {
                            format: (row) => row.manufacturer.name,
                            text: (row) => row.manufacturer.id + ' ' + row.manufacturer.name
                        },
                        duration: (row) => Utils.Seconds2PrettyString(row.duration),
                        progress: (row) => <ProgressBar
                            key={`progress-bar-${row.id}`}
                            bgcolor="#1f4503"
                            completed={
                                ((row.products - row.pending) * 100) / row.products
                            }
                        />,
                        status: (row) => row.status.toUpperCase(),
                        control: {
                            format: (row) => <Button
                                size="sm"
                                variant="outline-secondary"
                                className="tdButton"
                                onClick={() => { this.props.history.push("/imgdiscovery/job/" + row.id) }}
                            >
                                <FontAwesomeIcon icon={faListAlt} />
                            </Button>,
                            className: "text-center"
                        }
                    }}
                    reload={(setup) => {
                        this.setState({ loading: true })
                        return new Promise((resolve, reject) => {
                            ImageDiscoveryService.jobs({
                                pageSize: setup.pageSize,
                                page: setup.page,
                                search: setup.search,
                                sort: setup.sort,
                            }).then((data) => {
                                this.setState({ loading: false })
                                if (data === false) {
                                    Toast.error(
                                        "Something went wrong while retrieving the list of jobs"
                                    );
                                    reject();
                                    return;
                                }
                                resolve(data);
                            }).catch(reject);
                        })
                    }}
                />
            </Container>
        );
    }
}

export default withRouter(JobsList);
