import React from "react";
import PropTypes from "prop-types";

import { Button, Container, Modal, Row } from "react-bootstrap";
// import Toast from "../services/Toast";
import ProcessingButton from "./ProcessingButton";
import WebsiteSearch from "./WebsiteSearch";

/**
 * Shows a Import URLs component
 * @component
 */
class PickupWebsite extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            importing: false,
            loading: false,
            website: null
        }
        this.onEntering = this.onEntering.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onEntering() {
        this.setState({
            importing: false,
            loading: false,
            website: null
        });
    }

    onChange(websites) {
        this.setState({ website: websites.length === 0 ? null : websites[0] })
    }

    render() {
        const cancel = () => {
            if (this.state.importing) {
                return;
            }
            this.props.onClose();
        }

        const save = () => {
            if (this.state.importing) {
                return;
            }
            this.setState({ importing: true }, () => {
                this.props.callbackPromise(this.state.website).then((result) => {
                    if (result) {
                        this.setState({ importing: false });
                        this.props.onClose(result);
                    }
                }).catch(() => {
                    this.setState({ importing: false });
                });
            });
        }

        return (
            <Modal
                onEntering={this.onEntering}
                size="lg"
                show={this.props.show}
                onHide={cancel}
                backdrop={!this.state.importing}
                keyboard={!this.state.importing}
                centered
            >
                <Modal.Header closeButton onHide={cancel}>
                    <Modal.Title>
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Container>
                        <Row>
                            <WebsiteSearch onChange={this.onChange} skip={this.props.skip} />
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer style={{
                    justifyContent: "center"
                }}>
                    <Button disabled={this.state.importing} size="sm" variant="secondary" onClick={cancel}>Cancel</Button>
                    <ProcessingButton
                        size="sm"
                        processing={this.state.importing}
                        processingLabel={this.props.processingLabel}
                        label={this.props.processLabel}
                        onClick={save}
                        disabled={this.state.user === null}
                    />
                </Modal.Footer>
            </Modal >
        );
    }
}

PickupWebsite.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    onUpdated: PropTypes.func,
    issues: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    processLabel: PropTypes.string.isRequired,
    processingLabel: PropTypes.string.isRequired,
    callbackPromise: PropTypes.func,
    skip: PropTypes.arrayOf(PropTypes.number),
};

PickupWebsite.defaultProps = {
    show: false,
    onClose: () => { console.log("onClose") },
    issues: [],
    title: 'Connect existing website',
    processLabel: 'Connect',
    processingLabel: 'Connecting ...',
    skip: [],
    callbackPromise: (users) => { return new Promise((resolve) => { console.log(users); setTimeout(() => { resolve(true) }, 2000); }); }
};

export default PickupWebsite;
