import React from "react";
import Loading from "../Loading";

import AuthService from "../../services/AuthService";
import { Redirect } from "react-router-dom";
import Emitter from "../../services/Emitter";

class OAuth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            authenticated: false,
        };
    }

    componentDidMount() {
        let location = window.location,
            code = location.search.match(/code=([^&]+)/);

        if (code == null) {
            AuthService.ping().then((result) => {
                if (typeof result == "boolean" && result) {
                    this.setState({
                        authenticated: true,
                        loading: false,
                    }, () => Emitter.emit("LOGGED_IN"));
                } else if (
                    typeof result == "string" &&
                    result.match(/google/)
                ) {
                    window.location.href = result;
                } else {
                    this.setState({
                        authenticated: false,
                        loading: false,
                    });
                }
            });
        } else {
            AuthService.sign(decodeURIComponent(code[1])).then((result) => {
                this.setState({
                    authenticated: result,
                    loading: false,
                }, () => result && Emitter.emit("LOGGED_IN"));
            });
        }
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }
        let href = this.state.authenticated
            ? AuthService.getDesiredLocation()
            : "/forbidden";
        return <Redirect to={href} />;
    }
}

export default OAuth;
