import React, { createRef } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Link, withRouter } from "react-router-dom";
import { Breadcrumb, Button, Container } from "react-bootstrap";
import Emitter from "../../services/Emitter";
import MyTable from "../../components/MyTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { faCheck, faPen, faPlus, faTimes, faWrench } from "@fortawesome/free-solid-svg-icons";
import PartnerService from "../../services/PartnerService";
import Toast from "../../services/Toast";
import IconButton from "../../components/IconButton";
import MySpinner from "../../components/MySpinner";

class List extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
        this.tableRef = createRef();
        this.reload = this.reload.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.toogleStatus = this.toogleStatus.bind(this);
    }

    componentDidMount() {
        Emitter.on("RELOAD_PARTNER_LIST", () => {
            this.setState({ loading: true }, this.reload);
        });
        this.reload();
    }

    componentWillUnmount() {
        Emitter.off("RELOAD_PARTNER_LIST");
    }

    reload() {
        PartnerService.all().then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of partners");
                return;
            }
            this.tableRef.current?.populate(data);
        });
    }

    handleEdit = (evt) => {
        const row = this.tableRef.current.getRowFromEvent(evt);
        this.props.history.push(`/partner/edit/${row.row.id}`);
    }

    toogleStatus = (evt) => {
        const obj = this.tableRef.current.getRowFromEvent(evt);
        const row = obj.row;
        const enabled = !row.enabled;
        row.updating = true;
        this.tableRef.current.updateRow(row);

        PartnerService.update(row.id, { enabled: enabled ? 1 : 0 }).then((result) => {
            delete row.updating;
            if (result === false) {
                Toast.error("Something went wrong while updating the partner status");
                this.tableRef.current.updateRow(row);
                return;
            }

            row.enabled = enabled;
            this.tableRef.current.updateRow(row);
            Toast.ok("Partner status updated successfully");
        });
    }

    render() {
        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Integration Partners</Breadcrumb.Item>
                    <Breadcrumb.Item active>List</Breadcrumb.Item>
                </Breadcrumb>
                <MyTable
                    name="partner-list"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="name"
                    sortOrder="asc"
                    headers={
                        [
                            {
                                field: "id",
                                label: "ID",
                                sortable: true,
                                searchable: true,
                                width: "60px",
                            },
                            {
                                field: "name",
                                label: "Name",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "email",
                                label: "Report Email",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "enabled",
                                label: "Status",
                                width: "100px",
                                options: [{ label: 'Enabled', value: '1' }, { label: 'Disabled', value: '0' }],
                                allowEmpty: true,
                                sortable: true,
                            },
                            {
                                field: "modules",
                                label: "Modules",
                                width: "110px",
                                allowEmpty: true,
                                sortable: true,
                            },
                            {
                                field: "brands",
                                label: "Brands",
                                allowEmpty: true,
                                sortable: true,
                            },
                            {
                                field: "created_at",
                                label: "Created At",
                                width: "150px",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "updated_at",
                                label: "Updated At",
                                width: "150px",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: 'control',
                                width: "50px",
                                className: "text-center",
                                content: <IconButton
                                    size="sm"
                                    className="tdButton"
                                    variant="success"
                                    icon={faPlus}
                                    to="/partner/new"
                                />
                            }
                        ]
                    }
                    renderColumns={{
                        id: (row) => <Link className="link" to={`/partner/edit/${row.id}`}>{row.id}</Link>,
                        name: (row) => <Link className="link" to={`/partner/edit/${row.id}`}>{row.name}</Link>,
                        modules: (row) => row.modules.map((module) => module.label).join(", "),
                        brands: (row) => {
                            const brands = row?.brands ?? [];
                            return brands
                                .map((brand) => <Link
                                    key={`partner-${row.id}-brand-${brand.id}`}
                                    target="_blank"
                                    className="link"
                                    to={`/brand/${brand.id}`}
                                >
                                    {`${brand.name} (${brand.module.label})`}
                                </Link>)
                                .reduce((prev, curr) => (prev ? [prev, ', ', curr] : curr), null);
                        },
                        enabled: {
                            format: (row) => row.enabled
                                ? (
                                    <FontAwesomeIcon
                                        style={{
                                            color: "green",
                                        }}
                                        icon={faCheck}
                                    />
                                )
                                : (
                                    <FontAwesomeIcon
                                        style={{ color: "red" }}
                                        icon={faTimes}
                                    />
                                ),
                            cvs: (row) => row.enabled ? "Enabled" : "Disabled",
                            className: "text-center"
                        },
                        control: {
                            format: (row) => {
                                if (typeof row.updating !== "undefined") {
                                    return <MySpinner />
                                }

                                const action = row.enabled ?
                                    <React.Fragment>
                                        <FontAwesomeIcon
                                            style={{ color: "red", minWidth: "20px", transform: "translateX(-10px)" }}
                                            icon={faTimes}
                                        /> Disable
                                    </React.Fragment>
                                    : <React.Fragment>
                                        <FontAwesomeIcon
                                            style={{ color: "green", minWidth: "20px", transform: "translateX(-10px)" }}
                                            icon={faCheck}
                                        /> Enable
                                    </React.Fragment>;

                                return <Menu
                                    direction="left"
                                    menuButton={<Button
                                        size="sm"
                                        variant="outline-secondary"
                                        className="tdButton">
                                        <FontAwesomeIcon icon={faWrench} />
                                    </Button>}>
                                    <MenuItem onClick={this.handleEdit}>
                                        <FontAwesomeIcon icon={faPen} style={{ minWidth: "20px", transform: "translateX(-10px)" }} /> Edit
                                    </MenuItem>
                                    <MenuItem onClick={this.toogleStatus}>
                                        {action}
                                    </MenuItem>
                                </Menu >
                            },
                            className: "text-center"
                        }
                    }}
                />
            </Container>
        )
    }
}

export default withRouter(List);
