import React from "react";
import {
    Container,
    Row,
    Col,
    OverlayTrigger,
    Popover,
    PopoverBody,
    Spinner,
    Table
} from "react-bootstrap";
import Websites from "../services/WebsiteService";
import Generic from "../services/GenericService";
import Settings from "../services/SettingsService";
import Toast from "../services/Toast";
import Utils from "../services/Utils";
import MultiSelect from "react-multi-select-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import confetti from "canvas-confetti";
import { faDownload, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import "./Welcome.css";

import {
    AreaChart,
    Area,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell,
} from "recharts";
import { Link } from "react-router-dom";
import ProcessingButton from "../components/ProcessingButton";
import CSV from "../services/CSV";

const COLORS = Utils.GetColors();
/**
 * Renders initial page for every authenticated user
 * @component
 * @category Scenes
 */
class Welcome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            daily: [],
            brands: [],
            brand: [{ label: "All", value: 0 }],
            brandSelectionEnabled: false,
            stats: null,
            statsLoading: true,
            volumeChartsLoading: true,
            trackedBrandsLoading: true,
            trackedBrands: [],
            reviewsPerDayLoading: true,
            reviewsPerDay: [],
            summaryBrands: [],
            summaryBrandsLoading: true,
            summaryWebsites: [],
            summaryWebsitesLoading: true,
            downloadingTop1kWebsites: false
        };
        this.volumeCharts = React.createRef();
        this.selectBrand = this.selectBrand.bind(this);
        this.loadChart = this.loadChart.bind(this);
        this.Stats = this.Stats.bind(this);
        this.VolumeCharts = this.VolumeCharts.bind(this);
        this.Brands = this.Brands.bind(this);
        this.Websites = this.Websites.bind(this);
        this.TrackedBrands = this.TrackedBrands.bind(this);
        this.ReviewsPerDay = this.ReviewsPerDay.bind(this);
        this.downloadingTop1kWebsites = this.downloadingTop1kWebsites.bind(this);
    }

    componentDidMount() {
        this.loadChart();

        Websites.brands(0).then((data) => {
            if (data === false) {
                return;
            }
            this.setState({
                brands: data,
                brandSelectionEnabled: data.length > 0,
            });
        });

        Generic.summaryDB().then((result) => {
            if (result === false) {
                Toast.error(
                    'Something went wrong while loading Growth over the last Month'
                );
                this.setState({
                    statsLoading: false,
                });
                return;
            }
            this.setState({
                stats: result,
                statsLoading: false,
            });
        });
        Generic.summaryBrands().then((result) => {
            if (result === false) {
                Toast.error(
                    'Something went wrong while loading "Top 25 Crawled Brands"'
                );
                this.setState({
                    summaryBrandsLoading: false,
                });
                return;
            }
            this.setState({
                summaryBrands: result,
                summaryBrandsLoading: false,
            });
        });
        Generic.summaryWebsites().then((result) => {
            if (result === false) {
                Toast.error(
                    'Something went wrong while loading "Top 25 Crawled Websites"'
                );
                this.setState({
                    summaryWebsitesLoading: false,
                });
                return;
            }
            this.setState({
                summaryWebsites: result,
                summaryWebsitesLoading: false,
            });
        });

        Generic.trackedBrands().then((result) => {

            if (result === false) {
                Toast.error(
                    'Something went wrong while loading data from "Tracked Brands"'
                );
                this.setState({ trackedBrandsLoading: false });
                return;
            }
            let data = [],
                keys = Object.keys(result);
            keys.forEach((key) => {
                data.push({
                    name: key,
                    total: result[key]
                })
            });
            this.setState({
                trackedBrandsLoading: false,
                trackedBrands: data
            });
        });

        Generic.reviewsPerDay().then((result) => {

            if (result === false) {
                Toast.error(
                    'Something went wrong while loading data from "Reviews Per Day"'
                );
                this.setState({ reviewsPerDayLoading: false });
                return;
            }
            let data = [],
                keys = Object.keys(result);
            keys.forEach((key) => {
                data.push({
                    name: key,
                    total: result[key]
                })
            });
            this.setState({
                reviewsPerDayLoading: false,
                reviewsPerDay: data
            });
        });
    }

    // let's celebrate crawling records
    fireworks() {
        var that = this;
        this.interval = setInterval(() => {
            var timeLeft = that.animationEnd - Date.now();

            if (timeLeft <= 0) {
                return clearInterval(that.interval);
            }

            var particleCount = 50 * (timeLeft / 15000);
            // since particles fall down, start a bit higher than random
            confetti(Object.assign({}, { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 }, { particleCount, origin: { x: Utils.randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }));
            confetti(Object.assign({}, { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 }, { particleCount, origin: { x: Utils.randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }));
        }, 250);
    }

    downloadingTop1kWebsites() {
        this.setState({ downloadingTop1kWebsites: true }, () => {
            Generic.summaryWebsites(1000).then((result) => {
                this.setState({
                    downloadingTop1kWebsites: false,
                });
                if (result === false) {
                    Toast.error('Something went wrong while loading "Top 1k Crawled Websites"');
                    return;
                }
                let csv = new CSV();
                csv.addColumn("Website ID", "Website", "Crawled Products", "%");
                result.forEach((item) => {
                    csv.addRow(item.id, item.name, item.value, item.p.toFixed(2));
                });
                csv.Download("top1kCrawledWebsites-" + Utils.DateYesterday() + ".csv");
            });
        });
    }

    loadChart(brand = 0) {
        Websites.dailyVolume(0, brand).then((data) => {
            if (data === false) {
                Toast.error(
                    'Something went wrong while loading the volume charts'
                );
                return;
            }
            let days = Object.keys(data.total),
                total = [],
                unique = [];
            days.forEach((day) => {
                total.push({
                    name: day,
                    total: data.total[day],
                });
                unique.push({
                    name: day,
                    total: data.unique[day],
                });
            });
            this.setState({
                dailyTotal: total,
                dailyUnique: unique,
                volumeChartsLoading: false,
            });

            let record = data.record ?? false,
                shouldThrowFireworks = brand === 0
                    && record
                    && Settings.Get('lastRecord') !== Utils.DateYesterday();

            if (shouldThrowFireworks) {
                Settings.Set('lastRecord', Utils.DateYesterday());
                Toast.info("Hooray! It looks like we had a crawling record yesterday!", 10000);
                this.animationEnd = Date.now() + 15000;
                this.fireworks();
            }
        });
    }

    selectBrand(list) {
        if (list.length > 1) {
            list.splice(0, 1);
        }
        this.setState({
            brand: list,
        });
        if (list.length) {
            this.loadChart(list[0].value);
        } else {
            this.loadChart();
        }
        window.scrollTo(0, this.volumeCharts.current.offsetTop)
    }

    getColor(list, index) {
        index = list * 13 + index;
        return {
            backgroundColor: COLORS[index % COLORS.length],
        };
    }

    CustomTooltip({ active, payload, label }) {
        if (active && payload && payload.length) {
            return (
                <div className="entryTooltip">
                    <div className="title">{payload[0].name}</div>
                    <div className="percentage">
                        {payload[0].value.toFixed(2)}%
                    </div>
                </div>
            );
        }
        return null;
    }

    Loading() {
        return (
            <Row>
                <Col className="d-flex align-items-center justify-content-center">
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                </Col>
            </Row>
        );

    }

    Stats() {
        if (this.state.statsLoading || this.state.stats === null) {
            return null;
        }

        let TDWithHint = (label, hint) => {
            return <OverlayTrigger
                placement="top"
                trigger={["hover", "focus"]}
                overlay={
                    <Popover>
                        <PopoverBody>{hint}</PopoverBody>
                    </Popover>
                }
            >
                <td>{label}</td>
            </OverlayTrigger>
        },
            prettyNumbers = (n) => {
                if (typeof n === "undefined") {
                    return null;
                }
                return n.toLocaleString();
            },
            crawled_websites = this.state.stats.websites.crawled.v1 + this.state.stats.websites.crawled.ng,
            v1_websites = crawled_websites ? this.state.stats.websites.crawled.v1 * 100 / crawled_websites : 0,
            ng_websites = crawled_websites ? this.state.stats.websites.crawled.ng * 100 / crawled_websites : 0,
            crawled_offers = this.state.stats.offers.v1 + this.state.stats.offers.ng,
            v1_offers = crawled_offers ? this.state.stats.offers.v1 * 100 / crawled_offers : 0,
            ng_offers = crawled_offers ? this.state.stats.offers.ng * 100 / crawled_offers : 0,
            tracked_clients = this.state.stats.products.total_tracked ? this.state.stats.products.total_tracked_clients * 100 / this.state.stats.products.total_tracked : 0,
            tracked_pdlt = this.state.stats.products.total_tracked ? this.state.stats.products.total_tracked_pdlt * 100 / this.state.stats.products.total_tracked : 0;

        return (
            <React.Fragment>
                <Row>
                    <Col
                        className="d-flex align-items-center justify-content-center"
                        style={{ marginBottom: "20px" }}
                    >
                        General Statistics
                    </Col>
                </Row>
                <Row>
                    <Table>
                        <Table striped bordered hover style={{
                            textAlign: "center",
                            fontSize: "small"
                        }}>
                            <thead>
                                <tr>
                                    <th colSpan={3}>Websites</th>
                                    <th colSpan={4}>Products</th>
                                    <th colSpan={2}>Paying Clients</th>
                                    <th colSpan={4}>Miscellaneous</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {TDWithHint('Active', 'Total number of registered websites')}
                                    {TDWithHint('Crawled V1', 'Total number of websites being actively crawled by V1')}
                                    {TDWithHint('Crawled NG', 'Total number of websites being actively crawled by NG')}
                                    {TDWithHint('Tracked', 'Total number of products registered and enabled in system')}
                                    {TDWithHint('Tracked (Clients)', 'Total number of products registered and enabled in system related to active & paying accounts')}
                                    {TDWithHint('Tracked (Discover)', 'Total number of products registered and enabled in system related to discover (PDLT) accounts')}
                                    {TDWithHint('Crawled', 'Total number of unique products being actively crawled')}
                                    {TDWithHint('Organizations', 'Total number of paying clients')}
                                    {TDWithHint('Dashboards', 'Total number of dashboards registered to paying clients')}
                                    {TDWithHint('Listings', 'Total number of unique product listings (URL)')}
                                    {TDWithHint('Offers V1', 'Total number of unique offers being regularly scrapped by V1')}
                                    {TDWithHint('Offers NG', 'Total number of unique offers being regularly scrapped by NG')}
                                    {TDWithHint('Merchants', 'Total number of merchants being actively tracked')}
                                </tr>
                                <tr>
                                    <td>{prettyNumbers(this.state.stats.websites.active)}</td>
                                    <OverlayTrigger
                                        placement="bottom"
                                        trigger={["hover", "focus"]}
                                        overlay={
                                            <Popover>
                                                <PopoverBody>{v1_websites.toFixed(2)}%</PopoverBody>
                                            </Popover>
                                        }
                                    >
                                        <td>{prettyNumbers(this.state.stats.websites.crawled.v1)}</td>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="bottom"
                                        trigger={["hover", "focus"]}
                                        overlay={
                                            <Popover>
                                                <PopoverBody>{ng_websites.toFixed(2)}%</PopoverBody>
                                            </Popover>
                                        }
                                    >
                                        <td>{prettyNumbers(this.state.stats.websites.crawled.ng)}</td>
                                    </OverlayTrigger>
                                    <td>{prettyNumbers(this.state.stats.products.total_tracked)}</td>
                                    <OverlayTrigger
                                        placement="bottom"
                                        trigger={["hover", "focus"]}
                                        overlay={
                                            <Popover>
                                                <PopoverBody>{tracked_clients.toFixed(2)}%</PopoverBody>
                                            </Popover>
                                        }
                                    >
                                        <td>{prettyNumbers(this.state.stats.products.total_tracked_clients)}</td>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="bottom"
                                        trigger={["hover", "focus"]}
                                        overlay={
                                            <Popover>
                                                <PopoverBody>{tracked_pdlt.toFixed(2)}%</PopoverBody>
                                            </Popover>
                                        }
                                    >
                                        <td>{prettyNumbers(this.state.stats.products.total_tracked_pdlt)}</td>
                                    </OverlayTrigger>
                                    <td>{prettyNumbers(this.state.stats.products.crawled)}</td>
                                    <td>{prettyNumbers(this.state.stats.clients)}</td>
                                    <td>{prettyNumbers(this.state.stats.dashboards)}</td>
                                    <td>{prettyNumbers(this.state.stats.listings)}</td>
                                    <OverlayTrigger
                                        placement="bottom"
                                        trigger={["hover", "focus"]}
                                        overlay={
                                            <Popover>
                                                <PopoverBody>{v1_offers.toFixed(2)}%</PopoverBody>
                                            </Popover>
                                        }
                                    >
                                        <td>{prettyNumbers(this.state.stats.offers.v1)}</td>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="bottom"
                                        trigger={["hover", "focus"]}
                                        overlay={
                                            <Popover>
                                                <PopoverBody>{ng_offers.toFixed(2)}%</PopoverBody>
                                            </Popover>
                                        }
                                    >
                                        <td>{prettyNumbers(this.state.stats.offers.ng)}</td>
                                    </OverlayTrigger>
                                    <td>{prettyNumbers(this.state.stats.merchants)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Table>
                </Row>
            </React.Fragment>
        );
    }

    Brands() {
        const ListB = [].concat(this.state.summaryBrands),
            ListA = ListB.splice(0, 13);
        if (this.state.summaryBrandsLoading) {
            return null;
        }

        if (ListA.length === 0) {
            return null;
        }

        return (
            <React.Fragment>
                <Row>
                    <Col
                        className="d-flex align-items-center justify-content-center"
                        style={{
                            marginBottom: "20px",
                        }}
                    >
                        Top 25 Crawled Brands
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <ResponsiveContainer
                            ResponsiveContainer
                            width={"99%"}
                            height={260}
                        >
                            <PieChart>
                                <Pie
                                    dataKey="value"
                                    data={this.state.summaryBrands}
                                    outerRadius={120}
                                    fill="#82ca9d"
                                >
                                    {this.state.summaryBrands.map(
                                        (entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={
                                                    COLORS[
                                                    index % COLORS.length
                                                    ]
                                                }
                                            />
                                        )
                                    )}
                                </Pie>
                                <Tooltip content={<this.CustomTooltip />} />
                            </PieChart>
                        </ResponsiveContainer>
                    </Col>
                    <Col sm={3}>
                        {ListA.map((entry, index) => (
                            <div className="listEntry" key={`brand.${entry.id}`}>
                                <span
                                    className="square"
                                    style={this.getColor(0, index)}
                                ></span>
                                <span className="pointer" onClick={() => {
                                    this.selectBrand([{
                                        value: entry.id,
                                        label: entry.name
                                    }]);
                                }
                                }>{entry.name} ({entry.value.toFixed(2)}%)</span>
                            </div>
                        ))}
                    </Col>
                    <Col sm={3}>
                        {ListB.map((entry, index) => (
                            <div className="listEntry" key={`brand.${entry.id}`}>
                                <span
                                    className="square"
                                    style={this.getColor(1, index)}
                                ></span>
                                <span className="pointer" onClick={() => {
                                    this.selectBrand([{
                                        value: entry.id,
                                        label: entry.name
                                    }]);
                                }
                                }>{entry.name} ({entry.value.toFixed(2)}%)</span>
                            </div>
                        ))}
                    </Col>
                </Row>
                <hr />
            </React.Fragment >
        );
    }

    Websites() {
        const ListB = [].concat(this.state.summaryWebsites),
            ListA = ListB.splice(0, 13);
        if (this.state.summaryWebsitesLoading) {
            return null;
        }

        if (ListA.length === 0) {
            return null;
        }

        return (
            <React.Fragment>
                <span style={{
                    float: "right"
                }}
                >
                    <ProcessingButton
                        size="sm"
                        processing={this.state.downloadingTop1kWebsites}
                        processingLabel="Downloading ..."
                        variant="primary"
                        label="Top 1k Crawled Websites"
                        icon={faDownload}
                        onClick={this.downloadingTop1kWebsites}
                    />
                </span>
                <Row>
                    <Col
                        className="d-flex align-items-center justify-content-center"
                        style={{
                            marginBottom: "20px",
                        }}
                    >
                        Top 25 Crawled Websites
                    </Col>
                </Row>
                <Row>
                    <Col sm={2}></Col>
                    <Col sm={3}>
                        {ListA.map((entry, index) => (
                            <div className="listEntry" key={`website.${entry.id}`}>
                                <span
                                    className="square"
                                    style={this.getColor(0, index)}
                                ></span>
                                <Link className="pointer" to={`/website/${entry.id}`}>{entry.name} ({entry.value.toFixed(2)}%)</Link>
                            </div>
                        ))}
                    </Col>
                    <Col sm={3}>
                        {ListB.map((entry, index) => (
                            <div className="listEntry" key={`website.${entry.id}`}>
                                <span
                                    className="square"
                                    style={this.getColor(1, index)}
                                ></span>
                                <Link className="pointer" to={`/website/${entry.id}`}>{entry.name} ({entry.value.toFixed(2)}%)</Link>
                            </div>
                        ))}
                    </Col>
                    <Col sm={4}>
                        <ResponsiveContainer
                            ResponsiveContainer
                            width={"99%"}
                            height={260}
                        >
                            <PieChart>
                                <Pie
                                    dataKey="value"
                                    data={this.state.summaryWebsites}
                                    outerRadius={120}
                                    fill="#82ca9d"
                                >
                                    {this.state.summaryWebsites.map(
                                        (entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={
                                                    COLORS[
                                                    index % COLORS.length
                                                    ]
                                                }
                                            />
                                        )
                                    )}
                                </Pie>
                                <Tooltip content={<this.CustomTooltip />} />
                            </PieChart>
                        </ResponsiveContainer>
                    </Col>
                </Row>
                <hr />
            </React.Fragment>
        );
    }

    TrackedBrands() {
        if (this.state.trackedBrandsLoading) {
            return this.Loading();
        }
        if (this.state.trackedBrands.length === 0) {
            return null;
        }

        return (
            <React.Fragment>
                <Row>
                    <Col
                        className="d-flex align-items-center justify-content-center"
                        style={{
                            marginBottom: "20px",
                        }}
                    >
                        Tracked Brands over time
                    </Col>

                </Row>
                <Row>
                    <ResponsiveContainer width={"99%"} height={300}>
                        <AreaChart
                            data={this.state.trackedBrands}
                            margin={{
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 30,
                            }}
                        >
                            <Area
                                type="monotone"
                                dataKey="total"
                                stroke="#8884d8"
                            />
                            <CartesianGrid
                                stroke="#ccc"
                                strokeDasharray="5 5"
                            />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                        </AreaChart>
                    </ResponsiveContainer>
                </Row>
                <hr />
            </React.Fragment>
        );
    }


    ReviewsPerDay() {
        if (this.state.reviewsPerDayLoading) {
            return this.Loading();
        }
        if (this.state.reviewsPerDay.length === 0) {
            return null;
        }

        return (
            <React.Fragment>
                <Row>
                    <Col
                        className="d-flex align-items-center justify-content-center"
                        style={{
                            marginBottom: "20px",
                        }}
                    >
                        Reviews Per Day
                    </Col>

                </Row>
                <Row>
                    <ResponsiveContainer width={"99%"} height={300}>
                        <AreaChart
                            data={this.state.reviewsPerDay}
                            margin={{
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 30,
                            }}
                        >
                            <Area
                                type="monotone"
                                dataKey="total"
                                stroke="#8884d8"
                            />
                            <CartesianGrid
                                stroke="#ccc"
                                strokeDasharray="5 5"
                            />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                        </AreaChart>
                    </ResponsiveContainer>
                </Row>
            </React.Fragment>
        );
    }


    VolumeCharts() {
        if (this.state.volumeChartsLoading) {
            return this.Loading();
        }

        return (
            <React.Fragment>
                <Row ref={this.volumeCharts}>
                    <Col
                        sm={4}
                        className="d-flex align-items-center justify-content-end"
                    >
                        Daily Volume{" "}
                    </Col>
                    <Col sm={6}>
                        <MultiSelect
                            id="brands"
                            disabled={!this.state.brandSelectionEnabled}
                            options={this.state.brands}
                            value={this.state.brand}
                            hasSelectAll={false}
                            onChange={this.selectBrand}
                            labelledBy="Select brand"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col
                        style={{
                            marginTop: "20px",
                        }}
                        className="d-flex align-items-center justify-content-center"
                        sm={6}
                    >
                        <OverlayTrigger
                            placement="right"
                            trigger={["hover", "focus"]}
                            overlay={
                                <Popover>
                                    <PopoverBody>
                                        Total unique products found per website
                                    </PopoverBody>
                                </Popover>
                            }
                        >
                            <span>
                                Unique hits{" "}
                                <FontAwesomeIcon icon={faQuestionCircle} />
                            </span>
                        </OverlayTrigger>
                    </Col>
                    <Col
                        sm={6}
                        className="d-flex align-items-center justify-content-center"
                        style={{
                            marginTop: "20px",
                        }}
                    >
                        <OverlayTrigger
                            placement="right"
                            trigger={["hover", "focus"]}
                            overlay={
                                <Popover>
                                    <PopoverBody>
                                        Total number of offers found per website
                                    </PopoverBody>
                                </Popover>
                            }
                        >
                            <span>
                                {" "}
                                Total hits{" "}
                                <FontAwesomeIcon icon={faQuestionCircle} />
                            </span>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row>
                    <Col
                        sm={6}
                        className="d-flex align-items-center justify-content-center text-center"
                    >
                        <ResponsiveContainer width={"99%"} height={300}>
                            <AreaChart
                                data={this.state.dailyUnique}
                                margin={{
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 30,
                                }}
                            >
                                <Area
                                    type="monotone"
                                    dataKey="total"
                                    stroke="#8884d8"
                                />
                                <CartesianGrid
                                    stroke="#ccc"
                                    strokeDasharray="5 5"
                                />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                            </AreaChart>
                        </ResponsiveContainer>
                    </Col>
                    <Col
                        sm={6}
                        className="d-flex align-items-center justify-content-center text-center"
                    >
                        <ResponsiveContainer width={"99%"} height={300}>
                            <AreaChart
                                data={this.state.dailyTotal}
                                margin={{
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 30,
                                }}
                            >
                                <Area
                                    type="monotone"
                                    dataKey="total"
                                    stroke="#8884d8"
                                />
                                <CartesianGrid
                                    stroke="#ccc"
                                    strokeDasharray="5 5"
                                />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                            </AreaChart>
                        </ResponsiveContainer>
                    </Col>
                </Row>
                <hr />
            </React.Fragment>
        );
    }

    render() {
        return (
            <Container className="pt-4">
                <this.Stats />
                <this.VolumeCharts />
                <this.Brands />
                <this.Websites />
                <this.TrackedBrands />
                <this.ReviewsPerDay />
            </Container>
        );
    }
}

export default Welcome;
