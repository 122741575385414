import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Loader from "react-loader-spinner";

/**
 * Renders a loading page
 * @component
 * @category Scenes
 */
class Loading extends React.Component {
    render() {
        return (
            <Container
                className="pt-4"
                style={{
                    height: "200px",
                }}
            >
                <Row className="d-flex align-items-center justify-content-center text-center">
                    <Col data-testid="loading">
                        <Loader
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: `translate(-50px, -50px)`,
                            }}
                            type="RevolvingDot"
                            height={100}
                            width={100}
                            color="#81B72A"
                        />
                    </Col>
                </Row>
            </Container>
        );
    }
}
export default Loading;
