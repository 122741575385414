import React from "react";

import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Button,
    Form,
    OverlayTrigger,
    Popover,
    PopoverBody,
} from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Toast from "../../services/Toast";
import Emitter from "../../services/Emitter";
import ManufacturerSearch from "../../components/ManufacturerSearch";
import ProcessingButton from "../../components/ProcessingButton";
import Countries from "../../components/Countries";
import MySpinner from "../../components/MySpinner";
import ManufacturerService from "../../services/ManufacturerService";
import PDDService from "../../services/PDDService";
import Utils from "../../services/Utils";

/**
 * New Job component
 * @component
 * @category Scenes
 * @subcategory PDD Jobs
 */
class NewJob extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState();
        this.handleChangeManufacturer = this.handleChangeManufacturer.bind(this);
        this.handleChangeCountry = this.handleChangeCountry.bind(this);
        this.loadCountries = this.loadCountries.bind(this);
        this.appendToCountries = this.appendToCountries.bind(this);
        this.preSelectCountry = this.preSelectCountry.bind(this);
        this.checkSummaryForCountries = this.checkSummaryForCountries.bind(this);
        this.typeahead = React.createRef();
        this.submit = this.submit.bind(this);
        this.buffer = {
            manufacturers: {},
            urls: {
                all: [],
                new: [],
                nourls: []
            },
        };
    }

    initialState() {
        return {
            updating: false,
            manufacturers: [],
            mode: "complete",
            recentProducts: -1,
            allProducts: -1,
            noUrlsProducts: -1,
            canSubmit: false,
            countries: [],
            sellingCountries: [],
            loadingCountries: false,
            loadingSummary: false
        }
    }

    handleChangeManufacturer(values) {

        if (values.length) {
            this.setState({
                manufacturers: values,
                loadingCountries: true,
                canSubmit: false,

            });
            this.manufacturers = values.map((value) => value.id);
            this.loadCountries();
        } else {
            this.setState(this.initialState());
        }
    }

    appendToCountries(countries) {
        const sellingCountries = this.state.sellingCountries,
            ids = sellingCountries.map((country) => country.value);
        countries.forEach((country_id) => {
            if (ids.indexOf(country_id) === -1) {
                sellingCountries.push({
                    label: Countries.GetByID(country_id).label,
                    value: country_id
                });
            }
        });
        this.setState({
            sellingCountries
        });
    }

    loadCountries = () => {
        if (this.manufacturers.length === 0) { return this.preSelectCountry(); }

        const manufacturerId = this.manufacturers.shift();
        if (this.buffer.manufacturers[manufacturerId]) {
            this.appendToCountries(this.buffer.manufacturers[manufacturerId]);
            this.loadCountries();
            return;
        }

        ManufacturerService.getTrackedCountries(manufacturerId).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while loading this manufacturer's settings");
                return;
            }
            this.buffer.manufacturers[manufacturerId] = result;
            this.appendToCountries(result);
            this.loadCountries();
        })
    }

    preSelectCountry = () => {
        const usa = this.state.sellingCountries.find((country) => country.value === Countries.UNITED_STATES_ID);
        const state = {
            loadingCountries: false,
            countries: usa ? [usa] : [],
        };
        this.setState(state, this.checkSummaryForCountries);
    }

    handleChangeCountry(selected) {
        this.setState({ countries: selected }, this.checkSummaryForCountries);
    }

    defineMode(selected, numbers) {
        const mode = selected.length > 0 ? selected : "complete";
        if (
            (mode === "recent" && !numbers.recent) ||
            (mode === 'nourls' && !numbers.nourls)
        ) {
            if (numbers.all > 0) {
                return "complete";
            }
            return "";
        }
        if (mode === "complete" && !numbers.all) {
            return "";
        }
        return mode;
    }

    checkSummaryForCountries() {

        this.setState({
            allProducts: -1,
            recentProducts: -1,
            noUrlsProducts: -1,
            canSubmit: false,
            loadingSummary: this.state.countries.length > 0
        });

        if (!this.state.countries.length) { return; }

        const country_ids = this.state.countries.map(country => country.value);
        const manufacturer_ids = this.state.manufacturers.map(manufacturer => manufacturer.id);

        PDDService.summary(manufacturer_ids, country_ids).then((rows) => {

            if (rows === false) {
                Toast.error("Failed retrieving manufacturers' summary");
                return
            }

            const numbers = {
                all: rows.length,
                recent: 0,
                nourls: 0,
            };
            rows.forEach((row) => {
                const recent = row[1];
                const hasUrl = row[2];
                if (recent) {
                    numbers.recent++;
                }
                if (!hasUrl) {
                    numbers.nourls++;
                }
            });
            this.buffer.urls = rows;

            this.setState({
                allProducts: numbers.all,
                recentProducts: numbers.recent,
                noUrlsProducts: numbers.nourls,
                canSubmit: numbers.all + numbers.recent + numbers.nourls > 0,
                mode: this.defineMode(this.state.mode, numbers),
                loadingSummary: false
            });
        });
    }

    submit() {
        const mode = this.state.mode;
        this.setState({
            updating: true,
        });
        const products = this.buffer.urls.map(function (row) {
            const [pid, recent, hasUrl] = row;
            if (
                mode === 'complete' ||
                (mode === 'recent' && recent) ||
                (mode === 'nourls' && !hasUrl)
            ) {
                return pid;
            }
            return 0;
        })
            .filter((content) => content !== 0);

        const payload = {
            manufacturer_ids: this.state.manufacturers.map((manufacturer) => manufacturer.id),
            country_ids: this.state.countries.map((country) => country.value),
            product_ids: products,
        };

        PDDService.request(payload).then((result) => {
            if (!result) {
                Toast.error(
                    "Something went wrong while request this new job."
                );
                this.setState({
                    updating: false,
                });
                return;
            }

            this.setState(this.initialState(),
                () => {
                    this.typeahead.current.clear();
                    setTimeout(() => {
                        Emitter.emit("RELOAD_PDD_JOBS");
                    }, 500);
                    this.props.history.push(`/pdd/jobs/#list`);
                }
            );
        });
    }

    render() {
        const labelAllProducts = Utils.Pluralize('All products (%d productZ)', this.state.allProducts);
        const labelNoUrls = Utils.Pluralize('Products with no URL (%d productZ)', this.state.noUrlsProducts);
        const labelRecentlyAdded = <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={
                <Popover>
                    <PopoverBody>Only products added in the last week</PopoverBody>
                </Popover>
            }>
            <span>{Utils.Pluralize('Recently added products only (%d productZ)', this.state.recentProducts)}</span>
        </OverlayTrigger>;

        return (
            <Container className="pt-3 dd">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>PDD</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="#list">
                            Jobs
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>New</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <Col
                        sm={2}
                        className="d-flex align-items-center justify-content-end text-center"
                    >
                        Manufacturers
                    </Col>
                    <Col sm={7}>
                        <ManufacturerSearch
                            ref={this.typeahead}
                            onChange={this.handleChangeManufacturer}
                            multiple={true}
                        />
                    </Col>
                    <Col sm={3}>
                        <Button
                            disabled={this.state.updating}
                            variant="secondary"
                            as={Link}
                            to="#list"
                        >
                            Cancel
                        </Button>
                        <ProcessingButton
                            processing={this.state.updating}
                            processingLabel="Saving ..."
                            label="Request"
                            onClick={this.submit}
                            disabled={!this.state.canSubmit}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col
                        sm={2}
                        className="d-flex align-items-center justify-content-end text-center"
                    >
                        Countries
                    </Col>
                    <Col sm={7}>
                        <Countries
                            disabled={true}
                            singleValue={false}
                            name="countries"
                            value={this.state.countries}
                            options={this.state.sellingCountries}
                            onChange={this.handleChangeCountry} />
                    </Col>
                </Row>
                <Row>
                    <Col
                        sm={2}
                        className="d-flex align-items-center justify-content-end text-center"
                    >
                        Mode
                    </Col>
                    <Col sm={6}>
                        <Form.Check
                            disabled={this.state.allProducts === 0 || this.state.countries.length === 0 || !this.state.canSubmit}
                            checked={this.state.mode === 'complete'}
                            name="mode"
                            type="radio"
                            onClick={() => { this.setState({ mode: 'complete' }) }}
                            label={labelAllProducts} />
                        <Form.Check
                            disabled={this.state.recentProducts === 0 || this.state.countries.length === 0 || !this.state.canSubmit}
                            checked={this.state.mode === 'recent'}
                            onClick={() => { this.setState({ mode: 'recent' }) }}
                            name="mode"
                            type="radio"
                            label={labelRecentlyAdded} />
                        <Form.Check
                            disabled={this.state.noUrlsProducts === 0 || this.state.countries.length === 0 || !this.state.canSubmit}
                            checked={this.state.mode === 'nourls'}
                            onClick={() => { this.setState({ mode: 'nourls' }) }}
                            name="mode"
                            type="radio"
                            label={labelNoUrls} />

                    </Col>
                    {(this.state.loadingSummary || this.state.loadingCountries) && (
                        <Col
                            sm={1}
                            className="d-flex align-items-center justify-content-center text-center"
                        >
                            <MySpinner />
                        </Col>
                    )}
                </Row>
            </Container>
        );
    }
}

export default withRouter(NewJob);
