import React from "react";
import PropTypes from "prop-types";
import { Button, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

/**
 * Button component with Icon
 * @component
 */
class IconButton extends React.Component {

    render() {
        const style = typeof this.props.style === "undefined" || this.props.style === null ? {} : this.props.style;
        const icon = typeof this.props.icon === "object" ?
            <FontAwesomeIcon
                icon={this.props.icon}
                style={this.props.label ? { marginRight: "7px" } : {}}
            />
            : null;
        if (typeof this.props.minWidth === "string") {
            style.minWidth = this.props.minWidth
        }
        const props = {
            className: this.props.className,
            variant: this.props.variant,
            size: this.props.size,
            disabled: this.props.disabled,
            onClick: this.props.onClick,
            style: style,
        }
        if (this.props.to !== undefined) {
            props.to = this.props.to;
            props.as = NavLink;
        }
        if (this.props.href !== undefined) {
            props.href = this.props.href;
        }

        const button = <Button {...props}>
            {icon}{this.props.label}
        </Button>;

        return this.props.hint
            ? <OverlayTrigger
                placement="bottom"
                trigger={["hover", "focus"]}
                overlay={
                    <Popover>
                        <PopoverBody>{this.props.hint}</PopoverBody>
                    </Popover>
                }
            >
                {button}
            </OverlayTrigger>
            : button;
    }
}

IconButton.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    variant: PropTypes.string.isRequired,
    label: PropTypes.string,
    size: PropTypes.string.isRequired,
    className: PropTypes.string,
    minWidth: PropTypes.string,
    icon: PropTypes.object,
    style: PropTypes.object,
    hint: PropTypes.string,
};

IconButton.defaultProps = {
    size: "md",
    label: null,
    variant: "primary",
    disabled: false,
    onClick: () => {
        console.log('Clicked')
    },
    style: null,
    className: null,
    hint: null,
};

export default IconButton;
