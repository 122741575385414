import React from "react";

import { Nav, Carousel, Container, Breadcrumb } from "react-bootstrap";
import CrawlResults from "./CrawlResults";
import Rejected from "./Rejected";
import Profile from "./Profile";
import Volume from "./Volume";
import Summary from "./Summary";
import ProductsMapped from "./ProductsMapped";
import Emitter from "../../services/Emitter";
import ProductDiscovery from "./ProductDiscovery";
import Merchants from "./Merchants";
import WebsiteService from "../../services/WebsiteService";
import MyCarousel from "../../components/MyCarousel";
import CrawlingEffort from "./CrawlingEffort";
import Costs from "./Costs";
import Auth from "../../services/AuthService";


class Index extends React.Component {
    constructor(props) {
        super(props);
        let websiteId = this.props.match.params.id * 1;
        this.state = {
            websiteId,
            website_name: '...'
        };
        WebsiteService.breadcrumb(websiteId).then((row) => {
            this.setState({ website_name: row.display_name });
        }).catch(() => {
            this.setState({ website_name: '?' });
        })
        this.firstLoad = true;
        this.showCosts = Auth.hasAccessToCosts();
    }

    showPage(tab) {
        let key, event;
        if (tab === "#profile") {
            key = 1;
            event = "WEBSITE_PROFILE";
        } else if (tab === "#volume") {
            key = 2;
            event = "WEBSITE_VOLUME";
        } else if (tab === "#merchants") {
            key = 3;
            event = "WEBSITE_MERCHANTS";
        } else if (tab === "#crawlresults") {
            key = 4;
            event = "WEBSITE_CRAWLRESULTS";
        } else if (tab === "#rejected") {
            key = 5;
            event = "WEBSITE_REJECTED";
        } else if (tab === "#products") {
            key = 6;
            event = "WEBSITE_PRODUCTS";
        } else if (tab === "#discovery") {
            key = 7;
            event = "WEBSITE_DISCOVERY";
        } else if (tab === "#crawlingeffort") {
            key = 8;
            event = "WEBSITE_CRAWLING_EFFORT";
        } else if (tab === "#costs") {
            key = 9;
            event = "WEBSITE_COSTS";
        } else {
            key = 0;
            event = "WEBSITE_SUMMARY";
        }
        if (this.firstLoad) {
            this.firstLoad = false;
            setTimeout(() => {
                Emitter.emit(event);
            }, 250);
        } else {
            Emitter.emit(event);
        }

        return (
            <MyCarousel activeIndex={key}>
                <Carousel.Item key="#summary">
                    <Summary websiteId={this.state.websiteId} />
                </Carousel.Item>
                <Carousel.Item key="#profile">
                    <Profile websiteId={this.state.websiteId} />
                </Carousel.Item>
                <Carousel.Item key="#volume">
                    <Volume websiteId={this.state.websiteId} />
                </Carousel.Item>
                <Carousel.Item key="#merchants">
                    <Merchants websiteId={this.state.websiteId} />
                </Carousel.Item>
                <Carousel.Item key="#crawlresults">
                    <CrawlResults websiteId={this.state.websiteId} />
                </Carousel.Item>
                <Carousel.Item key="#rejected">
                    <Rejected websiteId={this.state.websiteId} />
                </Carousel.Item>
                <Carousel.Item key="#products">
                    <ProductsMapped websiteId={this.state.websiteId} />
                </Carousel.Item>
                <Carousel.Item key="#discovery">
                    <ProductDiscovery websiteId={this.state.websiteId} />
                </Carousel.Item>
                <Carousel.Item key="#crawlingeffort">
                    <CrawlingEffort websiteId={this.state.websiteId} />
                </Carousel.Item>
                <Carousel.Item key="#costs">
                    {this.showCosts && <Costs websiteId={this.state.websiteId} />}
                </Carousel.Item>
            </MyCarousel>
        );
    }

    render() {
        const activeKey = this.props.location.hash.length ? this.props.location.hash : "#summary";

        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Website</Breadcrumb.Item>
                    <Breadcrumb.Item active>{this.state.website_name}</Breadcrumb.Item>
                </Breadcrumb>
                <Nav variant="tabs" activeKey={activeKey}>
                    <Nav.Item>
                        <Nav.Link href="#summary">Summary</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#profile">Profile</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#volume">Volume</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#merchants">Merchants</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#crawlresults">Crawl Results</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#rejected">Rejected</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#products">Products Mapped</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#discovery">Product Discovery</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#crawlingeffort">Crawling Effort</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#costs" disabled={!this.showCosts}>Costs</Nav.Link>
                    </Nav.Item>
                </Nav>
                {this.showPage(activeKey)}
            </Container>
        );
    }
}
export default Index;
