import React from "react";

const ProgressBar = (props) => {
    let { bgcolor, completed } = props;

    if (typeof completed === "string") {
        completed = parseFloat(completed);
    }
    if (isNaN(completed) || typeof completed !== "number") {
        completed = 0;
    }

    if (completed > 100) {
        completed = 100;
    } else if (completed < 0) {
        completed = 0;
    }

    if (typeof bgcolor !== "string") {
        bgcolor = "#1f4503";
    }

    const containerStyles = {
        height: 20,
        width: "100%",
        backgroundColor: "gray",
        borderRadius: "6px",
    };

    const fillerStyles = {
        height: "100%",
        width: `${completed}%`,
        backgroundColor: bgcolor,
        borderRadius: "inherit",
        textAlign: "right",
    };

    const labelStyles = {
        padding: 5,
        color: "white",
        fontWeight: "bold",
    };

    return (
        <div style={containerStyles}>
            <div style={fillerStyles}>
                <span style={labelStyles}>{`${completed.toFixed(1)}%`}</span>
            </div>
        </div>
    );
};

export default ProgressBar;
