/**
 * Should assist User settings management
 * @hideconstructor
 */
class SettingsService {
    static Has(key) {
        let settings;
        try {
            let pref = localStorage.getItem("preferences");
            settings = typeof pref === "string" ? JSON.parse(localStorage.getItem("preferences")) : {};
        } catch (exception) {
            settings = {};
        }
        return settings !== null && typeof settings[key] !== "undefined";
    }

    static Get(key, defaultValue = null) {
        let settings;
        try {
            let pref = localStorage.getItem("preferences");
            settings = typeof pref === "string" ? JSON.parse(localStorage.getItem("preferences")) : {};
        } catch (exception) {
            settings = {};
        }
        if (settings === null || typeof settings !== "object" || typeof settings[key] === "undefined") {
            return defaultValue;
        }
        return settings[key];
    }

    static Set(key, value) {
        let settings;
        try {
            let pref = localStorage.getItem("preferences");
            settings = typeof pref === "string" ? JSON.parse(localStorage.getItem("preferences")) : {};
        } catch (exception) {
            settings = {};
        }
        if (settings === null) {
            settings = {};
        }
        settings[key] = value;
        localStorage.setItem("preferences", JSON.stringify(settings));
    }

    static Reset() {
        localStorage.removeItem('preferences');
    }
}

export default SettingsService;
