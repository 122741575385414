import React from "react";
import PropTypes from "prop-types";

import { Button, Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import "./EditSIM.css"
import Toast from "../services/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./EditProductAttributes.css"
import ProductService from "../services/ProductService";
import ProcessingButton from "./ProcessingButton";

/**
 * Shows a Edit Products Attributes modal
 * @component
 */
class EditProductAttributes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            legacy: [],
            selections: [],
            problems: {
                legacy: false,
                selections: false,
            },
            saving: false,
            loading: false
        }
        this.onEntering = this.onEntering.bind(this);
    }

    /**
     * Auxiliary function to identify overlapping attributes and prevent form to be save
     * @param {array} list 
     * @returns object {problems, list}
     */
    validateLegacyAttribs(list) {
        let indexes = {};
        let problems = 0;
        list.forEach((element, index) => {
            if (typeof indexes[element.name] === "undefined") {
                indexes[element.name] = [index];
            } else {
                problems++;
                for (let i = 0; i < indexes[element.name].length; i++) {
                    list[indexes[element.name][i]].problem = true;
                }
                list[index].problem = true;
                indexes[element.name].push(index)
            }
        });
        Object.keys(indexes).forEach((attribute) => {
            if (indexes[attribute].length === 1) {
                list[indexes[attribute][0]].problem = false;
            }
        });
        return problems === 0;
    }


    /**
     * Auxiliary function to identify overlapping attributes and prevent form to be save
     * @param {array} list 
     * @returns object {problems, list}
     */
    validateSelectionsAttribs(list) {
        let indexes = {};
        let problems = 0;
        list.forEach((element, index) => {
            if (typeof indexes[element.name] === "undefined") {
                indexes[element.name] = [index];
                if (element.name.length === 0) {
                    problems++;
                    list[index].problem = true;
                }
            } else {
                problems++;
                for (let i = 0; i < indexes[element.name].length; i++) {
                    list[indexes[element.name][i]].problem = true;
                }
                list[index].problem = true;
                indexes[element.name].push(index)
            }
        });
        Object.keys(indexes).forEach((attribute) => {
            if (indexes[attribute].length === 1) {
                list[indexes[attribute][0]].problem = false;
            }
        });
        return problems === 0;
    }

    onEntering() {
        this.setState({
            legacy: [],
            selections: [],
            problems: {
                legacy: false,
                selections: false,
            },
            loading: true
        });
        let domain = this.props.row.website.trim().toLowerCase().replace(/^www\./, '');
        ProductService.getLegacyAttributes(this.props.row.pid).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while retrieving this product's attributes.");
                return;
            }
            let legacy = [];
            let selections = [];

            result.forEach((attr) => {
                if (attr.name === "selections") {
                    try {
                        let json = JSON.parse(attr.value),
                            keys = Object.keys(json);

                        for (let i = 0; i < keys.length; i++) {
                            let domainName = keys[i].toLowerCase().replace(/^www\./, '');
                            if (domainName === domain) {
                                let attributes = json[keys[i]],
                                    names = Object.keys(attributes),
                                    j = 0;
                                names.forEach((name) => {
                                    selections.push({
                                        id: j++,
                                        name,
                                        value: attributes[name]
                                    });
                                });
                                break;
                            }
                        }
                    } catch (e) { }

                } else {
                    legacy.push(attr);
                }
            });
            this.setState({
                loading: false,
                legacy, selections
            })
        });
    }

    render() {
        if (typeof this.props.row === "undefined" || this.props.row === null) {
            return null;
        }

        let cancel = () => { this.props.onClose(false) },
            save = () => {
                this.setState({ saving: true }, () => {
                    let legacy = this.state.legacy,
                        selections = this.state.selections;

                    for (let i = 0; i < legacy.length; i++) {
                        delete legacy[i].problem;
                        if (typeof legacy[i].isNew !== "undefined") {
                            delete legacy[i].hasChanged;
                            delete legacy[i].id;
                        }
                    }
                    for (let i = 0; i < selections.length; i++) {
                        delete selections[i].problem;
                        if (typeof selections[i].isNew !== "undefined") {
                            delete selections[i].hasChanged;
                        }
                        delete selections[i].id;
                    }
                    let rowId = this.props.row.id;
                    ProductService.updateLegacyAttributes(this.props.row.pid, {
                        legacy,
                        selections,
                        domain: this.props.row.website,
                        website_id: this.props.row.mid
                    }).then((result) => {
                        if (result === true) {
                            this.props.onClose(true)
                            if (typeof this.props.onUpdated !== "undefined") {
                                this.props.onUpdated(rowId);
                            }
                            Toast.ok("Attributes updated successfully");
                        } else {
                            Toast.error("Something went wrong while updating attributes");
                        }
                        this.setState({
                            saving: false
                        })
                        rowId = null;
                    });
                });
            }

        const Legacy = () => {
            const Select = (props) => {
                const keys = ["alternative_skus", "alternative_upcs", "application", "asin", "barrel_length", "caliber", "case",
                    "choke", "color", "color_code", "cord", "condition", "custom_shaft", "diameter", "drivetrain", "drive_shaft",
                    "dropdown", "engine", "filter", "flavor", "flex", "frame_color", "frame_lens", "frame_material", "frame_type",
                    "gender", "generation", "grips", "grip_wraps", "hand", "height", "length", "lens_color", "lens_material",
                    "lens_tint", "lens_type", "lie_angle", "loft", "loft-bounce", "make", "material", "model", "mount", "oil_sump",
                    "options", "option", "part_size", "quantity", "rounds", "scent", "shaft", "shaft_length", "shocks", "size",
                    "size_code", "stage_choice", "stroke", "style", "selections", "submodel", "transmission", "trim", "type",
                    "unit_weight", "voltage", "weight", "year"];
                const options = keys.map((key) => (<option selected={props.attribute === key}>{key}</option>))
                const handleSelectChange = (evt) => {
                    let rowIndex = evt.target.closest("div").getAttribute("index") * 1,
                        legacy = this.state.legacy,
                        problems = this.state.problems;

                    legacy[rowIndex].name = evt.target.value;
                    legacy[rowIndex].hasChanged = true;
                    problems.legacy = !this.validateLegacyAttribs(legacy);
                    this.setState({ legacy, problems })
                }
                return <Form.Select isInvalid={props.problem} aria-label="Attribute name" onChange={handleSelectChange}>
                    {options}
                </Form.Select>
            }

            const handleValueChange = (evt) => {
                let rowIndex = evt.target.closest("div").getAttribute("index") * 1,
                    legacy = this.state.legacy;

                legacy[rowIndex].value = evt.target.value;
                legacy[rowIndex].hasChanged = true;
                this.setState({ legacy })
            }

            const handleDelete = (evt) => {
                let rowIndex = evt.target.closest("div").getAttribute("index") * 1,
                    legacy = this.state.legacy,
                    problems = this.state.problems;

                legacy.splice(rowIndex, 1);
                problems.legacy = !this.validateLegacyAttribs(legacy);

                this.setState({ legacy, problems })
            }
            return this.state.legacy.map((attr, index) => {
                return (
                    <div className="attribute-row" index={index} key={`attr-legacy-${attr.id}`} >
                        <span style={{
                            width: "1px"
                        }}>
                            <Button size="sm" variant="outline-danger" onClick={handleDelete}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </span>
                        <span>
                            <Select key={`attr-legacy-${attr.id}`} attribute={attr.name} problem={typeof attr.problem !== "undefined" && attr.problem} />
                        </span>
                        <span>
                            <Form.Control
                                key={`val-legacy-${attr.id}`}
                                type="text"
                                defaultValue={attr.value}
                                onChange={handleValueChange}
                            />
                        </span>
                    </div>
                )
            })
        }

        const Selections = () => {

            const handleAttributeChange = (evt) => {
                let rowIndex = evt.target.closest("div").getAttribute("index") * 1,
                    selections = this.state.selections,
                    problems = this.state.problems;

                selections[rowIndex].name = evt.target.value;
                selections[rowIndex].hasChanged = true;
                problems.selections = !this.validateSelectionsAttribs(selections);

                this.setState({ selections, problems })
            }

            const handleValueChange = (evt) => {
                let rowIndex = evt.target.closest("div").getAttribute("index") * 1,
                    selections = this.state.selections;

                selections[rowIndex].value = evt.target.value;
                selections[rowIndex].hasChanged = true;
                this.setState({ selections })
            }

            const handleDelete = (evt) => {
                let rowIndex = evt.target.closest("div").getAttribute("index") * 1,
                    selections = this.state.selections,
                    problems = this.state.problems;

                selections.splice(rowIndex, 1);
                problems.selections = !this.validateSelectionsAttribs(selections);

                this.setState({ selections, problems })
            }
            return this.state.selections.map((attr, index) => {
                return (
                    <div className="attribute-row" index={index} key={`attr-selections-${attr.id}`} >
                        <span style={{
                            width: "1px"
                        }}>
                            <Button size="sm" variant="outline-danger" onClick={handleDelete}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </span>
                        <span>
                            <Form.Control
                                key={`attr-selections-${attr.id}`}
                                type="text"
                                isInvalid={typeof attr.problem !== "undefined" && attr.problem}
                                defaultValue={attr.name}
                                onChange={handleAttributeChange}
                            />
                        </span>
                        <span>
                            <Form.Control
                                key={`val-selections-${attr.id}`}
                                type="text"
                                defaultValue={attr.value}
                                onChange={handleValueChange}
                            />
                        </span>
                    </div>
                )
            })
        }

        const addNewLegacy = () => {
            let legacy = this.state.legacy,
                problems = this.state.problems;
            legacy.push({
                name: "alternative_skus", value: "", id: Date.now(), isNew: true
            });
            problems.legacy = !this.validateLegacyAttribs(legacy);
            this.setState({ legacy, problems })
        }

        const addNewSelections = () => {
            let selections = this.state.selections,
                problems = this.state.problems;
            selections.push({
                name: "", value: "", id: Date.now(), isNew: true
            });
            problems.selections = !this.validateLegacyAttribs(selections);
            this.setState({ selections, problems })
        }

        return (
            <Modal
                onEntering={this.onEntering}
                size="lg"
                show={this.props.show}
                backdrop={true}
                keyboard={true}
                centered
            >
                <Modal.Header closeButton onHide={cancel}>
                    <Modal.Title>
                        Product Attributes
                    </Modal.Title>
                </Modal.Header>
                {this.state.loading && (
                    <Modal.Body className="text-center">
                        <Spinner
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    </Modal.Body>
                )}
                {!this.state.loading && (
                    <React.Fragment>
                        <Modal.Body className="text-center">
                            <Container>
                                <Row>
                                    <Col sm="3">
                                        <Form.Control
                                            type="text"
                                            value={this.props.row.upc}
                                            readOnly={true}
                                        />
                                    </Col>
                                    <Col sm="3">
                                        <Form.Control
                                            type="text"
                                            value={this.props.row.sku}
                                            readOnly={true}
                                        />
                                    </Col>
                                    <Col sm="6">
                                        <Form.Control
                                            type="text"
                                            value={this.props.row.title}
                                            readOnly={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            as="textarea"
                                            value={this.props.row.notice}
                                            readOnly={true}
                                            className="blocked notice"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6" className="text-left">
                                        <Button size="sm" variant="outline-primary" className="tdButton" onClick={addNewLegacy}>
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button> Legacy attributes
                                    </Col>
                                    <Col sm="6" className="text-left">
                                        <Button size="sm" variant="outline-primary" className="tdButton" onClick={addNewSelections}>
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button> Selections
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6" className="text-left">
                                        {Legacy()}
                                    </Col>
                                    <Col sm="6" className="text-left">
                                        {Selections()}
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer style={{
                            justifyContent: "center"
                        }}>
                            <Button size="sm" variant="secondary" onClick={cancel}>Cancel</Button>
                            <ProcessingButton
                                size="sm"
                                processing={this.state.saving}
                                processingLabel="Saving ..."
                                label="Save"
                                onClick={save}
                                disabled={this.state.problems.legacy || this.state.problems.selections}
                            />
                        </Modal.Footer>

                    </React.Fragment>
                )}
            </Modal >
        );
    }
}

EditProductAttributes.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    onUpdated: PropTypes.func
};

EditProductAttributes.defaultProps = {
    show: true,
    onClose: () => { console.log("onClose") }
};

export default EditProductAttributes;
