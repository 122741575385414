import React from "react";
import PropTypes from "prop-types";

import { Container, Row, Col, Button, Form, Accordion } from "react-bootstrap";
import TagsInput from "@incoqnito.io/react-tagsinput";
import Categories from "../../components/Categories";
import Countries from "../../components/Countries";
import Currencies from "../../components/Currencies";
import Toast from "../../services/Toast";
import Loading from "../Loading";
import Websites from "../../services/WebsiteService";
import Memory from "../../services/MemoryService";
import "./Profile.css";
import "@incoqnito.io/react-tagsinput/react-tagsinput.css";
import Emitter from "../../services/Emitter";
import AuthService from "../../services/AuthService";
import ProcessingButton from "../../components/ProcessingButton";

/**
 * Show a CRUD for the Website row
 * @component
 * @category Scenes
 * @subcategory Websites
 */
class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.websiteId,
            row: {},
            loading: true,
            editing: false,
            updating: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCategories = this.handleChangeCategories.bind(this);
        this.handleChangeCurrency = this.handleChangeCurrency.bind(this);
        this.handleChangeCountry =
            this.handleChangeCountry.bind(this);
        this.dropEdition = this.dropEdition.bind(this);
        this.startEdition = this.startEdition.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.handleTagChange = this.handleTagChange.bind(this);
        this.reload = this.reload.bind(this);

        this.reactTags = React.createRef();
        this.isAdmin = AuthService.isAdmin();
    }

    handleChange(evt) {
        let key = evt.target.name,
            row = this.state.row,
            isCheckbox = evt.target.className.match(/check/);
        switch (key) {
            default:
                row[key] = isCheckbox
                    ? this.state.row[key]
                        ? 0
                        : 1
                    : evt.target.value;
        }
        this.setState({ row });
    }

    handleChangeCategories(selected) {
        let row = this.state.row;
        row["categories"] = selected;
        this.setState({ row });
    }

    handleChangeCurrency(selected, name) {
        let row = this.state.row;
        row[name] = selected;
        this.setState({ row });
    }

    handleChangeCountry(selected) {
        let row = this.state.row;
        row["sellingRegion"] = selected;
        this.setState({ row });
    }

    polishData(row) {
        row.categories = Categories.Selected(row.categories);
        row.defaultCurrency = Currencies.Selected([row.currency_id]);
        row.supportedCurrencies = Currencies.Selected(row.currencies);
        row.sellingRegion = Countries.Selected([row.country_id]);
        row.action = "";
        return row;
    }

    reload() {
        Websites.get(this.state.id).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while retrieving data from this website");
                return;
            }
            let prettyData = this.polishData(data);
            this.setState({
                loading: false,
                row: prettyData,
                editing: false,
            });
        });
    }

    componentWillUnmount() {
        Emitter.off("WEBSITE_PROFILE");
    }

    componentDidMount() {
        Emitter.once("WEBSITE_PROFILE", this.reload);
    }

    saveChanges() {
        this.setState({ updating: true });
        Websites.update(this.state.row).then((data) => {
            if (data === false) {
                this.setState({ updating: false, editing: true });
                Toast.error(
                    "Something wrong happened and changes could not be saved."
                );
            } else {
                this.setState({
                    updating: false,
                    editing: false,
                    row: this.polishData(data)
                });
                Memory.Set("WEBSITE_PROFILE", data);
                Toast.ok("Changes properly saved.");
            }
        });
    }

    startEdition() {
        this.original = Object.assign({}, this.state.row);
        this.setState({ editing: true });
    }

    dropEdition() {
        this.setState({ editing: false, row: this.original });
        delete this.original;
    }

    handleTagChange = (tags) => {
        let row = this.state.row;
        row.tags = tags;
        this.setState({ row });
    };

    render() {
        if (this.state.loading) {
            return (
                <Container className="pt-4">
                    <Row
                        style={{
                            height: "100px",
                        }}
                    >
                        <Col>
                            <Loading />
                        </Col>
                    </Row>
                </Container>
            );
        }
        return (
            <Container className="pt-4">
                <Row>
                    <Col sm={3}>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="display_name"
                            value={this.state.row.display_name}
                            readOnly={true}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Bucket</Form.Label>
                        <Form.Control
                            type="text"
                            name="bucket"
                            value={this.state.row.bucket}
                            readOnly={true}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Crawl frequency</Form.Label>
                        <Form.Select
                            aria-label="Crawl frequency"
                            name="crawl_frequency"
                            disabled={
                                !this.state.editing || this.state.updating
                            }
                            onChange={this.handleChange}
                            value={this.state.row.crawl_frequency}
                        >
                            <option value="daily">1x/day</option>
                            <option value="twice">2x/day</option>
                            <option value="thrice">3x/day</option>
                        </Form.Select>
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Automation Status</Form.Label>
                        <Form.Control
                            type="text"
                            name="automation"
                            value={this.state.row.automation}
                            readOnly={true}
                        />
                    </Col>

                </Row>
                <Row>
                    <Col sm={3}>
                        <Form.Check
                            type="switch"
                            label="Is Retailer?"
                            name="is_retailer"
                            value="true"
                            checked={this.state.row.is_retailer}
                            disabled={true}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col sm={3}>
                        <Form.Check
                            type="switch"
                            label="Has Parser?"
                            name="has_parser"
                            value="true"
                            checked={this.state.row.has_parser}
                            disabled={true}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col sm={3}>
                        <Form.Check
                            type="switch"
                            label="Is active?"
                            name="is_active"
                            checked={this.state.row.is_active}
                            disabled={!this.isAdmin || !this.state.editing || this.state.updating}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col sm={3} style={{
                        display: "inline-flex"
                    }}>
                        <Form.Check
                            type="switch"
                            name="upc_lookup"
                            label="UPC Search"
                            checked={this.state.row.upc_lookup}
                            disabled={true}
                            onChange={this.handleChange}
                        />
                        <Form.Check
                            style={{
                                marginLeft: "auto",
                                marginRight: "60px"
                            }}
                            type="switch"
                            name="bdo"
                            label="BDO"
                            checked={this.state.row.bdo}
                            disabled={true}
                            onChange={this.handleChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <Form.Label>Categories</Form.Label>
                        <Categories
                            name="categories"
                            value={this.state.row.categories}
                            disabled={
                                !this.state.editing || this.state.updating
                            }
                            onChange={this.handleChangeCategories}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>SIM Range</Form.Label>
                        <Form.Control
                            type="text"
                            name="sim"
                            value={this.state.row.sim}
                            readOnly={
                                !this.state.editing || this.state.updating
                            }
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Crawler Version</Form.Label>
                        <Form.Control
                            type="text"
                            name="version"
                            value={this.state.row.version}
                            readOnly={true}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Created</Form.Label>
                        <Form.Control
                            type="text"
                            name="created"
                            value={this.state.row.created}
                            readOnly={true}
                        />

                    </Col>
                    <Col sm={2}>
                        <Form.Label>Modified</Form.Label>
                        <Form.Control
                            type="text"
                            name="modified"
                            value={this.state.row.modified}
                            readOnly={true}
                        />
                    </Col>

                </Row>
                <Row>
                    <Col sm={4}>
                        <Form.Label>Selling country</Form.Label>
                        <Countries
                            singleValue={true}
                            name="sellingRegion"
                            disabled={
                                !this.state.editing || this.state.updating
                            }
                            value={this.state.row.sellingRegion}
                            readOnly={
                                !this.state.editing || this.state.updating
                            }
                            onChange={this.handleChangeCountry}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Default currency</Form.Label>
                        <Currencies
                            singleValue={true}
                            disabled={
                                !this.state.editing || this.state.updating
                            }
                            name="defaultCurrency"
                            value={this.state.row.defaultCurrency}
                            readOnly={
                                !this.state.editing || this.state.updating
                            }
                            onChange={this.handleChangeCurrency}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Supported currencies</Form.Label>
                        <Currencies
                            name="supportedCurrencies"
                            disabled={
                                !this.state.editing || this.state.updating
                            }
                            value={this.state.row.supportedCurrencies}
                            readOnly={
                                !this.state.editing || this.state.updating
                            }
                            onChange={this.handleChangeCurrency}
                        />
                    </Col>

                    <Col sm={2}>
                        <Form.Label>Screenshot Service</Form.Label>
                        <Form.Select
                            name="ss_service"
                            value={this.state.row.ss_service}
                            disabled={true}
                        >
                            <option value="cache">From cache</option>
                            <option value="sqs">Microservice</option>
                        </Form.Select>
                    </Col>
                    {this.state.row.ss_service === "sqs" && (
                        <Col sm={2}>
                            <Form.Label>Screenshot Wait Time</Form.Label>
                            <Form.Select
                                name="ss_wait"
                                value={this.state.row.ss_wait}
                                disabled={true}
                            >
                                <option value="5">5 seconds</option>
                                <option value="10">10 seconds</option>
                                <option value="15">15 seconds</option>
                                <option value="20">20 seconds</option>
                                <option value="30">30 seconds</option>
                                <option value="60">60 seconds</option>
                                <option value="90">90 seconds</option>
                            </Form.Select>
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col sm={6}>
                        <Form.Label>Search page</Form.Label>
                        <Form.Control
                            type="text"
                            name="search_page"
                            value={this.state.row.search_page}
                            onChange={this.handleChange}
                            readOnly={
                                !this.state.editing || this.state.updating
                            }
                        />
                    </Col>
                    <Col sm={6}>
                        <Form.Label>Brands URL</Form.Label>
                        <Form.Control
                            type="text"
                            name="brands_url"
                            value={this.state.row.brands_url}
                            onChange={this.handleChange}
                            readOnly={
                                !this.state.editing || this.state.updating
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Form.Label>Comments</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Leave a comment here"
                            name="comments"
                            value={this.state.row.comments}
                            onChange={this.handleChange}
                            readOnly={
                                !this.state.editing || this.state.updating
                            }
                        />
                    </Col>
                    <Col sm={6}>
                        <Form.Label>Tags</Form.Label>
                        <TagsInput
                            className={
                                !this.state.editing || this.state.updating
                                    ? "react-tagsinput-disabled"
                                    : "react-tagsinput"
                            }
                            onlyUnique={true}
                            validationRegex={/^[\d\w_-]+$/}
                            focusedClassName="react-tagsinput-focus"
                            value={this.state.row.tags}
                            onChange={this.handleTagChange}
                            disabled={
                                !this.state.editing || this.state.updating
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    {this.isAdmin && (
                        <Accordion defaultActiveKey="0" flush>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Advanced settings</Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col sm={2}>
                                            <Form.Label>Multiple URL profile</Form.Label>
                                            <Form.Select
                                                name="multiple_url_profile"
                                                value={this.state.row.multiple_url_profile}
                                                disabled={true}
                                            // disabled={!this.isAdmin || !this.state.editing || this.state.updating}
                                            // onChange={this.handleChange}
                                            >
                                                <option value="single_without_item_number">Single URL</option>
                                                <option value="single_with_item_number" disabled>Single URL with Item Numbers</option>
                                                <option value="single_without_url" disabled>Single Item Number</option>
                                                <option value="multiple_without_item_number" disabled>Multiple URLs</option>
                                                <option value="multiple_with_item_number">Multiple URLs with Item Numbers</option>
                                                <option value="multiple_without_url" disabled>Multiple Item Numbers</option>
                                            </Form.Select>
                                        </Col>
                                        <Col sm={1}>
                                            <Form.Label>Scheme</Form.Label>
                                            <Form.Control
                                                placeholder="http"
                                                name="scheme"
                                                value={this.state.row.scheme}
                                                onChange={this.handleChange}
                                                disabled={!this.state.editing || this.state.updating}
                                            />
                                        </Col>
                                        <Col sm={1}>
                                            <Form.Label>Subdomain</Form.Label>
                                            <Form.Control
                                                placeholder="www"
                                                name="subdomain"
                                                value={this.state.row.subdomain}
                                                onChange={this.handleChange}
                                                disabled={!this.state.editing || this.state.updating}
                                            />
                                        </Col>
                                        <Col sm={2}>
                                            <Form.Label>Domain</Form.Label>
                                            <Form.Control
                                                placeholder="domain"
                                                name="domain_name"
                                                value={this.state.row.domain_name}
                                                onChange={this.handleChange}
                                                disabled={!this.state.editing || this.state.updating}
                                            />
                                        </Col>
                                        <Col sm={1}>
                                            <Form.Label>TLD</Form.Label>
                                            <Form.Control
                                                placeholder="com"
                                                name="suffix"
                                                value={this.state.row.suffix}
                                                onChange={this.handleChange}
                                                disabled={!this.state.editing || this.state.updating}
                                            />
                                        </Col>
                                        <Col sm={2}>
                                            <Form.Label>Action</Form.Label>
                                            <Form.Select
                                                name="action"
                                                value={this.state.row.action}
                                                onChange={this.handleChange}
                                                disabled={!this.state.editing || this.state.updating}
                                            >
                                                <option></option>
                                                <option value="sniffing">Send back to bucket sniffing</option>
                                                {this.state.row.saw !== 'direct' && <option value="direct-LightsAmerica">Turn into Direct bucket (LightsAmerica)</option>}
                                            </Form.Select>
                                        </Col>
                                        <Col sm={3}>
                                            {(this.state.row.saw === 'direct' || this.state.row.action === 'direct') && (
                                                <Form.Check
                                                    style={{
                                                        marginLeft: "auto",
                                                        marginRight: "60px"
                                                    }}
                                                    type="switch"
                                                    name="ss_generate"
                                                    label="Generate Screenshot"
                                                    checked={this.state.row.ss_generate}
                                                    disabled={!this.state.editing || this.state.updating}
                                                    onChange={this.handleChange}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>)}
                </Row>
                <Row>
                    <Col sm={6}>
                        {!this.state.editing && (
                            <Button
                                variant="secondary"
                                onClick={this.startEdition}
                            >
                                Edit
                            </Button>
                        )}
                        {this.state.editing && (
                            <span>
                                <Button
                                    disabled={this.state.updating}
                                    variant="secondary"
                                    onClick={this.dropEdition}
                                >
                                    Cancel
                                </Button>
                                <ProcessingButton
                                    variant="primary"
                                    processing={this.state.updating}
                                    processingLabel="Saving ..."
                                    label="Save"
                                    onClick={this.saveChanges}
                                />
                            </span>
                        )}
                    </Col>
                </Row>
            </Container >
        );
    }
}

Profile.propTypes = {
    /** Website ID */
    websiteId: PropTypes.number.isRequired,
};

export default Profile;
