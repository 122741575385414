import React from "react";

import {
    Container,
    Col,
    Row,
    Breadcrumb
} from "react-bootstrap";

import {withRouter} from "react-router-dom";
import {
    faDownload,
} from "@fortawesome/free-solid-svg-icons";
import ProcessingButton from "../../components/ProcessingButton";
import Toast from "../../services/Toast";
import GenericService from "../../services/GenericService";
import CSV from "../../services/CSV";
import BrandsFilter from "../../components/BrandsFilter";

class ProductDiscoveryAnalyzer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            brands: [],
            dataLoading: false,
        };
    }

    onBrandChange = (rows) => {
        this.setState({
            brands: rows
        });
    }

    getReport = () => {
        this.setState({dataLoading: true});
        return GenericService.getProductDiscoveryAnalyzerReport(this.state.brands)
            .then(response => {
                this.setState({dataLoading: false});
                if (response === false) {
                    Toast.error('Something went wrong while generating report');
                    return;
                }

                const csv = new CSV();
                const headers = [
                    'ID',
                    'Website',
                    'Bucket',
                    'Automation Status',
                    'Number of Dashboards',
                    'Number of Active Products',
                    'Dashboards'
                ]
                headers.forEach(header => {
                    csv.addColumn(header)
                })
                response.data.forEach(item => {
                    csv.addRow(...Object.values(item));
                });
                csv.Download('product_discovery_analyzer.csv');
                return true;
            });
    }

    render() {
        const Breadcrumbs = () => {
            return (
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Others</Breadcrumb.Item>
                    <Breadcrumb.Item active>Product Discovery Analyzer</Breadcrumb.Item>
                </Breadcrumb>
            );
        }
        return (
            <Container className="pt-4">
                <Breadcrumbs/>
                <Row><Col className="p-0">Select Brands</Col></Row>
                <Row className="d-flex align-items-center">
                    <Col className="p-0" md={6}>
                        <BrandsFilter onChange={this.onBrandChange} />
                    </Col>
                    <Col className="p-0" md={6}>
                        <ProcessingButton
                            disabled={this.state.brands.length < 1}
                            processing={this.state.dataLoading}
                            processingLabel="Exporting ..."
                            variant="success"
                            label="Export"
                            icon={faDownload}
                            onClick={this.getReport}
                            style={{minWidth: "150px"}}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withRouter(ProductDiscoveryAnalyzer);
