import React, { createRef } from "react";

import Brands from "../../../services/BrandService";
import Loading from "../../Loading";
import {
    Form,
    Button,
    Carousel,
    Container,
    Col,
    Row,
    Breadcrumb
} from "react-bootstrap";
import ProgressBar from "../../../components/ProgressBar";
import Toast from "../../../services/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBug, faCamera, faCheck, faCheckSquare, faChevronCircleLeft, faChevronCircleRight, faDownload, faExternalLinkAlt, faLink, faPen, faRetweet, faSlidersH, faTimes, faToolbox, faTools, faUnlink, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import CrawlResultsService from "../../../services/CrawlResultsService";
import Utils from "../../../services/Utils";
import {TICKET_TYPES, confirm as ticket} from "../../../components/JIRATicket";
import CSV from "../../../services/CSV";
import WebsiteService from "../../../services/WebsiteService";
import confirm from "../../../components/Confirm";
import prompt from "../../../components/Prompt";
import EditSIM from "../../../components/EditSIM";
import EditProductAttributes from "../../../components/EditProductAttributes";
import { Link, withRouter } from "react-router-dom";
import BrandSearch from "../../../components/BrandSearch";
import BrandService from "../../../services/BrandService";
import MyCarousel from "../../../components/MyCarousel";
import MyTable from "../../../components/MyTable";
import MySpinner from "../../../components/MySpinner";
import FileUploadButton from "../../../components/FileUploadButton";
import { readString } from 'react-papaparse'
import Currencies from "../../../components/Currencies";
import { Menu, MenuItem, SubMenu } from "@szhsin/react-menu";


class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            brand: null,
            isWebsitesLoading: false,
            isProcessing: true,
            options: [],
            websites: {
                all: [],
                map: {},
                selected: [],
            },
            processingIndex: 0,
            showModal: null,
            hasFinishedLoadingInfos: false,
            diffSteps: 0,
            diffCurrent: 0
        };

        this.tableRefResults = createRef();
        this.tableRefDiffs = createRef();
        this.ListOfWebsites = this.ListOfWebsites.bind(this);
        this.TypeAhead = this.TypeAhead.bind(this);
        this.onCheckboxStateChange = this.onCheckboxStateChange.bind(this);
        this.processNext = this.processNext.bind(this);
        this.ProcessingStatus = this.ProcessingStatus.bind(this);
        this.onSlide = this.onSlide.bind(this);
        // this.removeNextURL = this.removeNextURL.bind(this);
        this.handleEditSIM = this.handleEditSIM.bind(this);
        this.handleEditProductAttributes = this.handleEditProductAttributes.bind(this);
        this.onAttributesUpdated = this.onAttributesUpdated.bind(this);
        this.saveResults = this.saveResults.bind(this);
        this.processDiffFile = this.processDiffFile.bind(this);

        //dropdown menus
        this.handleVisitURL = this.handleVisitURL.bind(this);
        this.handleCreateJIRA = this.handleCreateJIRA.bind(this);
        this.handleEditURL = this.handleEditURL.bind(this);
        this.handleRemoveAllWithTheSameError = this.handleRemoveAllWithTheSameError.bind(this);
        this.handleRemoveURL = this.handleRemoveURL.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleDownloadDiff = this.handleDownloadDiff.bind(this);
        this.handleEditAllURLs = this.handleEditAllURLs.bind(this);
        this.handleVisitWebsiteProfile = this.handleVisitWebsiteProfile.bind(this);
        this.handleReleaseProduct = this.handleReleaseProduct.bind(this);
        this.handleReleaseAllProducts = this.handleReleaseAllProducts.bind(this);
        this.handleReleaseAllProductsWithError = this.handleReleaseAllProductsWithError.bind(this);
        this.handleReleaseProductsFromWebsite = this.handleReleaseProductsFromWebsite.bind(this);
        this.handleSwitchChecked = this.handleSwitchChecked.bind(this);

        if (window.location.hash.match(/(results|websites|diff)/)) {
            this.props.history.push('/diags/rp');
        }
    }

    onSlide(key) {
        if (key === 0) {
            this.setState({
                websites: {
                    all: [],
                    map: {},
                    selected: [],
                },
                processingIndex: 0,
                isProcessing: false,
                canceled: true
            });
        } else if (key === 1) {

            this.setState({
                canceled: true,
                processingIndex: 0,
                isProcessing: false,
            }, () => {
                if (this.tableRefResults.current !== null) {
                    this.tableRefResults.current.populate([]);
                }
            });

        }
    }

    TypeAhead = () => {
        return (
            <React.Fragment>
                <Row>
                    <Col>Start by selecting a brand:</Col>
                </Row>
                <Row>
                    <Col>
                        <BrandSearch onChange={this.handleChange} />
                    </Col>
                </Row>
                or:
                <Row>
                    <Col>
                        <ul>
                            <li>
                                <FileUploadButton
                                    acceptedFileTypes=".csv,.csv"
                                    label="Check for modifications over a CSV Report file"
                                    type="text"
                                    onFileUpload={this.processDiffFile}
                                /></li>
                            <li>
                                <FileUploadButton
                                    acceptedFileTypes=".rpaudit,.RPAUDIT"
                                    label="Load a snapshot file"
                                    type="text"
                                    onFileUpload={(file) => {
                                        let obj;
                                        try {
                                            obj = JSON.parse(file.content);
                                        } catch (e) {
                                            obj = null;
                                        }
                                        if (obj !== null) {
                                            this.setState({
                                                brand: obj.brand,
                                                websites: obj.websites,
                                                isProcessing: false,
                                                hasFinishedLoadingInfos: true
                                            }, () => {
                                                this.tableRefResults.current.snapshot(obj.table);
                                                this.props.history.push("/diags/rp#results");
                                            })
                                        }
                                    }}
                                /></li>
                        </ul>
                    </Col>
                </Row>
                <Row
                    style={{
                        height: "350px",
                    }}
                ></Row>
            </React.Fragment>
        );
    };

    /**
     * Scan
     */

    saveResults = () => {
        Utils.DownloadContent(this.state.brand.name.replace(/\s+/, '') + "-" + Utils.DateNow().substring(0, 10) + ".rpaudit", JSON.stringify({
            brand: this.state.brand,
            websites: this.state.websites,
            table: this.tableRefResults.current.snapshot()
        }));
    }

    handleChange = (row) => {
        this.start = null;
        if (this.tableRefResults.current !== null) {
            this.tableRefResults.current.populate([]);
        }

        if (!row.length) {
            this.setState({
                brand: null
            });
            return;
        }

        this.setState({
            brand: row[0],
            isWebsitesLoading: true,
            processingIndex: 0,
            isProcessing: true
        }, () => {
            this.props.history.push("/diags/rp#websites");
        });
        Brands.getSellingWebsites(row[0].id).then((result) => {
            let map = {},
                i = 0;
            result.forEach((row) => {
                map[row.id] = i++;
            });
            this.setState({
                isWebsitesLoading: false,
                websites: {
                    all: result,
                    map,
                    selected: [],
                },
            });
        });
    };


    onCheckboxStateChange(evt) {
        let wid = evt.target.getAttribute("wid") * 1,
            websites = this.state.websites,
            position = websites.selected.indexOf(wid);
        if (position === -1) {
            websites.selected.push(wid);
        } else {
            websites.selected.splice(position, 1);
        }
        this.setState({ websites });
    }

    processNext() {
        if (this.state.canceled) {
            return;
        }

        if (this.start === null) {
            this.start = Date.now();
        }

        let next = this.state.processingIndex;
        if (next >= this.state.websites.selected.length) {

            Toast.ok("Finished loading data after " + Utils.Seconds2Time((Date.now() - this.start) / 1000));

            this.setState({
                isProcessing: false,
                processingIndex: 0,
                hasFinishedLoadingInfos: this.pendingInfo === 0
            });

        } else {

            let website_id = this.state.websites.selected[next],
                website = this.state.websites.all[this.state.websites.map[website_id]],
                brand_id = this.state.brand.id;

            CrawlResultsService.getNonCrawledItems(website_id, brand_id).then((result) => {
                if (this.state.canceled) {
                    return;
                }
                if (result === false) {
                    Toast.error("Something went wrong while request data from website '" + this.state.websites.all[website_id].display_name + "'")
                    return;
                }

                let ids = [],
                    now = (new Date()).toISOString().replace('T', ' ').split('.')[0];
                result.currencies.forEach((currency_id) => {
                    result.items.forEach((item) => {

                        let row = {
                            order: Date.now(),
                            mid: website_id,
                            pid: item.pid,
                            website: website.name,
                            identifier: item.identifier,
                            upc: item.upc,
                            sku: item.sku,
                            title: item.title,
                            currency_id: currency_id,
                            notice: null,
                            blocked: typeof item.blocked !== "undefined" && item.blocked.indexOf(currency_id) !== -1,
                            url: item.url,
                            date: typeof item.currencies !== "undefined" ? item.currencies[currency_id] : now,
                            checked: typeof item.action !== "undefined" && typeof item.action[currency_id] !== "undefined" ? "1" : "0"
                        },
                            id = row.mid + "_" + row.pid + "_" + row.currency_id + "_0";

                        row.id = id;
                        ids.push(id);
                        this.tableRefResults.current.addRow(row);
                    });
                });

                if (ids.length) {
                    this.pendingInfo++;
                    CrawlResultsService.get(ids).then((data) => {
                        if (this.state.canceled) {
                            return;
                        }
                        if (data === false) {
                            return;
                        }

                        let rows = [];
                        Object.keys(data).forEach((id) => {
                            let json, content;
                            try {
                                json = JSON.parse(data[id]);
                                content = JSON.stringify(json, undefined, 2);
                            } catch (exception) {
                                content = data[id];
                            }
                            let obj = this.tableRefResults.current.getRowByID(id);
                            obj.row.notice = content;
                            rows.push(obj.row);
                        });
                        this.tableRefResults.current.updateRows(rows);
                        this.pendingInfo--;

                        if (this.pendingInfo === 0 && this.state.processingIndex === 0 && !this.state.hasFinishedLoadingInfos) {
                            this.setState({ hasFinishedLoadingInfos: true });
                        }
                    });
                }

                this.setState({
                    processingIndex: next + 1,
                }, this.processNext)
            });
        }
    }

    handleVisitURL(evt) {
        let obj = this.tableRefResults.current.getRowFromEvent(evt);
        window.open(obj.row.url, "_blank", "noopener,noreferrer");
    }

    handleVisitWebsiteProfile(evt) {
        let obj = this.tableRefResults.current.getRowFromEvent(evt);
        window.open("/website/" + obj.row.mid, "_blank", "noopener,noreferrer");
    }

    handleEditAllURLs(evt) {
        let obj = this.tableRefResults.current.getRowFromEvent(evt);
        window.open("/brand/" + this.state.brand.id + "#lookup," + obj.row.mid, "_blank", "noopener,noreferrer");
    }

    handleEditURL(evt) {
        let obj = this.tableRefResults.current.getRowFromEvent(evt);
        prompt("Edit URL", "Update", "Cancel", obj.row.identifier, obj.row.url)
            .then((result) => {
                if (result.value === obj.row.url) {
                    return;
                }

                if (result.value.trim().length === 0) {
                    WebsiteService.removeURL(
                        obj.row.mid,
                        obj.row.pid
                    ).then((updateResult) => {
                        if (updateResult) {
                            this.tableRefResults.current.deleteRow(obj.row);
                            Toast.ok("URL removed successfully");
                        } else {
                            Toast.error(
                                "Something went wrong while updating this URL. Changes could not be properly saved."
                            );
                        }
                    });
                } else {
                    WebsiteService.setURL(
                        obj.row.mid,
                        obj.row.pid,
                        result.value
                    ).then((updateResult) => {
                        if (updateResult) {
                            obj.row.url = result.value;
                            obj.row.blocked = false;
                            this.tableRefResults.current.updateRow(obj.row);
                            Toast.ok("URL updated successfully");
                        } else {
                            Toast.error(
                                "Something went wrong while updating this URL. Changes could not be properly saved."
                            );
                        }
                    });
                }
            })
            .catch(() => { });
    }

    async handleRemoveAllWithTheSameError(evt) {
        let obj = this.tableRefResults.current.getRowFromEvent(evt);
        if (
            await confirm("Are you sure?", "Yes, continue", "No", {
                message:
                    "You might be about to remove URLs for a bunch of items. This operation can't be undone. Do you want to proceed?",
            }) === false
        ) {
            return;
        }


        let urlRemoval = {
            mid: obj.row.mid,
            pids: [],
            ids: []
        },
            rows = [];
        this.tableRefResults.current.data(false).forEach((item) => {
            if (item.mid === obj.row.mid && item.notice === obj.row.notice) {
                urlRemoval.pids.push(item.pid);
                urlRemoval.ids.push(item.id);
                rows.push(item);
            }
        });

        WebsiteService.removeURLs(urlRemoval.mid, urlRemoval.pids).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while removing URLs");
                return;
            }

            this.tableRefResults.current.deleteRows(rows);
            Toast.ok("Successfully removed " + urlRemoval.pids.length + " items");
        });
    }

    async handleReleaseAllProducts() {
        let answer = await confirm("Should all websites be released?", "All websites", "Websites in the table only", {
            onDropReturn: null,
            message:
                "Perhaps you'd like to include all products being prevented from crawling at other websites too. Would you like to release all products of " + this.state.brand.name + " from being crawled at all websites or just at the websites cited in the current table?",
        });
        if (answer === null) {
            return;
        }

        BrandService.unblock(this.state.brand.id, answer ? [] : this.state.websites.selected).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while releasing products");
                return;
            }

            const rows = this.tableRefResults.current.data(false).map((item) => {
                item.blocked = false;
                return item;
            });
            this.tableRefResults.current.updateRows(rows);

            Toast.ok("Released products successfully");
        });
    }

    handleReleaseAllProductsWithError(evt) {
        let obj = this.tableRefResults.current.getRowFromEvent(evt),
            ids = [],
            rows = [];

        this.tableRefResults.current.data(false).forEach((item) => {
            if (item.mid === obj.row.mid && item.notice === obj.row.notice) {
                ids.push(item.mid + "-" + item.pid);
                rows.push(item);
            }
        });

        WebsiteService.unblockByID(ids).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while releasing products");
                return;
            }

            rows.forEach((row) => {
                row.blocked = false;
            });

            this.tableRefResults.current.updateRows(rows);
            Toast.ok("Products released successfully");
        });
    }

    handleSwitchChecked(evt) {
        let obj = this.tableRefResults.current.getRowFromEvent(evt),
            checked = obj.row.checked === '0';

        obj.row.checking = true;
        this.tableRefResults.current.updateRow(obj.row);
        CrawlResultsService.switchCheck(obj.row.id, checked).then((result) => {
            delete obj.row.checking;
            if (result === false) {
                Toast.error("Something went wrong while switching statuses")
            } else {
                obj.row.checked = checked ? '1' : '0'
            }
            this.tableRefResults.current.updateRow(obj.row);
        });
    }

    handleReleaseProductsFromWebsite(evt) {
        let obj = this.tableRefResults.current.getRowFromEvent(evt);

        WebsiteService.unblock(obj.row.mid).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while releasing this website");
                return;
            }

            let rows = [];
            this.tableRefResults.current.data(false).forEach((row) => {
                if (row.mid === obj.row.mid) {
                    row.blocked = false;
                    rows.push(row);
                }
            })

            this.tableRefResults.current.updateRows(rows);
            Toast.ok("Products from " + obj.row.website + " were released successfully");
        });
    }

    handleReleaseProduct(evt) {
        let obj = this.tableRefResults.current.getRowFromEvent(evt);
        WebsiteService.unblock(obj.row.mid, obj.row.pid).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while releasing this product");
                return;
            }

            obj.row.blocked = false;
            this.tableRefResults.current.updateRow(obj.row);
            Toast.ok("Released product successfully");
        });
    }

    async handleRemoveURL(evt) {
        let obj = this.tableRefResults.current.getRowFromEvent(evt);
        if (
            await confirm("Remove URL?", "Yes, continue", "No", {
                message:
                    "This operation can't be undone. Do you want to proceed?",
            }) === false
        ) {
            return;
        }

        WebsiteService.removeURL(obj.row.mid, obj.row.pid).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while removing URL");
                return;
            }

            this.tableRefResults.current.deleteRow(obj.row);
            Toast.ok("URL successfully removed");
        });
    }

    handleEditSIM(evt) {
        let obj = this.tableRefResults.current.getRowFromEvent(evt);

        this.setState({
            showModal: {
                type: "sim",
                website: {
                    id: obj.row.mid,
                    name: obj.row.website
                }
            }
        })
    }

    handleEditProductAttributes(evt) {
        let obj = this.tableRefResults.current.getRowFromEvent(evt);

        this.setState({
            showModal: {
                type: "pa",
                row: obj.row
            }
        })
    }

    async handleCreateJIRA(evt) {
        const obj = this.tableRefResults.current.getRowFromEvent(evt);
        await ticket(TICKET_TYPES.REJECTED_PRODUCT, {
            notes: obj.row.notice,
            website_id: obj.row.mid,
            website_name: obj.row.website,
            brand_id: this.state.brand.id,
            brand_name: this.state.brand.name,
            product_id: obj.row.pid,
            product_name: obj.row.title,
            offer_url: obj.row.url,
        });
    }

    handleDownload() {
        let csv = new CSV();
        csv.addColumn("Website", "Identifier", "UPC", "SKU", "Title", "URL", "Currency", "Notice", "Verified", "Product Attributes", "Map Product Attributes", "Website profile");
        this.tableRefResults.current.data().forEach((item) => {
            csv.addRow(item.website, item.identifier, item.upc, item.sku, item.title, item.url, Currencies.CodeByID(item.currency_id), item.notice, item.checked === '1' ? "Yes" : "No", "https://super.trackstreet.com/super/tools/product_attributes/#" + item.upc, item.url + "#UPC:" + item.upc, "https://tools.trackstreet.com/website/" + item.mid);
        });
        csv.Download("rp-brand" + this.state.brand.id + ".csv");
    }

    handleDownloadDiff() {
        let filename = this.filename.substring(0, this.filename.length - 4) + '-diff-' + Utils.DateNow().substring(0, 10) + '.csv';
        this.tableRefDiffs.current.CSV({
            filename
        });
    }

    onAttributesUpdated(pid) {
        let obj = this.tableRefResults.current.getRowByID(pid);
        obj.row.blocked = false;
        this.tableRefResults.current.updateRow(obj.row);
    }



    ListOfWebsites = () => {
        if (this.state.brand === null) {
            return null;
        }
        if (this.state.isWebsitesLoading) {
            return <Loading />;
        }
        const WebsitesList = () => {
            let websites = this.state.websites.all;
            return websites.map((row) => {
                return (
                    <Col key={`col-${row.id}`} sm={3}>
                        <Form.Check
                            wid={row.id}
                            type="checkbox"
                            checked={
                                this.state.websites.selected.indexOf(
                                    row.id
                                ) !== -1
                            }
                            label={row.name}
                            onChange={this.onCheckboxStateChange}
                        />
                    </Col>
                );
            });
        };
        return (
            <React.Fragment>
                <Row>
                    <Col sm={2}>
                        <Button
                            size="sm"
                            variant="outline-danger"
                            onClick={() => {
                                this.props.history.push("/diags/rp");
                            }}
                        >
                            <FontAwesomeIcon icon={faChevronCircleLeft} style={{
                                marginRight: "10px"
                            }} />
                            Return
                        </Button>
                    </Col>
                    <Col sm={8} className="text-center">
                        <Button
                            size="sm"
                            variant="outline-secondary"
                            onClick={() => {
                                let websites = this.state.websites;
                                websites.selected = [];
                                websites.all.forEach((row) => {
                                    websites.selected.push(row.id);
                                });
                                this.setState({ websites });
                            }}
                        >
                            <FontAwesomeIcon icon={faCheckSquare} style={{
                                marginRight: "10px"
                            }} />
                            Select All
                        </Button>
                        <Button
                            size="sm"
                            variant="outline-secondary"
                            onClick={() => {
                                let websites = this.state.websites;
                                websites.selected = [];
                                this.setState({ websites });
                            }}
                        >
                            <FontAwesomeIcon icon={faSquare} style={{
                                marginRight: "10px"
                            }} />
                            Deselect All
                        </Button>
                        <Button
                            size="sm"
                            variant="outline-secondary"
                            onClick={() => {
                                let websites = this.state.websites,
                                    newSelection = [];
                                websites.all.forEach((row) => {
                                    if (
                                        websites.selected.indexOf(row.id) === -1
                                    ) {
                                        newSelection.push(row.id);
                                    }
                                });
                                websites.selected = newSelection;
                                this.setState({ websites });
                            }}
                        >
                            <FontAwesomeIcon icon={faRetweet} style={{
                                marginRight: "10px"
                            }} />
                            Invert selection
                        </Button>
                    </Col>
                    <Col sm={2} className="text-right">
                        <Button
                            disabled={this.state.websites.selected.length === 0}
                            size="sm"
                            variant="outline-primary"
                            onClick={() => {
                                this.setState({ canceled: false, isProcessing: true, hasFinishedLoadingInfos: false }, () => {
                                    this.props.history.push("/diags/rp#results");
                                    this.pendingInfo = 0;
                                    this.processNext();
                                });
                            }}
                        >
                            Next
                            <FontAwesomeIcon icon={faChevronCircleRight} style={{
                                marginLeft: "10px"
                            }} />
                        </Button>
                    </Col>
                </Row>
                <Row
                    className="checkboxes"
                    style={{
                        marginTop: "20px ! important",
                    }}
                >
                    <WebsitesList />
                </Row>
            </React.Fragment>
        );
    };

    ProcessingStatus() {
        if (
            !this.state.isProcessing ||
            this.state.websites.selected.length === 0 || this.state.processingIndex >= this.state.websites.length
        ) {
            return null;
        }
        let widInProcess =
            this.state.websites.selected[this.state.processingIndex],
            inProcess =
                this.state.websites.all[
                this.state.websites.map[widInProcess]
                ];
        if (typeof inProcess == "undefined") {
            return null;
        }
        return (
            <Row>
                <Col sm={6}>
                    ({this.state.processingIndex + 1}/
                    {this.state.websites.selected.length}) Checking{" "}
                    {inProcess.name}
                </Col>
                <Col sm={6}>
                    <ProgressBar
                        bgcolor="#1f4503"
                        completed={
                            (this.state.processingIndex * 100) /
                            this.state.websites.selected.length
                        }
                    />
                </Col>
            </Row>
        );
    }


    /**
     * DIFF
     */

    processDiffFile(file) {
        return new Promise((resolve) => {
            this.filename = file.metadata.name;
            readString(
                file.content, {
                complete: (results) => {
                    if (results.errors.length) {
                        Toast.error("One or more lines is malformed.");
                        resolve(false);
                        return;
                    }
                    if (results.data.length === 0) {
                        Toast.error("Empty file");
                        resolve(false);
                        return;
                    }

                    let headers = results.data.shift();
                    if (
                        headers[0] !== 'Website' ||
                        headers[1] !== 'Identifier' ||
                        headers[2] !== 'UPC' ||
                        headers[3] !== 'SKU' ||
                        headers[4] !== 'Title' ||
                        headers[5] !== 'URL' ||
                        headers[6] !== 'Currency' ||
                        headers[7] !== 'Notice'
                    ) {
                        Toast.error("Unknown CSV format. Please use only original CSV files generated by tools.");
                        resolve(false);
                        return;
                    }

                    let data = [],
                        items = results.data.length,
                        pack = [];

                    this.packs = [];

                    for (var i = 0; i < items; i++) {
                        let row = results.data.shift(),
                            item = {
                                website_id: parseInt(row[11].substring(38)),
                                website: row[0],
                                identifier: row[1],
                                upc: row[2],
                                sku: row[3],
                                title: row[4],
                                url: row[5],
                                currency_id: Currencies.IDByCode(row[6]),
                                oldNotice: row[7],
                                notice: null,
                                changed: null,
                                fixed: null
                            };
                        pack.push({
                            website_id: item.website_id,
                            identifier: item.identifier,
                            currency_id: item.currency_id
                        });
                        item.id = item.website_id + '_' + item.identifier + '_' + item.currency_id;
                        data.push(item);

                        if (pack.length >= 50) {
                            this.packs.push(pack);
                            pack = [];
                        }
                    }

                    if (pack.length) {
                        this.packs.push(pack);
                    }

                    this.tableRefDiffs.current.populate(data);

                    this.setState({
                        diffSteps: this.packs.length,
                        diffCurrent: 0,
                        canceled: false
                    }, () => {

                        this.props.history.push("/diags/rp#diff");
                        this.processNextDiff();
                        resolve(true);

                    });
                }
            })
        })

    }

    processNextDiff() {
        if (this.state.canceled) {
            return;
        }

        if (this.start === null) {
            this.start = Date.now();
        }

        let next = this.packs.shift();
        if (typeof next !== 'object') {

            Toast.ok("Finished reviewing data after " + Utils.Seconds2Time((Date.now() - this.start) / 1000));

        } else {

            CrawlResultsService.checkForModifications(next).then((result) => {

                if (typeof result === "object" && result.length) {
                    let rows = [];
                    if (this.tableRefDiffs.current == null) {
                        return;
                    }
                    for (var i = 0; i < result.length; i++) {
                        let obj = this.tableRefDiffs.current.getRowByID(result[i].id);
                        if (obj.row === null) {
                            continue;
                        }

                        obj.row.fixed = typeof result[i].crawled === "boolean" && result[i].crawled ? 1 : 0;
                        if (obj.row.fixed === 0) {
                            if (typeof result[i].notice === "string") {
                                try {
                                    let json = JSON.parse(result[i].notice);
                                    obj.row.notice = JSON.stringify(json, undefined, 2);
                                } catch (exception) {
                                    obj.row.notice = result[i].notice;
                                }
                            } else {
                                obj.row.notice = '(missing)';
                            }
                            obj.row.notice = Utils.cleanCSVValue(obj.row.notice);
                            obj.row.changed = obj.row.notice !== obj.row.oldNotice ? 1 : 0;
                        } else {
                            obj.row.changed = 1;
                            obj.row.notice = '';
                        }
                        rows.push(obj.row);
                    }
                    this.tableRefDiffs.current.updateRows(rows);
                }

                this.setState({
                    diffCurrent: this.state.diffCurrent + 1
                });
                this.start = null;
                this.processNextDiff();

            });
        }
    }

    render() {
        const key = window.location.hash.match(/results/) ? 2 : (window.location.hash.match(/websites/) ? 1 : (window.location.hash.match(/diff/) ? 3 : 0));
        const Breadcrumbs = () => {
            return (<Breadcrumb className="dd">
                <Breadcrumb.Item>Diagnostics</Breadcrumb.Item>
                <Breadcrumb.Item
                    active={key === 0}
                    onClick={() => { this.props.history.push("/diags/rp") }}
                >
                    Rejected Products
                </Breadcrumb.Item>
                {key > 0 && key <= 2 && (
                    <Breadcrumb.Item active={key === 1}
                        onClick={() => { this.props.history.push("/diags/rp#websites") }}
                    >Websites</Breadcrumb.Item>
                )}
                {key === 2 && (
                    <Breadcrumb.Item active>Results</Breadcrumb.Item>
                )}
                {key === 3 && (
                    <Breadcrumb.Item active>Diff</Breadcrumb.Item>
                )}
            </Breadcrumb>)
        }
        return (
            <Container className="pt-4">
                <EditSIM website={this.state.showModal !== null && this.state.showModal.type === "sim" ? this.state.showModal.website : { id: 0, name: "" }} show={this.state.showModal !== null && this.state.showModal.type === "sim"} onClose={() => this.setState({ showModal: null })} />
                <EditProductAttributes row={this.state.showModal !== null && this.state.showModal.type === "pa" ? this.state.showModal.row : null} show={this.state.showModal !== null && this.state.showModal.type === "pa"} onClose={() => this.setState({ showModal: null })} onUpdated={this.onAttributesUpdated} />
                {key === 2 && !this.state.isProcessing && (
                    <span style={{
                        float: "right"
                    }}
                    >
                        <Button
                            size="sm"
                            className="tdButton"
                            onClick={this.saveResults}
                            disabled={!this.state.hasFinishedLoadingInfos}
                        >
                            <FontAwesomeIcon icon={faCamera} style={{
                                marginRight: "5px"
                            }} />
                            Save Snapshot
                        </Button>
                        <Button
                            size="sm"
                            variant="success"
                            className="tdButton"
                            onClick={this.handleDownload}
                            disabled={!this.state.hasFinishedLoadingInfos}
                        >
                            <FontAwesomeIcon icon={faDownload} style={{
                                marginRight: "5px"
                            }} />
                            Download spreadsheet
                        </Button>
                    </span>
                )
                }
                {key === 3 && this.state.diffCurrent === this.state.diffSteps && (
                    <span style={{
                        float: "right"
                    }}
                    >
                        <Button
                            size="sm"
                            variant="success"
                            className="tdButton"
                            onClick={this.handleDownloadDiff}
                        >
                            <FontAwesomeIcon icon={faDownload} style={{
                                marginRight: "5px"
                            }} />
                            Download spreadsheet
                        </Button>
                    </span>)
                }
                <Breadcrumbs />
                <MyCarousel activeIndex={key} onSlide={this.onSlide}>
                    <Carousel.Item>
                        <this.TypeAhead />
                    </Carousel.Item>
                    <Carousel.Item>
                        <this.ListOfWebsites />
                    </Carousel.Item>
                    <Carousel.Item>
                        <this.ProcessingStatus />
                        <MyTable
                            name="diagnostic-rejected-products"
                            dynamic={false}
                            enableSnapshoting={true}
                            ref={this.tableRefResults}
                            initialFilter={{ checked: "0" }}
                            sortBy="website"
                            sortOrder="asc"
                            renderRows={{
                                className: (row) => row.blocked ? "blocked" : ""
                            }}
                            headers={
                                [
                                    {
                                        width: "150px",
                                        field: "website",
                                        label: "Website",
                                        sortable: true,
                                        searchable: true
                                    },
                                    {
                                        width: "75px",
                                        field: "identifier",
                                        label: "Identifier",
                                        sortable: true,
                                        searchable: true,
                                    },
                                    {
                                        width: "75px",
                                        field: "upc",
                                        label: "UPC",
                                        sortable: true,
                                        searchable: true,
                                    },
                                    {
                                        width: "75px",
                                        field: "sku",
                                        label: "SKU",
                                        sortable: true,
                                        searchable: true
                                    },
                                    {
                                        width: "250px",
                                        field: "title",
                                        label: "Title",
                                        sortable: true,
                                        searchable: true
                                    },
                                    {
                                        field: "currency",
                                        label: "$",
                                        width: "1px"
                                    },
                                    {
                                        field: "notice",
                                        label: "Notice",
                                        searchable: true
                                    },
                                    {
                                        field: "checked",
                                        label: "Verified",
                                        sortable: true,
                                        allowEmpty: true,
                                        options: [{ label: "No", value: "0" }, { label: "Yes", value: "1" }],
                                        width: "80px"
                                    },
                                    {
                                        field: "control",
                                        width: "1px",
                                        content: <Menu
                                            style={{
                                                fontSize: "medium",
                                                fontWeight: "normal"
                                            }}
                                            align={`start`}
                                            direction={`left`}
                                            menuButton={<Button variant="outline-primary"
                                                size="sm"
                                                style={{
                                                    minWidth: "35px",
                                                    marginLeft: "0px",
                                                    marginRight: "0px"
                                                }}><FontAwesomeIcon icon={faTools} /></Button>}>
                                            <MenuItem onClick={this.handleReleaseAllProducts} disabled={this.state.isProcessing}>
                                                <FontAwesomeIcon
                                                    icon={faUnlock}
                                                    className='subMenuIcon'
                                                /> Release all products for crawling
                                            </MenuItem>
                                            <MenuItem onClick={this.handleDownload} disabled={this.state.isProcessing}>
                                                <FontAwesomeIcon
                                                    icon={faDownload}
                                                    className='subMenuIcon'
                                                /> Download spreadsheet
                                            </MenuItem>
                                        </Menu>
                                    }
                                ]
                            }
                            renderColumns={{
                                website: (row) => <Link className="link" to={`/website/${row.mid}`} target="_blank">(#{row.mid}) {row.website}</Link>,
                                identifier: (row) => <Link className="link" to={`/product/${row.pid}`} target="_blank">{row.identifier}</Link>,
                                upc: (row) => <Link className="link" to={`/product/${row.pid}`} target="_blank">{row.upc}</Link>,
                                sku: (row) => <Link className="link" to={`/product/${row.pid}`} target="_blank">{row.sku}</Link>,
                                title: (row) => <Form.Control
                                    type="text"
                                    value={row.title}
                                    readOnly={true}
                                />,
                                checked: {
                                    format: (row) => row.checking ? <MySpinner /> : <span onClick={this.handleSwitchChecked}>{row.checked === "1" ? <FontAwesomeIcon icon={faCheck} className="faSwitch checked" /> : <FontAwesomeIcon icon={faTimes} className="faSwitch" />}</span>,
                                    className: "text-center"
                                },
                                currency: (row) => {
                                    const currency = Currencies.CodeByID(row.currency_id).toLowerCase();
                                    return <div className={`currency-flag currency-flag-${currency}`}></div>
                                },
                                control: (row) => {
                                    const notice = row.notice === null || typeof row.notice !== "string" ? '' : row.notice,
                                        shouldDisplaySIM = notice.indexOf('SIMilarity') !== -1,
                                        shouldDisplayAttribute = /(variation|found)/i.test(notice);
                                    return <Menu
                                        align={`start`}
                                        direction={`left`}
                                        key={`control-${row.pid}-${row.mid}`}
                                        menuButton={<Button variant="outline-secondary"
                                            size="sm"
                                            style={{
                                                minWidth: "35px",
                                                marginLeft: "0px",
                                                marginRight: "0px"
                                            }}><FontAwesomeIcon icon={faTools} /></Button>}>
                                        <SubMenu
                                            label={
                                                <span>
                                                    <FontAwesomeIcon
                                                        icon={faLink}
                                                        className='subMenuIcon'
                                                    /> URL
                                                </span>}
                                            direction={`left`}
                                            offsetY={-8}>
                                            <MenuItem onClick={this.handleVisitURL}>
                                                <FontAwesomeIcon
                                                    icon={faExternalLinkAlt}
                                                    className='subMenuIcon'
                                                /> Visit
                                            </MenuItem>
                                            <MenuItem onClick={this.handleEditURL}>
                                                <FontAwesomeIcon
                                                    icon={faPen}
                                                    className='subMenuIcon'
                                                /> Edit
                                            </MenuItem>
                                            <MenuItem onClick={this.handleEditAllURLs}>
                                                <FontAwesomeIcon
                                                    icon={faPen}
                                                    className='subMenuIcon'
                                                /> Edit all
                                            </MenuItem>
                                            <MenuItem onClick={this.handleRemoveURL}>
                                                <FontAwesomeIcon
                                                    icon={faUnlink}
                                                    className='fal subMenuIcon'
                                                /> Remove
                                            </MenuItem>
                                            <MenuItem onClick={this.handleRemoveAllWithTheSameError}>
                                                <FontAwesomeIcon
                                                    icon={faUnlink}
                                                    className='subMenuIcon'
                                                /> Remove all with this error
                                            </MenuItem>
                                        </SubMenu>
                                        <SubMenu
                                            label={
                                                <span>
                                                    <FontAwesomeIcon
                                                        icon={faUnlock}
                                                        className='subMenuIcon'
                                                    /> Release
                                                </span>}
                                            direction={`left`}>
                                            <MenuItem onClick={this.handleReleaseProduct}>Product</MenuItem>
                                            <MenuItem onClick={this.handleReleaseProductsFromWebsite}>All products from this website</MenuItem>
                                            <MenuItem onClick={this.handleReleaseAllProductsWithError}>All products with this error</MenuItem>
                                        </SubMenu>
                                        {shouldDisplayAttribute && <MenuItem onClick={this.handleEditProductAttributes}>
                                            <FontAwesomeIcon
                                                icon={faToolbox}
                                                className='subMenuIcon'
                                            /> Edit attributes
                                        </MenuItem>}
                                        {shouldDisplaySIM && <MenuItem onClick={this.handleEditSIM}>
                                            <FontAwesomeIcon
                                                icon={faSlidersH}
                                                className='subMenuIcon'
                                            /> Edit SIM range
                                        </MenuItem>}
                                        <MenuItem onClick={this.handleCreateJIRA}>
                                            <FontAwesomeIcon
                                                icon={faBug}
                                                className='subMenuIcon'
                                            /> Create JIRA
                                        </MenuItem>
                                    </Menu>
                                },
                                notice: (row) => row.notice === null ? (<MySpinner />) : (
                                    <Form.Control
                                        type="text"
                                        value={row.notice}
                                        readOnly={true}
                                    />),
                            }}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        {this.state.diffCurrent !== this.state.diffSteps && (
                            <Row>
                                <Col>
                                    <ProgressBar
                                        bgcolor="#1f4503"
                                        completed={this.state.diffSteps ? ((this.state.diffCurrent * 100) / this.state.diffSteps) : 0}
                                    />
                                </Col>
                            </Row>
                        )}
                        <MyTable
                            name="diagnostic-rejected-products-diffs"
                            dynamic={false}
                            ref={this.tableRefDiffs}
                            sortBy="website"
                            sortOrder="asc"
                            headers={
                                [
                                    {
                                        width: "150px",
                                        field: "website",
                                        label: "Website",
                                        sortable: true,
                                        searchable: true
                                    },
                                    {
                                        width: "75px",
                                        field: "identifier",
                                        label: "Identifier",
                                        sortable: true,
                                        searchable: true,
                                    },
                                    {
                                        width: "75px",
                                        field: "upc",
                                        label: "UPC",
                                        sortable: true,
                                        searchable: true,
                                    },
                                    {
                                        width: "75px",
                                        field: "sku",
                                        label: "SKU",
                                        sortable: true,
                                        searchable: true
                                    },
                                    {
                                        width: "250px",
                                        field: "title",
                                        label: "Title",
                                        sortable: true,
                                        searchable: true
                                    },
                                    {
                                        field: "currency",
                                        label: "$",
                                        width: "1px"
                                    },
                                    {
                                        field: "notice",
                                        label: "New notice",
                                        searchable: true
                                    },
                                    {
                                        field: "changed",
                                        label: "Changed",
                                        hint: "Has the Notice field changed ever since?",
                                        sortable: true,
                                        allowEmpty: true,
                                        options: [{ label: "No", value: "0" }, { label: "Yes", value: "1" }],
                                        width: "80px"
                                    },
                                    {
                                        field: "fixed",
                                        label: "Fixed",
                                        hint: "Are we crawling this product today?",
                                        sortable: true,
                                        allowEmpty: true,
                                        options: [{ label: "No", value: "0" }, { label: "Yes", value: "1" }],
                                        width: "80px"
                                    }
                                ]
                            }
                            renderColumns={{
                                website: (row) => <Link className="link" to={`/website/${row.website_id}`} target="_blank">(#{row.website_id}) {row.website}</Link>,
                                identifier: (row) => <Link className="link" to={`/product/${row.pid}`} target="_blank">{row.identifier}</Link>,
                                upc: (row) => <Link className="link" to={`/product/${row.pid}`} target="_blank">{row.upc}</Link>,
                                sku: (row) => <Link className="link" to={`/product/${row.pid}`} target="_blank">{row.sku}</Link>,
                                title: (row) => <Form.Control
                                    type="text"
                                    value={row.title}
                                    readOnly={true}
                                />,
                                currency: {
                                    format: (row) => {
                                        const currency = Currencies.CodeByID(row.currency_id).toLowerCase();
                                        return <div className={`currency-flag currency-flag-${currency}`}></div>
                                    },
                                    csv: (row) => Currencies.CodeByID(row.currency_id)
                                },
                                notice: (row) => row.notice === null ? <MySpinner /> : (
                                    row.changed === 1 && row.fixed === 0 ? <Form.Control
                                        type="text"
                                        value={row.notice}
                                        readOnly={true}
                                    /> : null),
                                fixed: {
                                    format: (row) => row.fixed === null ? null : <span>{row.fixed === 1 ? <FontAwesomeIcon icon={faCheck} className="faSwitch checked" /> : <FontAwesomeIcon icon={faTimes} className="faSwitch" />}</span>,
                                    className: "text-center",
                                    csv: (row) => row.fixed === 1 ? 'Yes' : 'No'
                                },
                                changed: {
                                    format: (row) => row.changed === null ? null : <span>{row.changed === 1 ? <FontAwesomeIcon icon={faCheck} className="faSwitch checked" /> : <FontAwesomeIcon icon={faTimes} className="faSwitch" />}</span>,
                                    className: "text-center",
                                    csv: (row) => row.changed === 1 ? 'Yes' : 'No'
                                },
                            }}
                        />
                    </Carousel.Item>
                </MyCarousel>
            </Container >
        );
    }
}
export default withRouter(Index);
