import React, { createRef } from "react";

import { Container, Breadcrumb, Button, Modal, Row, Col, Form } from "react-bootstrap";
import Toast from "../../services/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import confirm from "../../components/Confirm";
import MyTable from "../../components/MyTable";
import ProcessingButton from "../../components/ProcessingButton";
import DropDown from "../../components/DropDown";
import Utils from "../../services/Utils";
import DoNotMapService from "../../services/DoNotMapService";
import MySpinner from "../../components/MySpinner";

/**
 * Do Not Map component
 * @component
 * @category Others
 */
class DoNotMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loading: true, edit: null, saving: false, canSubmit: false }

        this.tableRef = createRef();

        this.handleChange = this.handleChange.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
    }

    componentWillUnmount() {
    }

    downloadSpreadsheet(evt) {
        this.tableRef.current.CSV()
    }

    componentDidMount() {
        DoNotMapService.all().then((data) => {
            if (data === false) {
                Toast.error(
                    "Something went wrong while retrieving the list of rows"
                );
                return;
            }
            data.forEach((row, index) => {
                row.rid = typeof row.id === "undefined" || !row.id ? null : row.id;
                row.id = index;
                row.status = row.status === 1 ? 'Active' : 'Disabled'
            });
            this.tableRef.current?.populate(data);
        });
    }

    simpleText(reason) {
        if (reason === 1) {
            Toast.info("Semi-Automated Website");
        } else if (reason === 2) {
            Toast.info("BDO Website");
        }
        return false;
    }

    canSubmit(row) {
        return row.domain.length > 3 && Utils.isValidDomain(row.domain);
    }

    handleChange(evt) {
        let field = evt.target.getAttribute('name');
        let edit = this.state.edit;
        edit[field] = field === "reason" ? parseInt(evt.target.value) : evt.target.value;
        this.setState({ edit, canSubmit: this.canSubmit(edit) });
    }

    handleEdit(evt) {
        evt.stopPropagation();
        evt.preventDefault();
        let obj = this.tableRef.current.getRowFromEvent(evt);
        if (typeof obj.row.collapse === "string") {
            obj.row.note = obj.row.collapse;
            this.setState({
                edit: JSON.parse(JSON.stringify(obj.row)),
                canSubmit: true
            });
        } else {
            obj.row.loading = true;
            this.tableRef.current.updateRow(obj.row);
            DoNotMapService.note(obj.row.rid).then((result) => {
                if (result === false) {
                    Toast.error("Failed to load notes from this row");
                    return;
                }
                obj.row.collapse = obj.row.note = result;
                delete obj.row.loading;
                this.tableRef.current.updateRow(obj.row);
                this.setState({
                    edit: JSON.parse(JSON.stringify(obj.row)),
                    canSubmit: true
                });
            });
        }
    }

    handleSave() {
        const id = this.state.edit.rid;

        this.setState({ saving: true }, () => {
            if (id === null) {
                DoNotMapService.new(this.state.edit).then((result) => {
                    if (result === false) {
                        Toast.error("Something went wrong while adding this domain. Perhaps it's already in the list?", 5000);
                        this.setState({ saving: false })
                        return;
                    }
                    this.setState({
                        edit: null,
                        saving: false
                    }, () => {
                        result.rid = result.id;
                        result.id = this.tableRef.current.numberOfItems();
                        this.tableRef.current.addRow(result);
                    });
                });
                return;
            }
            DoNotMapService.save(id, this.state.edit).then((result) => {
                if (result === false) {
                    Toast.error("Something went wrong while saving changes");
                    this.setState({ saving: false })
                    return;
                }
                let row = JSON.parse(JSON.stringify(this.state.edit));
                row.reason = parseInt(row.reason);
                row.collapse = row.note;
                this.setState({
                    edit: null,
                    saving: false
                }, () => {
                    this.tableRef.current.updateRow(row);
                });
            });
        });
    }

    async handleDelete(evt) {
        evt.stopPropagation();
        evt.preventDefault();
        let obj = this.tableRef.current.getRowFromEvent(evt);
        if (!await confirm('Are you sure?', 'Yes', 'Cancel', {
            message: "You're' about to remove the domain " + obj.row.domain + ". This operation can't be undone. Would you like to proceed?"
        })) {
            return;
        }

        this.tableRef.current.setLoading(true);
        DoNotMapService.delete(obj.row.rid).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while removing this domain");
                this.tableRef.current.setLoading(false);
            } else {
                this.tableRef.current.deleteRow(obj.row);
            }
        });
    }

    render() {
        const convert = (n) => {
            switch (parseInt(n)) {
                case 0: return 'Other';
                case 1: return 'SAW';
                case 2: return 'BDO';
                case 3: return 'Redirects';
                default: return '';
            }
        }

        return (
            <Container className="pt-4">
                <span style={{
                    float: "right"
                }}
                >
                    <Button
                        size="sm"
                        className="tdButton"
                        variant="success"
                        label="Download CSV"
                        onClick={this.downloadSpreadsheet}
                    >
                        <FontAwesomeIcon icon={faDownload} /> Download
                    </Button>
                </span>
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Others</Breadcrumb.Item>
                    <Breadcrumb.Item active>Do Not Map</Breadcrumb.Item>
                </Breadcrumb>
                <Modal
                    size="lg"
                    show={this.state.edit !== null}
                    backdrop={true}
                    keyboard={true}
                    centered
                >
                    <Modal.Header closeButton onHide={() => this.setState({ edit: null })}>
                        <Modal.Title>
                            {this.state.edit !== null && this.state.edit.rid === null ? "Add" : "Edit"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={8}>
                                <Form.Label>Domain</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="domain"
                                    value={this.state.edit !== null ? this.state.edit.domain : ''}
                                    readOnly={this.state.edit === null || this.state.saving}
                                    onChange={this.handleChange}
                                />
                            </Col>
                            <Col sm={4}>
                                <Form.Label>Reason</Form.Label>
                                <DropDown
                                    allowEmpty={false}
                                    selected={this.state.edit !== null ? this.state.edit.reason : 0}
                                    options={[{ value: 0, label: 'Other' }, { value: 3, label: 'Redirects' }]}
                                    onChange={(evt) => { let edit = this.state.edit; edit.reason = evt.target.value; this.setState({ edit }) }}
                                    disabled={this.state.edit === null || this.state.saving}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Notes</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="note"
                                    value={this.state.edit !== null ? this.state.edit.note : ''}
                                    onChange={this.handleChange}
                                    readOnly={this.state.edit === null || this.state.saving}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer style={{
                        justifyContent: "center"
                    }}>
                        <Button size="sm" variant="secondary" onClick={() => this.setState({ edit: null })}>Cancel</Button>
                        <ProcessingButton
                            size="sm"
                            disabled={!this.state.canSubmit}
                            processing={this.state.saving}
                            processingLabel="Saving ..."
                            label="Save"
                            onClick={this.handleSave}
                        />
                    </Modal.Footer>
                </Modal >
                <MyTable
                    name="do-not-map"
                    ref={this.tableRef}
                    dynamic={false}
                    sortBy="domain"
                    sortOrder="asc"
                    headers={[
                        {
                            field: "domain",
                            label: "Domain",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "bucket",
                            label: "Bucket",
                            sortable: true,
                            searchable: true,
                            width: "150px",
                        },
                        {
                            field: "mode",
                            label: "PD Mode",
                            sortable: true,
                            allowEmpty: true,
                            options: ['UPC', 'BDO', 'NPD', 'DESCOBRIDOR', 'DIRECT', 'PDD'],
                            width: "125px",
                        },
                        {
                            field: "reason",
                            label: "Reason",
                            sortable: true,
                            allowEmpty: true,
                            width: "120px",
                            options: ['SAW', 'BDO', 'Redirects', 'Other']
                        },
                        {
                            field: "region",
                            label: "Region",
                            sortable: true,
                            searchable: true,
                            width: "120px",
                        },
                        {
                            field: "status",
                            label: "Status",
                            sortable: true,
                            allowEmpty: true,
                            width: "120px",
                            options: ['Active', 'Disabled']
                        },
                        {
                            field: "control",
                            width: "87px",
                            className: "text-center",
                            content: <Button
                                onClick={() => this.setState({
                                    edit: {
                                        rid: null,
                                        domain: '',
                                        reason: 0,
                                        note: ''
                                    },
                                    canSubmit: false
                                })}
                                size="sm"
                                className="tdButton"
                                variant="success">
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        }
                    ]}
                    collapse={(row) => row.reason >= 1 && row.reason <= 2 ? this.simpleText(row.reason) : new Promise((resolve, rejected) => {
                        DoNotMapService.note(row.rid).then((result) => {
                            if (result === false) {
                                Toast.error("Failed to load notes from this row");
                                rejected();
                                return;
                            }
                            resolve(result)
                        });
                    })}
                    collapseStyle={{
                        fontFamily: "Courier New, monospace",
                        whiteSpace: "pre",
                        fontSize: "x-small",
                        padding: "10px"
                    }}
                    renderColumns={{
                        reason: {
                            format: (row) => convert(row.reason),
                            text: (row) => convert(row.reason),
                            csv: (row) => convert(row.reason)
                        },
                        mode: {
                            className: "text-center",
                            format: (row) => typeof row.mode === "string" ? row.mode.toUpperCase() : '',
                            csv: (row) => typeof row.mode === "string" ? row.mode.toUpperCase() : '',
                        },
                        control: {
                            className: "text-center",
                            format: (row) => row.reason >= 1 && row.reason <= 2 ? null : <React.Fragment>
                                {typeof row.loading === "boolean" && row.loading && (
                                    <MySpinner />
                                )}
                                {typeof row.loading === "undefined" && (
                                    <Button
                                        onClick={this.handleEdit}
                                        size="sm"
                                        className="tdButton"
                                        variant="primary">
                                        <FontAwesomeIcon icon={faPen} />
                                    </Button>
                                )}
                                <Button
                                    onClick={this.handleDelete}
                                    size="sm"
                                    className="tdButton"
                                    variant="danger">
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </React.Fragment>
                        }
                    }}
                />
            </Container >
        );
    }
}

export default DoNotMap;
