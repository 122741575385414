import React from "react";
import PropTypes from "prop-types";

import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { NavLink, withRouter } from "react-router-dom";

import logo from "../images/logo.png";
import "./TopMenu.css";
import {
    Menu,
    MenuItem,
    SubMenu
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import Version from "../components/Version";
import Auth from "../services/AuthService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faCheckSquare, faFileInvoiceDollar, faMoneyBill, faProjectDiagram, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { PERMISSION_TYPE } from "../services/UsersPermission";

const NavMenu = (props) => {
    const { user, goTo, setState } = props;

    if (!user) return null;

    const menuItem = (key, label, href, icon, permission) => {
        if (permission && !Auth.hasAccessTo(permission)) return null;
        const i = icon ? <FontAwesomeIcon icon={icon} className="mr-10" /> : null;
        return <MenuItem key={key} onClick={() => { goTo(href) }}>
            {i}{label}
        </MenuItem>
    }

    const subMenu = (key, label, children, permission) => {
        if (permission && !Auth.hasAccessTo(permission)) return null;
        return <SubMenu key={key} label={label}>
            {elements(children, key)}
        </SubMenu>
    }

    const entryItem = (index, label, children, permission) => {
        if (permission && !Auth.hasAccessTo(permission)) return null;
        const key = 'menu-' + index;
        return <Menu key={key} menuButton={
            <Nav.Item>
                <div className="dropdown-toggle nav-link">{label}</div>
            </Nav.Item>
        }>
            {elements(children, key)}
        </Menu>
    }

    const elements = (list, parentKey) => {
        return list.map((element, index) => {
            const key = parentKey + '-' + index;
            if (element.children) {
                return subMenu(key, element.label, element.children, element.permission);
            }
            return menuItem(key, element.label, element.href, element.icon, element.permission);
        });
    }

    const MenuMap = props.structure.map((item, index) => {
        return entryItem(index, item.label, item.children, item.permission);
    });

    return <Navbar.Collapse>
        <Nav className="me-auto">
            {MenuMap}
        </Nav>
        <Nav>
            <NavDropdown title={<div className="thumb">
                <span className="name">{user.name}</span>
                <span
                    className="user"
                    style={{
                        backgroundImage: `url(${user.picture})`,
                    }}
                ></span>
            </div>}>
                <NavDropdown.Item as={NavLink} to="/user/preferences" >Preferences</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/logs" className={!Auth.hasAccessTo(PERMISSION_TYPE.LOGS) ? 'hidden' : null}>Logs</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/releases">Release Notes</NavDropdown.Item>
                <NavDropdown.Item onClick={() => { setState({ showVersion: true }) }}>Versions</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={NavLink} to="/user/logout">Logout</NavDropdown.Item>
            </NavDropdown>
        </Nav>
    </Navbar.Collapse>
}

const map = [
    {
        label: "Profiles",
        children: [
            { label: "Website", href: "/website" },
            { label: "Manufacturer", href: "/manufacturer" },
            { label: "Merchant", href: "/merchant" },
            { label: "Integration Partners", href: "/partner" },
            { label: "Crawler Details", href: "/crawler-details" },
        ]
    },
    {
        label: "Clients",
        children: [
            { label: "Organization", href: "/organization" },
            { label: "User", href: "/user" },
            { label: "Dashboard", href: "/brand" },
            { label: "Product", href: "/product" },
        ]
    },
    {
        label: "Discovery",
        children: [
            { label: "ASIN", href: "/ad/jobs" },
            {
                label: "Domain",
                children: [
                    { label: "Jobs", href: "/dd" },
                    {
                        label: "Blacklist",
                        children: [
                            { label: "TLD", href: "/dd/blacklist/tld" },
                            { label: "Domain", href: "/dd/blacklist/domain" },
                            { label: "Subdomain", href: "/dd/blacklist/subdomain" },
                        ]
                    },
                    { label: "E-commerce Override", href: "/dd/override" },
                    { label: "Logs", href: "/dd/logs" },
                ]
            },
            {
                label: "PDD",
                permission: PERMISSION_TYPE.PDD,
                children: [
                    { label: "Jobs", href: "/pdd/jobs" },
                    { label: "Manual Review", href: "/pdd/review" },
                    { label: "Disabled Dashboards", href: "/pdd/disabled" },
                ]
            },
            { label: "Image", href: "/imgdiscovery/jobs" },
        ]
    },
    {
        label: "Diagnostics",
        children: [
            { label: "Rejected Products", href: "/diags/rp" },
            { label: "Missing Websites", href: "/diags/mw" },
            { label: "Price Violations", href: "/diags/price-violations" },
        ]
    },
    {
        label: "Onboarding",
        children: [
            { label: "Bucketization", href: "/onboarding/bucketization" },
            { label: "Bucketization - Google Shopping PD", href: "/onboarding/bucketization/gs-pd" },
            { label: "XPath", href: "/onboarding/xpath" },
        ]
    },
    {
        label: "Others",
        children: [
            {
                label: "URL",
                children: [
                    { label: "Mappings", href: "/others/mappings", icon: faProjectDiagram },
                    { label: "Uploader", href: "/others/url", icon: faUpload },
                    { label: "Checker", href: "/others/urlchecker", icon: faCheckSquare },
                    { label: "Remover", href: "/others/urlremover", icon: faTrash },
                ]
            },
            {
                label: "Cost Explorer",
                permission: PERMISSION_TYPE.COSTS,
                children: [
                    { label: "Bills of Last Week", href: "/costexplorer/bills-last-week", icon: faMoneyBill },
                    { label: "Bills of Last Semester", href: "/costexplorer/bills-last-semester", icon: faMoneyBill },
                    { label: "Formula", href: "/costexplorer/formula", icon: faBook },
                    { label: "Asset Cost per Entity", href: "/costexplorer/asset-entity", icon: faFileInvoiceDollar },
                    { label: "Cost per Entity", href: "/costexplorer/entity", icon: faFileInvoiceDollar },
                ]
            },
            { label: "Crawling Scheduler", href: "/others/scheduler" },
            { label: "Do Not Map", href: "/others/donotmap" },
            {
                label: "Product Discovery",
                children: [
                    { label: "Product Discovery Success", href: "/others/pd" },
                    {
                        label: "NPD",
                        permission: PERMISSION_TYPE.NPD,
                        children: [
                            { label: "Smart Selection", href: "/npd/smartselection" },
                            { label: "Autofeeder", href: "/npd/autofeeder" },
                            { label: "Gauges", href: "/npd/gauges" },
                            { label: "Crawler", href: "/npd/crawler" },
                            { label: "Matcher", href: "/npd/matcher" },
                            { label: "Deliveries per Website", href: "/npd/websites" },
                        ]
                    },
                    { label: "Product Discovery Analyzer", href: "/others/product-discovery-analyzer" },
                ],
            },
        ]
    }
];

/**
 * Show the top menu based on the user context (it should hide protected options from unauthenticated users)
 * @component
 */
class TopMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showVersion: false,
        }
    }

    render() {
        return <Navbar id="topMenu" expand="lg" >
            <Version show={this.state.showVersion} onClose={() => { this.setState({ showVersion: false }) }} />
            <Container>
                <Navbar.Brand as={NavLink} to="/"><img src={logo} alt="Logo" /></Navbar.Brand>
                <Navbar.Toggle />
                <NavMenu
                    user={this.props.user}
                    goTo={this.props.history.push}
                    setState={this.setState.bind(this)}
                    structure={map}
                />
            </Container>
        </Navbar>
    }
}

TopMenu.propTypes = {
    /** Authenticated user object */
    user: PropTypes.any.isRequired,
};

export default withRouter(TopMenu);
