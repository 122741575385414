import React from "react";
import PropTypes from "prop-types";

import MultiSelect from "react-multi-select-component";
import ReactCountryFlag from "react-country-flag";
import "./Countries.css";

/**
 * Shows a Countries MultiSelect field
 * @component
 */
class Countries extends React.Component {
    /**
     * All supported countries
     */
    static OPTIONS = [
        { label: "Afghanistan", value: 3 },
        { label: "Åland Islands", value: 15 },
        { label: "Albania", value: 6 },
        { label: "Algeria", value: 62 },
        { label: "American Samoa", value: 11 },
        { label: "Andorra", value: 1 },
        { label: "Angola", value: 8 },
        { label: "Anguilla", value: 5 },
        { label: "Antarctica", value: 9 },
        { label: "Antigua and Barbuda", value: 4 },
        { label: "Argentina", value: 10 },
        { label: "Armenia", value: 7 },
        { label: "Aruba", value: 14 },
        { label: "Australia", value: 13 },
        { label: "Austria", value: 12 },
        { label: "Azerbaijan", value: 16 },
        { label: "Bahamas", value: 32 },
        { label: "Bahrain", value: 23 },
        { label: "Bangladesh", value: 19 },
        { label: "Barbados", value: 18 },
        { label: "Belarus", value: 36 },
        { label: "Belgium", value: 20 },
        { label: "Belize", value: 37 },
        { label: "Benin", value: 25 },
        { label: "Bermuda", value: 27 },
        { label: "Bhutan", value: 33 },
        { label: "Bolivia", value: 29 },
        { label: "Bonaire, Sint Eustatius and Saba", value: 30 },
        { label: "Bosnia and Herzegovina", value: 17 },
        { label: "Botswana", value: 35 },
        { label: "Bouvet Island", value: 34 },
        { label: "Brazil", value: 31 },
        { label: "British Indian Ocean Territory", value: 106 },
        { label: "British Virgin Islands", value: 239 },
        { label: "Brunei Darussalam", value: 28 },
        { label: "Bulgaria", value: 22 },
        { label: "Burkina Faso", value: 21 },
        { label: "Burundi", value: 24 },
        { label: "Cambodia", value: 117 },
        { label: "Cameroon", value: 47 },
        { label: "Canada", value: 38 },
        { label: "Cape Verde", value: 52 },
        { label: "Cayman Islands", value: 124 },
        { label: "Central African Republic", value: 41 },
        { label: "Chad", value: 215 },
        { label: "Chile", value: 46 },
        { label: "China", value: 48 },
        { label: "Christmas Island", value: 54 },
        { label: "Cocos [Keeling] Islands", value: 39 },
        { label: "Colombia", value: 49 },
        { label: "Comoros", value: 119 },
        { label: "Cook Islands", value: 45 },
        { label: "Costa Rica", value: 50 },
        { label: "Croatia", value: 98 },
        { label: "Cuba", value: 51 },
        { label: "Curaçao", value: 53 },
        { label: "Cyprus", value: 55 },
        { label: "Czech Republic", value: 56 },
        { label: "Democratic Republic of the Congo", value: 40 },
        { label: "Denmark", value: 59 },
        { label: "Djibouti", value: 58 },
        { label: "Dominica", value: 60 },
        { label: "Dominican Republic", value: 61 },
        { label: "East Timor", value: 221 },
        { label: "Ecuador", value: 63 },
        { label: "Egypt", value: 65 },
        { label: "El Salvador", value: 210 },
        { label: "Equatorial Guinea", value: 88 },
        { label: "Eritrea", value: 67 },
        { label: "Estonia", value: 64 },
        { label: "Ethiopia", value: 69 },
        { label: "Falkland Islands", value: 72 },
        { label: "Faroe Islands", value: 74 },
        { label: "Fiji", value: 71 },
        { label: "Finland", value: 70 },
        { label: "France", value: 75 },
        { label: "French Guiana", value: 80 },
        { label: "French Polynesia", value: 175 },
        { label: "French Southern Territories", value: 216 },
        { label: "Gabon", value: 76 },
        { label: "Gambia", value: 85 },
        { label: "Georgia", value: 79 },
        { label: "Germany", value: 57 },
        { label: "Ghana", value: 82 },
        { label: "Gibraltar", value: 83 },
        { label: "Greece", value: 89 },
        { label: "Greenland", value: 84 },
        { label: "Grenada", value: 78 },
        { label: "Guadeloupe", value: 87 },
        { label: "Guam", value: 92 },
        { label: "Guatemala", value: 91 },
        { label: "Guernsey", value: 81 },
        { label: "Guinea", value: 86 },
        { label: "Guinea-Bissau", value: 93 },
        { label: "Guyana", value: 94 },
        { label: "Haiti", value: 99 },
        { label: "Heard Island and McDonald Islands", value: 96 },
        { label: "Honduras", value: 97 },
        { label: "Hong Kong", value: 95 },
        { label: "Hungary", value: 100 },
        { label: "Iceland", value: 109 },
        { label: "India", value: 105 },
        { label: "Indonesia", value: 101 },
        { label: "Iran", value: 108 },
        { label: "Iraq", value: 107 },
        { label: "Ireland", value: 102 },
        { label: "Isle of Man", value: 104 },
        { label: "Israel", value: 103 },
        { label: "Italy", value: 110 },
        { label: "Ivory Coast", value: 44 },
        { label: "Jamaica", value: 112 },
        { label: "Japan", value: 114 },
        { label: "Jersey", value: 111 },
        { label: "Jordan", value: 113 },
        { label: "Kazakhstan", value: 125 },
        { label: "Kenya", value: 115 },
        { label: "Kiribati", value: 118 },
        { label: "Kosovo", value: 245 },
        { label: "Kuwait", value: 123 },
        { label: "Kyrgyzstan", value: 116 },
        { label: "Laos", value: 126 },
        { label: "Latvia", value: 135 },
        { label: "Lebanon", value: 127 },
        { label: "Lesotho", value: 132 },
        { label: "Liberia", value: 131 },
        { label: "Libya", value: 136 },
        { label: "Liechtenstein", value: 129 },
        { label: "Lithuania", value: 133 },
        { label: "Luxembourg", value: 134 },
        { label: "Macao", value: 148 },
        { label: "Madagascar", value: 142 },
        { label: "Malawi", value: 156 },
        { label: "Malaysia", value: 158 },
        { label: "Maldives", value: 155 },
        { label: "Mali", value: 145 },
        { label: "Malta", value: 153 },
        { label: "Marshall Islands", value: 143 },
        { label: "Martinique", value: 150 },
        { label: "Mauritania", value: 151 },
        { label: "Mauritius", value: 154 },
        { label: "Mayotte", value: 247 },
        { label: "Mexico", value: 157 },
        { label: "Micronesia", value: 73 },
        { label: "Moldova", value: 139 },
        { label: "Monaco", value: 138 },
        { label: "Mongolia", value: 147 },
        { label: "Montenegro", value: 140 },
        { label: "Montserrat", value: 152 },
        { label: "Morocco", value: 137 },
        { label: "Mozambique", value: 159 },
        { label: "Myanmar [Burma]", value: 146 },
        { label: "Namibia", value: 160 },
        { label: "Nauru", value: 169 },
        { label: "Nepal", value: 168 },
        { label: "Netherlands", value: 166 },
        { label: "New Caledonia", value: 161 },
        { label: "New Zealand", value: 171 },
        { label: "Nicaragua", value: 165 },
        { label: "Niger", value: 162 },
        { label: "Nigeria", value: 164 },
        { label: "Niue", value: 170 },
        { label: "Norfolk Island", value: 163 },
        { label: "North Korea", value: 121 },
        { label: "North Macedonia", value: 144 },
        { label: "Northern Mariana Islands", value: 149 },
        { label: "Norway", value: 167 },
        { label: "Oman", value: 172 },
        { label: "Pakistan", value: 178 },
        { label: "Palau", value: 185 },
        { label: "Palestine", value: 183 },
        { label: "Panama", value: 173 },
        { label: "Papua New Guinea", value: 176 },
        { label: "Paraguay", value: 186 },
        { label: "Peru", value: 174 },
        { label: "Philippines", value: 177 },
        { label: "Pitcairn Islands", value: 181 },
        { label: "Poland", value: 179 },
        { label: "Portugal", value: 184 },
        { label: "Puerto Rico", value: 182 },
        { label: "Qatar", value: 187 },
        { label: "Republic of the Congo", value: 42 },
        { label: "Réunion", value: 188 },
        { label: "Romania", value: 189 },
        { label: "Russia", value: 191 },
        { label: "Rwanda", value: 192 },
        { label: "Saint Barthélemy", value: 26 },
        { label: "Saint Helena", value: 199 },
        { label: "Saint Kitts and Nevis", value: 120 },
        { label: "Saint Lucia", value: 128 },
        { label: "Saint Martin", value: 141 },
        { label: "Saint Pierre and Miquelon", value: 180 },
        { label: "Saint Vincent and the Grenadines", value: 237 },
        { label: "Samoa", value: 244 },
        { label: "San Marino", value: 204 },
        { label: "São Tomé and Príncipe", value: 209 },
        { label: "Saudi Arabia", value: 193 },
        { label: "Senegal", value: 205 },
        { label: "Serbia", value: 190 },
        { label: "Seychelles", value: 195 },
        { label: "Sierra Leone", value: 203 },
        { label: "Singapore", value: 198 },
        { label: "Sint Maarten", value: 211 },
        { label: "Slovakia", value: 202 },
        { label: "Slovenia", value: 200 },
        { label: "Solomon Islands", value: 194 },
        { label: "Somalia", value: 206 },
        { label: "South Africa", value: 248 },
        { label: "South Georgia and the South Sandwich Islands", value: 90 },
        { label: "South Korea", value: 122 },
        { label: "South Sudan", value: 208 },
        { label: "Spain", value: 68 },
        { label: "Sri Lanka", value: 130 },
        { label: "Sudan", value: 196 },
        { label: "Suriname", value: 207 },
        { label: "Svalbard and Jan Mayen", value: 201 },
        { label: "Swaziland", value: 213 },
        { label: "Sweden", value: 197 },
        { label: "Switzerland", value: 43 },
        { label: "Syria", value: 212 },
        { label: "Taiwan", value: 228 },
        { label: "Tajikistan", value: 219 },
        { label: "Tanzania", value: 229 },
        { label: "Thailand", value: 218 },
        { label: "Togo", value: 217 },
        { label: "Tokelau", value: 220 },
        { label: "Tonga", value: 224 },
        { label: "Trinidad and Tobago", value: 226 },
        { label: "Tunisia", value: 223 },
        { label: "Turkey", value: 225 },
        { label: "Turkmenistan", value: 222 },
        { label: "Turks and Caicos Islands", value: 214 },
        { label: "Tuvalu", value: 227 },
        { label: "U.S. Minor Outlying Islands", value: 232 },
        { label: "U.S. Virgin Islands", value: 240 },
        { label: "Uganda", value: 231 },
        { label: "Ukraine", value: 230 },
        { label: "United Arab Emirates", value: 2 },
        {
            label: "United Kingdom of Great Britain and Northern Ireland",
            value: 77,
        },
        { label: "United States", value: 233 },
        { label: "Uruguay", value: 234 },
        { label: "Uzbekistan", value: 235 },
        { label: "Vanuatu", value: 242 },
        { label: "Vatican City", value: 236 },
        { label: "Venezuela", value: 238 },
        { label: "Vietnam", value: 241 },
        { label: "Wallis and Futuna", value: 243 },
        { label: "Western Sahara", value: 66 },
        { label: "Yemen", value: 246 },
        { label: "Zambia", value: 249 },
        { label: "Zimbabwe", value: 250 },
    ];

    static #map = {
        1: { label: "Andorra", code: "AD", currency: "EUR" },
        2: { label: "United Arab Emirates", code: "AE", currency: "AED" },
        3: { label: "Afghanistan", code: "AF", currency: "AFN" },
        4: { label: "Antigua and Barbuda", code: "AG", currency: "XCD" },
        5: { label: "Anguilla", code: "AI", currency: "XCD" },
        6: { label: "Albania", code: "AL", currency: "ALL" },
        7: { label: "Armenia", code: "AM", currency: "AMD" },
        8: { label: "Angola", code: "AO", currency: "AOA" },
        9: { label: "Antarctica", code: "AQ", currency: "" },
        10: { label: "Argentina", code: "AR", currency: "ARS" },
        11: { label: "American Samoa", code: "AS", currency: "USD" },
        12: { label: "Austria", code: "AT", currency: "EUR" },
        13: { label: "Australia", code: "AU", currency: "AUD" },
        14: { label: "Aruba", code: "AW", currency: "AWG" },
        15: { label: "Åland Islands", code: "AX", currency: "EUR" },
        16: { label: "Azerbaijan", code: "AZ", currency: "AZN" },
        17: { label: "Bosnia and Herzegovina", code: "BA", currency: "BAM" },
        18: { label: "Barbados", code: "BB", currency: "BBD" },
        19: { label: "Bangladesh", code: "BD", currency: "BDT" },
        20: { label: "Belgium", code: "BE", currency: "EUR" },
        21: { label: "Burkina Faso", code: "BF", currency: "XOF" },
        22: { label: "Bulgaria", code: "BG", currency: "BGN" },
        23: { label: "Bahrain", code: "BH", currency: "BHD" },
        24: { label: "Burundi", code: "BI", currency: "BIF" },
        25: { label: "Benin", code: "BJ", currency: "XOF" },
        26: { label: "Saint Barthélemy", code: "BL", currency: "EUR" },
        27: { label: "Bermuda", code: "BM", currency: "BMD" },
        28: { label: "Brunei Darussalam", code: "BN", currency: "BND" },
        29: { label: "Bolivia", code: "BO", currency: "BOB" },
        30: { label: "Bonaire, Sint Eustatius and Saba", code: "BQ", currency: "USD" },
        31: { label: "Brazil", code: "BR", currency: "BRL" },
        32: { label: "Bahamas", code: "BS", currency: "BSD" },
        33: { label: "Bhutan", code: "BT", currency: "BTN" },
        34: { label: "Bouvet Island", code: "BV", currency: "NOK" },
        35: { label: "Botswana", code: "BW", currency: "BWP" },
        36: { label: "Belarus", code: "BY", currency: "BYR" },
        37: { label: "Belize", code: "BZ", currency: "BZD" },
        38: { label: "Canada", code: "CA", currency: "CAD" },
        39: { label: "Cocos [Keeling] Islands", code: "CC", currency: "AUD" },
        40: { label: "Democratic Republic of the Congo", code: "CD", currency: "COD" },
        41: { label: "Central African Republic", code: "CF", currency: "XAF" },
        42: { label: "Republic of the Congo", code: "CG", currency: "XAF" },
        43: { label: "Switzerland", code: "CH", currency: "CHF" },
        44: { label: "Ivory Coast", code: "CI", currency: "XOF" },
        45: { label: "Cook Islands", code: "CK", currency: "NZD" },
        46: { label: "Chile", code: "CL", currency: "CLP" },
        47: { label: "Cameroon", code: "CM", currency: "XAF" },
        48: { label: "China", code: "CN", currency: "CNY" },
        49: { label: "Colombia", code: "CO", currency: "COP" },
        50: { label: "Costa Rica", code: "CR", currency: "CRC" },
        51: { label: "Cuba", code: "CU", currency: "CUP" },
        52: { label: "Cape Verde", code: "CV", currency: "CVE" },
        53: { label: "Curaçao", code: "CW", currency: "ANG" },
        54: { label: "Christmas Island", code: "CX", currency: "AUD" },
        55: { label: "Cyprus", code: "CY", currency: "EUR" },
        56: { label: "Czech Republic", code: "CZ", currency: "CZK" },
        57: { label: "Germany", code: "DE", currency: "EUR" },
        58: { label: "Djibouti", code: "DJ", currency: "DJF" },
        59: { label: "Denmark", code: "DK", currency: "DKK" },
        60: { label: "Dominica", code: "DM", currency: "XCD" },
        61: { label: "Dominican Republic", code: "DO", currency: "DOP" },
        62: { label: "Algeria", code: "DZ", currency: "DZD" },
        63: { label: "Ecuador", code: "EC", currency: "USD" },
        64: { label: "Estonia", code: "EE", currency: "EUR" },
        65: { label: "Egypt", code: "EG", currency: "EGP" },
        66: { label: "Western Sahara", code: "EH", currency: "MAD" },
        67: { label: "Eritrea", code: "ER", currency: "ERN" },
        68: { label: "Spain", code: "ES", currency: "EUR" },
        69: { label: "Ethiopia", code: "ET", currency: "ETB" },
        70: { label: "Finland", code: "FI", currency: "EUR" },
        71: { label: "Fiji", code: "FJ", currency: "FJD" },
        72: { label: "Falkland Islands", code: "FK", currency: "FKP" },
        73: { label: "Micronesia", code: "FM", currency: "USD" },
        74: { label: "Faroe Islands", code: "FO", currency: "DKK" },
        75: { label: "France", code: "FR", currency: "EUR" },
        76: { label: "Gabon", code: "GA", currency: "XAF" },
        77: { label: "United Kingdom of Great Britain and Northern ", code: "GB", currency: "GBP" },
        78: { label: "Grenada", code: "GD", currency: "XCD" },
        79: { label: "Georgia", code: "GE", currency: "GEL" },
        80: { label: "French Guiana", code: "GF", currency: "EUR" },
        81: { label: "Guernsey", code: "GG", currency: "GBP" },
        82: { label: "Ghana", code: "GH", currency: "GHS" },
        83: { label: "Gibraltar", code: "GI", currency: "GIP" },
        84: { label: "Greenland", code: "GL", currency: "DKK" },
        85: { label: "Gambia", code: "GM", currency: "GMD" },
        86: { label: "Guinea", code: "GN", currency: "GNF" },
        87: { label: "Guadeloupe", code: "GP", currency: "EUR" },
        88: { label: "Equatorial Guinea", code: "GQ", currency: "XAF" },
        89: { label: "Greece", code: "GR", currency: "EUR" },
        90: { label: "South Georgia and the South Sandwich Islands", code: "GS", currency: "GBP" },
        91: { label: "Guatemala", code: "GT", currency: "GTQ" },
        92: { label: "Guam", code: "GU", currency: "USD" },
        93: { label: "Guinea-Bissau", code: "GW", currency: "XOF" },
        94: { label: "Guyana", code: "GY", currency: "GYD" },
        95: { label: "Hong Kong", code: "HK", currency: "HKD" },
        96: { label: "Heard Island and McDonald Islands", code: "HM", currency: "AUD" },
        97: { label: "Honduras", code: "HN", currency: "HNL" },
        98: { label: "Croatia", code: "HR", currency: "HRK" },
        99: { label: "Haiti", code: "HT", currency: "HTG" },
        100: { label: "Hungary", code: "HU", currency: "HUF" },
        101: { label: "Indonesia", code: "ID", currency: "IDR" },
        102: { label: "Ireland", code: "IE", currency: "EUR" },
        103: { label: "Israel", code: "IL", currency: "ILS" },
        104: { label: "Isle of Man", code: "IM", currency: "GBP" },
        105: { label: "India", code: "IN", currency: "INR" },
        106: { label: "British Indian Ocean Territory", code: "IO", currency: "USD" },
        107: { label: "Iraq", code: "IQ", currency: "IQD" },
        108: { label: "Iran", code: "IR", currency: "IRR" },
        109: { label: "Iceland", code: "IS", currency: "ISK" },
        110: { label: "Italy", code: "IT", currency: "EUR" },
        111: { label: "Jersey", code: "JE", currency: "GBP" },
        112: { label: "Jamaica", code: "JM", currency: "JMD" },
        113: { label: "Jordan", code: "JO", currency: "JOD" },
        114: { label: "Japan", code: "JP", currency: "JPY" },
        115: { label: "Kenya", code: "KE", currency: "KES" },
        116: { label: "Kyrgyzstan", code: "KG", currency: "KGS" },
        117: { label: "Cambodia", code: "KH", currency: "KHR" },
        118: { label: "Kiribati", code: "KI", currency: "AUD" },
        119: { label: "Comoros", code: "KM", currency: "KMF" },
        120: { label: "Saint Kitts and Nevis", code: "KN", currency: "XCD" },
        121: { label: "North Korea", code: "KP", currency: "KPW" },
        122: { label: "South Korea", code: "KR", currency: "KRW" },
        123: { label: "Kuwait", code: "KW", currency: "KWD" },
        124: { label: "Cayman Islands", code: "KY", currency: "KYD" },
        125: { label: "Kazakhstan", code: "KZ", currency: "KZT" },
        126: { label: "Laos", code: "LA", currency: "LAK" },
        127: { label: "Lebanon", code: "LB", currency: "LBP" },
        128: { label: "Saint Lucia", code: "LC", currency: "XCD" },
        129: { label: "Liechtenstein", code: "LI", currency: "CHF" },
        130: { label: "Sri Lanka", code: "LK", currency: "LKR" },
        131: { label: "Liberia", code: "LR", currency: "LRD" },
        132: { label: "Lesotho", code: "LS", currency: "LSL" },
        133: { label: "Lithuania", code: "LT", currency: "EUR" },
        134: { label: "Luxembourg", code: "LU", currency: "EUR" },
        135: { label: "Latvia", code: "LV", currency: "EUR" },
        136: { label: "Libya", code: "LY", currency: "LYD" },
        137: { label: "Morocco", code: "MA", currency: "MAD" },
        138: { label: "Monaco", code: "MC", currency: "EUR" },
        139: { label: "Moldova", code: "MD", currency: "MDL" },
        140: { label: "Montenegro", code: "ME", currency: "EUR" },
        141: { label: "Saint Martin", code: "MF", currency: "EUR" },
        142: { label: "Madagascar", code: "MG", currency: "MGA" },
        143: { label: "Marshall Islands", code: "MH", currency: "USD" },
        144: { label: "North Macedonia", code: "MK", currency: "MKD" },
        145: { label: "Mali", code: "ML", currency: "XOF" },
        146: { label: "Myanmar [Burma]", code: "MM", currency: "MMK" },
        147: { label: "Mongolia", code: "MN", currency: "MNT" },
        148: { label: "Macao", code: "MO", currency: "MOP" },
        149: { label: "Northern Mariana Islands", code: "MP", currency: "USD" },
        150: { label: "Martinique", code: "MQ", currency: "EUR" },
        151: { label: "Mauritania", code: "MR", currency: "MRO" },
        152: { label: "Montserrat", code: "MS", currency: "XCD" },
        153: { label: "Malta", code: "MT", currency: "EUR" },
        154: { label: "Mauritius", code: "MU", currency: "MUR" },
        155: { label: "Maldives", code: "MV", currency: "MVR" },
        156: { label: "Malawi", code: "MW", currency: "MWK" },
        157: { label: "Mexico", code: "MX", currency: "MXN" },
        158: { label: "Malaysia", code: "MY", currency: "MYR" },
        159: { label: "Mozambique", code: "MZ", currency: "MZN" },
        160: { label: "Namibia", code: "NA", currency: "NAD" },
        161: { label: "New Caledonia", code: "NC", currency: "XPF" },
        162: { label: "Niger", code: "NE", currency: "XOF" },
        163: { label: "Norfolk Island", code: "NF", currency: "AUD" },
        164: { label: "Nigeria", code: "NG", currency: "NGN" },
        165: { label: "Nicaragua", code: "NI", currency: "NIO" },
        166: { label: "Netherlands", code: "NL", currency: "EUR" },
        167: { label: "Norway", code: "NO", currency: "NOK" },
        168: { label: "Nepal", code: "NP", currency: "NPR" },
        169: { label: "Nauru", code: "NR", currency: "AUD" },
        170: { label: "Niue", code: "NU", currency: "NZD" },
        171: { label: "New Zealand", code: "NZ", currency: "NZD" },
        172: { label: "Oman", code: "OM", currency: "OMR" },
        173: { label: "Panama", code: "PA", currency: "PAB" },
        174: { label: "Peru", code: "PE", currency: "PEN" },
        175: { label: "French Polynesia", code: "PF", currency: "XPF" },
        176: { label: "Papua New Guinea", code: "PG", currency: "PGK" },
        177: { label: "Philippines", code: "PH", currency: "PHP" },
        178: { label: "Pakistan", code: "PK", currency: "PKR" },
        179: { label: "Poland", code: "PL", currency: "PLN" },
        180: { label: "Saint Pierre and Miquelon", code: "PM", currency: "EUR" },
        181: { label: "Pitcairn Islands", code: "PN", currency: "NZD" },
        182: { label: "Puerto Rico", code: "PR", currency: "USD" },
        183: { label: "Palestine", code: "PS", currency: "ILS" },
        184: { label: "Portugal", code: "PT", currency: "EUR" },
        185: { label: "Palau", code: "PW", currency: "USD" },
        186: { label: "Paraguay", code: "PY", currency: "PYG" },
        187: { label: "Qatar", code: "QA", currency: "QAR" },
        188: { label: "Réunion", code: "RE", currency: "EUR" },
        189: { label: "Romania", code: "RO", currency: "RON" },
        190: { label: "Serbia", code: "RS", currency: "RSD" },
        191: { label: "Russia", code: "RU", currency: "RUB" },
        192: { label: "Rwanda", code: "RW", currency: "RWF" },
        193: { label: "Saudi Arabia", code: "SA", currency: "SAR" },
        194: { label: "Solomon Islands", code: "SB", currency: "SBD" },
        195: { label: "Seychelles", code: "SC", currency: "SCR" },
        196: { label: "Sudan", code: "SD", currency: "SDG" },
        197: { label: "Sweden", code: "SE", currency: "SEK" },
        198: { label: "Singapore", code: "SG", currency: "SGD" },
        199: { label: "Saint Helena", code: "SH", currency: "SHP" },
        200: { label: "Slovenia", code: "SI", currency: "EUR" },
        201: { label: "Svalbard and Jan Mayen", code: "SJ", currency: "NOK" },
        202: { label: "Slovakia", code: "SK", currency: "EUR" },
        203: { label: "Sierra Leone", code: "SL", currency: "SLL" },
        204: { label: "San Marino", code: "SM", currency: "EUR" },
        205: { label: "Senegal", code: "SN", currency: "XOF" },
        206: { label: "Somalia", code: "SO", currency: "SOS" },
        207: { label: "Suriname", code: "SR", currency: "SRD" },
        208: { label: "South Sudan", code: "SS", currency: "SSP" },
        209: { label: "São Tomé and Príncipe", code: "ST", currency: "STD" },
        210: { label: "El Salvador", code: "SV", currency: "USD" },
        211: { label: "Sint Maarten", code: "SX", currency: "ANG" },
        212: { label: "Syria", code: "SY", currency: "SYP" },
        213: { label: "Swaziland", code: "SZ", currency: "SZL" },
        214: { label: "Turks and Caicos Islands", code: "TC", currency: "USD" },
        215: { label: "Chad", code: "TD", currency: "XAF" },
        216: { label: "French Southern Territories", code: "TF", currency: "EUR" },
        217: { label: "Togo", code: "TG", currency: "XOF" },
        218: { label: "Thailand", code: "TH", currency: "THB" },
        219: { label: "Tajikistan", code: "TJ", currency: "TJS" },
        220: { label: "Tokelau", code: "TK", currency: "NZD" },
        221: { label: "East Timor", code: "TL", currency: "USD" },
        222: { label: "Turkmenistan", code: "TM", currency: "TMT" },
        223: { label: "Tunisia", code: "TN", currency: "TND" },
        224: { label: "Tonga", code: "TO", currency: "TOP" },
        225: { label: "Turkey", code: "TR", currency: "TRY" },
        226: { label: "Trinidad and Tobago", code: "TT", currency: "TTD" },
        227: { label: "Tuvalu", code: "TV", currency: "AUD" },
        228: { label: "Taiwan", code: "TW", currency: "TWD" },
        229: { label: "Tanzania", code: "TZ", currency: "TZS" },
        230: { label: "Ukraine", code: "UA", currency: "UAH" },
        231: { label: "Uganda", code: "UG", currency: "UGX" },
        232: { label: "U.S. Minor Outlying Islands", code: "UM", currency: "USD" },
        233: { label: "United States", code: "US", currency: "USD" },
        234: { label: "Uruguay", code: "UY", currency: "UYU" },
        235: { label: "Uzbekistan", code: "UZ", currency: "UZS" },
        236: { label: "Vatican City", code: "VA", currency: "EUR" },
        237: { label: "Saint Vincent and the Grenadines", code: "VC", currency: "XCD" },
        238: { label: "Venezuela", code: "VE", currency: "VEF" },
        239: { label: "British Virgin Islands", code: "VG", currency: "USD" },
        240: { label: "U.S. Virgin Islands", code: "VI", currency: "USD" },
        241: { label: "Vietnam", code: "VN", currency: "VND" },
        242: { label: "Vanuatu", code: "VU", currency: "VUV" },
        243: { label: "Wallis and Futuna", code: "WF", currency: "XPF" },
        244: { label: "Samoa", code: "WS", currency: "WST" },
        245: { label: "Kosovo", code: "XK", currency: "EUR" },
        246: { label: "Yemen", code: "YE", currency: "YER" },
        247: { label: "Mayotte", code: "YT", currency: "EUR" },
        248: { label: "South Africa", code: "ZA", currency: "ZAR" },
        249: { label: "Zambia", code: "ZM", currency: "ZMW" },
        250: { label: "Zimbabwe", code: "ZW", currency: "ZWL" },
    };

    static UNITED_STATES_ID = 233;

    static GetByID(id) {
        return typeof Countries.#map[id] !== "undefined" ? Countries.#map[id] : null;
    }

    /**
     * Defines the set of currencies that should be marked as selected
     * @function
     * @param {array} list
     * @example [1, 2]
     * @returns {array} A list of {id, label} entries
     */
    static Selected(list) {
        let selected = [];
        for (let i = 0; i < list.length; i++) {
            if (typeof Countries.#map[list[i]] != "undefined") {
                selected.push({
                    label: Countries.#map[list[i]].label,
                    value: list[i],
                });
            }
        }
        return selected;
    }

    /**
     * Returns only the numeric "value" of the items in the list
     * @function
     * @param {array} list
     * @example [{id: 1, label: "A"}, {id: 2, label: "B"}]
     * @returns {array} Array of integers representing the "value" field of each entry in the list
     */
    static Values(list) {
        let selected = [];
        for (let i = 0; i < list.length; i++) {
            selected.push(list[i].value);
        }
        return selected;
    }

    setSelected = (elements) => {
        if (this.props.singleValue === true) {
            if (elements.length > 1) {
                elements.splice(0, 1);
            }
        }
        this.setState({ value: elements });
        this.props.onChange(elements, this.props.name);
    };

    customValueRenderer = (selected) => {
        return selected.length
            ? selected.map(
                ({ value, label }) =>
                    typeof value != "undefined" && (
                        <span key={`uniq${value}`}>
                            <ReactCountryFlag
                                countryCode={Countries.#map[value].code}
                                className="flag"
                            />
                            {label}
                        </span>
                    )
            )
            : "Select...";
    };

    render() {
        const options = typeof this.props.options === "object" ? this.props.options : Countries.OPTIONS;
        let className = this.props.disabled ? "disabled" : "";
        return (
            <MultiSelect
                className={`selCountry ${className}`}
                options={options}
                value={this.props.value}
                onChange={this.setSelected}
                labelledBy="Select"
                hasSelectAll={false}
                disabled={this.props.disabled}
                valueRenderer={this.customValueRenderer}
            />
        );
    }
}

Countries.propTypes = {
    /** Selling Region under selection */
    value: PropTypes.array.isRequired,
    /** OnChange callback function */
    onChange: PropTypes.func.isRequired,
    /** Should this component be disabled? */
    disabled: PropTypes.bool,
    options: PropTypes.array
};

export default Countries;
