import React from "react";

import { Container, Row, Col, Button, Form, Card, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";
import Emitter from "../../services/Emitter";
import Toast from "../../services/Toast";
import "./Prices.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import MultiSelect from "react-multi-select-component";
import ProcessingButton from "../../components/ProcessingButton";
import JSONEditor from "../../components/JSONEditor";
import Currencies from "../../components/Currencies";
import { createRef } from "react";
import ProductService from "../../services/ProductService";
import TableSpinnerOverlay from "../../components/TableSpinnerOverlay";

/**
 * Shows a product attribute editing page
 * @component
 * @category Scenes
 * @subcategory Products
 */
class Attributes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            info: null,
            dictionary: [],
            legacy: [],
            selections: '{}',
            problems: {
                dictionary: false,
                legacy: false,
                selections: false,
            },
            editing: false,
            saving: false,
            loading: true
        }
        this.reload = this.reload.bind(this);
        this.jsonEditor = createRef();
        this.getEditor = this.getEditor.bind(this);
        this.updateEditor = this.updateEditor.bind(this);
        this.resetView = this.resetView.bind(this);
        this.startEditing = this.startEditing.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    reload() {
        ProductService.getDictionaryKeys().then((dictionary) => {
            if (dictionary === false) {
                Toast.error("Something went wrong while retrieving data");
                return;
            }

            this.dictionary = [];
            dictionary.forEach((entry) => {
                this.dictionary.push({
                    label: entry.key,
                    value: entry.id
                });
            });
            ProductService.getAttributes(this.props.id).then((data) => {

                if (data === false) {
                    Toast.error("Something went wrong while retrieving data");
                    this.setState({ loading: false });
                    return;
                }

                this.setState({ loading: false, info: data.info });

                let original = {
                    legacy: [],
                    dictionary: [],
                    selections: '{}'
                };

                if (typeof data.legacy !== 'undefined') {
                    data.legacy.forEach((entry) => {
                        if (entry.name === 'selections') {
                            original.selections = entry.value;
                        } else {
                            original.legacy.push(entry);
                        }
                    });
                }

                if (typeof data.dictionary !== 'undefined') {
                    data.dictionary.forEach((entry) => {
                        original.dictionary.push({
                            label: entry.key,
                            value: entry.id
                        });
                    });
                }

                this.original = JSON.stringify(original);
                this.resetView();
            });
        });
    }

    componentWillUnmount() {
        Emitter.off("PRODUCT_ATTRIBUTES");
    }

    componentDidMount() {
        Emitter.once("PRODUCT_ATTRIBUTES", this.reload);
    }

    startEditing() {
        this.setState({
            editing: true,
            problems: {
                dictionary: false,
                legacy: false,
                selections: false,
            }
        }, () => {
            this.getEditor().setMode('tree');
        });
    }

    resetView() {
        let original = JSON.parse(this.original);
        this.setState({
            legacy: original.legacy,
            dictionary: original.dictionary,
            selections: original.selections,
            editing: false
        }, () => {
            const editor = this.getEditor();
            editor.update(JSON.parse(original.selections));
            editor.setMode('code'); // this is on purpose to make the component expand items in the editor
            editor.setMode('view');
        });
    }


    /**
     * Auxiliary function to identify overlapping attributes and prevent form to be save
     * @param {array} list 
     * @returns object {problems, list}
     */
    validateLegacyAttribs(list) {
        let indexes = {};
        let problems = 0;
        list.forEach((element, index) => {
            if (typeof indexes[element.name] === "undefined") {
                indexes[element.name] = [index];
                if (element.value.trim().length === 0) {
                    problems++;
                }
            } else {
                problems++;
                for (let i = 0; i < indexes[element.name].length; i++) {
                    list[indexes[element.name][i]].problem = true;
                }
                list[index].problem = true;
                indexes[element.name].push(index)
            }
        });
        Object.keys(indexes).forEach((attribute) => {
            if (indexes[attribute].length === 1) {
                list[indexes[attribute][0]].problem = false;
            }
        });
        return problems === 0;
    }

    validateDictionaryAttribs(list) {
        let indexes = {};
        let problems = 0;
        list.forEach((element, index) => {
            if (element === null) {
                problems++;
                return;
            }
            if (typeof indexes[element.value] === "undefined") {
                indexes[element.value] = [index];
            } else {
                problems++;
                for (let i = 0; i < indexes[element.value].length; i++) {
                    list[indexes[element.value][i]].problem = true;
                }
                list[index].problem = true;
                indexes[element.value].push(index)
            }
        });
        Object.keys(indexes).forEach((attribute) => {
            if (indexes[attribute].length === 1) {
                list[indexes[attribute][0]].problem = false;
            }
        });
        return problems === 0;
    }


    addNewLegacy = () => {
        let legacy = this.state.legacy,
            problems = this.state.problems;
        legacy.push({
            name: "alternative_skus", value: "", id: Date.now(), isNew: true
        });
        problems.legacy = !this.validateLegacyAttribs(legacy);
        this.setState({ legacy, problems })
    }

    addNewDictionary = () => {
        let dictionary = this.state.dictionary,
            problems = this.state.problems;
        dictionary.push(null);
        problems.dictionary = !this.validateDictionaryAttribs(dictionary);
        this.setState({ dictionary, problems })
    }

    getEditor() {
        return this.jsonEditor.current.jsoneditor;
    }

    updateEditor() {
        const oldState = this.getEditor().getMode();
        this.getEditor().setMode(oldState === 'text' ? 'code' : 'text');
        this.getEditor().setMode(oldState);
    }

    saveChanges() {
        this.setState({ saving: true }, () => {
            this.updateEditor();
            let payload = {
                dictionary: this.state.dictionary,
                legacy: this.state.legacy,
                selections: this.state.selections
            };
            ProductService.updateAttributes(this.props.id, payload).then((result) => {
                if (result === false) {
                    Toast.error("Something went wrong while saving modifications");
                    this.setState({ saving: false }, this.updateEditor);
                } else {
                    Toast.ok("Modifications saved successfully");
                    this.original = JSON.stringify(payload);
                    this.setState({ saving: false, editing: false }, this.updateEditor);
                }
            });
        });
    }

    render() {

        const Legacy = () => {
            if (this.state.legacy.length === 0) {
                return <i>None</i>
            }

            if (!this.state.editing) {
                return <ul style={{
                    lineHeight: 2
                }}>
                    {this.state.legacy.map((attr, index) => {
                        const values = attr.value.split('|');
                        return <li key={`attr-legacy-${index}`}><b>{attr.name}</b><span>:</span>{values.map((value) => {
                            return <span className="attr-legacy-value">{value}</span>
                        })}</li>
                    })}
                </ul>
            }

            const Select = (props) => {
                const keys = ["alternative_skus", "alternative_upcs", "application", "asin", "barrel_length", "caliber", "case",
                    "choke", "color", "color_code", "cord", "condition", "custom_shaft", "diameter", "drivetrain", "drive_shaft",
                    "dropdown", "engine", "filter", "flavor", "flex", "frame_color", "frame_lens", "frame_material", "frame_type",
                    "gender", "generation", "grips", "grip_wraps", "hand", "height", "length", "lens_color", "lens_material",
                    "lens_tint", "lens_type", "lie_angle", "loft", "loft-bounce", "make", "material", "model", "mount", "oil_sump",
                    "options", "option", "part_size", "quantity", "rounds", "scent", "shaft", "shaft_length", "shocks", "size",
                    "size_code", "stage_choice", "stroke", "style", "submodel", "transmission", "trim", "type",
                    "unit_weight", "voltage", "weight", "year"];
                const options = keys.map((key) => (<option selected={props.attribute === key}>{key}</option>))
                const handleSelectChange = (evt) => {
                    let rowIndex = evt.target.closest("div").getAttribute("index") * 1,
                        legacy = this.state.legacy,
                        problems = this.state.problems;

                    legacy[rowIndex].name = evt.target.value;
                    legacy[rowIndex].hasChanged = true;
                    problems.legacy = !this.validateLegacyAttribs(legacy);
                    this.setState({ legacy, problems })
                }
                return <Form.Select disabled={props.disabled} isInvalid={props.problem} aria-label="Attribute name" onChange={handleSelectChange}>
                    {options}
                </Form.Select>

            }

            const handleValueChange = (evt) => {
                let rowIndex = evt.target.closest("div").getAttribute("index") * 1,
                    legacy = this.state.legacy,
                    problems = this.state.problems;

                legacy[rowIndex].value = evt.target.value;
                legacy[rowIndex].hasChanged = true;

                problems.legacy = !this.validateLegacyAttribs(legacy);
                this.setState({ legacy, problems })
            }

            const handleDelete = (evt) => {
                let rowIndex = evt.target.closest("div").getAttribute("index") * 1,
                    legacy = this.state.legacy,
                    problems = this.state.problems;

                legacy.splice(rowIndex, 1);
                problems.legacy = !this.validateLegacyAttribs(legacy);

                this.setState({ legacy, problems })
            }
            return this.state.legacy.map((attr, index) => {
                const style = attr.value.trim().length > 0 ? null : {
                    borderColor: "red"
                };
                return (
                    <div className="attribute-row" style={{
                        width: "100%"
                    }} index={index} key={`attr-legacy-${index}`} >
                        <span style={{
                            width: "1px"
                        }}>
                            <Button disabled={this.state.saving} size="sm" variant="outline-danger" onClick={handleDelete}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </span>
                        <span>
                            <Select disabled={this.state.saving} attribute={attr.name} problem={typeof attr.problem !== "undefined" && attr.problem} />
                        </span>
                        <span>
                            <Form.Control
                                style={style}
                                key={`val-legacy-${attr.id}`}
                                type="text"
                                defaultValue={attr.value}
                                disabled={this.state.saving}
                                onChange={handleValueChange}
                            />
                        </span>
                    </div>
                )
            })
        }

        const Dictionary = () => {

            if (this.state.dictionary.length === 0) {
                return <i>None</i>
            }

            if (!this.state.editing) {
                return <ul>
                    {this.state.dictionary.map((attr, index) => (
                        <li style={{
                            fontFamily: "monospace"
                        }}>{attr.label}</li>
                    ))}
                </ul>
            }

            const Select = (props) => {

                const handleChange = (values) => {
                    if (values.length > 1) {
                        values.splice(0, 1);
                    }
                    props.onChange(props.index, values.length ? values[0] : null);
                }

                return <MultiSelect
                    options={this.dictionary}
                    hasSelectAll={false}
                    value={props.value !== null ? [props.value] : []}
                    onChange={handleChange}
                    className={props.problem ? "is-invalid" : ''}
                    disabled={props.disabled}
                    labelledBy="Select"
                />

            }

            const handleDelete = (evt) => {
                let rowIndex = evt.target.closest("div").getAttribute("index") * 1,
                    dictionary = this.state.dictionary,
                    problems = this.state.problems;

                dictionary.splice(rowIndex, 1);
                problems.dictionary = !this.validateDictionaryAttribs(dictionary);

                this.setState({ dictionary, problems })
            }

            const handleChange = (rowIndex, value) => {
                let dictionary = this.state.dictionary,
                    problems = this.state.problems;

                dictionary[rowIndex] = value;
                problems.dictionary = !this.validateDictionaryAttribs(dictionary);
                this.setState({ dictionary, problems })

            }
            return this.state.dictionary.map((attr, index) => {
                return (
                    <div className="attribute-row" style={{
                        width: "100%"
                    }} index={index} key={`attr-dictionary-${index}`} >
                        <span style={{
                            width: "1px"
                        }}>
                            <Button disabled={this.state.saving} size="sm" variant="outline-danger" onClick={handleDelete}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </span>
                        <span>
                            <Select disabled={this.state.saving} index={index} problem={attr === null || (typeof attr.problem !== "undefined" && attr.problem)} value={attr} onChange={handleChange} />
                        </span>
                    </div>
                )
            })
        }

        const Info = () => {
            if (this.state.info === null) {
                return <span>...</span>
            }
            let brands = Object.values(this.state.info.brands),
                maps = this.state.info.maps,
                prices = [],
                upc = typeof this.state.info.upc === "string" && this.state.info.upc !== this.state.info.identifier ? this.state.info.upc : null;

            brands.forEach((brand) => {
                if (typeof maps[brand.id] !== "object") {
                    return;
                }

                Object.keys(maps[brand.id]).forEach((currency_id) => {
                    prices.push({
                        currency: Currencies.CodeByID(currency_id).toLowerCase(),
                        price: maps[brand.id][currency_id],
                        brand
                    });
                });
            });

            return <div>
                <Row>
                    <Col sm={upc === null ? 7 : 5}><b>Title</b><br />{this.state.info.title}</Col>
                    {upc !== null && (
                        <Col sm={2}><b>UPC</b><br />{upc}</Col>
                    )}
                    <Col sm={2}><b>SKU</b><br />{this.state.info.sku}</Col>
                    <Col sm={3}><b>MAP</b><br />
                        {prices.map((row) => (
                            <span key={`price-${row.brand.id}-${row.currency}`}
                                style={{
                                    marginRight: "15px",
                                    display: "inline-block"
                                }}>
                                <OverlayTrigger
                                    placement="bottom"
                                    trigger={["hover", "focus"]}
                                    overlay={
                                        <Popover>
                                            <PopoverBody>{row.brand.name}</PopoverBody>
                                        </Popover>
                                    }>
                                    <span>
                                        <span
                                            key={`currency-${row.currency}`}
                                            className={`currency-flag currency-flag-${row.currency}`}
                                        ></span>
                                        {row.price}
                                    </span>
                                </OverlayTrigger>
                            </span>
                        ))}
                    </Col>
                </Row>
            </div>
        }

        return (
            <Container className={this.state.loading ? "pt-1" : "pt-4"}>
                <TableSpinnerOverlay loading={this.state.loading} />
                <Row>
                    <Col>
                        <Card style={{ width: '100%' }}>
                            <Card.Body>
                                <Card.Title>Product Info</Card.Title>
                                <Card.Text as={`div`}>
                                    {Info()}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6} className="d-flex align-top">

                        <Card style={{ width: '100%' }}>
                            <Card.Body>
                                <Card.Title style={{
                                    marginBottom: "15px"
                                }}>Dictionary{this.state.editing && (
                                    <Button
                                        disabled={this.state.saving}
                                        size="sm"
                                        variant="outline-primary"
                                        onClick={this.addNewDictionary}
                                        style={{
                                            float: "right",
                                            minWidth: "35px",
                                            marginRight: "0px"
                                        }}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                )}</Card.Title>
                                <Card.Text as={`div`}>
                                    {Dictionary()}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col sm={6} className="d-flex align-top">

                        <Card style={{ width: '100%' }}>
                            <Card.Body>
                                <Card.Title style={{
                                    marginBottom: "15px"
                                }}>Legacy{this.state.editing && (
                                    <Button
                                        disabled={this.state.saving}
                                        size="sm"
                                        variant="outline-primary"
                                        onClick={this.addNewLegacy}
                                        style={{
                                            float: "right",
                                            minWidth: "35px",
                                            marginRight: "0px"
                                        }}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                )}</Card.Title>
                                <Card.Text as={`div`} style={{
                                    maxHeight: "225px",
                                    overflow: "auto"
                                }}>
                                    {Legacy()}
                                </Card.Text>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
                <Row>
                    <Col>

                        <Card style={{ width: '100%' }}>
                            <Card.Body>
                                <Card.Title>Selections</Card.Title>
                                <Card.Text as={`div`} className="product-attribute">
                                    <JSONEditor
                                        ref={this.jsonEditor}
                                        json={this.state.selections}
                                        sortObjectKeys={false}
                                        navigationBar={false}
                                        history={true}
                                        allowedModes={['tree', 'code', 'text', 'view']}
                                        mode={'view'}
                                        statusBar={false}
                                        search={false}
                                        readOnly={!this.state.editing || this.state.saving}
                                        onChangeText={(jsonString) => {
                                            let problems = this.state.problems;
                                            problems.dictionary = true;
                                            if (jsonString.trim().length > 0) {
                                                try {
                                                    JSON.parse(jsonString);
                                                    problems.dictionary = false;
                                                } catch (e) { }
                                            }
                                            this.setState({ selections: jsonString, problems })
                                        }}
                                    />
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {!this.state.editing && (
                            <Button
                                variant="secondary"
                                onClick={this.startEditing}
                            >
                                Edit
                            </Button>
                        )}
                        {this.state.editing && (
                            <span>
                                <Button
                                    disabled={this.state.saving}
                                    variant="secondary"
                                    onClick={this.resetView}
                                >
                                    Cancel
                                </Button>
                                <ProcessingButton
                                    variant="primary"
                                    disabled={this.state.problems.legacy || this.state.problems.dictionary || this.state.problems.selections}
                                    processing={this.state.saving}
                                    processingLabel="Saving ..."
                                    label="Save"
                                    onClick={this.saveChanges}
                                />
                            </span>
                        )}
                    </Col>
                </Row>
            </Container >
        );
    }
}

export default Attributes;
