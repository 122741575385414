import React from "react";
import PropTypes from "prop-types";

import { Button, Form, Modal } from "react-bootstrap";
import Toast from "../services/Toast";
import OnboardingService from "../services/OnboardingService";
import MemoryService from "../services/MemoryService";
import ProcessingButton from "./ProcessingButton";

/**
 * Shows a Edit SIM range modal
 * @component
 */
class XPathRequest extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            saving: false,
            websites: ""
        }
        this.handleChange = this.handleChange.bind(this);
        this.checkValues = this.checkValues.bind(this);
    }

    checkValues() {
        if (MemoryService.Has('XPATH_LIST')) {
            let list = MemoryService.Get('XPATH_LIST').join("\n");
            MemoryService.Flush('XPATH_LIST');
            this.setState({ websites: list, canSubmit: this.validate(list) });
        }
    }

    handleChange(evt) {
        this.setState({
            websites: evt.target.value,
            canSubmit: this.validate(evt.target.value)
        });
    }

    validate(text) {
        let clean = typeof text === "string" ? text.trim() : '';
        return clean.length >= 3 && /[\w\d]{1,}\.[\w\d]{2,}/.test(clean);
    }

    render() {
        let cancel = () => {
            if (!this.state.saving) {
                this.props.onClose(false)
            }
        },
            save = () => {
                this.setState({ saving: true }, () => {
                    let websites = {},
                        list = this.state.websites.replace(/[,\n;]/g, '|').split('|');
                    list.forEach((entry) => {
                        let clean = entry.trim();
                        if (clean.length > 3 && /[\w\d]{1,}\.[\w\d]{2,}/.test(clean)) {
                            websites[clean] = null;
                        }
                    });
                    websites = Object.keys(websites);
                    OnboardingService.requestXPaths(websites).then((result) => {
                        if (result === true) {
                            this.props.onClose(true)
                            Toast.ok("XPaths requested successfully!");
                        } else {
                            Toast.error("Something went wrong while requesting xpaths");
                        }
                        this.setState({
                            saving: false
                        })
                    });
                });
            }
        return (
            <Modal
                onEntering={() => {
                    this.setState({ websites: "", canSubmit: false })
                }}
                onEntered={this.checkValues}
                size="lg"
                show={this.props.show}
                backdrop={true}
                keyboard={true}
                centered
            >
                <Modal.Header closeButton onHide={cancel}>
                    <Modal.Title>
                        New request
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Form.Control
                        as="textarea"
                        placeholder="Websites"
                        style={{
                            height: "350px"
                        }}
                        value={this.state.websites}
                        onChange={this.handleChange}
                        readOnly={this.state.saving}
                    />
                </Modal.Body>
                <Modal.Footer style={{
                    justifyContent: "center"
                }}>
                    <Button size="sm" variant="secondary" onClick={cancel} disabled={this.state.saving}>Cancel</Button>
                    <ProcessingButton
                        size="sm"
                        processing={this.state.saving}
                        processingLabel="Saving ..."
                        label="Save"
                        onClick={save}
                        disabled={!this.state.canSubmit}
                    />
                </Modal.Footer>
            </Modal >
        );
    }
}

XPathRequest.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
};

XPathRequest.defaultProps = {
    show: true,
    onClose: () => { console.log("onClose") }
};

export default XPathRequest;
