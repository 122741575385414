import React from "react";
import { Modal, Button, FormControl } from "react-bootstrap";
import { confirmable, createConfirmation } from "react-confirm";

class Prompt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
        };
        this.onChange = this.onChange.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);

        this.inputFocus = {
            ref: React.createRef(),
            setFocus: () => {
                this.inputFocus.ref.current &&
                    this.inputFocus.ref.current.focus();
            },
        };
    }

    componentDidMount() {
        this.inputFocus.setFocus();
    }

    onChange(evt) {
        this.setState({ value: evt.target.value });
    }

    onKeyPress(evt) {
        if (typeof this.props.options === "object" && typeof this.props.options.submitOnEnter === "boolean" && !this.props.options.submitOnEnter) {
            return;
        }
        if (evt.charCode === 13) {
            this.handleOnClick(evt);
        }
    }

    handleOnClick(evt) {
        return this.props.proceed({
            value: this.state.value,
        });
    }

    render() {
        const {
            show,
            confirmation,
            proceedLabel,
            cancelLabel,
            dismiss,
            cancel,
            message,
            options
        } = this.props;
        const
            readOnly = typeof options.readOnly === "boolean" && options.readOnly ? true : false,
            input = typeof options.numericField === "boolean" && options.numericField ?
                <FormControl
                    data-testid="prompt-value"
                    type="number"
                    value={this.state.value}
                    onChange={this.onChange}
                    ref={this.inputFocus.ref}
                    readOnly={readOnly}
                    onKeyPress={this.onKeyPress}
                />
                :
                <FormControl
                    data-testid="prompt-value"
                    type="text"
                    as="textarea"
                    style={{
                        height: "100px"
                    }
                    }
                    value={this.state.value}
                    onChange={this.onChange}
                    readOnly={readOnly}
                    ref={this.inputFocus.ref}
                    onKeyPress={this.onKeyPress}
                />
            ;
        return (
            <div className="static-modal" >
                <Modal show={show} onHide={dismiss}>
                    <Modal.Header>
                        <Modal.Title>{confirmation}</Modal.Title>
                    </Modal.Header>
                    {message.length > 0 && <Modal.Body>{message}</Modal.Body>}
                    <Modal.Footer>
                        {input}
                        {cancelLabel !== null && cancelLabel.length && <Button data-testid="prompt-cancel" onClick={cancel} variant="secondary">
                            {cancelLabel}
                        </Button>}
                        {proceedLabel !== null && proceedLabel.length && <Button data-testid="prompt-ok" variant="primary" onClick={this.handleOnClick}>
                            {proceedLabel}
                        </Button>}
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default function confirm(
    confirmation,
    proceedLabel = "Save",
    cancelLabel = "Cancel",
    message = "",
    value = "",
    options = {}
) {
    return createConfirmation(confirmable(Prompt))({
        confirmation,
        proceedLabel,
        cancelLabel,
        message,
        value,
        options
    });
}
