import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import Utils from "../services/Utils"

/**
 * Form.Control element to facilitate handling of DropDown menus
 * @component
 */
class DropDown extends React.Component {
    render() {
        let selectedValue = '';
        let options = [];
        if (this.props.allowEmpty) {
            options.push({ value: '', label: '' });
        }
        this.props.options.forEach((entry) => {
            let opt = null;
            if (typeof entry === "object") {
                if (typeof entry.value !== "undefined") {
                    opt = {
                        value: entry.value
                    };
                } else if (typeof entry.id !== "undefined") {
                    opt = {
                        value: entry.id
                    };
                } else if (typeof entry === "string" || typeof entry === "number") {
                    opt = {
                        value: entry
                    }
                }
                if (opt === null) {
                    return;
                }
                if (typeof entry.label !== "undefined") {
                    opt.label = entry.label;
                } else if (typeof entry.name !== "undefined") {
                    opt.label = entry.name;
                } else if (typeof entry === "string" || typeof entry === "number") {
                    opt.label = entry;
                } else {
                    opt.label = '?';
                }
            } else {
                opt = {
                    label: entry,
                    value: entry
                };
            }
            if (opt !== null) {
                options.push(opt)
            }
        });
        if (this.props.selected === null) {
            // ignore
        } else if (typeof this.props.selected === "object") {
            if (typeof this.props.selected.value !== "undefined") {
                selectedValue = this.props.selected.value;
            } else if (typeof this.props.selected.id !== "undefined") {
                selectedValue = this.props.selected.id
            } else if (typeof this.props.selected === "string" || typeof this.props.selected === "number") {
                selectedValue = this.props.selected;
            }
        } else if (typeof this.props.selected === "string" || typeof this.props.selected === "number") {
            selectedValue = this.props.selected;
        }
        let attribs = {
            size: this.props.size,
            name: this.props.name,
            disabled: this.props.disabled,
            isInvalid: this.props.isInvalid,
            onChange: this.props.onChange,
        };
        if (typeof this.props.selected !== "undefined") {
            attribs.value = selectedValue;
        }

        return <Form.Select
            {...attribs}>
            {options.map((entry, index) => (<option key={`drop-${this.props.name}-${index}`} value={entry.value}>{entry.label}</option>))}
        </Form.Select>
    }
}

DropDown.propTypes = {
    size: PropTypes.string,
    name: PropTypes.string,
    selected: PropTypes.any,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    isInvalid: PropTypes.bool,
    disabled: PropTypes.bool,
    allowEmpty: PropTypes.bool
};

DropDown.defaultProps = {
    size: "md",
    name: Utils.generateUUID(),
    options: [],
    onChange: (evt) => {
        console.log('value', evt.target.value, 'evt', evt);
    },
    isInvalid: false,
    disabled: false,
    allowEmpty: true
};

export default DropDown;