import React from "react";

import { Carousel } from "react-bootstrap";
import MyCarousel from "../../components/MyCarousel";
import JobsList from "./JobsList";
import NewJob from "./NewJob";

class Jobs extends React.Component {
    showPage(tab) {
        let key = 0;

        if (tab === "#new") {
            key = 1;
        }

        return (
            <MyCarousel activeIndex={key}>
                <Carousel.Item key="#list" className="standardGrid">
                    <JobsList />
                </Carousel.Item>
                <Carousel.Item key="#new" className="standardGrid">
                    <NewJob />
                </Carousel.Item>
            </MyCarousel>
        );
    }

    render() {
        let match = window.location.href.match(/#\w+/),
            activeKey = match != null ? match[0] : "#list";

        return this.showPage(activeKey);
    }
}
export default Jobs;
