import React, { createRef } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Link, withRouter } from "react-router-dom";
import Websites from "../../services/WebsiteService";
import Brand from "../../services/BrandService";
import Toast from "../../services/Toast";
import { Container, Row, Form } from "react-bootstrap";
import DelayedInput from "../../components/DelayedInput";
import Emitter from "../../services/Emitter";
import Utils from "../../services/Utils";
import Loading from "../Loading";
import WebsiteSearch from "../../components/WebsiteSearch";
import MyTable from "../../components/MyTable";


/**
 * @component
 * @category Scenes
 * @subcategory Brand
 */
class Lookup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            websiteId: 0,
            loadingProducts: true,
            shouldBlockEdition: false,
        };
        this.onEnter = this.onEnter.bind(this);
        this.handleURLChange = this.handleURLChange.bind(this);
        this.tableRef = createRef();
    }

    componentDidMount() {
        Emitter.once("BRAND_LOOKUP", this.onEnter);
    }

    componentWillUnmount() {
        Emitter.off("BRAND_LOOKUP");
    }

    onEnter() {
        this.original = {};
        Brand.products(this.props.brandId).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of products");
                return;
            }

            data.forEach((row) => {
                row.editing = false;
                this.original[row.id] = row;
            });

            let website = window.location.hash.match(/lookup,(\d+)/);
            if (website !== null) {

                Websites.simpleRow(website[1]).then((result) => {
                    if (result !== false) {
                        this.setState({
                            defaultSelected: [result],
                            loadingProducts: false,
                            websiteId: website[1]
                        }, () => {
                            this.handleWebsiteChange([{ id: website[1] }]);
                        });
                    }
                });

            } else {
                this.setState({ loadingProducts: false })
            }
        });
    }


    handleWebsiteChange = (row) => {

        if (row.length === 0) {
            this.setState({
                websiteId: 0,
                search: {
                    upc: "",
                    sku: "",
                    title: "",
                    origin: "",
                },
            });
            return;
        }

        this.setState({ websiteId: row[0].id })

        if (Object.keys(this.original).length === 0) {
            setTimeout(() => this.tableRef.current?.setLoading(false), 250);
            return;
        }

        Object.keys(this.original).forEach((pid) => {
            this.original[pid].url = "";
            this.original[pid].origin = "";
            this.original[pid].updated = "";
        });

        this.tableRef.current?.setLoading();

        this.props.history.push("/brand/" + this.props.brandId + "#lookup," + row[0].id)

        Websites.urls(row[0].id, this.props.brandId).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while retriving URLs for this website.");
                return;
            }

            const items = result.items,
                shouldBlock = result.url_profile === 'multiple_with_item_number';
            if (shouldBlock) {
                Toast.info('You can\'t edit the URLs because this website allows multiple URLs per product.\n' +
                    'Kindly use Platform\'s Product Catalog page instead.', 15000);
            }

            items.forEach((row) => {
                if (typeof this.original[row.pid] === "undefined") {
                    return;
                }
                this.original[row.pid].url = typeof row.url === "string" ? row.url : "";
                this.original[row.pid].origin = typeof row.origin === "string" ? row.origin : "manual";
                this.original[row.pid].updated = typeof row.origin === "string" ? row.updated : "";
            });

            let data = [];
            Object.keys(this.original).forEach((pid) => data.push(this.original[pid]));
            this.tableRef.current?.populate(data);
            this.setState({ shouldBlockEdition: shouldBlock });
        });

    };

    handleURLChange(field, value, target) {
        let obj = this.tableRef.current.getRowFromEvent(target);
        if (obj.index === -1) {
            Toast.error("Something went wrong while saving changes");
            return;
        }
        let f = value.trim().length === 0 ? Websites.removeURL : Websites.setURL;

        f(this.state.websiteId, obj.row.id, value).then((result) => {
            if (result === true) {
                obj.row.url = value;
                obj.row.origin = value.trim().length === 0 ? '' : 'manual';
                obj.row.updated = Utils.DateNow().substring(0, 10);
                this.tableRef.current.updateRow(obj.row);
            } else {
                Toast.error(typeof result === "string" ? result : "Something went wrong while saving changes");
            }
        });
    }

    render() {
        const isSAW = (origin) => Utils.PDSystems().indexOf(origin) !== -1;
        const bottom = this.state.loadingProducts ? <Loading /> : (this.state.websiteId === 0 ? null :
            <MyTable
                name="brand-urls-lookup"
                dynamic={false}
                ref={this.tableRef}
                sortBy="identifier"
                sortOrder="asc"
                headers={
                    [
                        {
                            field: "identifier",
                            label: "Identifier",
                            sortable: true,
                            searchable: true,
                            width: "140px",
                        },
                        {
                            field: "upc",
                            label: "UPC",
                            sortable: true,
                            searchable: true,
                            width: "140px",
                        },
                        {
                            field: "sku",
                            label: "SKU",
                            width: "140px",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "origin",
                            label: "Origin",
                            width: "100px",
                            sortable: true,
                            allowEmpty: false,
                            options: [{ label: 'All', value: '' }, { label: 'Manual', value: 'manual' }, { label: 'SAW', value: 'saw' }, { label: 'Unknown', value: 'unknown' }]
                        },
                        {
                            field: "updated",
                            label: "Date",
                            width: "100px",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "title",
                            label: "Title",
                            width: "300px",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "url",
                            label: "URL",
                            sortable: true,
                            searchable: true
                        }
                    ]
                }
                renderColumns={{
                    identifier: (row) => this.state.shouldBlockEdition ?
                        <Link
                            target="_blank"
                            className="link"
                            to={`/product/${row.id}/#lookup`}
                        >{row.identifier}</Link>
                        : row.identifier,

                    title: (row) => <Form.Control
                        key={`title-${row.id}`}
                        type="text"
                        as="textarea"
                        style={{
                            height: "80px",
                            fontSize: "small"
                        }}
                        value={row.title}
                        readOnly={true}
                    />,
                    origin: {
                        text: (row) => isSAW(row.origin) ? 'saw' : row.origin
                    },
                    url: (row) => <DelayedInput
                        key={`url-${row.id}`}
                        as="textarea"
                        disabled={this.state.shouldBlockEdition}
                        style={{
                            height: "80px",
                            fontSize: "small"
                        }}
                        onChange={this.handleURLChange}
                        value={row.url}
                        minLength={12}
                    />
                }}
            />);

        return (
            <Container className="pt-4">
                {!this.state.loadingProducts && (
                    <Row>
                        <WebsiteSearch defaultSelected={this.state.defaultSelected} onChange={this.handleWebsiteChange} />
                    </Row>
                )}
                {bottom}
            </Container>
        )
    }
}

export default withRouter(Lookup);