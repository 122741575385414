import React, { createRef } from "react";

import { Container, Breadcrumb, Button, Form, Spinner } from "react-bootstrap";
import DelayedInput from "../../components/DelayedInput";
import DomainDiscoveryService from "../../services/DomainDiscoveryService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import prompt from "../../components/Prompt";
import confirm from "../../components/Confirm";
import Toast from "../../services/Toast";
import Utils from "../../services/Utils";
import MyTable from "../../components/MyTable";

/**
 * Override component
 * @component
 * @category Scenes
 * @subcategory Domain Discovery
 */
class Override extends React.Component {
    constructor(props) {
        super(props);


        this.handleDelete = this.handleDelete.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleDomainChange = this.handleDomainChange.bind(this);
        this.handleIsCommerceChange = this.handleIsCommerceChange.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.tableRef = createRef();
    }

    componentDidMount() {
        DomainDiscoveryService.getAllOverride().then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of domains");
                return;
            }
            this.tableRef.current?.populate(data);
        });
    }

    handleDownload() {
        this.tableRef.current.CSV();
    }


    async handleDelete(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);

        if (
            await confirm("Are you sure?", "Yes", "No", {
                message:
                    "You are about the remove domain *" +
                    obj.row.domain +
                    "* from the list. This operation can't be undone. Do you want to proceed?",
            })
        ) {
            DomainDiscoveryService.removeOverride(obj.row.id).then((result) => {
                if (result) {
                    Toast.ok("Domain removed successfully.");
                    this.tableRef.current.deleteRow(obj.row);
                } else {
                    Toast.error(
                        "Something went wrong while removing the domain."
                    );
                }
            });
        }
    }

    handleNew() {
        prompt("Add new Domain", "Add", "Cancel", "", "", {
            submitOnEnter: false
        }).then((inputResult) => {

            let dirtyValues = inputResult.value.replace(/[\n ]/g, ',').split(','),
                cleanValues = [],
                plural;

            dirtyValues.forEach((text) => {
                let cleanValue = Utils.CleanUpDomain(text);
                if (cleanValue.length === 0) {
                    return;
                }
                cleanValues.push(cleanValue);
            });
            if (cleanValues.length === 0) {
                return;
            }
            plural = cleanValues.length > 1;
            confirm(plural ? "Are these e-commerce websites?" : "Is it an e-commerce website?", "Yes", "No").then((is_ecommerce) => {
                DomainDiscoveryService.insertOverride(cleanValues, is_ecommerce ? 1 : 0).then(
                    (result) => {
                        if (result !== false) {
                            result.forEach((row) => {
                                this.tableRef.current.addRow(row);
                            });
                            Toast.ok(plural ? "Domains added successfully" : "Domain added successfully");
                        } else {
                            Toast.error(
                                "Something went wrong while adding the new Domain" + (plural ? 's' : '')
                            );
                        }
                    }
                );
            })
        })
            .catch(() => { });
    }

    handleDomainChange(field, value, target) {
        let obj = this.tableRef.current.getRowFromEvent(target);

        let newValue = Utils.CleanUpDomain(value);
        if (newValue !== obj.row.domain) {
            DomainDiscoveryService.updateOverride(
                obj.row.id,
                newValue,
                obj.row.is_ecommerce
            ).then((result) => {
                if (result) {
                    obj.row.domain = newValue;
                    this.tableRef.current.updateRow(obj.row);
                    Toast.ok("Domain updated successfully");
                } else {
                    Toast.error("Something went wrong while updating the Domain.");
                }
            });
        }
    }

    handleIsCommerceChange(event) {
        let obj = this.tableRef.current.getRowFromEvent(event),
            checked = event.target.checked ? 1 : 0;

        obj.row.saving = true;
        this.tableRef.current.updateRow(obj.row);
        DomainDiscoveryService.updateOverride(
            obj.row.id,
            obj.row.domain,
            checked
        ).then((result) => {
            obj.row.saving = false;
            this.tableRef.current.updateRow(obj.row);
            if (result) {
                obj.row.is_ecommerce = checked;
                this.tableRef.current.updateRow(obj.row);
                Toast.ok("Setting updated successfully");
            } else {
                Toast.error(
                    "Something went wrong while updating this entry."
                );
            }
        });
    }

    render() {

        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Domain Discovery</Breadcrumb.Item>
                    <Breadcrumb.Item active>E-commerce Override</Breadcrumb.Item>
                </Breadcrumb>
                <MyTable
                    name="dd-ecommerce-override"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="domain"
                    sortOrder="asc"
                    headers={
                        [
                            {
                                field: "domain",
                                label: "Domain",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "is_ecommerce",
                                label: "E-commerce",
                                width: "100px",
                                sortable: true
                            },
                            {
                                field: "control",
                                width: "87px",
                                className: "text-center",
                                content: <React.Fragment>
                                    <Button
                                        size="sm"
                                        variant="success"
                                        className="tdButton"
                                        onClick={this.handleNew}
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                    <Button
                                        size="sm"
                                        variant="primary"
                                        className="tdButton"
                                        onClick={this.handleDownload}
                                    >
                                        <FontAwesomeIcon icon={faDownload} />
                                    </Button>
                                </React.Fragment>
                            }
                        ]
                    }
                    renderColumns={{
                        domain: (row) => <DelayedInput
                            key={`domain-${row.id}`}
                            onChange={this.handleDomainChange}
                            value={row.domain}
                            minLength={0}
                            onHitEnterOnly={false}
                        />,
                        is_ecommerce: {
                            csv: (row) => row.is_ecommerce === 1 ? 'Yes' : 'No',
                            format: (row) => row.saving ? <Spinner
                                animation="border"
                                role="status"
                            /> : <Form.Check className="lg" type="checkbox" checked={row.is_ecommerce === 1} onChange={this.handleIsCommerceChange} />,
                            className: "text-center"
                        },
                        control: {
                            format: (row) =>
                                <Button
                                    size="sm"
                                    variant="danger"
                                    className="tdButton"
                                    onClick={this.handleDelete}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>,
                            className: "text-center",
                        }
                    }}
                />
            </Container>
        );
    }
}

export default Override;
