import React, { createRef } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Link, withRouter } from "react-router-dom";
import Brand from "../../services/BrandService";
import Toast from "../../services/Toast";
import { Container } from "react-bootstrap";
import Emitter from "../../services/Emitter";
import MyTable from "../../components/MyTable";


/**
 * @component
 * @category Scenes
 * @subcategory Brand
 */
class Trends extends React.Component {
    constructor(props) {
        super(props);
        this.onEnter = this.onEnter.bind(this);
        this.tableRef = createRef();
        this.state = {
            days: ['...', '...', '...', '...', '...', '...', '...']
        };

    }

    componentDidMount() {
        Emitter.once("BRAND_TRENDS", this.onEnter);
    }

    componentWillUnmount() {
        Emitter.off("BRAND_TRENDS");
    }

    onEnter() {
        Brand.trends(this.props.brandId).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of products");
                return;
            }
            let list = [];
            let keys = Object.keys(data.items.websites);
            keys.forEach((website_id) => {
                let row = data.items.websites[website_id];
                list.push({
                    id: website_id,
                    name: row.name,
                    products: row.products,
                    day0: row.diff[0],
                    day1: row.diff[1],
                    day2: row.diff[2],
                    day3: row.diff[3],
                    day4: row.diff[4],
                    day5: row.diff[5],
                    day6: row.diff[6],
                    sum: row.diff[1] + row.diff[2] + row.diff[3] + row.diff[4] + row.diff[5] + row.diff[6]
                });
            })
            this.setState({ days: data.items.days }, () => {
                this.tableRef.current?.populate(list);
            });
        });
    }

    render() {
        return (
            <Container className="pt-4">
                <MyTable
                    name="brand-trends"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="sum"
                    sortOrder="asc"
                    headers={
                        [
                            {
                                field: 'name',
                                label: 'Website',
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: 'day0',
                                width: "80px",
                                hint: 'Reference date',
                                label: this.state.days[0],
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: 'day1',
                                width: "80px",
                                hint: `Variation between ${this.state.days[0]} and ${this.state.days[1]}`,
                                label: this.state.days[1],
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: 'day2',
                                width: "80px",
                                hint: `Variation between ${this.state.days[1]} and ${this.state.days[2]}`,
                                label: this.state.days[2],
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: 'day3',
                                width: "80px",
                                hint: `Variation between ${this.state.days[2]} and ${this.state.days[3]}`,
                                label: this.state.days[3],
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: 'day4',
                                width: "80px",
                                hint: `Variation between ${this.state.days[3]} and ${this.state.days[4]}`,
                                label: this.state.days[4],
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: 'day5',
                                width: "80px",
                                hint: `Variation between ${this.state.days[5]} and ${this.state.days[6]}`,
                                label: this.state.days[5],
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: 'day6',
                                width: "80px",
                                hint: `Variation between ${this.state.days[5]} and ${this.state.days[6]}`,
                                label: this.state.days[6],
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: 'sum',
                                label: 'Sum',
                                width: "80px",
                                hint: 'Cumulative variation over the last week',
                                sortable: true,
                                searchable: true
                            },
                        ]
                    }
                    renderColumns={{
                        name: (row) => <Link key={`link-website-${row.id}`} className="link" to={`/website/${row.id}`}>(#{row.id}) {row.name}</Link>,
                        day0: {
                            className: "gray"
                        },
                        day1: (row) => <span className={row.day1 > 0 ? 'fgreen' : (row.day1 < 0 ? 'fred' : '')}>{row.day1 !== 0 ? row.day1 : ""}</span>,
                        day2: (row) => <span className={row.day2 > 0 ? 'fgreen' : (row.day2 < 0 ? 'fred' : '')}>{row.day2 !== 0 ? row.day2 : ""}</span>,
                        day3: (row) => <span className={row.day3 > 0 ? 'fgreen' : (row.day3 < 0 ? 'fred' : '')}>{row.day3 !== 0 ? row.day3 : ""}</span>,
                        day4: (row) => <span className={row.day4 > 0 ? 'fgreen' : (row.day4 < 0 ? 'fred' : '')}>{row.day4 !== 0 ? row.day4 : ""}</span>,
                        day5: (row) => <span className={row.day5 > 0 ? 'fgreen' : (row.day5 < 0 ? 'fred' : '')}>{row.day5 !== 0 ? row.day5 : ""}</span>,
                        day6: (row) => <span className={row.day6 > 0 ? 'fgreen' : (row.day6 < 0 ? 'fred' : '')}>{row.day6 !== 0 ? row.day6 : ""}</span>,
                        sum: (row) => <span className={row.sum > 0 ? 'fgreen' : (row.sum < 0 ? 'fred' : '')}><b>{row.sum !== 0 ? row.sum : ""}</b></span>
                    }}
                />
            </Container>
        );
    }
}

export default withRouter(Trends);
