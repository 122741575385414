import React from "react";
import { Spinner } from "react-bootstrap";

export default function MySpinner(props) {
    let style = {
        padding: "9px",
        margin: "2px",
        marginLeft: "4px",
        marginRight: "4px",
    };

    if (typeof props.style === "object") {
        Object.keys(props.style).forEach((attribute) => {
            style[attribute] = props.style[attribute];
        })
    }

    return <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
        style={style}
    />;
}