import React, { createRef } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Link, withRouter } from "react-router-dom";
import Manufacturer from "../../services/ManufacturerService";
import Toast from "../../services/Toast";
import { Breadcrumb, Container } from "react-bootstrap";
import MyTable from "../../components/MyTable";
import ProcessingButton from "../../components/ProcessingButton";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Categories from "../../components/Categories";


/**
 * @component
 * @category Scenes
 * @subcategory Manufacturer
 */
class List extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = createRef();
        this.state = {
            downloading: false,
        }
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
        this.categories = [];
        Categories.OPTIONS.forEach((category) => {
            this.categories.push(category.label);
        })
    }

    componentDidMount() {
        Manufacturer.all().then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of manufacturers");
                return;
            }
            this.tableRef.current?.populate(data);
        });
    }


    downloadSpreadsheet() {
        this.tableRef.current.CSV();
    }

    render() {
        return (

            <Container className="pt-4">
                <span style={{
                    float: "right"
                }}
                >
                    <ProcessingButton
                        size="sm"
                        processing={this.state.downloading}
                        processingLabel="Downloading ..."
                        variant="success"
                        label="Download CSV"
                        icon={faDownload}
                        onClick={this.downloadSpreadsheet}
                    />
                </span>
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Manufacturer</Breadcrumb.Item>
                    <Breadcrumb.Item active>List</Breadcrumb.Item>
                </Breadcrumb>
                <MyTable
                    name="manufacturer-list"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="id"
                    sortOrder="desc"
                    headers={
                        [
                            {
                                field: "id",
                                label: "ID",
                                sortable: true,
                                searchable: true,
                                width: "60px",
                            },
                            {
                                field: "name",
                                label: "Name",
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "categories",
                                label: "Categories",
                                sortable: true,
                                allowEmpty: true,
                                options: this.categories
                            },
                            {
                                field: "products",
                                label: "# Products",
                                sortable: true,
                                width: "100px"
                            }
                        ]
                    }
                    renderColumns={{
                        name: (row) => <Link className="link" to={`/manufacturer/${row.id}`}>{row.name}</Link>,
                        categories: {
                            format: (row) => row.categories.map((category) => category.name).join(', '),
                            text: (row) => row.categories.map((category) => category.name).join(', ')
                        },
                        products: {
                            className: "text-right"
                        }
                    }}
                />
            </Container>
        )
    }
}

export default withRouter(List);