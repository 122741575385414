import React, { createRef } from "react";
import { Container, Breadcrumb, Row, Col, Button, Carousel, Form, Popover, OverlayTrigger, PopoverBody } from "react-bootstrap";
import Spreadsheet from "../../components/Spreadsheet";
import URLLookupService from "../../services/URLLookupService";
import Toast from "../../services/Toast";
import Utils from "../../services/Utils";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faDownload, faFileImport, faTimes, faWrench } from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "../../components/ProgressBar";
import confirm from "../../components/Confirm";
import ImportURLs from "../../components/ImportURLs";
import MyTable from "../../components/MyTable";
import WebsiteService from "../../services/WebsiteService";
import Loading from "../Loading";
import ProcessingButton from "../../components/ProcessingButton";
import { readString } from 'react-papaparse'
import MyCarousel from "../../components/MyCarousel";
import FileUploadButton from "../../components/FileUploadButton";


/**
 * Replacement for https://super.trackstreet.com/super/tools/lookup_urls
 * @component
 * @category Scenes
 */
class URLUploader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            fromFile: false,
            loading: false,
            problems: 0,
            showModal: false,
            npd: false,
            progress: 0
        }
        this.upload = this.upload.bind(this);
        this.spreadsheet = createRef();
        this.tableRef = createRef();
        this.editable = true;
        if (window.location.hash.match(/(results)/)) {
            this.props.history.push('/others/url');
        }
        this.data = [[]];

        this.ShowResults = this.ShowResults.bind(this);
        this.onImport = this.onImport.bind(this);
        this.procNext = this.procNext.bind(this);
        this.processFile = this.processFile.bind(this);
        this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
    }

    componentDidMount() {
        let autoLoad = window.localStorage.getItem('uploader');
        if (autoLoad !== null) {
            window.localStorage.removeItem('uploader');
            this.setState({ loading: true });
            autoLoad = JSON.parse(autoLoad);
            WebsiteService.urls(autoLoad.website_id, [autoLoad.brand_id]).then((result) => {
                this.setState({ loading: false });
                if (result === false) {
                    Toast.error("Something went wrong while retrieving URLs");
                    return;
                }
                this.onImport(result);
            });
        }
    }

    getSpreadsheet() {
        return this.spreadsheet.current.Get();
    }

    onImport(data) {
        this.setState({ showModal: false });
        if (typeof data === "undefined" || data.length === 0) {
            return;
        }

        let newData = this.getSpreadsheet().getData();
        for (let i = 0; i < newData.length; i++) {
            if (newData[i][0].trim().length === 0 && newData[i][1].trim().length === 0) {
                newData.splice(i--, 1);
            }
        }

        for (let i = 0; i < data.length; i++) {
            newData.push([data[i].identifier, data[i].url]);
        }

        this.getSpreadsheet().setData(newData);
        Toast.ok(data.length + " row(s) imported successfully");
    }

    downloadSpreadsheet() {
        this.tableRef.current.CSV();
    }

    upload() {
        let items = [], cleanData = [];

        this.getSpreadsheet().options.editable = false;
        this.getSpreadsheet().getData().forEach((item) => {
            if (
                item[0].trim().length === 0 || item[1].trim().length === 0 ||
                item[0].match(/^[^\w-]$/i) || !item[1].match(/^https?:\/\/.+/)
            ) {
                return;
            }
            items.push({
                identifier: item[0].trim(),
                url: item[1].trim()
            });
            cleanData.push([item[0].trim(), item[1].trim()]);
        });

        if (items.length === 0) {
            this.getSpreadsheet().options.editable = true;
            Toast.error("No valid data was informed.");
            return;
        }

        this.getSpreadsheet().setData(cleanData);
        this.tableRef.current?.search("status", "");
        this.setState({
            saving: true,
            progress: 0,
        });

        this.packs = [];
        do {
            let fragment = items.splice(0, 25);
            this.packs.push(fragment);
        } while (items.length > 25);
        if (items.length > 0) {
            this.packs.push(items);
        }

        this.results = {
            items: [],
            websites: {},
            problems: 0,
            steps: this.packs.length,
            pending: this.packs.length
        };
        this.tableRef.current.populate([]);
        this.procNext();
    }

    processFile(file) {
        readString(
            file.content, {
            complete: (results) => {
                if (results.errors.length) {
                    Toast.error("One or more lines is malformed.");
                    return;
                }
                if (results.data.length === 0) {
                    Toast.error("Empty file");
                    return;
                }
                let indexes = {
                    identifier: -1,
                    url: -1
                },
                    headers = results.data[0];
                for (var i = 0; i < headers.length; i++) {
                    if (/identifier|upc/i.test(headers[i])) {
                        indexes.identifier = i;
                    } else if (/url/i.test(headers[i])) {
                        indexes.url = i;
                    }
                }
                if (indexes.identifier === -1) {
                    for (i = 0; i < headers.length; i++) {
                        if (/^http/i.test(headers[i])) {
                            indexes.url = i;
                        } else if (/^[\w-]+$/i.test(headers[i])) {
                            indexes.identifier = i;
                        }
                    }
                }
                if (indexes.url === -1 || indexes.identifier === -1) {
                    Toast.error("Could not identify columns Identifier and URL");
                    return;
                }
                let cleanData = [];
                for (i = 0; i < results.data.length; i++) {
                    if (/^http/i.test(results.data[i][indexes.url]) && /^[\w-]+$/i.test(results.data[i][indexes.identifier])) {
                        cleanData.push({
                            identifier: results.data[i][indexes.identifier],
                            url: results.data[i][indexes.url]
                        });
                    }
                }

                this.setState({
                    saving: true,
                    fromFile: true,
                    progress: 0,
                });

                this.packs = [];
                do {
                    let fragment = cleanData.splice(0, 25);
                    this.packs.push(fragment);
                } while (cleanData.length > 25);
                if (cleanData.length > 0) {
                    this.packs.push(cleanData);
                }

                this.results = {
                    items: [],
                    websites: {},
                    problems: 0,
                    steps: this.packs.length,
                    pending: this.packs.length
                };
                this.procNext();
            }
        })
    }

    procNext() {

        if (this.packs.length === 0) {

            this.setState({
                saving: false,
                problems: this.results.problems,
            }, () => {
                this.tableRef.current.populate(this.results.items);
                this.props.history.push('/others/url#results');
                if (!this.state.fromFile) {
                    this.getSpreadsheet().options.editable = true;
                }
                this.ShowResults();
            });

            return;
        }

        let items = this.packs.shift();
        URLLookupService.upload({ items, npd: this.state.npd }).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while uploading URLs");
                this.setState({
                    saving: false,
                    fromFile: false
                }, () => {
                    this.getSpreadsheet().options.editable = true;
                });
                return;
            }

            result.items.forEach((item, index) => {
                item.order = index;
                delete item.id;
                delete item.original;
                item.status = typeof item.error === "undefined" ? "1" : "0";
                if (typeof item.task === "undefined") {
                    item.task = "?";
                } else if (item.task === "edit") {
                    item.task = "Updated";
                } else if (item.task === "add") {
                    item.task = "Added";
                } else if (item.task === "remove") {
                    item.task = "Removed";
                }
                if (item.status === "0") {
                    this.results.problems++;
                }
                this.results.items.push(item);
            })

            Object.keys(result.websites).forEach((key) => {
                this.results.websites[key] = result.websites[key];
            });

            this.results.pending--;
            this.setState({
                progress: (this.results.steps - this.results.pending) * 100 / this.results.steps
            })
            this.procNext();
        });

    }

    async ShowResults() {
        let plural;
        if (this.state.problems > 0) {
            if (this.state.problems === this.results.items) {
                Toast.error(plural ? "No rows were processed" : "Row not processed");
                return;
            }
            plural = this.state.problems > 1;
            if (

                await confirm("Shall we filter results?", "Yes", "No", {
                    message: this.state.problems + (plural ? " rows" : " row") + " presented problems. Would you like to filter results and see only the entries that couldn't be processed?",
                })
            ) {
                this.tableRef.current.search("status", "0");
            } else {
                this.tableRef.current.search("status", "");
            }
        } else {
            plural = this.results.items.length > 1;
            Toast.ok(plural ? "All rows have been processed successfully" : "Row processed successfully");
        }

    }

    render() {
        const key = window.location.hash.match(/#results$/) ? 1 : (window.location.hash.match(/websites/) ? 1 : 0);

        return (
            <Container className="pt-4">
                {this.state.saving === false && key === 1 && (
                    <span style={{
                        float: "right"
                    }}
                    >
                        {!this.state.fromFile && (
                            <Button disabled={this.state.problems === 0} size="sm" variant="outline-primary" onClick={() => {
                                let data = this.getSpreadsheet().getData();
                                for (let i = 0; i < this.results.items.length; i++) {
                                    if (this.results.items[i].status === "1") {
                                        data[i] = null;
                                    }
                                }
                                data = data.filter(function (el) {
                                    return el != null;
                                });

                                this.getSpreadsheet().setData(data);
                                this.props.history.push("/others/url");
                            }}><FontAwesomeIcon icon={faWrench} />{` `}Re-edit entries with problems</Button>
                        )}

                        <Button
                            size="sm"
                            className="tdButton"
                            variant="success"
                            onClick={this.downloadSpreadsheet}
                        >
                            <FontAwesomeIcon icon={faDownload} style={{
                                marginRight: "5px"
                            }} />
                            Download
                        </Button>

                    </span>
                )}
                {key === 0 && !this.state.fromFile && (
                    <span style={{
                        float: "right"
                    }}>
                        <Button size="sm" disabled={this.state.saving} variant="outline-primary" onClick={() => {
                            this.setState({ showModal: true })
                        }}><FontAwesomeIcon icon={faFileImport} />{` `}Import URLs</Button>
                    </span>
                )}

                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Others</Breadcrumb.Item>
                    <Breadcrumb.Item
                        active={key === 0}
                        onClick={() => {
                            if (this.state.saving) {
                                return;
                            }
                            if (this.state.fromFile) {
                                this.setState({ fromFile: false }, () => {
                                    this.props.history.push("/others/url")
                                })
                                return;
                            }
                            this.props.history.push("/others/url")
                        }}
                    >
                        URL Uploader
                    </Breadcrumb.Item>
                    {key > 0 && (
                        <Breadcrumb.Item active>Results</Breadcrumb.Item>
                    )}
                </Breadcrumb>
                <ImportURLs onClose={this.onImport} show={this.state.showModal} />
                <MyCarousel activeIndex={key}>
                    <Carousel.Item>
                        {this.state.loading && (
                            <Loading />
                        )}
                        {!this.state.loading && (
                            <React.Fragment>
                                {!this.state.fromFile && (
                                    <Spreadsheet ref={this.spreadsheet} data={[[]]} options={{
                                        minDimensions: [2, 10000],
                                        lazyLoading: true,
                                        tableOverflow: true,
                                        columnResize: false,
                                        minSpareRows: 1,
                                        columns: [
                                            {
                                                type: 'text',
                                                title: 'Identifier',
                                                width: 150,
                                                align: "left"
                                            },
                                            {
                                                type: 'text',
                                                title: 'URL',
                                                width: 900,
                                                align: "left"
                                            }
                                        ]
                                    }} />
                                )}
                                <Row>
                                    <Col sm={6} style={{
                                        display: "contents"
                                    }}>
                                        {!this.state.fromFile && (
                                            <ProcessingButton
                                                variant="primary"
                                                size="sm"
                                                processing={this.state.saving}
                                                processingLabel="Uploading ..."
                                                label="Upload"
                                                onClick={this.upload}
                                            />
                                        )}
                                        {(!this.state.saving || this.state.fromFile) && (
                                            <FileUploadButton
                                                processing={this.state.saving}
                                                onFileUpload={this.processFile}
                                                instructions={`Provide a CSV file containing two mandatory columns, Identifier and URL, with their respective headers. Mind that all rows in the file will be processed right away.`}
                                            />
                                        )}
                                        {!this.state.fromFile && (
                                            <OverlayTrigger
                                                placement="top"
                                                trigger={["hover", "focus"]}
                                                overlay={
                                                    <Popover>
                                                        <PopoverBody>Were these URLs delivered by NPD? Check this if so</PopoverBody>
                                                    </Popover>
                                                }
                                            >
                                                <Form.Check
                                                    type="checkbox"
                                                    label="NPD"
                                                    disabled={this.state.saving}
                                                    checked={this.state.npd}
                                                    onChange={() => {
                                                        this.setState({ npd: !this.state.npd })
                                                    }}
                                                />
                                            </OverlayTrigger>

                                        )}
                                    </Col>
                                    {this.state.saving && (
                                        <Col>
                                            <ProgressBar completed={this.state.progress} bgcolor="#1f4503" />
                                        </Col>
                                    )}
                                </Row>
                            </React.Fragment>
                        )}
                    </Carousel.Item>
                    <Carousel.Item>
                        <MyTable
                            name="url-uploader"
                            dynamic={false}
                            ref={this.tableRef}
                            sortBy="identifier"
                            sortOrder="asc"
                            headers={
                                [
                                    {
                                        width: "125px",
                                        field: "identifier",
                                        label: "Identifier",
                                        sortable: true,
                                        searchable: true
                                    },
                                    {
                                        width: "400px",
                                        field: "url",
                                        label: "URL",
                                        sortable: true,
                                        searchable: true
                                    },
                                    {

                                        width: "75px",
                                        field: "task",
                                        label: "Action",
                                        sortable: true,
                                        options: [{ label: 'All', value: '' }, 'Added', 'Updated', 'Removed', { label: 'Unknown', value: '\\?' }]
                                    },
                                    {
                                        width: "75px",
                                        field: "status",
                                        label: "Result",
                                        sortable: true,
                                        options: [{ label: 'All', value: '' }, { label: 'OK', value: '1' }, { label: 'Not OK', value: '0' }]
                                    },
                                    {
                                        field: "info",
                                        label: "Info",
                                        searchable: true,
                                        sortable: true
                                    }
                                ]}
                            renderColumns={{
                                identifier: (row) => <Link className="link" to={`/product/${row.pid}`}>{row.identifier}</Link>,
                                url: (row) => <Form.Control
                                    type="text"
                                    value={row.url}
                                    readOnly={true}
                                />,
                                task: (row) => typeof row.task === "undefined" ? "?" : Utils.Capitalize(row.task),
                                status: {
                                    format: (row) => row.status === '1' ? <FontAwesomeIcon
                                        style={{
                                            color: "#1f4503",
                                        }}
                                        icon={faCheck}
                                    /> : <FontAwesomeIcon
                                        style={{ color: "red" }}
                                        icon={faTimes}
                                    />,
                                    csv: (row) => row.status === '1' ? 'OK' : 'Not OK'
                                },
                                info: {
                                    format: (row) => {
                                        const status = typeof row.error === 'undefined',
                                            task = typeof row.task === "undefined" ? "?" : Utils.Capitalize(row.task);
                                        let info;
                                        if (status) {
                                            if (task === 'Add') {
                                                info = 'URL was added to ' + this.results.websites[row.mid] + ' (#' + row.mid + ')';
                                            } else if (task === 'Remove') {
                                                info = 'URL was removed from ' + this.results.websites[row.mid] + ' (#' + row.mid + ')';
                                            } else {
                                                info = 'URL was modified for ' + this.results.websites[row.mid] + ' (#' + row.mid + ')';
                                            }
                                        } else {
                                            info = row.error;
                                        }
                                        return <Form.Control
                                            type="text"
                                            value={info}
                                            readOnly={true}
                                        />
                                    },
                                    csv: (row) => {
                                        const status = typeof row.error === 'undefined',
                                            task = typeof row.task === "undefined" ? "?" : Utils.Capitalize(row.task);
                                        let info;
                                        if (status) {
                                            if (task === 'Add') {
                                                info = 'URL was added to ' + this.results.websites[row.mid] + ' (#' + row.mid + ')';
                                            } else if (task === 'Remove') {
                                                info = 'URL was removed from ' + this.results.websites[row.mid] + ' (#' + row.mid + ')';
                                            } else {
                                                info = 'URL was modified for ' + this.results.websites[row.mid] + ' (#' + row.mid + ')';
                                            }
                                        } else {
                                            info = row.error;
                                        }
                                        return info;
                                    }
                                }
                            }}
                        />
                    </Carousel.Item>
                </MyCarousel>
            </Container>
        );
    }
}
export default withRouter(URLUploader);
