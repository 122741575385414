import React from "react";
import { Container, Row, Col } from "react-bootstrap";

/**
 * Renders a 404 page
 * @component
 * @category Scenes
 */
class NoMatch extends React.Component {
    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <span
                            style={{
                                position: "fixed",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                fontSize: "48pt",
                            }}
                        >
                            404
                        </span>
                    </Col>
                </Row>
            </Container>
        );
    }
}
export default NoMatch;
