import React from "react";

import { Container, Breadcrumb, Row, Col } from "react-bootstrap";
import NPDService from "../../../services/NPDService";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Toast from "../../../services/Toast";
import ProcessingButton from "../../../components/ProcessingButton";
import WebsiteService from "../../../services/WebsiteService";

/**
 * Autofeeder Constraints component
 * @component
 * @category Others
 */
class Download extends React.Component {

    constructor(props) {
        super(props);
        let fragment = this.props.location.hash,
            state = {
                canDownload: false,
                downloading: false
            };

        if (fragment.length) {
            let mode = fragment.match(/#([^:]+):/),
                website_id = fragment.match(/:(\d+)$/);

            if (mode != null && website_id != null) {
                state = {
                    canDownload: true,
                    mode: mode[1],
                    website_id: parseInt(website_id[1])
                }
            }
        }
        this.state = state;

        this.handleDownload = this.handleDownload.bind(this);
    }

    handleDownload(evt) {
        let f = NPDService.package,
            extra = null;
        if (this.state.mode !== 'package') {
            f = WebsiteService.npd;
            extra = this.state.mode;
        }
        this.setState({ downloading: true });
        f(this.state.website_id, extra).then((result) => {
            if (result === false) {
                Toast.error("Something went wrong while downloading file");
            }
            this.setState({ downloading: false });
        });
    }

    render() {
        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Others</Breadcrumb.Item>
                    <Breadcrumb.Item>NPD</Breadcrumb.Item>
                    <Breadcrumb.Item active>Download</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <Col>
                        <ProcessingButton
                            variant="success"
                            disabled={!this.state.canDownload}
                            processing={this.state.downloading}
                            processingLabel="Downloading ..."
                            label="Download report"
                            icon={faDownload}
                            onClick={this.handleDownload} />
                    </Col>
                </Row>
            </Container >
        );
    }
}

export default Download;
