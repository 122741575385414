import axios from "axios";

import Config from "./Config";
import APIHelper from "./APIHelper";

const DomainDiscoveryService = {
    getAllBlacklistedTLD() {
        return axios
            .get(Config.API_URL + "dd/blacklist/tld")
            .then(APIHelper.returnDataOrEmptyArray)
            .catch(APIHelper.returnEmptyArray);
    },
    updateBlackListTLD(id, tld) {
        return axios
            .put(Config.API_URL + "dd/blacklist/tld/" + id, {
                tld,
            })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    insertBlackListTLD(tld) {
        return axios
            .post(Config.API_URL + "dd/blacklist/tld", {
                tld,
            })
            .then(APIHelper.returnItems)
            .catch(APIHelper.returnFalse);
    },
    removeBlackListTLD(id) {
        return axios
            .delete(Config.API_URL + "dd/blacklist/tld/" + id)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    getAllBlacklistedDomain() {
        return axios
            .get(Config.API_URL + "dd/blacklist/domain")
            .then(APIHelper.returnDataOrEmptyArray)
            .catch(APIHelper.returnEmptyArray);
    },
    updateBlackListDomain(id, domain) {
        return axios
            .put(Config.API_URL + "dd/blacklist/domain/" + id, {
                domain,
            })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    insertBlackListDomain(domain) {
        return axios
            .post(Config.API_URL + "dd/blacklist/domain", {
                domain,
            })
            .then(APIHelper.returnItems)
            .catch(APIHelper.returnFalse);
    },
    removeBlackListDomain(id) {
        return axios
            .delete(Config.API_URL + "dd/blacklist/domain/" + id)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    getAllBlacklistedSubdomain() {
        return axios
            .get(Config.API_URL + "dd/blacklist/subdomain")
            .then(APIHelper.returnDataOrEmptyArray)
            .catch(APIHelper.returnEmptyArray);
    },
    updateBlackListSubdomain(id, subdomain) {
        return axios
            .put(Config.API_URL + "dd/blacklist/subdomain/" + id, {
                subdomain,
            })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    insertBlackListSubdomain(subdomain) {
        return axios
            .post(Config.API_URL + "dd/blacklist/subdomain", {
                subdomain,
            })
            .then(APIHelper.returnItems)
            .catch(APIHelper.returnFalse);
    },
    removeBlackListSubdomain(id) {
        return axios
            .delete(Config.API_URL + "dd/blacklist/subdomain/" + id)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    // Non-Ecommerce
    getAllOverride() {
        return axios
            .get(Config.API_URL + "dd/override")
            .then(APIHelper.returnDataOrEmptyArray)
            .catch(APIHelper.returnEmptyArray);
    },
    updateOverride(id, domain, is_ecommerce) {
        return axios
            .put(Config.API_URL + "dd/override/" + id, {
                domain,
                is_ecommerce
            })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    insertOverride(domain, is_ecommerce) {
        return axios
            .post(Config.API_URL + "dd/override", {
                domain,
                is_ecommerce
            })
            .then(APIHelper.returnItems)
            .catch(APIHelper.returnFalse);
    },
    removeOverride(id) {
        return axios
            .delete(Config.API_URL + "dd/override/" + id)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    /**
     * Retrieves jobs
     * @param {object} settings Related to pagination settings: page, pageSize, sort, search
     * @returns {array} of rows
     */
    jobs(settings) {
        return axios
            .post(Config.API_URL + "dd/jobs", settings)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    /**
     * Dispatch a new job
     * @param {integer} manufacturer_id
     * @param {object} settings Engines + keywords, pages
     * @returns {bool} Has the new job been properly scheduled?
     */
    request(manufacturer_id, settings) {
        settings['manufacturer_id'] = manufacturer_id
        return axios
            .post(Config.API_URL + "dd/job", settings)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    autogenerate(id) {
        return axios
            .get(Config.API_URL + "dd/keywords/manufacturer/" + id)
            .then(response => APIHelper.returnAttribute(response, 'keywords'))
            .catch(APIHelper.returnFalse);
    },
    getResults(id) {
        return axios
            .get(Config.API_URL + "dd/jobs/" + id + "/csv")
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data.report])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    "dd_manufacturer" + response.data.job.manufacturer.id + ".csv"
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
                return true;
            })
            .catch(APIHelper.returnFalse);
    },
    deleteJob(id) {
        return axios
            .delete(Config.API_URL + "dd/job/" + id)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    getKeywords(id) {
        return axios
            .get(Config.API_URL + "dd/jobs/" + id + "/keywords")
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data.report])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    "dd_manufacturer" + response.data.job.manufacturer.id + "-keywords.csv"
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
                return true;
            })
            .catch(APIHelper.returnFalse);
    },
    getDomains(id) {
        return axios
            .get(Config.API_URL + "dd/jobs/" + id + "/domains")
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data.report])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    "dd_manufacturer" + response.data.job.manufacturer.id + "-domains.csv"
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
                return true;
            })
            .catch(APIHelper.returnFalse);
    },
    playpause(id, mode) {
        return axios
            .put(Config.API_URL + "dd/job/" + id, {
                mode
            })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    job(id) {
        return axios
            .get(Config.API_URL + "dd/job/" + id + '/fragments')
            .then(APIHelper.returnItems)
            .catch(APIHelper.returnFalse);
    },
    fragment(id) {
        return axios
            .get(Config.API_URL + "dd/job/fragment/" + id)
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    removeFragment(id) {
        return axios
            .delete(Config.API_URL + "dd/job/fragment/" + id)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnFalse);
    },
    resetFragment(id) {
        return axios
            .delete(Config.API_URL + "dd/job/fragment/" + id + "/results")
            .then((response) => {
                if (typeof response.data === "object" && typeof response.data.status === "boolean" && typeof response.data.error === "string") {
                    return response.data.error;
                }
                return (
                    typeof response.data === "object" && typeof response.data.status === "boolean" && response.data.status
                );
            })
            .catch(APIHelper.returnFalse);
    },
};

export default DomainDiscoveryService;
