import React, { useMemo } from "react";

import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Toast from "../../services/Toast";
import Loading from "../Loading";
import Emitter from "../../services/Emitter";
import ProcessingButton from "../../components/ProcessingButton";
import OrganizationService from "../../services/OrganizationService";
import DropDown from "../../components/DropDown";
import GenericService from "../../services/GenericService";
import { useDropzone } from "react-dropzone";


/**
 * Show a CRUD for the Organizations row
 * @component
 * @category Scenes
 * @subcategory Organization
 */
class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.csm = [];
        this.sm = [];
        this.state = this.initialState();

        this.handleChange = this.handleChange.bind(this);
        this.handleSettingChange = this.handleSettingChange.bind(this);
        this.dropEdition = this.dropEdition.bind(this);
        this.startEdition = this.startEdition.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.handleRemoveImage = this.handleRemoveImage.bind(this);
        this.reload = this.reload.bind(this);
    }

    loadUsers() {
        GenericService.getManagers().then((data) => {
            if (data === false) {
                Toast.error("Failed retrieving Managers' data");
                return;
            }
            this.csm = data.csm;
            this.sm = data.sm;
            this.setState({
                csm: data.csm,
                sm: data.sm
            })
        });
    }

    initialState() {
        return {
            row: {},
            loading: true,
            editing: false,
            updating: false,
            csm: this.csm,
            sm: this.sm,
            image: null,
            preview: null,
            functionalImageURL: true
        };
    }

    handleChange(evt) {
        let row = this.state.row,
            key = evt.target.name;

        row[key] = evt.target.value;
        this.setState({ row });
    }

    handleSettingChange(evt) {
        let row = this.state.row,
            key = evt.target.name;

        row.settings[key] = evt.target.value;
        this.setState({ row });
    }

    loadSettings(data) {
        return {
            loading: false,
            row: data,
            editing: false,
            csm: typeof data.settings !== "undefined" && typeof data.settings.csm === "object" && data.settings.csm !== null ? [{ label: data.settings.csm.name, value: data.settings.csm.id }] : [],
            sm: typeof data.settings !== "undefined" && typeof data.settings.sm === "object" && data.settings.sm !== null ? [{ label: data.settings.sm.name, value: data.settings.sm.id }] : [],
            image: typeof data.settings !== "undefined" && typeof data.settings.logo === "string" ? data.settings.logo : null,
            airtable_id: typeof data.settings !== "undefined" && typeof data.settings.airtable_id === "string" ? data.settings.airtable_id : null
        };
    }

    reload() {
        OrganizationService.get(this.props.orgId).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading this organization's data");
                return;
            }
            this.setState(this.loadSettings(data), this.loadUsers);
        });
    }

    componentWillUnmount() {
        Emitter.off("ORG_PROFILE");
    }

    componentDidMount() {
        Emitter.once("ORG_PROFILE", this.reload);
    }

    saveChanges() {
        this.setState({ updating: true });
        let payload = {
            row: this.state.row,
        },
            config = {
                headers: {
                    'content-type': 'application/json'
                }
            }
        if (this.state.removeImage) {
            payload.row.image = 'remove';
        } else if (this.state.preview !== null) {
            let row = this.state.row;
            row.image = 'new';
            payload = new FormData();
            payload.append('row', JSON.stringify(row));
            payload.append('image', this.image);
            config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
        }

        OrganizationService.update(this.props.orgId, payload, config).then((result) => {
            if (result === false) {
                this.setState({ updating: false });
                Toast.error(
                    "Something wrong happened and changes could not be saved."
                );
                return;
            } else {
                delete this.original;
                delete this.image;
                let row = this.state.row;
                row.settings.logo = typeof result.image === "string" ? result.image : '';
                this.setState({
                    row,
                    updating: false,
                    editing: false,
                    preview: null,
                    image: row.settings.logo
                });
                Toast.ok("Changes properly saved.");
            }
        });
    }

    startEdition() {
        this.original = JSON.stringify(this.state.row);
        this.setState({ editing: true, removeImage: false, preview: null });
    }

    dropEdition() {
        this.setState(this.loadSettings(JSON.parse(this.original)), this.loadUsers);
        delete this.original;
    }


    handleRemoveImage() {
        this.setState({
            image: null,
            preview: null,
            removeImage: true
        })
    }

    render() {
        if (this.state.loading) {
            return (
                <Container className="pt-4">
                    <Row
                        style={{
                            height: "100px",
                        }}
                    >
                        <Col>
                            <Loading />
                        </Col>
                    </Row>
                </Container>
            );
        }

        const baseStyle = {
            cursor: "pointer",
            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#eeeeee',
            borderStyle: 'dashed',
            outline: 'none',
            position: "absolute"
        };

        const activeStyle = {
            borderColor: '#2196f3'
        };

        const acceptStyle = {
            borderColor: '#00e676'
        };

        const rejectStyle = {
            borderColor: '#ff1744'
        };

        function StyledDropzone(props) {
            const {
                getRootProps,
                getInputProps,
                isDragActive,
                isDragAccept,
                isDragReject
            } = useDropzone({
                accept: 'image/*', onDrop: acceptedFiles => {
                    if (acceptedFiles.length === 0) {
                        Toast.error("File type not valid");
                        return;
                    }
                    props.onFileSelected(acceptedFiles[0]);
                }
            });

            const style = useMemo(() => ({
                ...baseStyle,
                ...(isDragActive ? activeStyle : {}),
                ...(isDragAccept ? acceptStyle : {}),
                ...(isDragReject ? rejectStyle : {})
            }), [
                isDragActive,
                isDragReject,
                isDragAccept
            ]);

            return (
                <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <img src={props.image} alt={''}
                        className="thumb"
                        style={{
                            maxWidth: "100px",
                            maxHeight: "100px",
                        }}
                        onError={(e) => {
                            e.target.className += " failed";
                            e.target.src = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                        }} />
                </div>
            );
        }

        return (
            <Container className="pt-4">
                <Row>
                    <Col sm={1}>
                        <Form.Label>ID</Form.Label>
                        <Form.Control
                            type="text"
                            name="id"
                            value={this.state.row.id}
                            readOnly={true}
                        />
                    </Col>
                    <Col sm={5}>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={this.state.row.name}
                            onChange={this.handleChange}
                            readOnly={!this.state.editing || this.state.updating}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Hubspot</Form.Label>
                        <Form.Control
                            type="text"
                            name="hubspot"
                            value={this.state.row.hubspot}
                            onChange={this.handleChange}
                            readOnly={!this.state.editing || this.state.updating}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Airtable ID</Form.Label>
                        <Form.Control
                            type="text"
                            name="airtable_id"
                            value={this.state.row.settings.airtable_id}
                            onChange={this.handleSettingChange}
                            readOnly={!this.state.editing || this.state.updating}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Image</Form.Label>
                        {(this.state.editing || this.state.updating) && (
                            <React.Fragment>
                                <StyledDropzone image={this.state.preview !== null ? this.state.preview : (this.state.image !== null ? this.state.image : "#")} onFileSelected={(file) => {
                                    this.image = file;
                                    this.setState({ preview: URL.createObjectURL(file), removeImage: false })
                                }} />
                                {(this.state.image !== null || this.state.preview !== null) && !this.state.removeImage && this.state.functionalImageURL && (
                                    <Button size="sm" variant="outline-danger" style={{
                                        position: "relative",
                                        top: "70px",
                                        left: "70px"
                                    }} onClick={this.handleRemoveImage}>Remove image</Button>
                                )}
                            </React.Fragment>
                        )}
                        {!this.state.editing && !this.state.updating && (
                            <div style={{
                                width: "100px",
                                height: "100px",
                                position: "absolute"
                            }}>
                                <img src={this.state.image} alt="Logo" className="thumb"
                                    onError={(e) => {
                                        e.target.className += " failed";
                                        e.target.src = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                                        this.setState({ functionalImageURL: false });
                                    }} />
                            </div>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control
                            type="text"
                            name="contact_email"
                            value={this.state.row.contact_email !== null ? this.state.row.contact_email : ''}
                            readOnly={!this.state.editing || this.state.updating}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col sm={4}>
                        <Form.Label>Website</Form.Label>
                        <Form.Control
                            type="text"
                            name="website"
                            value={this.state.row.website !== null ? this.state.row.website : ''}
                            onChange={this.handleChange}
                            readOnly={!this.state.editing || this.state.updating}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={2}>
                        <Form.Label>Status</Form.Label>
                        <DropDown
                            name="status"
                            allowEmpty={false}
                            options={[{ label: 'Active', value: 'active' }, { label: 'Disabled', value: 'disabled' }, { label: 'Deleted', value: 'deleted' }]}
                            selected={this.state.row.status}
                            disabled={!this.state.editing || this.state.updating}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Type</Form.Label>
                        <DropDown
                            name="type"
                            allowEmpty={false}
                            options={[{ label: 'Client', value: 'client' }, { label: 'PDLT', value: 'lead' }]}
                            selected={this.state.row.type}
                            disabled={true}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Client Success Manager</Form.Label>
                        <DropDown
                            name="csm"
                            options={this.state.csm}
                            selected={this.state.row.settings.csm}
                            disabled={!this.state.editing || this.state.updating}
                            onChange={this.handleSettingChange}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Sales Manager</Form.Label>
                        <DropDown
                            name="sm"
                            options={this.state.sm}
                            selected={this.state.row.settings.sm}
                            disabled={!this.state.editing || this.state.updating}
                            onChange={this.handleSettingChange}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Created</Form.Label>
                        <Form.Control
                            type="text"
                            name="created"
                            value={this.state.row.created}
                            readOnly={true}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Modified</Form.Label>
                        <Form.Control
                            type="text"
                            name="modified_at"
                            value={this.state.row.modified}
                            readOnly={true}
                        />
                    </Col>
                </Row>
                <Row
                    style={{
                        marginTop: "30px",
                        height: "350px",
                    }}
                >
                    <Col sm={6}>
                        {!this.state.editing && (
                            <Button
                                variant="secondary"
                                onClick={this.startEdition}
                            >
                                Edit
                            </Button>
                        )}
                        {this.state.editing && (
                            <span>
                                <Button
                                    disabled={this.state.updating}
                                    variant="secondary"
                                    onClick={this.dropEdition}
                                >
                                    Cancel
                                </Button>
                                <ProcessingButton
                                    variant="primary"
                                    processing={this.state.updating}
                                    processingLabel="Saving ..."
                                    label="Save"
                                    onClick={this.saveChanges}
                                />
                            </span>
                        )}
                    </Col>
                </Row>
            </Container >
        );
    }
}

export default Profile;
