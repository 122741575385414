import React, { createRef } from "react";

import { Container, Breadcrumb, Button, Modal, Row, Col, Form, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";
import Toast from "../../../services/Toast";
import MyTable from "../../../components/MyTable";
import NPDService from "../../../services/NPDService";
import { Link } from "react-router-dom";
import MySpinner from "../../../components/MySpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faDownload, faExclamationTriangle, faHourglass, faInfoCircle, faThumbsUp, faWrench } from "@fortawesome/free-solid-svg-icons";
import ProcessingButton from "../../../components/ProcessingButton";

/**
 * NPD Crawler component
 * @component
 * @category Others
 */
class Crawler extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            saving: false,
            wait_days: 0,
            requests_per_minute: 0,
            website: null
        }
        this.tableRef = createRef();
        this.downloadPackage = this.downloadPackage.bind(this);
        this.handleConfig = this.handleConfig.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSettingChange = this.handleSettingChange.bind(this);
    }

    handleConfig(evt) {
        var obj = this.tableRef.current.getRowFromEvent(evt);
        this.row = obj.row;
        this.setState({
            wait_days: obj.row.wait_days,
            requests_per_minute: obj.row.requests_per_minute,
            website: obj.row.website,
            edit: true
        });
    }

    handleSettingChange(evt) {
        let edit = {};
        edit[evt.target.getAttribute('field')] = evt.target.value;
        this.setState(edit);
    }

    downloadPackage(evt) {
        var obj = this.tableRef.current.getRowFromEvent(evt),
            row = obj.row;

        row.downloading = true;
        this.tableRef.current.updateRow(row);
        NPDService.package(row.website_id).then((result) => {
            if (!result) {
                Toast.error("Something went wrong while retriving package.json");
            }
            delete row.downloading;
            this.tableRef.current.updateRow(row);
        });
    }

    handleSave(evt) {
        const payload = {
            requests_per_minute: parseInt(this.state.requests_per_minute),
            wait_days: parseInt(this.state.wait_days)
        }
        let changes = 0;
        Object.keys(payload).forEach((field) => {
            if (payload[field] !== parseInt(this.row[field])) {
                changes++;
            }
        })
        if (changes === 0) {
            this.setState({ edit: false });
            Toast.info("No changes were made.");
            return;
        }

        this.setState({ saving: true });
        NPDService.settings(this.row.website_id, payload).then((result) => {
            if (result === false) {
                Toast.error(
                    "Something went wrong while saving settings."
                );
                this.setState({ saving: false });
                return;
            }
            let row = this.row;
            row.requests_per_minute = this.state.requests_per_minute;
            row.wait_days = this.state.wait_days;
            this.tableRef.current.updateRow(row);
            delete this.row;
            this.setState({ saving: false, edit: false }, () => {
                Toast.ok("Changes saved successfully.");
            });
        });
    }

    componentDidMount() {
        NPDService.crawler().then((data) => {
            if (data === false) {
                Toast.error(
                    "Something went wrong while retrieving the list of rows"
                );
                return;
            }
            this.tableRef.current?.populate(data);
        });
    }

    render() {
        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Others</Breadcrumb.Item>
                    <Breadcrumb.Item>NPD</Breadcrumb.Item>
                    <Breadcrumb.Item active>Crawler</Breadcrumb.Item>
                </Breadcrumb>
                <Modal
                    size="lg"
                    show={this.state.edit || this.state.saving}
                    backdrop={true}
                    keyboard={true}
                    centered
                >
                    <Modal.Header closeButton onHide={() => this.setState({ edit: false })}>
                        <Modal.Title>
                            Crawler settings <small>({this.state.website})</small>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={3} className="d-flex align-items-center justify-content-end">
                                <OverlayTrigger
                                    placement="bottom"
                                    trigger={["hover", "focus"]}
                                    overlay={<Popover><PopoverBody>How frequently we should crawl this website</PopoverBody></Popover>}
                                >
                                    <Form.Label>Wait days <FontAwesomeIcon icon={faInfoCircle} /></Form.Label>
                                </OverlayTrigger>
                            </Col>
                            <Col sm={2}><Form.Control type="number" value={this.state.wait_days} disabled={this.state.saving} field="wait_days" onChange={this.handleSettingChange} /></Col>
                            <Col sm={3} className="d-flex align-items-center justify-content-end">
                                <OverlayTrigger
                                    placement="bottom"
                                    trigger={["hover", "focus"]}
                                    overlay={<Popover><PopoverBody>Number of requests per minute</PopoverBody></Popover>}
                                >
                                    <Form.Label>Speed of crawl <FontAwesomeIcon icon={faInfoCircle} /></Form.Label>
                                </OverlayTrigger>
                            </Col>
                            <Col sm={2}><Form.Control type="number" value={this.state.requests_per_minute} disabled={this.state.saving} field="requests_per_minute" onChange={this.handleSettingChange} /> </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer style={{
                        justifyContent: "center"
                    }}>
                        <Button size="sm" variant="secondary" disabled={this.state.saving} onClick={() => this.setState({ edit: false })}>Cancel</Button>
                        <ProcessingButton
                            size="sm"
                            processing={this.state.saving}
                            processingLabel="Saving ..."
                            label="Save"
                            onClick={this.handleSave}
                        />
                    </Modal.Footer>
                </Modal >
                <MyTable
                    name="npd-crawler"
                    ref={this.tableRef}
                    dynamic={false}
                    sortBy="status"
                    sortOrder="desc"
                    headers={[
                        {
                            field: "website_id",
                            label: "ID",
                            sortable: true,
                            searchable: true,
                            width: "75px"
                        },
                        {
                            field: "website",
                            label: "Website",
                            sortable: true,
                            searchable: true
                        },
                        {
                            field: "bucket",
                            label: "Bucket",
                            sortable: true,
                            searchable: true,
                            width: "125px"
                        },
                        {
                            field: "status",
                            label: "Status",
                            sortable: true,
                            width: "125px",
                            allowEmpty: true,
                            options: [{ label: 'In-queue', value: 'initial' }, { label: 'Running', value: 'running' }, { label: 'Failed', value: 'failed' }, { label: 'Completed', value: 'completed' }]
                        },
                        {
                            field: "last_crawl",
                            label: "Last Crawled",
                            sortable: true,
                            searchable: true,
                            width: "175px"
                        },
                        {
                            field: "control",
                            width: "87px"
                        }
                    ]}
                    renderColumns={{
                        website: (row) => <Link className="link" to={`/website/${row.website_id}#discovery`} target="_blank">{row.website.toLowerCase()}</Link>,
                        status: {
                            text: (row) => {
                                if (row.status === 'crawled') {
                                    return 'completed';
                                }
                                return row.status;
                            },
                            format: (row) => {
                                if (row.status === 'initial') {
                                    return (
                                        <span style={{ color: "silver" }}>
                                            <FontAwesomeIcon icon={faHourglass} /> In-queue
                                        </span>
                                    )
                                }
                                if (row.status === 'running') {
                                    return (
                                        <span style={{ color: "orange" }}>
                                            <FontAwesomeIcon icon={faBolt} /> Running
                                        </span>
                                    )
                                }
                                if (row.status === 'failed') {
                                    return (
                                        <span style={{ color: "red" }}>
                                            <FontAwesomeIcon icon={faExclamationTriangle} /> Failed
                                        </span>
                                    )
                                }
                                if (row.status === 'completed' || row.status === 'crawled') {
                                    return (
                                        <span style={{ color: "green" }}>
                                            <FontAwesomeIcon icon={faThumbsUp} /> Completed
                                        </span>
                                    )
                                }
                            }
                        },
                        control: (row) => {
                            const downloadBtn = typeof row.downloading !== "undefined" ? <MySpinner /> :
                                <Button
                                    disabled={row.status !== "completed" && row.status !== "crawled"}
                                    size="sm"
                                    className="tdButton"
                                    variant="outline-secondary"
                                    onClick={this.downloadPackage}
                                >
                                    <FontAwesomeIcon icon={faDownload} />
                                </Button>;

                            return <React.Fragment>
                                {downloadBtn}
                                <Button
                                    size="sm"
                                    className="tdButton"
                                    variant="outline-primary"
                                    onClick={this.handleConfig}
                                >
                                    <FontAwesomeIcon icon={faWrench} />
                                </Button>
                            </React.Fragment>
                        }

                    }}
                />
            </Container >
        );
    }
}

export default Crawler;
